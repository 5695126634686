/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { responsiveStyle } from '../constants/media-query';
import { Button, ButtonBackgroundColor } from './button';
import { Divider } from './divider';
import { Page } from './page';
import { Section } from './section';
import { TitleText, TitleTextBackground } from './title-text';
import { TransparentText } from './transparent-text';

const subsectionStyles = css({
  padding: '24px 0px',
});

const buttonWrapperStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const responsiveText = css(
  responsiveStyle('fontSize', {
    xs: '20px',
    sm: '20px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  }),
);

const centerAlignStyles = css({
  textAlign: 'center',
});

// const linkStyles = css({
//   color: '#4512EA'
// });

const titleSectionButtonWrapperStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
});

export type RefreshPageProps = {
  onJoinTheWaitlist: () => void;
  chopPath: string;
  aiEngineerPath: string;
  partnerLogoPaths: string[];
};

export function RefreshPage({
  chopPath,
  aiEngineerPath,
  partnerLogoPaths,
  onJoinTheWaitlist,
}: RefreshPageProps) {
  return (
    <Page>
      <Section
        gridTemplateColumns={{
          xs: '1fr',
          sm: '1fr',
          md: '1fr',
          lg: '1fr auto',
          xl: '1fr auto',
        }}
      >
        <TitleText
          textAlign={{
            xs: 'center',
            sm: 'center',
            md: 'left',
            lg: 'left',
            xl: 'left',
          }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '32px',
            sm: '32px',
            md: '64px',
            lg: '64px',
            xl: '64px',
          }}
          fontWeight={700}
        >
          <div
            css={css(
              responsiveStyle('display', {
                xs: 'none',
                sm: 'none',
                md: 'block',
                lg: 'block',
                xl: 'block',
              }),
            )}
          >
            <div>
              Breathe <TransparentText value={'New Life'} /> into
            </div>
            <div>
              your Catalog. <TransparentText value={'Refresh'} /> it.
            </div>
          </div>

          <div
            css={css(
              responsiveStyle('display', {
                xs: 'block',
                sm: 'block',
                md: 'none',
                lg: 'none',
                xl: 'none',
              }),
            )}
          >
            <div>
              Breathe <TransparentText value={'New Life'} /> into
            </div>
            <div>your Catalog.</div>
            <div>
              <TransparentText value={'Refresh it.'} />
            </div>
          </div>
        </TitleText>

        <div css={titleSectionButtonWrapperStyles}>
          <span>
            <Button
              contents={'Join the Waitlist'}
              buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
              onClick={onJoinTheWaitlist}
            />
          </span>
        </div>
      </Section>

      <Section>
        <div
          css={css(
            {
              display: 'grid',
              rowGap: '32px',
              columnGap: '32px',
              margin: 'auto',
            },
            responsiveStyle('gridTemplateColumns', {
              xs: '1fr',
              sm: '358px',
              md: '358px',
              lg: '2fr 3fr',
              xl: '2fr 3fr',
            }),
          )}
        >
          <div
            css={css(
              {
                padding: '32px',
              },
              responsiveStyle('gridRowStart', {
                xs: 2,
                sm: 2,
                md: 2,
                lg: 1,
                xl: 1,
              }),
            )}
          >
            <TitleText
              textAlign={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'left',
                xl: 'left',
              }}
              background={TitleTextBackground.RED_TO_YELLOW}
              fontSize={{
                xs: '24px',
                sm: '24px',
                md: '40px',
                lg: '40px',
                xl: '40px',
              }}
              fontWeight={700}
            >
              <div
                css={css(
                  responsiveStyle('display', {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block',
                    xl: 'block',
                  }),
                )}
              >
                <div>
                  What is <TransparentText value={'Recordless'} />
                </div>
                <div>
                  <TransparentText value={'Refresh?'} />
                </div>
              </div>

              <div
                css={css(
                  responsiveStyle('display', {
                    xs: 'block',
                    sm: 'block',
                    md: 'block',
                    lg: 'none',
                    xl: 'none',
                  }),
                )}
              >
                <div>
                  What is <TransparentText value={'Recordless Refresh?'} />
                </div>
              </div>
            </TitleText>

            <p css={css(responsiveText)}>
              Recordless Refresh is an AI-powered service designed to revitalize
              catalogs of music held by labels, collectives, and music
              corporations.
            </p>

            <p css={css(responsiveText)}>
              With our innovative technology, you can boost your catalog's value
              with never before heard mash-ups and remixes.
            </p>

            <div css={css(buttonWrapperStyles, subsectionStyles)}>
              <span>
                <Button
                  contents={'Join the Waitlist'}
                  buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
                  onClick={onJoinTheWaitlist}
                />
              </span>
            </div>
          </div>

          <div
            css={css(
              responsiveStyle('textAlign', {
                xs: 'center',
                lg: 'right',
              }),
            )}
          >
            <img
              css={css({
                maxWidth: '100%',
                paddingBottom: '16px',
                position: 'relative',
                zIndex: 10,
              })}
              src={chopPath}
              alt="chop"
            />
          </div>
        </div>

        <div
          css={css({
            position: 'absolute',
            left: '50%',
            top: '60%',
            width: '40%',
            height: '50%',
            background:
              'linear-gradient(149.16deg, #4512EA 10.99%, #1282EA 32.3%, #12EA5B 48%, #EFE75E 58.01%, #E07614 71.09%, #F31875 88.77%, #4512EA 102.86%)',
            opacity: '0.4',
            filter: 'blur(186.805px)',
            borderRadius: '383.217px',
            transform: 'matrix(0.97, -0.26, -0.26, -0.97, 0, 0)',
            zIndex: -10,
          })}
        />
      </Section>

      <Section>
        <TitleText
          textAlign={{ xs: 'left' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '28px',
            sm: '28px',
            md: '48px',
            lg: '48px',
            xl: '48px',
          }}
          fontWeight={700}
        >
          <div
            css={css(
              responsiveStyle('display', {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
                xl: 'block',
              }),
            )}
          >
            <div>
              <TransparentText value={'Recordless AI Engineer'} /> does the
              heavy lifting.
            </div>
            <div>
              You'll have <TransparentText value={'choices.'} />
            </div>
          </div>

          <div
            css={css(
              responsiveStyle('display', {
                xs: 'block',
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none',
              }),
            )}
          >
            <div>
              <TransparentText value={'Our AI Engineer'} /> does
            </div>
            <div>the heavy lifting.</div>
          </div>
        </TitleText>

        <Divider />

        <div>
          <img
            css={css({
              maxWidth: '100%',
              position: 'relative',
              zIndex: 10,
              marginTop: '-24px',
            })}
            src={aiEngineerPath}
            alt="ai_engineer"
          />
        </div>

        <Divider margin={'-120px 0px 0px 0px'} />

        <div
          css={css({
            position: 'absolute',
            left: '-20%',
            top: '80%',
            width: '40%',
            height: '40%',
            background:
              'linear-gradient(147.59deg, #4512EA 6.81%, #1282EA 19.99%, #12EA5B 29.72%, #EFE75E 35.91%, #E07614 44%, #F31875 54.95%, #4512EA 63.67%)',
            opacity: '0.4',
            filter: 'blur(152px)',
            borderRadius: '140.092px',
            transform: 'matrix(0.88, -0.48, 0.51, 0.86, 0, 0)',
            zIndex: -10,
          })}
        />
      </Section>

      <Section>
        <div
          css={css(
            {
              display: 'grid',
              rowGap: '32px',
              columnGap: '32px',
              margin: 'auto',
            },
            responsiveStyle('gridTemplateColumns', {
              xs: '1fr',
              sm: '358px',
              md: '358px',
              lg: '1fr 1fr',
              xl: '1fr 1fr',
            }),
          )}
        >
          <div
            css={css({
              background: 'rgba(255, 255, 255, 0.05)',
              borderRadius: '8px',
              padding: '32px',
            })}
          >
            <TitleText
              textAlign={{
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'left',
                xl: 'left',
              }}
              background={TitleTextBackground.ORANGE_TO_PINK}
              fontSize={{
                xs: '30px',
                sm: '30px',
                md: '48px',
                lg: '48px',
                xl: '48px',
              }}
              fontWeight={700}
            >
              <div>
                Why <TransparentText value={'Refresh'} /> your
              </div>
              <div>catalog?</div>
            </TitleText>

            <p css={css(responsiveText)}>
              Recordless Refresh will increase engagement within your existing
              user base, and provide a compelling marketing story that sets your
              catalog apart from the rest.
            </p>

            <p css={css(responsiveText)}>
              Imagine the attention and growth you can achieve when you offer
              your listeners an entirely new way to experience the music they
              love. It's an innovative opportunity to drive excitement and
              create a lasting impact.
            </p>
          </div>

          <div>
            <TitleText
              textAlign={{ xs: 'center' }}
              background={TitleTextBackground.RED_TO_PURPLE}
              fontSize={{
                xs: '30px',
                sm: '30px',
                md: '48px',
                lg: '48px',
                xl: '48px',
              }}
              fontWeight={700}
            >
              <div>Join our early label</div>
              <div>
                <TransparentText value="Partners" />
              </div>
            </TitleText>

            {partnerLogoPaths.map((logo) => (
              <div css={centerAlignStyles}>
                <img
                  css={css({
                    maxWidth: '100%',
                    padding: '24px 0',
                    position: 'relative',
                    zIndex: 10,
                  })}
                  src={logo}
                />
              </div>
            ))}
          </div>
        </div>
      </Section>
    </Page>
  );
}
