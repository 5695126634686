import { ErrorDescriptor } from '@inkibra/error-base';
import {
  Brand,
  CacheableObjectUtil,
  UNHANDLED_VALIDATION_FAILURE,
} from '@inkibra/observable-cache';
import { Result, err, ok } from 'neverthrow';
import typia from 'typia';

export type InkibraRecordlessLibrarySongType = Readonly<{
  id: Brand<typeof InkibraRecordlessLibrarySongType.typename>;
  slug: string;
  title: string;
  artists: string[];
  albumArtist: string;
  genres: InkibraRecordlessLibrarySongType.GenreLiterals[];
  key: InkibraRecordlessLibrarySongType.KeyLiterals;
  albumTitle: string;
  trackNumber?: number & typia.tags.Minimum<0>;
  discNumber?: number & typia.tags.Minimum<0>;
  keywords: string[];
  moodWords: InkibraRecordlessLibrarySongType.MoodLiterals[];
  activityWords: InkibraRecordlessLibrarySongType.ActivityLiterals[];
  energy: number & typia.tags.Minimum<0> & typia.tags.Maximum<10>;
  description: string;
  tagline: string;
  year?: number & typia.tags.Minimum<0>;
  catalogStatus: InkibraRecordlessLibrarySongType.CatalogStatus;
  orderedSectionFields: InkibraRecordlessLibrarySongType.Section[];
  transitions: InkibraRecordlessLibrarySongType.Transition[];
  duration: number & typia.tags.Minimum<0>;
  bpm: number & typia.tags.Minimum<0>;
  version: 1;
  type: typeof InkibraRecordlessLibrarySongType.typename;
  modified: string & typia.tags.Format<'date-time'>;
  created: string & typia.tags.Format<'date-time'>;
  deleted?: string & typia.tags.Format<'date-time'>;
}>;
export enum EnergyCurveRequestRelativeEnergy {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
}
export namespace InkibraRecordlessLibrarySongType {
  export type ActivityLiterals =
    | 'Clubbing'
    | 'Party'
    | 'Cardio'
    | 'Aerobics'
    | 'HIIT'
    | 'Sports'
    | 'Strength Training'
    | 'Workout'
    | 'Driving'
    | 'Walking'
    | 'Stretching'
    | 'Leisure'
    | 'Studying'
    | 'Yoga'
    | 'Meditation'
    | 'Dancing'
    | 'Romance'
    | 'Running'
    | 'Cycling'
    | 'House Work'
    | 'Working'
    | 'Sleeping'
    | 'Shopping'
    | 'Socializing'
    | 'Gaming'
    | 'Hiking'
    | 'Traveling'
    | 'Skiing';
  export type GenreLiterals =
    | 'Hip-Hop/Rap'
    | 'Amapiano'
    | 'Samba'
    | 'Pop'
    | 'Rock'
    | 'Psychedelic Rock'
    | 'Bass (EDM)'
    | 'Dubstep (EDM)'
    | 'Trap (EDM)'
    | 'Future Bass (EDM)'
    | 'Country'
    | 'Reggaeton'
    | 'R&B/Soul'
    | 'Alternative'
    | 'Neo Soul'
    | 'House (EDM)'
    | 'Deep House (EDM)'
    | 'Progressive House (EDM)'
    | 'Hip House (EDM)'
    | 'Tropical House (EDM)'
    | 'Hardstyle (EDM)'
    | 'Tech House (EDM)'
    | 'G-House (EDM)'
    | 'UK Drill (Rap)'
    | 'NY Drill (Rap)'
    | 'Drill (Rap)'
    | 'Chicago Drill (Rap)'
    | 'Trap (Rap)'
    | 'Techno (EDM)'
    | 'Latin'
    | 'Jazz'
    | 'Classical'
    | 'Reggae'
    | 'Blues'
    | 'Metal'
    | 'Folk'
    | 'Punk'
    | 'Indie'
    | 'EDM'
    | 'Dance'
    | 'Funk'
    | 'Disco'
    | 'Gospel'
    | 'Ska'
    | 'New Age'
    | 'World'
    | 'Holiday'
    | 'Children'
    | 'Acoustic'
    | 'Ambient'
    | 'Cinematic'
    | 'Instrumental'
    | 'Electronic';

  export type KeyLiterals =
    | '1A'
    | '2A'
    | '3A'
    | '4A'
    | '5A'
    | '6A'
    | '7A'
    | '8A'
    | '9A'
    | '10A'
    | '11A'
    | '12A'
    | '1B'
    | '2B'
    | '3B'
    | '4B'
    | '5B'
    | '6B'
    | '7B'
    | '8B'
    | '9B'
    | '10B'
    | '11B'
    | '12B';

  export type MoodLiterals =
    | 'Happy'
    | 'Sad'
    | 'Energetic'
    | 'Calm'
    | 'Intense'
    | 'Playful'
    | 'Emotional'
    | 'Seductive'
    | 'Upbeat'
    | 'Laid Back'
    | 'Epic'
    | 'Sensual'
    | 'Obsessive'
    | 'Silly'
    | 'Triumphant'
    | 'Sorrowful'
    | 'Hopeful'
    | 'Nostalgic'
    | 'Eerie'
    | 'Euphoric'
    | 'Intimate'
    | 'Cinematic'
    | 'Light'
    | 'Intense'
    | 'Dark'
    | 'Competitive'
    | 'Murderous'
    | 'Haunting'
    | 'Serious'
    | 'Celebratory'
    | 'Sexy'
    | 'Violent'
    | 'Ominous'
    | 'Introspective'
    | 'Confident'
    | 'Assertive'
    | 'Dark'
    | 'Relaxed'
    | 'Romantic'
    | 'Melancholic'
    | 'Angry'
    | 'Empowering'
    | 'Peaceful'
    | 'Uplifting'
    | 'Reflective'
    | 'Dreamy'
    | 'Mysterious'
    | 'Nostalgic'
    | 'Anxious'
    | 'Exciting'
    | 'Soothing'
    | 'Fun'
    | 'Passionate'
    | 'Inspirational'
    | 'Motivational'
    | 'Aggressive'
    | 'Chill'
    | 'Funky'
    | 'Groovy'
    | 'Haunting'
    | 'Lively'
    | 'Serene';
  export type Section = Readonly<{
    id: Brand<'nkrcue'>;
    name: string;
    startTime: number;
    energy: number;
    type: 'nkrcue';
  }>;

  export namespace Section {
    export type CANNOT_FIND_SECTION_BY_ID_FAILURE = ErrorDescriptor<
      'CANNOT_FIND_SECTION_BY_ID',
      'The requested song section was not found in the song.',
      { sectionId: Section['id'] }
    >;
    export type NO_SECTION_AT_INDEX_FAILURE = ErrorDescriptor<
      'NO_SECTION_AT_INDEX',
      `No section at index ${number}`,
      {}
    >;
    export type CANNOT_MOVE_START_TIME_OF_FIRST_SECTION_BEFORE_ZERO_FAILURE =
      ErrorDescriptor<
        'CANNOT_MOVE_START_TIME_OF_FIRST_SECTION_BEFORE_ZERO',
        'Cannot move start time of first section before zero',
        Section
      >;

    export type CANNOT_MOVE_SECTION_BEFORE_PREVIOUS_SECTION_FAILURE =
      ErrorDescriptor<
        'CANNOT_MOVE_SECTION_BEFORE_PREVIOUS_SECTION',
        'Cannot move section before previous section',
        Section
      >;

    export type CANNOT_MOVE_SECTION_PAST_NEXT_SECTION_FAILURE = ErrorDescriptor<
      'CANNOT_MOVE_SECTION_PAST_NEXT_SECTION',
      'Cannot move section past next section',
      Section
    >;

    export type CANNOT_MOVE_SECTION_PAST_END_OF_SONG_FAILURE = ErrorDescriptor<
      'CANNOT_MOVE_SECTION_PAST_END_OF_SONG',
      'Cannot move section past end of song',
      Section
    >;

    export type CANNOT_REMOVE_FIRST_SECTION_FAILURE = ErrorDescriptor<
      'CANNOT_REMOVE_FIRST_SECTION',
      'Cannot remove first section',
      {}
    >;

    export type CANNOT_REMOVE_LAST_SECTION_FAILURE = ErrorDescriptor<
      'CANNOT_REMOVE_LAST_SECTION',
      'Cannot remove last section',
      {}
    >;
  }

  export type Transition = Readonly<{
    id: Brand<'nkrtrn'>;
    score: number;
    numberOfVotes: number;
    fadeOutTrackTimeOffset: number;
    crossfadeDuration: number;
    crossfadeOffset: number;
    sourceSectionFieldId: Section['id'];
    targetSectionFieldId: Section['id'];
    type: 'nkrtrn';
  }>;

  export namespace Transition {
    export type CANNOT_GET_TRANSITION_BY_ID_FAILURE = ErrorDescriptor<
      'CANNOT_GET_TRANSITION_BY_ID',
      'The requested transition was not found in the song.',
      { transitionId: Transition['id'] }
    >;
    type CrossfadeMoveErrorCondition =
      | 'BEFORE_SOURCE_SECTION_START'
      | 'AFTER_SOURCE_SECTION_NEXT_SECTION'
      | 'AFTER_END_OF_SONG'
      | 'BEFORE_TARGET_SECTION_PREVIOUS_SECTION'
      | 'BEFORE_START_OF_SONG'
      | 'AFTER_END_OF_TARGET_SECTION';
    export type CANNOT_MOVE_CROSSFADE_PARAM_EARLIER_FAILURE = ErrorDescriptor<
      'CANNOT_MOVE_CROSSFADE_PARAM_EARLIER',
      'Cannot move crossfade param earlier',
      {
        parameter: number;
        condition: CrossfadeMoveErrorCondition;
        fadeOutTrackTimeOffset: number;
      }
    >;

    export type CANNOT_MOVE_CROSSFADE_PARAM_LATER_FAILURE = ErrorDescriptor<
      'CANNOT_MOVE_CROSSFADE_PARAM_LATER',
      'Cannot move crossfade param later',
      {
        parameter: number;
        condition: CrossfadeMoveErrorCondition;
        fadeOutTrackTimeOffset: number;
      }
    >;

    export type CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_EARLIER_FAILURE =
      ErrorDescriptor<
        'CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_EARLIER',
        'Cannot move fade out track time offset before the start of the source section',
        { parameter: number }
      >;

    export type CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_LATER_FAILURE =
      ErrorDescriptor<
        'CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_LATER',
        "Cannot move fade out track time offset after the end of the source section's next section",
        { parameter: number }
      >;
  }

  export enum CatalogStatus {
    DRAFT = 'DRAFT',
    NEEDS_ATTENTION = 'NEEDS_ATTENTION',
    READY_FOR_REVIEW = 'READY_FOR_REVIEW',
    IN_CONTINUOUS_REVIEW = 'IN_CONTINUOUS_REVIEW',
  }

  export namespace CatalogStatus {
    export function toString(status: CatalogStatus) {
      switch (status) {
        case CatalogStatus.DRAFT:
          return 'Draft';
        case CatalogStatus.NEEDS_ATTENTION:
          return 'Needs Attention';
        case CatalogStatus.READY_FOR_REVIEW:
          return 'Ready for Review';
        case CatalogStatus.IN_CONTINUOUS_REVIEW:
          return 'In Continuous Review';
      }
    }
  }

  export type AlbumSlug = Brand<'INKIBRA_RECORDLESS_LIBRARY_SONG_ALBUM_SLUG'>;

  export const typename = 'nkrsong';

  export type Creation = Partial<
    Pick<
      InkibraRecordlessLibrarySongType,
      | 'moodWords'
      | 'activityWords'
      | 'description'
      | 'keywords'
      | 'trackNumber'
      | 'discNumber'
      | 'year'
      | 'tagline'
    >
  > &
    Pick<
      InkibraRecordlessLibrarySongType,
      | 'title'
      | 'artists'
      | 'albumArtist'
      | 'albumTitle'
      | 'duration'
      | 'genres'
      | 'key'
      | 'bpm'
      | 'orderedSectionFields'
      | 'energy'
    >;

  /**
   * The modifications that can be made to a song.
   *
   * TODO: allow modification to the album artist and album title.
   * This will require moving the song assets to the new album path
   * or storing song assets in a flat structure without album paths.
   *
   * TODO: allow bpm modification. this requires also updating the bpm
   * stored in the song section.
   */
  export type Modification = Partial<
    Pick<
      InkibraRecordlessLibrarySongType,
      | 'artists'
      | 'activityWords'
      | 'catalogStatus'
      | 'description'
      | 'discNumber'
      | 'energy'
      | 'genres'
      | 'key'
      | 'keywords'
      | 'moodWords'
      | 'orderedSectionFields'
      | 'transitions'
      | 'tagline'
      | 'trackNumber'
      | 'title'
      | 'year'
    >
  >;

  export type SONG_VALIDATION_FAILURE = ErrorDescriptor<
    'SONG_VALIDATION_FAILURE',
    'Song validation failed',
    {
      field: keyof InkibraRecordlessLibrarySongType;
    }
  >;

  export type INVALID_CATALOG_STATUS_TRANSITION_FAILURE = ErrorDescriptor<
    'INVALID_CATALOG_STATUS_TRANSITION',
    'Invalid catalog status transition',
    {
      from: CatalogStatus;
      to: CatalogStatus;
    }
  >;

  export class RecordlessSongUtil extends CacheableObjectUtil<
    typeof InkibraRecordlessLibrarySongType.typename,
    InkibraRecordlessLibrarySongType['id'],
    InkibraRecordlessLibrarySongType,
    InkibraRecordlessLibrarySongType.Creation,
    never,
    InkibraRecordlessLibrarySongType.Modification,
    SONG_VALIDATION_FAILURE,
    {}
  > {
    public readonly type = typename;
    public static is = typia.createIs<InkibraRecordlessLibrarySongType>();
    public is(data: unknown): data is InkibraRecordlessLibrarySongType {
      return RecordlessSongUtil.is(data);
    }
    protected getValidationErrors(
      data: InkibraRecordlessLibrarySongType,
    ): false | SONG_VALIDATION_FAILURE {
      if (
        !typia.is<InkibraRecordlessLibrarySongType['activityWords']>(
          data.activityWords,
        )
      ) {
        return {
          code: 'SONG_VALIDATION_FAILURE',
          message: 'Song validation failed',
          data: {
            field: 'activityWords',
          },
        } as const;
      }
      if (!typia.is<InkibraRecordlessLibrarySongType['genres']>(data.genres)) {
        return {
          code: 'SONG_VALIDATION_FAILURE',
          message: 'Song validation failed',
          data: {
            field: 'genres',
          },
        } as const;
      }
      if (
        !typia.is<InkibraRecordlessLibrarySongType['moodWords']>(data.moodWords)
      ) {
        return {
          code: 'SONG_VALIDATION_FAILURE',
          message: 'Song validation failed',
          data: {
            field: 'moodWords',
          },
        } as const;
      }
      if (
        !typia.is<InkibraRecordlessLibrarySongType['keywords']>(data.keywords)
      ) {
        return {
          code: 'SONG_VALIDATION_FAILURE',
          message: 'Song validation failed',
          data: {
            field: 'keywords',
          },
        } as const;
      }
      return false;
    }
    protected fromCreateData(
      creation: Creation,
    ): Result<InkibraRecordlessLibrarySongType, never> {
      return ok({
        id: Brand.createId2<'nkrsong'>('nkrsong'),
        title: creation.title,
        albumArtist: creation.albumArtist,
        duration: creation.duration,
        key: creation.key,
        albumTitle: creation.albumTitle,
        artists: creation.artists,
        genres: creation.genres,
        trackNumber: creation.trackNumber,
        discNumber: creation.discNumber,
        keywords: creation.keywords || [],
        moodWords: creation.moodWords || [],
        activityWords: creation.activityWords || [],
        energy: creation.energy,
        description: creation.description || '',
        tagline: creation.tagline || '',
        year: creation.year,
        bpm: creation.bpm,
        slug: `${creation.albumArtist.toLowerCase()}-${creation.albumTitle.toLowerCase()}-${creation.title.toLowerCase()}`,
        catalogStatus: InkibraRecordlessLibrarySongType.CatalogStatus.DRAFT,
        orderedSectionFields: creation.orderedSectionFields,
        transitions: [],
        version: 1,
        type: InkibraRecordlessLibrarySongType.typename,
        modified: new Date().toISOString(),
        created: new Date().toISOString(),
      });
    }
    public updateWithModifications(
      song: InkibraRecordlessLibrarySongType,
      modifications: InkibraRecordlessLibrarySongType.Modification,
    ) {
      return this.modify(song, modifications);
    }
    private sanitizeSlug(slug: string) {
      return slug.replaceAll('/', '-').replaceAll('%', '-');
    }
    public getAlbumSlug(data: InkibraRecordlessLibrarySongType): AlbumSlug {
      return `${this.sanitizeSlug(
        data.albumArtist.toLowerCase(),
      )}-${this.sanitizeSlug(data.albumTitle.toLowerCase())}` as AlbumSlug;
    }
    public getAlbumRelativePath(data: InkibraRecordlessLibrarySongType) {
      return `${this.sanitizeSlug(
        data.albumArtist.toLowerCase(),
      )}/${this.sanitizeSlug(data.albumTitle.toLowerCase())}`;
    }
    public getBestBpmAndEnergyMatch(
      song: InkibraRecordlessLibrarySongType,
      bpmLow: number,
      bpmHigh: number,
      energyLow: number,
      energyHigh: number,
    ): Result<{ bpm: number; energy: number }, false> {
      const defaultMatch =
        song.bpm >= bpmLow &&
        song.bpm <= bpmHigh &&
        song.energy >= energyLow &&
        song.energy <= energyHigh;
      if (defaultMatch) {
        return ok({
          bpm: song.bpm,
          energy: song.energy,
        });
      }
      // see if the song's half time bpm matches with a song_energy_offset
      const halfTimeBpm = song.bpm / 2;
      const halfTimeEnergy = song.energy + 2;
      const halfTimeMatch =
        halfTimeBpm >= bpmLow &&
        halfTimeBpm <= bpmHigh &&
        halfTimeEnergy >= energyLow &&
        halfTimeEnergy <= energyHigh;
      if (halfTimeMatch) {
        return ok({
          bpm: halfTimeBpm,
          energy: halfTimeEnergy,
        });
      }
      // see if the song's double time bpm matches with a song_energy_offset
      const doubleTimeBpm = song.bpm * 2;
      const doubleTimeEnergy = song.energy - 2;
      const doubleTimeMatch =
        doubleTimeBpm >= bpmLow &&
        doubleTimeBpm <= bpmHigh &&
        doubleTimeEnergy >= energyLow &&
        doubleTimeEnergy <= energyHigh;
      if (doubleTimeMatch) {
        return ok({
          bpm: doubleTimeBpm,
          energy: doubleTimeEnergy,
        });
      }
      return err(false);
    }
    public filterByAdjustedBpmAndEnergy(
      songs: InkibraRecordlessLibrarySongType[],
      bpmLow: number,
      bpmHigh: number,
      energyLow: number,
      energyHigh: number,
    ) {
      return songs.filter((song) => {
        const match = this.getBestBpmAndEnergyMatch(
          song,
          bpmLow,
          bpmHigh,
          energyLow,
          energyHigh,
        );
        return match.isOk();
      });
    }
    public assignRelativeEnergyBucketsToSections(
      sections: InkibraRecordlessLibrarySongType.Section[],
    ) {
      const sectionsSortedByEnergy = [...sections].sort((a, b) => {
        return a.energy - b.energy;
      });
      const thirdSize = Math.floor(sectionsSortedByEnergy.length / 3);
      const energyBuckets = {
        low: sectionsSortedByEnergy.slice(0, thirdSize),
        medium: sectionsSortedByEnergy.slice(thirdSize, thirdSize * 2),
        high: sectionsSortedByEnergy.slice(thirdSize * 2),
      };
      return [
        ...energyBuckets.low.map((section) => ({
          section,
          energyLevel: EnergyCurveRequestRelativeEnergy.LOW,
        })),
        ...energyBuckets.medium.map((section) => ({
          section,
          energyLevel: EnergyCurveRequestRelativeEnergy.MEDIUM,
        })),
        ...energyBuckets.high.map((section) => ({
          section,
          energyLevel: EnergyCurveRequestRelativeEnergy.HIGH,
        })),
      ].sort((a, b) => {
        // sort by section index
        return sections.indexOf(a.section) - sections.indexOf(b.section);
      });
    }
    public findFirstSectionIndexWithRelativeEnergy(
      data: InkibraRecordlessLibrarySongType,
      relativeEnergy: EnergyCurveRequestRelativeEnergy,
    ) {
      const sectionsWithEnergyBuckets =
        this.assignRelativeEnergyBucketsToSections(data.orderedSectionFields);
      return sectionsWithEnergyBuckets.findIndex(
        (sectionWithEnergy) => sectionWithEnergy.energyLevel === relativeEnergy,
      );
    }
    public voteForTransitions(
      song: InkibraRecordlessLibrarySongType,
      transitionIds: Transition['id'][],
    ) {
      return this.modify(song, {
        transitions: song.transitions.map((transition) => {
          if (transitionIds.includes(transition.id)) {
            return {
              ...transition,
              score: transition.score + (1 - transition.score) / 2,
            };
          }
          return transition;
        }),
      });
    }
    public setCatalogStatus(
      song: InkibraRecordlessLibrarySongType,
      newCatalogStatus: InkibraRecordlessLibrarySongType.CatalogStatus,
    ): Result<
      InkibraRecordlessLibrarySongType,
      | INVALID_CATALOG_STATUS_TRANSITION_FAILURE
      | SONG_VALIDATION_FAILURE
      | UNHANDLED_VALIDATION_FAILURE
    > {
      // Cannot transition a song to draft
      if (
        newCatalogStatus ===
        InkibraRecordlessLibrarySongType.CatalogStatus.DRAFT
      ) {
        return err({
          code: 'INVALID_CATALOG_STATUS_TRANSITION',
          message: 'Invalid catalog status transition',
          data: {
            from: song.catalogStatus,
            to: newCatalogStatus,
          },
        });
      }
      return this.modify(song, {
        catalogStatus: newCatalogStatus,
      });
    }
    public voteAgainstTransitions(
      song: InkibraRecordlessLibrarySongType,
      transitionIds: Transition['id'][],
    ) {
      return this.modify(song, {
        transitions: song.transitions.map((transition) => {
          if (transitionIds.includes(transition.id)) {
            return {
              ...transition,
              score: transition.score - (1 - transition.score) / 2,
            };
          }
          return transition;
        }),
      });
    }
    public addTransitions(
      song: InkibraRecordlessLibrarySongType,
      transitions: Transition[],
    ) {
      return this.modify(song, {
        transitions: [...song.transitions, ...transitions],
      });
    }
    public getTransitionById(
      song: InkibraRecordlessLibrarySongType,
      transitionId: Transition['id'],
    ): Result<Transition, Transition.CANNOT_GET_TRANSITION_BY_ID_FAILURE> {
      const transition = song.transitions.find(
        (transition) => transition.id === transitionId,
      );
      if (!transition) {
        return err({
          code: 'CANNOT_GET_TRANSITION_BY_ID',
          message: 'The requested transition was not found in the song.',
          data: { transitionId },
        });
      }
      return ok(transition);
    }
    public getSectionById(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<Section, Section.CANNOT_FIND_SECTION_BY_ID_FAILURE> {
      const section = song.orderedSectionFields.find(
        (section) => section.id === sectionId,
      );
      if (!section) {
        return err({
          code: 'CANNOT_FIND_SECTION_BY_ID',
          message: 'The requested song section was not found in the song.',
          data: { sectionId },
        });
      }
      return ok(section);
    }
    public getSectionIndex(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<number, Section.CANNOT_FIND_SECTION_BY_ID_FAILURE> {
      const section = this.getSectionById(song, sectionId);
      if (section.isErr()) {
        return err(section.error);
      }
      const index = song.orderedSectionFields.indexOf(section.value);
      return ok(index);
    }
    public getBeatLength(song: InkibraRecordlessLibrarySongType): number {
      return 60 / song.bpm;
    }
    public getSectionAt(
      song: InkibraRecordlessLibrarySongType,
      index: number,
    ): Result<Section, Section.NO_SECTION_AT_INDEX_FAILURE> {
      const section = song.orderedSectionFields.at(index);
      if (section === undefined) {
        return err({
          code: 'NO_SECTION_AT_INDEX',
          message: `No section at index ${index}`,
          data: {},
        });
      }
      return ok(section);
    }
    public getSectionAfter(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      Section,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const sectionIndexResult = this.getSectionIndex(song, sectionId);
      if (sectionIndexResult.isErr()) {
        return err(sectionIndexResult.error);
      }
      const nextSectionResult = this.getSectionAt(
        song,
        sectionIndexResult.value + 1,
      );
      if (nextSectionResult.isErr()) {
        return err(nextSectionResult.error);
      }
      return ok(nextSectionResult.value);
    }
    public getSectionBefore(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      Section,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const sectionIndexResult = this.getSectionIndex(song, sectionId);
      if (sectionIndexResult.isErr()) {
        return err(sectionIndexResult.error);
      }
      const previousSectionResult = this.getSectionAt(
        song,
        sectionIndexResult.value - 1,
      );
      if (previousSectionResult.isErr()) {
        return err(previousSectionResult.error);
      }
      return ok(previousSectionResult.value);
    }
    public getSectionQuantizedStartTime(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      number,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const section = this.getSectionById(song, sectionId);
      if (section.isErr()) {
        return err(section.error);
      }
      const firstSection = this.getSectionAt(song, 0);
      if (firstSection.isErr()) {
        return err(firstSection.error);
      }
      // If the section is the first section, return the start time
      if (firstSection.value.id === section.value.id) {
        return ok(section.value.startTime);
      }
      const beatLength = this.getBeatLength(song);

      return ok(
        firstSection.value.startTime +
          Math.round(
            (section.value.startTime - firstSection.value.startTime) /
              beatLength,
          ) *
            beatLength,
      );
    }
    public computeOrderedSectionInfo(song: InkibraRecordlessLibrarySongType) {
      const sectionInfo: {
        id: Section['id'];
        name: string;
        startTime: number;
        startBeat: number;
        positionType: 'start' | 'middle' | 'end';
        endTime: number;
      }[] = [];
      const firstSectionResult = this.getSectionAt(song, 0);
      if (firstSectionResult.isErr()) {
        return err(firstSectionResult.error);
      }
      const lastSectionResult = this.getSectionAt(song, -1);
      if (lastSectionResult.isErr()) {
        return err(lastSectionResult.error);
      }
      for (const section of song.orderedSectionFields) {
        const quantizedStartTimeResult = this.getSectionQuantizedStartTime(
          song,
          section.id,
        );
        if (quantizedStartTimeResult.isErr()) {
          return err(quantizedStartTimeResult.error);
        }
        const quantizedEndTimeResult = this.getSectionQuantizedEndTime(
          song,
          section.id,
        );
        if (quantizedEndTimeResult.isErr()) {
          return err(quantizedEndTimeResult.error);
        }

        const beatLength = 60 / song.bpm;
        const startBeat = Math.round(
          (section.startTime - firstSectionResult.value.startTime) / beatLength,
        );

        if (firstSectionResult.value.id === section.id) {
          sectionInfo.push({
            id: section.id,
            name: section.name,
            startTime: quantizedStartTimeResult.value,
            endTime: quantizedEndTimeResult.value,
            startBeat,
            positionType: 'start',
          });
        } else if (lastSectionResult.value.id === section.id) {
          sectionInfo.push({
            id: section.id,
            name: section.name,
            startTime: quantizedStartTimeResult.value,
            endTime: quantizedEndTimeResult.value,
            startBeat,
            positionType: 'end',
          });
        } else {
          sectionInfo.push({
            id: section.id,
            name: section.name,
            startTime: quantizedStartTimeResult.value,
            endTime: quantizedEndTimeResult.value,
            startBeat,
            positionType: 'middle',
          });
        }
      }
      return ok(sectionInfo);
    }

    public computeSectionDuration(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      number,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const sectionWithTimes = this.getSectionById(song, sectionId).andThen(
        (section) =>
          this.getSectionQuantizedStartTime(song, sectionId)
            .map((startTime) => ({
              ...section,
              startTime,
            }))
            .andThen((section) =>
              this.getSectionQuantizedEndTime(song, sectionId).map(
                (endTime) => ({
                  ...section,
                  endTime,
                }),
              ),
            ),
      );
      if (sectionWithTimes.isErr()) {
        return err(sectionWithTimes.error);
      }
      return ok(
        sectionWithTimes.value.endTime - sectionWithTimes.value.startTime,
      );
    }
    public computeSectionTimes(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      { startTime: number; endTime: number; duration: number },
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const sectionWithTimes = this.getSectionById(song, sectionId).andThen(
        (section) =>
          this.getSectionQuantizedStartTime(song, sectionId)
            .map((startTime) => ({
              ...section,
              startTime,
            }))
            .andThen((section) =>
              this.getSectionQuantizedEndTime(song, sectionId).map(
                (endTime) => ({
                  ...section,
                  endTime,
                }),
              ),
            ),
      );
      if (sectionWithTimes.isErr()) {
        return err(sectionWithTimes.error);
      }
      return ok({
        startTime: sectionWithTimes.value.startTime,
        endTime: sectionWithTimes.value.endTime,
        duration:
          sectionWithTimes.value.endTime - sectionWithTimes.value.startTime,
      });
    }

    public validateSectionStartTime(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
      newStartTime: number,
    ): Result<
      true,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
      | Section.CANNOT_MOVE_START_TIME_OF_FIRST_SECTION_BEFORE_ZERO_FAILURE
      | Section.CANNOT_MOVE_SECTION_BEFORE_PREVIOUS_SECTION_FAILURE
      | Section.CANNOT_MOVE_SECTION_PAST_END_OF_SONG_FAILURE
      | Section.CANNOT_MOVE_SECTION_PAST_NEXT_SECTION_FAILURE
    > {
      const firstSection = this.getSectionAt(song, 0);
      if (firstSection.isErr()) {
        return err(firstSection.error);
      }
      const section = this.getSectionById(song, sectionId);
      if (section.isErr()) {
        return err(section.error);
      }
      if (firstSection.value.id === section.value.id) {
        if (newStartTime < -5) {
          // TEST WITH NEGATIVE VALUES
          return err({
            code: 'CANNOT_MOVE_START_TIME_OF_FIRST_SECTION_BEFORE_ZERO',
            message: 'Cannot move start time of first section before zero',
            data: section.value,
          });
        }
      } else {
        // We cannot move the start time of a section before the previous section
        const previousSection = this.getSectionBefore(song, sectionId);
        if (previousSection.isErr()) {
          return err(previousSection.error);
        }
        if (newStartTime <= previousSection.value.startTime) {
          return err({
            code: 'CANNOT_MOVE_SECTION_BEFORE_PREVIOUS_SECTION',
            message: 'Cannot move section before previous section',
            data: section.value,
          });
        }
      }
      // We cannot move the start time of a section past the next section or past the end of the song
      const lastSection = this.getSectionAt(song, -1);
      if (lastSection.isErr()) {
        return err(lastSection.error);
      }
      if (lastSection.value.id === section.value.id) {
        if (newStartTime >= song.duration) {
          return err({
            code: 'CANNOT_MOVE_SECTION_PAST_END_OF_SONG',
            message: 'Cannot move section past end of song',
            data: section.value,
          });
        }
      } else {
        const nextSection = this.getSectionAfter(song, sectionId);
        if (nextSection.isErr()) {
          return err(nextSection.error);
        }
        if (newStartTime >= nextSection.value.startTime) {
          return err({
            code: 'CANNOT_MOVE_SECTION_PAST_NEXT_SECTION',
            message: 'Cannot move section past next section',
            data: section.value,
          });
        }
      }
      return ok(true);
    }
    public updateSectionName(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
      newName: string,
    ) {
      return this.modify(song, {
        orderedSectionFields: song.orderedSectionFields.map((s) => {
          if (s.id === sectionId) {
            return {
              ...s,
              name: newName,
            };
          }
          return s;
        }),
      });
    }
    public updateSectionStartTime(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
      newStartTime: number,
    ) {
      const section = this.getSectionById(song, sectionId);
      if (section.isErr()) {
        return err(section.error);
      }
      const validation = this.validateSectionStartTime(
        song,
        sectionId,
        newStartTime,
      );
      if (validation.isErr()) {
        return err(validation.error);
      }
      return this.modify(song, {
        orderedSectionFields: song.orderedSectionFields.map((s) => {
          if (s.id === sectionId) {
            return {
              ...s,
              startTime: newStartTime,
            };
          }
          return s;
        }),
      });
    }
    public getSectionEndTime(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      number,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const section = this.getSectionById(song, sectionId);
      if (section.isErr()) {
        return err(section.error);
      }
      const lastSection = this.getSectionAt(song, -1);
      if (lastSection.isErr()) {
        return err(lastSection.error);
      }
      if (lastSection.value.id === section.value.id) {
        return ok(song.duration);
      }
      const nextSection = this.getSectionAfter(song, sectionId);
      if (nextSection.isErr()) {
        return err(nextSection.error);
      }
      return ok(nextSection.value.startTime);
    }
    public getSectionQuantizedEndTime(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      number,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const section = this.getSectionById(song, sectionId);
      if (section.isErr()) {
        return err(section.error);
      }
      const lastSection = this.getSectionAt(song, -1);
      if (lastSection.isErr()) {
        return err(lastSection.error);
      }
      if (lastSection.value.id === section.value.id) {
        return ok(song.duration);
      }
      const nextSection = this.getSectionAfter(song, sectionId);
      if (nextSection.isErr()) {
        return err(nextSection.error);
      }
      return this.getSectionQuantizedStartTime(song, nextSection.value.id);
    }
    public getSectionDuration(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ): Result<
      number,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const quantizedStartTimeResult = this.getSectionQuantizedStartTime(
        song,
        sectionId,
      );
      if (quantizedStartTimeResult.isErr()) {
        return err(quantizedStartTimeResult.error);
      }
      const quantizedEndTimeResult = this.getSectionQuantizedEndTime(
        song,
        sectionId,
      );
      if (quantizedEndTimeResult.isErr()) {
        return err(quantizedEndTimeResult.error);
      }
      return ok(quantizedEndTimeResult.value - quantizedStartTimeResult.value);
    }

    public insertNewSectionAtTime(
      song: InkibraRecordlessLibrarySongType,
      startTime: number,
    ) {
      const newSection: Section = {
        id: Brand.createId2<'nkrcue'>('nkrcue'),
        name: 'New Section',
        energy: song.energy, // TODO: later require that the song energy prediction is given when inserting new sections so that we can adjust the energy of the new section
        startTime: startTime,
        type: 'nkrcue',
      };
      return this.modify(song, {
        orderedSectionFields: [...song.orderedSectionFields, newSection].sort(
          (a, b) => a.startTime - b.startTime,
        ),
      });
    }
    public removeSection(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
    ) {
      const firstSection = this.getSectionAt(song, 0);
      if (firstSection.isErr()) {
        return err(firstSection.error);
      }
      const lastSection = this.getSectionAt(song, -1);
      if (lastSection.isErr()) {
        return err(lastSection.error);
      }
      if (firstSection.value.id === sectionId) {
        return err(
          ErrorDescriptor.create<Section.CANNOT_REMOVE_FIRST_SECTION_FAILURE>(
            'CANNOT_REMOVE_FIRST_SECTION',
            'Cannot remove first section',
            {},
          ),
        );
      }
      if (lastSection.value.id === sectionId) {
        return err(
          ErrorDescriptor.create<Section.CANNOT_REMOVE_LAST_SECTION_FAILURE>(
            'CANNOT_REMOVE_LAST_SECTION',
            'Cannot remove last section',
            {},
          ),
        );
      }
      return this.modify(song, {
        orderedSectionFields: song.orderedSectionFields.filter(
          (section) => section.id !== sectionId,
        ),
        transitions: song.transitions.filter(
          (transition) =>
            transition.sourceSectionFieldId !== sectionId &&
            transition.targetSectionFieldId !== sectionId,
        ),
      });
    }
    public draftTransition(
      data: Readonly<InkibraRecordlessLibrarySongType>,
      sourceSectionFieldId: InkibraRecordlessLibrarySongType.Section['id'],
      targetSectionFieldId: InkibraRecordlessLibrarySongType.Section['id'],
      crossfadeOffset = 0,
      crossfadeDuration = 0.5,
      fadeOutTrackTimeOffset = 0,
    ): Result<
      InkibraRecordlessLibrarySongType.Transition,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
      | Transition.CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_EARLIER_FAILURE
      | Transition.CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_LATER_FAILURE
      | Transition.CANNOT_MOVE_CROSSFADE_PARAM_EARLIER_FAILURE
      | Transition.CANNOT_MOVE_CROSSFADE_PARAM_LATER_FAILURE
    > {
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );
      const validateFadeOutTrackTimeOffsetResult =
        songUtil.validateFadeOutTrackTimeOffset(
          data,
          sourceSectionFieldId,
          fadeOutTrackTimeOffset,
        );
      if (validateFadeOutTrackTimeOffsetResult.isErr()) {
        return err(validateFadeOutTrackTimeOffsetResult.error);
      }

      const validateCrossfadeOffsetResult = songUtil.validateCrossfadeParameter(
        data,
        sourceSectionFieldId,
        targetSectionFieldId,
        crossfadeOffset,
        fadeOutTrackTimeOffset,
      );
      if (validateCrossfadeOffsetResult.isErr()) {
        return err(validateCrossfadeOffsetResult.error);
      }

      const validateCrossfadeDurationResult =
        songUtil.validateCrossfadeParameter(
          data,
          sourceSectionFieldId,
          targetSectionFieldId,
          crossfadeOffset + crossfadeDuration,
          fadeOutTrackTimeOffset,
        );
      if (validateCrossfadeDurationResult.isErr()) {
        return err(validateCrossfadeDurationResult.error);
      }

      return ok({
        id: Brand.createId2<
          InkibraRecordlessLibrarySongType.Transition['type']
        >('nkrtrn'),
        score: 0,
        numberOfVotes: 0,
        fadeOutTrackTimeOffset,
        crossfadeDuration,
        crossfadeOffset,
        sourceSectionFieldId,
        targetSectionFieldId,
        type: 'nkrtrn',
      });
    }
    /**
     * Validates the crossfade parameter for transitions between section fields.
     *
     * The crossfade parameter represents the starting point of the crossfade effect
     * relative to the beginning of the transition. This value is crucial for ensuring
     * that the crossfade between sections happens at an appropriate time,
     * considering the duration of both the source and destination sections.
     *
     * Constraints:
     * 1. The crossfade parameter cannot be less than the negative of the source section's duration
     *    plus the fade-out track time parameter. This ensures that the crossfade does not start
     *    before the source section has had enough playtime, accounting for any fade-out effects.
     *
     * 2. It cannot be more than the duration of the section following the source section (if such a section
     *    exists) plus the fade-out track time parameter. This guarantees the crossfade will not
     *    extend beyond the end of the next section, maintaining a smooth transition.
     *
     * 3. The parameter cannot be less than the negative duration of the destination section's
     *    preceding section (if such a section exists) or zero. This constraint prevents the
     *    crossfade from starting before an appropriate entry point in the transition.
     *
     * 4. It cannot exceed the duration of the destination section. This prevents the crossfade
     *    from going beyond the length of the destination section, ensuring the content
     *    of that section is not inadvertently trimmed or missed.
     *
     * @param value The new crossfade parameter value, adhering to the constraints mentioned above.
     *              Specified in seconds (or appropriate unit of time). This determines when, relative to
     *              the transition's start, the crossfade effect will commence.
     */
    public validateCrossfadeParameter(
      song: InkibraRecordlessLibrarySongType,
      sourceSectionFieldId: Section['id'],
      targetSectionFieldId: Section['id'],
      crossfadeParameter: number,
      fadeOutTrackTimeOffset: number,
    ): Result<
      true,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
      | Transition.CANNOT_MOVE_CROSSFADE_PARAM_EARLIER_FAILURE
      | Transition.CANNOT_MOVE_CROSSFADE_PARAM_LATER_FAILURE
    > {
      const sourceSectionWithDurationAndEndTimeResult = this.getSectionById(
        song,
        sourceSectionFieldId,
      ).andThen((section) => {
        return this.computeSectionDuration(song, section.id).andThen(
          (duration) => {
            return this.getSectionQuantizedEndTime(song, section.id).map(
              (endTime) => {
                return { section, duration, endTime };
              },
            );
          },
        );
      });
      if (sourceSectionWithDurationAndEndTimeResult.isErr()) {
        return err(sourceSectionWithDurationAndEndTimeResult.error);
      }
      // We cannot place the transition before the start of the source section
      if (
        crossfadeParameter <
        -sourceSectionWithDurationAndEndTimeResult.value.duration +
          crossfadeParameter
      ) {
        return err({
          code: 'CANNOT_MOVE_CROSSFADE_PARAM_EARLIER',
          message: 'Cannot move crossfade param earlier',
          data: {
            parameter: crossfadeParameter,
            fadeOutTrackTimeOffset,
            condition: 'BEFORE_SOURCE_SECTION_START',
          },
        });
      }
      // We cannot place the transition after the end of the source section's next section (if it exists)
      const sectionAfterSourceSectionWithEndTimeResult = this.getSectionAfter(
        song,
        sourceSectionFieldId,
      ).andThen((section) => {
        return this.getSectionQuantizedEndTime(song, section.id).andThen(
          (endTime) => {
            return ok({ section, endTime });
          },
        );
      });
      if (
        sectionAfterSourceSectionWithEndTimeResult.isOk() &&
        crossfadeParameter >
          sectionAfterSourceSectionWithEndTimeResult.value.endTime -
            sourceSectionWithDurationAndEndTimeResult.value.endTime -
            fadeOutTrackTimeOffset
      ) {
        return err({
          code: 'CANNOT_MOVE_CROSSFADE_PARAM_LATER',
          message: 'Cannot move crossfade param later',
          data: {
            parameter: crossfadeParameter,
            fadeOutTrackTimeOffset,
            condition: 'AFTER_SOURCE_SECTION_NEXT_SECTION',
          },
        });
      }
      // If the source section is the last section, we cannot place the transition after the end of the song
      if (
        sectionAfterSourceSectionWithEndTimeResult.isErr() &&
        crossfadeParameter < fadeOutTrackTimeOffset
      ) {
        return err({
          code: 'CANNOT_MOVE_CROSSFADE_PARAM_LATER',
          message: 'Cannot move crossfade param later',
          data: {
            parameter: crossfadeParameter,
            fadeOutTrackTimeOffset,
            condition: 'AFTER_END_OF_SONG',
          },
        });
      }
      // We cannot place the transition before the start of the target section's previous section (if it exists)
      const sectionBeforeTargetSectionWithEndTimeResult = this.getSectionBefore(
        song,
        targetSectionFieldId,
      ).andThen((section) => {
        return this.getSectionQuantizedEndTime(song, section.id).andThen(
          (endTime) => {
            return ok({ section, endTime });
          },
        );
      });
      if (
        sectionBeforeTargetSectionWithEndTimeResult.isOk() &&
        crossfadeParameter >
          sectionBeforeTargetSectionWithEndTimeResult.value.endTime
      ) {
        return err({
          code: 'CANNOT_MOVE_CROSSFADE_PARAM_EARLIER',
          message: 'Cannot move crossfade param earlier',
          data: {
            parameter: crossfadeParameter,
            fadeOutTrackTimeOffset,
            condition: 'BEFORE_TARGET_SECTION_PREVIOUS_SECTION',
          },
        });
      }
      // Since the destination section is the first section, we cannot place the transition before the start of the song
      if (
        sectionBeforeTargetSectionWithEndTimeResult.isErr() &&
        crossfadeParameter < fadeOutTrackTimeOffset
      ) {
        return err({
          code: 'CANNOT_MOVE_CROSSFADE_PARAM_EARLIER',
          message: 'Cannot move crossfade param earlier',
          data: {
            parameter: crossfadeParameter,
            fadeOutTrackTimeOffset,
            condition: 'BEFORE_START_OF_SONG',
          },
        });
      }
      // We cannot place the transition after the end of the target section
      const targetSectionDurationResult = this.getSectionById(
        song,
        targetSectionFieldId,
      ).andThen((section) => {
        return this.computeSectionDuration(song, section.id);
      });
      if (targetSectionDurationResult.isErr()) {
        return err(targetSectionDurationResult.error);
      }
      if (crossfadeParameter > targetSectionDurationResult.value) {
        return err({
          code: 'CANNOT_MOVE_CROSSFADE_PARAM_LATER',
          message: 'Cannot move crossfade param later',
          data: {
            parameter: crossfadeParameter,
            fadeOutTrackTimeOffset,
            condition: 'AFTER_END_OF_TARGET_SECTION',
          },
        });
      }
      return ok(true);
    }
    public validateFadeOutTrackTimeOffset(
      song: InkibraRecordlessLibrarySongType,
      sourceSectionFieldId: Section['id'],
      fadeOutTrackTimeOffset: number,
    ): Result<
      true,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
      | Transition.CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_EARLIER_FAILURE
      | Transition.CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_LATER_FAILURE
    > {
      const sourceSectionResult = this.getSectionById(
        song,
        sourceSectionFieldId,
      );
      if (sourceSectionResult.isErr()) {
        return err(sourceSectionResult.error);
      }
      const sourceSectionEndTimeResult = this.getSectionQuantizedEndTime(
        song,
        sourceSectionFieldId,
      );
      if (sourceSectionEndTimeResult.isErr()) {
        return err(sourceSectionEndTimeResult.error);
      }
      const sourceSectionDurationResult = this.computeSectionDuration(
        song,
        sourceSectionFieldId,
      );
      if (sourceSectionDurationResult.isErr()) {
        return err(sourceSectionDurationResult.error);
      }
      // We cannot place the transition before the start of the source section
      // or after the end of the source section's next section
      if (fadeOutTrackTimeOffset > sourceSectionDurationResult.value) {
        return err({
          code: 'CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_EARLIER',
          message:
            'Cannot move fade out track time offset before the start of the source section',
          data: { parameter: fadeOutTrackTimeOffset },
        });
      }
      const sectionAfterSourceSectionEndTimeResult = this.getSectionAfter(
        song,
        sourceSectionFieldId,
      ).andThen((section) => {
        return this.getSectionEndTime(song, section.id);
      });
      if (
        sectionAfterSourceSectionEndTimeResult.isOk() &&
        fadeOutTrackTimeOffset >
          sectionAfterSourceSectionEndTimeResult.value -
            sourceSectionEndTimeResult.value
      ) {
        // We cannot place the transition after the end of the source section's next section
        console.debug(
          "we cannot place the transition after the end of the source section's next section",
        );
        return err(
          ErrorDescriptor.create<Transition.CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_LATER_FAILURE>(
            'CANNOT_MOVE_FADE_OUT_TRACK_TIME_OFFSET_LATER',
            "Cannot move fade out track time offset after the end of the source section's next section",
            { parameter: fadeOutTrackTimeOffset },
          ),
        );
      }
      return ok(true);
    }
    public filterTransitionsForSection(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
      type: 'source' | 'target' | 'both',
    ) {
      return song.transitions.filter((transition) => {
        if (type === 'source') {
          return transition.sourceSectionFieldId === sectionId;
        }
        if (type === 'target') {
          return transition.targetSectionFieldId === sectionId;
        }
        return (
          transition.sourceSectionFieldId === sectionId ||
          transition.targetSectionFieldId === sectionId
        );
      });
    }
    public computeDataWindowForSection(
      song: InkibraRecordlessLibrarySongType,
      sectionId: Section['id'],
      data: number[],
    ): Result<
      number[],
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const sectionStartTimeResult = this.getSectionQuantizedStartTime(
        song,
        sectionId,
      );
      if (sectionStartTimeResult.isErr()) {
        return err(sectionStartTimeResult.error);
      }
      const sectionEndTimeResult = this.getSectionQuantizedEndTime(
        song,
        sectionId,
      );
      if (sectionEndTimeResult.isErr()) {
        return err(sectionEndTimeResult.error);
      }
      const dataWindowStart = Math.floor(
        (sectionStartTimeResult.value / song.duration) * data.length,
      );
      const dataWindowEnd = Math.ceil(
        (sectionEndTimeResult.value / song.duration) * data.length,
      );
      return ok(data.slice(dataWindowStart, dataWindowEnd));
    }
    public updateEnergyFromEnergyData(
      song: InkibraRecordlessLibrarySongType,
      energyData: number[],
    ): Result<
      InkibraRecordlessLibrarySongType,
      | Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | Section.NO_SECTION_AT_INDEX_FAILURE
      | SONG_VALIDATION_FAILURE
      | UNHANDLED_VALIDATION_FAILURE
    > {
      const sectionEnergies: { [key: string]: number } = {};
      for (const section of song.orderedSectionFields) {
        const dataWindowResult = this.computeDataWindowForSection(
          song,
          section.id,
          energyData,
        );
        if (dataWindowResult.isErr()) {
          return err(dataWindowResult.error);
        }
        const energy = dataWindowResult.value.reduce(
          (acc, val) => acc + val,
          0,
        );
        sectionEnergies[section.id] = energy / dataWindowResult.value.length;
      }
      return this.modify(song, {
        orderedSectionFields: song.orderedSectionFields.map((section) => {
          return {
            ...section,
            energy: sectionEnergies[section.id] ?? section.energy,
          };
        }),
      });
    }
  }
}
