/** @jsxImportSource @emotion/react */

import { CacheContext, MimeType, TypedFormData } from '@inkibra/api-base';
import * as musicMetadata from 'music-metadata-browser';
import { useContext, useEffect, useState } from 'react';

import { css } from '@emotion/react';
import { Brand } from '@inkibra/observable-cache';
import { EditableText } from '@inkibra/recordless.ux';
import typia from 'typia';
import {
  CreateCatalogSong,
  InkibraRecordlessLibraryApiFetcherRegistry,
} from '../api';
import { InkibraRecordlessLibrarySongType } from '../type-song';

const justifyRight = css({
  justifySelf: 'end',
});

type MixedInKeyCuePoints = {
  algorithm: 14;
  cues: {
    name: string;
    time: number;
  }[];
  source: 'mixedinkey';
};

type MixedInKeyEnergy = {
  algorithm: 13;
  energyLevel: InkibraRecordlessLibrarySongType['energy'];
  source: 'mixedinkey';
};

type MixedInKeyKey = {
  algorithm: 94;
  key: InkibraRecordlessLibrarySongType.KeyLiterals;
  source: 'mixedinkey';
};

type MixedInKeyBeatGrid = {
  algorithm: 12;
  beats: number[];
  tempo: number;
  source: 'mixedinkey';
};

type SongDetails = {
  audio: File;
  albumArtURL?: string;
  audioURL?: string;
  songTitle?: string;
  songAlbumArtist?: string;
  songArtists?: string[];
  songAlbum?: string;
  songDuration?: number;
  songBpm?: number;
  songEnergy?: InkibraRecordlessLibrarySongType['energy'];
  songGenres?: InkibraRecordlessLibrarySongType['genres'];
  songKey?: InkibraRecordlessLibrarySongType['key'];
  songDescription?: string;
  songKeywords?: string[];
  songTrackNumber?: number;
  songDiscNumber?: number;
  songYear?: number;
  songCuePoints?: MixedInKeyCuePoints['cues'];
  validationError?: string;
  missingInformation?: string;
  uploading: boolean;
};

export const SongAddDialog = () => {
  const cache = useContext(CacheContext)();
  const [songDetails, setSongDetails] = useState<SongDetails[]>([]);
  const [requestUpload, setRequestUpload] = useState<boolean>(false);
  const uploading = songDetails.some((songDetail) => songDetail.uploading);

  useEffect(() => {
    return () => {
      // release URL resources
      songDetails.forEach((songDetail) => {
        if (songDetail.audioURL) URL.revokeObjectURL(songDetail.audioURL);
        if (songDetail.albumArtURL) URL.revokeObjectURL(songDetail.albumArtURL);
      });
    };
  }, []);

  useEffect(() => {
    if (!requestUpload) {
      return;
    }
    // make sure we are not already uploading a song
    if (uploading) {
      return;
    }
    const songDetail = songDetails.find(
      (songDetail) =>
        !songDetail.uploading &&
        songDetail.validationError === undefined &&
        songDetail.missingInformation === undefined,
    );
    if (songDetail === undefined) {
      setRequestUpload(false);
      return;
    }
    const typedFormData = new TypedFormData(
      CreateCatalogSong.Route.fileInputDescription,
    );
    console.log('Uploading song', songDetail.audio);
    typedFormData.set('audio', songDetail.audio);
    songDetail.uploading = true;
    setSongDetails([...songDetails]);

    if (
      !songDetail.songTitle ||
      !songDetail.songAlbumArtist ||
      !songDetail.songAlbum ||
      !songDetail.songDuration ||
      !songDetail.songGenres ||
      !songDetail.songKey ||
      !songDetail.songArtists ||
      !songDetail.songBpm ||
      !songDetail.songEnergy ||
      !songDetail.songCuePoints
    ) {
      songDetail.validationError = 'Missing required information';
      songDetail.uploading = false;
      setSongDetails([...songDetails]);
      return;
    }
    InkibraRecordlessLibraryApiFetcherRegistry.get('createCatalogSong')
      .fn({
        body: {
          title: songDetail.songTitle,
          albumArtist: songDetail.songAlbumArtist,
          artists: songDetail.songArtists,
          albumTitle: songDetail.songAlbum,
          duration: songDetail.songDuration,
          bpm: songDetail.songBpm,
          genres: songDetail.songGenres,
          key: songDetail.songKey,
          description: songDetail.songDescription,
          orderedSectionFields: songDetail.songCuePoints?.map((cuePoint) => {
            return {
              id: Brand.createId2<'nkrcue'>('nkrcue'),
              startTime: cuePoint.time / 1000, // convert milliseconds to seconds
              energy: songDetail.songEnergy || 0,
              name: cuePoint.name,
              type: 'nkrcue',
            } satisfies InkibraRecordlessLibrarySongType.Section;
          }),
          keywords: songDetail.songKeywords,
          trackNumber: songDetail.songTrackNumber,
          discNumber: songDetail.songDiscNumber,
          year: songDetail.songYear,
          energy: songDetail.songEnergy,
        },
        files: typedFormData,
        pathParams: {},
        pathQuery: {},
      })
      .then((res) => {
        if (res.type === 'Ok') {
          cache.input.next(res.value);
          // release URL resources
          if (songDetail.audioURL) URL.revokeObjectURL(songDetail.audioURL);
          if (songDetail.albumArtURL)
            URL.revokeObjectURL(songDetail.albumArtURL);
          setSongDetails(songDetails.filter((sd) => sd !== songDetail));
        } else {
          songDetail.validationError = `${res.error.message}: ${JSON.stringify(
            res.error.data,
          )}`;
          songDetail.uploading = false;
          setSongDetails([...songDetails]);
        }
      });
  }, [requestUpload, songDetails]);
  return (
    <div>
      <input
        disabled={uploading}
        accept={MimeType.AUDIO_MP4}
        multiple
        type="file"
        onChange={async (e) => {
          if (e.target.files) {
            for (const file of Array.from(e.target.files)) {
              const metadata = await musicMetadata.parseBlob(file);
              console.log('Got Metadata', metadata);

              const iTunesTags = metadata.native.iTunes;
              let maybeCuePoints: MixedInKeyCuePoints | undefined;
              let maybeEnergy: MixedInKeyEnergy | undefined;
              let maybeKey: MixedInKeyKey | undefined;
              let maybeBeatGrid: MixedInKeyBeatGrid | undefined;
              iTunesTags?.forEach((tag) => {
                switch (tag.id) {
                  case '----:com.mixedinkey.mixedinkey:cuepoints':
                    try {
                      maybeCuePoints =
                        typia.json.assertParse<MixedInKeyCuePoints>(
                          atob(tag.value),
                        );
                    } catch (e) {
                      console.warn(
                        'Failed to parse Mixed In Key cue points',
                        e,
                      );
                    }
                    break;
                  case '----:com.mixedinkey.mixedinkey:energy':
                    try {
                      maybeEnergy = typia.json.assertParse<MixedInKeyEnergy>(
                        atob(tag.value),
                      );
                    } catch (e) {
                      console.warn('Failed to parse Mixed In Key energy', e);
                    }
                    break;
                  case '----:com.mixedinkey.mixedinkey:key':
                    try {
                      maybeKey = typia.json.assertParse<MixedInKeyKey>(
                        atob(tag.value),
                      );
                    } catch (e) {
                      console.warn('Failed to parse Mixed In Key key', e);
                    }
                    break;
                  case '----:com.mixedinkey.mixedinkey:beatgrid':
                    try {
                      maybeBeatGrid =
                        typia.json.assertParse<MixedInKeyBeatGrid>(
                          atob(tag.value),
                        );
                    } catch (e) {
                      console.warn('Failed to parse Mixed In Key beat grid', e);
                    }
                    break;
                }
              });

              console.log('Got cue points, energy, and key, beatgrid', {
                maybeCuePoints,
                maybeEnergy,
                maybeKey,
                maybeBeatGrid,
              });

              const albumArtBuffer = metadata.common.picture?.[0]?.data;
              // convert album art buffer to url
              const albumArtURL = albumArtBuffer
                ? URL.createObjectURL(new Blob([albumArtBuffer]))
                : undefined;

              setSongDetails((existingSongDetails) => {
                return [
                  ...existingSongDetails,
                  {
                    audio: file,
                    albumArtURL,
                    audioURL: URL.createObjectURL(file),
                    songTitle:
                      metadata.common.title ||
                      metadata.common.titlesort ||
                      file.name,
                    songAlbumArtist:
                      metadata.common.albumartist ||
                      metadata.common.artist ||
                      metadata.common.artistsort,
                    songArtist: metadata.common.artist,
                    songArtists: metadata.common.artists,
                    songEnergy: maybeEnergy?.energyLevel,
                    songAlbum:
                      metadata.common.album || metadata.common.albumsort,
                    songDuration: metadata.format.duration,
                    songBpm: maybeBeatGrid?.tempo,
                    songGenres:
                      metadata.common.genre?.filter((genre) =>
                        typia.is<InkibraRecordlessLibrarySongType.GenreLiterals>(
                          genre,
                        ),
                      ) || [],
                    songCuePoints: maybeCuePoints?.cues,
                    songKey: maybeKey?.key,
                    songDescription: metadata.common.description?.join('\n'),
                    songKeywords: metadata.common.keywords,
                    songTrackNumber: metadata.common.track.no || 0,
                    songDiscNumber: metadata.common.disk.no || 0,
                    songYear: metadata.common.year,
                    missingInformation:
                      !maybeCuePoints ||
                      !maybeEnergy ||
                      !maybeKey ||
                      !maybeBeatGrid
                        ? 'Missing Mixed In Key information'
                        : !albumArtURL
                          ? 'Missing album art'
                          : undefined,
                    uploading: false,
                  },
                ];
              });
            }
          }
        }}
      />
      {songDetails.length > 0 && (
        <button
          disabled={uploading}
          onClick={async () => {
            // validate all songs have a title, album artist, and album before uploading
            const invalidSongs = songDetails.filter(
              (songDetail) =>
                !songDetail.songTitle ||
                !songDetail.songAlbumArtist ||
                !songDetail.songAlbum ||
                !songDetail.songDuration,
            );
            if (invalidSongs.length > 0) {
              invalidSongs.forEach((invalidSong) => {
                invalidSong.validationError =
                  'Song must have a title, album artist, and album.';
              });
              setSongDetails([...songDetails]);
              return;
            }
            setRequestUpload(true);
          }}
        >
          Upload Songs
        </button>
      )}
      {songDetails.map((songDetail, index) => {
        return (
          <div
            key={index}
            css={{
              label: 'song-detail-item',
              display: 'grid',
              gridTemplate: `
              "audio audio" auto
              "song-validation-error song-validation-error" auto
              "album-art song-information" auto / 110px 1fr
            `,
            }}
          >
            <audio
              css={{ label: 'song-audio', gridArea: 'audio' }}
              src={songDetail.audioURL}
              controls
            />
            <span
              css={{
                label: 'song-validation-error',
                gridArea: 'song-validation-error',
              }}
            >
              {songDetail.missingInformation}
              {songDetail.validationError}
            </span>
            <img
              css={{
                label: 'album-art',
                gridArea: 'album-art',
                width: '100px',
                height: '100px',
                objectFit: 'contain',
              }}
              src={songDetail.albumArtURL}
              alt="Album Art"
            />
            <div
              css={{
                label: 'song-detail-information',
                gridArea: 'song-information',
                display: 'grid',
                gridTemplateColumns: 'max-content 0.8fr',
                gap: '1em',
              }}
            >
              <label css={justifyRight}>Title: </label>
              <EditableText
                forceEditing
                value={songDetail.songTitle || ''}
                onValueChange={(newSongTitle) => {
                  setSongDetails((existingSongDetails) => {
                    const newSongDetails = [...existingSongDetails];
                    if (newSongDetails[index] === undefined) {
                      return existingSongDetails;
                    }
                    newSongDetails[index].songTitle = newSongTitle;
                    newSongDetails[index].validationError = undefined;
                    return newSongDetails;
                  });
                }}
              />
              <label css={justifyRight}>Artists: </label>
              <EditableText
                forceEditing
                value={songDetail.songArtists?.join(', ') || ''}
                onValueChange={(newSongArtist) => {
                  setSongDetails((existingSongDetails) => {
                    const newSongDetails = [...existingSongDetails];
                    if (newSongDetails[index] === undefined) {
                      return existingSongDetails;
                    }
                    newSongDetails[index].songArtists = newSongArtist
                      .split(',')
                      .map((artist) => artist.trim());
                    newSongDetails[index].validationError = undefined;
                    return newSongDetails;
                  });
                }}
              />

              <label css={justifyRight}>Album Artist: </label>
              <EditableText
                forceEditing
                value={songDetail.songAlbumArtist || ''}
                onValueChange={(newSongAlbumArtist) => {
                  setSongDetails((existingSongDetails) => {
                    const newSongDetails = [...existingSongDetails];
                    if (newSongDetails[index] === undefined) {
                      return existingSongDetails;
                    }
                    newSongDetails[index].songAlbumArtist = newSongAlbumArtist;
                    newSongDetails[index].validationError = undefined;
                    return newSongDetails;
                  });
                }}
              />
              <label css={justifyRight}>Album: </label>
              <EditableText
                forceEditing
                value={songDetail.songAlbum || ''}
                onValueChange={(newSongAlbum) => {
                  setSongDetails((existingSongDetails) => {
                    const newSongDetails = [...existingSongDetails];
                    if (newSongDetails[index] === undefined) {
                      return existingSongDetails;
                    }
                    newSongDetails[index].songAlbum = newSongAlbum;
                    newSongDetails[index].validationError = undefined;
                    return newSongDetails;
                  });
                }}
              />
              <label css={justifyRight}>Genres: </label>
              <EditableText
                forceEditing
                value={songDetail.songGenres?.join(', ') || ''}
                onValueChange={(newSongGenres) => {
                  setSongDetails((existingSongDetails) => {
                    const newSongDetails = [...existingSongDetails];
                    if (newSongDetails[index] === undefined) {
                      return existingSongDetails;
                    }
                    newSongDetails[index].songGenres = newSongGenres
                      .split(',')
                      .map((genre) => genre.trim())
                      .filter((genre) =>
                        typia.is<InkibraRecordlessLibrarySongType.GenreLiterals>(
                          genre,
                        ),
                      );
                    newSongDetails[index].validationError = undefined;
                    return newSongDetails;
                  });
                }}
              />
              <label css={justifyRight}>Duration: </label>
              <span>{songDetail.songDuration?.toString() || ''}</span>
              <label css={justifyRight}>BPM: </label>
              <span>{songDetail.songBpm?.toString() || ''}</span>
              <label css={justifyRight}>Key: </label>
              <span>{songDetail.songKey || ''}</span>
              <label css={justifyRight}>Energy: </label>
              <span>{songDetail.songEnergy?.toString() || ''}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
};
