/** @jsxImportSource @emotion/react */

import { LoggerContext } from '@inkibra/api-base';
import { Bunyan } from '@inkibra/logger';
import { Brand, Filter } from '@inkibra/observable-cache';
import {
  EnergyCurveRequestRelativeEnergy,
  InkibraRecordlessLibraryApiFetcherRegistry,
  InkibraRecordlessLibraryArrangementType,
  InkibraRecordlessLibraryClientPlayerElement,
  InkibraRecordlessLibraryMixType,
  InkibraRecordlessLibrarySongType,
  LibraryPlayerController,
  LoadingState,
  UnloadedAsyncPlayable,
} from '@inkibra/recordless.library-api';
import { err, ok } from 'neverthrow';
import {
  RefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CreateMixForWorkout,
  InkibraRecordlessTempoApiFetcherRegistry,
} from '../api';
import { ErrorRoute } from '../app/routes';
import {
  InkibraRecordlessWorkoutIntervalType,
  InkibraRecordlessWorkoutType,
} from '../type';
import { NowPlayingTray, NowPlayingTrayController } from './now-playing-tray';

export type WorkoutSessionAnnotation = {
  workout: InkibraRecordlessWorkoutType;
  lastPlannedTime: number;
  type: 'preworkout' | 'workout';
};

type WorkoutSessionInfo = {
  workoutGenres: InkibraRecordlessWorkoutType.WorkoutGenre[];
  announcerMode: CreateMixForWorkout.AnnouncerMode;
};

export enum DistanceSessionActivity {
  WALKING = 'WALKING',
  RUNNING = 'RUNNING',
  JOGGING = 'JOGGING',
  HIKING = 'HIKING',
}

type WorkoutSessionController = {
  queueWorkout: (
    workout: InkibraRecordlessWorkoutType,
    newSessionInfo?: WorkoutSessionInfo,
  ) => void;
  hideTray: () => void;
  showTray: () => void;
  getSessionInfo: () => WorkoutSessionInfo | undefined;
  playback: RefObject<LibraryPlayerController<WorkoutSessionAnnotation>>;
  nowPlayingTrayRef: RefObject<NowPlayingTrayController>;
  isWorkoutSessionActive: boolean;
};
export const WorkoutSessionContext = createContext<
  () => WorkoutSessionController
>(() => {
  throw new Error('WorkoutSessionContext not setup');
});

export type WorkoutSessionGuardProps = {
  guardedElement: JSX.Element;
};

async function ensureWorkoutMixHasBeenCreated(
  jobId: string,
  workoutId: InkibraRecordlessWorkoutType['id'],
  workoutSessionInfo: CreateMixForWorkout.Body,
  callDepth = 0,
): Promise<CreateMixForWorkout.Response> {
  // if call depth is not 0 we should wait for 5 seconds before calling the endpoint again
  if (callDepth > 0) {
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }

  const result = await InkibraRecordlessTempoApiFetcherRegistry.get(
    'createMixForWorkout',
  ).fn({
    pathParams: { id: workoutId },
    pathQuery: { jobId },
    body: workoutSessionInfo,
    files: undefined,
  });

  // If we have called this endpoint more than 50 times, we should stop
  if (callDepth > 50) {
    return result;
  }

  // we should keep calling this endpoint until it returns a status of 'complete'
  if (result.type === 'Ok' && result.value.status !== 'complete') {
    return ensureWorkoutMixHasBeenCreated(
      jobId,
      workoutId,
      workoutSessionInfo,
      callDepth + 1,
    );
  }

  return result;
}

// TODO: use this again for interval planning
export function makeCompleteAsyncPlayableFromWorkout(
  logger: Bunyan,
  workout: InkibraRecordlessWorkoutType,
  workoutSessionInfo: CreateMixForWorkout.Body,
) {
  const state: UnloadedAsyncPlayable<WorkoutSessionAnnotation> = {
    entryId: Brand.createId2<'nkrplayable'>('nkrplayable'),
    loader: async () => {
      const workoutJobResult = await ensureWorkoutMixHasBeenCreated(
        state.entryId,
        workout.id,
        workoutSessionInfo,
      );
      if (workoutJobResult.type === 'Err') {
        logger.error('Error loading workoutJobResult', workoutJobResult.error);
        return err(undefined);
      }
      if (workoutJobResult.value.status !== 'complete') {
        logger.error('Workout mix creation job did not complete');
        return err(undefined);
      }
      const workoutMix = workoutJobResult.value.result;
      return ok({
        entryId: state.entryId,
        state: LoadingState.LOADED,
        playable: workoutMix,
        annotation: { workout, type: 'workout', lastPlannedTime: 0 },
      });
    },
    annotation: { workout, type: 'workout', lastPlannedTime: 0 },
    state: LoadingState.UNLOADED,
  };

  return state;
}

// TODO: exclude songs that have already been played in the workout session
async function findSongForSessionAndInterval(
  _: Bunyan,
  workoutSessionInfo: CreateMixForWorkout.Body,
  interval: InkibraRecordlessWorkoutIntervalType,
  alreadyPlayedSongIds: InkibraRecordlessLibrarySongType['id'][],
) {
  const songGenres = Array.from(
    new Set(
      workoutSessionInfo.workoutGenres.flatMap((workoutGenre) => {
        return InkibraRecordlessWorkoutType.WorkoutGenre.convertToSongGenre(
          workoutGenre,
        );
      }),
    ),
  );
  const intervalSongEnergy =
    InkibraRecordlessWorkoutType.intervalEnergyToSongEnergy(interval);
  const songResult = await InkibraRecordlessLibraryApiFetcherRegistry.get(
    'getAllLibraryCatalogSongs',
  ).fn({
    body: undefined,
    files: undefined,
    pathParams: {},
    pathQuery: {
      limit: 1000,
      filter: {
        catalogStatus: {
          operator: Filter.Operators.IN,
          values: [
            InkibraRecordlessLibrarySongType.CatalogStatus.IN_CONTINUOUS_REVIEW,
            InkibraRecordlessLibrarySongType.CatalogStatus.READY_FOR_REVIEW,
          ],
        },
        genres: {
          operator: Filter.Operators.ANY_IN,
          values: songGenres,
        },
        energy: {
          operator: Filter.Operators.BETWEEN,
          firstValue: intervalSongEnergy - 2,
          secondValue: intervalSongEnergy + 2,
        },
      },
    },
  });

  return songResult.value
    .filter((song) => !alreadyPlayedSongIds.includes(song.id))
    .sort((a, b) => {
      return (
        Math.abs(a.energy - interval.energy) -
        Math.abs(b.energy - interval.energy)
      );
    })
    .slice(0, 10)
    .sort(() => 0.5 - Math.random())
    .at(0);
}

function getVirtualArrangementWithHeadOffsetBeatsAndBodyBeats(
  song: InkibraRecordlessLibrarySongType,
  playbackTime: number,
  tryPreviousSection = true,
) {
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
  const indexOfHighEnergySection =
    songUtil.findFirstSectionIndexWithRelativeEnergy(
      song,
      EnergyCurveRequestRelativeEnergy.HIGH,
    ) - (tryPreviousSection ? 1 : 0);
  const highEnergySection = song.orderedSectionFields.at(
    indexOfHighEnergySection,
  );
  if (!highEnergySection) {
    return err(undefined);
  }
  const virtualArrangementResult =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil(
      {},
    ).createVirtualArrangementWithMaxDuration(
      {
        name: 'Virtual Arrangement',
        librarySong: song,
      },
      indexOfHighEnergySection,
      2 * 60,
    );
  if (virtualArrangementResult.isErr()) {
    return err(undefined);
  }
  let headOffsetBeats = 0;
  const firstSection = virtualArrangementResult.value.sectionArrangements.at(0);
  if (!firstSection) {
    return err(undefined);
  }
  if (firstSection?.sectionFieldId !== highEnergySection.id) {
    const sectionDurationResult = songUtil.getSectionDuration(
      song,
      firstSection.sectionFieldId,
    );
    if (sectionDurationResult.isErr()) {
      return err(undefined);
    }
    const sectionDurationInBars = Math.floor(
      (sectionDurationResult.value * song.bpm) / 240,
    );
    headOffsetBeats = sectionDurationInBars * 4;
  }
  console.log('playbackTime', playbackTime);
  const playbackTimeInBars = Math.ceil((playbackTime * song.bpm) / 240);
  console.log('playbackTimeInBars', playbackTimeInBars);
  const bodyBeats = playbackTimeInBars * 4;
  return ok({
    arrangement: virtualArrangementResult.value,
    headOffsetBeats,
    bodyBeats,
  });
}

function makeInitialMixAsyncPlayableForWorkout(
  logger: Bunyan,
  workout: InkibraRecordlessWorkoutType,
  workoutSessionInfo: CreateMixForWorkout.Body,
  alreadyPlayedSongIds: InkibraRecordlessLibrarySongType['id'][],
) {
  const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({});
  const state: UnloadedAsyncPlayable<WorkoutSessionAnnotation> = {
    entryId: Brand.createId2<'nkrplayable'>('nkrplayable'),
    loader: async () => {
      const firstInterval =
        InkibraRecordlessWorkoutType.getIntervalsWithStartAndEndTimes(
          workout,
        ).at(0);
      if (!firstInterval) {
        return err(undefined);
      }
      const song = await findSongForSessionAndInterval(
        logger,
        workoutSessionInfo,
        firstInterval,
        alreadyPlayedSongIds,
      );
      if (!song) {
        return err(undefined);
      }
      const firstIntervalEndTimeInBars = Math.ceil(
        (firstInterval.endTime * song.bpm) / 240,
      );
      const firstIntervalEndTimeInBeats = firstIntervalEndTimeInBars * 4;
      const virtualArrangementResult =
        getVirtualArrangementWithHeadOffsetBeatsAndBodyBeats(
          song,
          firstIntervalEndTimeInBeats,
          false,
        );
      if (virtualArrangementResult.isErr()) {
        return err(undefined);
      }
      const startingMix = mixUtil.create({ name: workout.name });
      if (startingMix.isErr()) {
        return err(undefined);
      }
      logger.info('Creating initial mix for workout', {
        workout,
        workoutSessionInfo,
        firstInterval,
        headOffsetBeats: virtualArrangementResult.value.headOffsetBeats,
        bodyBeats: virtualArrangementResult.value.bodyBeats,
      });
      const mix = mixUtil.createAndAppendNode(
        startingMix.value,
        { targetBpm: song.bpm, targetDetune: 0, targetTailSeamBeats: 4 },
        virtualArrangementResult.value.arrangement,
        {
          headOffsetBeats: virtualArrangementResult.value.headOffsetBeats,
          bodyBeats: virtualArrangementResult.value.bodyBeats,
          annotation: `interval-${firstInterval.index}`,
        },
      );
      if (mix.isErr()) {
        return err(undefined);
      }
      return ok({
        entryId: state.entryId,
        state: LoadingState.LOADED,
        playable: mix.value,
        annotation: { workout, type: 'workout', lastPlannedTime: 0 },
      });
    },
    annotation: { workout, type: 'workout', lastPlannedTime: 0 },
    state: LoadingState.UNLOADED,
  };

  return state;
}

function canPlanNextMixSwap(
  mix: InkibraRecordlessLibraryMixType,
  workout: InkibraRecordlessWorkoutType,
  planningTime: number,
  lookAheadTime = 30,
  intervalConsiderationTime = 30,
) {
  planningTime += lookAheadTime;
  const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({
    forceRecalculateMixOutputInfo: true,
  });

  let planningInterval = InkibraRecordlessWorkoutType.getIntervalAtTime(
    workout,
    planningTime,
  );
  if (!planningInterval) {
    planningTime -= lookAheadTime;
    planningInterval = InkibraRecordlessWorkoutType.getIntervalAtTime(
      workout,
      planningTime,
    );
    if (!planningInterval) {
      console.log('no planning interval');
      return ok(false);
    }
  }

  const timeRemainingInCurrentInterval =
    planningInterval.endTime - planningTime;

  const nextInterval =
    InkibraRecordlessWorkoutType.getIntervalsWithStartAndEndTimes(workout).at(
      planningInterval.index + 1,
    );

  const currentMixElement = mixUtil.getNodePlayingAtTime(
    mix,
    planningTime,
    true,
  );
  if (
    currentMixElement &&
    (timeRemainingInCurrentInterval >= intervalConsiderationTime ||
      !nextInterval)
  ) {
    const currentMixElementTimeRemaining =
      mixUtil.convertPlaybackTimeToNodeBodyRelativeTimeRemaining(
        mix,
        currentMixElement.id,
        planningTime,
      );
    if (currentMixElementTimeRemaining.isErr()) {
      return err(undefined);
    }
    if (currentMixElementTimeRemaining.value > lookAheadTime) {
      return ok(false);
    }
    return ok(true);
  }
  if (!nextInterval) {
    return ok(false);
  }
  return ok(true);
}

function makeAsyncPlayableMixSwapFromWorkout(
  logger: Bunyan,
  playableId: Brand<'nkrplayable'>,
  mix: InkibraRecordlessLibraryMixType,
  workout: InkibraRecordlessWorkoutType, // the intervals here contain the energy values
  workoutSessionInfo: CreateMixForWorkout.Body,
  alreadyPlayedSongIds: InkibraRecordlessLibrarySongType['id'][],
  planningTime: number,
  lookAheadTime = 30,
  minElementPlanTime = 30,
  intervalConsiderationTime = 30,
) {
  const state: UnloadedAsyncPlayable<
    WorkoutSessionAnnotation,
    undefined,
    InkibraRecordlessLibraryMixType
  > = {
    entryId: playableId,
    loader: async () => {
      logger.info('planning next mix swap', {
        planningTime,
        lookAheadTime,
        minElementPlanTime,
        intervalConsiderationTime,
        workout,
        mixNodes: mix.orderedMixNodes,
        workoutSessionInfo,
        alreadyPlayedSongIds,
      });
      planningTime += lookAheadTime;
      const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({
        forceRecalculateMixOutputInfo: true,
      });

      let planningInterval = InkibraRecordlessWorkoutType.getIntervalAtTime(
        workout,
        planningTime,
      );
      if (!planningInterval) {
        planningTime -= lookAheadTime;
        console.log('no planning interval, looking back');
        planningInterval = InkibraRecordlessWorkoutType.getIntervalAtTime(
          workout,
          planningTime,
        );
        if (!planningInterval) {
          return err(undefined);
        }
      }
      const timeRemainingInCurrentInterval =
        planningInterval.endTime - planningTime;

      const nextInterval =
        InkibraRecordlessWorkoutType.getIntervalsWithStartAndEndTimes(
          workout,
        ).at(planningInterval.index + 1);

      // TODO: what if there is no node playing at the planning time?
      const currentMixElement = mixUtil.getNodePlayingAtTime(
        mix,
        planningTime,
        true,
      );
      console.log('info of planning', {
        currentMixElement,
        timeRemainingInCurrentInterval,
        planningInterval,
        nextInterval,
      });

      if (
        currentMixElement &&
        (timeRemainingInCurrentInterval >= intervalConsiderationTime ||
          !nextInterval)
        // nextInterval.energy === planningInterval.energy
      ) {
        const currentMixElementTimeRemaining =
          mixUtil.convertPlaybackTimeToNodeBodyRelativeTimeRemaining(
            mix,
            currentMixElement.id,
            planningTime,
          );
        if (currentMixElementTimeRemaining.isErr()) {
          return err(undefined);
        }

        if (currentMixElementTimeRemaining.value > lookAheadTime) {
          // We cannot plan a new element yet, we should not have called this yet
          return err(undefined);
        }
        // we can plan the current interval
        console.log('planning the current interval');
        const mixWithExtendedElement = mixUtil.extendMixElementByTime(
          mix,
          currentMixElement.id,
          Math.min(timeRemainingInCurrentInterval, minElementPlanTime),
        );
        if (mixWithExtendedElement.isOk()) {
          console.log('mixWithExtendedElement', mixWithExtendedElement);
          return ok({
            entryId: state.entryId,
            state: LoadingState.LOADED,
            playable: mixWithExtendedElement.value,
            annotation: {
              workout,
              type: 'workout',
              lastPlannedTime: planningTime,
            },
          });
        }
        // we need to find a new a new song to play
        const newSong = await findSongForSessionAndInterval(
          logger,
          workoutSessionInfo,
          planningInterval,
          alreadyPlayedSongIds,
        );
        if (!newSong) {
          return err(undefined);
        }
        const newVirtualArrangementResult =
          getVirtualArrangementWithHeadOffsetBeatsAndBodyBeats(
            newSong,
            minElementPlanTime,
          );
        if (newVirtualArrangementResult.isErr()) {
          return err(undefined);
        }
        const appendedMix = mixUtil.createAndAppendNode(
          mix,
          { targetBpm: newSong.bpm, targetDetune: 0, targetTailSeamBeats: 16 },
          newVirtualArrangementResult.value.arrangement,
          {
            headOffsetBeats: newVirtualArrangementResult.value.headOffsetBeats,
            bodyBeats: newVirtualArrangementResult.value.bodyBeats,
            annotation: `interval-${planningInterval.index}`,
          },
        );
        if (appendedMix.isErr()) {
          return err(undefined);
        }
        return ok({
          entryId: state.entryId,
          state: LoadingState.LOADED,
          playable: appendedMix.value,
          annotation: {
            workout,
            type: 'workout',
            lastPlannedTime: planningTime,
          },
        });
      }

      if (!nextInterval) {
        return err(undefined);
      }

      // we need to plan the next interval
      const newSong = await findSongForSessionAndInterval(
        logger,
        workoutSessionInfo,
        nextInterval,
        alreadyPlayedSongIds,
      );
      if (!newSong) {
        return err(undefined);
      }
      const newVirtualArrangementResult =
        getVirtualArrangementWithHeadOffsetBeatsAndBodyBeats(
          newSong,
          minElementPlanTime,
        );
      if (newVirtualArrangementResult.isErr()) {
        return err(undefined);
      }
      console.log('aligning mix', {
        mix,
        arrangement: newVirtualArrangementResult.value.arrangement,
        bpm: newSong.bpm,
        headSeamBeats: 16,
        alignedArrangementBodyStartTime: nextInterval.startTime,
        options: {
          annotation: `interval-${nextInterval.index}`,
          bodyBeats: newVirtualArrangementResult.value.bodyBeats,
          headOffsetBeats: newVirtualArrangementResult.value.headOffsetBeats,
        },
      });
      const alignedMixResult = mixUtil.alignArrangementToMix(
        mix,
        newVirtualArrangementResult.value.arrangement,
        newSong.bpm,
        16, // head seam beats,
        nextInterval.startTime,
        {
          annotation: `interval-${nextInterval.index}`,
          bodyBeats: newVirtualArrangementResult.value.bodyBeats,
          headOffsetBeats: newVirtualArrangementResult.value.headOffsetBeats,
        },
      );

      if (alignedMixResult.isErr()) {
        console.log('Could not create aligned mix, falling back to appending');
        const appendedMix = mixUtil.createAndAppendNode(
          mix,
          { targetBpm: newSong.bpm, targetDetune: 0, targetTailSeamBeats: 16 },
          newVirtualArrangementResult.value.arrangement,
          {
            headOffsetBeats: newVirtualArrangementResult.value.headOffsetBeats,
            bodyBeats: newVirtualArrangementResult.value.bodyBeats,
            annotation: `interval-${nextInterval.index}`,
          },
        );
        if (appendedMix.isErr()) {
          console.log('appendedMix error', appendedMix.error);
          return err(undefined);
        }
        return ok({
          entryId: state.entryId,
          state: LoadingState.LOADED,
          playable: appendedMix.value,
          annotation: {
            workout,
            type: 'workout',
            lastPlannedTime: planningTime,
          },
        });
      }
      console.log('alignedMixResult', alignedMixResult);
      return ok({
        entryId: state.entryId,
        state: LoadingState.LOADED,
        playable: alignedMixResult.value,
        annotation: {
          workout,
          type: 'workout',
          lastPlannedTime: planningTime,
        },
      });
    },
    annotation: {
      workout,
      type: 'workout',
      lastPlannedTime: planningTime,
    },
    state: LoadingState.UNLOADED,
  };

  return state;
}
export function WorkoutSessionContextGuard(props: WorkoutSessionGuardProps) {
  const logger = useContext(LoggerContext)().child({
    component: 'workout-session-context',
  });
  const [isTrayHidden, setIsTrayHidden] = useState(false);
  const [workoutSessionInfo, setWorkoutSessionInfo] = useState<
    WorkoutSessionInfo | undefined
  >(undefined);
  const [isWorkoutSessionActive, setIsWorkoutSessionActive] = useState(false);
  const clientPlayerRef =
    useRef<LibraryPlayerController<WorkoutSessionAnnotation>>(null);
  const nowPlayingTrayRef = useRef<NowPlayingTrayController>(null);
  const navigate = useNavigate();
  const [playedSongsInSession, setPlayedSongsInSession] = useState<
    InkibraRecordlessLibrarySongType['id'][]
  >([]);

  useEffect(() => {
    clientPlayerRef.current?.setRadioMode('on');
    return () => {
      clientPlayerRef.current?.setRadioMode('off');
    };
  }, [clientPlayerRef]);

  useEffect(() => {
    const subscription = clientPlayerRef.current?.subscribeToQueueState(
      (queueState) => {
        const playedSongIds = queueState.history
          .concat(queueState.nowPlaying ? [queueState.nowPlaying] : [])
          .flatMap((historyItem) => {
            switch (historyItem.playable.type) {
              case 'nkrsong':
                return [historyItem.playable.id];
              case 'nkrarrangement':
                return [historyItem.playable.librarySong.id];
              case 'nkrmix':
                return historyItem.playable.orderedMixNodes.map(
                  (mixNode) => mixNode.currentLibraryArrangement.librarySong.id,
                );
            }
          });
        setPlayedSongsInSession(playedSongIds);
        setIsWorkoutSessionActive(
          queueState.queue.length > 0 || queueState.nowPlaying !== undefined,
        );
      },
    );

    return () => {
      clientPlayerRef.current?.unsubscribeFromQueueState(subscription);
    };
  }, [clientPlayerRef.current]);

  useEffect(() => {
    if (!isWorkoutSessionActive) {
      setWorkoutSessionInfo(undefined);
    }
  }, [isWorkoutSessionActive]);

  useEffect(() => {
    const playbackStateSubscription =
      clientPlayerRef.current?.subscribeToPlaybackState((playbackState) => {
        if (
          playbackState.nowPlaying?.playable.type === 'nkrmix' &&
          isWorkoutSessionActive &&
          playbackState.nowPlaying.annotation.type === 'workout' &&
          playbackState.swapping === false &&
          workoutSessionInfo &&
          playbackState.time >
            playbackState.nowPlaying.annotation.lastPlannedTime
        ) {
          const willPlanNextMixSwap = canPlanNextMixSwap(
            playbackState.nowPlaying.playable,
            playbackState.nowPlaying.annotation.workout,
            playbackState.time,
          );
          if (
            willPlanNextMixSwap.isOk() &&
            willPlanNextMixSwap.value === true
          ) {
            const newMixSwapPlayable = makeAsyncPlayableMixSwapFromWorkout(
              logger,
              playbackState.nowPlaying.entryId,
              playbackState.nowPlaying.playable,
              playbackState.nowPlaying.annotation.workout,
              workoutSessionInfo,
              playedSongsInSession,
              playbackState.time,
            );
            clientPlayerRef.current?.asyncSwapNowPlayingMix(
              newMixSwapPlayable,
              {
                startWithNodeAtTime: Math.max(0, playbackState.time - 180),
                endWithNodeAtTime: playbackState.time + 1000,
              },
            );
          }
        }
      });

    return () => {
      clientPlayerRef.current?.unsubscribeFromPlaybackState(
        playbackStateSubscription,
      );
    };
  }, [clientPlayerRef.current, isWorkoutSessionActive]);

  const makeSessionWorkoutMixPlayable = async (
    workout: InkibraRecordlessWorkoutType,
    sessionInfo: WorkoutSessionInfo,
    isNewSession: boolean,
  ) => {
    const lowestEnergyInterval = workout.intervals.find(
      (interval) =>
        interval.energy ===
        Math.min(...workout.intervals.map((interval) => interval.energy)),
    );
    if (!lowestEnergyInterval) {
      logger.error('Unexpected error: no lowest energy interval');
      navigate(
        ErrorRoute.makeRouteLink({
          id: workout.id,
          reason: 'ERROR_LOADING_PREWORKOUT_SONG',
        }),
      );
      return;
    }
    const preworkoutSong = await findSongForSessionAndInterval(
      logger,
      sessionInfo,
      lowestEnergyInterval,
      playedSongsInSession,
    );

    if (!preworkoutSong) {
      logger.warn('No random song found');
      // TODO: make sure the dock is minimized or that error is above dock?
      navigate(
        ErrorRoute.makeRouteLink({
          id: workout.id,
          reason: 'ERROR_LOADING_PREWORKOUT_SONG',
        }),
      );
      return;
    }

    if (isNewSession) {
      console.log('adding play next when loaded');
      clientPlayerRef.current?.addPlayNext(
        preworkoutSong,
        {
          workout,
          type: 'preworkout',
          lastPlannedTime: 0,
        },
        true,
      );
    } else {
      clientPlayerRef.current?.addToQueue(preworkoutSong, {
        workout,
        type: 'preworkout',
        lastPlannedTime: 0,
      });
    }

    const workoutMixAsyncPlayable = await makeInitialMixAsyncPlayableForWorkout(
      logger,
      workout,
      sessionInfo,
      playedSongsInSession.concat(preworkoutSong.id),
    );

    if (!workoutMixAsyncPlayable) {
      // TODO: handle this error
      logger.error('Unexpected error: no workout mix async playable');
      return;
    }
    clientPlayerRef.current?.addAsyncToQueue(workoutMixAsyncPlayable);
    nowPlayingTrayRef.current?.openTrayDrawer();
  };

  return (
    <WorkoutSessionContext.Provider
      value={() => ({
        hideTray: () => setIsTrayHidden(true),
        showTray: () => setIsTrayHidden(false),
        playback: clientPlayerRef,
        getSessionInfo: () => workoutSessionInfo,
        nowPlayingTrayRef,
        isWorkoutSessionActive,
        queueWorkout: async (
          workout: InkibraRecordlessWorkoutType,
          newSessionInfo?: WorkoutSessionInfo,
        ) => {
          if (newSessionInfo && newSessionInfo.workoutGenres.length > 0) {
            setWorkoutSessionInfo(newSessionInfo);
          } else if (!workoutSessionInfo?.workoutGenres.length) {
            nowPlayingTrayRef.current?.requestSessionInfo(workout);
            nowPlayingTrayRef.current?.openTrayDrawer();
            return;
          }

          if (isWorkoutSessionActive === false) {
            setIsWorkoutSessionActive(true);
          }

          const sessionInfoToUse = newSessionInfo ?? workoutSessionInfo;

          if (!sessionInfoToUse) {
            logger.error('Unexpected error: no session info to use');
            throw new Error('Unexpected error: no session info to use');
          }
          console.log('queueWorkout', { workout, sessionInfoToUse });

          if (
            [
              InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_WALKING,
              InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_RUNNING,
              InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_JOGGING,
              InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_HIKING,
            ].includes(workout.workoutType)
          ) {
            // TODO: handle distance workouts
            await makeSessionWorkoutMixPlayable(
              workout,
              sessionInfoToUse,
              newSessionInfo !== undefined,
            );
          } else {
            await makeSessionWorkoutMixPlayable(
              workout,
              sessionInfoToUse,
              newSessionInfo !== undefined,
            );
          }
        },
      })}
    >
      <InkibraRecordlessLibraryClientPlayerElement ref={clientPlayerRef} />
      <div
        css={{
          display: 'grid',
          gridTemplateRows:
            isTrayHidden || !isWorkoutSessionActive ? '1fr 0px' : '1fr 100px',
        }}
      >
        {props.guardedElement}
        <NowPlayingTray
          hidden={isTrayHidden || !isWorkoutSessionActive}
          ref={nowPlayingTrayRef}
        />
      </div>
    </WorkoutSessionContext.Provider>
  );
}
