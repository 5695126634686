/** @jsxImportSource @emotion/react */

export function StretchingMan() {
  return (
    <svg
      height="100%"
      viewBox="0 0 148 192"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.4017 178.634C60.7445 177.659 62.8015 176.598 64.1729 173.94L83.0298 138.869C83.3726 138.248 83.6297 137.983 84.4012 137.983H130.515V182.885C130.515 188.022 134.457 191.919 139.171 191.919C144.143 191.919 148 188.022 148 182.885V130.012C148 124.078 144.914 120.536 138.914 120.004L100.086 116.373V65.9804C100.086 56.1498 93.4011 48.0018 81.6584 48.0018H77.5441C70.6015 48.0018 65.2873 49.2417 58.4303 54.5555L29.6308 76.8737C24.6594 80.5935 25.0023 88.1215 30.0594 91.5758L65.2873 115.754V118.056L49.9447 161.984L6.40265 174.383C1.77418 175.711 -0.968629 180.582 0.317057 185.365C1.68846 190.413 6.05981 192.982 11.0312 191.653L57.4017 178.634ZM49.9447 83.6046L62.973 73.5083C64.0015 72.7998 65.2873 73.3312 65.2873 74.6596V94.1442L49.9447 83.6046ZM81.6584 38.7026C91.8581 38.7026 100.258 30.0233 100.258 19.2184C100.258 8.67929 91.8581 0 81.6584 0C71.2015 0 62.8015 8.67929 62.8015 19.2184C62.8015 30.0233 71.2015 38.7026 81.6584 38.7026Z"
        fill="white"
      />
    </svg>
  );
}
