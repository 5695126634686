/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Children } from 'react';
import { responsiveStyle } from '../constants/media-query';

const cardStyles = css(
  {
    display: 'grid',
    rowGap: '32px',
    backgroundColor: 'rgba(19, 20, 21, 0.7)',
    padding: '16px',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },
  responsiveStyle('columnGap', {
    xs: '20px',
    sm: '20px',
    md: '32px',
    lg: '32px',
    xl: '32px',
  }),
  responsiveStyle('gridTemplateColumns', {
    xs: '1fr',
    sm: '358px',
    md: '585px',
    lg: '92px auto',
    xl: '92px 424px',
  }),
  responsiveStyle('gridTemplateRows', {
    xs: '75px 43px 248px',
    sm: '75px 43px 248px',
    md: '75px 43px 248px',
    lg: '43px 200px',
    xl: '43px 200px',
  }),
);

const cardImageStyles = css(
  {
    gridRowStart: 1,
    alignSelf: 'start',
    justifySelf: 'center',
  },
  responsiveStyle('width', {
    xs: '75px',
    sm: '75px',
    md: '92px',
    lg: '92px',
    xl: '92px',
  }),
  responsiveStyle('gridRowEnd', {
    xs: 1,
    sm: 1,
    md: 1,
    lg: 3,
    xl: 3,
  }),
);

const cardTitleStyles = css({
  lineHeight: '43px',
});

const cardContentStyles = css({});

export type RecordlessCardProps = {
  image: React.ReactNode;
};

export function RecordlessCard({
  image,
  children,
}: RecordlessCardProps & { children: React.ReactNode }) {
  const arrayChildren = Children.toArray(children);

  return (
    <div css={cardStyles}>
      <div css={cardImageStyles}>{image}</div>

      <div css={cardTitleStyles}>{arrayChildren.at(0)}</div>

      <div css={cardContentStyles}>{arrayChildren.at(1)}</div>
    </div>
  );
}
