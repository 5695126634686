/** @jsxImportSource @emotion/react */

import { keyframes } from '@emotion/react';
import { RunningManDark } from './icons/running-man-dark';

const spin = keyframes({
  '0%': {
    transform: 'rotate(0deg)',
  },
  '100%': {
    transform: 'rotate(360deg)',
  },
});

export type LoaderProps = {
  diameter: number;
  duration: number;
  spinnerThickness: number;
};

export function Loader({ diameter, duration, spinnerThickness }: LoaderProps) {
  const diameterPx = `${diameter}px`;

  const animation = `${spin} ${duration}s linear infinite`;

  return (
    <div
      css={{
        position: 'relative',
        overflow: 'hidden',
        width: diameterPx,
        height: diameterPx,
      }}
    >
      <div
        css={{
          label: 'loader-running-man-container',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          backgroundImage: 'linear-gradient(90deg, #52FF00 0%, #319900 100%)',
          display: 'grid',
          alignItems: 'center',
          justifyItems: 'center',
        }}
      >
        <RunningManDark />
      </div>

      <div
        css={{
          label: 'loader-spinner',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          zIndex: 10,
          animation,
          border: `${spinnerThickness}px solid transparent`,
          borderTop: `${spinnerThickness}px solid #FFFFFF`,
        }}
      />
    </div>
  );
}
