import { SchemaHelpersFor } from '@inkibra/api-base';
import typia from 'typia';
import * as uuid from 'uuid';

export type InkibraRecordlessWaitlistEntryType = {
  id: string & typia.tags.Format<'uuid'>;
  waitlistId: string & typia.tags.Format<'uuid'>;
  email: string & typia.tags.Format<'email'>;
  name?: string;
  wallet?: string;
  confirmed?: true;
  type: typeof InkibraRecordlessWaitlistEntryType.typename;
  modified: string & typia.tags.Format<'date-time'>;
  created: string & typia.tags.Format<'date-time'>;
  deleted?: string & typia.tags.Format<'date-time'>;
};

export namespace InkibraRecordlessWaitlistEntryType {
  export const schema: SchemaHelpersFor<InkibraRecordlessWaitlistEntryType> = {
    is: typia.createIs<InkibraRecordlessWaitlistEntryType>(),
    assert: typia.createAssert<InkibraRecordlessWaitlistEntryType>(),
    validate: typia.createValidate<InkibraRecordlessWaitlistEntryType>(),
    stringify: typia.json.createStringify<InkibraRecordlessWaitlistEntryType>(),
    clone: typia.misc.createClone<InkibraRecordlessWaitlistEntryType>(),
  };

  export const typename = 'INKIBRA_RECORDLESS_WAITLIST_ENTRY';
  export function is(val: unknown): val is InkibraRecordlessWaitlistEntryType {
    return (val as InkibraRecordlessWaitlistEntryType).type === typename;
  }
  export function fromCreation(
    val: Creation,
    waitlistId: string,
  ): InkibraRecordlessWaitlistEntryType {
    return {
      ...val,
      id: uuid.v4(),
      waitlistId,
      type: InkibraRecordlessWaitlistEntryType.typename,
      modified: new Date().toISOString(),
      created: new Date().toISOString(),
    };
  }

  export type Creation = Pick<
    InkibraRecordlessWaitlistEntryType,
    'email' | 'name' | 'wallet'
  >;

  export namespace Creation {
    export const schema: SchemaHelpersFor<Creation> = {
      is: typia.createIs<Creation>(),
      assert: typia.createAssert<Creation>(),
      validate: typia.createValidate<Creation>(),
      stringify: typia.json.createStringify<Creation>(),
      clone: typia.misc.createClone<Creation>(),
    };
  }

  export type Modification = Pick<
    InkibraRecordlessWaitlistEntryType,
    'confirmed' | 'wallet'
  >;

  export namespace Modification {
    export const schema: SchemaHelpersFor<Modification> = {
      is: typia.createIs<Modification>(),
      assert: typia.createAssert<Modification>(),
      validate: typia.createValidate<Modification>(),
      stringify: typia.json.createStringify<Modification>(),
      clone: typia.misc.createClone<Modification>(),
    };
  }

  export type WalletAddressModification = Required<
    Pick<Modification, 'wallet'>
  >;

  export namespace WalletAddressModification {
    export const schema: SchemaHelpersFor<WalletAddressModification> = {
      is: typia.createIs<WalletAddressModification>(),
      assert: typia.createAssert<WalletAddressModification>(),
      validate: typia.createValidate<WalletAddressModification>(),
      stringify: typia.json.createStringify<WalletAddressModification>(),
      clone: typia.misc.createClone<WalletAddressModification>(),
    };
  }
}
