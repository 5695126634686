/** @jsxImportSource @emotion/react */

import {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { PlaybackSessionContext } from './playback-session-guard-context';

/**
 * Represents the props for the MusicPlaybackDisplayUX component.
 */
export type RecordlessPlaybackDisplayProps = {
  windowedWaveformData: number[];
  relativeSections: {
    name: string;
    startTime: number;
  }[];
  duration: number;
  relativeZero: number;
  containerRef: React.RefObject<HTMLDivElement>;
  audioPlayerRef?: React.RefObject<HTMLAudioElement>;
  shrink?: number;
  gridArea?: string;
  usePixelPerSecondForWidth?: number;
};

export const RecordlessPlaybackDisplay = (
  props: RecordlessPlaybackDisplayProps,
) => {
  const {
    windowedWaveformData,
    relativeSections,
    relativeZero,
    shrink,
    containerRef,
    duration,
    audioPlayerRef,
    usePixelPerSecondForWidth,
  } = props;
  const [relativeTime, setRelativeTime] = useState(0);
  const [relativeHoverTime, setRelativeHoverTime] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const width = usePixelPerSecondForWidth
    ? usePixelPerSecondForWidth * duration
    : containerWidth - (shrink || 0);
  const height = 40;
  const rectWidth = width / windowedWaveformData.length;
  const rectHeight = height / 2;
  const rectY = height / 4;
  const rectY2 = height / 4 + rectHeight;

  useLayoutEffect(() => {
    const onResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    window.addEventListener('resize', onResize);
    setTimeout(onResize, 0);
    return () => window.removeEventListener('resize', onResize);
  }, [containerRef]);

  const playback = useContext(PlaybackSessionContext)();
  useEffect(() => {
    if (audioPlayerRef?.current) {
      const currentAudioRef = audioPlayerRef.current;
      const onTimeUpdate = () => {
        setRelativeTime(currentAudioRef.currentTime - relativeZero);
      };
      currentAudioRef.addEventListener('timeupdate', onTimeUpdate);
      return () => {
        currentAudioRef.removeEventListener('timeupdate', onTimeUpdate);
      };
    }

    const playbackStateSubscription =
      playback.current?.subscribeToPlaybackState((state) => {
        setRelativeTime(state.time - relativeZero);
      });

    return () => {
      playback.current?.unsubscribeFromPlaybackState(playbackStateSubscription);
    };
  }, [playback, audioPlayerRef]);

  const pixelsPerSecond = width / duration;

  return (
    <svg
      css={{
        gridArea: props.gridArea,
      }}
      width={width}
      height={height}
      onMouseMove={(e) => {
        const svgRect = e.currentTarget.getBoundingClientRect();
        const relativeX = e.clientX - svgRect.left;
        const relativePosition = relativeX / width;
        setRelativeHoverTime(duration * relativePosition);
      }}
      onMouseLeave={() => {
        setRelativeHoverTime(0);
      }}
    >
      {/* The background */}
      <rect x={0} y={0} width={width} height={height} fill="#D9D9D9" />
      {/* The waveform lines in blue */}
      {windowedWaveformData.map((waveform, index) => {
        // If we are past the relative time, we draw the waveform in gray
        const strokeColor =
          index * rectWidth < relativeTime * pixelsPerSecond ? 'black' : 'gray';
        return (
          <line
            key={`${index}-waveform-line`}
            x1={index * rectWidth}
            y1={rectY - rectHeight * (waveform * 0.8)}
            x2={index * rectWidth}
            y2={rectY2 + rectHeight * (waveform * 0.8)} // Adjust the second y coordinate for better spacing
            stroke={strokeColor}
            strokeWidth={1}
          />
        );
      })}
      {/* The section labels */}
      {relativeSections.map((section, index) => {
        const mockCanvasCtx = document.createElement('canvas').getContext('2d');
        if (!mockCanvasCtx) {
          return null;
        }
        mockCanvasCtx.font = '14px Arial';
        const textWidth = mockCanvasCtx.measureText(section.name).width;
        return (
          <Fragment key={index}>
            {/* White background for text */}
            <rect
              x={section.startTime * pixelsPerSecond + 10}
              y={0}
              width={textWidth + 20}
              height={20}
              fill="white"
              fillOpacity={0.7}
              rx={6}
            />
            <text
              x={section.startTime * pixelsPerSecond + 20}
              y={15}
              css={{
                fontSize: '14px',
                fill: 'red',
              }}
            >
              {section.name}
            </text>
            <line
              x1={section.startTime * pixelsPerSecond}
              y1={0}
              x2={section.startTime * pixelsPerSecond}
              y2={height}
              stroke="black"
              strokeWidth={1}
            />
          </Fragment>
        );
      })}
      {/* The time label */}
      <text
        x={relativeTime * pixelsPerSecond}
        y={height - 5}
        css={{
          fontSize: '14px',
          fill: 'black',
        }}
      >
        {(relativeTime + relativeZero).toFixed(2)}
      </text>
      {/* The hover time line */}
      <line
        x1={0}
        y1={height / 2}
        x2={relativeHoverTime * pixelsPerSecond}
        y2={height / 2}
        stroke="black"
        strokeWidth={1}
      />
      {/* The hover control ball */}
      <circle
        cx={relativeHoverTime * pixelsPerSecond}
        cy={height / 2}
        r={5}
        fill="black"
        onClick={() => {
          // Set the audio player time
          if (audioPlayerRef?.current) {
            audioPlayerRef.current.currentTime =
              relativeHoverTime + relativeZero;
          } else if (playback.current) {
            playback.current.seek(relativeHoverTime + relativeZero);
          }
        }}
        onDoubleClick={() => {
          // Play the audio player
          if (audioPlayerRef?.current) {
            audioPlayerRef.current.play();
          } else if (playback.current) {
            playback.current.resume();
          }
        }}
      />
    </svg>
  );
};
