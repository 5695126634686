import {
  ApiRoute,
  EmptyObject,
  GenericRegistry,
  HttpMethod,
  JobId,
  JobProgressType,
  JobResultType,
  NoData,
  SchemaHelpersFor,
  SerializableResult,
  SimpleQuery,
  StatusCode,
} from '@inkibra/api-base';
import { ErrorDescriptor } from '@inkibra/error-base';
import {
  InkibraRecordlessLibraryMixType,
  InkibraRecordlessLibrarySongType,
} from '@inkibra/recordless.library-api';
import typia from 'typia';
import { InkibraRecordlessWorkoutType } from './type';

export type SUBSCRIPTION_CHECK_FAILURE = ErrorDescriptor<
  'SUBSCRIPTION_CHECK_FAILURE',
  'User must have a subscription to access this feature',
  {}
>;

export type InkibraRecordlessWorkoutTypeResponse =
  SerializableResult.OkWithStatusCode<
    InkibraRecordlessWorkoutType,
    StatusCode.OK
  >;

export namespace InkibraRecordlessWorkoutTypeResponse {
  export const schema: SchemaHelpersFor<InkibraRecordlessWorkoutTypeResponse> =
    {
      is: typia.createIs<InkibraRecordlessWorkoutTypeResponse>(),
      assert: typia.createAssert<InkibraRecordlessWorkoutTypeResponse>(),
      validate: typia.createValidate<InkibraRecordlessWorkoutTypeResponse>(),
      stringify:
        typia.json.createStringify<InkibraRecordlessWorkoutTypeResponse>(),
      clone: typia.misc.createClone<InkibraRecordlessWorkoutTypeResponse>(),
    };
}

export type InkibraRecordlessWorkoutsTypeResponse =
  SerializableResult.OkWithStatusCode<
    InkibraRecordlessWorkoutType[],
    StatusCode.OK
  >;

export namespace InkibraRecordlessWorkoutsTypeResponse {
  export const schema: SchemaHelpersFor<InkibraRecordlessWorkoutsTypeResponse> =
    {
      is: typia.createIs<InkibraRecordlessWorkoutsTypeResponse>(),
      assert: typia.createAssert<InkibraRecordlessWorkoutsTypeResponse>(),
      validate: typia.createValidate<InkibraRecordlessWorkoutsTypeResponse>(),
      stringify:
        typia.json.createStringify<InkibraRecordlessWorkoutsTypeResponse>(),
      clone: typia.misc.createClone<InkibraRecordlessWorkoutsTypeResponse>(),
    };
}

export namespace GetAllInkibraRecordlessWorkouts {
  export type PathParams = EmptyObject;

  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = SimpleQuery;

  export type Body = NoData;

  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = InkibraRecordlessWorkoutsTypeResponse;

  export namespace Response {
    export const schema = InkibraRecordlessWorkoutsTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.workouts.all',
    {
      Name: 'getAllInkibraRecordlessWorkouts';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getAllInkibraRecordlessWorkouts',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.workouts.all',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetInkibraRecordlessWorkouts {
  export type PathParams = EmptyObject;

  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = SimpleQuery;

  export type Body = NoData;

  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = InkibraRecordlessWorkoutsTypeResponse;

  export namespace Response {
    export const schema = InkibraRecordlessWorkoutsTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.workouts',
    {
      Name: 'getInkibraRecordlessWorkouts';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getInkibraRecordlessWorkouts',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.workouts',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetInkibraRecordlessWorkout {
  export type PathParams = Pick<InkibraRecordlessWorkoutType, 'id'>;

  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = SimpleQuery;

  export type Body = NoData;

  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = InkibraRecordlessWorkoutTypeResponse;

  export namespace Response {
    export const schema = InkibraRecordlessWorkoutTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.workouts/:id',
    {
      Name: 'getInkibraRecordlessWorkout';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getInkibraRecordlessWorkout',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.workouts/:id',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateInkibraRecordlessWorkout {
  export type PathParams = EmptyObject;

  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessWorkoutType.Creation;

  export namespace Body {
    export const schema = InkibraRecordlessWorkoutType.Creation.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessWorkoutType,
    StatusCode.OK
  >;

  export namespace Response {
    export const schema = InkibraRecordlessWorkoutTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.workouts/',
    {
      Name: 'createInkibraRecordlessWorkout';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createInkibraRecordlessWorkout',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.workouts/',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace ModifyInkibraRecordlessWorkout {
  export type PathParams = Pick<InkibraRecordlessWorkoutType, 'id'>;

  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessWorkoutType.Modification;

  export namespace Body {
    export const schema = InkibraRecordlessWorkoutType.Modification.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessWorkoutType,
    StatusCode.OK
  >;

  export namespace Response {
    export const schema = InkibraRecordlessWorkoutTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.workouts/:id',
    {
      Name: 'modifyInkibraRecordlessWorkout';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'modifyInkibraRecordlessWorkout',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.workouts/:id',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace DeleteInkibraRecordlessWorkout {
  export type PathParams = Pick<InkibraRecordlessWorkoutType, 'id'>;

  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;

  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessWorkoutType,
    StatusCode.OK
  >;

  export namespace Response {
    export const schema = InkibraRecordlessWorkoutTypeResponse.schema;
  }
  export const Route = new ApiRoute<
    '/api/inkibra/recordless.workouts/:id',
    {
      Name: 'deleteInkibraRecordlessWorkout';
      Method: HttpMethod.DELETE;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'deleteInkibraRecordlessWorkout',
    method: HttpMethod.DELETE,
    path: '/api/inkibra/recordless.workouts/:id',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateMixForWorkout {
  export type PathParams = Pick<InkibraRecordlessWorkoutType, 'id'>;

  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {
    jobId: JobId<'CreateMixForWorkout'>;
  };

  export enum AnnouncerMode {
    OFF = 'OFF',
    LIGHT_ANNOUNCEMENTS = 'LIGHT_ANNOUNCEMENTS',
    FREQUENT_ANNOUNCEMENTS = 'FREQUENT_ANNOUNCEMENTS',
  }

  export type Body = {
    workoutGenres: InkibraRecordlessWorkoutType.WorkoutGenre[];
    announcerMode: AnnouncerMode;
    avoidSongs?: InkibraRecordlessLibrarySongType['id'][];
    selectFromSongs?: InkibraRecordlessLibrarySongType['id'][];
  };

  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type JobProgress = JobProgressType<
    'CreateMixForWorkout',
    {
      workoutId: InkibraRecordlessWorkoutType['id'];
      workoutGenres: Body['workoutGenres'];
      announcerMode: Body['announcerMode'];
    }
  >;
  export type JobResult = JobResultType<
    'CreateMixForWorkout',
    InkibraRecordlessLibraryMixType
  >;

  export type Response =
    | SerializableResult.OkWithStatusCode<
        JobProgress | JobResult,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        SUBSCRIPTION_CHECK_FAILURE,
        StatusCode.FORBIDDEN
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.workouts/:id/mix',
    {
      Name: 'createMixForWorkout';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createMixForWorkout',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.workouts/:id/mix',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export const InkibraRecordlessTempoApiRouteRegistry = GenericRegistry.init({
  getAllInkibraRecordlessWorkouts: GetAllInkibraRecordlessWorkouts.Route,
  getInkibraRecordlessWorkouts: GetInkibraRecordlessWorkouts.Route,
  getInkibraRecordlessWorkout: GetInkibraRecordlessWorkout.Route,
  createInkibraRecordlessWorkout: CreateInkibraRecordlessWorkout.Route,
  modifyInkibraRecordlessWorkout: ModifyInkibraRecordlessWorkout.Route,
  deleteInkibraRecordlessWorkout: DeleteInkibraRecordlessWorkout.Route,
  createMixForWorkout: CreateMixForWorkout.Route,
});

export const InkibraRecordlessTempoApiFetcherRegistry =
  InkibraRecordlessTempoApiRouteRegistry.matchWithAll((routes) => {
    return {
      ...routes.getAllInkibraRecordlessWorkouts.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.getInkibraRecordlessWorkouts.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.getInkibraRecordlessWorkout.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.createInkibraRecordlessWorkout.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.modifyInkibraRecordlessWorkout.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.deleteInkibraRecordlessWorkout.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.createMixForWorkout.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
    };
  });
