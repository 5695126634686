import { createContext } from 'react';
import { InkibraRecordlessWorkoutType } from '../type';

export type RecordlessTempoConfig = {
  STRIPE_TEMPO_SUBSCRIPTION_PRICE_ID: string;
  DEFAULT_ALBUM_ART_URL: string;
  osInformation: AppOsInformation;
  basePath: string;
};

export type RecordlessTempoBindings = {
  shareWorkout?: WorkoutShareFunction;
};

const defaultShareWorkout: WorkoutShareFunction = async (
  workout: InkibraRecordlessWorkoutType,
) => {
  if (navigator.share === undefined) {
    return false;
  }
  try {
    await navigator.share({
      title: `Checkout this workout "${workout.name}" on TempoTempo`,
      text: 'This workout keeps you motivated with synced music and a voice coach.',
      url: window.location.href,
    });
  } catch (error) {
    console.log('Did not share workout', error);
    return false;
  }
  return true;
};

export class RecordlessTempoConfigProvider {
  private config: RecordlessTempoConfig;
  private bindings: RecordlessTempoBindings;
  constructor(
    config: RecordlessTempoConfig,
    bindings: RecordlessTempoBindings,
  ) {
    this.config = config;
    this.bindings = bindings;
  }
  get STRIPE_TEMPO_SUBSCRIPTION_PRICE_ID() {
    return this.config.STRIPE_TEMPO_SUBSCRIPTION_PRICE_ID;
  }
  get DEFAULT_ALBUM_ART_URL() {
    return this.config.DEFAULT_ALBUM_ART_URL;
  }
  get osInformation() {
    return this.config.osInformation;
  }
  get basePath() {
    return this.config.basePath;
  }
  get shareWorkout() {
    if (this.bindings.shareWorkout) {
      return this.bindings.shareWorkout;
    }
    return defaultShareWorkout;
  }
}

export type AppOsInformation = {
  os: 'ios' | 'web';
};

export type WorkoutShareFunction = (
  workout: InkibraRecordlessWorkoutType,
) => Promise<boolean>;

export const ToneTempoConfigContext = createContext<
  () => RecordlessTempoConfigProvider
>(() => {
  throw new Error('ToneTempoConfigContext not setup');
});
