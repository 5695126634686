/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import { AuthenticatedSessionContext } from '@inkibra/recordless.auth-api';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import { useContext, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import { InkibraRecordlessTempoApiFetcherRegistry } from '../api';
import { Button } from '../components/button';
import { Hamburger } from '../components/icons/hamburger';
import { WorkoutDetails } from '../components/workout-details';
import { WorkoutEditor } from '../components/workout-editor';
import { WorkoutList } from '../components/workout-list';
import { WorkoutSessionContext } from '../components/workout-session-context';
import { WorkoutStartButton } from '../components/workout-start-button';
import { useWorkouts } from '../hooks';
import { InkibraRecordlessWorkoutType } from '../type';
import { DistanceActivityStartRoute, WorkoutRoute } from './routes';

const styles = css({
  label: 'browse-container-styles',
  maxWidth: '1000px',
  margin: 'auto',
  width: '100vw',
  display: 'grid',
  gridTemplateRows: '637px 1fr',
  gap: '25px',
  paddingTop: 'calc(env(safe-area-inset-top) + 45px)',
});

const appBarStyles = css({
  label: 'app-bar',
  maxWidth: '1000px',
  display: 'grid',
  position: 'fixed',
  top: 0,
  width: '100%',
  gridTemplateColumns: 'min-content min-content',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 'calc(max(env(safe-area-inset-top), 8px))',
  zIndex: 100,
  backgroundColor: 'rgba(8, 3, 29, 0.8)',
  backdropFilter: 'blur(10px)',
});

const topSectionCarouselItemStyles = css({
  label: 'top-section-carousel-item',
  maxWidth: '1000px',
  width: '100vw',
  height: '637px',
  backgroundImage:
    'linear-gradient(180deg, rgba(0, 0, 0, 0.37) 8%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%), url(https://storage.googleapis.com/corp-inkibra-us-public-cdn/tempo/banner.png)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
});

const workoutStyles = css({
  display: 'grid',
  height: '100%',
  backgroundImage:
    'linear-gradient(180deg, rgba(36, 14, 131, 0) 32%, #08031D 100%)',
  gridTemplateRows: 'min-content min-content min-content',
  rowGap: '16px',
  padding: '0 8px',
  paddingBottom: '40px',
  alignContent: 'end',
});

const bottomSectionStyles = css({
  width: '100%',
  padding: '0 0 16px',
  display: 'grid',
  gridTemplateRows: 'min-content 1fr',
  gap: '24px',
});

const workoutListTitleStyles = css({
  fontWeight: '600',
  fontSize: '20px',
  lineHeight: '125%',
  textAlign: 'center',
  color: '#FFFFFF',
});

const hamburgerStyles = css({
  display: 'grid',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
});

export function Browse() {
  const navigate = useNavigate();
  const cache = useContext(CacheContext)();
  const workoutSession = useContext(WorkoutSessionContext)();
  const { logout } = useContext(AuthenticatedSessionContext)();
  const hookEffectContext = new HookEffectContext();
  const workouts = hookEffectContext.addEffect(useWorkouts());
  hookEffectContext.runEffects(cache);

  const [contextMenuProps, toggleContextMenu] = useMenuState();
  const [contextMenuAnchor, setContextMenuAnchor] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [workoutEditor, setWorkoutEditor] = useState(false);

  const handleWorkoutSubmit = async (name: string) => {
    const response = await InkibraRecordlessTempoApiFetcherRegistry.get(
      'createInkibraRecordlessWorkout',
    ).fn({
      pathParams: {},
      pathQuery: {},
      body: {
        name,
        workoutType: InkibraRecordlessWorkoutType.WorkoutType.USER_WORKOUT,
        intervals: [],
      },
      files: undefined,
    });

    cache.input.next(response.value);

    workoutSession.showTray();
    setWorkoutEditor(false);
    navigate(WorkoutRoute.makeRouteLink({ id: response.value.id }));

    return true;
  };

  const filteredWorkouts = workouts.filter(
    (workout) =>
      workout.workoutType ===
        InkibraRecordlessWorkoutType.WorkoutType.NEW_CATALOG_WORKOUT &&
      workout.deleted === undefined,
  );

  return (
    <div css={styles}>
      <div css={appBarStyles}>
        <div
          css={[
            hamburgerStyles,
            contextMenuProps.state === 'open' && {
              background: 'rgba(0, 0, 0, 0.2)',
            },
          ]}
          onClick={(e) => {
            e.stopPropagation();
            toggleContextMenu();
            setContextMenuAnchor({ x: e.clientX, y: e.clientY });
          }}
        >
          <Hamburger />
          <ControlledMenu
            {...contextMenuProps}
            anchorPoint={contextMenuAnchor}
            onClose={() => {
              toggleContextMenu();
            }}
            direction="right"
          >
            <MenuItem
              onClick={() => {
                navigate(DistanceActivityStartRoute.makeRouteLink({}));
              }}
            >
              Switch to Distance Mode
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await logout();
              }}
            >
              Log out
            </MenuItem>
          </ControlledMenu>
        </div>
      </div>
      <Carousel
        key={filteredWorkouts.length}
        showStatus={false}
        emulateTouch={true}
        width={'min(100vw, 1000px)'}
        showThumbs={false}
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={true}
      >
        {filteredWorkouts.map((workout) => (
          <div
            key={workout.id}
            style={{
              backgroundImage: workout.backgroundImage
                ? `linear-gradient(180deg, rgba(0, 0, 0, 0.37) 8%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%), url(${workout.backgroundImage})`
                : undefined,
            }}
            css={topSectionCarouselItemStyles}
          >
            <div css={workoutStyles}>
              <WorkoutDetails workout={workout} />
              <WorkoutStartButton workout={workout} />
              <Button
                type="button"
                fillMode="outline"
                onClick={() => {
                  navigate(WorkoutRoute.makeRouteLink({ id: workout.id }));
                }}
                contents="VIEW WORKOUT"
              />
            </div>
          </div>
        ))}
      </Carousel>

      <div css={bottomSectionStyles}>
        <div css={workoutListTitleStyles}>YOUR WORKOUTS</div>

        <WorkoutList
          workouts={workouts.filter(
            (workout) =>
              workout.deleted === undefined &&
              workout.workoutType !==
                InkibraRecordlessWorkoutType.WorkoutType.NEW_CATALOG_WORKOUT,
          )}
          onCreateWorkout={() => {
            workoutSession.hideTray();
            setWorkoutEditor(true);
          }}
          onSelectWorkout={(id) => navigate(WorkoutRoute.makeRouteLink({ id }))}
        />
      </div>

      <WorkoutEditor
        creationMode={true}
        active={workoutEditor}
        onSubmit={handleWorkoutSubmit}
        onClose={() => {
          workoutSession.showTray();
          setWorkoutEditor(false);
        }}
      />
    </div>
  );
}
