/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { responsiveStyle } from '../constants/media-query';
import {
  HeaderText,
  HeaderTextAlign,
  HeaderTextBackground,
} from './header-text';
import { Checkbox } from './icons/check-box';

export type MarketingPricingCardProps = {
  title: string;
  paragraph: string;
};

const styles = css(
  {
    display: 'grid',
    width: '100%',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.04) 0%, rgba(255, 255, 255, 0.04) 97.57%)',
    // background: 'conic-gradient(from -39.17deg at 43.75% 42.19%, #4512EA 0deg, #1282EA 39.07deg, #12EA5B 77.44deg, #EFE75E 117.77deg, #E07614 143.48deg, #E55C2F 175.12deg, #EA4448 200.54deg, #F31875 235.05deg, #B416A0 265.33deg, #4512EA 306.9deg, #4512EA 339.41deg, #4512EA 360deg)',
    backdropFilter: 'blur(30px)',
    boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.25)',
    boxSizing: 'border-box',
    borderRadius: '30px',
    border: '3px solid #EA4448',
    alignContent: 'center',
    justifyItems: 'center',
  },
  responsiveStyle('height', {
    xs: '500px',
    lg: '350px',
  }),
  responsiveStyle('padding', {
    xs: '5px',
    md: '40px',
    lg: '60px',
  }),
  responsiveStyle('gridTemplateColumns', {
    xs: '100%',
    lg: '50% 50%',
  }),
  responsiveStyle('gridTemplateRows', {
    xs: '40% 40%',
    lg: '100%',
  }),
);

const pricingInfoStyles = css(
  {
    display: 'grid',
    height: '100%',
    gridTemplateRows: '30px 50px 1fr',
    paddingRight: '35px',
  },
  responsiveStyle('paddingRight', {
    xs: '0px',
    lg: '35px',
  }),
  responsiveStyle('width', {
    xs: '90%',
    lg: '100%',
  }),
  responsiveStyle('gridTemplateRows', {
    xs: '30px 60px 1fr',
    lg: '30px 50px 1fr',
  }),
);

const pricingDetailStyles = css(
  {
    height: '100%',
  },
  responsiveStyle('width', {
    xs: '90%',
    lg: '100%',
  }),
  responsiveStyle('borderLeft', {
    xs: 'none',
    lg: '2px solid rgba(255, 255, 255, 0.4)',
  }),
  responsiveStyle('borderTop', {
    xs: '2px solid rgba(255, 255, 255, 0.4)',
    lg: 'none',
  }),
  responsiveStyle('paddingLeft', {
    xs: '0px',
    lg: '35px',
  }),
  responsiveStyle('paddingTop', {
    xs: '30px',
    lg: '0px',
  }),
);

const earlyAccessTextStyles = css({
  color: '#71E993',
  fontFamily:
    'DM Sans, Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  fontSize: '14px',
  lineHeight: '160%',
  fontWeight: 500,
  opacity: 0.7,
  letterSpacing: '1px',
});

const pricingTextStyles = css({
  color: 'white',
  fontFamily:
    'DM Sans, Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  fontSize: '15px',
  lineHeight: '160%',
  fontWeight: 400,
});

const pricingBenefitHeaderStyles = css(pricingTextStyles, {
  fontWeight: 600,
});

const benefitListStyles = css({
  display: 'grid',
  gridTemplateColumns: '32px 1fr',
  gridTemplateRows: 'repeat(4, 35px)',
  color: 'white',
  fontFamily:
    'DM Sans, Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  fontSize: '14px',
  lineHeight: '200%',
  fontWeight: 400,
  marginTop: '20px',
});

export function MarketingPricingCard({
  title,
  paragraph,
}: MarketingPricingCardProps) {
  return (
    <div css={styles}>
      <div css={pricingInfoStyles}>
        <span css={earlyAccessTextStyles}>Early Access</span>
        <HeaderText
          value={title}
          background={HeaderTextBackground.SOLID_WHITE}
          fontSize="24px"
          fontWeight={600}
          textAlign={HeaderTextAlign.LEFT}
        />
        <span css={pricingTextStyles}>{paragraph}</span>
      </div>
      <div css={pricingDetailStyles}>
        <span css={pricingBenefitHeaderStyles}>This Plan Includes:</span>
        <div css={benefitListStyles}>
          <Checkbox style={{ marginTop: '4px' }} />
          <span>
            <b>Discord community</b> with participants
          </span>
          <Checkbox style={{ marginTop: '4px' }} />
          <span>
            <b>Feedback</b> on your <b>Pre-Release Music</b>
          </span>
          <Checkbox style={{ marginTop: '4px' }} />
          <span>
            <b>Insights</b> from <b>Fan Remixes</b>
          </span>
          <Checkbox style={{ marginTop: '4px' }} />
          <span>
            A Private <b>Email List</b> with All Fans
          </span>
        </div>
      </div>
    </div>
  );
}
