import typia from 'typia';
import { SchemaHelpersFor } from './schema-helpers';
import { SerializableResult } from './serializable-result';
import { StatusCode } from './status-code';

export type SimpleSuccess = SerializableResult.OkWithStatusCode<
  true,
  StatusCode.OK
>;

export namespace SimpleSuccess {
  export const schema: SchemaHelpersFor<SimpleSuccess> = {
    is: typia.createIs<SimpleSuccess>(),
    assert: typia.createAssert<SimpleSuccess>(),
    validate: typia.createValidate<SimpleSuccess>(),
    stringify: typia.json.createStringify<SimpleSuccess>(),
    clone: typia.misc.createClone<SimpleSuccess>(),
  };
}
