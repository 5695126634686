/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const inputStyles = css({
  marginRight: '8px',
});

const valueStyles = css({
  fontWeight: '600',
  fontSize: '20px',
  color: '#FFFFFF',
});

// const unitStyles = css({
//   fontWeight: '400',
//   fontSize: '16px',
//   color: '#FFFFFF',
// });

const labelStyles = css({
  display: 'block',
});

export type RadioInputProps = {
  name: string;
  value: string | number;
  required: boolean;
  selected: boolean;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export function RadioInput({
  name,
  value,
  required,
  selected,
  onChange,
}: RadioInputProps) {
  const id = `${name}-${value}`;

  return (
    <div>
      <input
        css={inputStyles}
        type="radio"
        id={id}
        name={name}
        required={required}
        value={value}
        checked={selected}
        onChange={onChange}
      />

      <label htmlFor={id} css={labelStyles}>
        <span css={valueStyles}>{value}</span>
        {/* <span css={unitStyles}>s</span> */}
      </label>
    </div>
  );
}
