/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { responsiveStyle } from '../constants/media-query';

const carouselSyles = css(
  {
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'space-around',
    rowGap: '32px',
    columnGap: '16px',
    padding: '64px 0px',
    boxSizing: 'border-box',
  },
  responsiveStyle('gridTemplateColumns', {
    xs: '1fr',
    sm: '1fr',
    md: '1fr',
    lg: '1fr 1fr',
    xl: '1fr 1fr',
  }),
);

export function RecordlessCardCarousel({
  children,
}: { children: React.ReactNode }) {
  return <div css={carouselSyles}>{children}</div>;
}
