/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type ForwardButtonProps = {
  style: Interpolation<Theme>;
};

export function ForwardButton({ style }: ForwardButtonProps) {
  return (
    <svg
      width="25"
      height="21"
      viewBox="0 0 25 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <path
        d="M19.3771 20.6055H22.2716C22.8966 20.6055 23.3654 20.4453 23.6779 20.125C23.9904 19.8125 24.1466 19.3477 24.1466 18.7305L24.1466 2.34766C24.1466 1.73047 23.9904 1.26562 23.6779 0.953125C23.3654 0.632812 22.8966 0.472656 22.2716 0.472656L19.3771 0.472656C18.7599 0.472656 18.2912 0.632812 17.9709 0.953125C17.6584 1.26562 17.5021 1.73047 17.5021 2.34766L17.5021 18.7305C17.5021 19.3477 17.6584 19.8125 17.9709 20.125C18.2834 20.4453 18.7521 20.6055 19.3771 20.6055ZM2.2443 20.3242C2.53336 20.3242 2.81071 20.2852 3.07633 20.207C3.34977 20.1289 3.62321 20.0078 3.89664 19.8438L16.0021 12.7656C16.4787 12.4844 16.8576 12.1641 17.1388 11.8047C17.4201 11.4375 17.5607 11.0117 17.5607 10.5273C17.5607 10.043 17.424 9.62891 17.1505 9.28516C16.8771 8.93359 16.4943 8.61328 16.0021 8.32422L3.89664 1.23437C3.61539 1.07031 3.34196 0.949219 3.07633 0.871094C2.81071 0.792969 2.52946 0.753906 2.23258 0.753906C1.63102 0.753906 1.11149 0.972656 0.673986 1.41016C0.244299 1.83984 0.0294552 2.46094 0.0294552 3.27344L0.0294552 17.8164C0.0294552 18.6211 0.248205 19.2383 0.685705 19.668C1.12321 20.1055 1.64274 20.3242 2.2443 20.3242Z"
        fill="white"
      />
    </svg>
  );
}
