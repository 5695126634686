/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { LoggerContext } from '@inkibra/api-base';
import { InkibraRecordlessBillingApiFetcherRegistry } from '@inkibra/recordless.billing-api';
import { useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../components/button';
import { ExclamationBubble } from '../components/icons/exclamation-bubble';
import { ToneTempoConfigContext } from './config';
import { ErrorRoute, ExtractRoutePathParams } from './routes';

const styles = css({
  maxWidth: '400px',
  margin: 'auto',
  width: '100%',
  height: '100%',
  display: 'grid',
  gridTemplateRows: 'min-content 1fr min-content min-content',
  gridTemplateColumns: '1fr',
  justifyContent: 'center',
  padding: '0px 8px 16px',
  rowGap: '16px',
});

const topSectionStyles = css({
  textAlign: 'center',
  width: '100%',
  paddingTop: 'env(safe-area-inset-top)',
  overflow: 'auto',
  margin: '64px 0',
});

const preparingStyles = css({
  fontWeight: '300',
  fontSize: '14px',
  lineHeight: '100%',
  color: '#F2F2F7',
});

const errorStyles = css({
  fontWeight: '900',
  fontSize: '50px',
  color: '#D81919',
  margin: '24px 0',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxHeight: '2em',
  width: '100%',
  lineHeight: '1em',
});

const midSectionStyles = css({
  textAlign: 'center',
});

const unableStyles = css({
  fontWeight: '300',
  fontSize: '12px',
  color: '#F2F2F7',
  margin: '24px 0',
});

type ErrorHandleButtonProps = ExtractRoutePathParams<typeof ErrorRoute>;

function ErrorHandleButton(props: ErrorHandleButtonProps) {
  const toneTempoConfig = useContext(ToneTempoConfigContext)();
  switch (props.reason) {
    case 'SUBSCRIPTION_CHECK_FAILURE':
      return (
        <Button
          type="button"
          contents="SUBSCRIBE"
          fillMode="outline"
          color="error"
          onClick={async () => {
            const checkoutSessionResult =
              await InkibraRecordlessBillingApiFetcherRegistry.get(
                'createCheckoutSession',
              ).fn({
                pathParams: {},
                pathQuery: {},
                body: {
                  priceId: toneTempoConfig.STRIPE_TEMPO_SUBSCRIPTION_PRICE_ID,
                  successUrl: document.location.origin, // TODO: add query param for success and display a toast
                  cancelUrl: document.location.origin, // TODO: add query param for cancellation and display a toast
                },
                files: undefined,
              });

            window.location.href = checkoutSessionResult.value.url;
          }}
        />
      );
    default:
      return null;
  }
}

export function ErrorPage() {
  const logger = useContext(LoggerContext)().child({ component: 'error' });
  const navigate = useNavigate();
  const { id, reason } = useParams<ExtractRoutePathParams<typeof ErrorRoute>>();

  if (!id || !reason) {
    throw new Error('No workout ID or reason provided');
  }

  if (reason !== 'SUBSCRIPTION_CHECK_FAILURE') {
    logger.error('Showing client error preparing workout', { id, reason });
  } else {
    logger.info('Showing client subscription check failure error', { id });
  }

  return (
    <div css={styles}>
      <div css={topSectionStyles}>
        <div css={preparingStyles}>UNABLE TO PREPARE YOUR MIX</div>
        <div css={errorStyles}>ERROR</div>
      </div>

      <div css={midSectionStyles}>
        <ExclamationBubble />

        <div css={unableStyles}>
          We are unable to prepare your mix, check again or use the buttons
          below
        </div>
      </div>

      <ErrorHandleButton id={id} reason={reason} />

      <Button
        type="button"
        contents="GO BACK"
        fillMode="outline"
        onClick={() => navigate(-1)}
      />
    </div>
  );
}
