/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type PlayButtonProps = {
  style: Interpolation<Theme>;
};

export function PlayButton({ style }: PlayButtonProps) {
  return (
    <svg
      width="36"
      height="40"
      viewBox="0 0 36 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <path
        style={{ pointerEvents: 'none' }}
        d="M3.26563 39.9531C3.73438 39.9531 4.17969 39.8672 4.60156 39.6953C5.02344 39.5391 5.46875 39.3203 5.9375 39.0391L33.2656 23.2422C34.25 22.6641 34.9297 22.1562 35.3047 21.7187C35.6953 21.2656 35.8906 20.7266 35.8906 20.1016C35.8906 19.4766 35.6953 18.9453 35.3047 18.5078C34.9297 18.0547 34.25 17.5469 33.2656 16.9844L5.9375 1.16406C5.46875 0.898437 5.02344 0.6875 4.60156 0.53125C4.17969 0.359375 3.73438 0.273437 3.26562 0.273437C2.40625 0.273437 1.71875 0.578125 1.20313 1.1875C0.703125 1.79687 0.453125 2.60938 0.453125 3.625L0.453125 36.5781C0.453125 37.5938 0.703125 38.4062 1.20313 39.0156C1.71875 39.6406 2.40625 39.9531 3.26563 39.9531Z"
        fill="white"
      />
    </svg>
  );
}
