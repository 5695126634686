/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  Button,
  ButtonBackgroundColor,
  responsiveStyle,
} from '@inkibra/recordless.ux';

const textStyles = css({
  fontFamily: "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '19px',
  letterSpacing: '-0.25px',
  color: 'white',
  userSelect: 'none',
  pointerEvents: 'none',
  margin: 0,
});

export type AlbumInformationDisplayProps = {
  artworkPath: string;
  artist: string;
  albumName: string;
  addedToLibraryDate: Date;
  onPlay: () => void;
};

export function AlbumInformationDisplay(props: AlbumInformationDisplayProps) {
  return (
    <div
      css={css(
        {
          display: 'grid',
          gridTemplateRows: '80px 30px 30px 30px 40px 40px',
          gridTemplateAreas: `
      "artwork a b"
      "artwork album-title album-title"
      "artwork album-artist album-artist"
      "artwork album-date c"
      "artwork d e"
      "artwork play-button f"
    `,
        },
        responsiveStyle('columnGap', {
          xs: '20px',
          sm: '60px',
        }),
        responsiveStyle('gridTemplateColumns', {
          xs: '150px 150px 1fr',
          sm: '250px 150px 1fr',
        }),
      )}
    >
      <img
        css={css(
          {
            gridArea: 'artwork',
            borderRadius: '4px',
            pointerEvents: 'none',
            userSelect: 'none',
          },
          responsiveStyle('width', {
            xs: '150px',
            sm: '250px',
          }),
          responsiveStyle('height', {
            xs: '150px',
            sm: '250px',
          }),
        )}
        src={props.artworkPath}
      />
      <h2
        css={css(textStyles, {
          gridArea: 'album-title',
          letterSpacing: '1.25px',
          fontWeight: 500,
          fontSize: '25px',
          lineHeight: '25px',
        })}
      >
        {props.albumName}
      </h2>
      <h2
        css={css(textStyles, {
          gridArea: 'album-artist',
          fontWeight: 400,
          letterSpacing: '1.25px',
          fontSize: '25px',
          lineHeight: '25px',
          color: 'rgba(211, 211, 252, 0.5)',
        })}
      >
        {props.artist}
      </h2>
      <h3
        css={css(textStyles, {
          gridArea: 'album-date',
          fontWeight: 400,
          letterSpacing: '1.25px',
          fontSize: '14px',
          lineHeight: '18px',
          color: 'rgba(211, 211, 252, 0.5)',
        })}
      >
        {props.addedToLibraryDate.toLocaleDateString()}
      </h3>
      <Button
        buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
        contents="Play"
        color="white"
        gridArea="play-button"
        onClick={props.onPlay}
      />
    </div>
  );
}
