/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { EditableIntervalListItem } from './editable-interval-list-item';

import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  MouseSensor,
  SortableContext,
  TouchSensor,
  arrayMove,
  useSensor,
  useSensors,
} from '@inkibra/recordless.ux';
import { InkibraRecordlessWorkoutIntervalType } from '../type';

const styles = css({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridAutoRows: 'min-content',
  gap: '16px',
});

export type EditableIntervalListProps = {
  intervals: InkibraRecordlessWorkoutIntervalType[];
  onIntervalMenuClick: (
    index: number,
    { x, y }: { x: number; y: number },
  ) => void;
  onReorder: (
    intervals: InkibraRecordlessWorkoutIntervalType[],
  ) => Promise<boolean>;
  editLocked: boolean;
};

export function EditableIntervalList({
  intervals,
  onIntervalMenuClick,
  onReorder,
  editLocked,
}: EditableIntervalListProps) {
  const [localIntervals, setLocalIntervals] = useState(intervals);

  useEffect(
    function setIntervalFromProps() {
      setLocalIntervals(intervals);
    },
    [intervals],
  );

  const reorder = (e: DragEndEvent) => {
    const { active, over } = e;

    if (over && active.id !== over.id) {
      const oldIndex = intervals.findIndex(
        (item, i) => i + item.name === active.id,
      );

      const newIndex = intervals.findIndex(
        (item, i) => i + item.name === over.id,
      );

      const newIntervals = arrayMove(intervals, oldIndex, newIndex);

      setLocalIntervals(newIntervals);

      onReorder(newIntervals);
    }
  };
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 10,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  return (
    <DndContext
      onDragEnd={reorder}
      onDragStart={() => {
        if (window.navigator.vibrate) {
          // TODO: verify is this works on PWA mode.
          window.navigator.vibrate(100);
        }
      }}
      sensors={sensors}
    >
      <div css={styles}>
        <SortableContext
          items={localIntervals.map((interval, index) => {
            return {
              ...interval,
              id: index + interval.name,
            };
          })}
        >
          {localIntervals.map((data, index) => (
            <EditableIntervalListItem
              key={index + data.name}
              index={index}
              sortKey={index + data.name}
              interval={data}
              editLocked={editLocked}
              onIntervalMenuClick={onIntervalMenuClick}
            />
          ))}
        </SortableContext>
      </div>
    </DndContext>
  );
}
