/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import {
  AuthenticatedSessionContext,
  InkibraSessionRoles,
} from '@inkibra/recordless.auth-api';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { InkibraRecordlessTempoApiFetcherRegistry } from '../api';
import { Button } from '../components/button';
import { EditableIntervalList } from '../components/editable-interval-list';
import { Back } from '../components/icons/back';
import { RunningMan } from '../components/icons/running-man';
import { Share } from '../components/icons/share';
import { VerticalMenu } from '../components/icons/vertical-menu';
import { IntervalEditor } from '../components/interval-editor';
import { TabataIntervalCreator } from '../components/tabata-interval-creator';
import { WorkoutDetails } from '../components/workout-details';
import { WorkoutEditor } from '../components/workout-editor';
import { WorkoutSessionContext } from '../components/workout-session-context';
import { WorkoutStartButton } from '../components/workout-start-button';
import { useWorkout } from '../hooks';
import {
  InkibraRecordlessWorkoutIntervalType,
  InkibraRecordlessWorkoutType,
} from '../type';
import { BrowseRoute, ExtractRoutePathParams, WorkoutRoute } from './routes';

const styles = css({
  maxWidth: '1000px',
  margin: 'auto',
  width: '100%',
  display: 'grid',
  gridTemplateRows: '637px 1fr',
  gap: '25px',
  paddingTop: 'calc(env(safe-area-inset-top) + 45px)',
});

const appBarStyles = css({
  label: 'app-bar',
  maxWidth: '1000px',
  display: 'grid',
  position: 'fixed',
  top: 0,
  width: '100%',
  gridTemplateColumns: 'min-content min-content',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '8px',
  paddingTop: 'env(safe-area-inset-top)',
  zIndex: 100,
  backgroundColor: 'rgba(8, 3, 29, 0.8)',
  backdropFilter: 'blur(10px)',
});

const topSectionStyles = css({
  label: 'top-section',
  width: '100%',
  display: 'grid',
  gridTemplateRows: '1fr',
  alignItems: 'center',
  backgroundImage: 'linear-gradient(0deg, #08031D 0%, #52FF00 88.9%)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  paddingTop: 'env(safe-area-inset-top)',
});

const workoutStyles = css({
  label: 'workout-styles',
  display: 'grid',
  gridTemplateRows: 'min-content min-content min-content',
  height: '100%',
  backgroundImage:
    'linear-gradient(180deg, rgba(36, 14, 131, 0) 32%, #08031D 100%)',
  padding: '0 8px',
  alignContent: 'end',
});

const workoutIconAlignmentStyles = css({
  textAlign: 'center',
});

const bottomSectionStyles = css({
  label: 'bottom-section',
  width: '100%',
  marginTop: '-10px',
  padding: '0 8px 16px',
  display: 'grid',
  gridTemplateRows: 'min-content min-content min-content',
  gap: '25px',
});

const aboutWorkoutTitleStyles = css({
  label: 'about-workout-title',
  fontWeight: '600',
  fontSize: '12px',
  color: '#FFFFFF',
  marginTop: '16px',
  marginBottom: '8px',
});

const aboutWorkoutContentStyles = css({
  label: 'about-workout-content',
  wordBreak: 'break-word',
  fontWeight: '300',
  fontSize: '12px',
  lineHeight: '150%',
  color: '#989898',
});

const appBarRightStyles = css({
  display: 'grid',
  gridTemplateColumns: 'min-content min-content',
  gap: '16px',
  alignItems: 'center',
});

const verticalMenuStyles = css({
  display: 'grid',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
});

// const contextStyles = css({
//   fontWeight: '600',
//   fontSize: '12px',
//   padding: '12px',
//   borderRadius: '7px',
// });

// const renameStyles = css({
//   color: '#08031D',
//   backgroundColor: '#FFFFFF',
//   padding: '12px',
//   cursor: 'default',
// });

// const deleteStyles = css({
//   color: '#FF0000',
//   backgroundColor: '#000000',
//   padding: '12px',
//   cursor: 'default',
// });

type WorkoutDetailsHeaderProps =
  | {
      workout: InkibraRecordlessWorkoutType;
      background: 'running-man' | 'stretch';
    }
  | {
      workout: InkibraRecordlessWorkoutType;
      background: 'image';
      imageUrl: string;
    };
function WorkoutDetailsHeader(props: WorkoutDetailsHeaderProps) {
  if (props.background === 'image') {
    return (
      <div
        css={css(topSectionStyles, {
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.37) 8%, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 1) 100%), url(${props.imageUrl})`,
        })}
      >
        <div css={workoutStyles}>
          <WorkoutDetails workout={props.workout} />
          <div css={aboutWorkoutTitleStyles}>ABOUT THIS WORKOUT</div>
          <div css={aboutWorkoutContentStyles}>
            {props.workout.description ??
              "Get ready to take your fitness journey to the next level. Create, re-order and edit your workout's intervals to craft your ideal workout routine. Queue the workout when you're ready."}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div css={topSectionStyles}>
      <div css={workoutIconAlignmentStyles}>
        <RunningMan />
      </div>
      <div css={workoutStyles}>
        <WorkoutDetails workout={props.workout} />
        <div css={aboutWorkoutTitleStyles}>ABOUT THIS WORKOUT</div>
        <div css={aboutWorkoutContentStyles}>
          {props.workout.description ??
            "Get ready to take your fitness journey to the next level. Create, re-order and edit your workout's intervals to craft your ideal workout routine. Queue the workout when you're ready."}
        </div>
      </div>
    </div>
  );
}

type EditorMode =
  | {
      type:
        | 'edit-workout-metadata'
        | 'create-single-interval'
        | 'tabata-create-intervals'
        | 'closed';
      interval?: InkibraRecordlessWorkoutIntervalType;
    }
  | {
      type: 'edit-single-interval';
      interval: InkibraRecordlessWorkoutIntervalType;
    };

export function Workout() {
  const navigate = useNavigate();
  const cache = useContext(CacheContext)();
  const workoutSession = useContext(WorkoutSessionContext)();
  const sessionContext = useContext(AuthenticatedSessionContext)();
  const isWorkoutAdmin = sessionContext.session.roles.includes(
    InkibraSessionRoles.WORKOUT_ADMIN,
  );

  const { id } = useParams<ExtractRoutePathParams<typeof WorkoutRoute>>();

  if (!id) {
    throw new ReferenceError('Expected Id in URL Params');
  }

  const hookEffectContext = new HookEffectContext();
  const workout = hookEffectContext.addEffect(useWorkout(id));
  hookEffectContext.runEffects(cache);
  const [workoutContextMenuProps, toggleWorkoutContextMenu] = useMenuState();
  const [workoutContextMenuAnchor, setWorkoutContextMenuAnchor] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [intervalContextMenuProps, toggleIntervalContextMenu] = useMenuState();
  const [intervalContextMenuAnchor, setIntervalContextMenuAnchor] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [intervalIndexForContextMenu, setIntervalIndexForContextMenu] =
    useState<number>();

  const [editorMode, setEditorMode] = useState<EditorMode>({ type: 'closed' });

  if (!workout) {
    return null;
  }

  const editLocked = workout.workoutType !== 'USER_WORKOUT';

  const onEditWorkoutMetadata = async (name: string) => {
    if (editLocked) {
      return false;
    }
    const response = await InkibraRecordlessTempoApiFetcherRegistry.get(
      'modifyInkibraRecordlessWorkout',
    ).fn({
      pathParams: { id: workout.id },
      pathQuery: {},
      body: { name },
      files: undefined,
    });

    cache.input.next(response.value);

    setEditorMode({ type: 'closed' });

    return true;
  };

  const handleDuplicateWorkout = async () => {
    const response = await InkibraRecordlessTempoApiFetcherRegistry.get(
      'createInkibraRecordlessWorkout',
    ).fn({
      pathParams: {},
      pathQuery: {},
      body: {
        name: workout.name,
        workoutType: InkibraRecordlessWorkoutType.WorkoutType.USER_WORKOUT,
        intervals: workout.intervals,
      },
      files: undefined,
    });

    cache.input.next(response.value);

    navigate(WorkoutRoute.makeRouteLink({ id: response.value.id }));

    return true;
  };

  const changeWorkoutCatalogType = async (
    workoutType: InkibraRecordlessWorkoutType.WorkoutType,
  ) => {
    const response = await InkibraRecordlessTempoApiFetcherRegistry.get(
      'modifyInkibraRecordlessWorkout',
    ).fn({
      pathParams: { id: workout.id },
      pathQuery: {},
      body: {
        workoutType,
      },
      files: undefined,
    });

    cache.input.next(response.value);

    return true;
  };

  const handleDeleteWorkout = async () => {
    if (editLocked) {
      return false;
    }
    const response = await InkibraRecordlessTempoApiFetcherRegistry.get(
      'deleteInkibraRecordlessWorkout',
    ).fn({
      pathParams: { id: workout.id },
      pathQuery: {},
      body: undefined,
      files: undefined,
    });

    cache.input.next(response.value);

    navigate(BrowseRoute.makeRouteLink({}));

    return true;
  };

  const handleIntervalModification = async (
    intervals: InkibraRecordlessWorkoutIntervalType[],
  ) => {
    if (editLocked) {
      return false;
    }
    const response = await InkibraRecordlessTempoApiFetcherRegistry.get(
      'modifyInkibraRecordlessWorkout',
    ).fn({
      pathParams: { id: workout.id },
      pathQuery: {},
      body: {
        intervals,
      },
      files: undefined,
    });

    cache.input.next(response.value);

    return true;
  };

  const handleIntervalMenuClick = (
    index: number,
    { x, y }: { x: number; y: number },
  ) => {
    if (editLocked) {
      return;
    }
    setIntervalIndexForContextMenu(index);
    toggleIntervalContextMenu(true);
    setIntervalContextMenuAnchor({ x, y });
  };

  return (
    <>
      <div
        css={styles}
        style={{
          display: (
            [
              'create-single-interval',
              'edit-single-interval',
              'tabata-create-intervals',
            ] as EditorMode['type'][]
          ).includes(editorMode.type)
            ? 'none'
            : 'grid',
        }}
      >
        <div css={appBarStyles}>
          <div onClick={() => navigate(BrowseRoute.makeRouteLink({}))}>
            <Back />
          </div>

          <div css={appBarRightStyles}>
            <Share workout={workout} />

            <div
              css={[
                verticalMenuStyles,
                workoutContextMenuProps.state === 'open' && {
                  background: 'rgba(0, 0, 0, 0.2)',
                },
              ]}
              onClick={(e) => {
                e.stopPropagation();
                toggleWorkoutContextMenu(true);
                setWorkoutContextMenuAnchor({
                  x: e.clientX,
                  y: e.clientY,
                });
              }}
            >
              <VerticalMenu />
            </div>
            <ControlledMenu
              {...workoutContextMenuProps}
              anchorPoint={workoutContextMenuAnchor}
              direction="right"
              onClose={() => toggleWorkoutContextMenu(false)}
            >
              {editLocked === false && (
                <MenuItem
                  onClick={() =>
                    setEditorMode({ type: 'edit-workout-metadata' })
                  }
                >
                  Rename Workout
                </MenuItem>
              )}
              {editLocked === false && (
                <MenuItem onClick={handleDeleteWorkout}>
                  Delete Workout
                </MenuItem>
              )}
              <MenuItem onClick={handleDuplicateWorkout}>
                Duplicate Workout
              </MenuItem>
              {isWorkoutAdmin && (
                <>
                  <MenuItem
                    onClick={() => {
                      changeWorkoutCatalogType(
                        InkibraRecordlessWorkoutType.WorkoutType.USER_WORKOUT,
                      );
                    }}
                  >
                    Set as User Workout
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      changeWorkoutCatalogType(
                        InkibraRecordlessWorkoutType.WorkoutType
                          .CATALOG_WORKOUT,
                      );
                    }}
                  >
                    Set as Catalog Workout
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      changeWorkoutCatalogType(
                        InkibraRecordlessWorkoutType.WorkoutType
                          .NEW_CATALOG_WORKOUT,
                      );
                    }}
                  >
                    Feature Workout
                  </MenuItem>
                </>
              )}
            </ControlledMenu>
          </div>
        </div>
        {workout.backgroundImage ? (
          <WorkoutDetailsHeader
            workout={workout}
            background="image"
            imageUrl={workout.backgroundImage}
          />
        ) : (
          <WorkoutDetailsHeader workout={workout} background="running-man" />
        )}

        <div css={bottomSectionStyles}>
          <WorkoutStartButton workout={workout} />

          <EditableIntervalList
            intervals={workout.intervals}
            editLocked={editLocked}
            onIntervalMenuClick={handleIntervalMenuClick}
            onReorder={handleIntervalModification}
          />

          {editLocked === false && (
            <Button
              type="button"
              fillMode="outline"
              contents="ADD INTERVAL"
              onClick={() => {
                workoutSession.hideTray();
                setEditorMode({ type: 'create-single-interval' });
              }}
            />
          )}
          {editLocked === false && (
            <Button
              type="button"
              fillMode="outline"
              contents="ADD MULTIPLE INTERVALS"
              onClick={() => {
                workoutSession.hideTray();
                setEditorMode({ type: 'tabata-create-intervals' });
              }}
            />
          )}
        </div>

        <WorkoutEditor
          creationMode={false}
          active={editorMode.type === 'edit-workout-metadata'}
          onSubmit={onEditWorkoutMetadata}
          onClose={() => setEditorMode({ type: 'closed' })}
        />
      </div>
      <IntervalEditor
        active={(
          [
            'edit-single-interval',
            'create-single-interval',
          ] as EditorMode['type'][]
        ).includes(editorMode.type)}
        existingInterval={editorMode.interval}
        // TODO: if we are editing an existing interval, we need to pass the interval data here
        onCancel={() => {
          setEditorMode({ type: 'closed' });
          workoutSession.showTray();
        }}
        onSubmit={async (interval) => {
          const intervals = workout.intervals;
          if (editorMode.interval) {
            if (intervalIndexForContextMenu === undefined) {
              throw new ReferenceError(
                'intervalIndexForContextMenu is undefined',
              );
            }
            intervals[intervalIndexForContextMenu] = interval;
          } else {
            intervals.push(interval);
          }

          await handleIntervalModification(intervals);

          setEditorMode({ type: 'closed' });
          workoutSession.showTray();
          return true;
        }}
      />
      <TabataIntervalCreator
        active={editorMode.type === 'tabata-create-intervals'}
        onCancel={() => {
          setEditorMode({ type: 'closed' });
          workoutSession.showTray();
        }}
        onSubmit={async (newIntervals) => {
          const intervals = workout.intervals.concat(newIntervals);
          await handleIntervalModification(intervals);
          setEditorMode({ type: 'closed' });
          workoutSession.showTray();
          return true;
        }}
      />
      <ControlledMenu
        {...intervalContextMenuProps}
        anchorPoint={intervalContextMenuAnchor}
        direction="right"
        onClose={() => toggleIntervalContextMenu(false)}
      >
        <MenuItem
          onClick={() => {
            if (intervalIndexForContextMenu === undefined) {
              return;
            }
            const editingInterval =
              workout.intervals[intervalIndexForContextMenu];
            if (!editingInterval) {
              return;
            }
            workoutSession.hideTray();
            setEditorMode({
              type: 'edit-single-interval',
              interval: editingInterval,
            });
          }}
        >
          Edit Interval
        </MenuItem>
        <MenuItem
          onClick={async () => {
            if (intervalIndexForContextMenu === undefined) {
              return;
            }
            const intervals = workout.intervals;
            intervals.splice(intervalIndexForContextMenu, 1);
            await handleIntervalModification(intervals);
          }}
        >
          Delete Interval
        </MenuItem>
      </ControlledMenu>
    </>
  );
}
