/** @jsxImportSource @emotion/react */

import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  SortableContext,
  closestCenter,
  sortableKeyboardCoordinates,
  useSensor,
  useSensors,
  verticalListSortingStrategy,
} from '@inkibra/recordless.ux';

import { ok } from 'neverthrow';
import type { PointerEvent } from 'react';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibraryMixType } from '../type-mix';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { MixNodeDisplay, MixNodeDisplayProps } from './mix-node-display';

export type MixNodesDisplayProps = {
  mixData: InkibraRecordlessLibraryMixType;
  containerRef: React.RefObject<HTMLDivElement>;
  onMixUpdate?: (newMix: InkibraRecordlessLibraryMixType) => void;
  onPlay?: (item: MixNodeDisplayProps, key: number) => void;
};

/**
 * An extended "PointerSensor" that prevent some
 * interactive html element(button, input, textarea, select, option...) from dragging
 */
export class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      // biome-ignore lint/suspicious/noExplicitAny: PointerSensor defines eventName as any
      eventName: 'onPointerDown' as any,
      handler: ({ nativeEvent: event }: PointerEvent) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target as Element)
        ) {
          return false;
        }
        console.log(event);
        console.log('returning true');
        return true;
      },
    },
  ];
}

function isInteractiveElement(element: Element | null) {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option',
  ];
  if (
    element?.tagName &&
    interactiveElements.includes(element.tagName.toLowerCase())
  ) {
    return true;
  }

  return false;
}

export function MixNodesDisplay({
  onMixUpdate,
  onPlay,
  mixData,
  containerRef,
}: MixNodesDisplayProps) {
  const sensors = useSensors(
    useSensor(SmartPointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

  const orderedItems = mixData.orderedMixNodes.map((mixNode) => {
    const arrangement = mixNode.currentLibraryArrangement;
    const arrangementSong = arrangement.librarySong;
    const arrangementDuration = arrangementUtil
      .getOrComputeArrangementInfo(arrangement)
      .andThen((info) => ok(info.duration))
      .unwrapOr(0);
    const albumSlug = songUtil.getAlbumSlug(arrangement.librarySong);

    return {
      id: mixNode.id,
      title: arrangementSong.title,
      artist: arrangementSong.artists.join(', '),
      arrangement: arrangement.name,
      album: arrangementSong.albumTitle,
      duration: arrangementDuration,
      albumSlug: albumSlug,
      mixNode: mixNode,
      mix: mixData,
      containerRef,
    };
  });

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={
        onMixUpdate
          ? (event) => {
              const { active, over } = event;

              if (over && active.id !== over.id) {
                const oldIndex = orderedItems.findIndex(
                  (item, i) => `${item.id}-${i}` === active.id,
                );
                const newIndex = orderedItems.findIndex(
                  (item, i) => `${item.id}-${i}` === over.id,
                );

                const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({});
                const newMixResult = mixUtil.swapMixNodes(
                  mixData,
                  oldIndex,
                  newIndex,
                );
                if (newMixResult.isOk()) {
                  onMixUpdate(newMixResult.value);
                }
              }
            }
          : undefined
      }
    >
      <SortableContext
        disabled={!onMixUpdate}
        items={orderedItems.map((item, i) => {
          return {
            ...item,
            id: `${item.id}-${i}`,
          };
        })}
        strategy={verticalListSortingStrategy}
      >
        {orderedItems.map((item, i) => (
          <MixNodeDisplay
            key={`${item.id}-${i}`}
            sortKey={`${item.id}-${i}`}
            {...item}
            mix={mixData}
            mixNode={item.mixNode}
            onClick={
              onPlay
                ? () => {
                    onPlay(item, i);
                  }
                : undefined
            }
          />
        ))}
      </SortableContext>
    </DndContext>
  );
}
