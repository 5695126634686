import { ErrorType } from './error-type';

/**
 * @deprecated
 */
export type UNCAUGHT_ERROR_TYPE = ErrorType<
  'UNCAUGHT',
  `Uncaught error purpose: ${string}`
>;

/**
 * @deprecated
 */
export const ErrorCodes = {
  UNCAUGHT: new ErrorType('UNCAUGHT') as UNCAUGHT_ERROR_TYPE,
};
