/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { InkibraRecordlessWorkoutIntervalType } from '../type';
import { Button } from './button';
import { RangeInput } from './range-input';
import { TextArea } from './text-area';
import { TextInput } from './text-input';
import { ValueSliderInput } from './value-slider-input';

const styles = css({
  maxWidth: '600px',
  margin: 'auto',
  marginTop: 'calc(env(safe-area-inset-top) + 15px)',
  width: '100%',
  height: '100%',
  padding: '16px 8px 16px',
  zIndex: 10001,
  display: 'none',
  gridTemplateRows: 'min-content min-content min-content',
  background: '#08031D',
  rowGap: '24px',
});

const titleStyles = css({
  fontWeight: '700',
  fontSize: '24px',
  color: '#F2F2F7',
});

const buttonWrapperStyles = css({
  display: 'grid',
  gridTemplateRows: 'min-content min-content',
  gap: '24px',
});

export type IntervalEditorProps = {
  active: boolean;
  onSubmit: (
    interval: InkibraRecordlessWorkoutIntervalType,
  ) => Promise<boolean>;
  /**
   * Use existing interval if we are modifying an existing interval
   */
  existingInterval?: InkibraRecordlessWorkoutIntervalType;
  onCancel: () => void;
};

export function IntervalEditor({
  active,
  onSubmit,
  onCancel,
  existingInterval,
}: IntervalEditorProps) {
  const [name, setName] = useState(existingInterval?.name ?? '');
  const [comments, setComments] = useState(existingInterval?.comments ?? '');
  const [time, setTime] = useState(existingInterval?.time ?? 30);
  const [energy, setEnergy] = useState(existingInterval?.energy ?? 8);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (existingInterval) {
      setName(existingInterval.name);
      setComments(existingInterval.comments);
      setTime(existingInterval.time);
      setEnergy(existingInterval.energy);
    } else if (existingInterval === undefined) {
      setName('');
      setComments('');
      setTime(60);
      setEnergy(8);
    }
  }, [existingInterval]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitting(true);

    const interval = {
      name,
      comments,
      time,
      energy,
    } satisfies InkibraRecordlessWorkoutIntervalType;

    const success = await onSubmit(interval);

    if (success) {
      setName('');
      setComments('');
    }

    setSubmitting(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setName('');
    setComments;
    onCancel();
  };

  return (
    <div css={[styles, active && { display: 'grid' }]}>
      <div css={titleStyles}>INTERVAL EDITOR</div>

      <form
        css={{
          label: 'form-group-wrapper',
          display: 'grid',
          gridAutoRows: 'min-content',
          gridAutoFlow: 'row',
          gridTemplateColumns: '1fr',
          rowGap: '24px',
        }}
        id="intervalForm"
        onSubmit={handleSubmit}
        noValidate
      >
        <TextInput
          name="name"
          label="INTERVAL NAME"
          placeholder="E.g., Push Ups, Rest, Plank"
          required={true}
          value={name}
          onChange={(e) => setName(e.target.value.slice(0, 18))}
        />
        <TextArea
          name="comments"
          label="TRAINER VOICE TIPS"
          placeholder="E.g., Stretch and prepare for the next interval, focus on range of motion, push explosively."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <ValueSliderInput
          title="INTERVAL DURATION"
          subtitle="Choose the duration of the interval in seconds."
          name="time"
          min={30}
          max={60 * 10}
          stepSize={5}
          initialValue={time}
          label="minutes:seconds"
          formatter="time-from-seconds"
          onChange={(e) => setTime(e)}
          speed={1}
        />
        <RangeInput
          title="SONG ENERGY LEVEL"
          subtitle="Higher energy levels are great for intense movements, while lower energy levels are better for recovery or rest periods."
          name="energy"
          min={0}
          max={10}
          value={energy}
          onChange={(e) => setEnergy(e)}
        />
      </form>

      <div css={buttonWrapperStyles}>
        <Button
          type="submit"
          form="intervalForm"
          color="primary"
          disabled={submitting || !name}
          contents="SAVE"
        />

        <Button
          onClick={handleCancel}
          type="button"
          fillMode="outline"
          contents="CANCEL"
        />
      </div>
    </div>
  );
}
