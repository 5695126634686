import { MimeType } from '../constants';

export type SingleFile<TMimeType extends MimeType> = {
  allowableMimeTypes: TMimeType[];
};

export type MultipleFiles<TMimeType extends MimeType> = {
  allowableMimeTypes: TMimeType[];
  maxCount: number;
};

/**
 * FileManifest is a type that represents a manifest of files.
 */
export type FileInputDescription = {
  [name: string]: SingleFile<MimeType> | MultipleFiles<MimeType>;
};
