/** @jsxImportSource react */
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';

import { HookEffectContext } from '@inkibra/api-base';
import { useWaitlistsEntries } from '@inkibra/recordless.waitlist-api';
import { Connector } from '../../common/lib/connector';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 325 },
  { field: 'email', headerName: 'Email', width: 225 },
  { field: 'name', headerName: 'Name', width: 250 },
  { field: 'wallet', headerName: 'Wallet Address', width: 350 },
  { field: 'confirmed', headerName: 'Confirmed', width: 140, type: 'boolean' },
  {
    field: 'created',
    headerName: 'Date Created',
    sortable: false,
    width: 250,
  },
];

export default function WaitlistDisplay() {
  const { waitlistId } = useParams();
  if (waitlistId) {
    const connector = Connector.useContext();
    const hookEffectContext = new HookEffectContext();
    const waitlistEntries = hookEffectContext.addEffect(
      useWaitlistsEntries(waitlistId),
    );
    hookEffectContext.runEffects(connector.cache);

    return (
      <div style={{ display: 'flex', height: '80vh' }}>
        <div style={{ flexGrow: 1 }}>
          <DataGrid
            rows={waitlistEntries}
            columns={columns}
            checkboxSelection
          />
        </div>
      </div>
    );
  }
  throw new ReferenceError('Waitlist Id not found in location params');
}
