export type Filter<T, K extends keyof T> = Partial<{
  [P in keyof T]: P extends K
    ? T[P] extends number
      ? Filter.NumberFilter
      : T[P] extends string
        ? Filter.StringFilter
        : T[P] extends boolean
          ? Filter.BooleanFilter
          : T[P] extends Array<infer U>
            ? U extends number
              ? Filter.NumberArrayFilter
              : U extends string
                ? Filter.StringArrayFilter
                : never
            : never
    : never;
}>;

export namespace Filter {
  export type Filters =
    | StringFilter
    | NumberFilter
    | BooleanFilter
    | StringArrayFilter
    | NumberArrayFilter;

  export enum Operators {
    GREATER_THAN = 'gt',
    LESS_THAN = 'lt',
    BETWEEN = 'bt',
    EQUAL = 'eq',
    NOT_EQUAL = 'notEq',
    IN = 'in',
    NOT_IN = 'notIn',
    ANY_IN = 'anyIn',
    EVERY_IN = 'everyIn',
    NOT_ANY_IN = 'notAnyIn',
    NOT_EVERY_IN = 'notEveryIn',
  }

  export type NumberFilter =
    | {
        operator:
          | Operators.EQUAL
          | Operators.NOT_EQUAL
          | Operators.GREATER_THAN
          | Operators.LESS_THAN;
        value: number;
      }
    | {
        operator: Operators.IN | Operators.NOT_IN;
        values: number[];
      }
    | {
        operator: Operators.BETWEEN;
        firstValue: number;
        secondValue: number;
      };

  export type StringFilter =
    | {
        operator: Operators.EQUAL | Operators.NOT_EQUAL;
        value: string;
      }
    | {
        operator: Operators.IN | Operators.NOT_IN;
        values: string[];
      };

  export type BooleanFilter = {
    operator: Operators.EQUAL | Operators.NOT_EQUAL;
    value: boolean;
  };

  export type StringArrayFilter = {
    operator:
      | Operators.ANY_IN
      | Operators.EVERY_IN
      | Operators.NOT_ANY_IN
      | Operators.NOT_EVERY_IN;
    values: string[];
  };

  export type NumberArrayFilter = {
    operator:
      | Operators.ANY_IN
      | Operators.EVERY_IN
      | Operators.NOT_ANY_IN
      | Operators.NOT_EVERY_IN;
    values: number[];
  };
}
