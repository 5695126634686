/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { InkibraRecordlessWorkoutType } from '../type';
import { Button } from './button';
import { FormGroupWrapper } from './form-group-wrapper';
import { Check } from './icons/check';

const genreSelectorStyles = css({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  justifyItems: 'center',
  padding: '12px 14px',
});

const genreStyles = css({
  height: '90px',
  width: '90px',
  backgroundPosition: 'center',
  borderRadius: '12px',
  position: 'relative',
});

const genreRapStyles = css(
  {
    backgroundImage:
      'linear-gradient(0deg, rgba(63, 22, 225, 0.8), rgba(63, 22, 225, 0.8)), url(https://storage.googleapis.com/corp-inkibra-us-public-cdn/tempo/rap.png)',
  },
  genreStyles,
);

const genrePopStyles = css(
  {
    backgroundImage:
      'linear-gradient(0deg, rgba(63, 22, 225, 0.8), rgba(63, 22, 225, 0.8)), url(https://storage.googleapis.com/corp-inkibra-us-public-cdn/tempo/pop.png)',
  },
  genreStyles,
);

const genreEdmStyles = css(
  {
    backgroundImage:
      'linear-gradient(0deg, rgba(63, 22, 225, 0.8), rgba(63, 22, 225, 0.8)), url(https://storage.googleapis.com/corp-inkibra-us-public-cdn/tempo/edm.png)',
  },
  genreStyles,
);

const genreLabelStyles = css({
  position: 'absolute',
  bottom: '8px',
  left: '8px',
  fontWeight: '700',
  fontSize: '20px',
  color: '#F2F2F7',
});

const genreCircleStyles = css({
  width: '24px',
  height: '24px',
  border: '1px solid #52FF00',
  borderRadius: '50%',
  textAlign: 'center',
  position: 'absolute',
  top: '8px',
  right: '8px',
});

export type GenreSelectorProps = {
  values: InkibraRecordlessWorkoutType.WorkoutGenre[];
  onChange: (value: InkibraRecordlessWorkoutType.WorkoutGenre[]) => void;
};

export function GenreSelector({ values, onChange }: GenreSelectorProps) {
  const handleSelectAll = () => {
    onChange([
      InkibraRecordlessWorkoutType.WorkoutGenre.EDM,
      InkibraRecordlessWorkoutType.WorkoutGenre.POP,
      InkibraRecordlessWorkoutType.WorkoutGenre.RAP,
    ]);
  };

  const handleGenreClick = (value: InkibraRecordlessWorkoutType.WorkoutGenre) =>
    onChange(
      values.indexOf(value) === -1
        ? [...values, value]
        : values.filter((genre) => genre !== value),
    );

  return (
    <FormGroupWrapper
      title="MUSIC GENRES"
      subtitle="Choose the genres to listen to."
      formHeaderAction={
        <Button
          type="button"
          contents="Select All"
          fillMode="outline"
          color="white"
          buttonSize="small"
          onClick={handleSelectAll}
        />
      }
    >
      <div css={genreSelectorStyles}>
        <div
          css={genreEdmStyles}
          onClick={() =>
            handleGenreClick(InkibraRecordlessWorkoutType.WorkoutGenre.EDM)
          }
        >
          <div css={genreLabelStyles}>EDM</div>
          <div
            css={[
              genreCircleStyles,
              values.indexOf(InkibraRecordlessWorkoutType.WorkoutGenre.EDM) !==
                -1 && { backgroundColor: '#52FF00' },
            ]}
          >
            {values.indexOf(InkibraRecordlessWorkoutType.WorkoutGenre.EDM) !==
              -1 && <Check />}
          </div>
        </div>

        <div
          css={genrePopStyles}
          onClick={() =>
            handleGenreClick(InkibraRecordlessWorkoutType.WorkoutGenre.POP)
          }
        >
          <div css={genreLabelStyles}>POP</div>
          <div
            css={[
              genreCircleStyles,
              values.indexOf(InkibraRecordlessWorkoutType.WorkoutGenre.POP) !==
                -1 && { backgroundColor: '#52FF00' },
            ]}
          >
            {values.indexOf(InkibraRecordlessWorkoutType.WorkoutGenre.POP) !==
              -1 && <Check />}
          </div>
        </div>

        <div
          css={genreRapStyles}
          onClick={() =>
            handleGenreClick(InkibraRecordlessWorkoutType.WorkoutGenre.RAP)
          }
        >
          <div css={genreLabelStyles}>RAP</div>
          <div
            css={[
              genreCircleStyles,
              values.indexOf(InkibraRecordlessWorkoutType.WorkoutGenre.RAP) !==
                -1 && { backgroundColor: '#52FF00' },
            ]}
          >
            {values.indexOf(InkibraRecordlessWorkoutType.WorkoutGenre.RAP) !==
              -1 && <Check />}
          </div>
        </div>
      </div>
    </FormGroupWrapper>
  );
}
