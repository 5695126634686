/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const styles = css({
  width: '100%',
  border: '1px solid #5B5B5B',
  borderRadius: '12px',
  position: 'relative',
  fontSize: '12px',
});

const labelStyles = css({
  color: '#FFFFFF',
  fontWeight: '700',
  padding: '0 6px',
  backgroundColor: '#08031D',
  position: 'relative',
  top: '-8px',
  left: '8px',
});

const inputStyles = css({
  color: '#FFFFFF',
  width: '100%',
  fontWeight: '300',
  padding: '16px',
  backgroundColor: '#08031D',
  border: 'none',
  boxSizing: 'border-box',
  position: 'relative',
  top: '-8px',
  resize: 'none',
});

const errorStyles = css({
  fontSize: '12px',
  color: 'red',
});

export type TextAreaProps = {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  value: string | undefined;
  error?: string;
  onChange: React.ChangeEventHandler<HTMLTextAreaElement>;
};

export function TextArea({
  name,
  label,
  placeholder,
  required = false,
  value,
  error,
  onChange,
}: TextAreaProps) {
  return (
    <div css={styles}>
      <label css={labelStyles} htmlFor={name}>
        {label}
      </label>

      <textarea
        css={inputStyles}
        id={name}
        name={name}
        rows={4}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
      />

      {error && <div css={errorStyles}>{error}</div>}
    </div>
  );
}
