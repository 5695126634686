/** @jsxImportSource @emotion/react */

import { ReactNode } from 'react';

export enum ButtonBackgroundColor {
  PURPLE = '#4512EA',
  APP_FROSTED_LIGHT = 'rgba(79, 58, 214, 0.11)',
  APP_FROSTED_DARK = 'rgba(214, 58, 106, 0.11)',
  BLACK = 'black',
  WHITE = 'white',
}

export type ButtonProps = {
  contents: string | ReactNode;
  buttonBackgroundColor: ButtonBackgroundColor;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  gridArea?: string;
  color?: string;
  disabled?: boolean;
};

export function Button({
  contents,
  buttonBackgroundColor,
  onClick,
  gridArea,
  color,
  disabled,
}: ButtonProps) {
  return (
    <button
      type="button"
      disabled={disabled}
      css={{
        backgroundColor: buttonBackgroundColor,
        fontFamily:
          "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        fontWeight: 700,
        border: 0,
        borderRadius: '3em',
        cursor: 'pointer',
        display: 'inline-block',
        lineHeight: 1,
        fontSize: '14px',
        padding: '10px 20px',
        color: color ? color : 'white',
        width: '100%',
        gridArea,
      }}
      onClick={onClick}
    >
      {contents}
    </button>
  );
}
