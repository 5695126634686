import { ErrorCodes } from './error-codes';
import { ErrorType } from './error-type';

/**
 * A generic error descriptor.
 */
export type ErrorDescriptor<
  Code extends string,
  Message extends string,
  Data,
> = {
  /**
   * The error code.
   */
  code: Code;
  /**
   * The error message.
   */
  message: Message;
  /**
   * The error data.
   */
  data: Data;
};

export namespace ErrorDescriptor {
  /**
   * Creates an error descriptor.
   * @param code The error code.
   * @param message The error message.
   * @param data The error data.
   * @returns
   */
  export function create<E extends ErrorDescriptor<string, string, unknown>>(
    code: E['code'],
    message: E['message'],
    data: E['data'],
  ): E {
    return { code, message, data } as E;
  }

  export function toError<E extends ErrorDescriptor<string, string, unknown>>(
    errorDescriptor: E,
  ): Error {
    return new Error(
      `${errorDescriptor.code}: ${errorDescriptor.message}.\n${JSON.stringify(
        errorDescriptor.data,
        null,
        4,
      )}`,
    );
  }
}

/**
 * An error descriptor for an error that is not caught.
 * @example
 * const error = ErrorDescriptor.create<UNCAUGHT_ERROR_DESCRIPTOR>('UNCAUGHT', 'Uncaught error purpose: So the craziest thing happened...', { craziness: 'bro, you gotta see this!' });
 */
export type UNCAUGHT_ERROR_DESCRIPTOR = ErrorDescriptor<
  'UNCAUGHT',
  `Uncaught error purpose: ${string}`,
  unknown
>;

/**
 * @deprecated
 */
export type ErrorClientViewData = {
  message: string;
  code: string;
  status: number;
};

function guardString(data: unknown): data is string {
  if (typeof data === 'string') {
    return true;
  }
  return false;
}

/**
 * @deprecated
 */
export default class BaseError<
  T extends ErrorType<Code, Message>,
  Code extends string,
  Message extends string,
> extends Error {
  public readonly message: string;
  public readonly messageWithoutOriginalCause: string;
  public readonly errorType: T;
  public readonly name: string;
  public readonly stack?: string;

  constructor(errorType: T, message: Message, originalError?: unknown) {
    super(message);
    this.message = message;
    this.messageWithoutOriginalCause = this.message;
    if (originalError) {
      const maybeOriginalErrorName = (
        originalError as BaseError<ErrorType<string, string>, string, string>
      ).name;
      if (guardString(maybeOriginalErrorName)) {
        this.message = `${this.message}\nCaused By ${maybeOriginalErrorName}`;
      }
      const maybeOriginalErrorStack = (
        originalError as BaseError<ErrorType<string, string>, string, string>
      ).stack;
      if (guardString(maybeOriginalErrorStack) && this.stack) {
        this.stack = `${this.stack}\n Caused By ${maybeOriginalErrorStack}`;
      } else if (guardString(maybeOriginalErrorStack)) {
        this.stack = `Caused By ${maybeOriginalErrorStack}`;
      }
    }
    this.errorType = errorType;
    this.name = this.constructor.name;
    Object.freeze(this);
  }
  public static fromAnyError(originalError: unknown) {
    if (
      (originalError as BaseError<ErrorType<string, string>, string, string>)
        .errorType !== undefined
    ) {
      return originalError as BaseError<
        ErrorType<string, string>,
        string,
        string
      >;
    }
    const errorString = JSON.stringify(originalError);
    return ErrorCodes.UNCAUGHT.error(
      `Uncaught error purpose: ${errorString}`,
      originalError,
    );
  }
  get clientView() {
    return {
      message: this.messageWithoutOriginalCause,
      code: this.errorType.code,
      status: this.status,
    };
  }
  public static isClientView(
    errorData: unknown,
  ): errorData is ErrorClientViewData {
    if (
      typeof (errorData as { message: string }).message === 'string' &&
      typeof (errorData as { code: string }).code === 'string' &&
      typeof (errorData as { status: number }).status === 'number'
    ) {
      return true;
    }
    return false;
  }
  // biome-ignore lint/suspicious/noExplicitAny: this is a deprecated module
  public static makeErrorFromMaybeClientViewErrorData(maybeErrorData: any) {
    if (!BaseError.isClientView(maybeErrorData)) {
      return undefined;
    }
    const derivedClientErrorType = new ErrorType(
      maybeErrorData.code,
      maybeErrorData.status,
    );
    return derivedClientErrorType.error(maybeErrorData.message);
  }
  get status() {
    return this.errorType.status;
  }
  public toJSON() {
    return this.clientView;
  }
}

export { ErrorCodes } from './error-codes';
export { BaseError as ErrorBase, ErrorType };
