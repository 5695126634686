/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { responsiveStyle } from '../constants/media-query';

const cardStyles = css(
  {
    display: 'grid',
  },
  responsiveStyle('rowGap', {
    xs: '32px',
    sm: '32px',
    md: '64px',
    lg: '64px',
    xl: '64px',
  }),
  responsiveStyle('columnGap', {
    xs: '32px',
    sm: '32px',
    md: '40px',
    lg: '40px',
    xl: '40px',
  }),
  responsiveStyle('gridTemplateColumns', {
    xs: '60px 216px',
    sm: '60px 216px',
    md: '80px 216px',
    lg: '80px 216px',
    xl: '80px 216px',
  }),
  responsiveStyle('gridTemplateRows', {
    xs: '60px 104px',
    sm: '60px 104px',
    md: '80px 104px',
    lg: '80px 104px',
    xl: '80px 104px',
  }),
);

const cardTitleStyles = css(
  {
    fontWeight: 600,
  },
  responsiveStyle('lineHeight', {
    xs: '60px',
    sm: '60px',
    md: '80px',
    lg: '80px',
    xl: '80px',
  }),
  responsiveStyle('fontSize', {
    xs: '32px',
    sm: '32px',
    md: '32px',
    lg: '40px',
    xl: '40px',
  }),
);

const cardContentStyles = css(
  {
    gridColumnStart: 1,
    gridColumnEnd: 3,
    fontWeight: 300,
  },
  responsiveStyle('fontSize', {
    xs: '20px',
    sm: '20px',
    md: '20px',
    lg: '24px',
    xl: '24px',
  }),
);

export type ExplainerCardProps = {
  image: React.ReactNode;
  title: string;
  content: string;
};

export function ExplainerCard({ image, title, content }: ExplainerCardProps) {
  return (
    <div css={cardStyles}>
      <div>{image}</div>

      <div css={cardTitleStyles}>{title}</div>

      <div css={cardContentStyles}>{content}</div>
    </div>
  );
}
