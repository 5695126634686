/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState } from 'react';

const MODAL_BOARDER_RADIUS = '10px';

const modalStyles = css({
  display: 'grid',
  padding: '1px',
  boxSizing: 'border-box',
  position: 'fixed',
  top: '10%',
  left: 0,
  width: '100vw',
  zIndex: 999,
  opacity: 0,
  borderRadius: MODAL_BOARDER_RADIUS,
  visibility: 'hidden',
  transition: 'opacity 0.3s linear 0.3s, visibility 0.3s linear 0.3s',
  background:
    'conic-gradient(from -39deg at 43.75% 42.19%, #4512EA 0deg, #1282EA 39.07447725534439deg, #12EA5B 77.43583559989929deg, #EFE75E 117.77458548545837deg, #E07614 143.48064064979553deg, #E55C2F 175.11670589447021deg, #EA4448 200.54243803024292deg, #F31875 235.05255460739136deg, #B416A0 265.3322696685791deg, #4512EA 306.90011501312256deg, #4512EA 339.4148826599121deg)',
});

const overlayStyle = css({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 998,
  opacity: 0,
  background: 'rgba(0, 0, 0, 0.6)',
  visibility: 'hidden',
  transition: 'opacity 0.3s linear 0.3s, visibility 0.3s linear 0.3s',
});

export type ModalProps = {
  open: boolean;
  modal: React.ReactNode;
  onClosed: () => void;
};

export function Modal(props: ModalProps) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (props.open) setVisible(true);
    }, 0);
  }, [props.open]);

  if (visible && props.open === false) {
    setTimeout(() => {
      setVisible(false);
      props.onClosed();
    }, 300);
  }

  return (
    <div>
      <div
        css={[
          overlayStyle,
          visible && props.open && { opacity: 1, visibility: 'visible' },
        ]}
      />
      <div
        css={[
          modalStyles,
          visible && props.open && { opacity: 1, visibility: 'visible' },
        ]}
      >
        {props.modal}
      </div>
    </div>
  );
}

export namespace Modal {
  export const BOARDER_RADIUS = MODAL_BOARDER_RADIUS;
}
