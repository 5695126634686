/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const pageStyles = css({
  color: 'white',
  fontFamily:
    'DM Sans, Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  boxSizing: 'border-box',
});

export function Page({ children }: { children: React.ReactNode }) {
  return <main css={pageStyles}>{children}</main>;
}
