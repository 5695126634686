/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import { MixAnnotationType } from '@inkibra/recordless.music-engine';
import {
  BackwardsButton,
  Color,
  ForwardButton,
  HistoryButton,
  PauseButton,
  PlayButton,
  QueueButton,
  breakpoints,
  responsiveStyle,
} from '@inkibra/recordless.ux';
import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useLibraryAlbumArtworkLocator } from '../hooks';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibraryMixType } from '../type-mix';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import {
  AudioDataStateMap,
  Playable,
  PlaybackState,
} from './client-player-element';
import { NowPlayingInformation } from './now-playing-information';
import { RecordlessPlaybackDisplay } from './playback-display';
import { PlaybackSessionContext } from './playback-session-guard-context';

const containerLayout = css(
  {
    display: 'grid',
    position: 'absolute',
    margin: 0,
    alignItems: 'center',
    width: '100%',
    boxSizing: 'border-box',
    background: Color.CARD_BG,
    borderTop: `1px solid ${Color.APP_BORDER_COLOR}`,
  },
  responsiveStyle('justifyItems', {
    sm: 'center',
  }),
  responsiveStyle('gridTemplateRows', {
    xs: '75px 15px 50px',
    sm: '45px 20px 50px',
  }),
  responsiveStyle('gridTemplateColumns', {
    xs: '300px 1fr',
    sm: '300px 1fr',
  }),
  responsiveStyle('gridTemplateAreas', {
    xs: `
    "now-playing-information button-container"
    "playback-bar playback-bar"
    "song-guide song-guide"
  `,
    sm: `
    "now-playing-information button-container"
    "now-playing-information playback-bar"
    "now-playing-information song-guide"
  `,
  }),
);

// const playbackBarStyles = css({
//   height: '5px',
//   WebkitAppearance: 'none',
//   gridArea: 'playback-bar',
//   marginBottom: '10px',
//   borderRadius: '8px',
//   background: '#3b7677',
//   transition: 'background 0.2s ease',
//   cursor: 'pointer',
//   bottom: '25px',
//   left: '320px',
// });

const buttonContainerStyles = css(
  {
    height: '35px',
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 35px)',
    alignSelf: 'center',
    alignItems: 'center',
    columnGap: '10px',
    // justifyItems: 'center',
    // justifyContent: 'center',
    gridArea: 'button-container',
  },
  responsiveStyle('justifyContent', {
    sm: 'center',
  }),
);

const buttonResetStyles = css({
  border: 'none',
  background: 'none',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  cursor: 'pointer',
  color: 'white',
  position: 'relative',
});

const backwardsButtonStyles = css(
  buttonResetStyles,
  {
    width: '30px',
    height: '30px',
  },
  responsiveStyle('display', {
    xs: 'none',
    sm: 'block',
  }),
);

const playButtonStyles = css(buttonResetStyles, {
  width: '30px',
  height: '30px',
  // alignSelf: 'center',
});

const forwardButtonStyles = css(
  {
    width: '30px',
    height: '30px',
  },
  buttonResetStyles,
);

const historyButtonStyles = css(
  buttonResetStyles,
  {
    width: '30px',
    height: '30px',
  },
  responsiveStyle('display', {
    xs: 'none',
    sm: 'block',
  }),
);

const queueButtonStyles = css(
  buttonResetStyles,
  {
    width: '30px',
    height: '30px',
  },
  responsiveStyle('display', {
    xs: 'none',
    sm: 'block',
  }),
);

export type NowPlayingDockProps = {
  onOpenHistory?: () => void;
  onOpenQueue?: () => void;
  onBackwards?: () => void;
  onForwards?: () => void;
};

// TODO: alter this so that so that we can display everything based on the current section
/*
  This means that the playback information can change based on the current section
  For songs this means we can use the title to display the song name
  We will display the song artist as well
  The arrangement name would be not set
  The section names will be displayed on the timeline
  The album art will be displayed based on the album slug

  For arrangements we will do the same, except for we can set the arrangement name

  For mixes we can change the song name as the mix evolves
*/
function getDisplayablePlaybackInformation(nowPlaying: Playable | undefined):
  | {
      title: string;
      artist: string;
      albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug;
      arrangementName?: string;
      duration: number;
      sections: { name: string; startTime: number }[];
    }
  | undefined {
  switch (nowPlaying?.type) {
    case 'nkrarrangement': {
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );

      const arrangementUtil =
        new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil(
          {},
        );

      const arrangementInfoResult =
        arrangementUtil.getOrComputeArrangementInfo(nowPlaying);
      if (arrangementInfoResult.isErr()) {
        throw arrangementInfoResult.error;
      }

      return {
        title: nowPlaying.librarySong.title,
        artist: nowPlaying.librarySong.artists.join(', '),
        albumSlug: songUtil.getAlbumSlug(nowPlaying.librarySong),
        arrangementName: nowPlaying.name,
        duration: arrangementInfoResult.value.duration,
        sections: arrangementInfoResult.value.sectionInfo,
      };
    }
    case 'nkrmix': {
      const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({});
      const albumSlug = '' as InkibraRecordlessLibrarySongType.AlbumSlug;
      const mixOutputInfo = mixUtil.getBestMixOutputInfo(nowPlaying);
      const mixNodes = nowPlaying.orderedMixNodes;
      console.log('mixOutputInfo', mixOutputInfo);
      console.log('mixNodes', mixNodes);
      return {
        title: nowPlaying.name,
        artist: 'Various Artists',
        albumSlug,
        duration: mixOutputInfo.duration,
        sections: mixNodes.map((node) => {
          const startAnnotation = mixUtil
            .getMixAnnotationsForNode(nowPlaying, node.id)
            .find((annotation) => {
              return (
                annotation.annotationType === MixAnnotationType.IntermixStart
              );
            });

          if (!startAnnotation) {
            throw new Error('Could not find start annotation');
          }
          return {
            name: node.currentLibraryArrangement.librarySong.title,
            startTime: startAnnotation?.startsAt,
          };
        }),
      };
    }
    case 'nkrsong': {
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );
      const orderedSectionInfoResult =
        songUtil.computeOrderedSectionInfo(nowPlaying);
      if (orderedSectionInfoResult.isErr()) {
        throw orderedSectionInfoResult.error;
      }

      const albumSlug = songUtil.getAlbumSlug(nowPlaying);

      return {
        title: nowPlaying.title,
        artist: nowPlaying.artists.join(', '),
        albumSlug: albumSlug,
        duration: nowPlaying.duration,
        sections: orderedSectionInfoResult.value,
      };
    }

    default:
      return undefined;
  }
}

export function NowPlayingDock(props: NowPlayingDockProps) {
  const cache = useContext(CacheContext)();
  const playback = useContext(PlaybackSessionContext)();
  const [nowPlaying, setNowPlaying] = useState<Playable | undefined>();
  const nowPlayingInformation = getDisplayablePlaybackInformation(nowPlaying);
  const hookEffectContext = new HookEffectContext();
  const albumArtworkLocator = hookEffectContext.addEffect(
    useLibraryAlbumArtworkLocator(nowPlayingInformation?.albumSlug),
  );
  hookEffectContext.runEffects(cache);
  const [paused, setPaused] = useState(true);
  const [trackProgress, setTrackProgress] = useState(0);
  const [playbackBarShrink, setPlaybackBarShrink] = useState(0);
  const [waveform, setWaveform] = useState<number[]>([]);
  const [audioDataLoaded, setAudioDataLoaded] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const onResize = () => {
      if (containerRef?.current) {
        if (window.innerWidth > breakpoints.sm) {
          setPlaybackBarShrink(350);
        } else {
          setPlaybackBarShrink(5);
        }
      }
    };
    window.addEventListener('resize', onResize);
    setTimeout(onResize, 0);
    return () => window.removeEventListener('resize', onResize);
  }, [containerRef]);

  useEffect(() => {
    function handlePlaybackStateChange(playbackState: PlaybackState) {
      setPaused(!playbackState.isPlaying);
      setTrackProgress(playbackState.time);
      setWaveform(playbackState.waveform);
      setAudioDataLoaded(!!playbackState.nowPlaying);
      setNowPlaying(playbackState.nowPlaying?.playable);
    }
    function handleAudioDataStateChange(audioDataState: AudioDataStateMap) {
      console.log('audio data state change', audioDataState);
    }

    playback.current?.subscribeToPlaybackState(handlePlaybackStateChange);
    playback.current?.subscribeToAudioDataState(handleAudioDataStateChange);

    return () => {
      playback.current?.unsubscribeFromPlaybackState(handlePlaybackStateChange);
      playback.current?.unsubscribeFromAudioDataState(
        handleAudioDataStateChange,
      );
    };
  }, [playback.current]);

  if (nowPlayingInformation === undefined) {
    return null;
  }
  // const duration = nowPlayingInformation.duration || 0;
  // const currentPercentage = duration
  //   ? `${(trackProgress / duration) * 100}%`
  //   : '0%';

  // const trackStyling = `
  //     -webkit-gradient(linear, 0% 0%, 100% 0%, color-stop(${currentPercentage}, #fff), color-stop(${currentPercentage}, #777))
  //   `;

  // if (props.loading === true) {
  //   return (
  //     <div css={containerLayout} ref={containerRef}>
  //       <NowPlayingInformation
  //         gridArea="now-playing-information"
  //         title={nowPlayingInformation.title}
  //         artist={nowPlayingInformation.artist}
  //         albumArtworkPath={albumArtworkLocator?.url || ''}
  //         arrangement={nowPlayingInformation.arrangementName || 'Original'}
  //       />
  //       <PropagateLoader
  //         css={css(
  //           responsiveStyle('left', {
  //             xs: '0px',
  //             sm: '-100px',
  //           }),
  //           responsiveStyle('top', {
  //             xs: '50px',
  //             sm: '25px',
  //           }),
  //         )}
  //         color={ButtonBackgroundColor.PURPLE}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div css={containerLayout} ref={containerRef}>
      <NowPlayingInformation
        gridArea="now-playing-information"
        title={nowPlayingInformation.title}
        artist={nowPlayingInformation.artist}
        albumArtworkPath={albumArtworkLocator?.url || ''}
        arrangement={nowPlayingInformation.arrangementName || 'Original'}
      />
      {playbackBarShrink && playback.current ? (
        <RecordlessPlaybackDisplay
          duration={nowPlayingInformation.duration}
          gridArea="song-guide"
          windowedWaveformData={waveform}
          relativeSections={nowPlayingInformation.sections}
          relativeZero={0}
          shrink={playbackBarShrink}
          containerRef={containerRef}
        />
      ) : null}
      <div css={buttonContainerStyles}>
        {props.onBackwards ? (
          <button
            css={backwardsButtonStyles}
            onClick={() => {
              if (trackProgress > 5) {
                playback.current?.seek(trackProgress - 5);
              } else {
                if (props.onBackwards) {
                  props.onBackwards();
                }
              }
            }}
          >
            <BackwardsButton
              style={{
                position: 'absolute',
                width: '25px',
                height: '25px',
                top: 0,
                left: 0,
              }}
            />
          </button>
        ) : null}
        <button
          css={playButtonStyles}
          disabled={!audioDataLoaded}
          onClick={async () => {
            if (paused) {
              await playback.current?.resume();
            } else if (!paused) {
              playback.current?.pause();
            }
          }}
        >
          {audioDataLoaded ? 'audio loaded' : 'audio loading'}
          {paused ? (
            <PlayButton
              style={{
                position: 'absolute',
                width: '25px',
                height: '25px',
                top: 0,
                left: 0,
              }}
            />
          ) : (
            <PauseButton
              style={{
                position: 'absolute',
                width: '25px',
                height: '25px',
                top: 0,
                left: 0,
              }}
            />
          )}
        </button>
        {props.onForwards ? (
          <button
            css={forwardButtonStyles}
            onClick={() => {
              if (props.onForwards) {
                props.onForwards();
              }
            }}
          >
            <ForwardButton
              style={{
                position: 'absolute',
                width: '25px',
                height: '25px',
                top: 0,
                left: 0,
              }}
            />
          </button>
        ) : null}
        {props.onOpenHistory ? (
          <button
            css={historyButtonStyles}
            onClick={() => {
              if (props.onOpenHistory) {
                props.onOpenHistory();
              }
            }}
          >
            <HistoryButton
              style={{
                position: 'absolute',
                width: '30px',
                height: '30px',
                top: 4,
                left: 0,
              }}
            />
          </button>
        ) : null}
        {props.onOpenQueue ? (
          <button
            css={queueButtonStyles}
            onClick={() => {
              if (props.onOpenQueue) {
                props.onOpenQueue();
              }
            }}
          >
            <QueueButton
              style={{
                position: 'absolute',
                width: '20px',
                height: '20px',
                top: 3,
                left: 0,
              }}
            />
          </button>
        ) : null}
      </div>
    </div>
  );
}
