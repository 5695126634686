/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FormGroupWrapper } from './form-group-wrapper';
import { RadioInput } from './radio-input';

const styles = css({
  display: 'grid',
  gridAutoColumns: '1fr',
  gridAutoFlow: 'column',
  alignContent: 'center',
  justifyContent: 'center',
  justifyItems: 'center',
  padding: '24px 14px',
});

export type RadioGroupProps<TValue extends string | number> = {
  title: string;
  subtitle: string;
  name: string;
  values: TValue[];
  required: boolean;
  selected: TValue;
  onChange: (value: TValue) => void;
};

export function RadioGroup<TValue extends string | number>({
  title,
  subtitle,
  name,
  values,
  required,
  selected,
  onChange,
}: RadioGroupProps<TValue>) {
  return (
    <FormGroupWrapper title={title} subtitle={subtitle}>
      <div css={styles}>
        {values.map((value) => (
          <RadioInput
            name={name}
            key={`${name}-${value}`}
            required={required}
            value={value}
            selected={value === selected}
            onChange={(e) => {
              if (e.target.checked) {
                onChange(value);
              }
            }}
          />
        ))}
      </div>
    </FormGroupWrapper>
  );
}
