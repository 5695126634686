import { Brand } from '@inkibra/observable-cache';

export enum DriverPlaybackState {
  Stopped = 'stopped',
  Playing = 'playing',
  Paused = 'paused',
  Swapping = 'swapping',
  Destroyed = 'destroyed',
}

export type TimeUpdate = {
  playableId?: Brand<'nkrplayable'>;
  time: number;
  duration: number;
  playbackState: DriverPlaybackState;
};

type LoadedSource = {
  playableId: Brand<'nkrplayable'>;
  buffer: AudioBuffer;
  source: AudioBufferSourceNode;
  gainNode: GainNode;
  audioContextStartTime: number;
  playbackOffset: number;
  swapOffset: number;
};

export type LoadedSourceReference = {
  playableId: Brand<'nkrplayable'>;
};

export type SourceSwapDetails = {
  alignedTimeInCurrentSource: number;
  alignedTimeInNewSource: number;
  lastTimeInCurrentSource: number;
  lastTimeInNewSource: number;
  transitionTime: number;
};

/**
 * A class that can playback audio using the web audio api.
 * It is design to have a similar api to an audio element,
 * but it uses the web audio api under the hood.
 * In addition it supports swapping playback between two sources.
 */
export class ClientPlaybackDriver {
  private audioContext: AudioContext = new AudioContext();
  private playingSource?: LoadedSource;
  private intervalId?: NodeJS.Timeout;
  private loadedSources: Map<Brand<'nkrplayable'>, LoadedSource> = new Map();
  private playbackState: DriverPlaybackState = DriverPlaybackState.Stopped;
  private timeUpdateSubscribers: ((update: TimeUpdate) => void)[] = [];
  private mediaStreamDestination: MediaStreamAudioDestinationNode =
    this.audioContext.createMediaStreamDestination();
  private audioOutput: HTMLAudioElement = new Audio();
  private lastTimeUpdate: TimeUpdate = {
    time: 0,
    duration: 0,
    playbackState: DriverPlaybackState.Paused,
  };
  private lastTimeUpdateSubscription?: () => void;
  constructor() {
    this.audioOutput.srcObject = this.mediaStreamDestination.stream;
    this.lastTimeUpdateSubscription = this.subscribeToTimeUpdates((update) => {
      this.lastTimeUpdate = update;
    });
    document.body.appendChild(this.audioOutput);
  }

  public destroy() {
    this.pause();
    if (this.playingSource) {
      this.playingSource.source.disconnect();
      this.playingSource.gainNode.disconnect();
    }

    this.playbackState = DriverPlaybackState.Destroyed;
    this.lastTimeUpdateSubscription?.();
    document.body.removeChild(this.audioOutput);
    this.audioContext.close();
  }
  public async loadSource(
    playableId: Brand<'nkrplayable'>,
    sourceUrl: string,
    forceReload = false,
  ): Promise<LoadedSourceReference> {
    // If we already have the source loaded, we can return early
    if (this.loadedSources.has(playableId) && !forceReload) {
      return { playableId };
    }
    const response = await fetch(sourceUrl);
    const audioBlob = await response.blob();
    const audioBuffer = await this.audioContext.decodeAudioData(
      await audioBlob.arrayBuffer(),
    );
    const { source, gainNode } = this.createSourceNode(audioBuffer, 1);
    const loadedSource: LoadedSource = {
      playableId,
      buffer: audioBuffer,
      source,
      gainNode,
      audioContextStartTime: this.audioContext.currentTime,
      playbackOffset: 0,
      swapOffset: 0,
    };
    this.loadedSources.set(playableId, loadedSource);
    return { playableId };
  }

  private createSourceNode(audioBuffer: AudioBuffer, initialGain: number) {
    const source = this.audioContext.createBufferSource();
    const gainNode = this.audioContext.createGain();
    source.buffer = audioBuffer;
    source.connect(gainNode);
    gainNode.connect(this.mediaStreamDestination);
    gainNode.gain.value = initialGain;
    return { source, gainNode };
  }

  public resume(playbackOffset?: number) {
    if (
      this.playbackState === DriverPlaybackState.Stopped &&
      this.playingSource
    ) {
      this.audioOutput
        .play()
        .then(() => {
          console.log('audio output played');
        })
        .catch((error) => {
          console.error('audio output failed to play', error);
        });
      this.audioContext.resume();
      const sourceToPlay = this.playingSource;
      const audioContextStartTime = this.audioContext.currentTime + 0.5;
      this.playingSource = {
        ...sourceToPlay,
        audioContextStartTime,
        playbackOffset: playbackOffset ?? 0,
      };
      this.playingSource.source.start(audioContextStartTime, playbackOffset);
      this.playbackState = DriverPlaybackState.Playing;
      this.intervalId = setInterval(this.timerFunction, 250);
    }
    if (
      this.playbackState === DriverPlaybackState.Paused &&
      this.playingSource
    ) {
      this.audioOutput
        .play()
        .then(() => {
          console.log('audio output played');
        })
        .catch((error) => {
          console.error('audio output failed to play', error);
        });
      this.audioContext.resume();
      const audioContextStartTime = this.audioContext.currentTime + 0.5;
      const playbackOffsetToUse = Math.max(
        0,
        playbackOffset ?? this.playingSource.playbackOffset,
      );
      console.log('playbackOffsetToUse', playbackOffsetToUse);
      console.log('playbackOffset', playbackOffset);
      console.log(
        'this.playingSource.playbackOffset',
        this.playingSource.playbackOffset,
      );
      this.playingSource.source.start(
        audioContextStartTime,
        playbackOffsetToUse,
      );
      this.playingSource.audioContextStartTime = audioContextStartTime;
      this.playingSource.playbackOffset = playbackOffsetToUse;
      this.playbackState = DriverPlaybackState.Playing;
      this.intervalId = setInterval(this.timerFunction, 250);
    }
  }

  public unloadPlayingSource() {
    if (this.playingSource) {
      this.pause();
      this.playingSource.source.disconnect();
      this.playingSource.gainNode.disconnect();
      this.playingSource = undefined;
    }
  }

  public pause() {
    if (
      this.playbackState === DriverPlaybackState.Playing &&
      this.playingSource
    ) {
      const currentlyPlayingSource = this.playingSource;
      const currentTime = this.audioContext.currentTime;
      const playbackTime =
        currentTime - currentlyPlayingSource.audioContextStartTime;
      const playbackPosition =
        playbackTime + currentlyPlayingSource.playbackOffset;

      currentlyPlayingSource.playbackOffset = playbackPosition;

      currentlyPlayingSource.source.stop();

      // Disconnect the old source and gain node
      currentlyPlayingSource.source.disconnect();
      currentlyPlayingSource.gainNode.disconnect();

      // Create a new source node
      const { source, gainNode } = this.createSourceNode(
        currentlyPlayingSource.buffer,
        1,
      );
      currentlyPlayingSource.source = source;
      currentlyPlayingSource.gainNode = gainNode;

      this.playbackState = DriverPlaybackState.Paused;

      // suspend the audio context
      this.audioOutput.pause();
      this.audioContext.suspend();
      clearInterval(this.intervalId);

      const clampedPlaybackPosition = Math.min(
        Math.max(playbackPosition + currentlyPlayingSource.swapOffset, 0),
        currentlyPlayingSource.buffer.duration +
          currentlyPlayingSource.swapOffset,
      );

      this.timeUpdateSubscribers.forEach((subscriber) =>
        subscriber({
          playableId: currentlyPlayingSource.playableId,
          time: clampedPlaybackPosition,
          duration:
            currentlyPlayingSource.buffer.duration +
            currentlyPlayingSource.swapOffset,
          playbackState: DriverPlaybackState.Paused,
        }),
      );
    }
  }

  public seek(time: number) {
    time += this.playingSource?.swapOffset ?? 0;
    const currentlyPlayingSource = this.playingSource;
    if (!currentlyPlayingSource) {
      return;
    }
    if (this.playbackState === DriverPlaybackState.Playing) {
      this.pause();
      this.resume(time);
    } else if (this.playbackState === 'paused') {
      currentlyPlayingSource.playbackOffset = time;
      const clampedPlaybackPosition = Math.min(
        Math.max(time, 0),
        currentlyPlayingSource.buffer.duration +
          currentlyPlayingSource.swapOffset,
      );
      this.timeUpdateSubscribers.forEach((subscriber) =>
        subscriber({
          playableId: currentlyPlayingSource.playableId,
          time: clampedPlaybackPosition,
          duration:
            currentlyPlayingSource.buffer.duration +
            currentlyPlayingSource.swapOffset,
          playbackState: DriverPlaybackState.Paused,
        }),
      );
    }
  }

  public playOrSwapSource(
    loadedSourceReference: LoadedSourceReference,
    swapDetails?: SourceSwapDetails,
    onSwapComplete?: (state: DriverPlaybackState) => void,
  ): boolean {
    const loadedSource = this.loadedSources.get(
      loadedSourceReference.playableId,
    );
    if (!loadedSource) {
      return false;
    }
    if (
      this.playbackState !== DriverPlaybackState.Playing ||
      !this.playingSource ||
      swapDetails === undefined
    ) {
      this.pause();

      // Clean up previous source if it exists
      if (this.playingSource) {
        this.playingSource.source.disconnect();
        this.playingSource.gainNode.disconnect();
      }

      this.playingSource = loadedSource;
      this.resume();
      onSwapComplete?.(DriverPlaybackState.Playing);
      return true;
    }

    console.log('swapDetails', swapDetails);

    if (
      this.playbackState === DriverPlaybackState.Playing &&
      this.playingSource
    ) {
      const oldPlayingSource = this.playingSource;
      const currentTime = this.audioContext.currentTime;
      console.log('currentTime', currentTime);
      const playbackTime = currentTime - oldPlayingSource.audioContextStartTime;
      console.log('playbackTime', playbackTime);
      const playbackPosition = playbackTime + oldPlayingSource.playbackOffset;
      console.log('playbackPosition', playbackPosition);
      // Calculate the earliest possible swap time
      const earliestSwapPlaybackPosition = Math.max(
        playbackPosition + 1,
        swapDetails.alignedTimeInCurrentSource,
      );

      // Ensure the swap can happen within the allowed window
      if (earliestSwapPlaybackPosition > swapDetails.lastTimeInCurrentSource) {
        // Cannot perform swap within the specified window
        console.error('Swap cannot be scheduled within the specified window.');
        return false;
      }

      // Calculate the delta time until the swap
      const deltaToSwapTime = earliestSwapPlaybackPosition - playbackPosition;

      // Calculate the playback offset for the new source
      const newSourcePlaybackOffset =
        swapDetails.alignedTimeInNewSource +
        (earliestSwapPlaybackPosition - swapDetails.alignedTimeInCurrentSource);

      // Ensure the new source playback offset is within the allowed window
      if (
        newSourcePlaybackOffset < swapDetails.alignedTimeInNewSource ||
        newSourcePlaybackOffset > swapDetails.lastTimeInNewSource
      ) {
        // Cannot align new source within specified window
        console.error(
          'New source playback offset is outside the allowed window.',
        );
        return false;
      }

      const newSourceContextStartTime = currentTime + deltaToSwapTime;

      const newPlayingSource = {
        ...loadedSource,
        audioContextStartTime: newSourceContextStartTime,
        playbackOffset: newSourcePlaybackOffset,
        swapOffset:
          loadedSource.playableId === this.playingSource.playableId
            ? swapDetails.alignedTimeInCurrentSource -
              swapDetails.alignedTimeInNewSource
            : 0,
      };

      // TODO: use a smoother crossfade here

      // Schedule gain change on old source
      oldPlayingSource.gainNode.gain.setValueAtTime(
        1,
        newSourceContextStartTime,
      );
      oldPlayingSource.gainNode.gain.linearRampToValueAtTime(
        0,
        newSourceContextStartTime + swapDetails.transitionTime,
      );

      // Start the new source
      newPlayingSource.source.start(
        newSourceContextStartTime,
        newSourcePlaybackOffset,
      );

      // Schedule gain increase on new source
      newPlayingSource.gainNode.gain.setValueAtTime(
        0,
        newSourceContextStartTime,
      );
      newPlayingSource.gainNode.gain.linearRampToValueAtTime(
        1,
        newSourceContextStartTime + swapDetails.transitionTime,
      );

      setTimeout(
        () => {
          // Stop and disconnect the old source
          oldPlayingSource.source.stop();
          oldPlayingSource.source.disconnect();
          oldPlayingSource.gainNode.disconnect();

          this.playingSource = newPlayingSource;
          this.playbackState = DriverPlaybackState.Playing;
          onSwapComplete?.(DriverPlaybackState.Playing);
        },
        (deltaToSwapTime + swapDetails.transitionTime) * 1000,
      );

      return true;
    }

    // If playback is not in a valid state or sources are missing
    console.error('Cannot perform swap: Invalid playback state or sources.');
    return false;
  }

  private timerFunction = () => {
    if (
      this.playbackState === DriverPlaybackState.Playing &&
      this.playingSource
    ) {
      const currentlyPlayingSource = this.playingSource;
      const currentTime = this.audioContext.currentTime;
      const playbackTime =
        currentTime - currentlyPlayingSource.audioContextStartTime;
      const playbackPosition =
        playbackTime + currentlyPlayingSource.playbackOffset;

      const clampedPlaybackPosition = Math.min(
        Math.max(playbackPosition + currentlyPlayingSource.swapOffset, 0),
        currentlyPlayingSource.buffer.duration +
          currentlyPlayingSource.swapOffset,
      );
      this.timeUpdateSubscribers.forEach((subscriber) =>
        subscriber({
          playableId: currentlyPlayingSource.playableId,
          time: clampedPlaybackPosition,
          duration:
            currentlyPlayingSource.buffer.duration +
            currentlyPlayingSource.swapOffset,
          playbackState: DriverPlaybackState.Playing,
        }),
      );

      if (playbackPosition >= currentlyPlayingSource.buffer.duration) {
        console.log('lastTimeUpdate', this.lastTimeUpdate, this.playingSource);
        console.log('auto pause');
        this.pause();
      }
    }
  };

  public subscribeToTimeUpdates(
    callback: (update: TimeUpdate) => void,
  ): () => void {
    this.timeUpdateSubscribers.push(callback);
    return () => {
      this.timeUpdateSubscribers = this.timeUpdateSubscribers.filter(
        (subscriber) => subscriber !== callback,
      );
    };
  }

  public getPlaybackInfo() {
    return this.lastTimeUpdate;
  }
}
