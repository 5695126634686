/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  HeaderText,
  HeaderTextAlign,
  HeaderTextBackground,
} from './header-text';

import { ParagraphText, ParagraphTextAlign } from './paragraph-text';

export type MarketingStepCardProps = {
  imgSrc: string;
  title: string;
  paragraph: string;
  highlight: boolean;
};

const styles = css({
  display: 'inline-grid',
  gridTemplateColumns: '100%',
  gridTemplateRows: '245px 40px 1fr',
  width: '305px',
  height: '415px',
  background:
    'linear-gradient(190deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%)',
  backdropFilter: 'blur(30px)',
  boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.7)',
  boxSizing: 'border-box',
  borderRadius: '40px',
  border: '2px solid rgba(255, 255, 255, .35)',
  padding: '25px',
  justifyItems: 'center',
});

const highlightedStyles = css({
  background:
    'conic-gradient(from 16.63deg at 55.37% 35.49%, #EC405F -62.13deg, #0013BF 88.28deg, #654EEE 179.58deg, #EC405F 297.87deg, #0013BF 448.28deg), #0B0B0B',
  boxShadow: '4px 20px 48px 8px rgba(35, 16, 94, 0.7)',
  backdropFilter: 'blur(112px)',
  border: '2px solid rgba(255, 255, 255, .80)',
});

const imageStyles = css({
  borderRadius: '20px',
  width: '215px',
  height: '215px',
  opacity: 0.85,
});

export function MarketingStepCard({
  imgSrc,
  title,
  paragraph,
  highlight,
}: MarketingStepCardProps) {
  return (
    <div css={css(styles, highlight ? highlightedStyles : undefined)}>
      <img src={imgSrc} css={imageStyles} />
      <HeaderText
        value={title}
        background={HeaderTextBackground.SOLID_WHITE}
        fontSize="16px"
        fontWeight={500}
        textAlign={HeaderTextAlign.LEFT}
      />
      <ParagraphText value={paragraph} textAlign={ParagraphTextAlign.LEFT} />
    </div>
  );
}
