export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};
export type ResponsiveValue<T> = T | { [key in keyof typeof breakpoints]?: T };

export const mq = (face: keyof typeof breakpoints) =>
  `@media (min-width: ${breakpoints[face]}px)`;

export const responsiveStyle = (
  property: string,
  value: ResponsiveValue<string | number>,
): { [key: string]: string | number | { [key: string]: string | number } } => {
  if (typeof value === 'object') {
    return Object.entries(value).reduce(
      (
        acc: { [key: string]: { [key: string]: string | number } },
        [face, val],
      ) => {
        const faceKey = face as keyof typeof breakpoints;
        acc[mq(faceKey)] = { [property]: val };
        return acc;
      },
      {},
    );
  }
  return { [property]: value };
};
