/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { MusicWaveformDisplay } from './waveform-display';

/**
 * Represents the props for the InkibraRecordlessLibraryApiMusicTransitionOverviewUX component.
 */
export type RecordlessTransitionOverviewProps = {
  song: InkibraRecordlessLibrarySongType;
  onTransitionClick: (
    transitionId: InkibraRecordlessLibrarySongType.Transition['id'],
  ) => void;
  filterBySourceSectionId?: InkibraRecordlessLibrarySongType.Section['id'];
  filterByTargetSectionId?: InkibraRecordlessLibrarySongType.Section['id'];
  waveformData: number[];
};

/**
 * A component that displays the transitions and sections of a song.
 */
export const RecordlessTransitionOverview = (
  props: RecordlessTransitionOverviewProps,
) => {
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
  const [selectionIndex, setSelectionIndex] = useState<number | undefined>(
    undefined,
  );
  const orderedSectionToDisplay = props.song.orderedSectionFields.filter(
    (section) => {
      if (props.filterBySourceSectionId) {
        return section.id === props.filterBySourceSectionId;
      }
      return true;
    },
  );
  return (
    <>
      {orderedSectionToDisplay.map((section, sectionIndex) => {
        let transitionToDisplay = songUtil.filterTransitionsForSection(
          props.song,
          section.id,
          'source',
        );
        if (props.filterByTargetSectionId) {
          transitionToDisplay = transitionToDisplay.filter(
            (transition) =>
              transition.targetSectionFieldId === props.filterByTargetSectionId,
          );
        }
        return (
          <Fragment
            key={`${sectionIndex}-section-${section.name}-${section.id}`}
          >
            <SelectableSectionDisplay
              song={props.song}
              sectionId={section.id}
              selected={selectionIndex === sectionIndex}
              waveform={props.waveformData}
              arrangementRole={
                sectionIndex === 0
                  ? 'arrangement-start'
                  : sectionIndex === orderedSectionToDisplay.length - 1
                    ? 'arrangement-end'
                    : 'arrangement-middle'
              }
              onSectionClick={() => {
                setSelectionIndex(sectionIndex);
              }}
            />
            {selectionIndex === sectionIndex
              ? transitionToDisplay.map((transition, transitionIndex) => {
                  return (
                    <SelectableTransitionDisplay
                      key={`${transitionIndex}-transition-${transition.id}`}
                      song={props.song}
                      transition={transition}
                      selected={false}
                      waveform={props.waveformData}
                      onTransitionClick={(transition) => {
                        props.onTransitionClick(transition);
                      }}
                    />
                  );
                })
              : null}
          </Fragment>
        );
      })}
    </>
  );
};

const SECTION_WHITE_COLOR = '#D9D9D9';
const SECTION_HIGHLIGHT_COLOR = '#FF3D00';
const FIXED_SECTION_STROKE_COLOR = '#8000FF';

type SectionDisplayProps = {
  song: InkibraRecordlessLibrarySongType;
  sectionId: InkibraRecordlessLibrarySongType.Section['id'];
  selected: boolean;
  waveform: number[];
  arrangementRole:
    | 'arrangement-start'
    | 'arrangement-end'
    | 'arrangement-middle';
  onSectionClick: () => void;
};

const SelectableSectionDisplay = (props: SectionDisplayProps) => {
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

  const [isHovered, setIsHovered] = useState(false);
  const [waveformWindow, setWaveformWindow] = useState<number[]>([]);

  const section = songUtil.getSectionById(props.song, props.sectionId);
  if (section.isErr()) {
    console.error('failed to get section', section.error);
    throw new Error('failed to get section');
  }

  useEffect(
    function setWaveformWindowOnMount() {
      const waveformWindow = songUtil.computeDataWindowForSection(
        props.song,
        props.sectionId,
        props.waveform,
      );

      if (waveformWindow.isErr()) {
        console.error('failed to get waveform window', waveformWindow.error);
        throw new Error('failed to get waveform window');
      }

      setWaveformWindow(waveformWindow.value);
    },
    [props.waveform, props.sectionId, props.song],
  );

  return (
    <>
      <svg
        width="340"
        height="50"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => props.onSectionClick()}
      >
        <rect
          stroke={
            props.arrangementRole !== 'arrangement-middle'
              ? FIXED_SECTION_STROKE_COLOR
              : 'none'
          }
          strokeOpacity={0.95}
          strokeWidth="2"
          width="340"
          height="50"
          fill={
            isHovered || props.selected
              ? SECTION_HIGHLIGHT_COLOR
              : SECTION_WHITE_COLOR
          }
          x="0"
          y="0"
          rx="6"
          opacity={0.98}
        />
        <text
          x="10"
          y="28"
          fill={isHovered || props.selected ? 'white' : 'black'}
          fontSize={14}
        >
          {section.value.name}
        </text>
        <MusicWaveformDisplay
          waveformData={waveformWindow}
          width={240}
          height={50}
          offset={60}
        />
      </svg>
      <br />
    </>
  );
};

type TransitionDisplayProps = {
  song: InkibraRecordlessLibrarySongType;
  transition: InkibraRecordlessLibrarySongType.Transition;
  selected: boolean;
  waveform: number[];
  onTransitionClick: (
    transitionId: InkibraRecordlessLibrarySongType.Transition['id'],
  ) => void;
};

const SelectableTransitionDisplay = (props: TransitionDisplayProps) => {
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

  const [isHovered, setIsHovered] = useState(false);
  const [waveformWindow, setWaveformWindow] = useState<number[]>([]);

  useEffect(
    function setWaveformWindowOnMount() {
      const waveformWindow = songUtil.computeDataWindowForSection(
        props.song,
        props.transition.targetSectionFieldId,
        props.waveform,
      );
      if (waveformWindow.isErr()) {
        console.error('failed to get waveform window', waveformWindow.error);
        throw new Error('failed to get waveform window');
      }
      setWaveformWindow(waveformWindow.value);
    },
    [props.waveform, props.transition, props.song],
  );

  const targetSection = songUtil.getSectionById(
    props.song,
    props.transition.targetSectionFieldId,
  );
  if (targetSection.isErr()) {
    console.error('failed to get target section', targetSection.error);
    throw new Error('failed to get target section');
  }

  return (
    <>
      <svg
        width="340"
        height="20"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => props.onTransitionClick(props.transition.id)}
      >
        <rect
          stroke={
            isHovered || props.selected
              ? SECTION_HIGHLIGHT_COLOR
              : SECTION_WHITE_COLOR
          }
          strokeWidth="2"
          width="340"
          height="20"
          fill={SECTION_WHITE_COLOR}
          x="0"
          y="0"
          rx="6"
          opacity={0.98}
        />
        <text x="10" y="14" fill="black" fontSize={14}>
          {targetSection.value.name}
        </text>
        <MusicWaveformDisplay
          waveformData={waveformWindow}
          width={240}
          height={20}
          offset={60}
        />
      </svg>
      <br />
    </>
  );
};
