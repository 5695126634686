import InternalStorage from 'internal-storage';
import BaseError from './';

interface IPrivateStorage {
  code: string;
  status: number;
  paramNames: string[];
}
const storage = InternalStorage<ErrorType<string, string>, IPrivateStorage>();
/**
 * @deprecated
 */
export type ErrorInstance<
  Code extends string,
  Message extends string,
> = BaseError<ErrorType<Code, Message>, Code, Message>;

/**
 * @deprecated
 */
export class ErrorType<Code extends string, Message extends string> {
  static get DEFAULT_HTTP_STATUS() {
    return 500;
  }
  constructor(code: Code, status?: number) {
    storage(this).code = code;
    storage(this).status = status || ErrorType.DEFAULT_HTTP_STATUS;
  }
  get code(): Code {
    return storage(this).code as Code;
  }
  get status(): number {
    return storage(this).status;
  }
  /**
   * @deprecated
   */
  public error(
    message: Message,
    originalError?: unknown,
  ): BaseError<this, Code, Message> {
    return new BaseError(this, message, originalError);
  }
}
