/** @jsxImportSource @emotion/react */
export type NowPlayingInformationProps = {
  title: string;
  artist: string;
  arrangement: string;
  albumArtworkPath: string;
  gridArea?: string;
};

export function NowPlayingInformation({
  albumArtworkPath,
  title,
  artist,
  arrangement,
  gridArea,
}: NowPlayingInformationProps) {
  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: '80px 175px',
        gridTemplateRows: '20px 20px 30px',
        gridTemplateAreas: `
      "artwork song-name"
      "artwork song-artist"
      "artwork arrangement-name"
    `,
        gridArea,
      }}
    >
      <img
        css={{
          gridArea: 'artwork',
          width: '70px',
          height: '70px',
          borderRadius: '6px',
        }}
        src={albumArtworkPath}
      />
      <span
        css={{
          gridArea: 'song-name',
          color: 'white',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '20px',
          fontWeight: '700',
          whiteSpace: 'nowrap',
        }}
      >
        {title}
      </span>
      <span
        css={{
          gridArea: 'song-artist',
          color: 'white',
          opacity: '0.7',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '20px',
          fontWeight: '400',
        }}
      >
        {artist}
      </span>
      <span
        css={{
          gridArea: 'arrangement-name',
          background:
            'linear-gradient(180deg, rgba(28, 20, 51, 0.57) 5.49%, #271C48 92.1%)',
          borderRadius: '4.01px 4.01px 4.01068px 4.01068px',
          color: 'white',
          fontSize: '12px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontWeight: '400',
          lineHeight: '30px',
          textAlign: 'center',
        }}
      >
        {arrangement}
      </span>
    </div>
  );
}
