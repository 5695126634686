/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { responsiveStyle } from '../constants/media-query';

import { ExplainerCard, ExplainerCardProps } from './explainer-card';

const carouselSyles = css(
  {
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    justifyContent: 'space-around',
    rowGap: '64px',
    padding: '64px 0px',
    boxSizing: 'border-box',
  },
  responsiveStyle('gridTemplateColumns', {
    xs: '1fr',
    sm: '1fr',
    md: '1fr',
    lg: '1fr 1fr 1fr',
    xl: '1fr 1fr 1fr',
  }),
);

export type ExplainerCardCarouselProps = {
  explainers: ExplainerCardProps[];
};

export function ExplainerCardCarousel({
  explainers,
}: ExplainerCardCarouselProps) {
  return (
    <div css={carouselSyles}>
      {explainers.map((explainer) => (
        <ExplainerCard
          image={explainer.image}
          title={explainer.title}
          content={explainer.content}
        />
      ))}
    </div>
  );
}
