/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { InkibraRecordlessWorkoutType } from '../type';
import { Separator } from './icons/separator';

const styles = css({
  width: '100%',
  display: 'grid',
  gridTemplateRows: 'min-content min-content',
  rowGap: '10px',
  overflow: 'auto',
});

const workoutTitleStyles = css({
  fontWeight: '700',
  fontSize: '50px',
  textAlign: 'center',
  color: '#F2F2F7',
  textTransform: 'uppercase',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxHeight: '3em',
  lineHeight: '1em',
});

const workoutPropertiesStyles = css({
  display: 'grid',
  gridTemplateColumns: '1fr min-content 1fr',
  color: '#FFFFFF',
  alignItems: 'center',
});

const workoutTimeStyles = css({
  fontWeight: '800',
  fontSize: '12px',
  textAlign: 'right',
});

const separatorStyles = css({
  margin: '0 16px',
});

const workoutSegmentsStyles = css({
  fontWeight: '400',
  fontSize: '12px',
  textAlign: 'left',
});

export type WorkoutDetailsProps = {
  workout: InkibraRecordlessWorkoutType;
};

export function WorkoutDetails(props: WorkoutDetailsProps) {
  const workoutDuration = props.workout.intervals.reduce(
    (acc, interval) => acc + interval.time,
    0,
  );

  return (
    <div css={styles}>
      <div css={workoutTitleStyles}>{props.workout.name}</div>

      <div css={workoutPropertiesStyles}>
        <div css={workoutTimeStyles}>
          {Math.ceil(workoutDuration / 60)} MINUTES
        </div>
        <div css={separatorStyles}>
          <Separator />
        </div>
        <div css={workoutSegmentsStyles}>
          {props.workout.intervals.length} SEGMENTS
        </div>
      </div>
    </div>
  );
}
