/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { InkibraRecordlessWorkoutType } from '../type';
import { Plus } from './icons/plus';
import { StretchingMan } from './icons/stretching-man';

const styles = css({
  width: '100%',
  height: '100%',
  display: 'grid',
  padding: '0 8px 0',
  gridTemplateColumns: 'repeat(auto-fill, minmax(175px, 1fr))', // Changed to auto-fill with flexible sizes
  gridAutoRows: '100px',
  gap: '8px',
  justifyContent: 'center',
});

const itemStyles = css({
  borderRadius: '7px',
  padding: '25px',
});

const createWorkoutItemStyles = css(
  {
    display: 'grid',
    gridTemplateRows: 'min-content min-content',
    gap: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #52FF00',
    boxShadow: 'inset 0px 1.25px 1.25px rgba(255, 255, 255, 0.24)',
    color: '#52FF00',
    fontSize: '12px',
    fontWeight: '600',
    justifyItems: 'center',
  },
  itemStyles,
);

const createWorkoutStyles = css({
  whiteSpace: 'nowrap',
});

const workoutItemStyles = css(
  {
    background: 'linear-gradient(180deg, #3F16E1 0%, #08031D 100%)',
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    color: '#FFFFFF',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  itemStyles,
);

const workoutItemContentStyles = css({
  display: 'grid',
  gridTemplateRows: 'min-content min-content',
  gap: '8px',
});

const workoutTitleStyles = css({
  fontWeight: '700',
  fontSize: '13px',
  overflow: 'hidden',
  textTransform: 'uppercase',
});

const workoutTimeStyles = css({
  fontWeight: '400',
  fontSize: '12px',
});

const emptyItemStyles = css(
  {
    background: 'linear-gradient(180deg, #3F16E1 -292.93%, #08031D 100%)',
  },
  itemStyles,
);

export type WorkoutListProps = {
  workouts: InkibraRecordlessWorkoutType[];
  onCreateWorkout: () => void;
  onSelectWorkout: (id: string) => void;
};

export function WorkoutList({
  workouts,
  onCreateWorkout,
  onSelectWorkout,
}: WorkoutListProps) {
  const minimumWorkouts = 3;

  const fills =
    workouts.length > 3
      ? Array()
      : Array(minimumWorkouts - workouts.length).fill(0);

  return (
    <div css={styles}>
      <div css={createWorkoutItemStyles} onClick={onCreateWorkout}>
        <span>
          <Plus />
        </span>
        <span css={createWorkoutStyles}>CREATE WORKOUT</span>
      </div>

      {workouts.map((workout, index) => (
        <div
          key={index}
          css={css(workoutItemStyles, {
            backgroundImage: workout.backgroundImage
              ? `linear-gradient(180deg, rgba(63, 22, 225, 0.37) 8%, rgba(63, 22, 225, 0.5) 25%, rgba(63, 22, 225, 0.37) 100%), url(${workout.backgroundImage})`
              : undefined,
          })}
          onClick={() => onSelectWorkout(workout.id)}
        >
          <div css={workoutItemContentStyles}>
            <span css={workoutTitleStyles}>{workout.name}</span>
            <span css={workoutTimeStyles}>
              {Math.ceil(
                workout.intervals.reduce(
                  (sum, interval) => sum + interval.time,
                  0,
                ) / 60,
              )}
              MIN
            </span>
          </div>

          {workout.backgroundImage === undefined && <StretchingMan />}
        </div>
      ))}

      {fills.map((_, index) => (
        <div key={index} css={emptyItemStyles} />
      ))}
    </div>
  );
}
