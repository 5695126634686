/** @jsxImportSource @emotion/react */

export type MusicWaveformDisplayProps = {
  waveformData: number[];
  width: number;
  height: number;
  offset: number;
};
export const MusicWaveformDisplay = (props: MusicWaveformDisplayProps) => {
  const waveformData = props.waveformData ? props.waveformData : [];
  const rectWidth = props.width / waveformData.length;
  return (
    <svg
      x={props.offset}
      width={props.width}
      height={props.height}
      css={{
        pointerEvents: 'none',
      }}
    >
      {/* The red reference line */}
      <line
        x1="0"
        y1={props.height / 2}
        x2={props.width}
        y2={props.height / 2}
        stroke="red"
        strokeWidth={1}
      />
      {/* The waveform lines in blue */}
      {waveformData.map((waveform, index) => {
        return (
          <line
            key={`${index}-waveform-line-${waveform}`}
            x1={index * rectWidth}
            y1={props.height / 2 - props.height * waveform}
            x2={index * rectWidth}
            y2={props.height / 2 + props.height * waveform} // Adjust the second y coordinate for better spacing
            stroke="blue"
            strokeWidth={1}
          />
        );
      })}
    </svg>
  );
};
