/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import {
  CSS,
  DragHandle,
  PlayButton,
  responsiveStyle,
  useSortable,
} from '@inkibra/recordless.ux';
import { useContext, useLayoutEffect, useState } from 'react';
import {
  useLibraryAlbumArtworkLocator,
  useRecordlessLibraryArrangementWaveform,
} from '../hooks';
import { InkibraRecordlessLibraryMixType } from '../type-mix';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { MusicBeatGrid } from './beat-grid';
import { beatIndexToSeconds, secondsToOffset } from './utils';
import { MusicWaveformDisplay } from './waveform-display';

function formatDuration(duration: number) {
  const date = new Date(0);
  date.setSeconds(duration);
  return date.toISOString().substring(15, 19);
}

export type MixNodeDisplayProps = {
  id: string;
  mix: InkibraRecordlessLibraryMixType;
  mixNode: InkibraRecordlessLibraryMixType.MixElement;
  title: string;
  artist: string;
  arrangement: string;
  albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug;
  album: string;
  arranger?: string;
  duration: number;
  containerRef: React.RefObject<HTMLDivElement>;
};

export function MixNodeDisplay({
  sortKey,
  albumSlug,
  title,
  artist,
  arrangement,
  album,
  duration,
  onClick,
  containerRef,
  mix,
  mixNode,
}: MixNodeDisplayProps & { sortKey: string; onClick?: () => void }) {
  const cache = useContext(CacheContext)();
  const hookEffectContext = new HookEffectContext();
  const albumArtworkLocator = hookEffectContext.addEffect(
    useLibraryAlbumArtworkLocator(albumSlug),
  );
  const waveformData = hookEffectContext.addEffect(
    useRecordlessLibraryArrangementWaveform(
      mixNode.currentLibraryArrangement.id,
    ),
  );
  hookEffectContext.runEffects(cache);
  const [svgRenderingWidth, setSvgRenderingWidth] = useState(0);

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: sortKey });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [hoveringBeatIndex, setHoveringBeatIndex] = useState<
    number | undefined
  >(undefined);

  useLayoutEffect(() => {
    const onResize = () => {
      if (containerRef.current) {
        setSvgRenderingWidth(containerRef.current.offsetWidth - 50);
      }
    };
    window.addEventListener('resize', onResize);
    setTimeout(onResize, 0);
    return () => window.removeEventListener('resize', onResize);
  }, [containerRef]);

  const pixelsPerSecond = svgRenderingWidth / duration;
  const nominalBpm = mixNode.currentLibraryArrangement.librarySong.bpm;

  const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({});

  const bodyDurationResult = mixUtil.calculateBodyDuration(mix, mixNode.id);
  const headBeatsResult = mixUtil.calculateMixElementNodeHeadBeats(
    mix,
    mixNode.id,
  );

  if (bodyDurationResult.isErr() || headBeatsResult.isErr()) {
    console.error('Error calculating body duration or head beats', {
      bodyDurationResult,
      headBeatsResult,
    });
    return null;
  }

  const bodyDuration = bodyDurationResult.value;
  const headBeats = headBeatsResult.value;
  return (
    <div
      {...attributes}
      {...listeners}
      ref={setNodeRef}
      style={style}
      css={css(
        {
          display: 'grid',
          gridTemplateColumns: '90px 1fr 0.5fr 1fr 1fr 10px',
          gridTemplateRows: '35px 35px 70px',
          gridTemplateAreas: `
        "artwork song-name   artist   tail-beats  duration drag-handle"
        "artwork arrangement album bpm  duration drag-handle"
        "intermix intermix intermix intermix intermix intermix"
      `,
          paddingTop: '20px',
          marginTop: '20px',
          borderTop: '1px solid rgba(255, 255, 255, 0.25)',
          rowGap: '10px',
        },
        responsiveStyle('gridTemplateAreas', {
          xs: `
        "artwork song-name duration drag-handle"
        "artwork artist arrangement drag-handle"
        "intermix intermix intermix intermix"
      `,
          sm: `
        "artwork song-name   artist   tail-beats  duration drag-handle"
        "artwork arrangement album bpm  duration drag-handle"
        "intermix intermix intermix intermix intermix intermix"
      `,
        }),
        responsiveStyle('gridTemplateColumns', {
          xs: '90px 1fr 100px 10px',
          sm: '90px 1fr 0.5fr 1fr 1fr 10px',
        }),
      )}
    >
      <div
        css={{
          gridArea: 'artwork',
          width: '70px',
          height: '70px',
          borderRadius: '6px',
          position: 'relative',
          userSelect: 'none',
        }}
      >
        <img
          css={{
            width: '70px',
            height: '70px',
            borderRadius: '6px',
            pointerEvents: 'none',
            userSelect: 'none',
          }}
          src={albumArtworkLocator?.url || ''}
        />
        <button
          data-no-dnd="true"
          css={{
            width: '70px',
            height: '70px',
            cursor: onClick ? 'pointer' : 'default',
            position: 'absolute',
            background: 'none',
            border: 'none',
            transform: 'scale(0.3)',
            top: '0',
            left: '0',
            opacity: onClick ? 0.05 : 0,
            ':hover': {
              opacity: onClick ? 1 : 0,
            },
          }}
          onClick={onClick}
        >
          <PlayButton
            style={{
              width: '70px',
              height: '70px',
              position: 'absolute',
              top: '0',
              left: '0',
            }}
          />
        </button>
      </div>
      <span
        css={{
          gridArea: 'song-name',
          color: 'white',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '20px',
          fontWeight: '700',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {title}
      </span>
      <span
        css={{
          gridArea: 'arrangement',
          color: 'white',
          opacity: '0.7',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          fontWeight: '400',
          lineHeight: '20px',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {arrangement}
      </span>
      <span
        css={{
          gridArea: 'artist',
          color: 'white',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '20px',
          fontWeight: '400',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {artist}
      </span>
      <span
        css={css(
          {
            gridArea: 'album',
            color: 'white',
            fontSize: '15px',
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            lineHeight: '60px',
            fontWeight: '400',
            pointerEvents: 'none',
            userSelect: 'none',
          },
          responsiveStyle('display', {
            xs: 'none',
            sm: 'block',
          }),
        )}
      >
        {album}
      </span>
      <span
        css={css({
          gridArea: 'tail-beats',
        })}
      >
        Tail Beats:
        <input
          type="number"
          value={mixNode.mixTarget.targetTailSeamBeats}
          onChange={(e) => {
            const newTailBeats = parseInt(e.target.value);
            if (Number.isNaN(newTailBeats)) {
              return;
            }
            const updatedMixResult = mixUtil.modifyMixElementNode(
              mix,
              mixNode.id,
              {
                mixTarget: {
                  targetTailSeamBeats: newTailBeats,
                  targetBpm: mixNode.mixTarget.targetBpm,
                  targetDetune: mixNode.mixTarget.targetDetune,
                },
              },
            );
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>
      <span css={{ gridArea: 'bpm' }}>
        BPM: {nominalBpm}
        <input
          type="number"
          value={mixNode.mixTarget.targetBpm}
          onChange={(e) => {
            const newBpm = parseInt(e.target.value);
            if (Number.isNaN(newBpm)) {
              return;
            }
            const updatedMixResult = mixUtil.modifyMixElementNode(
              mix,
              mixNode.id,
              {
                mixTarget: {
                  targetBpm: newBpm,
                  targetDetune: mixNode.mixTarget.targetDetune,
                  targetTailSeamBeats: mixNode.mixTarget.targetTailSeamBeats,
                },
              },
            );
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>

      <span
        css={css(
          {
            gridArea: 'duration',
            color: 'white',
            fontSize: '15px',
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            fontWeight: '400',
            pointerEvents: 'none',
            userSelect: 'none',
          },
          responsiveStyle('lineHeight', {
            xs: '20px',
            sm: '60px',
          }),
        )}
      >
        Nominal:{formatDuration(duration)} / Body:
        {formatDuration(bodyDuration)}
      </span>
      <svg
        width={svgRenderingWidth}
        css={{
          gridArea: 'intermix',
        }}
      >
        <MusicWaveformDisplay
          width={svgRenderingWidth}
          height={70}
          waveformData={waveformData}
          offset={0}
        />
        <MusicBeatGrid
          width={svgRenderingWidth}
          highlightedBeatIndex={hoveringBeatIndex}
          beatWidth={(60 / nominalBpm) * pixelsPerSecond}
          gridOffset={0}
          onHover={(beat) => setHoveringBeatIndex(beat)}
          onLeave={() => setHoveringBeatIndex(undefined)}
          onDoubleClick={(beat) => {
            const updatedMixResult = mixUtil.modifyMixElementNode(
              mix,
              mixNode.id,
              {
                bodyBeats: beat - headBeats,
              },
            );
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
        <MixElementLabel
          label={'Head'}
          x={secondsToOffset(
            beatIndexToSeconds(headBeats, nominalBpm),
            pixelsPerSecond,
          )}
          y={0}
        />
        <MixElementLabel
          label={'Tail Start'}
          x={secondsToOffset(
            beatIndexToSeconds(headBeats + mixNode.bodyBeats, nominalBpm),
            pixelsPerSecond,
          )}
          y={0}
        />
        <MixElementLabel
          label={'Tail End'}
          x={secondsToOffset(
            beatIndexToSeconds(
              headBeats +
                mixNode.bodyBeats +
                mixNode.mixTarget.targetTailSeamBeats,
              nominalBpm,
            ),
            pixelsPerSecond,
          )}
          y={0}
        />
      </svg>
      <DragHandle
        style={{
          fill: '#919eab',
          pointerEvents: 'none',
          userSelect: 'none',
          cursor: 'grab',
          alignSelf: 'center',
          marginTop: '25px',
        }}
      />
    </div>
  );
}

type MixElementLabelProps = {
  x: number;
  y: number;
  label: string;
};
function MixElementLabel(props: MixElementLabelProps) {
  return (
    <svg x={props.x} y={props.y} width="70" height="40">
      <text fontSize="12" y="12" fill="white" transform={'rotate(90, 0, 12)'}>
        {props.label}
      </text>
    </svg>
  );
}
