/** @jsxImportSource @emotion/react */

export function Easy() {
  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_350_537"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <rect width="80" height="80" rx="20" fill="white" />
      </mask>
      <g mask="url(#mask0_350_537)">
        <rect width="80" height="80" fill="#F8E011" fill-opacity="0.77" />
        <circle
          cx="60.5"
          cy="66.5"
          r="31.5"
          fill="#F31875"
          stroke="black"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
