/** @jsxImportSource @emotion/react */

export type FormGroupWrapperProps = {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  formHeaderAction?: React.ReactNode;
};

export const FormGroupWrapper = ({
  children,
  title,
  subtitle,
  formHeaderAction,
}: FormGroupWrapperProps) => {
  return (
    <div
      css={{
        label: 'form-group-wrapper',
        width: '100%',
        border: '1px solid #5B5B5B',
        borderRadius: '12px',
      }}
    >
      <div
        css={{
          label: 'form-group-header',
          padding: '12px 14px',
          borderBottom: '1px solid #5B5B5B',
          display: 'grid',
          gridTemplateColumns: 'auto auto',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={{
            label: 'form-group-header-text',
            display: 'grid',
            gap: '4px',
          }}
        >
          <div
            css={{
              label: 'form-group-title',
              fontWeight: '700',
              fontSize: '12px',
              color: '#FFFFFF',
              lineHeight: '150%',
            }}
          >
            {title}
          </div>
          {subtitle && (
            <div
              css={{
                label: 'form-group-subtitle',
                fontWeight: '400',
                fontSize: '12px',
                color: '#6D6D6D',
              }}
            >
              {subtitle}
            </div>
          )}
        </div>
        {formHeaderAction}
      </div>
      {children}
    </div>
  );
};
