export function pixelOffsetToSeconds(
  pixelOffset: number,
  pixelsPerSecond: number,
) {
  return pixelOffset / pixelsPerSecond;
}

export function beatIndexToSeconds(beatIndex: number, bpm: number) {
  return beatIndex * (60 / bpm);
}

export function secondsToOffset(seconds: number, pixelsPerSecond: number) {
  return seconds * pixelsPerSecond;
}
