import { Result } from 'neverthrow';
import { CacheableObject } from './cacheable-object';

/**
 * Represents a static interface for managing typed data.
 * @template DataType - The type of the data object.
 * @template OldDataType - The type of the old data object.
 * @template CreateData - The type of the data used for creating a new instance.
 * @template CreateErrors - The type of errors that can occur during the creation process.
 * @template FromDataErrors - The type of errors that can occur during the conversion from data process.
 * @deprecated
 */
export interface TypedDataManagerStatic<
  DataType extends CacheableObject,
  OldDataType,
  CreateData,
  CreateErrors,
  FromDataErrors,
> {
  /**
   * Creates a new instance of the data manager.
   * @param data - The data object.
   * @returns An unknown instance of the data manager.
   */
  new (data: DataType): unknown;

  /**
   * Converts the given data object to an instance of the data manager.
   * @param data - The data object or the old data object.
   * @returns A result containing an instance of the data manager or an error.
   */
  FromData(
    data: DataType | OldDataType,
  ): Result<InstanceType<this>, FromDataErrors>;

  /**
   * Creates a new instance of the data manager using the given data.
   * @param data - The data used for creating a new instance.
   * @returns A result containing an instance of the data manager or an error.
   */
  Create(data: CreateData): Result<InstanceType<this>, CreateErrors>;
}

/**
 * Abstract class representing a typed data manager.
 * @template DataType - The type of data managed by the manager.
 */
export abstract class TypedDataManager<DataType extends CacheableObject> {
  #data: DataType;

  /**
   * Constructs a new TypedDataManager instance.
   * @param data - The initial data for the manager.
   */
  constructor(data: DataType) {
    this.#data = data;
  }

  /**
   * Gets the data managed by the manager.
   * @returns The managed data.
   */
  get data(): DataType {
    return this.#data;
  }

  /**
   * Gets the id of the managed data.
   * @returns The id of the managed data.
   */
  get id(): DataType['id'] {
    return this.#data.id;
  }

  /**
   * Gets the type of the managed data.
   * @returns The type of the managed data.
   */
  get type(): DataType['type'] {
    return this.#data.type;
  }

  /**
   * Gets the reference to the managed data.
   * @returns The reference to the managed data.
   */
  get ref(): CacheableObject.Reference<DataType> {
    return CacheableObject.makeRef(this.#data);
  }

  /**
   * Creates a factory for a specific TypedDataManager class.
   * @param staticClass - The static class of the TypedDataManager.
   * @returns An object containing the FromData and Create methods of the TypedDataManager class.
   * @example
   * const ExampleDataManagerFactory = CreateDataManagerFactory<CurrentDataType, OldDataType, CreateData, typeof ExampleDataManager>(ExampleDataManager);
   */
  public static CreateFactory<
    CurrentDataType extends CacheableObject,
    OldDataType extends CacheableObject,
    CreateData,
    TDataManagerStatic extends TypedDataManagerStatic<
      CurrentDataType,
      OldDataType,
      CreateData,
      CreateErrors,
      FromDataErrors
    >,
    CreateErrors = never,
    FromDataErrors = never,
  >(staticClass: TDataManagerStatic) {
    return {
      FromData: staticClass.FromData,
      Create: staticClass.Create,
    } as const;
  }
}
