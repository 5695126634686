/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { useEffect, useState } from 'react';
import { responsiveStyle } from '../constants/media-query';
import {
  MarketingStepCard,
  MarketingStepCardProps,
} from './marketing-step-card';

export type MarketingStepCardCarouselProps = {
  cards: Omit<MarketingStepCardProps, 'highlight'>[];
  interval: number;
};

const styles = css(
  {
    display: 'grid',
    margin: 'auto',
    alignItems: 'center',
    justifyItems: 'center',
  },
  responsiveStyle('gridTemplateColumns', {
    xs: '305px',
    lg: '350px 350px',
  }),
  responsiveStyle('gridTemplateRows', {
    xs: '450px 450px 450px 450px',
    lg: '450px 450px',
  }),
);

export function MarketingStepCardCarousel({
  cards,
  interval,
}: MarketingStepCardCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [cards.length, interval]);

  return (
    <div css={styles}>
      {cards.map((cardProps, index) => (
        <MarketingStepCard
          imgSrc={cardProps.imgSrc}
          title={cardProps.title}
          paragraph={cardProps.paragraph}
          highlight={currentIndex === index}
        />
      ))}
    </div>
  );
}
