import { Bunyan } from '@inkibra/logger';
import { CacheableObject } from '@inkibra/observable-cache';
import { MimeType, SerializableResult, StatusCode } from '../constants';
import {
  ApiRoute,
  HandlerFunctionForRoute,
  RouteNamedTypes,
} from './api-route';
import { TypedFormData } from './typed-form-data';

/**
 * Type definition for the constructor arguments of FetchProvider.
 */
type FetchProviderConstructorArgs = {
  fetch: typeof fetch;
  Request: typeof Request;
  Response: typeof Response;
  Headers: typeof Headers;
  domain: string;
  port: number;
  protocol: 'http:' | 'https:';
  storage?: {
    authorization?: string;
  };
  getStoredAuthorization?: () => Promise<string | undefined>;
  setStoredAuthorization?: (token: string | undefined) => Promise<void>;
  logger: Bunyan;
  includeCredentials?: boolean;
};

/**
 * FetchProvider class provides methods to handle fetch requests.
 */
export class FetchProvider {
  public readonly fetch: FetchProviderConstructorArgs['fetch'];
  public readonly Request: FetchProviderConstructorArgs['Request'];
  public readonly Response: FetchProviderConstructorArgs['Response'];
  public readonly Headers: FetchProviderConstructorArgs['Headers'];
  public readonly storage: FetchProviderConstructorArgs['storage'];
  public readonly domain: FetchProviderConstructorArgs['domain'];
  public readonly port: FetchProviderConstructorArgs['port'];
  public readonly protocol: FetchProviderConstructorArgs['protocol'];
  public readonly includeCredentials: Required<
    FetchProviderConstructorArgs['includeCredentials']
  >;
  public readonly logger: FetchProviderConstructorArgs['logger'];
  public readonly clientId: string;
  public readonly getStoredAuthorization?: FetchProviderConstructorArgs['getStoredAuthorization'];
  public readonly setStoredAuthorization?: FetchProviderConstructorArgs['setStoredAuthorization'];

  /**
   * Constructor for FetchProvider class.
   * @param {FetchProviderConstructorArgs} args - The constructor arguments.
   */
  public constructor({
    fetch,
    Request,
    Response,
    Headers,
    storage,
    domain,
    port,
    protocol,
    includeCredentials = false,
    logger,
    getStoredAuthorization,
    setStoredAuthorization,
  }: FetchProviderConstructorArgs) {
    this.fetch = fetch;
    this.Request = Request;
    this.Response = Response;
    this.Headers = Headers;
    this.storage = storage;
    this.domain = domain;
    this.port = port;
    this.protocol = protocol;
    this.includeCredentials = includeCredentials;
    this.logger = logger;
    this.getStoredAuthorization = getStoredAuthorization;
    this.setStoredAuthorization = setStoredAuthorization;
    this.clientId = CacheableObject.makeId();
  }

  /**
   * Creates a route handler.
   * @param {ApiRoute} route - The route.
   * @returns {HandlerFunctionForRoute} The handler function for the route.
   */
  public createRouteHandler<
    Path extends string,
    RouteTypes extends RouteNamedTypes<Path>,
    TContextLevelErrors extends
      | SerializableResult.ErrWithStatusCode<unknown, StatusCode>
      | never = never,
  >(
    route: ApiRoute<Path, RouteTypes, TContextLevelErrors>,
  ): HandlerFunctionForRoute<Path, RouteTypes, void> {
    return async (args) => {
      const relativePath = route.constructPath(args);
      const fullPath = `${this.protocol}//${this.domain}:${this.port}${relativePath}`;
      let request: Request;
      const requestId = CacheableObject.makeId();
      if (args.files instanceof TypedFormData) {
        request = new this.Request(fullPath, {
          method: route.method,
          body: args.files.serializeWithBody(JSON.stringify(args.body)),
        });
        // We don't have to set the content type here because the Browser automatically sets it
        // and included the boundary in the content type header.
        // request.headers.set('Content-Type', MimeType.MULTIPART_FORM_DATA);
      } else if (args.body === undefined) {
        request = new this.Request(fullPath, { method: route.method });
      } else {
        request = new this.Request(fullPath, {
          method: route.method,
          body: JSON.stringify(args.body),
          credentials: this.includeCredentials ? 'include' : 'same-origin',
        });
        request.headers.set('Content-Type', MimeType.APPLICATION_JSON);
      }
      request.headers.set('X-Inkibra-Client-ID', this.clientId);
      request.headers.set('X-Request-ID', CacheableObject.makeId());
      if (this.storage?.authorization) {
        request.headers.set('Authorization', this.storage.authorization);
      }
      if (this.getStoredAuthorization) {
        const storedAuthorization = await this.getStoredAuthorization();
        if (storedAuthorization) {
          request.headers.set('Authorization', storedAuthorization);
        }
      }
      console.debug('Sending Request', {
        requestId,
        routeMethod: route.method,
        routeName: route.name,
        routePath: route.path,
        fullPath,
        routeArgs: { pathParams: args.pathParams, pathQuery: args.pathQuery },
        clientId: this.clientId,
      });

      const response = await this.fetch(request);
      const useAuthorization = response.headers.get('X-Use-Authorization');
      if (useAuthorization && this.storage) {
        this.storage.authorization = useAuthorization;
      }
      if (useAuthorization && this.setStoredAuthorization) {
        await this.setStoredAuthorization(useAuthorization);
      }
      const decodedResponse = await response.json();
      const parsedResponse = route.validateResponse(decodedResponse);
      console.debug('Got Response', {
        requestId,
        routeName: route.name,
        routeMethod: route.method,
        routePath: route.path,
        routeArgs: args,
        clientId: this.clientId,
        parsedResponse,
      });
      if (parsedResponse.success) {
        if (response.status !== (parsedResponse.data.statusCode as number)) {
          this.logger.error(
            `Invalid Response (Reason: Expected ${parsedResponse.data.statusCode}, Got ${response.status})`,
            {
              requestId,
              routeName: route.name,
              routeMethod: route.method,
              routePath: route.path,
              routeArgs: args,
              clientId: this.clientId,
            },
          );
          throw new Error(
            `Invalid Response (Reason: Expected ${parsedResponse.data.statusCode}, Got ${response.status})`,
          );
        }
        return parsedResponse.data;
      }
      this.logger.error('Invalid Response', {
        errors: parsedResponse.errors,
        decodedResponse,
        requestId,
        routeName: route.name,
        routeMethod: route.method,
        routePath: route.path,
        routeArgs: args,
        clientId: this.clientId,
      });
      throw new Error(
        `Invalid Response (Reason: ${JSON.stringify(
          parsedResponse.errors,
          null,
          4,
        )})`,
      );
    };
  }
}
