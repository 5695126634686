import {
  Brand,
  BrandRegex,
  CacheableObjectUtil,
  UNHANDLED_VALIDATION_FAILURE,
} from '@inkibra/observable-cache';
import { Result, err, ok } from 'neverthrow';
import typia, { tags } from 'typia';
import { InkibraSessionRoles } from './roles';

export type InkibraSession =
  | InkibraSession.UserSession
  | InkibraSession.VisitorSession;

export enum InkibraRecordlessAuthSessionType {
  VISITOR = 'VISITOR',
  AUTHENTICATED = 'AUTHENTICATED',
}

// TODO: might make sense to make two separate types for visitor and authenticated user
// TODO: prob different dals ...
export namespace InkibraSession {
  export type UserSession = {
    id: Brand<typeof InkibraSession.typename> &
      BrandRegex<typeof InkibraSession.typename>;
    email: string & tags.Format<'email'>;
    handle?: string;
    fullName: string;
    roles: InkibraSessionRoles[];
    passwordHash?: string;
    latestVerificationTime: string & tags.Format<'date-time'>;
    latestVerificationSessionBrand: InkibraSession.SessionBrand;
    initialVerificationSessionBrand: InkibraSession.SessionBrand;
    sessionKind: InkibraSession.SessionKind.AUTHENTICATED_USER;
    type: typeof InkibraSession.typename;
    modified: string & tags.Format<'date-time'>;
    created: string & tags.Format<'date-time'>;
    deleted?: string & tags.Format<'date-time'>;
    version: 0;
  };

  export type VisitorSession = {
    id: Brand<typeof InkibraSession.typename> &
      BrandRegex<typeof InkibraSession.typename>;
    email?: string & tags.Format<'email'>;
    handle?: string;
    fullName?: string;
    initialVerificationSessionBrand?: InkibraSession.SessionBrand;
    // TODO: add fields for events and marketing tracking
    // TODO: add fields for where the session was merged into
    // TODO: add fields for ip addresses?
    sessionKind: InkibraSession.SessionKind.VISITOR;
    type: typeof InkibraSession.typename;
    modified: string & tags.Format<'date-time'>;
    created: string & tags.Format<'date-time'>;
    deleted?: string & tags.Format<'date-time'>;
    version: 0;
  };

  // export type UtmParams = {
  //   utm_source?: string;
  //   utm_medium?: string;
  //   utm_campaign?: string;
  //   utm_term?: string;
  //   utm_content?: string;
  // };
  export enum SessionKind {
    VISITOR = 'VISITOR',
    AUTHENTICATED_USER = 'AUTHENTICATED_USER',
  }

  export enum SessionBrand {
    INKIBRA_ADMIN = 'INKIBRA_ADMIN',
    RECORDLESS_STUDIO = 'RECORDLESS_STUDIO',
    TONETEMPO = 'TONETEMPO',
  }

  /**
   * @description Stands for inKibra Session
   */
  export const typename = 'nksession';

  export type Creation = {
    id?: UserSession['id'];
  };

  export type Modification = Partial<
    Pick<
      UserSession,
      | 'email'
      | 'handle'
      | 'fullName'
      | 'roles'
      | 'passwordHash'
      | 'initialVerificationSessionBrand'
      | 'sessionKind'
      | 'latestVerificationTime'
      | 'latestVerificationSessionBrand'
    >
  >;

  export type SessionRegistrationData = {
    email: UserSession['email'];
    fullName: UserSession['fullName'];
    passwordHash: UserSession['passwordHash'];
    initialVerificationSessionBrand: UserSession['initialVerificationSessionBrand'];
  };

  export type VisitorSessionIdentificationData = {
    email: VisitorSession['email'];
    fullName: VisitorSession['fullName'];
  };

  export class SessionUtil extends CacheableObjectUtil<
    typeof InkibraSession.typename,
    InkibraSession['id'],
    InkibraSession,
    InkibraSession.Creation,
    never,
    InkibraSession.Modification,
    never,
    {}
  > {
    public readonly type = InkibraSession.typename;
    public static is = typia.createIs<InkibraSession>();
    public is(data: unknown): data is InkibraSession {
      return SessionUtil.is(data);
    }
    protected getValidationErrors(_: InkibraSession): false {
      return false;
    }
    protected fromCreateData(
      data: Creation,
    ): Result<Readonly<VisitorSession>, never> {
      return ok({
        id: data.id ?? this.createId2(),
        sessionKind: SessionKind.VISITOR,
        type: InkibraSession.typename,
        modified: new Date().toISOString(),
        created: new Date().toISOString(),
        version: 0,
      });
    }
    public upgradeVisitorSessionToUserSession(
      visitor: VisitorSession,
      upgradeData: SessionRegistrationData,
    ) {
      return this.modify(visitor, {
        ...upgradeData,
        roles: [],
        sessionKind: SessionKind.AUTHENTICATED_USER,
        latestVerificationTime: new Date().toISOString(),
        latestVerificationSessionBrand:
          upgradeData.initialVerificationSessionBrand,
        initialVerificationSessionBrand:
          upgradeData.initialVerificationSessionBrand,
      }) as Result<UserSession, never>;
    }

    public identifyVisitorSession(
      visitor: VisitorSession,
      identificationData: VisitorSessionIdentificationData,
    ) {
      const session = this.modify(visitor, identificationData);
      if (session.isErr()) {
        return err(session.error);
      }
      return ok(session.value as VisitorSession);
    }
    public createVisitorSession(
      id?: VisitorSession['id'],
    ): Result<VisitorSession, UNHANDLED_VALIDATION_FAILURE> {
      const session = this.create({ id });
      if (session.isErr()) {
        return err(session.error);
      }
      return ok(session.value as VisitorSession);
    }
  }
}
