import { ErrorDescriptor } from '@inkibra/error-base';
import { Brand } from '@inkibra/observable-cache';

export type JobId<JobBrand extends string> = Brand<JobBrand>;

export type JobProgressType<JobBrand extends string, JobBody = undefined> = {
  jobId: JobId<JobBrand>;
  status: 'pending' | 'running';
  body: JobBody;
};

export type JobResultType<JobBrand extends string, Result> = {
  jobId: JobId<JobBrand>;
  status: 'complete';
  result: Result;
};

export type JobErrorType<JobBrand extends string, Error> = {
  jobId: JobId<JobBrand>;
  status: 'failed';
  error: Error;
};

export type UNHANDLED_JOB_FAILURE = ErrorDescriptor<
  'UNHANDLED_JOB_FAILURE',
  'Unhandled job failure',
  { reason: string }
>;
