import { ErrorDescriptor } from '@inkibra/error-base';
import {
  Brand,
  CacheableObjectUtil,
  UNHANDLED_VALIDATION_FAILURE,
} from '@inkibra/observable-cache';
import {
  AnnotatedArrangementTransition,
  ArrangementOutputInfo,
} from '@inkibra/recordless.music-engine';
import { Result, err, ok } from 'neverthrow';
import typia from 'typia';
import {
  EnergyCurveRequestRelativeEnergy,
  InkibraRecordlessLibrarySongType,
} from './type-song';

export type InkibraRecordlessLibraryArrangementType = Readonly<{
  id: Brand<typeof InkibraRecordlessLibraryArrangementType.typename>;
  userCreatorId?: string & typia.tags.Format<'uuid'>;
  name: string;
  arrangementStatus: InkibraRecordlessLibraryArrangementType.ArrangementStatus;
  score: number;
  numberOfRatings: number;
  librarySongId: InkibraRecordlessLibrarySongType['id'];
  librarySong: InkibraRecordlessLibrarySongType;
  computedArrangementOutputInfo?: ArrangementOutputInfo;
  sectionArrangements: InkibraRecordlessLibraryArrangementType.SectionArrangement[];
  approvedTransitionSet: InkibraRecordlessLibrarySongType.Transition['id'][];
  proposedTransitionMap: {
    [transitionId: string]: InkibraRecordlessLibrarySongType.Transition;
  };
  version: 1;
  type: typeof InkibraRecordlessLibraryArrangementType.typename;
  modified: string & typia.tags.Format<'date-time'>;
  created: string & typia.tags.Format<'date-time'>;
  deleted?: string & typia.tags.Format<'date-time'>;
}>;
export namespace InkibraRecordlessLibraryArrangementType {
  export enum ArrangementStatus {
    PROPOSAL = 'PROPOSAL',
    LOCKED = 'LOCKED',
    VIRTUAL = 'VIRTUAL',
  }

  export namespace ArrangementStatus {
    export function toString(status: ArrangementStatus) {
      switch (status) {
        case ArrangementStatus.PROPOSAL:
          return 'Proposal';
        case ArrangementStatus.LOCKED:
          return 'Locked';
        case ArrangementStatus.VIRTUAL:
          return 'Virtual';
      }
    }
  }
  export const typename = 'nkrarrangement';

  export type Creation = Pick<
    InkibraRecordlessLibraryArrangementType,
    'name' | 'librarySong' | 'userCreatorId'
  >;

  export type Modification = Partial<
    Pick<
      InkibraRecordlessLibraryArrangementType,
      | 'name'
      | 'numberOfRatings'
      | 'score'
      | 'proposedTransitionMap'
      | 'approvedTransitionSet'
      | 'sectionArrangements'
      | 'arrangementStatus'
      | 'computedArrangementOutputInfo'
      | 'librarySong'
    >
  >;

  export type SectionArrangement = Readonly<{
    id: Brand<'nkrcueref'>;
    sectionFieldId: InkibraRecordlessLibrarySongType.Section['id'];
    // we should not select a transition only if there is not next section or the next section is the next section in the song
    // otherwise we should select a transition
    // The transition id can come from the song's transitions or new proposed transitions
    selectedTransitionId?: InkibraRecordlessLibrarySongType.Transition['id'];
  }>;

  export type ReviewableSectionArrangement = Readonly<{
    id: Brand<'nkrcueref'>;
    sectionFieldId: InkibraRecordlessLibrarySongType.Section['id'];
    selectedTransitionId: InkibraRecordlessLibrarySongType.Transition['id'];
    nextSectionFieldId: InkibraRecordlessLibrarySongType.Section['id'];
  }>;

  export type TransitionPlan = Readonly<{
    firstSection: InkibraRecordlessLibrarySongType.Section;
    lastSection: InkibraRecordlessLibrarySongType.Section;
    transitions: InkibraRecordlessLibrarySongType.Transition[];
    engineAnnotatedArrangementTransitions: AnnotatedArrangementTransition[];
    songStartTime: number;
    songEndTime: number;
  }>;

  export type EnergyCurveRequestSegment = Readonly<{
    duration: number;
    relativeEnergy: EnergyCurveRequestRelativeEnergy;
    // starts: CurveDescription;
    // ends: CurveDescription;
  }>;

  export namespace TransitionPlan {
    export type EMPTY_ARRANGEMENT_HAS_NO_PLAN_FAILURE = ErrorDescriptor<
      'EMPTY_ARRANGEMENT_HAS_NO_PLAN',
      'Empty arrangement has no plan',
      undefined
    >;
  }

  export type CANNOT_UPDATE_LOCKED_ARRANGEMENT_FAILURE = ErrorDescriptor<
    'CANNOT_UPDATE_LOCKED_ARRANGEMENT',
    'Cannot update a locked arrangement',
    undefined
  >;

  export type CANNOT_LOCK_ARRANGEMENT_WITH_MISSING_TRANSITIONS_FAILURE =
    ErrorDescriptor<
      'CANNOT_LOCK_ARRANGEMENT_WITH_MISSING_TRANSITIONS',
      'Cannot lock arrangement with missing transitions',
      { sectionArrangementsWithMissingTransitions: SectionArrangement[] }
    >;

  export type NO_ARRANGEMENT_SECTION_AT_INDEX_FAILURE = ErrorDescriptor<
    'NO_ARRANGEMENT_SECTION_AT_INDEX',
    'Cannot find arrangement section at index',
    { index: number }
  >;

  export type ARRANGEMENT_SECTION_NOT_FOUND_FAILURE = ErrorDescriptor<
    'ARRANGEMENT_SECTION_NOT_FOUND',
    'Cannot find arrangement section by id',
    { sectionArrangementId: string }
  >;

  export type EXPECT_NO_TRANSITION_FOR_LAST_SECTION_FAILURE = ErrorDescriptor<
    'EXPECT_NO_TRANSITION_FOR_LAST_SECTION',
    'Arrangement transition does not need to be set for the last section',
    { sectionArrangementId: string }
  >;

  export type ARRANGEMENT_TRANSITION_NOT_FOUND_FAILURE = ErrorDescriptor<
    'ARRANGEMENT_TRANSITION_NOT_FOUND',
    'Arrangement transition not found in song or in the proposed arrangements',
    { transitionId: string; sectionArrangementId: string }
  >;

  export type ARRANGEMENT_TRANSITION_SOURCE_SECTION_MISMATCH_FAILURE =
    ErrorDescriptor<
      'ARRANGEMENT_TRANSITION_SOURCE_SECTION_MISMATCH',
      'Arrangement transition source section mismatch',
      { transitionId: string; sectionArrangementId: string }
    >;

  export type ARRANGEMENT_TRANSITION_TARGET_SECTION_MISMATCH_FAILURE =
    ErrorDescriptor<
      'ARRANGEMENT_TRANSITION_TARGET_SECTION_MISMATCH',
      'Arrangement transition target section mismatch',
      { transitionId: string; sectionArrangementId: string }
    >;

  export type ARRANGEMENT_TRANSITION_REQUIRED_FAILURE = ErrorDescriptor<
    'ARRANGEMENT_TRANSITION_REQUIRED',
    `Arrangement transition required to ${string}`,
    { targetSectionFieldId: string; sectionArrangementId: string }
  >;

  export type INVALID_ARRANGEMENT_SONG_UPDATE_FAILURE = ErrorDescriptor<
    'INVALID_ARRANGEMENT_SONG_UPDATE',
    'Invalid arrangement song update',
    { arrangementId: string; songId: string }
  >;

  export type CANNOT_RESOLVE_ARRANGEMENT_PATH_FAILURE = ErrorDescriptor<
    'CANNOT_RESOLVE_ARRANGEMENT_PATH',
    'Cannot resolve arrangement path',
    {
      arrangementId: string;
      arrangementType: string;
      arrangementSongId: string;
    }
  >;

  export type ArrangementPathResolver = (
    arrangement: InkibraRecordlessLibraryArrangementType,
  ) => Promise<Result<string, CANNOT_RESOLVE_ARRANGEMENT_PATH_FAILURE>>;

  export class RecordlessArrangementUtil extends CacheableObjectUtil<
    typeof InkibraRecordlessLibraryArrangementType.typename,
    InkibraRecordlessLibraryArrangementType['id'],
    InkibraRecordlessLibraryArrangementType,
    InkibraRecordlessLibraryArrangementType.Creation,
    never,
    InkibraRecordlessLibraryArrangementType.Modification,
    never,
    {}
  > {
    public readonly type = 'nkrarrangement';
    public static is =
      typia.createIs<InkibraRecordlessLibraryArrangementType>();
    public is(data: unknown): data is InkibraRecordlessLibraryArrangementType {
      return RecordlessArrangementUtil.is(data);
    }
    protected getValidationErrors(
      _: InkibraRecordlessLibraryArrangementType,
    ): false {
      return false;
    }
    protected fromCreateData(
      data: InkibraRecordlessLibraryArrangementType.Creation,
    ): Result<Readonly<InkibraRecordlessLibraryArrangementType>, never> {
      return ok({
        ...data,
        librarySongId: data.librarySong.id,
        id: Brand.createId2<'nkrarrangement'>('nkrarrangement'),
        version: 1,
        type: InkibraRecordlessLibraryArrangementType.typename,
        sectionArrangements: [],
        approvedTransitionSet: [],
        proposedTransitionMap: {},
        score: 0,
        numberOfRatings: 0,
        arrangementStatus: ArrangementStatus.PROPOSAL,
        modified: new Date().toISOString(),
        created: new Date().toISOString(),
      });
    }
    public createVirtualArrangementWithMaxDuration(
      createData: InkibraRecordlessLibraryArrangementType.Creation,
      startingSectionIndex: number,
      maxDuration: number,
    ) {
      startingSectionIndex = Math.max(startingSectionIndex, 0);
      const create = this.create(createData);
      if (create.isErr()) {
        return create;
      }

      const librarySongUtil =
        new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

      let scheduledTime = 0;
      const sectionsToUse = create.value.librarySong.orderedSectionFields
        .slice(startingSectionIndex)
        .filter((section) => {
          const sectionDurationResult = librarySongUtil.getSectionDuration(
            create.value.librarySong,
            section.id,
          );
          if (sectionDurationResult.isErr()) {
            // TODO: pass logger into utils
            console.error(sectionDurationResult.error);
            return false;
          }
          scheduledTime += sectionDurationResult.value;
          if (scheduledTime <= maxDuration) {
            return true;
          }
          return false;
        });

      return this.modify(create.value, {
        sectionArrangements: sectionsToUse.map((section) => ({
          id: Brand.createId2<'nkrcueref'>('nkrcueref'),
          sectionFieldId: section.id,
        })),
        arrangementStatus: ArrangementStatus.VIRTUAL,
      });
    }
    /**
     *
     * Takes an energy curve request and by comparing it to the song's energy curve
     * proposes an arrangement that best fits the energy curve request.
     */
    public proposeArrangementFromEnergyCurveRequest(
      createData: InkibraRecordlessLibraryArrangementType.Creation,
      energyCurveRequest: EnergyCurveRequestSegment[],
    ) {
      const create = this.create(createData);
      if (create.isErr()) {
        return err(create.error);
      }
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );

      const firstSection = songUtil.getSectionAt(createData.librarySong, 0);
      const lastSection = songUtil.getSectionAt(createData.librarySong, -1);
      if (firstSection.isErr() || lastSection.isErr()) {
        // TODO: return a failure here
        throw new Error('Not implemented');
      }

      const orderedSectionsWithEnergyCurves =
        songUtil.assignRelativeEnergyBucketsToSections(
          createData.librarySong.orderedSectionFields,
        );

      const orderedSectionFieldsWithEnergyCurvesAndUsageCount: {
        section: InkibraRecordlessLibrarySongType.Section;
        duration: number;
        usageCount: number;
        energyLevel: EnergyCurveRequestRelativeEnergy;
      }[] = [];
      // Compute the energy curve and duration for each section
      for (const sectionWithEnergyCurve of orderedSectionsWithEnergyCurves) {
        const sectionWithEnergyCurveAndUsageCountResult = songUtil
          .computeSectionDuration(
            createData.librarySong,
            sectionWithEnergyCurve.section.id,
          )
          .andThen((duration) => {
            return ok({
              section: sectionWithEnergyCurve.section,
              duration,
              usageCount: 0,
              energyLevel: sectionWithEnergyCurve.energyLevel,
            });
          });
        if (sectionWithEnergyCurveAndUsageCountResult.isErr()) {
          // TODO: return a failure here
          throw new Error('Not implemented');
        }
        orderedSectionFieldsWithEnergyCurvesAndUsageCount.push(
          sectionWithEnergyCurveAndUsageCountResult.value,
        );
      }

      // pick sections of the song that best fit with the energy curve request
      const sectionArrangements: InkibraRecordlessLibraryArrangementType.SectionArrangement[] =
        [];
      const lastUsedSectionsIds: InkibraRecordlessLibrarySongType.Section['id'][] =
        [];
      // use `scoreAndConsumeEnergyCurveRequestSegments` to score and consume the energy curve request segments
      let remainingEnergyCurveRequestSegments = [...energyCurveRequest];
      while (remainingEnergyCurveRequestSegments.length > 0) {
        const scoredAndConsumedSections =
          this.scoreAndConsumeEnergyCurveRequestSegments(
            orderedSectionFieldsWithEnergyCurvesAndUsageCount,
            remainingEnergyCurveRequestSegments,
            lastUsedSectionsIds[lastUsedSectionsIds.length - 1],
          );

        if (sectionArrangements.length === 0) {
          // Pick the first section
          const selectedSection = scoredAndConsumedSections.find(
            (section) => section.section.id === firstSection.value.id,
          );
          if (!selectedSection) {
            // TODO: return a failure here
            throw new Error('not first section');
          }
          remainingEnergyCurveRequestSegments =
            selectedSection.remainingSegments;
          sectionArrangements.push({
            id: Brand.createId2<'nkrcueref'>('nkrcueref'),
            sectionFieldId: selectedSection.section.id,
          });
          lastUsedSectionsIds.push(selectedSection.section.id);
          continue;
        }
        // sort the sections by score
        const sortedScoredAndConsumedSections = scoredAndConsumedSections.sort(
          (a, b) => a.score - b.score,
        );

        // Filter out the last two used sections and the first and last sections
        const filteredSortedScoredAndConsumedSections =
          sortedScoredAndConsumedSections.filter((section) => {
            return (
              !lastUsedSectionsIds.includes(section.section.id) &&
              section.section.id !== firstSection.value.id &&
              section.section.id !== lastSection.value.id
            );
          });

        // Select the top section
        const selectedSection = filteredSortedScoredAndConsumedSections.at(0);
        if (!selectedSection) {
          // TODO: return a failure here
          throw new Error('not top section');
        }
        // consume the selected section
        remainingEnergyCurveRequestSegments = selectedSection.remainingSegments;
        sectionArrangements.push({
          id: Brand.createId2<'nkrcueref'>('nkrcueref'),
          sectionFieldId: selectedSection.section.id,
        });

        lastUsedSectionsIds.push(selectedSection.section.id);
        if (lastUsedSectionsIds.length > 2) {
          lastUsedSectionsIds.shift();
        }
      }

      // Add the last section to the end of the arrangement
      sectionArrangements.push({
        id: Brand.createId2<'nkrcueref'>('nkrcueref'),
        sectionFieldId: lastSection.value.id,
      });

      console.log('proposeArrangementFromEnergyCurveRequest', {
        requestedEnergyCurve: energyCurveRequest,
        arrangedSections: sectionArrangements,
      });
      return this.modify(create.value, {
        sectionArrangements,
      });
    }
    protected scoreAndConsumeEnergyCurveRequestSegments(
      orderedSectionsWithCurves: {
        section: InkibraRecordlessLibrarySongType.Section;
        duration: number;
        usageCount: number;
        energyLevel: EnergyCurveRequestRelativeEnergy;
      }[],
      energyCurveRequests: EnergyCurveRequestSegment[],
      maybeLastUsedSectionId:
        | InkibraRecordlessLibrarySongType.Section['id']
        | undefined,
    ): {
      section: InkibraRecordlessLibrarySongType.Section;
      score: number;
      consumedSegments: EnergyCurveRequestSegment[];
      usageCount: number;
      remainingSegments: EnergyCurveRequestSegment[];
    }[] {
      const results: {
        section: InkibraRecordlessLibrarySongType.Section;
        score: number;
        consumedSegments: EnergyCurveRequestSegment[];
        remainingSegments: EnergyCurveRequestSegment[];
        usageCount: number;
      }[] = [];

      for (const sectionWithCurve of orderedSectionsWithCurves) {
        let accumulatedSegmentDuration = 0;
        const consumedSegments: EnergyCurveRequestSegment[] = [];
        const remainingSegments = [...energyCurveRequests];

        // Process each segment until the section's duration is filled
        while (
          accumulatedSegmentDuration < sectionWithCurve.duration &&
          remainingSegments.length > 0
        ) {
          const currentSegment = remainingSegments.shift();
          if (!currentSegment) {
            break;
          }

          const remainingCapacity =
            sectionWithCurve.duration - accumulatedSegmentDuration;
          const consumableDuration = Math.min(
            remainingCapacity,
            currentSegment.duration,
          );

          // If the segment is too long, split it into two segments
          if (consumableDuration < currentSegment.duration) {
            consumedSegments.push({
              ...currentSegment,
              duration: consumableDuration,
            });
            remainingSegments.unshift({
              ...currentSegment,
              duration: currentSegment.duration - consumableDuration,
            });
          } else {
            // Otherwise, consume the segment
            consumedSegments.push(currentSegment);
          }
          // Finally, increment the accumulated duration
          accumulatedSegmentDuration += consumableDuration;
        }

        // Calculate the score for the section based on the consumed sections
        // Look each of the consumed segment's average energies and calculate the absolute difference
        // between that and the section's average energy over that part of its energy curve
        let score = 0;
        const totalConsumedSegmentDuration = consumedSegments.reduce(
          (a, b) => a + b.duration,
          0,
        );
        for (const consumedSegment of consumedSegments) {
          // Score based on matching energy levels
          const energyLevelMatch =
            sectionWithCurve.energyLevel === consumedSegment.relativeEnergy
              ? 0.1
              : sectionWithCurve.energyLevel ===
                    EnergyCurveRequestRelativeEnergy.MEDIUM ||
                  consumedSegment.relativeEnergy ===
                    EnergyCurveRequestRelativeEnergy.MEDIUM
                ? 0.5 // If we got or requested medium energy then the mismatch is less severe
                : 1;
          score +=
            energyLevelMatch *
            (consumedSegment.duration / totalConsumedSegmentDuration);
        }

        // Decrement the score if the scored section follows the last used section
        const lastUsedSectionIndex = orderedSectionsWithCurves.findIndex(
          (section) => section.section.id === maybeLastUsedSectionId,
        );
        const scoredSectionIndex = orderedSectionsWithCurves.findIndex(
          (section) => section.section.id === sectionWithCurve.section.id,
        );
        if (
          lastUsedSectionIndex !== -1 &&
          lastUsedSectionIndex + 1 === scoredSectionIndex
        ) {
          score *= 0.5;
        }

        results.push({
          section: sectionWithCurve.section,
          score,
          consumedSegments,
          remainingSegments,
          usageCount: sectionWithCurve.usageCount,
        });
      }
      return results;
    }
    public lockArrangement(
      data: InkibraRecordlessLibraryArrangementType,
      computedArrangementOutputInfo: ArrangementOutputInfo,
      sectionArrangements: InkibraRecordlessLibraryArrangementType.SectionArrangement[],
      approvedTransitionSet: InkibraRecordlessLibrarySongType.Transition['id'][],
      proposedTransitionMap: {
        [transitionId: string]: InkibraRecordlessLibrarySongType.Transition;
      },
    ): Result<
      InkibraRecordlessLibraryArrangementType,
      | UNHANDLED_VALIDATION_FAILURE
      | InkibraRecordlessLibrarySongType.Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | CANNOT_LOCK_ARRANGEMENT_WITH_MISSING_TRANSITIONS_FAILURE
    > {
      const modified = this.modify(data, {
        sectionArrangements,
        approvedTransitionSet,
        proposedTransitionMap,
        computedArrangementOutputInfo,
        arrangementStatus: ArrangementStatus.LOCKED,
      });
      if (modified.isErr()) {
        return err(modified.error);
      }
      const sectionArrangementsWithMissingTransitionsResult =
        this.findAllSectionArrangementsWithMissingTransitions(modified.value);
      if (sectionArrangementsWithMissingTransitionsResult.isErr()) {
        return err(sectionArrangementsWithMissingTransitionsResult.error);
      }
      if (sectionArrangementsWithMissingTransitionsResult.value.length > 0) {
        return err({
          code: 'CANNOT_LOCK_ARRANGEMENT_WITH_MISSING_TRANSITIONS',
          message: 'Cannot lock arrangement with missing transitions',
          data: {
            sectionArrangementsWithMissingTransitions:
              sectionArrangementsWithMissingTransitionsResult.value,
          },
        });
      }
      return modified;
    }

    public updateSong(
      data: InkibraRecordlessLibraryArrangementType,
      librarySong: InkibraRecordlessLibrarySongType,
    ): Result<
      InkibraRecordlessLibraryArrangementType,
      | UNHANDLED_VALIDATION_FAILURE
      | INVALID_ARRANGEMENT_SONG_UPDATE_FAILURE
      | CANNOT_UPDATE_LOCKED_ARRANGEMENT_FAILURE
    > {
      if (data.librarySong.id !== librarySong.id) {
        return err({
          code: 'INVALID_ARRANGEMENT_SONG_UPDATE',
          message: 'Invalid arrangement song update',
          data: { arrangementId: data.id, songId: librarySong.id },
        });
      }
      return this.modify(data, { librarySong }).andThen((arrangement) => {
        // Do this so that we can fill in any new transitions
        return this.updateSectionArrangementOrder(
          arrangement,
          arrangement.sectionArrangements,
        );
      });
    }
    public updateName(
      data: InkibraRecordlessLibraryArrangementType,
      name: string,
    ) {
      return this.modify(data, { name });
    }
    public proposeTransition(
      data: InkibraRecordlessLibraryArrangementType,
      transition: InkibraRecordlessLibrarySongType.Transition,
    ): Result<
      InkibraRecordlessLibraryArrangementType,
      UNHANDLED_VALIDATION_FAILURE | CANNOT_UPDATE_LOCKED_ARRANGEMENT_FAILURE
    > {
      if (data.arrangementStatus === ArrangementStatus.LOCKED) {
        return err({
          code: 'CANNOT_UPDATE_LOCKED_ARRANGEMENT',
          message: 'Cannot update a locked arrangement',
          data: undefined,
        });
      }
      return this.modify(data, {
        proposedTransitionMap: {
          ...data.proposedTransitionMap,
          [transition.id]: transition,
        },
      }).andThen((arrangement) => {
        return this.updateSectionArrangementOrder(
          arrangement,
          arrangement.sectionArrangements,
        );
      });
    }
    public approveTransition(
      data: InkibraRecordlessLibraryArrangementType,
      transitionId: InkibraRecordlessLibrarySongType.Transition['id'],
    ): Result<
      InkibraRecordlessLibraryArrangementType,
      UNHANDLED_VALIDATION_FAILURE | CANNOT_UPDATE_LOCKED_ARRANGEMENT_FAILURE
    > {
      if (data.arrangementStatus === ArrangementStatus.LOCKED) {
        return err({
          code: 'CANNOT_UPDATE_LOCKED_ARRANGEMENT',
          message: 'Cannot update a locked arrangement',
          data: undefined,
        });
      }

      return this.modify(data, {
        approvedTransitionSet: Array.from(
          new Set([...data.approvedTransitionSet, transitionId]),
        ),
      }).andThen((arrangement) => {
        return this.updateSectionArrangementOrder(
          arrangement,
          arrangement.sectionArrangements,
        );
      });
    }
    public addSectionArrangement(
      data: InkibraRecordlessLibraryArrangementType,
      sectionFieldId: InkibraRecordlessLibrarySongType.Section['id'],
    ) {
      if (data.arrangementStatus === ArrangementStatus.LOCKED) {
        return err({
          code: 'CANNOT_UPDATE_LOCKED_ARRANGEMENT',
          message: 'Cannot update a locked arrangement',
        });
      }
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );
      const sectionFieldResult = songUtil.getSectionById(
        data.librarySong,
        sectionFieldId,
      );
      if (sectionFieldResult.isErr()) {
        return err(sectionFieldResult.error);
      }

      return this.modify(data, {
        sectionArrangements: [
          ...data.sectionArrangements,
          {
            sectionFieldId,
            id: Brand.createId2<'nkrcueref'>('nkrcueref'),
          },
        ],
      });
    }
    public updateSectionArrangementOrder(
      data: InkibraRecordlessLibraryArrangementType,
      sectionArrangement: SectionArrangement[],
    ): Result<
      InkibraRecordlessLibraryArrangementType,
      UNHANDLED_VALIDATION_FAILURE | CANNOT_UPDATE_LOCKED_ARRANGEMENT_FAILURE
    > {
      if (data.arrangementStatus === ArrangementStatus.LOCKED) {
        return err({
          code: 'CANNOT_UPDATE_LOCKED_ARRANGEMENT',
          message: 'Cannot update a locked arrangement',
          data: undefined,
        });
      }
      return this.modify(data, {
        sectionArrangements: sectionArrangement.map(
          (sectionArrangementEntry, i) => {
            const nextSectionArrangementEntry = sectionArrangement[i + 1];
            if (!nextSectionArrangementEntry) {
              // If there is no next section arrangement entry
              // we can just return the sectionFieldId
              return {
                id: sectionArrangementEntry.id,
                sectionFieldId: sectionArrangementEntry.sectionFieldId,
              };
            }

            if (sectionArrangementEntry.selectedTransitionId) {
              // If there is a selected transition id
              // find the existing transition (proposed or in the song)
              // that matches the section arrangement
              const transition = [
                ...data.librarySong.transitions,
                ...Object.values(data.proposedTransitionMap),
              ].find((songTransition) => {
                return (
                  songTransition.id ===
                    sectionArrangementEntry.selectedTransitionId &&
                  songTransition.sourceSectionFieldId ===
                    sectionArrangementEntry.sectionFieldId &&
                  songTransition.targetSectionFieldId ===
                    nextSectionArrangementEntry.sectionFieldId
                );
              });
              // If we find a transition, return the section arrangement entry with the transition
              if (transition) {
                return {
                  id: sectionArrangementEntry.id,
                  sectionFieldId: sectionArrangementEntry.sectionFieldId,
                  selectedTransitionId: transition.id,
                };
              }
            }
            // Otherwise let's find a proposed transition that matches the section arrangement
            const proposedTransition = Object.values(
              data.proposedTransitionMap,
            ).find((songTransition) => {
              return (
                songTransition.sourceSectionFieldId ===
                  sectionArrangementEntry.sectionFieldId &&
                songTransition.targetSectionFieldId ===
                  nextSectionArrangementEntry.sectionFieldId
              );
            });
            // If we find a proposed transition, return the section arrangement entry with the transition
            if (proposedTransition) {
              return {
                id: sectionArrangementEntry.id,
                sectionFieldId: sectionArrangementEntry.sectionFieldId,
                selectedTransitionId: proposedTransition.id,
              };
            }

            // If we can't find a proposed transition
            // let's find the highest rated transition in the song
            // biasing for approved transitions
            const highestRatedTransition = data.librarySong.transitions
              .sort((a, b) => {
                const aApproved = data.approvedTransitionSet.includes(a.id);
                const bApproved = data.approvedTransitionSet.includes(b.id);
                const aScore = a.score + (aApproved ? 1 : 0);
                const bScore = b.score + (bApproved ? 1 : 0);
                return bScore - aScore;
              })
              .find((songTransition) => {
                return (
                  songTransition.sourceSectionFieldId ===
                    sectionArrangementEntry.sectionFieldId &&
                  songTransition.targetSectionFieldId ===
                    nextSectionArrangementEntry.sectionFieldId
                );
              });
            // If we find a transition, return the section arrangement entry with the transition
            if (highestRatedTransition) {
              return {
                id: sectionArrangementEntry.id,
                sectionFieldId: sectionArrangementEntry.sectionFieldId,
                selectedTransitionId: highestRatedTransition.id,
              };
            }

            // If we can't find a transition in the song
            // let's just return the section arrangement entry with no transition
            return {
              id: sectionArrangementEntry.id,
              sectionFieldId: sectionArrangementEntry.sectionFieldId,
            };
          },
        ),
      });
    }
    public getSectionArrangementById(
      data: InkibraRecordlessLibraryArrangementType,
      sectionArrangementId: SectionArrangement['id'],
    ): Result<SectionArrangement, ARRANGEMENT_SECTION_NOT_FOUND_FAILURE> {
      const sectionArrangement = data.sectionArrangements.find(
        (sectionArrangement) => {
          return sectionArrangement.id === sectionArrangementId;
        },
      );
      if (!sectionArrangement) {
        return err({
          code: 'ARRANGEMENT_SECTION_NOT_FOUND',
          data: { sectionArrangementId },
          message: 'Cannot find arrangement section by id',
        });
      }
      return ok(sectionArrangement);
    }
    public getSectionArrangementAfter(
      data: InkibraRecordlessLibraryArrangementType,
      sectionArrangementId: SectionArrangement['id'],
    ): Result<
      SectionArrangement,
      | ARRANGEMENT_SECTION_NOT_FOUND_FAILURE
      | NO_ARRANGEMENT_SECTION_AT_INDEX_FAILURE
    > {
      const sectionArrangement = data.sectionArrangements.find(
        (sectionArrangement) => {
          return sectionArrangement.id === sectionArrangementId;
        },
      );
      if (!sectionArrangement) {
        return err({
          code: 'ARRANGEMENT_SECTION_NOT_FOUND',
          data: { sectionArrangementId },
          message: 'Cannot find arrangement section by id',
        });
      }
      const sectionArrangementIndex =
        data.sectionArrangements.indexOf(sectionArrangement);
      const nextSectionArrangement =
        data.sectionArrangements[sectionArrangementIndex + 1];
      if (!nextSectionArrangement) {
        return err({
          code: 'NO_ARRANGEMENT_SECTION_AT_INDEX',
          data: { index: sectionArrangementIndex + 1 },
          message: 'Cannot find arrangement section at index',
        });
      }
      return ok(nextSectionArrangement);
    }
    public updateSectionArrangementTransition(
      data: InkibraRecordlessLibraryArrangementType,
      sectionArrangementId: SectionArrangement['id'],
      transitionId?: InkibraRecordlessLibrarySongType.Transition['id'],
    ): Result<
      InkibraRecordlessLibraryArrangementType,
      | ARRANGEMENT_SECTION_NOT_FOUND_FAILURE
      | EXPECT_NO_TRANSITION_FOR_LAST_SECTION_FAILURE
      | UNHANDLED_VALIDATION_FAILURE
      | ARRANGEMENT_TRANSITION_NOT_FOUND_FAILURE
      | ARRANGEMENT_TRANSITION_SOURCE_SECTION_MISMATCH_FAILURE
      | ARRANGEMENT_TRANSITION_TARGET_SECTION_MISMATCH_FAILURE
      | InkibraRecordlessLibrarySongType.Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | ARRANGEMENT_TRANSITION_REQUIRED_FAILURE
      | CANNOT_UPDATE_LOCKED_ARRANGEMENT_FAILURE
    > {
      if (data.arrangementStatus === ArrangementStatus.LOCKED) {
        return err({
          code: 'CANNOT_UPDATE_LOCKED_ARRANGEMENT',
          message: 'Cannot update a locked arrangement',
          data: undefined,
        });
      }
      const sectionArrangement = data.sectionArrangements.find(
        (sectionArrangement) => {
          return sectionArrangement.id === sectionArrangementId;
        },
      );
      if (!sectionArrangement) {
        return err({
          code: 'ARRANGEMENT_SECTION_NOT_FOUND',
          data: { sectionArrangementId },
          message: 'Cannot find arrangement section by id',
        });
      }
      const sectionArrangementIndex =
        data.sectionArrangements.indexOf(sectionArrangement);
      const nextSectionArrangement =
        data.sectionArrangements[sectionArrangementIndex + 1];

      if (!nextSectionArrangement) {
        if (transitionId) {
          return err({
            code: 'EXPECT_NO_TRANSITION_FOR_LAST_SECTION',
            data: { sectionArrangementId },
            message:
              'Arrangement transition does not need to be set for the last section',
          });
        }
        // If there is no next section arrangement and no transition id
        // we can just remove the transition id from the section arrangement
        return this.modify(data, {
          sectionArrangements: data.sectionArrangements.map(
            (sectionArrangementEntry) => {
              if (sectionArrangementEntry.id === sectionArrangementId) {
                return {
                  ...sectionArrangementEntry,
                  selectedTransitionId: undefined,
                };
              }
              return sectionArrangementEntry;
            },
          ),
        });
      }

      if (transitionId) {
        // validate that the transition exists in the song or in the proposed transition map
        const existingTransition = [
          ...data.librarySong.transitions,
          ...Object.values(data.proposedTransitionMap),
        ].find((songTransition) => {
          return songTransition.id === transitionId;
        });
        // If we can't find the transition, return an error
        if (!existingTransition) {
          return err({
            code: 'ARRANGEMENT_TRANSITION_NOT_FOUND',
            data: { transitionId, sectionArrangementId },
            message:
              'Arrangement transition not found in song or in the proposed arrangements',
          });
        }
        // if the transition is found, validate that the source and target section fields match
        if (
          existingTransition.sourceSectionFieldId !==
          sectionArrangement.sectionFieldId
        ) {
          return err({
            code: 'ARRANGEMENT_TRANSITION_SOURCE_SECTION_MISMATCH',
            data: { transitionId, sectionArrangementId },
            message: 'Arrangement transition source section mismatch',
          });
        }
        if (
          existingTransition.targetSectionFieldId !==
          nextSectionArrangement.sectionFieldId
        ) {
          return err({
            code: 'ARRANGEMENT_TRANSITION_TARGET_SECTION_MISMATCH',
            data: { transitionId, sectionArrangementId },
            message: 'Arrangement transition target section mismatch',
          });
        }
        // If the transition is valid, set the transition id
        return this.modify(data, {
          sectionArrangements: data.sectionArrangements.map(
            (sectionArrangementEntry) => {
              if (sectionArrangementEntry.id === sectionArrangementId) {
                return {
                  ...sectionArrangementEntry,
                  selectedTransitionId: transitionId,
                };
              }
              return sectionArrangementEntry;
            },
          ),
        });
      }
      // figure out if the next section is the next section in the song
      // if it is, we don't need to set a transition so this is fine
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );
      const sectionFieldToPlanIndexResult = songUtil.getSectionIndex(
        data.librarySong,
        sectionArrangement.sectionFieldId,
      );
      if (sectionFieldToPlanIndexResult.isErr()) {
        return err(sectionFieldToPlanIndexResult.error);
      }
      const nextSectionInSongIndexResult = songUtil.getSectionIndex(
        data.librarySong,
        nextSectionArrangement.sectionFieldId,
      );
      if (nextSectionInSongIndexResult.isErr()) {
        return err(nextSectionInSongIndexResult.error);
      }
      if (
        sectionFieldToPlanIndexResult.value + 1 ===
        nextSectionInSongIndexResult.value
      ) {
        return this.modify(data, {
          sectionArrangements: data.sectionArrangements.map(
            (sectionArrangementEntry) => {
              if (sectionArrangementEntry.id === sectionArrangementId) {
                return {
                  ...sectionArrangementEntry,
                  selectedTransitionId: undefined,
                };
              }
              return sectionArrangementEntry;
            },
          ),
        });
      }
      // otherwise we should have set a transition
      return err({
        code: 'ARRANGEMENT_TRANSITION_REQUIRED',
        data: {
          targetSectionFieldId: nextSectionArrangement.sectionFieldId,
          sectionArrangementId,
        },
        message: `Arrangement transition required to ${nextSectionArrangement.sectionFieldId}`,
      });
    }

    public getGridStartTime(data: InkibraRecordlessLibraryArrangementType) {
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );
      if (data.arrangementStatus === ArrangementStatus.VIRTUAL) {
        const time = this.getSectionFields(data).andThen((sectionFields) => {
          const firstSection = sectionFields.at(0);
          if (firstSection === undefined) {
            return err('No first section');
          }
          const startTime = songUtil.getSectionQuantizedStartTime(
            data.librarySong,
            firstSection.id,
          );
          if (startTime.isErr()) {
            return err(startTime.error);
          }
          return ok(startTime.value);
        });
        if (time.isErr()) {
          return 0;
        }
        return time.value;
      }
      return 0;
    }

    public getSectionFields(
      data: InkibraRecordlessLibraryArrangementType,
    ): Result<
      InkibraRecordlessLibrarySongType.Section[],
      InkibraRecordlessLibrarySongType.Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
    > {
      const songUtils = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );
      const sectionFieldResults = data.sectionArrangements.map(
        (sectionArrangement) => {
          return songUtils.getSectionById(
            data.librarySong,
            sectionArrangement.sectionFieldId,
          );
        },
      );
      const sectionFields: InkibraRecordlessLibrarySongType.Section[] = [];

      for (const sectionFieldResult of sectionFieldResults) {
        if (sectionFieldResult.isErr()) {
          return err(sectionFieldResult.error);
        }
        sectionFields.push(sectionFieldResult.value);
      }

      return ok(sectionFields);
    }
    public makeArrangementPlan(
      data: InkibraRecordlessLibraryArrangementType,
    ): Result<
      TransitionPlan,
      | TransitionPlan.EMPTY_ARRANGEMENT_HAS_NO_PLAN_FAILURE
      | ARRANGEMENT_TRANSITION_NOT_FOUND_FAILURE
      | InkibraRecordlessLibrarySongType.Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | ARRANGEMENT_TRANSITION_REQUIRED_FAILURE
      | InkibraRecordlessLibrarySongType.Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | InkibraRecordlessLibrarySongType.Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );

      const sectionFields = this.getSectionFields(data);
      if (sectionFields.isErr()) {
        return err(sectionFields.error);
      }

      const firstSection = sectionFields.value.shift();
      if (!firstSection) {
        return err({
          code: 'EMPTY_ARRANGEMENT_HAS_NO_PLAN',
          data: undefined,
          message: 'Empty arrangement has no plan',
        });
      }

      const lastSection = sectionFields.value.pop() || firstSection;

      sectionFields.value.unshift(firstSection);
      if (firstSection !== lastSection) {
        sectionFields.value.push(lastSection);
      }

      const transitions: InkibraRecordlessLibrarySongType.Transition[] = [];

      for (const sectionArrangementToPlan of data.sectionArrangements) {
        const nextSectionArrangementPlan =
          data.sectionArrangements[
            data.sectionArrangements.indexOf(sectionArrangementToPlan) + 1
          ];
        if (nextSectionArrangementPlan) {
          if (sectionArrangementToPlan.selectedTransitionId !== undefined) {
            // if the transition is in the proposedTransitionMap
            // then we should use that transition
            const proposedTransition =
              data.proposedTransitionMap[
                sectionArrangementToPlan.selectedTransitionId
              ];
            if (proposedTransition) {
              transitions.push(proposedTransition);
              continue;
            }

            // otherwise the transition should exist in the song
            const transition = data.librarySong.transitions.find(
              (songTransition) => {
                return (
                  songTransition.id ===
                  sectionArrangementToPlan.selectedTransitionId
                );
              },
            );
            if (!transition) {
              return err({
                code: 'ARRANGEMENT_TRANSITION_NOT_FOUND',
                data: {
                  transitionId: sectionArrangementToPlan.selectedTransitionId,
                  sectionArrangementId: sectionArrangementToPlan.id,
                },
                message:
                  'Arrangement transition not found in song or in the proposed arrangements',
              });
            }
            transitions.push(transition);
            continue;
          }
          // else the next section should be the next section in the song
          const sectionFieldToPlanIndexResult = songUtil.getSectionIndex(
            data.librarySong,
            sectionArrangementToPlan.sectionFieldId,
          );
          if (sectionFieldToPlanIndexResult.isErr()) {
            return err(sectionFieldToPlanIndexResult.error);
          }
          const nextSectionInSongIndexResult = songUtil.getSectionIndex(
            data.librarySong,
            nextSectionArrangementPlan.sectionFieldId,
          );
          if (nextSectionInSongIndexResult.isErr()) {
            return err(nextSectionInSongIndexResult.error);
          }
          if (
            sectionFieldToPlanIndexResult.value + 1 ===
            nextSectionInSongIndexResult.value
          ) {
            continue;
          }
          // this isn't a valid arrangement
          return err({
            code: 'ARRANGEMENT_TRANSITION_REQUIRED',
            data: {
              targetSectionFieldId: nextSectionArrangementPlan.sectionFieldId,
              sectionArrangementId: sectionArrangementToPlan.id,
            },
            message: `Arrangement transition required to ${nextSectionArrangementPlan.sectionFieldId}`,
          });
        }
        // TODO: require no transition for the last section
      }
      const firstSectionStartTimeResult = songUtil.getSectionQuantizedStartTime(
        data.librarySong,
        firstSection.id,
      );
      if (firstSectionStartTimeResult.isErr()) {
        return err(firstSectionStartTimeResult.error);
      }
      const lastSectionEndTimeResult = songUtil.getSectionQuantizedEndTime(
        data.librarySong,
        lastSection.id,
      );
      if (lastSectionEndTimeResult.isErr()) {
        return err(lastSectionEndTimeResult.error);
      }

      const engineAnnotatedArrangementTransitions: AnnotatedArrangementTransition[] =
        transitions.map((transition) => {
          // FIXME: so this doesn't use _unsafeUnwrap()
          const sourceSectionEndTime = songUtil
            .getSectionQuantizedEndTime(
              data.librarySong,
              transition.sourceSectionFieldId,
            )
            ._unsafeUnwrap();
          const targetSectionStartTime = songUtil
            .getSectionQuantizedStartTime(
              data.librarySong,
              transition.targetSectionFieldId,
            )
            ._unsafeUnwrap();
          const targetSectionName = songUtil
            .getSectionById(data.librarySong, transition.targetSectionFieldId)
            ._unsafeUnwrap().name;
          const { crossfadeDuration, crossfadeOffset, fadeOutTrackTimeOffset } =
            transition;
          return {
            outStartTime:
              sourceSectionEndTime + fadeOutTrackTimeOffset + crossfadeOffset,
            inStartTime: targetSectionStartTime + crossfadeOffset,
            transitionDuration: crossfadeDuration,
            metadata: targetSectionName,
          };
        });

      return ok({
        firstSection,
        lastSection,
        transitions,
        engineAnnotatedArrangementTransitions,
        songStartTime: firstSectionStartTimeResult.value,
        songEndTime: lastSectionEndTimeResult.value,
      });
    }
    public getOrComputeArrangementInfo(
      data: InkibraRecordlessLibraryArrangementType,
    ): Result<
      { sectionInfo: { name: string; startTime: number }[]; duration: number },
      | InkibraRecordlessLibrarySongType.Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
      | InkibraRecordlessLibrarySongType.Section.NO_SECTION_AT_INDEX_FAILURE
    > {
      if (data.computedArrangementOutputInfo?.annotations) {
        const sectionInfo = data.computedArrangementOutputInfo.annotations.map(
          (annotation) => {
            return {
              name: annotation.metadata,
              startTime: annotation.startsAt,
            };
          },
        );
        const duration = data.computedArrangementOutputInfo.duration;
        return ok({ sectionInfo, duration });
      }

      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );

      const sectionInfo: { name: string; startTime: number }[] = [];
      let schedulingTime = 0;
      for (const sectionArrangement of data.sectionArrangements) {
        const sourceSectionField = songUtil.getSectionById(
          data.librarySong,
          sectionArrangement.sectionFieldId,
        );
        if (sourceSectionField.isErr()) {
          return err(sourceSectionField.error);
        }

        sectionInfo.push({
          name: sourceSectionField.value.name,
          startTime: schedulingTime,
        });

        const sourceSectionQuantizedStartTimeResult =
          songUtil.getSectionQuantizedStartTime(
            data.librarySong,
            sourceSectionField.value.id,
          );
        if (sourceSectionQuantizedStartTimeResult.isErr()) {
          return err(sourceSectionQuantizedStartTimeResult.error);
        }
        const sourceSectionQuantizedEndTimeResult =
          songUtil.getSectionQuantizedEndTime(
            data.librarySong,
            sourceSectionField.value.id,
          );
        if (sourceSectionQuantizedEndTimeResult.isErr()) {
          return err(sourceSectionQuantizedEndTimeResult.error);
        }
        schedulingTime +=
          sourceSectionQuantizedEndTimeResult.value -
          sourceSectionQuantizedStartTimeResult.value;
      }

      return ok({ sectionInfo, duration: schedulingTime });
    }
    public updateComputedArrangementOutputInfo(
      data: InkibraRecordlessLibraryArrangementType,
      computedArrangementOutputInfo: ArrangementOutputInfo,
    ) {
      if (data.arrangementStatus === ArrangementStatus.LOCKED) {
        return err({
          code: 'CANNOT_UPDATE_LOCKED_ARRANGEMENT',
          message: 'Cannot update a locked arrangement',
        });
      }
      return this.modify(data, {
        computedArrangementOutputInfo,
      });
    }
    public computeAverageTransitionScore(
      data: InkibraRecordlessLibraryArrangementType,
    ) {
      const arrangementPlan = this.makeArrangementPlan(data);
      if (arrangementPlan.isErr()) {
        return err(arrangementPlan.error);
      }
      let score = 0;
      arrangementPlan.value.transitions.forEach((transition) => {
        score += transition.score;
      });
      return ok(score / arrangementPlan.value.transitions.length);
    }
    public findAllSectionArrangementsWithMissingTransitions(
      data: InkibraRecordlessLibraryArrangementType,
    ): Result<
      SectionArrangement[],
      InkibraRecordlessLibrarySongType.Section.CANNOT_FIND_SECTION_BY_ID_FAILURE
    > {
      const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil(
        {},
      );

      // find the section arrangements that doesn't have a transition
      // and the next arranged section is not the natural next section in the song
      // and the next arranged section is not the last section in the song
      const sectionArrangementsWithMissingTransitions: SectionArrangement[] =
        [];
      for (const sectionArrangement of data.sectionArrangements) {
        if (sectionArrangement.selectedTransitionId) {
          continue;
        }
        const sectionArrangementIndex =
          data.sectionArrangements.indexOf(sectionArrangement);
        const nextSectionArrangement =
          data.sectionArrangements[sectionArrangementIndex + 1];
        if (!nextSectionArrangement) {
          continue;
        }
        const sectionFieldToPlanIndexResult = songUtil.getSectionIndex(
          data.librarySong,
          sectionArrangement.sectionFieldId,
        );
        if (sectionFieldToPlanIndexResult.isErr()) {
          return err(sectionFieldToPlanIndexResult.error);
        }
        const nextSectionInSongIndexResult = songUtil.getSectionIndex(
          data.librarySong,
          nextSectionArrangement.sectionFieldId,
        );
        if (nextSectionInSongIndexResult.isErr()) {
          return err(nextSectionInSongIndexResult.error);
        }
        if (
          sectionFieldToPlanIndexResult.value + 1 !==
          nextSectionInSongIndexResult.value
        ) {
          sectionArrangementsWithMissingTransitions.push(sectionArrangement);
        }
      }

      return ok(sectionArrangementsWithMissingTransitions);
    }
    public findAllReviewableSectionArrangements(
      data: InkibraRecordlessLibraryArrangementType,
      lowScoreThreshold = 0.5,
    ): Result<
      ReviewableSectionArrangement[],
      | ARRANGEMENT_TRANSITION_NOT_FOUND_FAILURE
      | ARRANGEMENT_SECTION_NOT_FOUND_FAILURE
      | NO_ARRANGEMENT_SECTION_AT_INDEX_FAILURE
    > {
      // find the sectionArrangements that are not approved and have a low score
      const reviewableSectionArrangements: ReviewableSectionArrangement[] = [];
      for (const sectionArrangement of data.sectionArrangements) {
        if (!sectionArrangement.selectedTransitionId) {
          continue;
        }
        const transition = [
          ...data.librarySong.transitions,
          ...Object.values(data.proposedTransitionMap),
        ].find((songTransition) => {
          return songTransition.id === sectionArrangement.selectedTransitionId;
        });
        if (!transition) {
          return err({
            code: 'ARRANGEMENT_TRANSITION_NOT_FOUND',
            data: {
              transitionId: sectionArrangement.selectedTransitionId,
              sectionArrangementId: sectionArrangement.id,
            },
            message:
              'Arrangement transition not found in song or in the proposed arrangements',
          });
        }
        if (
          // skip approved transitions
          data.approvedTransitionSet.includes(transition.id) ||
          // skip transitions with high scores
          transition.score >= lowScoreThreshold ||
          // skip proposed transitions
          Object.keys(data.proposedTransitionMap).includes(transition.id)
        ) {
          continue;
        }

        const nextSectionArrangementResult = this.getSectionArrangementAfter(
          data,
          sectionArrangement.id,
        );
        if (nextSectionArrangementResult.isErr()) {
          return err(nextSectionArrangementResult.error);
        }

        reviewableSectionArrangements.push({
          ...sectionArrangement,
          selectedTransitionId: sectionArrangement.selectedTransitionId,
          nextSectionFieldId: nextSectionArrangementResult.value.sectionFieldId,
        });
      }

      return ok(reviewableSectionArrangements);
    }
  }
  export const ConformingArrangementEnergyCurveRequestMap = {
    'Low Energy 60 Seconds': [
      { duration: 60, relativeEnergy: EnergyCurveRequestRelativeEnergy.LOW },
    ] as const satisfies EnergyCurveRequestSegment[],
    'Low to High to Low Energy 60 Seconds': [
      // TODO: rename to High Energy 60 Seconds
      { duration: 15, relativeEnergy: EnergyCurveRequestRelativeEnergy.LOW },
      { duration: 30, relativeEnergy: EnergyCurveRequestRelativeEnergy.HIGH },
      { duration: 15, relativeEnergy: EnergyCurveRequestRelativeEnergy.LOW },
    ] as const satisfies EnergyCurveRequestSegment[],
  } as const;
}
