/** @jsxImportSource @emotion/react */

export function ToneTempoLogo() {
  return (
    <svg
      width="99"
      height="63"
      viewBox="0 0 99 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2282_425)">
        <path
          d="M26.9123 0.76016C26.29 0.770565 25.7387 1.16423 25.5269 1.74952L11.3669 40.8784C11.0093 41.8663 11.752 42.9062 12.8024 42.8886L26.9089 42.6528C27.5313 42.6424 28.0825 42.2487 28.2943 41.6634L42.4544 2.53454C42.8119 1.54664 42.0693 0.506763 41.0189 0.524324L26.9123 0.76016Z"
          fill="white"
          stroke="#110641"
        />
        <path
          d="M17.5829 14.7073C17.8211 14.049 17.3264 13.356 16.6264 13.3673L6.37161 13.5329C5.9566 13.5396 5.58887 13.8019 5.44752 14.1922L0.493954 27.8691C0.255485 28.5275 0.750143 29.2208 1.45032 29.2095L11.7114 29.0439C12.1266 29.0372 12.4944 28.7746 12.6356 28.3842L17.5829 14.7073Z"
          fill="white"
        />
        <path
          d="M69.6047 0.76016C68.9824 0.770565 68.4311 1.16423 68.2193 1.74952L54.0592 40.8784C53.7017 41.8663 54.4443 42.9062 55.4948 42.8886L69.6013 42.6528C70.2236 42.6424 70.7749 42.2487 70.9867 41.6634L85.1468 2.53455C85.5043 1.54664 84.7617 0.506763 83.7112 0.524324L69.6047 0.76016Z"
          fill="white"
          stroke="#110641"
        />
        <path
          d="M97.5065 13.1857C97.7447 12.5271 97.2496 11.834 96.5494 11.8457L86.295 12.0172C85.8802 12.0241 85.5128 12.2864 85.3715 12.6765L80.4181 26.3529C80.1795 27.0116 80.6746 27.705 81.375 27.6933L91.6356 27.5218C92.0506 27.5149 92.4181 27.2524 92.5593 26.8621L97.5065 13.1857Z"
          fill="#52FF00"
        />
        <path
          d="M97.9767 13.3558C98.334 12.3679 97.5914 11.3282 96.541 11.3457L86.2866 11.5172C85.6644 11.5277 85.1133 11.9211 84.9014 12.5062L79.948 26.1826C79.5901 27.1706 80.3328 28.2108 81.3834 28.1932L91.644 28.0217C92.2664 28.0113 92.8177 27.6176 93.0295 27.0322L97.9767 13.3558Z"
          stroke="#52FF00"
          strokeOpacity="0.25"
        />
        <path
          d="M41.0612 62.8407C41.4823 62.8407 41.8583 62.5769 42.0016 62.1809L59.4383 13.9863C59.6766 13.3277 59.1815 12.6345 58.4813 12.6462L44.3746 12.882C43.9597 12.889 43.5922 13.1514 43.451 13.5416L26.0944 61.5004C25.8585 62.1523 26.3414 62.8407 27.0348 62.8407H41.0612Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2282_425"
          x="0.433105"
          y="-0.475952"
          width="98.6348"
          height="63.3167"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="-2" />
          <feGaussianBlur stdDeviation="0.25" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0676963 0 0 0 0 0.0232986 0 0 0 0 0.254167 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2282_425"
          />
        </filter>
      </defs>
    </svg>
  );
}
