/** @jsxImportSource @emotion/react */

import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import {
  Button,
  ButtonBackgroundColor,
  ChevronDown,
  EditableText,
  EditableTextSize,
  Ellipsis,
  PlayButton,
} from '@inkibra/recordless.ux';
import {
  ControlledMenu,
  MenuItem,
  SubMenu,
  useMenuState,
} from '@szhsin/react-menu';
import { ok } from 'neverthrow';
import { useContext, useEffect, useState } from 'react';

import { InkibraRecordlessLibraryApiFetcherRegistry } from '../api';
import { SongWithAssociatedArrangements, useMixes } from '../hooks';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibraryMixType } from '../type-mix';
import { InkibraRecordlessLibrarySongType } from '../type-song';

type ContextMenuAnchorPoint = {
  x: number;
  y: number;
};

export type SongListProps = {
  songsWithAssociatedArrangements: SongWithAssociatedArrangements[];
  onPlay: (
    data:
      | InkibraRecordlessLibrarySongType
      | InkibraRecordlessLibraryArrangementType,
  ) => void;
  onSongEdit: (data: InkibraRecordlessLibrarySongType) => void;
  onEditArrangement: (data: InkibraRecordlessLibraryArrangementType) => void;
  onRequestArrangementsForSong: (songId: string) => void;
};

export function SongList({
  songsWithAssociatedArrangements,
  onPlay,
  onSongEdit,
  onEditArrangement,
  onRequestArrangementsForSong,
}: SongListProps) {
  const cache = useContext(CacheContext)();
  const hookEffectContext = new HookEffectContext();
  const mixData = hookEffectContext.addEffect(useMixes());
  hookEffectContext.runEffects(cache);

  const [collapsed, setCollapsed] = useState<Record<string, boolean>>(
    songsWithAssociatedArrangements.reduce(
      (acc, song) => {
        acc[song.id] = true;
        return acc;
      },
      {} as Record<string, boolean>,
    ),
  );

  useEffect(
    function updateCollapsedStateAfterReceivingNewSongsWithAssociatedArrangements() {
      setCollapsed(
        songsWithAssociatedArrangements.reduce(
          (acc, song) => {
            const existingCollapsedState = collapsed[song.id];
            if (existingCollapsedState === undefined) {
              acc[song.id] = true;
            } else {
              acc[song.id] = existingCollapsedState;
            }
            return acc;
          },
          {} as Record<string, boolean>,
        ),
      );
    },
    [songsWithAssociatedArrangements],
  );

  const [menuProps, toggleMenu] = useMenuState();
  const [anchorPoint, setAnchorPoint] = useState({ x: 0, y: 0 });
  const [contextMenuArrangement, setContextMenuArrangement] = useState<
    InkibraRecordlessLibraryArrangementType | undefined
  >(undefined);
  const [contextMenuSong, setContextMenuSong] = useState<
    InkibraRecordlessLibrarySongType | undefined
  >(undefined);
  const [filterCatalogStatus, setFilterCatalogStatus] =
    useState<InkibraRecordlessLibrarySongType.CatalogStatus>();

  return (
    <>
      <div>
        <button
          onClick={() => {
            setFilterCatalogStatus(undefined);
          }}
        >
          Clear Filters
        </button>
        <button
          onClick={() => {
            setFilterCatalogStatus(
              InkibraRecordlessLibrarySongType.CatalogStatus.DRAFT,
            );
          }}
        >
          Filter Catalog Status: Draft
        </button>
        <button
          onClick={() => {
            setFilterCatalogStatus(
              InkibraRecordlessLibrarySongType.CatalogStatus.NEEDS_ATTENTION,
            );
          }}
        >
          Filter Catalog Status: Needs Attention
        </button>
        <button
          onClick={() => {
            setFilterCatalogStatus(
              InkibraRecordlessLibrarySongType.CatalogStatus.READY_FOR_REVIEW,
            );
          }}
        >
          Filter Catalog Status: Ready for Review
        </button>
        <button
          onClick={() => {
            setFilterCatalogStatus(
              InkibraRecordlessLibrarySongType.CatalogStatus
                .IN_CONTINUOUS_REVIEW,
            );
          }}
        >
          Filter Catalog Status: In Continuous Review
        </button>
      </div>
      <div>
        {songsWithAssociatedArrangements
          .filter((song) => {
            if (filterCatalogStatus === undefined) {
              return true;
            }
            return song.catalogStatus === filterCatalogStatus;
          })
          .map((song) => (
            <div key={song.id}>
              <SongRow
                onCreateArrangement={async () => {
                  const arrangementCreation =
                    await InkibraRecordlessLibraryApiFetcherRegistry.get(
                      'createInkibraRecordlessLibraryArrangement',
                    ).fn({
                      body: {
                        name: 'New Arrangement',
                        librarySong: song,
                      },
                      files: undefined,
                      pathParams: {},
                      pathQuery: {},
                    });
                  cache.input.next(arrangementCreation.value);
                  onEditArrangement(arrangementCreation.value);
                }}
                onContextMenu={(anchor, song) => {
                  setAnchorPoint(anchor);
                  toggleMenu(true);
                  setContextMenuSong(song);
                  setContextMenuArrangement(undefined);
                }}
                data={song}
                onPlay={onPlay}
                title={song.title}
                duration={song.duration}
                collapsed={collapsed[song.id] ?? true}
                onExpand={() => {
                  // TODO: replace with fetcher request
                  onRequestArrangementsForSong(song.id);
                  setCollapsed({
                    ...collapsed,
                    [song.id]: !collapsed[song.id],
                  });
                }}
              />
              {!collapsed[song.id] && (
                <ul>
                  {song.arrangements.map((arrangement) => (
                    <SongArrangement
                      onRename={async (newName) => {
                        const renameResult =
                          await InkibraRecordlessLibraryApiFetcherRegistry.get(
                            'modifyInkibraRecordlessLibraryArrangement',
                          ).fn({
                            body: { name: newName },
                            files: undefined,
                            pathParams: {
                              arrangementId: arrangement.id,
                            },
                            pathQuery: {},
                          });
                        cache.input.next(renameResult.value);
                      }}
                      onContextMenu={(anchor, arrangement) => {
                        setAnchorPoint(anchor);
                        toggleMenu(true);
                        setContextMenuArrangement(arrangement);
                        setContextMenuSong(undefined);
                      }}
                      onPlay={onPlay}
                      key={arrangement.id}
                      data={arrangement}
                    />
                  ))}
                </ul>
              )}
            </div>
          ))}
      </div>
      {/* Arrangement Context Menu */}
      {contextMenuArrangement && (
        <ControlledMenu
          {...menuProps}
          anchorPoint={anchorPoint}
          direction="right"
          onClose={() => toggleMenu(false)}
        >
          {contextMenuArrangement.arrangementStatus ===
            InkibraRecordlessLibraryArrangementType.ArrangementStatus
              .PROPOSAL && (
            <MenuItem
              onClick={() => {
                onEditArrangement(contextMenuArrangement);
              }}
            >
              Edit Arrangement
            </MenuItem>
          )}
          <SubMenu label={'Add to Mix'}>
            {mixData.map((mix, i) => {
              return (
                <MenuItem
                  key={i}
                  onClick={() => {
                    const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil(
                      {},
                    );
                    const updatedMixResult = mixUtil.createAndAppendNode(
                      mix,
                      {
                        targetBpm: contextMenuArrangement.librarySong.bpm,
                        targetDetune: 0,
                        targetTailSeamBeats: 4,
                      },
                      contextMenuArrangement,
                      {},
                    );

                    if (updatedMixResult.isErr()) {
                      console.error('Error updating mix');
                      return;
                    }

                    cache.input.next(updatedMixResult.value);
                  }}
                >
                  {mix.name}
                </MenuItem>
              );
            })}
          </SubMenu>
        </ControlledMenu>
      )}
      {/* Song Context Menu */}
      {contextMenuSong && (
        <ControlledMenu
          {...menuProps}
          anchorPoint={anchorPoint}
          direction="right"
          onClose={() => toggleMenu(false)}
        >
          <MenuItem
            onClick={() => {
              onSongEdit(contextMenuSong);
            }}
          >
            Edit Song
          </MenuItem>
        </ControlledMenu>
      )}
    </>
  );
}

function PlayButtonWrapper({ onClick }: { onClick: () => void }) {
  return (
    <button
      css={{
        width: '15px',
        height: '15px',
        border: 'none',
        background: 'none',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        cursor: 'pointer',
        color: 'white',
        position: 'relative',
        gridArea: 'play-button',
      }}
      onClick={() => {
        onClick();
      }}
    >
      <PlayButton
        style={{
          position: 'absolute',
          width: '15px',
          height: '15px',
          top: '0',
          left: '0',
        }}
      />
    </button>
  );
}

type SongRowProps = {
  data: InkibraRecordlessLibrarySongType;
  title: string;
  duration: number;
  onExpand: () => void;
  collapsed: boolean;
  onCreateArrangement: (data: InkibraRecordlessLibrarySongType) => void;
  onContextMenu: (
    anchor: ContextMenuAnchorPoint,
    data: InkibraRecordlessLibrarySongType,
  ) => void;
  onPlay: (data: InkibraRecordlessLibrarySongType) => void;
};

function SongRow({
  title,
  duration,
  onExpand,
  onPlay,
  data,
  onContextMenu,
  collapsed,
  onCreateArrangement,
}: SongRowProps) {
  const durationAsDate = new Date(0);
  durationAsDate.setSeconds(duration);

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        onContextMenu({ x: e.clientX, y: e.clientY }, data);
      }}
      css={{
        display: 'grid',
        gridTemplateColumns: '45px 1fr 200px 30px 50px 40px',
        gridColumnGap: '25px',
        gridTemplateRows: '35px',
        gridTemplateAreas: `
        "play-button song-name create-arrangement-button expand duration ellipsis"
      `,
        paddingTop: '20px',
        marginTop: '20px',
        borderTop: '1px solid rgba(255, 255, 255, 0.25)',
        alignItems: 'center',
      }}
    >
      <PlayButtonWrapper onClick={() => onPlay(data)} />
      <span
        css={{
          gridArea: 'song-name',
          color: 'white',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '20px',
          fontWeight: '700',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {title}{' '}
        <i>
          (
          {InkibraRecordlessLibrarySongType.CatalogStatus.toString(
            data.catalogStatus,
          )}
          )
        </i>
      </span>
      <span
        onClick={onExpand}
        css={{
          gridArea: 'expand',
          cursor: 'pointer',
        }}
      >
        {collapsed ? (
          <ChevronDown style={{}} />
        ) : (
          <ChevronDown style={{ transform: 'rotate(180deg)' }} />
        )}
      </span>
      {data.catalogStatus ===
        InkibraRecordlessLibrarySongType.CatalogStatus.IN_CONTINUOUS_REVIEW && (
        <Button
          onClick={() => {
            onCreateArrangement(data);
          }}
          contents="Create An Arrangement"
          gridArea="create-arrangement-button"
          buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
        />
      )}
      <span
        css={{
          gridArea: 'duration',
          color: 'white',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '15px',
          fontWeight: '400',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {durationAsDate.toISOString().substring(15, 19)}
      </span>
      <span
        onClick={(e) => {
          e.preventDefault();
          onContextMenu({ x: e.clientX, y: e.clientY }, data);
        }}
        css={{
          gridArea: 'ellipsis',
          cursor: 'pointer',
        }}
      >
        <Ellipsis style={{}} />
      </span>
    </div>
  );
}

type SongArrangementProps = {
  data: InkibraRecordlessLibraryArrangementType;
  onPlay: (data: InkibraRecordlessLibraryArrangementType) => void;
  onContextMenu: (
    anchor: ContextMenuAnchorPoint,
    data: InkibraRecordlessLibraryArrangementType,
  ) => void;
  onRename: (
    newName: string,
    data: InkibraRecordlessLibraryArrangementType,
  ) => void;
};

function SongArrangement({
  onPlay,
  data,
  onContextMenu,
  onRename,
}: SongArrangementProps) {
  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});
  const duration = arrangementUtil
    .getOrComputeArrangementInfo(data)
    .andThen((info) => {
      return ok(info.duration);
    })
    .unwrapOr(0);

  const durationAsDate = new Date(0);
  durationAsDate.setSeconds(duration);

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        onContextMenu({ x: e.clientX, y: e.clientY }, data);
      }}
      css={{
        display: 'grid',
        gridTemplateColumns:
          data.arrangementStatus ===
          InkibraRecordlessLibraryArrangementType.ArrangementStatus.LOCKED
            ? '45px 1fr 70px 40px 40px'
            : '1fr 70px 40px 40px',
        gridTemplateRows: '35px',
        gridTemplateAreas:
          data.arrangementStatus ===
          InkibraRecordlessLibraryArrangementType.ArrangementStatus.LOCKED
            ? `"play-button arrangement-name arrangement-status duration ellipsis"`
            : `"arrangement-name arrangement-status duration ellipsis"`,
        paddingTop: '20px',
        marginTop: '20px',
        borderTop: '1px solid rgba(255, 255, 255, 0.25)',
      }}
    >
      {data.arrangementStatus ===
        InkibraRecordlessLibraryArrangementType.ArrangementStatus.LOCKED && (
        <PlayButtonWrapper onClick={() => onPlay(data)} />
      )}
      <EditableText
        value={data.name}
        size={EditableTextSize.DEFAULT}
        gridArea="arrangement-name"
        onValueChange={(newValue) => {
          onRename(newValue, data);
        }}
      />
      <span
        css={{
          gridArea: 'arrangement-status',
          color: 'white',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '20px',
          fontWeight: '400',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {InkibraRecordlessLibraryArrangementType.ArrangementStatus.toString(
          data.arrangementStatus,
        )}
      </span>
      <span
        css={{
          gridArea: 'duration',
          color: 'white',
          fontSize: '15px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '20px',
          fontWeight: '400',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {durationAsDate.toISOString().substring(15, 19)}
      </span>
      <span
        onClick={(e) => {
          e.preventDefault();
          onContextMenu({ x: e.clientX, y: e.clientY }, data);
        }}
        css={{
          gridArea: 'ellipsis',
          cursor: 'pointer',
        }}
      >
        <Ellipsis style={{}} />
      </span>
    </div>
  );
}
