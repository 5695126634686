/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { responsiveStyle } from '../constants/media-query';
import { Button, ButtonBackgroundColor } from './button';
import { Divider } from './divider';
import {
  ExplainerCardCarousel,
  ExplainerCardCarouselProps,
} from './explainer-card-carousel';
import { Page } from './page';
import { RecordlessCard } from './recordless-card';
import { RecordlessCardCarousel } from './recordless-card-carousel';
import { Section } from './section';
import {
  TestimonialCardCarousel,
  TestimonialCardCarouselProps,
} from './testimonial-card-carousel';
import { TitleText, TitleTextBackground } from './title-text';
import { TransparentText } from './transparent-text';

import { Sparkles } from '../components/icons/sparkles';
import { Trophy } from '../components/icons/trophy';
import { DownloadNowButtonContents } from './button-download-now-contents';

const centerAlignStyles = css({
  textAlign: 'center',
  position: 'relative',
});

const buttonWrapperStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const responsiveText = css(
  {
    marginBottom: '16px',
    fontWeight: 300,
  },
  responsiveStyle('fontSize', {
    xs: '20px',
    sm: '20px',
    md: '24px',
    lg: '24px',
    xl: '24px',
  }),
);

const linkStyles = css({
  color: '#4512EA',
  marginTop: '24px',
  display: 'inline-block',
});

export type LandingPageProps = {
  onPrimaryCTA: () => void;
  primaryAction: 'available' | 'waitlist';
  screenshotPath: string;
  dragDropPath: string;
  explainerProps: ExplainerCardCarouselProps;
  testimonialProps: TestimonialCardCarouselProps;
  arrangementCreator: React.ReactNode;
  refreshPagePath: string;
  contestPagePath: string;
  hidePossibilitiesLearnMoreLink: boolean;
  hidePossibilitiesSection: boolean;
};

export function LandingPage({
  screenshotPath,
  dragDropPath,
  explainerProps,
  testimonialProps,
  arrangementCreator,
  refreshPagePath,
  contestPagePath,
  onPrimaryCTA: onPrimaryAction,
  primaryAction,
  hidePossibilitiesLearnMoreLink,
  hidePossibilitiesSection,
}: LandingPageProps) {
  return (
    <Page>
      <Section>
        <TitleText
          textAlign={{ xs: 'center' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '32px',
            sm: '32px',
            md: '64px',
            lg: '64px',
            xl: '64px',
          }}
          fontWeight={700}
        >
          <div
            css={css(
              responsiveStyle('display', {
                xs: 'block',
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none',
              }),
            )}
          >
            <div>
              Make Your <TransparentText value={'Music'} /> the
            </div>
            <div>
              Way <TransparentText value={'You'} /> Want it.
            </div>
          </div>

          <div
            css={css(
              responsiveStyle('display', {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
                xl: 'block',
              }),
            )}
          >
            <div>
              Make Your <TransparentText value={'Music'} /> the Way
            </div>
            <div>
              <TransparentText value={'You'} /> Want it.
            </div>
          </div>
        </TitleText>

        <div
          css={css({
            marginTop: '32px',
          })}
        />

        <TitleText
          textAlign={{ xs: 'center' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '16px',
            sm: '16px',
            md: '24px',
            lg: '24px',
            xl: '24px',
          }}
          fontWeight={300}
        >
          <div>Recordless Studio allows you to create</div>
          <div>
            professional quality remixes with an{' '}
            <TransparentText value={'AI Assist.'} />
          </div>
        </TitleText>

        <div css={css(centerAlignStyles)}>
          <img
            css={css({
              maxWidth: '100%',
              padding: '32px 0',
              position: 'relative',
              zIndex: 10,
            })}
            src={screenshotPath}
            alt="screenshot"
          />
        </div>

        <div
          css={css(centerAlignStyles, buttonWrapperStyles, {
            marginTop: '10px',
          })}
        >
          <span>
            <Button
              contents={
                <DownloadNowButtonContents
                  label={
                    primaryAction === 'available'
                      ? 'Download Now'
                      : 'Join the Waitlist'
                  }
                />
              }
              buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
              onClick={onPrimaryAction}
            />
          </span>
        </div>

        <div
          css={css({
            position: 'absolute',
            left: '40%',
            bottom: '0',
            width: '50%',
            height: '50%',
            opacity: '0.4',
            background:
              'linear-gradient(149.16deg, #4512EA 10.99%, #1282EA 32.3%, #12EA5B 48%, #EFE75E 58.01%, #E07614 71.09%, #F31875 88.77%, #4512EA 102.86%)',
            filter: 'blur(100px)',
            borderRadius: '383.217px',
            transform: 'matrix(0.97, -0.26, -0.26, -0.97, 0, 0)',
            zIndex: -10,
          })}
        />
      </Section>

      <Section>
        <TitleText
          textAlign={{ xs: 'left' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '30px',
            sm: '30px',
            md: '48px',
            lg: '48px',
            xl: '48px',
          }}
          fontWeight={700}
        >
          <div
            css={css(
              responsiveStyle('display', {
                xs: 'block',
                sm: 'none',
                md: 'none',
                lg: 'none',
                xl: 'none',
              }),
            )}
          >
            <div>
              <TransparentText value={'Now'} /> it Really is
            </div>
            <div>
              Your <TransparentText value={'Music'} />
            </div>
          </div>

          <div
            css={css(
              responsiveStyle('display', {
                xs: 'none',
                sm: 'block',
                md: 'block',
                lg: 'block',
                xl: 'block',
              }),
            )}
          >
            <div>
              <TransparentText value={'Now'} /> it Really is Your{' '}
              <TransparentText value={'Music'} />
            </div>
          </div>
        </TitleText>

        <Divider />

        <ExplainerCardCarousel explainers={explainerProps.explainers} />

        <div
          css={css({
            marginTop: '56px',
          })}
        />

        <TitleText
          textAlign={{ xs: 'center' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '30px',
            sm: '30px',
            md: '40px',
            lg: '40px',
            xl: '40px',
          }}
          fontWeight={700}
        >
          This is <TransparentText value={'Interactive Music.'} />
        </TitleText>

        <div css={css(centerAlignStyles)}>
          <img
            css={css({
              maxWidth: '100%',
              padding: '32px 0',
              position: 'relative',
              zIndex: 10,
            })}
            src={dragDropPath}
            alt="dragdrop"
          />
        </div>

        <Divider margin={'-72px 0px 0px 0px'} />
      </Section>

      <Section background={'rgba(217, 217, 217, 0.05)'}>
        <TitleText
          textAlign={{ xs: 'center' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '32px',
            md: '40px',
            lg: '48px',
          }}
          fontWeight={700}
        >
          <TransparentText value={'Early'} /> Creators are Having a{' '}
          <TransparentText value={'Blast.'} />
        </TitleText>

        <TestimonialCardCarousel testimonials={testimonialProps.testimonials} />

        <div
          css={css({
            position: 'absolute',
            left: '-75%',
            top: '5%',
            width: '100%',
            height: '150%',
            background:
              'linear-gradient(147.59deg, #4512EA 6.81%, #1282EA 19.99%, #12EA5B 29.72%, #EFE75E 35.91%, #E07614 44%, #F31875 54.95%, #4512EA 63.67%)',
            opacity: '0.3',
            filter: 'blur(90px)',
            borderRadius: '140.092px',
            transform: 'matrix(0.88, -0.48, 0.51, 0.86, 0, 0)',
            zIndex: -10,
          })}
        />
      </Section>

      <Section>
        <div
          css={css(
            {
              display: 'grid',
              rowGap: '32px',
              columnGap: '32px',
              justifyContent: 'space-around',
              margin: 'auto',
            },
            responsiveStyle('gridTemplateColumns', {
              xs: '1fr',
              sm: '1fr',
              md: '1fr',
              lg: '2fr 1fr',
              xl: '2fr 1fr',
            }),
          )}
        >
          <div
            css={css(
              {
                display: 'grid',
                rowGap: '32px',
                columnGap: '32px',
                justifyContent: 'space-around',
                margin: 'auto',
                padding: '32px',
                borderRadius: '8px',
              },
              responsiveStyle('gridTemplateColumns', {
                xs: '1fr',
                sm: '1fr',
                md: '1fr',
                lg: '1fr 1fr',
                xl: '1fr 1fr',
              }),
              responsiveStyle('background', {
                xs: 'linear-gradient(to bottom, transparent, rgba(19, 20, 21, 0.7))',
                sm: 'linear-gradient(to bottom, transparent, rgba(19, 20, 21, 0.7))',
                md: 'linear-gradient(to bottom, transparent, rgba(19, 20, 21, 0.7))',
                lg: 'linear-gradient(to right, transparent, rgba(19, 20, 21, 0.7))',
                xl: 'linear-gradient(to right, transparent, rgba(19, 20, 21, 0.7))',
              }),
            )}
          >
            <div
              css={css(
                {
                  display: 'grid',
                },
                responsiveStyle('gridTemplateRows', {
                  xs: 'min-content 172px',
                  sm: 'min-content 172px',
                  md: 'min-content 172px',
                  lg: 'min-content auto',
                  xl: 'min-content auto',
                }),
              )}
            >
              <TitleText
                textAlign={{
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'left',
                  xl: 'left',
                }}
                background={TitleTextBackground.RED_TO_PURPLE}
                fontSize={{
                  xs: '30px',
                  sm: '30px',
                  md: '48px',
                  lg: '48px',
                  xl: '48px',
                }}
                fontWeight={700}
              >
                <div>
                  <TransparentText value={'Go On,'} /> Give it a
                </div>
                <div>
                  <TransparentText value={'Whirl'} />
                </div>
              </TitleText>

              <div css={css(buttonWrapperStyles)}>
                <div>
                  <div css={css(responsiveText)}>
                    Join the waitlist to get early access.
                  </div>
                  <div css={css(responsiveText)}>
                    We're excited to see what you create.
                  </div>
                </div>
              </div>
            </div>

            <div>{arrangementCreator}</div>
          </div>

          <div
            css={css(
              {
                background: 'rgba(19, 20, 21, 0.7)',
                padding: '32px',
                borderRadius: '8px',
                display: 'grid',
              },
              responsiveStyle('gridTemplateRows', {
                xs: 'min-content 116px',
                sm: 'min-content 116px',
                md: 'min-content 116px',
                lg: 'min-content auto',
                xl: 'min-content auto',
              }),
            )}
          >
            <TitleText
              textAlign={{ xs: 'center' }}
              background={TitleTextBackground.RED_TO_PURPLE}
              fontSize={{
                xs: '30px',
                sm: '30px',
                md: '48px',
                lg: '48px',
                xl: '48px',
              }}
              fontWeight={700}
            >
              <TransparentText value={'Join Us!'} />
            </TitleText>

            <div css={css(buttonWrapperStyles)}>
              <span>
                <Button
                  contents={
                    <DownloadNowButtonContents
                      label={
                        primaryAction === 'available'
                          ? 'Get Studio'
                          : 'Join Waitlist'
                      }
                    />
                  }
                  buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
                  onClick={onPrimaryAction}
                />
              </span>
            </div>
          </div>
        </div>
      </Section>
      {hidePossibilitiesSection ? null : (
        <Section>
          <TitleText
            textAlign={{ xs: 'center' }}
            background={TitleTextBackground.RED_TO_PURPLE}
            fontSize={{
              xs: '30px',
              sm: '30px',
              md: '48px',
              lg: '48px',
              xl: '48px',
            }}
            fontWeight={700}
          >
            <TransparentText value={'Discover'} /> the possibilities.
          </TitleText>

          <RecordlessCardCarousel>
            <RecordlessCard image={<Sparkles />}>
              <TitleText
                textAlign={{
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'left',
                  xl: 'left',
                }}
                background={TitleTextBackground.RED_TO_PURPLE}
                fontSize={{
                  xs: '24px',
                  sm: '24px',
                  md: '35px',
                  lg: '30px',
                  xl: '40px',
                }}
              >
                Recordless <TransparentText value={'Refresh'} />
              </TitleText>

              <div>
                <div>
                  Built for labels and collectives that own catalogs of music,
                  Refresh easily allows you to release your music in a new way.
                </div>
                <div css={{ marginTop: '20px' }}>
                  The Recordless AI Engineer does the heavy lifting to give new
                  life to the music you own.
                </div>
                <div>
                  {hidePossibilitiesLearnMoreLink ? null : (
                    <a href={refreshPagePath} css={linkStyles}>
                      Learn more &#8594;
                    </a>
                  )}
                </div>
              </div>
            </RecordlessCard>

            <RecordlessCard image={<Trophy />}>
              <TitleText
                textAlign={{
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'left',
                  xl: 'left',
                }}
                background={TitleTextBackground.BLUE_TO_GREEN}
                fontSize={{
                  xs: '24px',
                  sm: '24px',
                  md: '35px',
                  lg: '30px',
                  xl: '40px',
                }}
              >
                Recordless <TransparentText value={'Contests'} />
              </TitleText>

              <div>
                <div>
                  Artists know that fans are the key to their success. And the
                  more in touch they are with the people that care about their
                  music, the better it gets.
                </div>
                <div css={{ marginTop: '20px' }}>
                  Contests help you release music with the help of your most
                  beloved resource: your audience.
                </div>
                <div>
                  {hidePossibilitiesLearnMoreLink ? null : (
                    <a href={contestPagePath} css={linkStyles}>
                      Learn more &#8594;
                    </a>
                  )}
                </div>
              </div>
            </RecordlessCard>
          </RecordlessCardCarousel>
        </Section>
      )}
    </Page>
  );
}
