/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ResponsiveValue, responsiveStyle } from '../constants/media-query';

export enum HeaderTextAlign {
  LEFT = 'left',
  CENTER = 'center',
}

export type HeaderTextProps = {
  value: string;
  fontSize: ResponsiveValue<string>;
  fontWeight: number;
  background: HeaderTextBackground;
  textAlign: HeaderTextAlign;
};
export enum HeaderTextBackground {
  GRADIENT_BLUE_TO_SKY = 'linear-gradient(264.97deg, #51FAC7 23.75%, #3680EF 76.23%), #FFFFFF',
  GRADIENT_RED_TO_PURPLE = 'linear-gradient(88.8deg, #F35858 13.52%, #740EF7 63.85%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(80.99deg, #F44F94 14.86%, #EAC712 62.64%), #FFFFFF',
  GRADIENT_GREEN_TO_BLUE = 'linear-gradient(262.88deg, #18CCF3 24.9%, #00FF47 55.55%), #FFFFFF',
  GRADIENT_PURPLE_TO_BLUE = 'linear-gradient(80.99deg, #BF4FF4 14.86%, #1282EA 62.64%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), #FFFFFF',
  SOLID_WHITE = 'white',
}

const styles = css({
  color: 'white',
  fontFamily:
    'DM Sans, Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  backgroundClip: 'text',
  textFillColor: 'transparent',
  width: '100%',
});

export function HeaderText({
  value,
  fontSize,
  fontWeight,
  background,
  textAlign,
}: HeaderTextProps) {
  return (
    <span
      css={css(
        { fontWeight, background, textAlign },
        styles,
        responsiveStyle('fontSize', fontSize),
      )}
    >
      {value}
    </span>
  );
}
