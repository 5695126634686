/** @jsxImportSource @emotion/react */

import { PropagateLoader } from 'react-spinners';
import { ButtonBackgroundColor } from './button';
import {
  HeaderText,
  HeaderTextAlign,
  HeaderTextBackground,
} from './header-text';
import { ParagraphText, ParagraphTextAlign } from './paragraph-text';

export type LoadingCardProps = {
  label: string;
  explanation: string;
};

export function LoadingCard(props: LoadingCardProps) {
  return (
    <div
      css={{
        display: 'grid',
        justifyContent: 'center',
        width: '100%',
        top: 'calc(50% - 170px)',
        position: 'absolute',
        alignSelf: 'center',
      }}
    >
      <HeaderText
        background={HeaderTextBackground.GRADIENT_RED_TO_PURPLE}
        textAlign={HeaderTextAlign.CENTER}
        fontSize={{
          xs: '32px',
          sm: '32px',
          md: '38px',
          lg: '38px',
          xl: '38px',
        }}
        fontWeight={700}
        value={props.label}
      />

      <ParagraphText
        textAlign={ParagraphTextAlign.CENTER}
        value={props.explanation}
      />

      <PropagateLoader
        color={ButtonBackgroundColor.PURPLE}
        css={{
          justifySelf: 'center',
          marginTop: '100px',
        }}
      />
    </div>
  );
}
