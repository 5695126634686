/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  HeaderText,
  HeaderTextAlign,
  HeaderTextBackground,
} from './header-text';

import { responsiveStyle } from '../constants/media-query';
import { Chat } from './icons/chat';
import { Stars } from './icons/stars';
import { UpwardsChart } from './icons/upwards-chart';
import { Wave } from './icons/wave';
import { ParagraphText, ParagraphTextAlign } from './paragraph-text';

export type MarketingBenefitCardProps = {
  icon: MarketingBenefitCardIcon;
  title: string;
  paragraph: string;
};

export enum MarketingBenefitCardIcon {
  UPWARD_CHART = 0,
  STARS = 1,
  CHAT = 2,
  WAVE = 3,
}

const styles = css(
  {
    display: 'grid',
    gridTemplateColumns: '100%',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.04) 100%)',
    backdropFilter: 'blur(30px)',
    boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.25)',
    boxSizing: 'border-box',
    borderRadius: '40px',
    border: '3px solid rgba(255, 255, 255, .07)',
    padding: '35px',
    margin: '20px',
  },
  responsiveStyle('width', {
    xs: '360px',
    sm: '450px',
  }),
  responsiveStyle('margin', {
    xs: '0px',
    sm: '20px',
  }),
  responsiveStyle('height', {
    xs: '275px',
    lg: '300px',
  }),
  responsiveStyle('gridTemplateRows', {
    xs: '70px 35px 1fr',
    lg: '70px 50px 1fr',
  }),
);

export function MarketingBenefitCard({
  icon,
  title,
  paragraph,
}: MarketingBenefitCardProps) {
  return (
    <div css={styles}>
      {icon === MarketingBenefitCardIcon.UPWARD_CHART ? (
        <UpwardsChart style={{}} />
      ) : icon === MarketingBenefitCardIcon.STARS ? (
        <Stars style={{}} />
      ) : icon === MarketingBenefitCardIcon.CHAT ? (
        <Chat style={{}} />
      ) : icon === MarketingBenefitCardIcon.WAVE ? (
        <Wave style={{}} />
      ) : null}
      <HeaderText
        value={title}
        background={HeaderTextBackground.SOLID_WHITE}
        fontSize={{ xs: '20px', lg: '24px' }}
        fontWeight={600}
        textAlign={HeaderTextAlign.LEFT}
      />
      <ParagraphText value={paragraph} textAlign={ParagraphTextAlign.LEFT} />
    </div>
  );
}
