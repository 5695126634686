/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type ChevronDownProps = {
  style: Interpolation<Theme>;
};

export function ChevronDown({ style }: ChevronDownProps) {
  return (
    <svg
      width="12"
      height="7"
      viewBox="0 0 12 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <path
        d="M6 6.80029C6.0804 6.80029 6.15658 6.78548 6.22852 6.75586C6.30469 6.72201 6.37028 6.67546 6.42529 6.61621L11.3384 1.58252C11.4526 1.46826 11.5098 1.33285 11.5098 1.17627C11.5098 1.06624 11.4844 0.966797 11.4336 0.87793C11.3828 0.789062 11.313 0.719238 11.2241 0.668457C11.1395 0.617676 11.0422 0.592285 10.9321 0.592285C10.7756 0.592285 10.638 0.647298 10.5195 0.757324L5.65723 5.72754H6.33643L1.47412 0.757324C1.35986 0.647298 1.22233 0.592285 1.06152 0.592285C0.951497 0.592285 0.852051 0.617676 0.763184 0.668457C0.678548 0.719238 0.61084 0.789062 0.560059 0.87793C0.509277 0.966797 0.483887 1.06624 0.483887 1.17627C0.483887 1.25667 0.498698 1.33285 0.52832 1.40479C0.557943 1.47249 0.60026 1.53385 0.655273 1.58887L5.56836 6.61621C5.69531 6.73893 5.83919 6.80029 6 6.80029Z"
        fill="white"
      />
    </svg>
  );
}
