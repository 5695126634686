// Thanks to https://coolors.co/ for these color schemes
export enum Color {
  // https://coolors.co/1be7ff-6eeb83-e4ff1a-ffb800-ff5714
  SPANISH_SKY_BLUE = '#1be7ff',
  VERY_LIGHT_MALACHITE_GREEN = '#6eeb83',
  ARCTIC_LIME = '#e4ff1a',
  SELECTIVE_YELLOW = '#ffb800',
  ORIOLES_ORANGE = '#ff5714',
  // https://coolors.co/e2c2c6-b9929f-9c528b-610f7f-2f0147
  DUST_STORM = '#e2c2c6',
  TUSCANY = '#b9929f',
  RAZZMIC_BERRY = '#9c528b',
  INDIGO = '#610f7f',
  RUSSIAN_VIOLET = '#2f0147',
  // https://coolors.co/ffb997-f67e7d-843b62-0b032d-74546a
  MACARONI_AND_CHEESE = '#ffb997',
  LIGHT_CORAL = '#f67e7d',
  DEEP_RUBY = '#843b62',
  MIDDLE_RED_PURPLE = '#0b032d',
  DEEP_TAUPE = '#74546a',
  YELLOW = 'rgba(255, 217, 144, 1)',
  RED = 'rgba(235, 64, 77, 1)',
  VIOLET = 'rgba(188, 147, 255, 1)',
  BG_BLACK = 'rgba(3, 1, 16, 1)',
  LIGHT_BG = 'rgba(25, 21, 43, 1)',
  BLUE = 'rgba(37, 90, 234, 1)',
  CARD_BG = 'rgba(15, 12, 31, 1)',
  RED_ALERT = 'rgba(233, 80, 91, 1)',
  APP_PURPLE = 'rgba(69, 18, 234, 1)',
  APP_LIGHT_PURPLE = 'rgba(111, 102, 240, 1)',
  APP_GREEN = 'rgba(113, 233, 147, 1)',
  WAVEFORM_GREEN = '#71E993',
  APP_GREY_80 = 'rgba(207, 206, 210, 1)',
  APP_GREY_30 = 'rgba(87, 85, 99, 1)',
  APP_BORDER_COLOR = 'rgba(255, 50, 50, 0.5)',
}
export namespace Color {
  const Bright: Readonly<
    [
      Color.SPANISH_SKY_BLUE,
      Color.VERY_LIGHT_MALACHITE_GREEN,
      Color.ARCTIC_LIME,
      Color.SELECTIVE_YELLOW,
      Color.ORIOLES_ORANGE,
    ]
  > = [
    Color.SPANISH_SKY_BLUE,
    Color.VERY_LIGHT_MALACHITE_GREEN,
    Color.ARCTIC_LIME,
    Color.SELECTIVE_YELLOW,
    Color.ORIOLES_ORANGE,
  ];
  const VioletDust: Readonly<
    [
      Color.DUST_STORM,
      Color.TUSCANY,
      Color.RAZZMIC_BERRY,
      Color.INDIGO,
      Color.RUSSIAN_VIOLET,
    ]
  > = [
    Color.DUST_STORM,
    Color.TUSCANY,
    Color.RAZZMIC_BERRY,
    Color.INDIGO,
    Color.RUSSIAN_VIOLET,
  ];
  const CoralTaupe: Readonly<
    [
      Color.MACARONI_AND_CHEESE,
      Color.LIGHT_CORAL,
      Color.DEEP_RUBY,
      Color.MIDDLE_RED_PURPLE,
      Color.DEEP_TAUPE,
    ]
  > = [
    Color.MACARONI_AND_CHEESE,
    Color.LIGHT_CORAL,
    Color.DEEP_RUBY,
    Color.MIDDLE_RED_PURPLE,
    Color.DEEP_TAUPE,
  ];
  export const Colors = {
    Bright,
    VioletDust,
    CoralTaupe,
  };
  export type Colors = (typeof Colors)[keyof typeof Colors];
}
