/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const testimonialCardStyles = css({
  width: '320px',
  backgroundColor: 'white',
  borderRadius: '8px',
  padding: '16px',
  color: 'black',
  border: '1px solid rgba(0, 0, 0, 0.5)',
  boxSizing: 'border-box',
});

const testimonialQuoteStyles = css({
  fontSize: '14px',
});

const testimonialUserStyles = css({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: '24px',
  marginTop: '14px',
  alignItems: 'center',
});

const testimonialAvatarStyles = css({
  display: 'block',
  height: '45px',
});

const testimonialNameStyles = css({
  padding: '0px 16px',
  fontSize: '14px',
});

const testimonialLocationStyles = css({
  padding: '0px 16px',
  fontSize: '12px',
  fontStyle: 'italic',
});

export type TestimonialCardProps = {
  name: string;
  location: string;
  avatarPath: string;
  quote: string;
};

export function TestimonialCard({
  name,
  location,
  avatarPath,
  quote,
}: TestimonialCardProps) {
  return (
    <div css={testimonialCardStyles}>
      <div css={testimonialQuoteStyles}>"{quote}"</div>

      <div css={testimonialUserStyles}>
        <img src={avatarPath} alt="avatar" css={testimonialAvatarStyles} />

        <div>
          <div css={testimonialNameStyles}>{name}</div>
          <div css={testimonialLocationStyles}>{location}</div>
        </div>
      </div>
    </div>
  );
}
