/** @jsxImportSource react */

import { Grid, Typography } from '@mui/material';

export default function LibraryHeader() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Library Management
        </Typography>
      </Grid>
    </Grid>
  );
}
