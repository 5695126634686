/** @jsxImportSource @emotion/react */

import { Start } from './icons/start';

export type IconProps = {
  name: string;
};

export function Icon({ name }: IconProps) {
  if (name === 'start') {
    return <Start />;
  }

  return null;
}
