/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { FormGroupWrapper } from './form-group-wrapper';

const styles = css({
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr min-content',
  gap: '12px',
  alignItems: 'center',
  padding: '24px 14px',
});

const intensitySelectorLabelStyles = css({
  fontWeight: '400',
  fontSize: '12px',
  color: '#FFFFFF',
});

export type RangeInputProps = {
  title: string;
  subtitle: string;
  name: string;
  min: number;
  max: number;
  value: number;
  onChange: (value: number) => void;
};

export function RangeInput({
  title,
  subtitle,
  name,
  min,
  max,
  value,
  onChange,
}: RangeInputProps) {
  const handleOnChange = (value: string) => onChange(Number(value));

  return (
    <FormGroupWrapper title={title} subtitle={subtitle}>
      <div css={styles}>
        <div css={intensitySelectorLabelStyles}>LESS</div>

        <input
          type="range"
          id={name}
          name={name}
          min={min}
          max={max}
          value={value}
          onChange={(e) => handleOnChange(e.target.value)}
        />

        <div css={intensitySelectorLabelStyles}>MORE</div>
      </div>
    </FormGroupWrapper>
  );
}
