/** @jsxImportSource react */

import { HookEffectContext } from '@inkibra/api-base';
import {
  InkibraRecordlessWorkoutIntervalType,
  useWorkout,
} from '@inkibra/recordless.tempo-api';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { Connector } from '../../common/lib/connector';

export default function WorkoutDisplay() {
  const { id } = useParams();

  if (id) {
    const connector = Connector.useContext();
    const hookEffectContext = new HookEffectContext();
    const workout = hookEffectContext.addEffect(useWorkout(id));
    hookEffectContext.runEffects(connector.cache);

    if (!workout) {
      return <div>Workout not found</div>;
    }

    const intervalsColumns: GridColDef<InkibraRecordlessWorkoutIntervalType>[] =
      [
        {
          field: 'id',
          headerName: 'ID',
          width: 100,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 150,
        },
        {
          field: 'time',
          headerName: 'Time (s)',
          width: 150,
        },
        {
          field: 'bpmLock',
          headerName: 'BPM Lock?',
          width: 150,
        },
        {
          field: 'intensity',
          headerName: 'Intensity',
          width: 150,
        },
        {
          field: 'comments',
          headerName: 'Comments',
          width: 250,
        },
      ];

    return (
      <div style={{ display: 'flex', height: '80vh' }}>
        <div style={{ flexGrow: 1 }}>
          {workout.name}
          <DataGrid
            rows={workout.intervals.map((interval, index) => ({
              id: index,
              ...interval,
            }))}
            columns={intervalsColumns}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
            }}
          />
        </div>
      </div>
    );
  }
  throw new ReferenceError('Workout Id not found in location params');
}
