/** @jsxImportSource @emotion/react */

export function Play() {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L14 9L0 18V0Z" fill="white" />
    </svg>
  );
}
