import { ErrorDescriptor } from '@inkibra/error-base';
import {
  Brand,
  CacheableObjectUtil,
  UNHANDLED_VALIDATION_FAILURE,
} from '@inkibra/observable-cache';
import {
  AnnotationStartType,
  MixAnnotationOutput,
  MixAnnotationType,
  MixAnnounceRequest,
  MixEndInstruction,
  MixOutputInfo,
  MixRequest,
  MixStartInstruction,
  MixTargetRequest,
} from '@inkibra/recordless.music-engine';
import { Result, err, ok } from 'neverthrow';
import typia from 'typia';
import { InkibraRecordlessLibraryArrangementType } from './type-arrangement';

export type InkibraRecordlessLibraryMixType = {
  id: Brand<typeof InkibraRecordlessLibraryMixType.typename>;
  name: string;
  computedMixOutputInfo?: MixOutputInfo;
  head: {
    mixTarget: MixTargetRequest;
  };
  tail: {
    mixTarget: MixTargetRequest;
  };
  orderedMixNodes: InkibraRecordlessLibraryMixType.MixElement[];
  type: typeof InkibraRecordlessLibraryMixType.typename;
};

export namespace InkibraRecordlessLibraryMixType {
  export const typename = 'nkrmix';

  export type MixElement = {
    id: Brand<'nkrmixel'>;
    bodyBeats: number;
    headOffsetBeats: number;
    mixTarget: MixTargetRequest;
    annotation: string;
    announcement?: string;
    currentLibraryArrangement: InkibraRecordlessLibraryArrangementType;
    type: 'nkrmixel';
  };

  export namespace MixElement {
    export const typename = 'nkrmixel';
    export enum RelativePlaybackTime {
      PAST = 0,
      CURRENT = 1,
      FUTURE = 2,
    }
  }

  export type Creation = Pick<InkibraRecordlessLibraryMixType, 'name'> &
    Partial<
      Pick<InkibraRecordlessLibraryMixType, 'head' | 'tail' | 'orderedMixNodes'>
    >;

  export type Modification = Partial<
    Pick<
      InkibraRecordlessLibraryMixType,
      'name' | 'head' | 'tail' | 'orderedMixNodes' | 'computedMixOutputInfo'
    >
  >;

  export type MixIntermixPlan = {
    mixStartInstruction: MixStartInstruction;
    mixRequests: MixRequest[];
    mixEndInstruction: MixEndInstruction;
  };

  export type MIX_VALIDATION_FAILURE = ErrorDescriptor<
    'MIX_VALIDATION_FAILURE',
    'Mix validation failed',
    {
      field: keyof InkibraRecordlessLibraryMixType;
    }
  >;

  export class MixUtil extends CacheableObjectUtil<
    typeof InkibraRecordlessLibraryMixType.typename,
    InkibraRecordlessLibraryMixType['id'],
    InkibraRecordlessLibraryMixType,
    InkibraRecordlessLibraryMixType.Creation,
    never,
    InkibraRecordlessLibraryMixType.Modification,
    MIX_VALIDATION_FAILURE | UNHANDLED_VALIDATION_FAILURE,
    {}
  > {
    private forceRecalculateMixOutputInfo = false;
    constructor(
      options: {
        forceRecalculateMixOutputInfo?: boolean;
      } = {},
    ) {
      super({});
      this.forceRecalculateMixOutputInfo =
        options.forceRecalculateMixOutputInfo ?? false;
    }
    public readonly type = typename;
    public static is = typia.createIs<InkibraRecordlessLibraryMixType>();
    public is(data: unknown): data is InkibraRecordlessLibraryMixType {
      return MixUtil.is(data);
    }
    protected getValidationErrors(
      _: InkibraRecordlessLibraryMixType,
    ): false | MIX_VALIDATION_FAILURE {
      return false;
    }
    protected fromCreateData(
      creation: Creation,
    ): Result<InkibraRecordlessLibraryMixType, never> {
      return ok({
        id: Brand.createId2<'nkrmix'>('nkrmix'),
        name: creation.name,
        head: creation.head || {
          mixTarget: {
            targetBpm: 100,
            targetDetune: 0,
            targetTailSeamBeats: 0,
          },
        },
        tail: creation.tail || {
          mixTarget: {
            targetBpm: 100,
            targetDetune: 0,
            targetTailSeamBeats: 0,
          },
        },
        orderedMixNodes: creation.orderedMixNodes || [],
        type: 'nkrmix',
      });
    }

    public setName(mix: InkibraRecordlessLibraryMixType, newName: string) {
      return this.modify(mix, {
        name: newName,
      });
    }

    public setHeadMixTargetRequest(
      mix: InkibraRecordlessLibraryMixType,
      newMixTarget: MixTargetRequest,
    ) {
      return this.modify(mix, {
        head: {
          mixTarget: newMixTarget,
        },
      });
    }

    public setTailMixTargetRequest(
      mix: InkibraRecordlessLibraryMixType,
      newMixTarget: MixTargetRequest,
    ) {
      return this.modify(mix, {
        tail: {
          mixTarget: newMixTarget,
        },
      });
    }

    public extendMixElementByTime(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
      extensionTime?: number,
    ) {
      const mixElement = mix.orderedMixNodes.find(
        (node) => node.id === mixElementId,
      );
      if (!mixElement) {
        return err('Expected mix element');
      }

      const maxBodyBeatsForArrangementWithMixTarget =
        this.getMaxBodyBeatsForArrangementWithMixTarget(
          mixElement.mixTarget,
          mixElement.currentLibraryArrangement,
          mixElement.headOffsetBeats,
        );
      if (maxBodyBeatsForArrangementWithMixTarget.isErr()) {
        return err(maxBodyBeatsForArrangementWithMixTarget.error);
      }
      if (!extensionTime) {
        return this.modifyMixElementNode(mix, mixElementId, {
          bodyBeats: mixElement.bodyBeats + 16,
        });
      }

      const extensionTimeInBars = Math.ceil(
        (extensionTime * mixElement.mixTarget.targetBpm) / 240,
      );
      const extentionTimeInBeats = extensionTimeInBars * 4;
      if (
        maxBodyBeatsForArrangementWithMixTarget.value <
        mixElement.bodyBeats + extentionTimeInBeats
      ) {
        return this.modifyMixElementNode(mix, mixElementId, {
          bodyBeats: maxBodyBeatsForArrangementWithMixTarget.value,
        });
      }
      console.log('extentionTimeInBeats', extentionTimeInBeats);
      return this.modifyMixElementNode(mix, mixElementId, {
        bodyBeats: mixElement.bodyBeats + extentionTimeInBeats,
      });
    }

    public getMaxBodyBeatsForArrangementWithMixTarget(
      mixTarget: MixTargetRequest,
      currentLibraryArrangement: InkibraRecordlessLibraryArrangementType,
      headOffsetBeats = 0,
    ) {
      const arrangementUtil =
        new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil(
          {},
        );
      const durationResult = arrangementUtil
        .getOrComputeArrangementInfo(currentLibraryArrangement)
        .andThen((info) => {
          return ok(info.duration);
        });
      if (durationResult.isErr()) {
        return err(durationResult.error);
      }
      const lengthOfArrangementInBars = Math.floor(
        (durationResult.value * mixTarget.targetBpm) / 240,
      );
      const lengthOfArrangementInBeats = lengthOfArrangementInBars * 4;
      return ok(
        lengthOfArrangementInBeats -
          mixTarget.targetTailSeamBeats -
          headOffsetBeats,
      );
    }

    /*
      we want a function that says:
      take the current mix,
      and this arrangement,
      try to plan the arrangement, so that some section of the arrangement
      starts at the body (adjusting the headOffsetBeats and mixTarget as much as possible)
      and try to plan the arrangement for this amount of time
    */
    public alignArrangementToMix(
      mix: InkibraRecordlessLibraryMixType,
      currentLibraryArrangement: InkibraRecordlessLibraryArrangementType,
      targetBpm: number,
      headSeamBeats: number,
      alignedArrangementBodyStartTime: number,
      options: {
        annotation?: string;
        announcement?: string;
        bodyBeats?: number;
        headOffsetBeats?: number;
      },
    ) {
      console.log(
        'this.forceRecalculateMixOutputInfo',
        this.forceRecalculateMixOutputInfo,
      );
      let mixElementAtAlignedMixTime = this.getNodePlayingAtTime(
        mix,
        alignedArrangementBodyStartTime,
        true,
      );
      if (!mixElementAtAlignedMixTime) {
        const lastMixElement = mix.orderedMixNodes.at(-1);
        if (!lastMixElement) {
          console.warn('Expected a last mix element');
          return err('Expected mix element at aligned mix time');
        }
        const mixWithExtendedElement = this.extendMixElementByTime(
          mix,
          lastMixElement.id,
        );
        if (mixWithExtendedElement.isErr()) {
          console.warn('mixWithExtendedElement', mixWithExtendedElement.error);
          return err(mixWithExtendedElement.error);
        }
        mix = mixWithExtendedElement.value;
        mixElementAtAlignedMixTime = this.getNodePlayingAtTime(
          mix,
          alignedArrangementBodyStartTime,
          true,
        );
        if (!mixElementAtAlignedMixTime) {
          console.warn(
            'Expected mix element at aligned mix time, moving aligned mix time',
            mix,
          );
          // What is the last time we can plan this for?
          const timeDelta =
            this.convertPlaybackTimeToNodeBodyRelativeTimeRemaining(
              mix,
              lastMixElement.id,
              alignedArrangementBodyStartTime,
            );
          if (timeDelta.isErr()) {
            console.warn('Expected time delta', timeDelta.error);
            return err(timeDelta.error);
          }
          alignedArrangementBodyStartTime =
            alignedArrangementBodyStartTime - timeDelta.value;
          mixElementAtAlignedMixTime = lastMixElement;
        }
        console.log('extended mix to align it', mix);
      }
      const mixElementBodyTimeAtAlignedMixTime =
        this.convertPlaybackTimeToNodeBodyRelativeTimeElapsed(
          mix,
          mixElementAtAlignedMixTime.id,
          alignedArrangementBodyStartTime,
        );
      if (mixElementBodyTimeAtAlignedMixTime.isErr()) {
        console.warn('Expected mix element body time at aligned mix time');
        return err(mixElementBodyTimeAtAlignedMixTime.error);
      }

      const averageTransitionBpm =
        (mixElementAtAlignedMixTime.mixTarget.targetBpm + targetBpm) / 2;
      const transitionDuration = (headSeamBeats * 60) / averageTransitionBpm;

      const newBodyTimeForCurrentMixElement =
        mixElementBodyTimeAtAlignedMixTime.value - transitionDuration;
      const newBodyBarsFromCurrentMixElement = Math.floor(
        (newBodyTimeForCurrentMixElement * targetBpm) / 240,
      );
      const newBodyBeatsFromCurrentMixElement =
        newBodyBarsFromCurrentMixElement * 4;

      // Modify the current mix element in the mix
      // TODO: get rid of mix elements after the current one, if it exists
      const modifiedMix = this.modifyMixElementNode(
        mix,
        mixElementAtAlignedMixTime.id,
        {
          bodyBeats: newBodyBeatsFromCurrentMixElement,
          mixTarget: {
            ...mixElementAtAlignedMixTime.mixTarget,
            targetTailSeamBeats: headSeamBeats,
          },
        },
      );
      if (modifiedMix.isErr()) {
        console.warn('modifiedMix', modifiedMix.error);
        return err(modifiedMix.error);
      }
      console.log('modifiedMix for aligned mix', modifiedMix);
      const newMix = this.createAndAppendNode(
        mix,
        {
          targetBpm: targetBpm,
          targetDetune: 0,
          targetTailSeamBeats: 16,
        },
        currentLibraryArrangement,
        {
          annotation: options.annotation,
          announcement: options.announcement,
          headOffsetBeats: options.headOffsetBeats,
          bodyBeats: options.bodyBeats,
        },
      );
      if (newMix.isErr()) {
        console.warn('newMix error for aligned mix', newMix.error);
        return err(newMix.error);
      }
      return ok(newMix.value);
    }

    public createAndAppendNode(
      mix: InkibraRecordlessLibraryMixType,
      mixTarget: MixTargetRequest,
      currentLibraryArrangement: InkibraRecordlessLibraryArrangementType,
      options: {
        annotation?: string;
        announcement?: string;
        bodyBeats?: number;
        headOffsetBeats?: number;
      },
    ) {
      const headOffsetBeatsToUse = options.headOffsetBeats ?? 0;
      const maxBodyBeatsForArrangementWithMixTargetResult =
        this.getMaxBodyBeatsForArrangementWithMixTarget(
          mixTarget,
          currentLibraryArrangement,
          headOffsetBeatsToUse,
        );
      if (maxBodyBeatsForArrangementWithMixTargetResult.isErr()) {
        return err(maxBodyBeatsForArrangementWithMixTargetResult.error);
      }
      const maxBodyBeatsForArrangementWithMixTarget =
        maxBodyBeatsForArrangementWithMixTargetResult.value;
      const bodyBeatsToUse = options.bodyBeats
        ? Math.min(options.bodyBeats, maxBodyBeatsForArrangementWithMixTarget)
        : maxBodyBeatsForArrangementWithMixTarget;

      const newNode: MixElement = {
        id: Brand.createId2<'nkrmixel'>('nkrmixel'),
        mixTarget,
        headOffsetBeats: headOffsetBeatsToUse,
        currentLibraryArrangement,
        annotation: options.annotation ?? '',
        announcement: options.announcement,
        bodyBeats: bodyBeatsToUse,
        type: 'nkrmixel',
      };

      return this.modify(mix, {
        orderedMixNodes: mix.orderedMixNodes.concat(newNode),
      });
    }

    public getOrderedMixNodesWithAnnotation(
      mix: InkibraRecordlessLibraryMixType,
      annotation: string,
    ) {
      return mix.orderedMixNodes.filter(
        (node) => node.annotation === annotation,
      );
    }

    public swapMixNodes(
      mix: InkibraRecordlessLibraryMixType,
      indexA: number,
      indexB: number,
    ) {
      const orderedMixNodes = mix.orderedMixNodes.slice();
      const oldA = orderedMixNodes[indexA];
      const oldB = orderedMixNodes[indexB];
      if (!oldA || !oldB) {
        throw new Error('Expected oldA and oldB');
      }
      orderedMixNodes[indexA] = oldB;
      orderedMixNodes[indexB] = oldA;

      return this.modify(mix, {
        orderedMixNodes,
      });
    }

    public storeComputedMixOutputInfo(
      mix: InkibraRecordlessLibraryMixType,
      computedMixOutputInfo: MixOutputInfo,
    ) {
      return this.modify(mix, {
        computedMixOutputInfo,
      });
    }

    public getBestMixOutputInfo(
      mix: InkibraRecordlessLibraryMixType,
      forceRecalculate = this.forceRecalculateMixOutputInfo,
    ) {
      if (mix.computedMixOutputInfo && !forceRecalculate) {
        return mix.computedMixOutputInfo;
      }

      const mixNodes = mix.orderedMixNodes;
      let currentTime = 0;
      let previousMixTarget = mix.head.mixTarget;

      const collectedAnnotations = mixNodes.flatMap((mixNode, index) => {
        const annotations: MixAnnotationOutput[] = [];
        const previousMixNode = mixNodes.at(index - 1);
        if (previousMixNode) {
          annotations.push({
            mixElementId: previousMixNode.id,
            annotationType: MixAnnotationType.IntermixEnd,
            startsAt: currentTime,
          });
        }
        const intermixStartOutputInfo = {
          mixElementId: mixNode.id,
          annotationType: MixAnnotationType.IntermixStart,
          startsAt: currentTime,
        };
        annotations.push(intermixStartOutputInfo);

        // Calculate the body start time based on the previous mix target
        const averageHeadBpm =
          (previousMixTarget.targetBpm + mixNode.mixTarget.targetBpm) / 2;
        const headDuration =
          (previousMixTarget.targetTailSeamBeats * 60) / averageHeadBpm;

        currentTime += headDuration;

        const bodyStartOutputInfo = {
          mixElementId: mixNode.id,
          annotationType: MixAnnotationType.BodyStart,
          startsAt: currentTime,
        };
        annotations.push(bodyStartOutputInfo);
        // Calculate the body end time based on the current mix target
        const bodyDuration =
          (mixNode.bodyBeats * 60) / mixNode.mixTarget.targetBpm;
        currentTime += bodyDuration;

        const bodyEndOutputInfo = {
          mixElementId: mixNode.id,
          annotationType: MixAnnotationType.BodyEnd,
          startsAt: currentTime,
        };
        annotations.push(bodyEndOutputInfo);
        // Set the previous mix target to the current mix target
        previousMixTarget = mixNode.mixTarget;

        return annotations;
      });

      // Calculate the final duration based on the previous mix target and the tail target
      const averageTailBpm =
        (previousMixTarget.targetBpm + mix.tail.mixTarget.targetBpm) / 2;
      const tailDuration =
        (mix.tail.mixTarget.targetTailSeamBeats * 60) / averageTailBpm;
      currentTime += tailDuration;

      return {
        annotations: collectedAnnotations,
        duration: currentTime,
      };
    }

    public getMixAnnotationsForNode(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
    ) {
      return this.getBestMixOutputInfo(mix).annotations.filter(
        (annotation) => annotation.mixElementId === mixElementId,
      );
    }

    /**
     * Converts the playback time to the relative time elapsed in the body of the mix
     * @param playbackTime The playback time of the mix
     */
    public convertPlaybackTimeToNodeBodyRelativeTimeElapsed(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
      playbackTime: number,
    ) {
      const annotations = this.getMixAnnotationsForNode(mix, mixElementId);
      const bodyStartAnnotation = annotations.find(
        (annotation) =>
          annotation.annotationType === MixAnnotationType.BodyStart,
      );
      if (!bodyStartAnnotation) {
        return err('Expected body start annotation');
      }
      return ok(playbackTime - bodyStartAnnotation.startsAt);
    }

    /**
     * Converts the playback time to the relative time remaining in the body of the mix
     * @param playbackTime The playback time of the mix
     */
    public convertPlaybackTimeToNodeBodyRelativeTimeRemaining(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
      playbackTime: number,
    ) {
      const annotations = this.getMixAnnotationsForNode(mix, mixElementId);
      const bodyEndAnnotation = annotations.find(
        (annotation) => annotation.annotationType === MixAnnotationType.BodyEnd,
      );
      if (!bodyEndAnnotation) {
        return err('Expected body end annotation');
      }
      return ok(bodyEndAnnotation.startsAt - playbackTime);
    }

    public getNodeStartAndEndTime(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
    ) {
      const annotations = this.getMixAnnotationsForNode(mix, mixElementId);
      const bodyStartAnnotation = annotations.find(
        (annotation) =>
          annotation.annotationType === MixAnnotationType.BodyStart,
      );
      const bodyEndAnnotation = annotations.find(
        (annotation) => annotation.annotationType === MixAnnotationType.BodyEnd,
      );
      if (!bodyStartAnnotation || !bodyEndAnnotation) {
        return err('Expected first and last annotation');
      }
      return ok({
        start: bodyStartAnnotation.startsAt,
        end: bodyEndAnnotation.startsAt,
      });
    }

    public getNodeRelativePlaybackPosition(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
      playbackTime: number,
      includeIntermixPeriod = false,
    ) {
      const startAnnotationType = includeIntermixPeriod
        ? MixAnnotationType.IntermixStart
        : MixAnnotationType.BodyStart;
      const endAnnotationType = includeIntermixPeriod
        ? MixAnnotationType.IntermixEnd
        : MixAnnotationType.BodyEnd;

      const annotations = this.getMixAnnotationsForNode(mix, mixElementId);
      const startAnnotation = annotations.find(
        (annotation) => annotation.annotationType === startAnnotationType,
      );
      const endAnnotation = annotations.find(
        (annotation) => annotation.annotationType === endAnnotationType,
      );
      if (!startAnnotation || !endAnnotation) {
        return err('Expected first and last annotation');
      }
      if (playbackTime < startAnnotation.startsAt) {
        return ok(
          InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
            .FUTURE,
        );
      }
      if (playbackTime > endAnnotation.startsAt) {
        return ok(
          InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.PAST,
        );
      }
      return ok(
        InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.CURRENT,
      );
    }

    public modifyMixElementNode(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
      modifications: Partial<
        Pick<
          InkibraRecordlessLibraryMixType.MixElement,
          | 'mixTarget'
          | 'bodyBeats'
          | 'headOffsetBeats'
          | 'annotation'
          | 'announcement'
          | 'currentLibraryArrangement'
        >
      >,
    ) {
      const mixNodes = mix.orderedMixNodes;
      const mixNode = mixNodes.find((node) => node.id === mixElementId);
      if (!mixNode) {
        return err('Expected mix node');
      }
      return this.modify(mix, {
        orderedMixNodes: mixNodes.map((node) => {
          if (node.id === mixElementId) {
            return {
              ...node,
              ...modifications,
            };
          }
          return node;
        }),
      });
    }

    public calculateMixElementNodeHeadBeats(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
    ) {
      const mixNodes = mix.orderedMixNodes;
      const mixNode = mixNodes.find((node) => node.id === mixElementId);
      if (!mixNode) {
        return err('Expected mix node');
      }
      const indexOfNode = mixNodes.indexOf(mixNode);
      if (indexOfNode === 0) {
        return ok(mix.head.mixTarget.targetTailSeamBeats);
      }
      const previousNode = mixNodes[indexOfNode - 1];
      if (!previousNode) {
        return err('Expected previous node');
      }
      return ok(previousNode.bodyBeats);
    }

    public calculateBodyDuration(
      mix: InkibraRecordlessLibraryMixType,
      mixElementId: InkibraRecordlessLibraryMixType.MixElement['id'],
    ) {
      const mixNodes = mix.orderedMixNodes;
      const mixNode = mixNodes.find((node) => node.id === mixElementId);
      if (!mixNode) {
        return err('Expected mix node');
      }
      return ok((mixNode.bodyBeats * 60) / mixNode.mixTarget.targetBpm);
    }

    public getNodePlayingAtTime(
      mix: InkibraRecordlessLibraryMixType,
      time: number,
      includeIntermixPeriod = false,
    ) {
      const mixNodes = mix.orderedMixNodes;
      for (const mixNode of mixNodes) {
        const relativePlaybackPosition = this.getNodeRelativePlaybackPosition(
          mix,
          mixNode.id,
          time,
          includeIntermixPeriod,
        );
        if (
          relativePlaybackPosition.isOk() &&
          relativePlaybackPosition.value ===
            InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
              .CURRENT
        ) {
          return mixNode;
        }
      }
      return undefined;
    }

    /*
     * Before using this, you should `collectArrangementsFromHeadToLeaf()` and used the returned arrangements
     * to `createBackendArrangementInstruction()` via the `InkibraRecordlessLibraryArrangementType` manager.
     * The file paths of those arrangements will be used here to create the backend mix instruction.
     */
    public async getIntermixPlan(
      mix: InkibraRecordlessLibraryMixType,
      arrangementPathResolver: InkibraRecordlessLibraryArrangementType.ArrangementPathResolver,
      options?: {
        startWithNodeAtTime: number;
        endWithNodeAtTime: number;
      },
    ) {
      // In case the mix output info is already cached, we need to recalculate it
      mix.computedMixOutputInfo = undefined;
      const arrangementUtil =
        new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil(
          {},
        );

      const mixNodes = mix.orderedMixNodes.filter((node) => {
        if (!options) {
          return true;
        }

        const nodeStartAndEndResult = this.getNodeStartAndEndTime(mix, node.id);
        if (nodeStartAndEndResult.isErr()) {
          return false;
        }

        const nodeStart = nodeStartAndEndResult.value.start;
        const nodeEnd = nodeStartAndEndResult.value.end;

        return (
          nodeStart < options.endWithNodeAtTime &&
          nodeEnd > options.startWithNodeAtTime
        );
      });
      const mixRequestsResults = await Promise.all(
        mixNodes.map(async (mixElement) => {
          const sourceBpm =
            mixElement.currentLibraryArrangement.librarySong.bpm;
          const arrangementPathResult = await arrangementPathResolver(
            mixElement.currentLibraryArrangement,
          );

          if (arrangementPathResult.isErr()) {
            return err(arrangementPathResult.error);
          }

          const mixRequest: MixRequest = {
            targetRequest: mixElement.mixTarget,
            gridAlignmentOffset: arrangementUtil.getGridStartTime(
              mixElement.currentLibraryArrangement,
            ),
            headStartOffsetBeats: mixElement.headOffsetBeats,
            bodyBeats: mixElement.bodyBeats,
            sourceBpm,
            mixElementId: mixElement.id,
            audioBufferPath: arrangementPathResult.value,
          };

          return ok(mixRequest);
        }),
      );

      const mixRequestsErrors = mixRequestsResults.filter((result) =>
        result.isErr(),
      );

      if (mixRequestsErrors.length > 0) {
        return err(mixRequestsErrors.map((error) => error.error));
      }

      const mixStartInstruction: MixStartInstruction = {
        targetRequest: mix.head.mixTarget,
      };

      const mixEndInstruction: MixEndInstruction = {
        targetRequest: mix.tail.mixTarget,
      };

      const intermixPlan: MixIntermixPlan = {
        mixStartInstruction,
        mixRequests: mixRequestsResults
          .filter((result) => result.isOk())
          .map((result) => result.value),
        mixEndInstruction,
      };

      return ok(intermixPlan);
    }

    public async getAnnouncementRequests(
      mix: InkibraRecordlessLibraryMixType,
      getAnnouncementBufferPath: (
        announcement: string,
      ) => Promise<Result<string, false>>,
    ): Promise<MixAnnounceRequest[]> {
      const mixNodesWithAnnouncements = mix.orderedMixNodes.filter(
        (node) => node.announcement !== undefined,
      );

      const mixNodesWithAnnouncementsResults = await Promise.all(
        mixNodesWithAnnouncements.map(async (mixNode) => {
          if (mixNode.announcement === undefined) {
            return ok(undefined);
          }
          const announcementBufferPathResult = await getAnnouncementBufferPath(
            mixNode.announcement,
          );

          if (announcementBufferPathResult.isErr()) {
            return err(announcementBufferPathResult.error);
          }

          return ok({
            matchMixElementId: mixNode.id,
            audioBufferPath: announcementBufferPathResult.value,
            startType: AnnotationStartType.MixElementStart,
          });
        }),
      );

      const announcementRequests = mixNodesWithAnnouncementsResults
        .filter((result) => result.isOk() && result.value !== undefined)
        .map((result) => result.value);

      return announcementRequests;
    }

    public getMixSwapInfo(
      currentMix?: InkibraRecordlessLibraryMixType,
      swappedMix?: InkibraRecordlessLibraryMixType,
    ) {
      if (
        currentMix?.computedMixOutputInfo === undefined ||
        swappedMix?.computedMixOutputInfo === undefined
      ) {
        return err('Mixes must be computed before getting swap info');
      }

      // Find the common mix annotations elements between the two mixes
      const elementIdsInCurrentMix =
        currentMix.computedMixOutputInfo.annotations.map((annotation) => {
          return annotation.mixElementId;
        });

      const commonElementIds = Array.from(
        new Set(
          swappedMix.computedMixOutputInfo.annotations
            .filter((annotation) => {
              return elementIdsInCurrentMix.includes(annotation.mixElementId);
            })
            .map((annotation) => {
              return annotation.mixElementId;
            }),
        ),
      );

      const firstCommonElementId = commonElementIds.at(0);
      const lastCommonElementId = commonElementIds.at(-1);

      if (!firstCommonElementId || !lastCommonElementId) {
        return err('Expected common element ids');
      }
      console.debug('elementIdsInCurrentMix', elementIdsInCurrentMix);
      console.debug('commonElementIds', commonElementIds);
      console.debug('firstCommonElementId', firstCommonElementId);
      console.debug('lastCommonElementId', lastCommonElementId);

      const bodyStartAnnotationOfFirstCommonElementInCurrentMix =
        this.getMixAnnotationsForNode(currentMix, firstCommonElementId).find(
          (annotation) => {
            return annotation.annotationType === MixAnnotationType.BodyStart;
          },
        );

      const bodyStartAnnotationOfFirstCommonElementInSwappedMix =
        this.getMixAnnotationsForNode(swappedMix, firstCommonElementId).find(
          (annotation) => {
            return annotation.annotationType === MixAnnotationType.BodyStart;
          },
        );

      const bodyEndAnnotationOfLastCommonElementInCurrentMix =
        this.getMixAnnotationsForNode(currentMix, lastCommonElementId).find(
          (annotation) => {
            return annotation.annotationType === MixAnnotationType.BodyEnd;
          },
        );

      const bodyEndAnnotationOfLastCommonElementInSwappedMix =
        this.getMixAnnotationsForNode(swappedMix, lastCommonElementId).find(
          (annotation) => {
            return annotation.annotationType === MixAnnotationType.BodyEnd;
          },
        );

      if (
        !bodyStartAnnotationOfFirstCommonElementInCurrentMix ||
        !bodyStartAnnotationOfFirstCommonElementInSwappedMix ||
        !bodyEndAnnotationOfLastCommonElementInCurrentMix ||
        !bodyEndAnnotationOfLastCommonElementInSwappedMix
      ) {
        return err('Expected body start and end annotations');
      }

      return ok({
        alignedTimeInNewSource:
          bodyStartAnnotationOfFirstCommonElementInSwappedMix.startsAt,
        lastTimeInNewSource:
          bodyEndAnnotationOfLastCommonElementInSwappedMix.startsAt,
        alignedTimeInCurrentSource:
          bodyStartAnnotationOfFirstCommonElementInCurrentMix.startsAt,
        lastTimeInCurrentSource:
          bodyEndAnnotationOfLastCommonElementInCurrentMix.startsAt,
      });
    }
  }
}
