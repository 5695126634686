/** @jsxImportSource @emotion/react */

export function ExclamationBubble() {
  return (
    <svg
      width="225"
      height="216"
      viewBox="0 0 225 216"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2004_1974)" filter="url(#filter0_i_2004_1974)">
        <path
          d="M61.7469 216C66.6666 216 70.0803 213.444 76.2047 208.134L110.843 177.952H175.301C205.22 177.952 221.284 161.73 221.284 132.923V57.6131C221.284 28.8066 205.22 12.5845 175.301 12.5845H45.9839C16.0642 12.5845 0 28.7082 0 57.6131V132.923C0 161.828 16.0642 177.952 45.9839 177.952H50.8032V203.513C50.8032 211.084 54.7188 216 61.7469 216ZM65.8633 198.008V169.496C65.8633 164.187 63.7549 162.123 58.3333 162.123H45.9839C25.7028 162.123 16.1647 151.997 16.1647 132.825V57.6131C16.1647 38.4415 25.7028 28.4134 45.9839 28.4134H175.301C195.482 28.4134 205.12 38.4415 205.12 57.6131V132.825C205.12 151.997 195.482 162.123 175.301 162.123H110.241C104.619 162.123 101.807 162.909 97.9918 166.744L65.8633 198.008Z"
          fill="#08031D"
        />
        <path
          d="M110.642 112.965C115.462 112.965 118.273 110.31 118.374 105.198L119.779 53.2872C119.88 48.3714 115.863 44.5371 110.542 44.5371C105.12 44.5371 101.305 48.2731 101.405 53.1888L102.711 105.198C102.811 110.212 105.622 112.965 110.642 112.965ZM110.642 144.917C116.465 144.917 121.486 140.494 121.486 134.693C121.486 128.99 116.566 124.566 110.642 124.566C104.718 124.566 99.799 129.089 99.799 134.693C99.799 140.395 104.819 144.917 110.642 144.917Z"
          fill="#08031D"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2004_1974"
          x="0"
          y="0"
          width="238"
          height="235"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="13" dy="19" />
          <feGaussianBlur stdDeviation="11.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2004_1974"
          />
        </filter>
        <clipPath id="clip0_2004_1974">
          <rect width="225" height="216" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
