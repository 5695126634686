import * as Logger from 'bunyan';

function applyLoggerProxy(logger: Logger) {
  const handler = {
    // biome-ignore lint/suspicious/noExplicitAny: TODO: see if we can fix this
    apply: (target: any, thisArg: any, args: any[]) => {
      if (
        args !== undefined &&
        args[0] !== undefined &&
        typeof args[0] === 'string'
      ) {
        let shouldMutate = true;
        if (args[0].includes('%s')) {
          shouldMutate = false;
        } else if (args[0].includes('%d')) {
          shouldMutate = false;
        } else if (args[0].includes('%i')) {
          shouldMutate = false;
        } else if (args[0].includes('%f')) {
          shouldMutate = false;
        } else if (args[0].includes('%j')) {
          shouldMutate = false;
        } else if (args[0].includes('%o')) {
          shouldMutate = false;
        } else if (args[0].includes('%O')) {
          shouldMutate = false;
        }
        if (
          shouldMutate &&
          args[1] !== undefined &&
          typeof args[1] === 'object'
        ) {
          const temp = args.shift();
          args.push(temp);
        }
      }
      Reflect.apply(target, thisArg, args);
      return;
    },
  };
  logger.info = new Proxy(logger.info, handler);
  logger.debug = new Proxy(logger.debug, handler);
  logger.trace = new Proxy(logger.trace, handler);
  logger.fatal = new Proxy(logger.fatal, handler);
  logger.error = new Proxy(logger.error, handler);
  logger.warn = new Proxy(logger.warn, handler);
  logger.child = new Proxy(logger.child, {
    // biome-ignore lint/suspicious/noExplicitAny: TODO: see if we can fix this
    apply: (target: any, thisArg: any, args: any[]) => {
      const child = Reflect.apply(target, thisArg, args);
      return applyLoggerProxy(child as Logger);
    },
  });
  return logger;
}

export function init(
  service: string,
  logLevel: 'info' | 'debug' | 'trace' = 'debug',
) {
  if (process.title === 'node') {
    const logger: Logger = Logger.createLogger({
      name: service,
      serializers: Logger.stdSerializers,
      streams: [
        {
          level: logLevel,
          stream: process.stdout,
        },
        {
          level: 'error',
          stream: process.stderr,
        },
      ],
    });
    return applyLoggerProxy(logger);
  }
  const logger: Logger = Logger.createLogger({
    name: service,
    serializers: Logger.stdSerializers,
    streams: [
      {
        level: logLevel,
        type: 'raw',
        stream: {
          write: (rec: Object) => {
            const message = (rec as { msg: string }).msg;
            const time = (rec as { time: string }).time;
            const level =
              Logger.nameFromLevel[(rec as { level: number }).level];
            if (level === 'info') {
              console.log(`[${time}] ${message}`, rec);
              return;
            }
            if (level === 'debug') {
              console.debug(`[${time}] ${message}`, rec);
              return;
            }
            if (level === 'trace') {
              console.trace(`[${time}] ${message}`, rec);
              return;
            }
            if (level === 'fatal') {
              console.error(`FATAL: [${time}] ${message}`, rec);
              return;
            }
            if (level === 'error') {
              console.error(`[${time}] ${message}`, rec);
              return;
            }
            if (level === 'warn') {
              console.warn(`[${time}] ${message}`, rec);
              return;
            }
          },
        },
      },
      {
        level: 'error',
        type: 'raw',
        stream: {
          write: (rec: Object) => {
            const message = (rec as { msg: string }).msg;
            const time = (rec as { time: string }).time;
            const level =
              Logger.nameFromLevel[(rec as { level: number }).level];
            if (level === 'error') {
              console.error(`[${time}] ${message}`, rec);
              return;
            }
            if (level === 'fatal') {
              console.error(`FATAL: [${time}] ${message}`, rec);
              return;
            }
          },
        },
      },
    ],
  });
  return applyLoggerProxy(logger);
}

export default init;
export { Logger as Bunyan };
export { Logger };
