/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Button, ButtonBackgroundColor } from './button';

const calloutCardStyles = css({
  display: 'grid',
  gridTemplateRows: '1fr 50px',
  gridTemplateColumns: '150px 1fr',
  gridTemplateAreas: `
    "headline headline"
    "button blank"
  `,
  backgroundImage:
    "url('https://cdn.recordless.app/common-assets/callout-card-background.png')",
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '350px 160px',
  width: '350px',
  height: '160px',
  boxSizing: 'border-box',
  padding: '20px',
});

const headlineTextStyles = css({
  fontFamily: "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '34px',
  letterSpacing: '1.25px',
  color: 'white',
  userSelect: 'none',
  pointerEvents: 'none',
  margin: 0,
  gridArea: 'headline',
});

export type CalloutCardProps = {
  headline: string;
  buttonText: string;
  onClick: () => void;
};

export function CalloutCard(props: CalloutCardProps) {
  return (
    <div css={calloutCardStyles}>
      <h2 css={headlineTextStyles}>{props.headline}</h2>
      <Button
        gridArea="button"
        color="#4C4BDA"
        contents={props.buttonText}
        onClick={props.onClick}
        buttonBackgroundColor={ButtonBackgroundColor.WHITE}
      />
    </div>
  );
}
