/** @jsxImportSource react */

import { RefObject, createContext, useRef } from 'react';
import {
  AudioDataState,
  InkibraRecordlessLibraryClientPlayerElement,
  LibraryPlayerController,
  PlaybackState,
} from './client-player-element';

export type { AudioDataState, PlaybackState };

// TODO: potentially make this not a provider and instead an optional
export const PlaybackSessionContext = createContext<
  () => RefObject<LibraryPlayerController<undefined>>
>(() => {
  throw new Error('PlaybackSessionContext not setup');
});

export type PlaybackSessionGuardProps = {
  guardedElement: JSX.Element;
};

export function PlaybackSessionGuard(props: PlaybackSessionGuardProps) {
  const clientPlayerRef = useRef<LibraryPlayerController<undefined>>(null);
  return (
    <PlaybackSessionContext.Provider value={() => clientPlayerRef}>
      <InkibraRecordlessLibraryClientPlayerElement ref={clientPlayerRef} />
      {props.guardedElement}
    </PlaybackSessionContext.Provider>
  );
}
