import { Reference } from '..';
import { CacheableObject } from './cacheable-object';

/**
 * Type for typed collection related functions and types.
 */
export type TypedCollection<T extends CacheableObject> = {
  id: string;
  data: {
    [key: string]: T;
  };
  containedType: T['type'];
  type: `COLLECTION(${T['type']})`;
};

/**
 * Namespace for typed collection related functions and types.
 * @deprecated
 */
export namespace TypedCollection {
  /**
   * Represents a reference to an item in a typed collection.
   * @deprecated
   */
  export type ItemReference<T extends TypedCollection<CacheableObject>> = {
    referencedId: string;
    referencedType: T['data'][string]['type'];
    referencedCollectionId: string;
    referencedCollectionType: T['type'];
    type: `COLLECTION-REF(${T['data'][string]['type']})`;
  };

  /**
   * @deprecated
   * A function for upgrading a reference to a collection reference.
   * Will be removed in the future.
   * TODO: remove after `Reference` is removed.
   */
  export namespace ItemReference {
    export function upgradeFromReferenceWithCollectionType<
      T extends TypedCollection<CacheableObject>,
    >(ref: Reference<CacheableObject>, collection: T): ItemReference<T> {
      return {
        referencedId: ref.id,
        referencedType: collection.containedType,
        referencedCollectionId: collection.id,
        referencedCollectionType: collection.type,
        type: `COLLECTION-REF(${collection.containedType})`,
      } satisfies ItemReference<T>;
    }

    /**
     * Creates a new item reference for the specified collection and item.
     * @param collection - The collection containing the item.
     * @param item - The item to create a reference for.
     * @returns The created item reference.
     */
    export function makeRef<T extends TypedCollection<CacheableObject>>(
      collection: T,
      item: Pick<T['data'][string], 'id' | 'type'>,
    ): ItemReference<T> {
      return {
        referencedId: item.id,
        referencedType: item.type,
        referencedCollectionId: collection.id,
        referencedCollectionType: collection.type,
        type: `COLLECTION-REF(${item.type})`,
      };
    }

    /**
     * Checks if the given reference is equal to the specified reference.
     */
    export function refsMatch<T extends TypedCollection<CacheableObject>>(
      ref1: ItemReference<T>,
      ref2: ItemReference<T>,
    ): boolean {
      return (
        ref1.referencedCollectionId === ref2.referencedCollectionId &&
        ref1.referencedCollectionType === ref2.referencedCollectionType &&
        ref1.referencedId === ref2.referencedId &&
        ref1.referencedType === ref2.referencedType
      );
    }
  }

  /**
   * Checks if the given collection has the referenced item.
   * @param collection - The collection to check.
   * @param ref - The item reference to check.
   * @returns True if the collection has the referenced item, false otherwise.
   */
  export function hasReferencedItem<T extends TypedCollection<CacheableObject>>(
    collection: T,
    ref: ItemReference<T>,
  ): boolean {
    const item = collection.data[ref.referencedId];
    if (
      collection.id === ref.referencedCollectionId &&
      collection.type === ref.referencedCollectionType &&
      item !== undefined &&
      item.type === ref.referencedType
    ) {
      return true;
    }
    return false;
  }

  /**
   * Gets the referenced item from the given collection.
   * @param collection - The collection to get the item from.
   * @param ref - The item reference.
   * @returns The referenced item if it exists in the collection, undefined otherwise.
   */
  export function getReferencedItem<T extends TypedCollection<CacheableObject>>(
    collection: T,
    ref: ItemReference<T>,
  ): T['data'][string] | undefined {
    if (hasReferencedItem(collection, ref)) {
      return collection.data[ref.referencedId] as T['data'][string];
    }
    return undefined;
  }
}
