/** @jsxImportSource @emotion/react */

import { ok } from 'neverthrow';
import { useEffect, useState } from 'react';

import { InkibraRecordlessLibrarySongType } from '../type-song';
import { MusicBeatGrid } from './beat-grid';
import { pixelOffsetToSeconds } from './utils';
import { MusicWaveformDisplay } from './waveform-display';

export type RecordlessTransitionEditorProps = {
  song: InkibraRecordlessLibrarySongType;
  transition: InkibraRecordlessLibrarySongType.Transition;
  onTransitionUpdate: (
    newTransition: InkibraRecordlessLibrarySongType.Transition,
  ) => void;
  songWaveform: number[];
  pixelsPerSecond: number;
};
export const RecordlessTransitionEditor = (
  props: RecordlessTransitionEditorProps,
) => {
  const [waveformWindow, setWaveformWindow] = useState<number[]>([]);
  const [hoveringBeatIndex, setHoveringBeatIndex] = useState<
    number | undefined
  >(undefined);
  const [hoveringAddButton, setHoveringAddButton] = useState<
    number | undefined
  >(undefined);

  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
  const sourceSectionNameResult = songUtil
    .getSectionById(props.song, props.transition.sourceSectionFieldId)
    .andThen((section) => {
      return ok(section.name);
    });
  if (sourceSectionNameResult.isErr()) {
    console.error(
      'Error getting source section name',
      sourceSectionNameResult.error,
    );
    throw new Error('Error getting source section name');
  }
  const sourceSectionDurationResult = songUtil.computeSectionDuration(
    props.song,
    props.transition.sourceSectionFieldId,
  );
  if (sourceSectionDurationResult.isErr()) {
    console.error(
      'Error computing source section duration',
      sourceSectionDurationResult.error,
    );
    throw new Error('Error computing source section duration');
  }
  let displaySectionDuration = sourceSectionDurationResult.value;
  const sectionAfterSourceSectionResult = songUtil.getSectionAfter(
    props.song,
    props.transition.sourceSectionFieldId,
  );
  const sectionAfterSourceSectionDurationResult =
    sectionAfterSourceSectionResult.andThen((section) => {
      return songUtil.computeSectionDuration(props.song, section.id);
    });
  if (sectionAfterSourceSectionDurationResult.isOk()) {
    displaySectionDuration += sectionAfterSourceSectionDurationResult.value;
  }
  const width = displaySectionDuration * props.pixelsPerSecond;

  const sourceSectionDurationInBeats =
    sourceSectionDurationResult.value / (60 / props.song.bpm);

  useEffect(
    function setWaveformWindowOnMount() {
      const newWaveformWindowResult = songUtil.computeDataWindowForSection(
        props.song,
        props.transition.sourceSectionFieldId,
        props.songWaveform,
      );
      if (newWaveformWindowResult.isErr()) {
        console.error(
          'Error computing data window for section',
          newWaveformWindowResult.error,
        );
        throw new Error('Error computing data window for section');
      }
      const sectionAfterSourceSection = songUtil.getSectionAfter(
        props.song,
        props.transition.sourceSectionFieldId,
      );
      if (sectionAfterSourceSection.isOk()) {
        const nextSectionWaveform = songUtil.computeDataWindowForSection(
          props.song,
          sectionAfterSourceSection.value.id,
          props.songWaveform,
        );
        if (nextSectionWaveform.isErr()) {
          console.error(
            'Error computing data window for next section',
            nextSectionWaveform.error,
          );
          throw new Error('Error computing data window for next section');
        }
        setWaveformWindow([
          ...newWaveformWindowResult.value,
          ...nextSectionWaveform.value,
        ]);
      } else {
        setWaveformWindow(newWaveformWindowResult.value);
      }
    },
    [props.songWaveform, props.transition, props.song],
  );
  return (
    <svg
      width={width}
      height="160"
      onMouseLeave={() => {
        setHoveringBeatIndex(undefined);
      }}
    >
      <rect
        x="0"
        y="0"
        rx="6"
        width={sourceSectionDurationResult.value * props.pixelsPerSecond}
        height="75"
        fill="#FFFFFF"
        opacity={0.95}
      />
      {sectionAfterSourceSectionResult.isOk() &&
      sectionAfterSourceSectionDurationResult.isOk() ? (
        <rect
          x={sourceSectionDurationResult.value * props.pixelsPerSecond}
          y="0"
          rx="6"
          width={
            sectionAfterSourceSectionDurationResult.value *
            props.pixelsPerSecond
          }
          height="75"
          fill="#FFFFFF"
          opacity={0.95}
        />
      ) : null}
      <MusicWaveformDisplay
        waveformData={waveformWindow}
        width={width}
        height={75}
        offset={0}
      />
      <MusicBeatGrid
        highlightedBeatIndex={undefined}
        width={width}
        beatWidth={(60 / props.song.bpm) * props.pixelsPerSecond}
        gridOffset={0}
        onHover={(beatIndex) => {
          if (hoveringBeatIndex === beatIndex) {
            return;
          }
          setHoveringBeatIndex(beatIndex);
        }}
        onLeave={() => {
          if (hoveringAddButton !== undefined) {
            return;
          }
          setHoveringBeatIndex(undefined);
        }}
      />
      <text x="10" y="28" fill="black" fontSize={14}>
        {sourceSectionNameResult.value}
      </text>
      {sectionAfterSourceSectionResult.isOk() ? (
        <text
          x={sourceSectionDurationResult.value * props.pixelsPerSecond + 10}
          y="28"
          fill="black"
          fontSize={14}
        >
          {sectionAfterSourceSectionResult.value.name}
        </text>
      ) : null}
      {hoveringBeatIndex !== undefined ? (
        <>
          <TransitionDestinationDisplay
            beatIndex={hoveringBeatIndex}
            pixelsPerSecond={props.pixelsPerSecond}
            transition={props.transition}
            song={props.song}
            waveformData={props.songWaveform}
            onTransitionUpdate={(newTransitionManager) => {
              console.log(
                'forwarding update from TransitionSourceDisplay',
                newTransitionManager.crossfadeOffset,
              );
              props.onTransitionUpdate(newTransitionManager);
            }}
          />
          <AddButton
            x={
              hoveringBeatIndex * (60 / props.song.bpm) * props.pixelsPerSecond
            }
            y={70}
            onClick={() => {
              const adjustedBeatIndex =
                hoveringBeatIndex - sourceSectionDurationInBeats;
              const fadeOutBeatOffsetTime =
                adjustedBeatIndex * (60 / props.song.bpm);
              // set the transition offset according to the beat index
              const updatedTransitionResult = songUtil.draftTransition(
                props.song,
                props.transition.sourceSectionFieldId,
                props.transition.targetSectionFieldId,
                props.transition.crossfadeOffset,
                props.transition.crossfadeDuration,
                fadeOutBeatOffsetTime,
              );
              if (updatedTransitionResult.isErr()) {
                console.warn(
                  'Error updating transition',
                  updatedTransitionResult.error,
                );
              } else {
                props.onTransitionUpdate(updatedTransitionResult.value);
              }
              setHoveringBeatIndex(undefined);
            }}
            onMouseOver={() => setHoveringAddButton(hoveringBeatIndex)}
            onMouseLeave={() => {
              setHoveringAddButton(undefined);
            }}
          />
        </>
      ) : (
        <TransitionDestinationDisplay
          song={props.song}
          beatIndex={
            sourceSectionDurationInBeats +
            props.transition.fadeOutTrackTimeOffset / (60 / props.song.bpm)
          }
          pixelsPerSecond={props.pixelsPerSecond}
          transition={props.transition}
          waveformData={props.songWaveform}
          onTransitionUpdate={(newTransitionManager) => {
            console.log(
              'forwarding update from TransitionSourceDisplay',
              newTransitionManager.crossfadeOffset,
            );
            props.onTransitionUpdate(newTransitionManager);
          }}
        />
      )}
    </svg>
  );
};
type TransitionDestinationDisplayProps = {
  waveformData: number[];
  song: InkibraRecordlessLibrarySongType;
  beatIndex: number;
  pixelsPerSecond: number;
  transition: InkibraRecordlessLibrarySongType.Transition;
  onTransitionUpdate: (
    newTransitionManager: InkibraRecordlessLibrarySongType.Transition,
  ) => void;
};
const TransitionDestinationDisplay = (
  props: TransitionDestinationDisplayProps,
) => {
  const [editingCrossfadeOffset, setEditingCrossfadeOffset] = useState<
    number | undefined
  >(undefined);
  const [editingCrossfadeDuration, setEditingCrossfadeDuration] = useState<
    number | undefined
  >(undefined);
  const [waveformWindow, setWaveformWindow] = useState<number[]>([]);

  const height = 75;
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

  const targetSection = songUtil
    .getSectionById(props.song, props.transition.targetSectionFieldId)
    .andThen((section) => {
      return ok(section);
    });
  if (targetSection.isErr()) {
    console.error('Error getting target section', targetSection.error);
    throw new Error('Error getting target section');
  }
  const targetSectionTimes = songUtil.computeSectionTimes(
    props.song,
    props.transition.targetSectionFieldId,
  );
  if (targetSectionTimes.isErr()) {
    console.error('Error computing section times', targetSectionTimes.error);
    throw new Error('Error computing section times');
  }

  const widthOfDestinationSectionField =
    targetSectionTimes.value.duration * props.pixelsPerSecond;

  const previousSectionTimesResult = songUtil
    .getSectionBefore(props.song, props.transition.targetSectionFieldId)
    .andThen((section) => {
      return songUtil.computeSectionTimes(props.song, section.id);
    });
  const widthOfPreviousSectionField = previousSectionTimesResult
    .andThen((previousSectionTimes) => {
      return ok(previousSectionTimes.duration * props.pixelsPerSecond);
    })
    .unwrapOr(0);
  const width = widthOfDestinationSectionField + widthOfPreviousSectionField;

  useEffect(
    function setWaveformWindowOnMount() {
      const newWaveformWindowResult = songUtil.computeDataWindowForSection(
        props.song,
        props.transition.targetSectionFieldId,
        props.waveformData,
      );
      if (newWaveformWindowResult.isErr()) {
        console.error(
          'Error computing data window for section',
          newWaveformWindowResult.error,
        );
        throw new Error('Error computing data window for section');
      }
      const sectionBeforeTargetSectionResult = songUtil.getSectionBefore(
        props.song,
        props.transition.targetSectionFieldId,
      );
      if (sectionBeforeTargetSectionResult.isOk()) {
        const previousSectionWaveformResult =
          songUtil.computeDataWindowForSection(
            props.song,
            sectionBeforeTargetSectionResult.value.id,
            props.waveformData,
          );
        if (previousSectionWaveformResult.isErr()) {
          console.error(
            'Error computing data window for previous section',
            previousSectionWaveformResult.error,
          );
          throw new Error('Error computing data window for previous section');
        }
        setWaveformWindow([
          ...previousSectionWaveformResult.value,
          ...newWaveformWindowResult.value,
        ]);
      } else {
        setWaveformWindow(newWaveformWindowResult.value);
      }
    },
    [props.waveformData, props.transition, props.song.duration],
  );
  const svgXOffset =
    props.beatIndex * (60 / props.song.bpm) * props.pixelsPerSecond -
    widthOfPreviousSectionField;
  return (
    <svg
      width={width}
      height={height}
      y="80"
      x={svgXOffset}
      onMouseMove={(e) => {
        // Get the bounding rectangle of the SVG to calculate the mouse position relative to the SVG
        const svgRect = e.currentTarget.getBoundingClientRect();
        // Calculate the time offset for the beat based on the beat index and BPM
        const beatOffsetTime = props.beatIndex * (60 / props.song.bpm);
        // Calculate the relative X position of the mouse within the SVG, accounting for the SVG's x property offset
        const relativeX = e.clientX - svgRect.left + svgXOffset;
        // Convert the relative X position to the corresponding time in the song
        const timeAtMouseX = pixelOffsetToSeconds(
          relativeX,
          props.pixelsPerSecond,
        );
        // Calculate the crossfadeOffset by subtracting the beat offset time from the time at mouseX
        const crossfadeOffset = timeAtMouseX - beatOffsetTime;

        if (editingCrossfadeOffset !== undefined) {
          // If we are currently editing the crossfade offset, validate the new offset
          const editingTransitionResult = songUtil.validateCrossfadeParameter(
            props.song,
            props.transition.sourceSectionFieldId,
            props.transition.targetSectionFieldId,
            crossfadeOffset,
            props.transition.fadeOutTrackTimeOffset,
          );

          if (editingTransitionResult.isOk()) {
            // If the new offset is valid, update the state to reflect the change
            return setEditingCrossfadeOffset(crossfadeOffset);
          }
        } else if (editingCrossfadeDuration !== undefined) {
          // If we are currently editing the crossfade duration, calculate the new duration
          // The crossfadeDuration is the time at mouseX minus the beat offset time and the current crossfadeOffset
          const crossfadeDuration =
            timeAtMouseX - beatOffsetTime - props.transition.crossfadeOffset;
          // Validate the new crossfade duration before setting it
          const editingTransitionResult = songUtil.validateCrossfadeParameter(
            props.song,
            props.transition.sourceSectionFieldId,
            props.transition.targetSectionFieldId,
            props.transition.crossfadeOffset,
            crossfadeDuration,
          );

          if (editingTransitionResult.isOk()) {
            // If the new duration is valid, update the state to reflect the change
            return setEditingCrossfadeDuration(crossfadeDuration);
          }
        }
      }}
      onMouseLeave={() => {
        setEditingCrossfadeOffset(undefined);
        setEditingCrossfadeDuration(undefined);
      }}
    >
      {previousSectionTimesResult.isOk() ? (
        <rect
          x="0"
          y="0"
          rx="6"
          width={widthOfPreviousSectionField}
          height="75"
          fill="#FFFFFF"
          opacity={0.95}
        />
      ) : null}
      <rect
        x={widthOfPreviousSectionField}
        y="0"
        rx="6"
        width={widthOfDestinationSectionField}
        height="75"
        fill="#FFFFFF"
        opacity={0.95}
      />
      <MusicWaveformDisplay
        waveformData={waveformWindow}
        width={width}
        height={75}
        offset={0}
      />
      <text
        x={widthOfPreviousSectionField + 10}
        y="28"
        fill="black"
        fontSize={14}
      >
        {targetSection.value.name}
      </text>
      {/* Crossfade Begin Line */}
      <line
        x1={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond
        }
        y1={0}
        x2={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond
        }
        y2={20}
        stroke="#42FF00"
        strokeWidth={2}
      />
      {/* Crossfade End Line */}
      <line
        x1={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond +
          (editingCrossfadeDuration || props.transition.crossfadeDuration) *
            props.pixelsPerSecond
        }
        y1={0}
        x2={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond +
          (editingCrossfadeDuration || props.transition.crossfadeDuration) *
            props.pixelsPerSecond
        }
        y2={20}
        stroke="#FF1F00"
        strokeWidth={2}
      />
      {/* Crossfade Duration Line */}
      <line
        x1={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond
        }
        y1={10}
        x2={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond +
          (editingCrossfadeDuration || props.transition.crossfadeDuration) *
            props.pixelsPerSecond
        }
        y2={10}
        stroke="black"
        strokeWidth={2}
      />
      {/* Crossfade Begin Interaction Area */}
      <rect
        onClick={() => {
          if (editingCrossfadeOffset !== undefined) {
            songUtil
              .draftTransition(
                props.song,
                props.transition.sourceSectionFieldId,
                props.transition.targetSectionFieldId,
                editingCrossfadeOffset,
                props.transition.crossfadeDuration,
                props.transition.fadeOutTrackTimeOffset,
              )
              .andThen((newTransition) => {
                props.onTransitionUpdate(newTransition);
                return ok(true);
              });
            // TODO: should we move this to the andThen block?
            setEditingCrossfadeOffset(undefined);
          } else if (editingCrossfadeDuration === undefined) {
            setEditingCrossfadeOffset(props.transition.crossfadeOffset);
          }
        }}
        x={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond -
          10
        }
        y={0}
        width={editingCrossfadeOffset ? 25 : 15}
        height={20}
        fill="blue"
        fillOpacity="0.25"
      />
      {/* Crossfade End Interaction Area */}
      <rect
        onClick={() => {
          if (editingCrossfadeDuration !== undefined) {
            songUtil
              .draftTransition(
                props.song,
                props.transition.sourceSectionFieldId,
                props.transition.targetSectionFieldId,
                props.transition.crossfadeOffset,
                editingCrossfadeDuration,
                props.transition.fadeOutTrackTimeOffset,
              )
              .andThen((newTransition) => {
                props.onTransitionUpdate(newTransition);
                return ok(true);
              });
            // TODO: should we move this to the andThen block?
            setEditingCrossfadeDuration(undefined);
          } else if (editingCrossfadeOffset === undefined) {
            setEditingCrossfadeDuration(props.transition.crossfadeDuration);
          }
        }}
        x={
          widthOfPreviousSectionField +
          (editingCrossfadeOffset || props.transition.crossfadeOffset) *
            props.pixelsPerSecond +
          (editingCrossfadeDuration || props.transition.crossfadeDuration) *
            props.pixelsPerSecond -
          (editingCrossfadeDuration ? 10 : 5)
        }
        y={0}
        width={editingCrossfadeDuration ? 25 : 15}
        height={20}
        fill="red"
        fillOpacity="0.25"
      />
    </svg>
  );
};
type AddButtonProps = {
  x: number;
  y: number;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
};
function AddButton(props: AddButtonProps) {
  return (
    // biome-ignore lint/a11y/useKeyWithMouseEvents: <explanation>
    <svg
      onClick={props.onClick}
      x={props.x - 7}
      y={props.y}
      width="14"
      height="14"
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
    >
      <circle cx="7" cy="7" r="7" fill="#24FF00" />
      <line x1="0" y1="7" x2="14" y2="7" stroke="#63533A" />
      <line x1="7" y1="0" x2="7" y2="14" stroke="#63533A" />
    </svg>
  );
}
