/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { AuthenticatedSessionContext } from '@inkibra/recordless.auth-api';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/button';
import { Hamburger } from '../components/icons/hamburger';
import { RadioGroup } from '../components/radio-group';
import {
  DistanceSessionActivity,
  WorkoutSessionContext,
} from '../components/workout-session-context';
import { InkibraRecordlessWorkoutType } from '../type';
import { BrowseRoute, DistanceActivityPlaybackRoute } from './routes';

const appBarStyles = css({
  label: 'app-bar',
  maxWidth: '1000px',
  display: 'grid',
  position: 'fixed',
  top: 0,
  width: '100%',
  gridTemplateColumns: 'min-content min-content',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 'calc(max(env(safe-area-inset-top), 8px))',
  zIndex: 100,
  backgroundColor: 'rgba(8, 3, 29, 0.8)',
  backdropFilter: 'blur(10px)',
});

const hamburgerStyles = css({
  display: 'grid',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
});
export function DistanceActivityStart() {
  const navigate = useNavigate();
  const workoutSession = useContext(WorkoutSessionContext)();
  const { logout, session } = useContext(AuthenticatedSessionContext)();

  const [distanceSessionActivity, setDistanceSessionActivity] =
    useState<DistanceSessionActivity>(DistanceSessionActivity.RUNNING);

  const [contextMenuProps, toggleContextMenu] = useMenuState();
  const [contextMenuAnchor, setContextMenuAnchor] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  return (
    <div
      css={{
        label: 'distance-mode-container-styles',
        margin: 'auto',
        width: '100vw',
        height: 'fill-available',
        display: 'grid',
        gap: '25px',
        paddingTop: 'calc(env(safe-area-inset-top) + 45px)',
        gridAutoRows: 'min-content',
        alignContent: 'end',
      }}
    >
      <div css={appBarStyles}>
        <div
          css={[
            hamburgerStyles,
            contextMenuProps.state === 'open' && {
              background: 'rgba(0, 0, 0, 0.2)',
            },
          ]}
          onClick={(e) => {
            e.stopPropagation();
            toggleContextMenu();
            setContextMenuAnchor({ x: e.clientX, y: e.clientY });
          }}
        >
          <Hamburger />
          <ControlledMenu
            {...contextMenuProps}
            anchorPoint={contextMenuAnchor}
            onClose={() => {
              toggleContextMenu();
            }}
            direction="right"
          >
            <MenuItem
              onClick={() => {
                navigate(BrowseRoute.makeRouteLink({}));
              }}
            >
              Switch to Interval Mode
            </MenuItem>
            <MenuItem
              onClick={async () => {
                await logout();
              }}
            >
              Log out
            </MenuItem>
          </ControlledMenu>
        </div>
      </div>

      <RadioGroup
        title="Distance Session"
        subtitle="Select the activity you want to do"
        name="distance-session-activity"
        values={[
          DistanceSessionActivity.WALKING,
          DistanceSessionActivity.HIKING,
          DistanceSessionActivity.JOGGING,
          DistanceSessionActivity.RUNNING,
        ]}
        required
        selected={distanceSessionActivity}
        onChange={(newValue) => {
          setDistanceSessionActivity(newValue);
        }}
      />

      <Button
        type="button"
        icon="start"
        color="primary"
        disabled={workoutSession.isWorkoutSessionActive}
        buttonSize="large"
        contents="Start Distance Session"
        onClick={() => {
          const workoutType =
            distanceSessionActivity === DistanceSessionActivity.RUNNING
              ? InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_RUNNING
              : distanceSessionActivity === DistanceSessionActivity.WALKING
                ? InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_WALKING
                : InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_JOGGING;
          const distanceWorkout = InkibraRecordlessWorkoutType.fromCreation(
            {
              name: distanceSessionActivity,
              workoutType,
              intervals: [
                {
                  name: 'Get Going',
                  time: 60,
                  comments: '',
                  energy: 7,
                },
              ],
            },
            session.id,
          );
          workoutSession.queueWorkout(distanceWorkout);
          navigate(DistanceActivityPlaybackRoute.makeRouteLink({}));
        }}
      />
    </div>
  );
}
