/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useState } from 'react';
import { PropagateLoader } from 'react-spinners';
import { Button, ButtonBackgroundColor } from './button';
import {
  HeaderText,
  HeaderTextAlign,
  HeaderTextBackground,
} from './header-text';
import { ParagraphText, ParagraphTextAlign } from './paragraph-text';

export type ConfirmAuthFormProps = {
  onSubmit: (fullName: string) => Promise<true | string>;
  label: string;
};

const inputStyles = css({
  display: 'block',
  background: 'none',
  color: 'white',
  border: '1px solid rgba(217, 217, 217, 0.3)',
  borderRadius: '8px',
  boxSizing: 'border-box',
  width: '100%',
  height: '48px',
  fontFamily: "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  textAlign: 'center',
  lineHeight: '130%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '10px',
  marginBottom: '20px',
});

export function ConfirmAuthForm({ onSubmit, label }: ConfirmAuthFormProps) {
  const [fullName, setFullname] = useState<string>('');
  const [sent, setSent] = useState(false);
  const [complete, setComplete] = useState(false);

  const submitHandler = async () => {
    setSent(true);
    const res = await onSubmit(fullName);
    if (res === true) {
      setComplete(true);
    }
  };

  if (complete) {
    return (
      <form
        css={{
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          textAlign: 'center',
          width: '350px',
        }}
      >
        <HeaderText
          value="Thank you for confirming your email."
          fontSize="20px"
          fontWeight={600}
          background={HeaderTextBackground.SOLID_WHITE}
          textAlign={HeaderTextAlign.CENTER}
        />
        <ParagraphText
          value="You can now close this window. Return to the Recordless Studio app to continue."
          fontSize="18px"
          textAlign={ParagraphTextAlign.CENTER}
        />
      </form>
    );
  }

  return (
    <form
      css={{
        fontFamily:
          "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        textAlign: 'center',
        width: '350px',
      }}
    >
      <HeaderText
        value="Thank you for confirming your email."
        fontSize="20px"
        fontWeight={600}
        background={HeaderTextBackground.SOLID_WHITE}
        textAlign={HeaderTextAlign.CENTER}
      />
      <ParagraphText
        value={label}
        fontSize="18px"
        textAlign={ParagraphTextAlign.CENTER}
      />
      <input
        css={inputStyles}
        disabled={sent}
        autoComplete="full-name"
        aria-label="full name"
        placeholder="enter your full name"
        value={fullName}
        required
        type="text"
        onChange={(event) => {
          setFullname(event.target.value);
        }}
      />
      {sent === true ? (
        <PropagateLoader color={ButtonBackgroundColor.PURPLE} />
      ) : (
        <Button
          buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
          contents="Complete Your Sign Up"
          onClick={submitHandler}
          disabled={sent}
        />
      )}
    </form>
  );
}
