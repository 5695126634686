import typia from 'typia';

/*
  For anyone wondering why we don't do the typia.createIs<T>, typia.createAssert<T> etc. here it's
  because typia is an AOT (Ahead Of Time) compiler and it doesn't support unknown generic types.
*/

/**
 * Type definition for Schema Helpers. Used to collect typia helper functions for a type.
 * See https://typia.io/docs/ for more information.
 * @template T - The type to be validated
 * @property {function} is - Checks if the input is of type T
 * @property {function} assert - Asserts that the input is of type T
 * @property {function} validate - Validates the input and returns a validation object
 * @property {function} stringify - Converts the input of type T to a string
 * @property {function} clone - Creates a clone of the input of type T
 */
export type SchemaHelpersFor<T> = {
  is: (input: unknown) => input is T;
  assert: (input: unknown) => asserts input is T;
  validate: (input: unknown) => typia.IValidation<T>;
  stringify: (input: T) => string;
  clone: (input: T) => T;
};
