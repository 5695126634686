/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import { EditableText, EditableTextSize } from '@inkibra/recordless.ux';
import { useContext, useEffect, useRef, useState } from 'react';
import typia from 'typia';
import {
  FillSongInformation,
  InkibraRecordlessLibraryApiFetcherRegistry,
} from '../api';
import {
  RecordlessPlaybackDisplay,
  RecordlessSectionLabeling,
} from '../components';
import {
  useLibrarySong,
  useLibrarySongPath,
  useLibrarySongWaveform,
} from '../hooks';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { AlbumArtEditing } from './album-art-editing';

export type SongEditingProps = {
  songId: string;
};

enum ActionStatus {
  INITIAL = 'INITIAL',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export const SongEditing = (props: SongEditingProps) => {
  const cache = useContext(CacheContext)();

  const audioRef = useRef<HTMLAudioElement>(null);
  const [hasUpdatedSongSectioning, setHasUpdatedSongSectioning] =
    useState<boolean>(false);
  const [conformingArrangementsStatus, setConformingArrangementStatus] =
    useState<ActionStatus>(ActionStatus.INITIAL);
  const [fillSongAIError, setFillSongAIError] = useState<string>();
  const hookEffectContext = new HookEffectContext();
  const song = hookEffectContext.addEffect(useLibrarySong(props.songId));
  const songPath = hookEffectContext.addEffect(
    useLibrarySongPath(props.songId),
  );
  const songWaveform = hookEffectContext.addEffect(
    useLibrarySongWaveform(props.songId, '8'),
  );
  hookEffectContext.runEffects(cache);

  const [editedSong, setEditedSong] = useState<
    InkibraRecordlessLibrarySongType | undefined
  >(undefined);

  const containerRef = useRef<HTMLDivElement>(null);

  const justifyRight = css({
    justifySelf: 'end',
  });

  useEffect(() => {
    setEditedSong(song);
  }, [song]);

  if (!song || !songPath || !editedSong) {
    return null;
  }

  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
  const computedSectionInfoResult =
    songUtil.computeOrderedSectionInfo(editedSong);

  if (computedSectionInfoResult.isErr()) {
    console.error(
      'failed to compute section info',
      computedSectionInfoResult.error,
    );
    throw new Error('failed to compute section info');
  }

  const fillSongInformation = async (
    fieldRequest: keyof FillSongInformation.FillableSongInformation,
  ) => {
    setFillSongAIError(undefined);
    const filledInformation =
      await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'fillSongInformation',
      ).fn({
        body: {
          title: editedSong.title,
          albumArtist: editedSong.albumArtist,
          albumTitle: editedSong.albumTitle,
        },
        files: undefined,
        pathParams: {},
        pathQuery: { fieldRequest },
      });

    console.log('Got filled information: ', filledInformation);
    if (filledInformation.type === 'Err') {
      setFillSongAIError(filledInformation.error.message);
      return;
    }

    const filledInformationResponse = filledInformation.value[fieldRequest];
    setEditedSong({
      ...editedSong,
      [fieldRequest]: filledInformationResponse,
    });
  };

  return (
    <div>
      <AlbumArtEditing albumSlug={songUtil.getAlbumSlug(editedSong)} />
      <br />
      <div>{fillSongAIError}</div>
      <br />
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: 'max-content 0.8fr max-content',
          gap: '1em',
        }}
      >
        <label css={justifyRight}>Title:</label>
        <span>{editedSong.title}</span>
        <span />

        <label css={justifyRight}>Artists:</label>
        <EditableText
          forceEditing
          value={editedSong.artists.join(', ')}
          onValueChange={(value) =>
            setEditedSong({ ...editedSong, artists: value.split(', ') })
          }
        />
        <button
          onClick={() => {
            fillSongInformation('artists');
          }}
        >
          AI
        </button>

        <label css={justifyRight}>Album:</label>
        <span>{editedSong.albumTitle}</span>
        <span />

        <label css={justifyRight}>Album Artist:</label>
        <span>{editedSong.albumArtist}</span>
        <span />

        <label css={justifyRight}>Duration: </label>
        <span>{editedSong.duration}</span>
        <span />

        <label css={justifyRight}>BPM: </label>
        <EditableText
          forceEditing
          value={editedSong.bpm.toString()}
          onValueChange={(value) =>
            setEditedSong({ ...editedSong, bpm: parseInt(value) })
          }
        />
        <button
          onClick={() => {
            fillSongInformation('bpm');
          }}
        >
          AI
        </button>

        <label css={justifyRight}>Genres: </label>
        <EditableText
          forceEditing
          value={editedSong.genres.join(', ')}
          onValueChange={(value) => {
            const genres = value.split(', ');
            if (typia.is<InkibraRecordlessLibrarySongType['genres']>(genres)) {
              setEditedSong({ ...editedSong, genres });
            }
          }}
        />
        <button
          onClick={() => {
            fillSongInformation('genres');
          }}
        >
          AI
        </button>

        <label css={justifyRight}>Key: </label>
        <EditableText
          forceEditing
          value={editedSong.key || ''}
          onValueChange={(newKey) => {
            if (typia.is<InkibraRecordlessLibrarySongType['key']>(newKey)) {
              setEditedSong({ ...editedSong, key: newKey });
            }
          }}
        />
        <button
          onClick={() => {
            fillSongInformation('key');
          }}
        >
          AI
        </button>

        <label css={justifyRight}>Energy: </label>
        <EditableText
          forceEditing
          value={editedSong.energy.toString()}
          onValueChange={(value) =>
            setEditedSong({ ...editedSong, energy: parseInt(value) })
          }
        />
        <button onClick={() => fillSongInformation('energy')}>AI</button>

        <label css={justifyRight}>Activity Words: </label>
        <EditableText
          forceEditing
          value={editedSong.activityWords.join(', ')}
          onValueChange={(value) => {
            const activityWords = value.split(', ');
            if (
              typia.is<InkibraRecordlessLibrarySongType['activityWords']>(
                activityWords,
              )
            ) {
              setEditedSong({ ...editedSong, activityWords });
            }
          }}
        />
        <button onClick={() => fillSongInformation('activityWords')}>AI</button>

        <label css={justifyRight}>Mood Words: </label>
        <EditableText
          forceEditing
          value={editedSong.moodWords.join(', ')}
          onValueChange={(value) => {
            const moodWords = value.split(', ');
            if (
              typia.is<InkibraRecordlessLibrarySongType['moodWords']>(moodWords)
            ) {
              setEditedSong({ ...editedSong, moodWords });
            }
          }}
        />
        <button onClick={() => fillSongInformation('moodWords')}>AI</button>

        <label css={justifyRight}>Tagline: </label>
        <EditableText
          forceEditing
          value={editedSong.tagline}
          onValueChange={(value) =>
            setEditedSong({ ...editedSong, tagline: value })
          }
        />
        <button onClick={() => fillSongInformation('tagline')}>AI</button>

        <label css={justifyRight}>Description: </label>
        <EditableText
          forceEditing
          size={EditableTextSize.PARAGRAPH}
          value={editedSong.description}
          onValueChange={(value) =>
            setEditedSong({ ...editedSong, description: value })
          }
        />
        <button onClick={() => fillSongInformation('description')}>AI</button>

        <label css={justifyRight}>Keywords: </label>
        <EditableText
          forceEditing
          value={editedSong.keywords.join(', ')}
          onValueChange={(value) =>
            setEditedSong({ ...editedSong, keywords: value.split(', ') })
          }
        />
        <button onClick={() => fillSongInformation('keywords')}>AI</button>

        <label css={justifyRight}>Track Number: </label>
        <EditableText
          forceEditing
          value={editedSong.trackNumber?.toString() || ''}
          onValueChange={(value) =>
            setEditedSong({
              ...editedSong,
              trackNumber: parseInt(value),
            })
          }
        />
        <button onClick={() => fillSongInformation('trackNumber')}>AI</button>

        <label css={justifyRight}>Disc Number: </label>
        <EditableText
          forceEditing
          value={editedSong.discNumber?.toString() || ''}
          onValueChange={(value) =>
            setEditedSong({
              ...editedSong,
              discNumber: parseInt(value),
            })
          }
        />
        <button onClick={() => fillSongInformation('discNumber')}>AI</button>

        <label css={justifyRight}>Year: </label>
        <EditableText
          forceEditing
          value={editedSong.year?.toString() || ''}
          onValueChange={(value) =>
            setEditedSong({
              ...editedSong,
              year: parseInt(value),
            })
          }
        />
        <button onClick={() => fillSongInformation('year')}>AI</button>
      </div>
      <button
        onClick={async () => {
          const updateResult =
            await InkibraRecordlessLibraryApiFetcherRegistry.get(
              'modifyRecordlessSong',
            ).fn({
              body: {
                genres: editedSong.genres,
                key: editedSong.key,
                energy: editedSong.energy,
                activityWords: editedSong.activityWords,
                moodWords: editedSong.moodWords,
                tagline: editedSong.tagline,
                description: editedSong.description,
                keywords: editedSong.keywords,
                trackNumber: editedSong.trackNumber,
                discNumber: editedSong.discNumber,
                year: editedSong.year,
              },
              files: undefined,
              pathParams: {
                songId: editedSong.id,
              },
              pathQuery: {},
            });
          cache.input.next(updateResult.value);
        }}
      >
        Update Song Information
      </button>
      <h3>
        Catalog Status:{' '}
        {InkibraRecordlessLibrarySongType.CatalogStatus.toString(
          editedSong.catalogStatus,
        )}
      </h3>

      <audio ref={audioRef} src={songPath} controls />
      <div ref={containerRef}>
        <RecordlessPlaybackDisplay
          windowedWaveformData={songWaveform}
          duration={editedSong.duration}
          relativeSections={computedSectionInfoResult.value}
          audioPlayerRef={audioRef}
          relativeZero={0}
          containerRef={containerRef}
          usePixelPerSecondForWidth={15}
        />
        <RecordlessSectionLabeling
          containerRef={containerRef}
          onUpdate={(updatedSong) => {
            if (
              editedSong.catalogStatus !==
              InkibraRecordlessLibrarySongType.CatalogStatus
                .IN_CONTINUOUS_REVIEW
            ) {
              setEditedSong(updatedSong);
            }
          }}
          song={editedSong}
          waveformData={songWaveform}
          onPlayRequest={(time) => {
            if (audioRef.current) {
              audioRef.current.currentTime = time;
              audioRef.current.play();
            }
            console.log('play request', time);
          }}
        />
      </div>
      {editedSong.catalogStatus !==
        InkibraRecordlessLibrarySongType.CatalogStatus.IN_CONTINUOUS_REVIEW && (
        <button
          disabled={!hasUpdatedSongSectioning}
          onClick={async () => {
            const updateResult =
              await InkibraRecordlessLibraryApiFetcherRegistry.get(
                'setLibrarySongCatalogStatus',
              ).fn({
                body: {
                  catalogStatus:
                    InkibraRecordlessLibrarySongType.CatalogStatus
                      .IN_CONTINUOUS_REVIEW,
                },
                files: undefined,
                pathParams: {
                  songId: editedSong.id,
                },
                pathQuery: {},
              });
            cache.input.next(updateResult.value);
          }}
        >
          Approve for Continuous Review
        </button>
      )}
      {editedSong.catalogStatus ===
        InkibraRecordlessLibrarySongType.CatalogStatus.IN_CONTINUOUS_REVIEW && (
        <button
          disabled={!hasUpdatedSongSectioning}
          onClick={async () => {
            const updateResult =
              await InkibraRecordlessLibraryApiFetcherRegistry.get(
                'setLibrarySongCatalogStatus',
              ).fn({
                body: {
                  catalogStatus:
                    InkibraRecordlessLibrarySongType.CatalogStatus
                      .NEEDS_ATTENTION,
                },
                files: undefined,
                pathParams: {
                  songId: editedSong.id,
                },
                pathQuery: {},
              });
            cache.input.next(updateResult.value);
          }}
        >
          Set to Needs Attention
        </button>
      )}
      {editedSong.catalogStatus !==
        InkibraRecordlessLibrarySongType.CatalogStatus.IN_CONTINUOUS_REVIEW && (
        <button
          onClick={async () => {
            const updateResult =
              await InkibraRecordlessLibraryApiFetcherRegistry.get(
                'modifyRecordlessSong',
              ).fn({
                body: {
                  orderedSectionFields: editedSong.orderedSectionFields,
                },
                files: undefined,
                pathParams: {
                  songId: editedSong.id,
                },
                pathQuery: {},
              });
            cache.input.next(updateResult.value);
            setHasUpdatedSongSectioning(true);
          }}
        >
          Update Song Sectioning
        </button>
      )}
      {editedSong.catalogStatus ===
        InkibraRecordlessLibrarySongType.CatalogStatus.IN_CONTINUOUS_REVIEW && (
        <button
          disabled={conformingArrangementsStatus !== ActionStatus.INITIAL}
          onClick={async () => {
            setConformingArrangementStatus(ActionStatus.PENDING);
            const updateResult =
              await InkibraRecordlessLibraryApiFetcherRegistry.get(
                'ensureRecordlessSongHasConformingRecordlessArrangements',
              ).fn({
                body: undefined,
                files: undefined,
                pathParams: {
                  songId: editedSong.id,
                },
                pathQuery: {},
              });
            const updatedSong =
              await InkibraRecordlessLibraryApiFetcherRegistry.get(
                'getLibraryCatalogSong',
              ).fn({
                body: undefined,
                files: undefined,
                pathParams: {
                  songId: editedSong.id,
                },
                pathQuery: {},
              });

            cache.input.next(updatedSong.value);
            cache.input.next(updateResult.value);
            setConformingArrangementStatus(ActionStatus.SUCCESS);
          }}
        >
          Propose Conforming Arrangements {conformingArrangementsStatus}
        </button>
      )}
      <button onClick={() => setEditedSong(song)}>Cancel Changes</button>
    </div>
  );
};
