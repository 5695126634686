/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  InkibraRecordlessLibraryApiFetcherRegistry,
  InkibraRecordlessLibraryMixType,
  InkibraRecordlessLibrarySongType,
  Playable,
  PlaybackState,
} from '@inkibra/recordless.library-api';
import { useContext, useEffect, useState } from 'react';
import { ToneTempoConfigContext } from '../app';
import { Pause } from './icons/pause';
import { Play } from './icons/play';
import {
  WorkoutSessionAnnotation,
  WorkoutSessionContext,
} from './workout-session-context';

const styles = css({
  label: 'song-player-styles',
  width: '100%',
  padding: '16px',
  borderRadius: '7px',
  backgroundImage: 'linear-gradient(90deg, #3F16E1 0%, #08031D 153.45%)',
  display: 'grid',
  gap: '8px',
  gridTemplateColumns: '1fr min-content',
  alignItems: 'center',
});

const songDetailsStyles = css({
  label: 'song-details-styles',
  display: 'grid',
  gridTemplateColumns: '62px 1fr',
  alignItems: 'center',
  gap: '25px',
  color: '#FFFFFF',
  fontSize: '16px',
});

const songTitleStyles = css({
  label: 'song-title-styles',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: '600',
  lineHeight: '150%',
});

const songArtistStyles = css({
  label: 'song-artist-styles',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontWeight: '300',
  lineHeight: '150%',
});

const albumArtworkStyles = css({
  height: '62px',
  width: '62px',
});

export type SongPlayerProps = {
  onToggleTrayDrawer: () => void;
};

const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
export function SongPlayer(props: SongPlayerProps) {
  const workoutSession = useContext(WorkoutSessionContext)();
  const [nowPlaying, setNowPlaying] = useState<Playable | undefined>(undefined);
  const [paused, setPaused] = useState(true);
  const [playbackTime, setPlaybackTime] = useState(0);
  const toneTempoConfig = useContext(ToneTempoConfigContext)();
  const [albumArtUrl, setAlbumArtUrl] = useState<string>(
    toneTempoConfig.DEFAULT_ALBUM_ART_URL,
  );
  useEffect(() => {
    function handlePlaybackStateChange(
      playbackState: PlaybackState<WorkoutSessionAnnotation>,
    ) {
      setPlaybackTime(playbackState.time);
      setPaused(!playbackState.isPlaying);
      setNowPlaying(playbackState.nowPlaying?.playable);
    }

    workoutSession.playback.current?.subscribeToPlaybackState(
      handlePlaybackStateChange,
    );

    return () => {
      workoutSession.playback.current?.unsubscribeFromPlaybackState(
        handlePlaybackStateChange,
      );
    };
  }, [workoutSession.playback.current]);

  let currentSong: InkibraRecordlessLibrarySongType | undefined;

  if (nowPlaying?.type === 'nkrmix') {
    const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({});
    const nodePlayingAtTime = mixUtil.getNodePlayingAtTime(
      nowPlaying,
      playbackTime,
    );

    currentSong = nodePlayingAtTime?.currentLibraryArrangement.librarySong;
  } else if (nowPlaying?.type === 'nkrsong') {
    currentSong = nowPlaying;
  }

  useEffect(() => {
    if (currentSong === undefined) {
      setAlbumArtUrl(toneTempoConfig.DEFAULT_ALBUM_ART_URL);
      return;
    }
    const albumSlug = songUtil.getAlbumSlug(currentSong);
    InkibraRecordlessLibraryApiFetcherRegistry.get('getLibraryAlbumArtwork')
      .fn({
        body: undefined,
        files: undefined,
        pathParams: {
          albumSlug,
        },
        pathQuery: {},
      })
      .then((response) => {
        const newAlbumArtUrl =
          response.type === 'Ok'
            ? response.value.url
            : toneTempoConfig.DEFAULT_ALBUM_ART_URL;
        if (navigator.mediaSession) {
          navigator.mediaSession.metadata = new MediaMetadata({
            title: currentSong?.title,
            artist: currentSong?.albumArtist,
            album: currentSong?.albumTitle,
            artwork: [
              {
                src: newAlbumArtUrl,
              },
            ],
          });
        }
        setAlbumArtUrl(newAlbumArtUrl);
      });
  }, [currentSong]);

  return (
    <div css={styles}>
      <div css={songDetailsStyles} onClick={props.onToggleTrayDrawer}>
        <div>
          <img css={albumArtworkStyles} src={albumArtUrl} />
        </div>

        <div
          css={{
            label: 'song-information-container',
            display: 'inline-grid',
          }}
        >
          <div css={songTitleStyles}>{currentSong?.title}</div>
          <div css={songArtistStyles}>{currentSong?.artists.join(', ')}</div>
        </div>
      </div>

      <div
        css={{
          label: 'song-player-play-pause-button',
          padding: '8px',
        }}
        onClick={() => {
          if (paused) {
            workoutSession.playback.current?.resume();
          } else {
            workoutSession.playback.current?.pause();
          }
        }}
      >
        {paused ? <Play /> : <Pause />}
      </div>
    </div>
  );
}
