/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type WaveProps = {
  style: Interpolation<Theme>;
};

export function Wave({ style }: WaveProps) {
  return (
    <svg
      width="62"
      height="56"
      viewBox="0 0 62 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <rect
        x="0.0644531"
        y="0.928833"
        width="56.0056"
        height="49.9288"
        rx="12"
        fill="url(#paint0_linear_1690_19109)"
      />
      <g filter="url(#filter0_bd_1690_19109)">
        <path
          d="M12.0836 10C10.3799 10 9 11.2332 9 12.7558V16.8895V38.936C9.00009 39.697 9.69033 40.3139 10.5418 40.3139H44.4619C45.3134 40.3139 46.0036 39.697 46.0037 38.936V15.5116V12.7558C46.0037 11.2332 44.6238 10 42.92 10L12.0836 10ZM12.0836 16.8895H42.92V37.5581H12.0836V16.8895ZM30.5614 19.6265C29.7111 19.6384 29.032 20.2633 29.0437 21.0233V33.4244C29.0358 33.9213 29.3279 34.3835 29.8082 34.6341C30.2885 34.8846 30.8825 34.8846 31.3628 34.6341C31.843 34.3835 32.1352 33.9213 32.1273 33.4244V21.0233C32.133 20.6508 31.9698 20.2922 31.6748 20.029C31.3798 19.7659 30.9781 19.6207 30.5614 19.6265ZM24.3941 22.3823C23.5438 22.3942 22.8647 23.0191 22.8764 23.7791V30.6686C22.8685 31.1655 23.1607 31.6277 23.6409 31.8782C24.1212 32.1288 24.7152 32.1288 25.1955 31.8782C25.6757 31.6277 25.9679 31.1655 25.96 30.6686V23.7791C25.9657 23.4066 25.8025 23.048 25.5075 22.7848C25.2125 22.5217 24.8109 22.3765 24.3941 22.3823ZM36.7287 23.7602C35.8783 23.7721 35.1993 24.397 35.2109 25.157V30.6686C35.2031 31.1655 35.4952 31.6277 35.9755 31.8782C36.4558 32.1288 37.0498 32.1288 37.53 31.8782C38.0103 31.6277 38.3025 31.1655 38.2946 30.6686V25.157C38.3003 24.7845 38.137 24.4259 37.842 24.1627C37.547 23.8996 37.1454 23.7544 36.7287 23.7602ZM18.2268 25.1381C17.3765 25.15 16.6975 25.7749 16.7091 26.5349V29.2907C16.7012 29.7876 16.9934 30.2498 17.4736 30.5003C17.9539 30.7508 18.5479 30.7508 19.0282 30.5003C19.5085 30.2498 19.8006 29.7876 19.7927 29.2907V26.5349C19.7984 26.1624 19.6352 25.8038 19.3402 25.5406C19.0452 25.2775 18.6436 25.1323 18.2268 25.1381Z"
          fill="url(#paint1_linear_1690_19109)"
        />
        <path
          d="M12.0836 16.6895H11.8836V16.8895V37.5581V37.7581H12.0836H42.92H43.12V37.5581V16.8895V16.6895H42.92H12.0836ZM29.2437 21.0233L29.2436 21.0202C29.234 20.3914 29.8026 19.8371 30.5642 19.8265C30.9339 19.8213 31.2862 19.9504 31.5416 20.1783C31.7963 20.4054 31.9321 20.7097 31.9273 21.0202H31.9273V21.0233L31.9273 33.4244L31.9273 33.4276C31.9339 33.8407 31.691 34.2373 31.2703 34.4567C30.8479 34.677 30.323 34.677 29.9007 34.4567C29.48 34.2373 29.2371 33.8407 29.2436 33.4276H29.2437V33.4244L29.2437 21.0233ZM23.0764 23.7791H23.0764L23.0764 23.776C23.0667 23.1472 23.6354 22.5929 24.3969 22.5823C24.7666 22.5771 25.1189 22.7062 25.3744 22.9341C25.629 23.1613 25.7648 23.4655 25.76 23.776H25.76V23.7791L25.76 30.6686L25.76 30.6718C25.7666 31.0849 25.5237 31.4815 25.103 31.7009C24.6807 31.9212 24.1557 31.9212 23.7334 31.7009C23.3127 31.4815 23.0698 31.0849 23.0764 30.6718H23.0764V30.6686V23.7791ZM35.411 25.157L35.4109 25.1539C35.4013 24.5251 35.9699 23.9708 36.7315 23.9602C37.1011 23.955 37.4534 24.0841 37.7089 24.312C37.9636 24.5392 38.0994 24.8435 38.0946 25.1539H38.0946V25.157L38.0946 30.6686L38.0946 30.6718C38.1012 31.0849 37.8583 31.4815 37.4375 31.7009C37.0152 31.9212 36.4903 31.9212 36.068 31.7009C35.6473 31.4815 35.4044 31.0849 35.4109 30.6718H35.4109V30.6686L35.411 25.157ZM16.9091 26.5349L16.9091 26.5318C16.8994 25.903 17.4681 25.3488 18.2296 25.3381C18.5993 25.333 18.9516 25.462 19.2071 25.6899C19.4617 25.9171 19.5975 26.2214 19.5928 26.5318H19.5927V26.5349L19.5927 29.2907L19.5928 29.2939C19.5993 29.707 19.3564 30.1036 18.9357 30.323C18.5134 30.5433 17.9885 30.5433 17.5661 30.323C17.1454 30.1036 16.9025 29.707 16.9091 29.2939H16.9091V29.2907L16.9091 26.5349ZM9.2 12.7558C9.2 11.3642 10.4687 10.2 12.0836 10.2L42.92 10.2C44.535 10.2 45.8037 11.3642 45.8037 12.7558V15.5116V38.936C45.8036 39.566 45.2246 40.1139 44.4619 40.1139H10.5418C9.77907 40.1139 9.20007 39.566 9.2 38.936V16.8895V12.7558Z"
          stroke="url(#paint2_linear_1690_19109)"
          strokeWidth="0.4"
        />
      </g>
      <defs>
        <filter
          id="filter0_bd_1690_19109"
          x="-6"
          y="-5"
          width="67.0039"
          height="60.3139"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="7.5" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_1690_19109"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.537255 0 0 0 0 0.160784 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_backgroundBlur_1690_19109"
            result="effect2_dropShadow_1690_19109"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1690_19109"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1690_19109"
          x1="6.086"
          y1="14.5712"
          x2="38.3526"
          y2="82.5217"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FFA78F" />
          <stop offset="1" stop-color="#F23E2C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1690_19109"
          x1="26.9798"
          y1="12.1568"
          x2="7.02464"
          y2="35.6198"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" />
          <stop offset="1" stop-color="white" stop-opacity="0.2" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1690_19109"
          x1="14.8904"
          y1="13.5316"
          x2="33.0943"
          y2="41.0531"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="white" stop-opacity="0.25" />
          <stop offset="1" stop-color="white" stop-opacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
}
