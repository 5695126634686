/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useState } from 'react';
import { InkibraRecordlessWorkoutIntervalType } from '../type';
import { Button } from './button';
import { RangeInput } from './range-input';
import { TextArea } from './text-area';
import { TextInput } from './text-input';
import { ValueSliderInput } from './value-slider-input';

const styles = css({
  maxWidth: '600px',
  margin: 'auto',
  marginTop: 'calc(env(safe-area-inset-top) + 15px)',
  width: '100%',
  height: '100%',
  padding: '16px 8px 16px',
  zIndex: 10001,
  display: 'none',
  gridTemplateRows: 'min-content min-content min-content',
  background: '#08031D',
  rowGap: '24px',
});

const titleStyles = css({
  fontWeight: '700',
  fontSize: '24px',
  color: '#F2F2F7',
});

const buttonWrapperStyles = css({
  display: 'grid',
  gridTemplateRows: 'min-content min-content',
  gap: '24px',
});

export type TabataIntervalCreatorProps = {
  active: boolean;
  onSubmit: (
    interval: InkibraRecordlessWorkoutIntervalType[],
  ) => Promise<boolean>;
  onCancel: () => void;
};

export function TabataIntervalCreator({
  active,
  onSubmit,
  onCancel,
}: TabataIntervalCreatorProps) {
  const [intervalName, setIntervalName] = useState('');
  const [comments, setComments] = useState('');
  const [activeTime, setActiveTime] = useState(30);
  const [activeEnergy, setActiveEnergy] = useState(8);
  const [restTime, setRestTime] = useState(30);
  const [restEnergy, setRestEnergy] = useState(4);
  const [intervalCount, setIntervalCount] = useState(3);
  const [submitting, setSubmitting] = useState(false);

  const reset = () => {
    setIntervalName('');
    setComments('');
    setActiveTime(30);
    setActiveEnergy(8);
    setRestTime(30);
    setRestEnergy(4);
    setIntervalCount(3);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log('handleSubmit');
    e.preventDefault();

    setSubmitting(true);

    const intervals = Array.from({ length: intervalCount }, () => {
      const activeInterval: InkibraRecordlessWorkoutIntervalType = {
        name: intervalName,
        comments,
        time: activeTime,
        energy: activeEnergy,
      };

      const restInterval: InkibraRecordlessWorkoutIntervalType = {
        name: 'Rest',
        comments: `
        This is a rest interval before the next ${intervalName}.
        The upcoming interval has the following comments:
        ${comments}

        Take this time to prepare for that interval and regain your energy.
        `,
        time: restTime,
        energy: restEnergy,
      };

      return [activeInterval, restInterval];
    }).flat();

    const success = await onSubmit(intervals);

    if (success) {
      reset();
    }

    setSubmitting(false);
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    reset();
    onCancel();
  };

  return (
    <div css={[styles, active && { display: 'grid' }]}>
      <div css={titleStyles}>TABATA INTERVAL CREATOR</div>

      <form
        css={{
          label: 'form-group-wrapper',
          display: 'grid',
          gridAutoRows: 'min-content',
          gridAutoFlow: 'row',
          gridTemplateColumns: '1fr',
          rowGap: '24px',
        }}
        id="tabataIntervalForm"
        onSubmit={handleSubmit}
        noValidate
      >
        <TextInput
          name="name"
          label="INTERVAL NAME"
          placeholder="E.g., Push Ups, Rest, Plank"
          required={true}
          value={intervalName}
          onChange={(e) => setIntervalName(e.target.value.slice(0, 18))}
        />
        <TextArea
          name="comments"
          label="TRAINER VOICE TIPS"
          placeholder="E.g., Stretch and prepare for the next interval, focus on range of motion, push explosively."
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
        <ValueSliderInput
          title="INTERVAL DURATION"
          subtitle="Choose the duration of the interval in seconds."
          name="time"
          min={30}
          max={60 * 10}
          stepSize={5}
          initialValue={activeTime}
          label="minutes:seconds"
          formatter="time-from-seconds"
          onChange={(e) => setActiveTime(e)}
          speed={1}
        />
        <RangeInput
          title="ACTIVE INTERVAL SONG ENERGY LEVEL"
          subtitle="Higher energy levels are great for intense movements, while lower energy levels are better for recovery or rest periods."
          name="energy"
          min={0}
          max={10}
          value={activeEnergy}
          onChange={(e) => setActiveEnergy(e)}
        />
        <ValueSliderInput
          title="REST INTERVAL DURATION"
          subtitle="Choose the duration of the rest interval in seconds."
          name="restTime"
          min={30}
          max={60 * 10}
          stepSize={5}
          initialValue={restTime}
          label="minutes:seconds"
          formatter="time-from-seconds"
          onChange={(e) => setRestTime(e)}
          speed={1}
        />
        <RangeInput
          title="REST INTERVAL SONG ENERGY LEVEL"
          subtitle="Higher energy levels are great for intense movements, while lower energy levels are better for recovery or rest periods."
          name="restEnergy"
          min={0}
          max={10}
          value={restEnergy}
          onChange={(e) => setRestEnergy(e)}
        />
        <ValueSliderInput
          title="INTERVAL COUNT"
          subtitle="Choose the number of intervals."
          name="intervalCount"
          min={1}
          max={10}
          stepSize={1}
          initialValue={intervalCount}
          label="intervals"
          formatter="integer"
          onChange={(e) => setIntervalCount(e)}
          speed={1}
        />
      </form>

      <div css={buttonWrapperStyles}>
        <Button
          type="submit"
          form="tabataIntervalForm"
          color="primary"
          disabled={submitting || !intervalName || !comments.length}
          contents="SAVE"
        />

        <Button
          onClick={handleCancel}
          type="button"
          fillMode="outline"
          contents="CANCEL"
        />
      </div>
    </div>
  );
}
