/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import {
  InkibraRecordlessLibraryApiFetcherRegistry,
  InkibraRecordlessLibraryMixType,
  InkibraRecordlessLibrarySongType,
} from '@inkibra/recordless.library-api';
import { useContext, useEffect, useState } from 'react';
import { ToneTempoConfigContext } from '../app';
import { Check } from './icons/check';
import { Loader } from './loader';

const borderStyles = '1px solid #4017E1';

const styles = css({
  maxWidth: '1000px',
  padding: '32px 16px',
  borderTop: borderStyles,
  display: 'grid',
  gridTemplateColumns: '50px 1fr min-content',
  gap: '20px',
  width: '100vw',
  alignItems: 'center',
});

const timeBaseStyles = css({
  fontWeight: '700',
  fontSize: '16px',
  textAlign: 'center',
});

const detailsStyles = css({
  display: 'grid',
  gridTemplateColumns: '45px 1fr',
  gap: '16px',
  width: '100%',
  alignItems: 'center',
});

const titleBaseStyles = css({
  fontWeight: '800',
  fontSize: '16px',
  textTransform: 'capitalize',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  maxWidth: 'calc(100vw - 210px)',
});

const songStyles = css({
  fontWeight: '400',
  fontSize: '10px',
  color: '#989898',
  textOverflow: 'ellipsis',
  maxWidth: 'calc(100vw - 210px)',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});

const circleBaseStyles = css({
  width: '24px',
  height: '24px',
  borderRadius: '50%',
  textAlign: 'center',
});

const albumArtworkStyles = css({
  height: '45px',
  width: '45px',
});

function getFormattedTime(time: number) {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);
  return `${minutes < 10 ? `0${minutes}` : minutes}:${
    seconds < 10 ? `0${seconds}` : seconds
  }`;
}

function getTitleStyles(
  relativePlaybackPosition: InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime,
) {
  if (
    relativePlaybackPosition ===
    InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.PAST
  ) {
    return css(titleBaseStyles, {
      color: '#989898',
    });
  }
  if (
    relativePlaybackPosition ===
    InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.CURRENT
  ) {
    return css(titleBaseStyles, {
      color: '#52FF00',
    });
  }
  return css(titleBaseStyles, {
    color: '#52FF00',
  });
}

function getCircleStyles(
  relativePlaybackPosition: InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime,
) {
  if (
    relativePlaybackPosition ===
    InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.PAST
  ) {
    return css(circleBaseStyles, {
      border: '1px solid #52FF00',
      backgroundColor: '#52FF00',
    });
  }
  if (
    relativePlaybackPosition ===
    InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.CURRENT
  ) {
    return css(circleBaseStyles, {
      border: '1px solid #52FF00',
    });
  }
  return css(circleBaseStyles, {
    border: '1px solid #4017E1',
  });
}

function getTimeStyles(
  relativePlaybackPosition: InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime,
) {
  if (
    relativePlaybackPosition ===
    InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.PAST
  ) {
    return css(timeBaseStyles, {
      color: '#949494',
    });
  }
  if (
    relativePlaybackPosition ===
    InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.CURRENT
  ) {
    return css(timeBaseStyles, {
      color: '#F2F2F7',
    });
  }
  return css(timeBaseStyles, {
    color: '#949494',
  });
}

export type PlayableIntervalListItemProps =
  | {
      intervalName: string;
      relativePlaybackPosition: InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.FUTURE;
      duration?: number;
      isContinuation?: boolean;
      librarySong?: undefined;
      elapsedPlaybackTime?: number;
    }
  | {
      intervalName: string;
      duration: number;
      relativePlaybackPosition: InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime;
      isContinuation?: boolean;
      librarySong?: InkibraRecordlessLibrarySongType;
      elapsedPlaybackTime: number;
    };

const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
export function PlayableIntervalListItem(props: PlayableIntervalListItemProps) {
  const [albumArtURL, setAlbumArtURL] = useState<string>();
  const toneTempoConfig = useContext(ToneTempoConfigContext)();

  useEffect(() => {
    if (props.librarySong === undefined) {
      return;
    }
    const albumSlug = songUtil.getAlbumSlug(props.librarySong);

    InkibraRecordlessLibraryApiFetcherRegistry.get('getLibraryAlbumArtwork')
      .fn({
        body: undefined,
        files: undefined,
        pathParams: {
          albumSlug,
        },
        pathQuery: {},
      })
      .then((response) => {
        if (response.type === 'Ok') {
          setAlbumArtURL(response.value.url);
          return;
        }
      });
  }, [props.librarySong]);

  if (props.duration === undefined) {
    const formattedTime = props.duration
      ? getFormattedTime(props.duration)
      : '';
    const titleStyles = getTitleStyles(
      InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.FUTURE,
    );
    const circleStyles = getCircleStyles(
      InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.FUTURE,
    );
    const timeStyles = getTimeStyles(
      InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.FUTURE,
    );
    return (
      <div css={styles}>
        <div css={timeStyles}>{formattedTime}</div>

        <div css={detailsStyles}>
          <div>
            <Loader diameter={45} duration={2} spinnerThickness={3} />
          </div>

          <div>
            <div css={titleStyles}>{props.intervalName}</div>
          </div>
        </div>

        <div>
          <div css={circleStyles} />
        </div>
      </div>
    );
  }

  const timeToUse =
    props.relativePlaybackPosition ===
    InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime.CURRENT
      ? Math.max(props.duration - props.elapsedPlaybackTime, 0)
      : props.duration;

  const formattedTime = getFormattedTime(timeToUse);

  const circleStyles = getCircleStyles(props.relativePlaybackPosition);
  const timeStyles = getTimeStyles(props.relativePlaybackPosition);
  const titleStyles = getTitleStyles(props.relativePlaybackPosition);

  return (
    <div css={styles}>
      <div css={timeStyles}>{formattedTime}</div>

      <div css={detailsStyles}>
        <div>
          <img
            css={albumArtworkStyles}
            src={albumArtURL || toneTempoConfig.DEFAULT_ALBUM_ART_URL}
          />
        </div>

        <div>
          <div css={titleStyles}>
            {props.intervalName}
            {props.isContinuation ? ' CONT.' : ''}
          </div>
          <div />
          <div css={songStyles}>
            {props.librarySong
              ? `${props.librarySong.title} - ${props.librarySong.albumArtist}`
              : ''}
          </div>
        </div>
      </div>

      <div>
        <div css={circleStyles}>
          {props.relativePlaybackPosition ===
            InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
              .PAST && <Check />}
        </div>
      </div>
    </div>
  );
}
