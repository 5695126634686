/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type CheckboxProps = {
  style: Interpolation<Theme>;
};

export function Checkbox({ style }: CheckboxProps) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.3336C15.1547 19.3336 19.3333 15.1549 19.3333 10.0002C19.3333 4.84559 15.1547 0.666911 10 0.666911C4.84534 0.666911 0.666667 4.84559 0.666667 10.0002C0.666667 15.1549 4.84534 19.3336 10 19.3336ZM10 20.0002C15.5228 20.0002 20 15.5231 20 10.0002C20 4.4774 15.5228 0.000244141 10 0.000244141C4.47715 0.000244141 0 4.4774 0 10.0002C0 15.5231 4.47715 20.0002 10 20.0002Z"
        fill="#DEFD9C"
      />
      <path
        d="M13.9375 7.04452C14.1198 7.22681 14.1198 7.50024 13.9375 7.66431L9.125 12.4768C8.96094 12.6591 8.6875 12.6591 8.52344 12.4768L6.04427 9.99764C5.86198 9.83358 5.86198 9.56014 6.04427 9.37785C6.20833 9.21379 6.48177 9.21379 6.64583 9.37785L8.83333 11.5653L13.3359 7.04452C13.5 6.88045 13.7734 6.88045 13.9375 7.04452Z"
        fill="#DEFD9C"
      />
    </svg>
  );
}
