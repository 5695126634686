import * as Immutable from 'immutable';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { CacheableObject } from './cacheable-object';

function accumulator<T extends CacheableObject = CacheableObject>(
  current: Immutable.Map<string, T>,
  next: T | T[],
) {
  if (Array.isArray(next)) {
    let merged = current;
    const collectedObj = next.reduce(
      (prev, curr) => {
        prev[curr.id] = curr;
        merged = merged.remove(curr.id);
        return prev;
      },
      {} as { [key: string]: T },
    );
    merged = merged.mergeDeep(collectedObj);
    return merged;
  }
  const obj = {
    [next.id]: next,
  };
  const merged = current.remove(next.id).mergeDeep(obj);
  return merged;
}

/**
 * Represents an Observable Cache.
 * @template T - The type of the cacheable object.
 */
export class ObservableCache2<T extends CacheableObject = CacheableObject> {
  #behaviorSubject: BehaviorSubject<Immutable.Map<string, T>> =
    new BehaviorSubject<Immutable.Map<string, T>>(Immutable.Map<string, T>());
  #observable: Observable<Immutable.Map<string, T>> =
    this.#behaviorSubject.asObservable();

  /**
   * Adds data to the cache.
   */
  public inputNext(data: T | T[]) {
    this.#behaviorSubject.next(accumulator(this.#behaviorSubject.value, data));
  }

  /**
   * Gets the current value of the cache.
   */
  public get current() {
    return this.#behaviorSubject.value;
  }

  /**
   * Gets the observable cache.
   * @returns The observable cache.
   */
  public get observable() {
    return this.#observable.pipe(shareReplay(1));
  }
}
