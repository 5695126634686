/** @jsxImportSource @emotion/react */

export function DownloadNowButtonContents({ label }: { label: string }) {
  return (
    <span>
      <span
        css={{
          display: 'block',
          fontSize: '30px',
          lineHeight: '50px',
          marginLeft: '35px',
          marginRight: '35px',
          marginBottom: '5px',
        }}
      >
        {label}
      </span>
      <span
        css={{
          display: 'inline-grid',
          alignItems: 'center',
          gridTemplateColumns: '30px 1fr',
          height: '20px',
          lineHeight: '20px',
          letterSpacing: '0.5px',
          fontWeight: 400,
          marginBottom: '5px',
        }}
      >
        <img
          css={{
            height: '20px',
            width: '20px',
            marginTop: '-5px',
            display: 'inline-block',
          }}
          src="https://cdn.recordless.app/home-assets/wpf_mac-os.png"
          alt="Example Artwork"
        />{' '}
        Mac OS 11.8 or later
      </span>
    </span>
  );
}
