/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { Icon } from './icon';

const styles = css({
  display: 'grid',
  gridAutoColumns: 'min-content',
  gridAutoFlow: 'column',
  columnGap: '12px',
  placeContent: 'center',
  placeItems: 'center',
  whiteSpace: 'nowrap',
  lineHeight: '100%',
  borderWidth: '1px',
  borderStyle: 'solid',
  fontSize: '12px',
});

export type ButtonProps = {
  type: 'button' | 'submit';
  icon?: string;
  fillMode?: 'solid' | 'outline';
  buttonSize?: 'default' | 'small' | 'large';
  color?: 'default' | 'primary' | 'error' | 'white' | 'alternative';
  contents: string;
  form?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export function Button({
  type,
  icon,
  fillMode = 'solid',
  color = 'default',
  buttonSize = 'default',
  contents,
  form,
  disabled,
  onClick,
}: ButtonProps) {
  let internalStyles = styles;

  if (color === 'error') {
    internalStyles = css(
      {
        borderColor: '#D81919',
        color: '#D81919',
        background: fillMode === 'outline' ? 'transparent' : '#D81919',
      },
      internalStyles,
    );
  } else if (color === 'primary') {
    internalStyles = css(
      {
        borderColor: '#52FF00',
        color: '#08031D',
        background: fillMode === 'outline' ? 'transparent' : '#52FF00',
      },
      internalStyles,
    );
  } else if (color === 'white') {
    internalStyles = css(
      {
        color: '#FFFFFF',
        borderColor: '#FFFFFF',
        background: fillMode === 'outline' ? 'transparent' : '#FFFFFF',
      },
      internalStyles,
    );
  } else if (color === 'default') {
    internalStyles = css(
      {
        color: '#F2F2F7',
        borderColor: '#F2F2F7',
        background: fillMode === 'outline' ? 'transparent' : '#F2F2F7',
      },
      internalStyles,
    );
  } else if (color === 'alternative') {
    internalStyles = css(
      {
        color: '#F2F2F7',
        borderColor: '#4017E1',
        background: fillMode === 'outline' ? 'transparent' : '#4017E1',
      },
      internalStyles,
    );
  }

  if (buttonSize === 'small') {
    internalStyles = css(
      {
        fontWeight: '400',
        padding: '.75em',
        borderRadius: '16px',
      },
      internalStyles,
    );
  } else if (buttonSize === 'large') {
    internalStyles = css(
      {
        fontWeight: '700',
        padding: '1.25em',
        borderRadius: '8px',
      },
      internalStyles,
    );
  } else {
    internalStyles = css(
      {
        fontWeight: '700',
        padding: '1em',
        borderRadius: '8px',
      },
      internalStyles,
    );
  }

  if (disabled) {
    internalStyles = css(
      {
        opacity: 0.5,
      },
      internalStyles,
    );
  }

  return (
    <button
      css={internalStyles}
      type={type}
      form={form}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && (
        <span>
          <Icon name={icon} />
        </span>
      )}
      <span>{contents}</span>
    </button>
  );
}
