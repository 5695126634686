/** @jsxImportSource @emotion/react */

import { useDrag } from '@use-gesture/react';
import { useEffect, useState } from 'react';
import { FormGroupWrapper } from './form-group-wrapper';

const timeFormatter = (valueInSeconds: number) => {
  const minutes = Math.floor(valueInSeconds / 60);
  const seconds = Math.floor(valueInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const timeStringToValueInSeconds = (timeString: string) => {
  const [minutes, seconds] = timeString.split(':').map(Number);
  if (minutes === undefined || seconds === undefined) {
    throw new Error('Invalid time string');
  }
  return minutes * 60 + seconds;
};

const imperialDistanceFormatter = (valueInInches: number) => {
  const feet = Math.floor(valueInInches / 12);
  const inches = valueInInches % 12;
  return `${feet}'${inches}"`;
};
const imperialDistanceStringToValueInInches = (
  imperialDistanceString: string,
) => {
  const [feet, inches] = imperialDistanceString.split('.').map(Number);
  if (feet === undefined || inches === undefined) {
    throw new Error('Invalid imperial distance string');
  }
  return feet * 12 + inches;
};

const integerFormatter = (value: number) => value.toString();
const integerStringToValue = (integerString: string) => parseInt(integerString);

const decimalFormatter = (value: number) => value.toFixed(2).toString();
const decimalStringToValue = (decimalString: string) =>
  parseFloat(decimalString);

function stepValue(value: number, stepSize: number) {
  return Math.round(value / stepSize) * stepSize;
}

export type ValueSliderInputProps = {
  title: string;
  subtitle: string;
  name: string;
  min: number;
  max: number;
  speed: number;
  initialValue: number;
  formatter:
    | 'time-from-seconds'
    | 'decimal'
    | 'imperial-from-inches'
    | 'integer';
  stepSize: number;
  label: string;
  onChange: (value: number) => void;
};

export function ValueSliderInput({
  title,
  subtitle,
  initialValue,
  onChange,
  formatter,
  speed,
  stepSize,
  min,
  max,
  label,
}: ValueSliderInputProps) {
  const [rawValue, setRawValue] = useState(initialValue);

  const updateValue = (change: number) => {
    const newValue = Math.max(min, Math.min(max, rawValue + change));
    setRawValue(newValue);

    const steppedValue = stepValue(newValue, stepSize);

    const reportedValue =
      formatter === 'time-from-seconds'
        ? timeStringToValueInSeconds(timeFormatter(steppedValue))
        : formatter === 'decimal'
          ? decimalStringToValue(decimalFormatter(steppedValue))
          : formatter === 'imperial-from-inches'
            ? imperialDistanceStringToValueInInches(
                imperialDistanceFormatter(steppedValue),
              )
            : formatter === 'integer'
              ? integerStringToValue(integerFormatter(steppedValue))
              : void 0;
    if (reportedValue === undefined) {
      throw new Error('Invalid value');
    }
    onChange(reportedValue);
  };

  const dragBind = useDrag((state) => {
    const change = state.direction[0] * state.velocity[0] * speed;
    updateValue(change);
  });

  const steppedValue = stepValue(rawValue, stepSize);

  useEffect(() => {
    const timer = setTimeout(() => {
      setRawValue(steppedValue);
    }, 1000);
    return () => clearTimeout(timer);
  }, [steppedValue]);

  const formattedValue =
    formatter === 'time-from-seconds'
      ? timeFormatter(steppedValue)
      : formatter === 'decimal'
        ? decimalFormatter(steppedValue)
        : formatter === 'imperial-from-inches'
          ? imperialDistanceFormatter(steppedValue)
          : formatter === 'integer'
            ? integerFormatter(steppedValue)
            : void 0;

  if (formattedValue === undefined) {
    throw new Error('Invalid value');
  }

  return (
    <FormGroupWrapper title={title} subtitle={subtitle}>
      <div
        {...dragBind()}
        css={{
          label: 'value-slider-input-container',
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr 14px',
          touchAction: 'none',
          userSelect: 'none',
        }}
      >
        <div
          css={{
            label: 'value-slider-input',
            display: 'grid',
            gridTemplateColumns: 'min-content 1fr min-content',
            gap: '12px',
            alignItems: 'center',
            padding: '12px 14px',
            fontWeight: '500',
            fontSize: '32px',
            color: '#FFFFFF',
          }}
        >
          <div onClick={() => updateValue(-stepSize)}>-</div>

          <span
            css={{
              label: 'value-slider-input-label',
              textAlign: 'center',
              display: 'grid',
              gridTemplateRows: '1fr 1fr',
              gap: '4px',
            }}
          >
            <span>{formattedValue}</span>
            <span css={{ fontSize: '16px', fontWeight: '400' }}>{label}</span>
          </span>

          <div onClick={() => updateValue(stepSize)}>+</div>
        </div>
        <span
          css={{
            background: '#52FF00',
            fontSize: '10px',
            fontWeight: '400',
            color: 'black',
            textAlign: 'center',
            width: '100%',
            borderBottomLeftRadius: '12px',
            borderBottomRightRadius: '12px',
          }}
        >
          SLIDE TO ADJUST
        </span>
      </div>
    </FormGroupWrapper>
  );
}
