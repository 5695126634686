/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type EllipsisProps = {
  style: Interpolation<Theme>;
};

export function Ellipsis({ style }: EllipsisProps) {
  return (
    <svg
      width="12"
      height="3"
      viewBox="0 0 12 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <path
        d="M1.28369 2.69287C1.5249 2.69287 1.74072 2.63574 1.93115 2.52148C2.12581 2.40723 2.28027 2.25488 2.39453 2.06445C2.51302 1.87402 2.57227 1.66032 2.57227 1.42334C2.57227 1.0721 2.44743 0.773763 2.19775 0.52832C1.94808 0.278646 1.64339 0.153809 1.28369 0.153809C1.05094 0.153809 0.83724 0.210938 0.642578 0.325195C0.452148 0.439453 0.299805 0.593913 0.185547 0.788574C0.0712891 0.979004 0.0141602 1.19059 0.0141602 1.42334C0.0141602 1.66032 0.0712891 1.87402 0.185547 2.06445C0.299805 2.25488 0.452148 2.40723 0.642578 2.52148C0.83724 2.63574 1.05094 2.69287 1.28369 2.69287ZM6 2.69287C6.23698 2.69287 6.45068 2.63574 6.64111 2.52148C6.83154 2.40723 6.98389 2.25488 7.09814 2.06445C7.2124 1.87402 7.26953 1.66032 7.26953 1.42334C7.26953 1.0721 7.14469 0.773763 6.89502 0.52832C6.64958 0.278646 6.35124 0.153809 6 0.153809C5.76725 0.153809 5.55355 0.210938 5.35889 0.325195C5.16846 0.439453 5.01611 0.593913 4.90186 0.788574C4.79183 0.979004 4.73682 1.19059 4.73682 1.42334C4.73682 1.66032 4.79183 1.87402 4.90186 2.06445C5.01611 2.25488 5.16846 2.40723 5.35889 2.52148C5.55355 2.63574 5.76725 2.69287 6 2.69287ZM10.7163 2.69287C10.9491 2.69287 11.1606 2.63574 11.3511 2.52148C11.5457 2.40723 11.7002 2.25488 11.8145 2.06445C11.9287 1.87402 11.9858 1.66032 11.9858 1.42334C11.9858 1.0721 11.861 0.773763 11.6113 0.52832C11.3659 0.278646 11.0675 0.153809 10.7163 0.153809C10.4793 0.153809 10.2635 0.210938 10.0688 0.325195C9.87419 0.439453 9.71973 0.593913 9.60547 0.788574C9.49121 0.979004 9.43408 1.19059 9.43408 1.42334C9.43408 1.66032 9.49121 1.87402 9.60547 2.06445C9.71973 2.25488 9.87419 2.40723 10.0688 2.52148C10.2635 2.63574 10.4793 2.69287 10.7163 2.69287Z"
        fill="white"
      />
    </svg>
  );
}
