/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type BackwardsButtonProps = {
  style: Interpolation<Theme>;
};

export function BackwardsButton({ style }: BackwardsButtonProps) {
  return (
    <svg
      width="23"
      height="20"
      viewBox="0 0 23 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <path
        d="M2.2736 19.0898L4.94547 19.0898C5.45328 19.0898 5.84 18.957 6.10563 18.6914C6.37125 18.4258 6.50406 18.0352 6.50406 17.5195L6.50406 1.55859C6.50406 1.02734 6.37125 0.636719 6.10563 0.386719C5.84 0.128906 5.45328 0 4.94547 0L2.2736 0C1.75797 0 1.36735 0.132812 1.10172 0.398437C0.843908 0.664062 0.715002 1.05078 0.715002 1.55859L0.715002 17.5195C0.715002 18.0352 0.843908 18.4258 1.10172 18.6914C1.36735 18.957 1.75797 19.0898 2.2736 19.0898ZM20.9181 18.7383C21.3791 18.7383 21.7736 18.5664 22.1017 18.2227C22.4377 17.8867 22.6056 17.3906 22.6056 16.7344L22.6056 2.34375C22.6056 1.6875 22.4377 1.1875 22.1017 0.84375C21.7736 0.5 21.3791 0.328125 20.9181 0.328125C20.6838 0.328125 20.4611 0.367187 20.2502 0.445312C20.0392 0.515625 19.8127 0.621094 19.5705 0.761719L7.62906 7.79297C7.215 8.04297 6.91422 8.3125 6.72672 8.60156C6.53922 8.89062 6.44547 9.20312 6.44547 9.53906C6.44547 9.875 6.53922 10.1875 6.72672 10.4766C6.91422 10.7656 7.215 11.0352 7.62906 11.2852L19.5705 18.3047C19.8127 18.4453 20.0392 18.5508 20.2502 18.6211C20.4611 18.6992 20.6838 18.7383 20.9181 18.7383Z"
        fill="white"
      />
    </svg>
  );
}
