/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

export enum EditableTextSize {
  DEFAULT = 0,
  H1 = 1,
  PARAGRAPH = 2,
}

export type EditableTextProps = {
  value: string;
  onValueChange: (newValue: string) => void;
  size?: EditableTextSize;
  gridArea?: string;
  color?: string;
  forceEditing?: boolean;
};

export function EditableText({
  value,
  onValueChange,
  size,
  gridArea,
  color = 'white',
  forceEditing,
}: EditableTextProps) {
  const [editingMode, setEditingMode] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  if (editingMode || forceEditing) {
    switch (size) {
      case EditableTextSize.PARAGRAPH:
        return (
          <textarea
            css={{
              background: 'transparent',
              color,
              fontFamily:
                "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
              fontSize: '1em',
              display: 'block',
              gridArea: gridArea,
            }}
            value={editedValue}
            autoFocus
            onChange={(event) => {
              setEditedValue(event.target.value);
            }}
            onBlur={() => {
              setEditedValue(value);
              setEditingMode(false);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                setEditingMode(false);
                onValueChange(editedValue);
              } else if (event.key === 'Escape') {
                event.preventDefault();
                setEditedValue(value);
                setEditingMode(false);
              }
            }}
          />
        );
      default:
        return (
          <input
            css={{
              background: 'transparent',
              color,
              fontFamily:
                "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
              fontSize: size === EditableTextSize.H1 ? '2em' : undefined,
              display: size === EditableTextSize.H1 ? 'block' : 'inline-block',
              gridArea: gridArea,
            }}
            type="text"
            value={editedValue}
            autoFocus
            onChange={(event) => {
              setEditedValue(event.target.value);
            }}
            onBlur={() => {
              setEditedValue(value);
              setEditingMode(false);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
                setEditingMode(false);
                onValueChange(editedValue);
              } else if (event.key === 'Escape') {
                event.preventDefault();
                setEditedValue(value);
                setEditingMode(false);
              }
            }}
          />
        );
    }
  }
  switch (size) {
    case EditableTextSize.H1:
      return (
        <h1
          css={{
            color,
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          }}
          onDoubleClick={() => {
            setEditingMode(true);
          }}
        >
          {value}
        </h1>
      );
    default:
      return (
        <span
          css={{
            color,
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          }}
          onDoubleClick={() => {
            setEditingMode(true);
          }}
        >
          {value}
        </span>
      );
  }
}
