import { HookFunctionReturnType } from '@inkibra/api-base';
import { useState } from 'react';
import { isPresent } from 'ts-is-present';
import { InkibraRecordlessLibraryApiFetcherRegistry } from './api';
import { InkibraRecordlessLibraryArrangementType } from './type-arrangement';
import { InkibraRecordlessLibraryAlbumArtworkBinaryLocator } from './type-artwork-file-remote-binary-locator';
import { InkibraRecordlessLibraryMixType } from './type-mix';
import { InkibraRecordlessLibrarySongType } from './type-song';

const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

export function useLibrarySong(
  songId: string | undefined, // TODO: require ref
  runSetup = true,
): HookFunctionReturnType<InkibraRecordlessLibrarySongType | undefined> {
  const [data, setData] = useState<
    InkibraRecordlessLibrarySongType | undefined
  >(undefined);

  return {
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data)
          .filter(InkibraRecordlessLibrarySongType.RecordlessSongUtil.is)
          .find((song) => song.id === songId),
      );
    },
    constraint: songId,
    setup: async (cache) => {
      if (!songId || !runSetup) {
        return;
      }
      const data = await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'getLibraryCatalogSong',
      ).fn({
        body: undefined,
        pathParams: { songId },
        pathQuery: {},
        files: undefined,
      });

      setData(data.value);
      cache.input.next(data.value);
    },
  };
}

export function useLibrarySongs(
  runSetup = true,
): HookFunctionReturnType<InkibraRecordlessLibrarySongType[]> {
  const [data, setData] = useState<InkibraRecordlessLibrarySongType[]>([]);

  return {
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data).filter(
          InkibraRecordlessLibrarySongType.RecordlessSongUtil.is,
        ),
      );
    },
    setup: async (cache) => {
      if (!runSetup) {
        return;
      }
      const data = await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'getAllLibraryCatalogSongs',
      ).fn({
        body: undefined,
        pathParams: {},
        pathQuery: { limit: 1000 },
        files: undefined,
      });

      setData(data.value.sort((a, b) => a.title.localeCompare(b.title)));
      cache.input.next(data.value);
    },
  };
}

export type AlbumInformation = {
  albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug;
  artist: string;
  albumName: string;
};

// TODO: what we probably really want to do for performance
// is that every time the cache is updated, update the hook runners
// but only once per hook runner configuration (globally on the cache)
// this means the cache needs some sort of way to record run hook runners and their configurations
// We could write this stuff into a context maybe? but the easiest way is probably to just let the cache
// have a registry of hook runners and their configs. each hook runner could have a symbol, pure function, and call parameters
export function useLibraryAlbumInformation(): HookFunctionReturnType<
  AlbumInformation[]
> {
  const [data, setData] = useState<AlbumInformation[]>([]);

  return {
    data,
    hookRunner: (data) => {
      const songs = Object.values(data).filter(
        InkibraRecordlessLibrarySongType.RecordlessSongUtil.is,
      );
      const albumSongMap = songs.reduce(
        (acc, song) => {
          const albumSlug = songUtil.getAlbumSlug(song);
          acc[albumSlug] = acc[albumSlug]?.concat(song) || [song];
          return acc;
        },
        {} as Record<string, InkibraRecordlessLibrarySongType[]>,
      );
      const albums = Object.entries(albumSongMap).map(
        ([albumSlug, albumSongData]) => {
          const firstSongData = albumSongData[0];
          if (!firstSongData) {
            // TODO: is there a better way to handle this?
            throw new Error('No song data found for album');
          }
          return {
            albumSlug: albumSlug as InkibraRecordlessLibrarySongType.AlbumSlug,
            albumName: firstSongData.albumTitle,
            artist: firstSongData.albumArtist,
          };
        },
      );
      setData(albums.sort((a, b) => a.albumName.localeCompare(b.albumName)));
    },
  };
}

export type SongWithAssociatedArrangements =
  InkibraRecordlessLibrarySongType & {
    arrangements: InkibraRecordlessLibraryArrangementType[];
  };
type DetailedAlbumInformation = AlbumInformation & {
  songsWithArrangements: SongWithAssociatedArrangements[];
};

export function useSongsWithAssociatedArrangements(): HookFunctionReturnType<
  SongWithAssociatedArrangements[]
> {
  const [data, setData] = useState<SongWithAssociatedArrangements[]>([]);

  return {
    data,
    hookRunner: (data) => {
      const songs = Object.values(data).filter(
        InkibraRecordlessLibrarySongType.RecordlessSongUtil.is,
      );
      const arrangements = Object.values(data).filter(
        InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil.is,
      );
      const songsWithArrangements = songs.map((song) => {
        return {
          ...song,
          arrangements: arrangements
            .filter((arrangement) => arrangement.librarySongId === song.id)
            .sort((a, b) => a.name.localeCompare(b.name)),
        };
      });
      setData(
        songsWithArrangements.sort((a, b) => a.title.localeCompare(b.title)),
      );
    },
  };
}

export function useDetailedLibraryAlbumInformation(
  albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug | undefined,
): HookFunctionReturnType<DetailedAlbumInformation | undefined> {
  const [data, setData] = useState<DetailedAlbumInformation | undefined>(
    undefined,
  );

  return {
    data,
    hookRunner: (data) => {
      if (!albumSlug) {
        return;
      }
      const songsInAlbum = Object.values(data)
        .filter(InkibraRecordlessLibrarySongType.RecordlessSongUtil.is)
        .filter((song) => {
          return albumSlug === songUtil.getAlbumSlug(song);
        })
        .sort((a, b) => {
          const aSongNumber = (a.discNumber || 0) * 1000 + (a.trackNumber || 0);
          const bSongNumber = (b.discNumber || 0) * 1000 + (b.trackNumber || 0);
          return aSongNumber - bSongNumber;
        });

      console.log('found songs in album', songsInAlbum);
      const libraryArrangements = Object.values(data)
        .filter(
          InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil.is,
        )
        .filter((arrangement) => {
          return songsInAlbum.some(
            (song) => song.id === arrangement.librarySongId,
          );
        });
      console.log('found library arrangements', libraryArrangements);
      const libraryArrangementsBySongId = libraryArrangements.reduce(
        (acc, arrangement) => {
          acc[arrangement.librarySongId] = acc[
            arrangement.librarySongId
          ]?.concat(arrangement) ?? [arrangement];
          return acc;
        },
        {} as Record<string, InkibraRecordlessLibraryArrangementType[]>,
      );
      const songsWithArrangements = songsInAlbum.map((song) => {
        return {
          ...song,
          arrangements: libraryArrangementsBySongId[song.id] || [],
        };
      });
      const firstSong = songsInAlbum[0];
      if (!firstSong) {
        throw new Error('No songs found in album');
      }
      const albumInformation = {
        albumSlug,
        albumName: firstSong.albumTitle,
        artist: firstSong.albumArtist,
      };
      setData({
        ...albumInformation,
        songsWithArrangements,
      });
    },
    constraint: albumSlug,
  };
}

export function useLibrarySongPath(
  songId: string | undefined, // TODO: require ref
): HookFunctionReturnType<string | undefined> {
  const [data, setData] = useState<string | undefined>(undefined);

  return {
    constraint: songId,
    data,
    hookRunner: () => {},
    setup: async () => {
      if (!songId) {
        return;
      }
      const data = await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'getLibraryCatalogSongFile',
      ).fn({
        body: undefined,
        pathParams: { songId },
        pathQuery: {},
        files: undefined,
      });

      setData(data.value.url);
    },
  };
}

export function useLibraryAlbumArtworkLocator(
  albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug | undefined,
): HookFunctionReturnType<
  InkibraRecordlessLibraryAlbumArtworkBinaryLocator | undefined
> {
  const [data, setData] = useState<
    InkibraRecordlessLibraryAlbumArtworkBinaryLocator | undefined
  >(undefined);

  return {
    constraint: albumSlug,
    data,
    hookRunner: () => {},
    setup: async () => {
      if (!albumSlug) {
        return;
      }
      const data = await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'getLibraryAlbumArtwork',
      ).fn({
        body: undefined,
        pathParams: { albumSlug: encodeURIComponent(albumSlug) },
        pathQuery: {},
        files: undefined,
      });

      if (data.type === 'Ok') {
        setData(data.value);
      }
    },
  };
}

export function useArrangement(
  arrangementId: InkibraRecordlessLibraryArrangementType['id'] | undefined,
): HookFunctionReturnType<InkibraRecordlessLibraryArrangementType | undefined> {
  const [data, setData] = useState<
    InkibraRecordlessLibraryArrangementType | undefined
  >(undefined);

  return {
    constraint: arrangementId,
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data)
          .filter(
            InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil
              .is,
          )
          .find((arrangement) => arrangement.id === arrangementId),
      );
    },
  };
}

export function useLibraryArrangementsBySongIds(
  songIds: InkibraRecordlessLibrarySongType['id'][],
): HookFunctionReturnType<InkibraRecordlessLibraryArrangementType[]> {
  const [data, setData] = useState<InkibraRecordlessLibraryArrangementType[]>(
    [],
  );

  return {
    constraint: songIds,
    data,
    hookRunner: () => {},
    setup: async () => {
      const songArrangements = (
        await Promise.all(
          songIds.map(async (songId) => {
            const data = await InkibraRecordlessLibraryApiFetcherRegistry.get(
              'findInkibraRecordlessLibraryArrangements',
            ).fn({
              body: undefined,
              pathParams: {},
              pathQuery: { librarySongId: songId },
              files: undefined,
            });
            if (data.value) {
              return data.value;
            }
            return;
          }),
        )
      ).filter(isPresent);

      setData(
        songArrangements.flat().sort((a, b) => a.name.localeCompare(b.name)),
      );
    },
  };
}

export function useLibrarySongWaveform(
  songId: string | undefined,
  waveformResolutionOverride: '8' | '16' | '32',
): HookFunctionReturnType<number[]> {
  const [data, setData] = useState<number[]>([]);

  return {
    constraint: songId,
    data,
    hookRunner: () => {},
    setup: async () => {
      if (!songId) {
        return;
      }
      const data = await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'getLibraryCatalogSongWaveform',
      ).fn({
        body: undefined,
        pathParams: { songId },
        pathQuery: { waveformResolutionOverride },
        files: undefined,
      });

      setData(data.value);
    },
  };
}

export function useRecordlessLibraryArrangementWaveform(
  arrangementId: string | undefined, // TODO: require ref
): HookFunctionReturnType<number[]> {
  const [data, setData] = useState<number[]>([]);

  return {
    constraint: arrangementId,
    data,
    hookRunner: () => {},
    setup: async () => {
      if (!arrangementId) {
        return;
      }
      const data = await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'getInkibraRecordlessArrangementSources',
      ).fn({
        body: undefined,
        pathParams: { id: arrangementId },
        pathQuery: {},
        files: undefined,
      });
      if (data.type === 'Ok') {
        setData(data.value.waveform);
      }
    },
  };
}

export function useMix(
  mixId?: string,
): HookFunctionReturnType<InkibraRecordlessLibraryMixType | undefined> {
  const [data, setData] = useState<InkibraRecordlessLibraryMixType | undefined>(
    undefined,
  );

  return {
    data,
    hookRunner: (data) => {
      if (!mixId) {
        return setData(undefined);
      }
      const mix = data[mixId];
      console.log('useMix hookRunner', mix);
      if (InkibraRecordlessLibraryMixType.MixUtil.is(mix)) {
        return setData(mix);
      }
      return setData(undefined);
    },
    constraint: mixId,
  };
}

export function useMixes(): HookFunctionReturnType<
  InkibraRecordlessLibraryMixType[]
> {
  const [data, setData] = useState<InkibraRecordlessLibraryMixType[]>([]);

  return {
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data)
          .filter(InkibraRecordlessLibraryMixType.MixUtil.is)
          .sort((a, b) => a.name.localeCompare(b.name)),
      );
    },
  };
}
