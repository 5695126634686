/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { responsiveStyle } from '../constants/media-query';
import { Button, ButtonBackgroundColor } from './button';
import { Page } from './page';
import { Section } from './section';
import { TitleText, TitleTextBackground } from './title-text';
import { TransparentText } from './transparent-text';

import { DownloadNowButtonContents } from './button-download-now-contents';

const centerAlignStyles = css({
  textAlign: 'center',
  position: 'relative',
});

const buttonWrapperStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

export type DownloadStudioPageProps = {
  onPrimaryAction: () => void;
  primaryAction: 'available' | 'waitlist';
  screenshotPath: string;
};

export function DownloadStudioPage({
  screenshotPath,
  onPrimaryAction,
  primaryAction,
}: DownloadStudioPageProps) {
  return (
    <Page>
      <Section>
        <TitleText
          textAlign={{ xs: 'center' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '32px',
            sm: '32px',
            md: '64px',
            lg: '64px',
            xl: '64px',
          }}
          fontWeight={700}
        >
          <div
            css={css(
              responsiveStyle('display', {
                xs: 'block',
                sm: 'block',
                md: 'block',
                lg: 'none',
                xl: 'none',
              }),
            )}
          >
            <div>
              Make Your <TransparentText value={'Music'} /> the
            </div>
            <div>
              Way <TransparentText value={'You'} /> Want it.
            </div>
          </div>

          <div
            css={css(
              responsiveStyle('display', {
                xs: 'none',
                sm: 'none',
                md: 'none',
                lg: 'block',
                xl: 'block',
              }),
            )}
          >
            <div>
              Make Your <TransparentText value={'Music'} /> the Way
            </div>
            <div>
              <TransparentText value={'You'} /> Want it.
            </div>
          </div>
        </TitleText>

        <div
          css={css({
            marginTop: '32px',
          })}
        />

        <TitleText
          textAlign={{ xs: 'center' }}
          background={TitleTextBackground.RED_TO_PURPLE}
          fontSize={{
            xs: '16px',
            sm: '16px',
            md: '24px',
            lg: '24px',
            xl: '24px',
          }}
          fontWeight={300}
        >
          <div>Recordless Studio allows you to create</div>
          <div>
            professional quality remixes with an{' '}
            <TransparentText value={'AI Assist.'} />
          </div>
        </TitleText>

        <div css={css(centerAlignStyles)}>
          <img
            css={css({
              maxWidth: '100%',
              padding: '32px 0',
              position: 'relative',
              zIndex: 10,
            })}
            src={screenshotPath}
            alt="screenshot"
          />
        </div>

        <div
          css={css(centerAlignStyles, buttonWrapperStyles, {
            marginTop: '10px',
          })}
        >
          <span>
            <Button
              contents={
                primaryAction === 'available' ? (
                  <DownloadNowButtonContents label="Download Now" />
                ) : null
              }
              buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
              onClick={onPrimaryAction}
            />
          </span>
        </div>

        <div
          css={css({
            position: 'absolute',
            left: '40%',
            bottom: '0',
            width: '50%',
            height: '50%',
            opacity: '0.4',
            background:
              'linear-gradient(149.16deg, #4512EA 10.99%, #1282EA 32.3%, #12EA5B 48%, #EFE75E 58.01%, #E07614 71.09%, #F31875 88.77%, #4512EA 102.86%)',
            filter: 'blur(100px)',
            borderRadius: '383.217px',
            transform: 'matrix(0.97, -0.26, -0.26, -0.97, 0, 0)',
            zIndex: -10,
          })}
        />
      </Section>
    </Page>
  );
}
