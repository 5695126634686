import { ErrorDescriptor } from '@inkibra/error-base';
import { Result, err, ok } from 'neverthrow';
import { typeidUnboxed } from 'typeid-js';
import { tags } from 'typia';

// TODO: make __brand permanent when typia supports it, you maybe able to do this with a CustomValidator in typia...
// TODO: support https://github.com/samchon/typia/issues/911#issuecomment-2296620828
export type Brand<B extends string> = string & {
  readonly __brand?: B;
};

export type BrandRegex<B extends string> = tags.Pattern<`^${B}_[a-z0-9]{26}$`>;
export namespace Brand {
  export function createId2<B extends string>(type: B): Brand<B> {
    return typeidUnboxed(type) as string as Brand<B>;
  }
}
export type UNHANDLED_VALIDATION_FAILURE = ErrorDescriptor<
  'UNHANDLED_VALIDATION_FAILURE',
  'Unhandled validation failure',
  {}
>;
export abstract class CacheableObjectUtil<
  Type extends string,
  Id extends Brand<Type>,
  TData extends { id: Id; type: Type },
  CreateData,
  CreateFailures,
  ModificationData,
  DataValidationFailures,
  Options = {},
> {
  readonly options: Options;
  abstract readonly type: Type;
  constructor(options: Options) {
    this.options = options;
  }
  public createId2() {
    return typeidUnboxed(this.type) as string as Id;
  }
  public static stripId<Type extends string>(
    brandedId: Brand<Type>,
    type: Type,
  ) {
    const splitId = brandedId.split('_');
    const parsedType = splitId[0];
    const parsedId = splitId[1];
    if (parsedType !== type || !parsedId) {
      return err(false);
    }
    return ok(parsedId);
  }

  // we should include a validate that checks the version
  public abstract is(data: unknown): data is TData;
  public validate(
    data: TData,
  ): Result<true, DataValidationFailures | UNHANDLED_VALIDATION_FAILURE> {
    const validates = this.is(data);

    if (!validates) {
      const validationErrors = this.getValidationErrors(data);
      if (validationErrors) {
        return err(validationErrors);
      }
      return err({
        code: 'UNHANDLED_VALIDATION_FAILURE',
        message: 'Unhandled validation failure',
        data,
      });
    }
    return ok(true);
  }
  protected abstract getValidationErrors(
    data: TData,
  ): DataValidationFailures | false;
  protected abstract fromCreateData(
    data: CreateData,
  ): Result<Readonly<TData>, CreateFailures>;
  public create(
    data: CreateData,
  ): Result<
    Readonly<TData>,
    CreateFailures | DataValidationFailures | UNHANDLED_VALIDATION_FAILURE
  > {
    const created = this.fromCreateData(data);
    if (created.isErr()) {
      return created;
    }
    const validationResult = this.validate(created.value);
    if (validationResult.isErr()) {
      return err(validationResult.error);
    }
    return ok(created.value);
  }
  protected modify(
    data: Readonly<TData>,
    modificationData: ModificationData, // Make sure modification data doesn't include id or type
  ): Result<
    Readonly<TData>,
    DataValidationFailures | UNHANDLED_VALIDATION_FAILURE
  > {
    const modified = {
      ...data,
      ...modificationData,
      modified: new Date().toISOString(),
    };
    const validationResult = this.validate(modified);
    if (validationResult.isErr()) {
      return err(validationResult.error);
    }
    return ok(modified);
  }
}
