/** @jsxImportSource @emotion/react */

import {
  CacheContext,
  HookEffectContext,
  MimeType,
  TypedBlob,
  TypedFormData,
} from '@inkibra/api-base';
import { Brand } from '@inkibra/observable-cache';
import { ArrangementOutputInfo } from '@inkibra/recordless.music-engine';
import { err } from 'neverthrow';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  InkibraRecordlessLibraryApiFetcherRegistry,
  LockRecordlessArrangementAndUploadSources,
} from '../api';
import {
  useArrangement,
  useLibrarySong,
  useLibrarySongPath,
  useLibrarySongWaveform,
} from '../hooks';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import {
  RecordlessPlaybackDisplay,
  RecordlessTransitionOverview,
  RecordlessTransitionReview,
} from './';
import { RecordlessArrangementComposer } from './arrangement-composer';
import { RecordlessTransitionDrafting } from './transition-drafting';

export type ArrangementProposalEditingFlowProps = {
  arrangementId: InkibraRecordlessLibraryArrangementType['id'];
  onArrangementLock: () => void;
  onCancelEditing: () => void;
};

export function ArrangementProposalEditingFlow(
  props: ArrangementProposalEditingFlowProps,
) {
  const cache = useContext(CacheContext)();
  const hookEffectContext = new HookEffectContext();
  const arrangement = hookEffectContext.addEffect(
    useArrangement(props.arrangementId),
  );
  const latestSong = hookEffectContext.addEffect(
    useLibrarySong(arrangement?.librarySong.id || ''),
  );
  hookEffectContext.runEffects(cache);
  if (!arrangement || !latestSong) {
    return null;
  }
  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});
  const arrangementWithUpdatedLibrarySongResult = arrangementUtil.updateSong(
    arrangement,
    latestSong,
  );
  if (arrangementWithUpdatedLibrarySongResult.isErr()) {
    console.error(
      'Failed to update arrangement with latest song',
      arrangementWithUpdatedLibrarySongResult.error,
    );
    return null;
  }
  return (
    <InnerArrangementProposalEditingFlow
      arrangement={arrangementWithUpdatedLibrarySongResult.value}
      onArrangementLock={props.onArrangementLock}
      onCancelEditing={props.onCancelEditing}
    />
  );
}

// TODO: arrangement composer updates
// TODO: sections that don't have good transitions above the cutoff should be highlighted or italicized

// TODO: allow negative reviews of transitions
// TODO: consider allowing arrangement editing and saving without locking

type InnerArrangementProposalEditingFlowProps = {
  arrangement: InkibraRecordlessLibraryArrangementType;
  onArrangementLock: () => void;
  onCancelEditing: () => void;
};

function InnerArrangementProposalEditingFlow(
  props: InnerArrangementProposalEditingFlowProps,
) {
  const cache = useContext(CacheContext)();
  const originalSongAudioRef = useRef<HTMLAudioElement>(null);
  const arrangementAudioRef = useRef<HTMLAudioElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [songUrl, setSongUrl] = useState<string | undefined>(undefined);
  const [arrangementUrl, setArrangementUrl] = useState<string | undefined>(
    undefined,
  );
  const [
    maybeSelectedSectionArrangementId,
    setMaybeSelectedSectionArrangementId,
  ] =
    useState<
      InkibraRecordlessLibraryArrangementType.SectionArrangement['id']
    >();
  const [reviewableTransitionId, setReviewableTransitionId] =
    useState<InkibraRecordlessLibrarySongType.Transition['id']>();
  const [draftingTransition, setDraftingTransition] = useState<boolean>();

  const hookEffectContext = new HookEffectContext();
  const song = props.arrangement.librarySong;
  const songPath = hookEffectContext.addEffect(useLibrarySongPath(song.id));
  const songWaveform = hookEffectContext.addEffect(
    useLibrarySongWaveform(song.id, '8'),
  );
  hookEffectContext.runEffects(cache);

  const [
    maybeComputedArrangementOutputInfo,
    setMaybeComputedArrangementOutputInfo,
  ] = useState<ArrangementOutputInfo | undefined>(undefined);
  const [arrangementWaveform, setArrangementWaveform] = useState<number[]>([]);

  const [arrangement, setArrangement] =
    useState<InkibraRecordlessLibraryArrangementType>(props.arrangement);

  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});

  useEffect(
    function resetStateOnNewArrangement() {
      setMaybeComputedArrangementOutputInfo(undefined);
      setArrangementUrl(undefined);
      setDraftingTransition(false);
      setReviewableTransitionId(undefined);
    },
    [props.arrangement],
  );

  // Fetch song
  useEffect(() => {
    // We do this because it forces us to actually download the song
    // TODO: we should just make this a util function or move the behavior to the hook
    if (songPath) {
      fetch(songPath).then(async (response) => {
        const song = await response.blob();
        const url = URL.createObjectURL(song);
        setSongUrl(url);
      });
    }
    return () => {
      if (songUrl) {
        URL.revokeObjectURL(songUrl);
      }
    };
  }, [songPath]);

  if (!song || !songPath || !arrangement) {
    return null;
  }

  const sectionArrangementsWithMissingTransitions =
    arrangementUtil.findAllSectionArrangementsWithMissingTransitions(
      arrangement,
    );
  if (sectionArrangementsWithMissingTransitions.isErr()) {
    console.error(
      'Failed to find section arrangements with missing transitions',
      sectionArrangementsWithMissingTransitions.error,
    );
    return null;
  }

  console.log(
    'sectionArrangementsWithMissingTransitions',
    sectionArrangementsWithMissingTransitions,
  );

  const reviewableSectionArrangements =
    arrangementUtil.findAllReviewableSectionArrangements(arrangement);
  if (reviewableSectionArrangements.isErr()) {
    console.error(
      'Failed to find reviewable transitions',
      reviewableSectionArrangements.error,
    );
    return null;
  }
  console.log('reviewableSectionArrangements', reviewableSectionArrangements);
  const problematicSectionArrangements = Array.from(
    new Set([
      ...reviewableSectionArrangements.value.map(
        (sectionArrangement) => sectionArrangement.id,
      ),
      ...sectionArrangementsWithMissingTransitions.value.map(
        (sectionArrangement) => sectionArrangement.id,
      ),
    ]),
  );

  console.log(
    'TODO: pass problematicSectionArrangements to composer for italics or highlights',
    problematicSectionArrangements,
  );

  let maybeSelectedSectionArrangementWithSourceAndTargetSection:
    | {
        sourceSectionId: InkibraRecordlessLibrarySongType.Section['id'];
        targetSectionId: InkibraRecordlessLibrarySongType.Section['id'];
        sectionArrangementId: InkibraRecordlessLibraryArrangementType.SectionArrangement['id'];
        isProblematic: boolean;
      }
    | undefined = undefined;

  if (maybeSelectedSectionArrangementId) {
    const selectedSectionArrangementResult =
      arrangementUtil.getSectionArrangementById(
        arrangement,
        maybeSelectedSectionArrangementId,
      );
    if (selectedSectionArrangementResult.isErr()) {
      console.error(
        'Failed to get selected section arrangement',
        selectedSectionArrangementResult.error,
      );
      return null;
    }
    const sectionArrangementAfterSelection =
      arrangementUtil.getSectionArrangementAfter(
        arrangement,
        selectedSectionArrangementResult.value.id,
      );

    if (sectionArrangementAfterSelection.isErr()) {
      console.warn(
        'Failed to get section arrangement after selection',
        sectionArrangementAfterSelection.error,
      );
      // TODO: add a failure for when this is expected because the section arrangement is the last one
      console.warn('Setting maybeSelectedSectionArrangementId to undefined');
      setMaybeSelectedSectionArrangementId(undefined);
      setDraftingTransition(false);
      setReviewableTransitionId(undefined);
    } else {
      maybeSelectedSectionArrangementWithSourceAndTargetSection = {
        sourceSectionId: selectedSectionArrangementResult.value.sectionFieldId,
        targetSectionId: sectionArrangementAfterSelection.value.sectionFieldId,
        sectionArrangementId: maybeSelectedSectionArrangementId,
        isProblematic: problematicSectionArrangements.includes(
          maybeSelectedSectionArrangementId,
        ),
      };
    }
  }

  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
  const songAdditionalTransitionFromArrangementResult = songUtil.addTransitions(
    song,
    Object.values(arrangement.proposedTransitionMap),
  );
  if (songAdditionalTransitionFromArrangementResult.isErr()) {
    console.error(
      'Failed to add transitions from arrangement',
      songAdditionalTransitionFromArrangementResult.error,
    );
    return null;
  }

  const defaultArrangementInfoResult = songUtil.computeOrderedSectionInfo(song);
  if (defaultArrangementInfoResult.isErr()) {
    console.error(
      'Failed to get default arrangement info',
      defaultArrangementInfoResult.error,
    );
    return null;
  }

  return (
    <div>
      <h2>Original Song</h2>
      <audio ref={originalSongAudioRef} src={songUrl} controls />

      <div css={{ width: 'calc(100vw - 10px)' }} ref={containerRef}>
        <RecordlessPlaybackDisplay
          windowedWaveformData={songWaveform}
          duration={song.duration}
          relativeSections={defaultArrangementInfoResult.value}
          audioPlayerRef={originalSongAudioRef}
          relativeZero={0}
          containerRef={containerRef}
        />
        <input
          value={arrangement.name}
          onChange={(e) => {
            const updateNameResult = arrangementUtil.updateName(
              arrangement,
              e.target.value,
            );
            if (updateNameResult.isErr()) {
              console.error(
                'Failed to update arrangement name',
                updateNameResult.error,
              );
              return;
            }
            setArrangement(updateNameResult.value);
          }}
        />
        <RecordlessArrangementComposer
          orderedSectionsInfo={song.orderedSectionFields}
          newArrangement={arrangement}
          maybeSelectedSectionArrangementId={maybeSelectedSectionArrangementId}
          arrangementUpdate={(updatedArrangement): void => {
            console.log('updatedArrangement', updatedArrangement);
            console.log('newManager', updatedArrangement);
            setArrangementUrl(undefined);
            setArrangementWaveform([]);
            setDraftingTransition(false);
            setReviewableTransitionId(undefined);
            setArrangement(updatedArrangement);
          }}
        />
        {/* List of Arrangement Section for Selection */}
        {arrangement.sectionArrangements.map((sectionArrangement) => {
          const sourceSectionResult = songUtil.getSectionById(
            song,
            sectionArrangement.sectionFieldId,
          );
          if (sourceSectionResult.isErr()) {
            console.error(
              'Failed to get source section',
              sourceSectionResult.error,
            );
            return null;
          }
          const nextSectionArrangementResult =
            arrangementUtil.getSectionArrangementAfter(
              arrangement,
              sectionArrangement.id,
            );
          if (nextSectionArrangementResult.isErr()) {
            console.warn(
              'Failed to get next section',
              nextSectionArrangementResult.error,
            );
            return null;
          }
          const targetSectionResult = songUtil.getSectionById(
            song,
            nextSectionArrangementResult.value.sectionFieldId,
          );
          if (targetSectionResult.isErr()) {
            console.error(
              'Failed to get target section',
              targetSectionResult.error,
            );
            return null;
          }

          const isProblematic = problematicSectionArrangements.includes(
            sectionArrangement.id,
          );

          return (
            <div key={sectionArrangement.id}>
              <button
                onClick={() => {
                  setMaybeSelectedSectionArrangementId(sectionArrangement.id);
                }}
              >
                {sourceSectionResult.value.name} -{' '}
                {targetSectionResult.value.name} {isProblematic && '⚠️'}
              </button>
            </div>
          );
        })}
        {maybeSelectedSectionArrangementWithSourceAndTargetSection && (
          <RecordlessTransitionOverview
            song={songAdditionalTransitionFromArrangementResult.value}
            onTransitionClick={(transitionId: Brand<'nkrtrn'>) => {
              if (!maybeSelectedSectionArrangementWithSourceAndTargetSection) {
                console.error(
                  'Unexpected no source and target section for overview',
                );
                throw new Error('Unexpected no source and target section');
              }
              const transitionResult = songUtil.getTransitionById(
                songAdditionalTransitionFromArrangementResult.value,
                transitionId,
              );
              if (transitionResult.isErr()) {
                console.error(
                  'Failed to get transition',
                  transitionResult.error,
                );
                return;
              }
              setReviewableTransitionId(transitionId);
              // TODO: work on making it more clear what transitions are good or bad and giving feedback
              // if (transitionResult.value.score < 0.5) {
              //  review the transition
              //   setReviewableTransitionId(transitionId);
              // } else {
              //   // approve the transition
              //   const arrangementWithApprovedTransitionResult =
              //     arrangementUtil.updateSectionArrangementTransition(
              //       arrangement,
              //       maybeSelectedSectionArrangementWithSourceAndTargetSection.sectionArrangementId,
              //       transitionId,
              //     );
              //   if (arrangementWithApprovedTransitionResult.isErr()) {
              //     console.error(
              //       'Failed to approve transition',
              //       arrangementWithApprovedTransitionResult.error,
              //     );
              //     return;
              //   }
              //   setArrangement(arrangementWithApprovedTransitionResult.value);
              // }
            }}
            waveformData={songWaveform}
            filterBySourceSectionId={
              maybeSelectedSectionArrangementWithSourceAndTargetSection.sourceSectionId
            }
            filterByTargetSectionId={
              maybeSelectedSectionArrangementWithSourceAndTargetSection.targetSectionId
            }
          />
        )}
        {maybeSelectedSectionArrangementWithSourceAndTargetSection?.isProblematic &&
          !draftingTransition && (
            <button
              onClick={() => {
                if (
                  !maybeSelectedSectionArrangementWithSourceAndTargetSection
                ) {
                  console.error(
                    'Unexpected no source and target section for overview',
                  );
                  return;
                }
                console.log(
                  'should propose transition for section arrangement',
                );
                const draftTransitionResult = songUtil.draftTransition(
                  song,
                  maybeSelectedSectionArrangementWithSourceAndTargetSection.sourceSectionId,
                  maybeSelectedSectionArrangementWithSourceAndTargetSection.targetSectionId,
                );
                if (draftTransitionResult.isErr()) {
                  console.error(
                    'Failed to draft transition',
                    draftTransitionResult.error,
                  );
                  return;
                }
                setDraftingTransition(true);
                setReviewableTransitionId(undefined);
              }}
            >
              Propose Transition for Section Arrangement
            </button>
          )}
        {maybeSelectedSectionArrangementWithSourceAndTargetSection &&
          draftingTransition && (
            <RecordlessTransitionDrafting
              song={song}
              songWaveform={songWaveform}
              sourceSectionId={
                maybeSelectedSectionArrangementWithSourceAndTargetSection.sourceSectionId
              }
              targetSectionId={
                maybeSelectedSectionArrangementWithSourceAndTargetSection.targetSectionId
              }
              proposeTransition={(proposedTransition) => {
                if (
                  maybeSelectedSectionArrangementWithSourceAndTargetSection ===
                  undefined
                ) {
                  return;
                }

                const updatedArrangementResult = arrangementUtil
                  .proposeTransition(arrangement, proposedTransition)
                  .andThen((updatedArrangement) => {
                    if (
                      maybeSelectedSectionArrangementWithSourceAndTargetSection ===
                      undefined
                    ) {
                      return err('Unexpected no source and target section');
                    }
                    return arrangementUtil.updateSectionArrangementTransition(
                      updatedArrangement,
                      maybeSelectedSectionArrangementWithSourceAndTargetSection.sectionArrangementId,
                      proposedTransition.id,
                    );
                  });
                if (updatedArrangementResult.isErr()) {
                  console.error(
                    'Failed to propose transition',
                    updatedArrangementResult.error,
                  );
                  return;
                }
                setArrangement(updatedArrangementResult.value);
                setDraftingTransition(false);
                setReviewableTransitionId(undefined);
              }}
              cancel={() => {
                setDraftingTransition(false);
                setReviewableTransitionId(undefined);
              }}
              pixelsPerSecond={15}
            />
          )}
        {maybeSelectedSectionArrangementWithSourceAndTargetSection &&
          reviewableTransitionId && (
            <RecordlessTransitionReview
              song={songAdditionalTransitionFromArrangementResult.value}
              songWaveform={songWaveform}
              approveTransition={() => {
                if (
                  maybeSelectedSectionArrangementWithSourceAndTargetSection ===
                    undefined ||
                  reviewableTransitionId === undefined
                ) {
                  throw new Error(
                    'Unexpected no source and target section or transition',
                  );
                }
                const arrangementWithApprovedTransitionResult = arrangementUtil
                  .updateSectionArrangementTransition(
                    arrangement,
                    maybeSelectedSectionArrangementWithSourceAndTargetSection?.sectionArrangementId,
                    reviewableTransitionId,
                  )
                  .andThen((updatedArrangement) => {
                    return arrangementUtil.approveTransition(
                      updatedArrangement,
                      reviewableTransitionId,
                    );
                  });
                if (arrangementWithApprovedTransitionResult.isErr()) {
                  console.error(
                    'Failed to approve transition',
                    arrangementWithApprovedTransitionResult.error,
                  );
                  return;
                }
                setArrangementUrl(undefined);
                setArrangementWaveform([]);
                setArrangement(arrangementWithApprovedTransitionResult.value);
                setDraftingTransition(false);
                setReviewableTransitionId(undefined);
              }}
              pixelsPerSecond={15}
              reviewableTransitionId={reviewableTransitionId}
            />
          )}
        {problematicSectionArrangements.length === 0 && (
          <>
            <h2>Arrangement</h2>
            <audio ref={arrangementAudioRef} controls />
            {maybeComputedArrangementOutputInfo && (
              <RecordlessPlaybackDisplay
                duration={maybeComputedArrangementOutputInfo.duration}
                windowedWaveformData={arrangementWaveform}
                relativeSections={maybeComputedArrangementOutputInfo.annotations.map(
                  (annotation) => ({
                    startTime: annotation.startsAt,
                    name: annotation.metadata,
                  }),
                )}
                audioPlayerRef={arrangementAudioRef}
                relativeZero={0}
                containerRef={containerRef}
              />
            )}
            <button
              onClick={async () => {
                if (arrangementAudioRef.current) {
                  const sourcesResult =
                    await InkibraRecordlessLibraryApiFetcherRegistry.get(
                      'createInkibraRecordlessArrangementSources',
                    ).fn({
                      body: arrangement,
                      files: undefined,
                      pathParams: {},
                      pathQuery: {},
                    });
                  if (sourcesResult.type === 'Err') {
                    console.error(
                      'Failed to create arrangement sources',
                      sourcesResult.error,
                    );
                    throw new Error('Failed to create arrangement sources');
                  }
                  const sources = sourcesResult.value;
                  arrangementAudioRef.current.src = sources.locator.url;
                  setArrangementWaveform(sources.waveform);
                  console.log(
                    'setting arrangementOutputInfo',
                    sources.arrangementOutputInfo,
                  );
                  setMaybeComputedArrangementOutputInfo(
                    sources.arrangementOutputInfo,
                  );
                  setArrangementUrl(sources.locator.url);
                  setDraftingTransition(false);
                  setReviewableTransitionId(undefined);
                  if (arrangementAudioRef.current) {
                    await arrangementAudioRef.current.play();
                  }
                }
              }}
            >
              Preview Arrangement
            </button>
            <button
              disabled={!arrangementUrl || !maybeComputedArrangementOutputInfo}
              onClick={async () => {
                if (!arrangementUrl) {
                  console.warn('No arrangement url');
                  return;
                }
                if (!maybeComputedArrangementOutputInfo) {
                  console.warn('No arrangement output info');
                  return;
                }

                const formData = new TypedFormData(
                  LockRecordlessArrangementAndUploadSources.Route
                    .fileInputDescription,
                );
                const audio = await (await fetch(arrangementUrl)).blob();
                formData.set(
                  'audio',
                  TypedBlob.fromBlob(audio, MimeType.AUDIO_MP4),
                );

                const res =
                  await InkibraRecordlessLibraryApiFetcherRegistry.get(
                    'lockRecordlessArrangementAndUploadSources',
                  ).fn({
                    body: {
                      finalizedArrangement: {
                        proposedTransitionMap:
                          arrangement.proposedTransitionMap,
                        approvedTransitionSet:
                          arrangement.approvedTransitionSet,
                        sectionArrangements: arrangement.sectionArrangements,
                        computedArrangementOutputInfo:
                          maybeComputedArrangementOutputInfo,
                      },
                      waveform: arrangementWaveform,
                    },
                    files: formData,
                    pathParams: { arrangementId: arrangement.id },
                    pathQuery: {},
                  });
                console.log('LockRecordlessArrangementAndUploadSources', res);
                cache.input.next(res.value);
                props.onArrangementLock();
              }}
            >
              Lock Arrangement
            </button>
          </>
        )}
        <button onClick={props.onCancelEditing}>
          Cancel Arrangement Creation
        </button>
      </div>
    </div>
  );
}
