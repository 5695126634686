import { HookFunctionReturnType } from '@inkibra/api-base';
import { useState } from 'react';
import { InkibraRecordlessTempoApiFetcherRegistry } from './api';
import { InkibraRecordlessWorkoutType } from './type';

export function useWorkouts(
  runSetup = true,
): HookFunctionReturnType<InkibraRecordlessWorkoutType[]> {
  const [data, setData] = useState<InkibraRecordlessWorkoutType[]>([]);
  return {
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data)
          .filter(InkibraRecordlessWorkoutType.is)
          .sort(
            (a, b) =>
              new Date(a.created).getTime() - new Date(b.created).getTime(),
          ),
      );
    },
    setup: async (cache) => {
      if (runSetup) {
        const data = await InkibraRecordlessTempoApiFetcherRegistry.get(
          'getInkibraRecordlessWorkouts',
        ).fn({
          body: undefined,
          pathParams: {},
          pathQuery: {},
          files: undefined,
        });

        setData(
          data.value.sort(
            (a, b) =>
              new Date(a.created).getTime() - new Date(b.created).getTime(),
          ),
        );

        cache.input.next(data.value);
      }
    },
  };
}

export function useAllWorkouts(
  runSetup = true,
): HookFunctionReturnType<InkibraRecordlessWorkoutType[]> {
  const [data, setData] = useState<InkibraRecordlessWorkoutType[]>([]);
  return {
    data,
    hookRunner: (data) => {
      setData(Object.values(data).filter(InkibraRecordlessWorkoutType.is));
    },
    setup: async (cache) => {
      if (runSetup) {
        const data = await InkibraRecordlessTempoApiFetcherRegistry.get(
          'getAllInkibraRecordlessWorkouts',
        ).fn({
          body: undefined,
          pathParams: {},
          pathQuery: {},
          files: undefined,
        });

        setData(data.value);

        cache.input.next(data.value);
      }
    },
  };
}

// TODO: would there be a good way from setting an initial value for the workout?
export function useWorkout(
  id: string,
): HookFunctionReturnType<InkibraRecordlessWorkoutType | undefined> {
  const [data, setData] = useState<InkibraRecordlessWorkoutType | undefined>();
  return {
    data,
    constraint: id,
    hookRunner: (data) => {
      const workout = data[id];

      if (InkibraRecordlessWorkoutType.is(workout)) {
        setData(workout);
      }
    },
    setup: async (cache) => {
      const data = await InkibraRecordlessTempoApiFetcherRegistry.get(
        'getInkibraRecordlessWorkout',
      ).fn({
        body: undefined,
        pathParams: { id },
        pathQuery: {},
        files: undefined,
      });

      setData(data.value);

      cache.input.next(data.value);
    },
  };
}
