/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { PlayButton } from './icons/play-button';

const textStyles = css({
  fontFamily: "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '19px',
  letterSpacing: '-0.25px',
  color: 'white',
  userSelect: 'none',
  pointerEvents: 'none',
  margin: 0,
});

const songInfoGridLayout = css({
  display: 'grid',
  gridTemplateColumns: '100px 150px',
  gridTemplateRows: '20px 20px 20px',
  gridTemplateAreas: `
    "artwork song-title"
    "artwork song-artist"
    "artwork song-date"
  `,
});

const songInfoArtworkStyles = css({
  gridArea: 'artwork',
  width: '90px',
  height: '90px',
  borderRadius: '8px',
  pointerEvents: 'none',
  userSelect: 'none',
});

const songTitleTextStyles = css(textStyles, {
  gridArea: 'song-title',
  letterSpacing: '1.25px',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '18px',
});

const songArtistTextStyles = css(textStyles, {
  gridArea: 'song-artist',
  fontWeight: 400,
  letterSpacing: '1.25px',
  fontSize: '12px',
  lineHeight: '18px',
  color: 'rgba(211, 211, 252, 0.5)',
});

const songDateTextStyles = css(textStyles, {
  gridArea: 'song-date',
  fontWeight: 400,
  letterSpacing: '1.25px',
  fontSize: '12px',
  lineHeight: '18px',
  color: 'rgba(211, 211, 252, 0.5)',
});

export type SongInformationCardProps = {
  artworkPath: string;
  artist: string;
  title: string;
  librarySongCatalogStatus?: string;
  author?: string;
  addedToLibraryDate: Date;
  onPlay: () => void;
};

export function SongInformationCard(props: SongInformationCardProps) {
  return (
    <div css={songInfoGridLayout}>
      <img css={songInfoArtworkStyles} src={props.artworkPath} />
      <button
        css={{
          gridArea: 'artwork',
          width: '70px',
          height: '70px',
          cursor: 'pointer',
          background: 'none',
          border: 'none',
          transform: 'scale(0.3)',
          opacity: 1,
        }}
        onClick={props.onPlay}
      >
        <PlayButton
          style={{
            width: '70px',
            height: '70px',
            position: 'absolute',
            top: '0',
            left: '0',
          }}
        />
      </button>
      <h2 css={songArtistTextStyles}>{props.artist}</h2>
      <h2 css={songTitleTextStyles}>{props.title}</h2>
      <h3 css={songDateTextStyles}>
        {props.addedToLibraryDate.toLocaleDateString()}
      </h3>
    </div>
  );
}
