/** @jsxImportSource @emotion/react */

import { AsyncPlayable, LoadingState } from '@inkibra/recordless.library-api';
import { animated, useSpring } from '@react-spring/web';
import {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { CreateMixForWorkout } from '../api';
import { Back } from '../components/icons/back';
import { SongPlayer } from '../components/song-player';
import { InkibraRecordlessWorkoutType } from '../type';
import { Button } from './button';
import { GenreSelector } from './genre-selector';
import { PlayableIntervalList } from './playable-interval-list';
import { PlayableIntervalSingular } from './playable-interval-singular';
import {
  WorkoutSessionAnnotation,
  WorkoutSessionContext,
} from './workout-session-context';

export type NowPlayingTrayController = {
  openTrayDrawer: () => void;
  requestSessionInfo: (workout: InkibraRecordlessWorkoutType) => void;
};

export type NowPlayingTrayProps = {
  hidden: boolean;
};

export const NowPlayingTray = forwardRef(
  (props: NowPlayingTrayProps, ref: Ref<NowPlayingTrayController>) => {
    const [
      isRequestingSessionInfoForWorkout,
      setIsRequestingSessionInfoForWorkout,
    ] = useState<InkibraRecordlessWorkoutType | undefined>(undefined);
    const [animationStyleProps, animationApi] = useSpring(() => ({
      from: { y: window.innerHeight, opacity: 0 },
      config: { tension: 300, friction: 30 },
    }));

    const trayOpen = useRef(false);
    const toggleTray = () => {
      if (trayOpen.current) {
        trayOpen.current = false;
        document.body.style.overflow = '';
        animationApi.start({
          to: { y: window.innerHeight, opacity: 0 },
          onRest: () => {
            if (isRequestingSessionInfoForWorkout !== undefined) {
              setIsRequestingSessionInfoForWorkout(undefined);
            }
          },
        });
      } else {
        trayOpen.current = true;
        document.body.style.overflow = 'hidden';
        animationApi.start({
          to: { y: 0, opacity: 1 },
        });
      }
    };

    useImperativeHandle(ref, () => ({
      openTrayDrawer: () => {
        if (trayOpen.current === false) {
          toggleTray();
        }
      },
      requestSessionInfo: (workout: InkibraRecordlessWorkoutType) => {
        setIsRequestingSessionInfoForWorkout(workout);
        if (trayOpen.current === false) {
          toggleTray();
        }
      },
    }));

    return (
      <div
        css={{
          display:
            props.hidden && isRequestingSessionInfoForWorkout === undefined
              ? 'none'
              : 'block',
          zIndex: 1000,
        }}
      >
        <animated.div
          css={{
            label: 'animated-tray',
            maxWidth: '1000px',
            margin: 'auto',
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            backgroundColor: '#08031D',
            zIndex: 1000,
            paddingTop: 'env(safe-area-inset-top)',
            paddingBottom: '100px',
          }}
          style={{
            y: animationStyleProps.y,
            opacity: animationStyleProps.opacity,
          }}
        >
          <div
            css={{
              label: 'animated-tray-app-bar',
              maxWidth: '1000px',
              display: 'grid',
              position: 'absolute',
              width: '100%',
              gridTemplateColumns: 'min-content min-content',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '8px',
              zIndex: 10000,
            }}
          >
            <div
              onClick={() => {
                toggleTray();
              }}
            >
              <Back />
            </div>
          </div>
          <NowPlayingTraySessionInfoProvider
            isRequestingSessionInfoForWorkout={
              isRequestingSessionInfoForWorkout
            }
            onClose={() => {
              setIsRequestingSessionInfoForWorkout(undefined);
            }}
          />

          <NowPlayingTrayPlaylist
            hidden={isRequestingSessionInfoForWorkout !== undefined}
          />
        </animated.div>
        <div
          css={{
            label: 'song-player-tray',
            position: 'fixed',
            padding: '8px 4px 8px',
            bottom: '0',
            zIndex: 1000,
            display: 'grid',
            gridTemplateColumns: 'min(100vw, 1000px)',
            justifyContent: 'center',
            width: '100vw',
          }}
        >
          <SongPlayer
            onToggleTrayDrawer={() => {
              toggleTray();
            }}
          />
        </div>
      </div>
    );
  },
);

type NowPlayingTrayPlaylistProps = {
  hidden: boolean;
};
const NowPlayingTrayPlaylist = ({ hidden }: NowPlayingTrayPlaylistProps) => {
  const [asyncPlayablesToDisplay, setAsyncPlayablesToDisplay] = useState<
    AsyncPlayable<WorkoutSessionAnnotation>[]
  >([]);
  const workoutSession = useContext(WorkoutSessionContext)();

  useEffect(() => {
    if (!workoutSession.playback.current) {
      return;
    }
    const callback = workoutSession.playback.current.subscribeToQueueState(
      (queueState) => {
        const _asyncPlayablesToDisplay: AsyncPlayable<WorkoutSessionAnnotation>[] =
          [];
        if (queueState.nowPlaying) {
          _asyncPlayablesToDisplay.push(queueState.nowPlaying);
        }
        _asyncPlayablesToDisplay.push(...queueState.queue);
        console.log('----- queueState -----', queueState);
        setAsyncPlayablesToDisplay(_asyncPlayablesToDisplay);
      },
    );

    return () => {
      workoutSession.playback.current?.unsubscribeFromQueueState(callback);
    };
  }, [workoutSession.playback.current]);

  if (hidden || !workoutSession.isWorkoutSessionActive) {
    return null;
  }

  return (
    <>
      <div
        css={{
          width: '100%',
          overflow: 'auto',
          color: '#F2F2F7',
          marginBottom: '25px',
        }}
      >
        <div
          css={{
            width: '100%',
            margin: '16px 0',
            fontWeight: '900',
            fontSize: '24px',
            textOverflow: 'ellipsis',
            maxHeight: '2em',
            textAlign: 'center',
            lineHeight: '1em',
            textTransform: 'capitalize',
          }}
        >
          YOUR WORKOUT MIX
        </div>
      </div>
      {asyncPlayablesToDisplay.map((asyncPlayable) => {
        const maybePlayable =
          asyncPlayable.state === LoadingState.LOADED
            ? asyncPlayable.playable
            : undefined;

        if (asyncPlayable.annotation.type === 'preworkout') {
          const maybeLibrarySong =
            maybePlayable?.type === 'nkrsong' ? maybePlayable : undefined;
          return (
            <PlayableIntervalSingular
              key={asyncPlayable.entryId}
              name="Preworkout"
              playableEntryId={asyncPlayable.entryId}
              librarySong={maybeLibrarySong}
            />
          );
        }

        // This is useful for debugging...
        // const maybeMix =
        //   maybePlayable?.type === 'nkrmix' ? maybePlayable : undefined;

        return (
          <PlayableIntervalList
            key={asyncPlayable.entryId}
            playableEntryId={asyncPlayable.entryId}
            workout={asyncPlayable.annotation.workout}
            // mix={maybeMix}
          />
        );
      })}
    </>
  );
};

type NowPlayingTraySessionInfoProviderProps = {
  isRequestingSessionInfoForWorkout?: InkibraRecordlessWorkoutType;
  onClose: () => void;
};

const NowPlayingTraySessionInfoProvider = ({
  isRequestingSessionInfoForWorkout,
  onClose,
}: NowPlayingTraySessionInfoProviderProps) => {
  const workoutSession = useContext(WorkoutSessionContext)();
  const [selectedGenres, setSelectedGenres] = useState<
    InkibraRecordlessWorkoutType.WorkoutGenre[]
  >([]);

  if (isRequestingSessionInfoForWorkout === undefined) {
    return null;
  }

  return (
    <div
      css={{
        label: 'now-playing-tray-session-info-provider',
        marginTop: '50px',
        width: '100%',
        background: '#08031D',
        borderRadius: '25px',
        padding: '25px',
        display: 'grid',
        gridTemplateRows: 'min-content min-content min-content',
        gap: '20px',
      }}
    >
      <div
        css={{
          label: 'about-session-header',
          fontWeight: '600',
          fontSize: '12px',
          color: '#FFFFFF',
          marginBottom: '8px',
        }}
      >
        START YOUR WORKOUT SESSION WITH <br />
        {isRequestingSessionInfoForWorkout.name.toUpperCase()}
      </div>
      <div
        css={{
          label: 'about-session-content',
          wordBreak: 'break-word',
          fontWeight: '300',
          fontSize: '12px',
          lineHeight: '150%',
          color: '#989898',
        }}
      >
        Choose the genres of music you'll enjoy during your workout. Then, start
        your workout session with or without a motivational trainer voice. After
        you start your workout session, you can queue up additional workouts.
        You can skip to a workout by tapping the green header after the workout
        has loaded.
      </div>

      <GenreSelector values={selectedGenres} onChange={setSelectedGenres} />

      <Button
        type="button"
        color="primary"
        contents="START SESSION"
        disabled={selectedGenres.length === 0}
        onClick={() => {
          if (isRequestingSessionInfoForWorkout) {
            workoutSession.queueWorkout(isRequestingSessionInfoForWorkout, {
              workoutGenres: selectedGenres,
              announcerMode:
                CreateMixForWorkout.AnnouncerMode.LIGHT_ANNOUNCEMENTS,
            });
            onClose();
          } else {
            throw new Error('No workout selected');
          }
        }}
      />

      <Button
        type="button"
        fillMode="outline"
        contents="START SESSION WITHOUT TRAINER"
        disabled={selectedGenres.length === 0}
        onClick={() => {
          if (isRequestingSessionInfoForWorkout) {
            workoutSession.queueWorkout(isRequestingSessionInfoForWorkout, {
              workoutGenres: selectedGenres,
              announcerMode: CreateMixForWorkout.AnnouncerMode.OFF,
            });
            onClose();
          } else {
            throw new Error('No workout selected');
          }
        }}
      />
    </div>
  );
};
