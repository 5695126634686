import { FetchProvider } from '@inkibra/api-base';
import { ObservableCache } from '@inkibra/observable-cache';
import { createContext, useContext } from 'react';

export class Connector<Config> {
  #config: Config | undefined;

  public static STORAGE_GROUP = 'inkibra';

  public static Context = createContext(new Connector());
  public static useContext() {
    return useContext(Connector.Context);
  }

  public readonly cache = new ObservableCache();
  public maybeFetchProvider?: FetchProvider;
  constructor(config: Config | undefined = undefined) {
    this.#config = config;
  }
  public get config() {
    return this.#config;
  }
}
export namespace Connector {
  export type ConnectionStorage = {
    authorization: undefined | string;
    segment: undefined | null | string;
  };
}
