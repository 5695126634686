/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { ControlledMenu, MenuItem, useMenuState } from '@szhsin/react-menu';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../components/button';
import { Hamburger } from '../components/icons/hamburger';
import { StatisticsDisplay } from '../components/statistics-display';
import { ValueSliderInput } from '../components/value-slider-input';
import { WorkoutSessionContext } from '../components/workout-session-context';
import { InkibraRecordlessWorkoutType } from '../type';
import { DistanceActivityStartRoute } from './routes';

const appBarStyles = css({
  label: 'app-bar',
  maxWidth: '1000px',
  display: 'grid',
  position: 'fixed',
  top: 0,
  width: '100%',
  gridTemplateColumns: 'min-content min-content',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingTop: 'calc(max(env(safe-area-inset-top), 8px))',
  zIndex: 100,
  backgroundColor: 'rgba(8, 3, 29, 0.8)',
  backdropFilter: 'blur(10px)',
});

const hamburgerStyles = css({
  display: 'grid',
  width: '36px',
  height: '36px',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
});

type UserSpeedSubscription = {
  subscribeToUserSpeed: (cb: (speedInMph: number) => void) => () => void;
};

type DistanceActivityPlaybackProps = {
  userSpeedSubscription?: UserSpeedSubscription;
};

const PLAN_INTERVAL_TIME_LOOKAHEAD = 30; // TODO: get this from the config...
export function DistanceActivityPlayback({
  userSpeedSubscription, // TODO: get this from the config...
}: DistanceActivityPlaybackProps) {
  const navigate = useNavigate();
  const workoutSession = useContext(WorkoutSessionContext)();

  // User State
  const [manualSpeedInMph, setManualSpeedInMph] = useState(0);
  const [currentSpeedInMph, setCurrentSpeedInMph] = useState(0);
  const [smoothedSpeedInMph, setSmoothedSpeedInMph] = useState(0);
  const [longTermSmoothedSpeedInMph, setLongTermSmoothedSpeedInMph] =
    useState(0);
  const [lastSpeedTime, setLastSpeedTime] = useState<number>();
  const [targetPaceSpeed, setTargetPaceSpeed] = useState<number>(); // The speed of the target pace
  const [targetEnergyAdjustment, setTargetEnergyAdjustment] = useState(0);

  // Constants
  const [targetEnergy, setTargetEnergy] = useState(7); // The target stable state energy of the workout
  const [shortTermTimeConstant, setShortTermTimeConstant] = useState(5); // For short term EMA seconds
  const [longTermTimeConstant, setLongTermTimeConstant] = useState(30); // For long term EMA seconds
  const [speedScaleFactor, setSpeedScaleFactor] = useState(2); // The scale factor for the speed of the workout

  useEffect(
    function monitorWorkoutSession() {
      const playbackStateSubscription =
        workoutSession.playback.current?.subscribeToPlaybackState(
          (playbackState) => {
            if (
              playbackState.nowPlaying?.playable.type !== 'nkrmix' ||
              ![
                InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_HIKING,
                InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_RUNNING,
                InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_JOGGING,
                InkibraRecordlessWorkoutType.WorkoutType.DISTANCE_WALKING,
              ].includes(
                playbackState.nowPlaying?.annotation.workout.workoutType,
              )
            ) {
              return;
            }
            const currentWorkout = playbackState.nowPlaying.annotation.workout;
            const intervalAfterPlanningLookahead =
              InkibraRecordlessWorkoutType.getIntervalAtTime(
                currentWorkout,
                playbackState.time + PLAN_INTERVAL_TIME_LOOKAHEAD,
              );
            if (intervalAfterPlanningLookahead) {
              return;
            }

            const newWorkout = {
              ...currentWorkout,
              intervals: [
                ...currentWorkout.intervals,
                {
                  name:
                    targetEnergyAdjustment > 0 ? 'Motivating' : 'Cooling Off',
                  comments: '',
                  energy: targetEnergy + targetEnergyAdjustment,
                  time: 30,
                },
              ],
            };
            console.info('Planned next interval', newWorkout);
            workoutSession.playback.current?.updateNowPlayingAnnotation({
              ...playbackState.nowPlaying?.annotation,
              workout: newWorkout,
            });
          },
        );
      return () => {
        workoutSession.playback.current?.unsubscribeFromPlaybackState(
          playbackStateSubscription,
        );
      };
    },
    [workoutSession.playback.current],
  );

  const updateUserSpeed = (speedInMph: number) => {
    const timestamp = Date.now();

    if (lastSpeedTime === undefined) {
      setSmoothedSpeedInMph(speedInMph);
    } else {
      const deltaTimeInSeconds = (timestamp - lastSpeedTime) / 1000;
      const alpha = 1 - Math.exp(-deltaTimeInSeconds / shortTermTimeConstant);
      const newSmoothedSpeedInMph =
        alpha * speedInMph + (1 - alpha) * smoothedSpeedInMph;
      setSmoothedSpeedInMph(newSmoothedSpeedInMph);
      const longTermAlpha =
        1 - Math.exp(-deltaTimeInSeconds / longTermTimeConstant);
      const newLongTermSmoothedSpeedInMph =
        longTermAlpha * speedInMph +
        (1 - longTermAlpha) * longTermSmoothedSpeedInMph;
      setLongTermSmoothedSpeedInMph(newLongTermSmoothedSpeedInMph);

      let deltaSpeed: number;
      let energyAdjustment: number;
      // Calculate the target energy
      if (targetPaceSpeed !== undefined) {
        deltaSpeed = targetPaceSpeed - newSmoothedSpeedInMph;
        energyAdjustment = Math.asinh(deltaSpeed * speedScaleFactor);
      } else {
        deltaSpeed = newLongTermSmoothedSpeedInMph - newSmoothedSpeedInMph;
        energyAdjustment = Math.asinh(-deltaSpeed * speedScaleFactor);
      }

      setTargetEnergyAdjustment(energyAdjustment);
      // console.log('updateUserSpeed', {
      //   speedInMph,
      //   timestamp,
      //   energyAdjustment,
      //   newLongTermSmoothedSpeedInMph,
      //   newSmoothedSpeedInMph,
      // });
    }
    setCurrentSpeedInMph(speedInMph);
    setLastSpeedTime(timestamp);
  };

  useEffect(
    function subscribeToUserSpeed() {
      // If we have a user speed subscription, then we need to subscribe to the user speed.
      if (userSpeedSubscription) {
        const unsubscribe =
          userSpeedSubscription.subscribeToUserSpeed(updateUserSpeed);
        return () => {
          unsubscribe();
        };
      }
      // If not then we need to look at the manually set speed and use that.
      const interval = setInterval(() => {
        updateUserSpeed(manualSpeedInMph);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    },
    [userSpeedSubscription, manualSpeedInMph],
  );

  const [contextMenuProps, toggleContextMenu] = useMenuState();
  const [contextMenuAnchor, setContextMenuAnchor] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  return (
    <div
      css={{
        label: 'distance-mode-container-styles',
        margin: 'auto',
        width: '100vw',
        display: 'grid',
        gap: '25px',
        paddingTop: 'calc(env(safe-area-inset-top) + 45px)',
        gridAutoRows: 'min-content',
        gridTemplateColumns: '1fr 1fr',
        alignContent: 'end',
      }}
    >
      <div css={appBarStyles}>
        <div
          css={[
            hamburgerStyles,
            contextMenuProps.state === 'open' && {
              background: 'rgba(0, 0, 0, 0.2)',
            },
          ]}
          onClick={(e) => {
            e.stopPropagation();
            toggleContextMenu();
            setContextMenuAnchor({ x: e.clientX, y: e.clientY });
          }}
        >
          <Hamburger />
          <ControlledMenu
            {...contextMenuProps}
            anchorPoint={contextMenuAnchor}
            onClose={() => {
              toggleContextMenu();
            }}
            direction="right"
          >
            <MenuItem
              onClick={() => {
                workoutSession.playback.current?.stop();
                navigate(DistanceActivityStartRoute.makeRouteLink({}));
              }}
            >
              Stop Workout
            </MenuItem>
          </ControlledMenu>
        </div>
      </div>
      <StatisticsDisplay
        title="SPEED"
        subtitle="The current speed of the workout in mph."
        name="speed"
        formatter="decimal"
        value={currentSpeedInMph}
        label="mph"
      />
      <StatisticsDisplay
        title="SMOOTHED SPEED"
        subtitle="The smoothed speed of the workout in mph."
        name="smoothed-speed"
        formatter="decimal"
        value={smoothedSpeedInMph}
        label="mph"
      />
      <StatisticsDisplay
        title="LONG TERM SMOOTHED SPEED"
        subtitle="The long term smoothed speed of the workout in mph."
        name="long-term-smoothed-speed"
        formatter="decimal"
        value={longTermSmoothedSpeedInMph}
        label="mph"
      />
      <StatisticsDisplay
        title="TARGET PACE"
        subtitle="The target pace of the workout in minutes per mile."
        name="target-pace"
        formatter="time-from-seconds"
        value={targetPaceSpeed ? 60 / targetPaceSpeed : 0}
        label="pace"
      />
      <StatisticsDisplay
        title="TARGET ENERGY ADJUSTMENT"
        subtitle="The target energy adjustment of the workout."
        name="target-energy-adjustment"
        formatter="decimal"
        value={targetEnergyAdjustment}
        label="energy"
      />
      <ValueSliderInput
        title="TARGET ENERGY"
        subtitle="The target energy of the workout."
        name="target-energy"
        min={0}
        max={10}
        stepSize={1}
        initialValue={targetEnergy}
        label="energy"
        formatter="decimal"
        onChange={setTargetEnergy}
        speed={1}
      />
      <ValueSliderInput
        title="SHORT TERM TIME CONSTANT"
        subtitle="The time constant for the short term EMA in seconds."
        name="short-term-time-constant"
        min={0}
        max={100}
        stepSize={0.1}
        initialValue={shortTermTimeConstant}
        label="seconds"
        formatter="decimal"
        onChange={setShortTermTimeConstant}
        speed={1}
      />
      <ValueSliderInput
        title="LONG TERM TIME CONSTANT"
        subtitle="The time constant for the long term EMA in seconds."
        name="long-term-time-constant"
        min={0}
        max={100}
        stepSize={0.1}
        initialValue={longTermTimeConstant}
        label="seconds"
        formatter="decimal"
        onChange={setLongTermTimeConstant}
        speed={1}
      />
      <ValueSliderInput
        title="SPEED SCALE FACTOR"
        subtitle="The scale factor for the speed of the workout."
        name="speed-scale-factor"
        min={0}
        max={10}
        stepSize={0.1}
        initialValue={speedScaleFactor}
        label="scale factor"
        formatter="decimal"
        onChange={setSpeedScaleFactor}
        speed={1}
      />
      <ValueSliderInput
        title="SPEED"
        subtitle="Choose the manually input speed of the workout in mph."
        name="speed"
        min={0}
        max={100}
        stepSize={0.1}
        initialValue={manualSpeedInMph}
        label="mph"
        formatter="decimal"
        onChange={setManualSpeedInMph}
        speed={1}
      />
      <Button
        type="button"
        color="primary"
        buttonSize="large"
        contents={targetPaceSpeed ? 'SET TARGET PACE' : 'CLEAR TARGET PACE'}
        onClick={() => {
          if (targetPaceSpeed) {
            setTargetPaceSpeed(undefined);
          } else {
            setTargetPaceSpeed(currentSpeedInMph);
          }
        }}
      />
    </div>
  );
}
