/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from '@emotion/react';

export type PauseButtonProps = {
  style: Interpolation<Theme>;
};

export function PauseButton({ style }: PauseButtonProps) {
  return (
    <svg
      width="29"
      height="39"
      viewBox="0 0 29 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ pointerEvents: 'none' }}
      css={style}
    >
      <path
        d="M3.32031 38.1797H8.66406C9.67969 38.1797 10.4531 37.9141 10.9844 37.3828C11.5156 36.8516 11.7813 36.0703 11.7813 35.0391L11.7812 3.11719C11.7812 2.05469 11.5156 1.27344 10.9844 0.773437C10.4531 0.257812 9.67969 0 8.66406 0L3.32031 0C2.28906 0 1.50781 0.265625 0.976563 0.796875C0.460938 1.32812 0.203125 2.10156 0.203125 3.11719L0.203125 35.0391C0.203125 36.0703 0.460938 36.8516 0.976563 37.3828C1.50781 37.9141 2.28906 38.1797 3.32031 38.1797ZM20.3594 38.1797H25.6797C26.7109 38.1797 27.4844 37.9141 28 37.3828C28.5313 36.8516 28.7969 36.0703 28.7969 35.0391L28.7969 3.11719C28.7969 2.05469 28.5312 1.27344 28 0.773437C27.4844 0.257812 26.7109 0 25.6797 0L20.3594 0C19.3281 0 18.5469 0.265625 18.0156 0.796875C17.4844 1.32812 17.2188 2.10156 17.2188 3.11719L17.2188 35.0391C17.2188 36.0703 17.4844 36.8516 18.0156 37.3828C18.5469 37.9141 19.3281 38.1797 20.3594 38.1797Z"
        fill="white"
      />
    </svg>
  );
}
