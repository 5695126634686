/** @jsxImportSource react */
import { HookEffectContext } from '@inkibra/api-base';
import {
  InkibraRecordlessLibraryApiFetcherRegistry,
  InkibraRecordlessLibrarySongType,
  RecordlessTransitionOverview,
  useLibrarySong,
  useLibrarySongPath,
  useLibrarySongWaveform,
} from '@inkibra/recordless.library-api';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Connector } from '../../common/lib/connector';

export default function LibrarySongItem() {
  const connector = Connector.useContext();
  const { songId } = useParams();
  if (songId === undefined) {
    throw new ReferenceError('Expecting a song id in the params');
  }
  const audioRef = useRef<HTMLAudioElement>(null);

  const [songUrl, setSongUrl] = useState<string | undefined>(undefined);
  const hookEffectContext = new HookEffectContext();
  const song = hookEffectContext.addEffect(useLibrarySong(songId));
  const songPath = hookEffectContext.addEffect(useLibrarySongPath(songId));
  const songWaveform = hookEffectContext.addEffect(
    useLibrarySongWaveform(songId, '8'),
  );
  hookEffectContext.runEffects(connector.cache);

  const [mutableSong, setMutableSong] = useState<
    InkibraRecordlessLibrarySongType | undefined
  >(undefined);

  useEffect(() => {
    setMutableSong(song);
  }, [song]);

  // Fetch song
  useEffect(() => {
    if (songPath) {
      fetch(songPath).then(async (res) => {
        console.log('res', res);
        const songBlob = await res.blob();
        const newSongUrl = URL.createObjectURL(songBlob);
        setSongUrl(newSongUrl);
        console.log('newSongUrl', newSongUrl);
      });
    }
  }, [songPath]);

  if (!song || !songPath || !mutableSong) {
    return null;
  }

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Typography variant="h3" component="h3" gutterBottom>
            Title: {song.title}
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            Album: {song.albumTitle}
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            Album Artist: {song.albumArtist}
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            Artists: {song.artists.join(', ')}
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            Genres: {song.genres.join(', ')}
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            Status:{' '}
            {InkibraRecordlessLibrarySongType.CatalogStatus.toString(
              song.catalogStatus,
            )}
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            Duration: {song.duration}
          </Typography>
          <Typography variant="h4" component="h4" gutterBottom>
            BPM: {song.bpm}
          </Typography>
        </Grid>
        <Grid item>
          <audio src={songUrl} controls ref={audioRef} />
          <br />
          <RecordlessTransitionOverview
            song={mutableSong}
            onTransitionClick={() => {
              // this doesn't need to do anything
            }}
            waveformData={songWaveform}
          />
          <>
            <Button
              color="success"
              onClick={async () => {
                await InkibraRecordlessLibraryApiFetcherRegistry.get(
                  'setLibrarySongCatalogStatus',
                )
                  .fn({
                    body: {
                      catalogStatus:
                        InkibraRecordlessLibrarySongType.CatalogStatus
                          .IN_CONTINUOUS_REVIEW,
                    },
                    pathParams: { songId: song.id },
                    pathQuery: {},
                    files: undefined,
                  })
                  .then((res) => {
                    connector.cache.input.next(res.value);
                  });
              }}
            >
              {InkibraRecordlessLibrarySongType.CatalogStatus.toString(
                InkibraRecordlessLibrarySongType.CatalogStatus
                  .IN_CONTINUOUS_REVIEW,
              )}
            </Button>
            <Button
              color="error"
              onClick={async () => {
                await InkibraRecordlessLibraryApiFetcherRegistry.get(
                  'setLibrarySongCatalogStatus',
                )
                  .fn({
                    body: {
                      catalogStatus:
                        InkibraRecordlessLibrarySongType.CatalogStatus
                          .NEEDS_ATTENTION,
                    },
                    pathParams: { songId: song.id },
                    pathQuery: {},
                    files: undefined,
                  })
                  .then((res) => {
                    connector.cache.input.next(res.value);
                  });
              }}
            >
              {InkibraRecordlessLibrarySongType.CatalogStatus.toString(
                InkibraRecordlessLibrarySongType.CatalogStatus.NEEDS_ATTENTION,
              )}
            </Button>
          </>
        </Grid>
        <br />
      </Grid>
    </>
  );
}
