/** @jsxImportSource @emotion/react */

import { EditableText } from '@inkibra/recordless.ux';
import { useState } from 'react';
import { isPresent } from 'ts-is-present';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { MusicBeatGrid } from './beat-grid';
import { beatIndexToSeconds, secondsToOffset } from './utils';
import { MusicWaveformDisplay } from './waveform-display';

/**
 * Represents the props for the MusicSectionLabeling component.
 */
export type RecordlessSectionLabelingProps = {
  song: InkibraRecordlessLibrarySongType;
  onUpdate: (song: InkibraRecordlessLibrarySongType) => void;
  waveformData: number[];
  onPlayRequest: (time: number) => void;
  containerRef: React.RefObject<HTMLDivElement>;
};

/**
 * A component that allows the user to label sections of a song.
 *
 * This component is used to label sections of a song.
 *
 * @param props The props for the MusicSectionLabeling component.
 * @returns The MusicSectionLabeling component.
 */
export const RecordlessSectionLabeling = (
  props: RecordlessSectionLabelingProps,
) => {
  const [maybeMutatedSectionStartTime, setMaybeMutatedSectionStartTime] =
    useState<number | undefined>(undefined);
  const [draggingSectionId, setDraggingSectionId] = useState<
    string | undefined
  >(undefined);
  const [hoveringBeatIndex, setHoveringBeatIndex] = useState<
    number | undefined
  >(undefined);
  const [overAddButtonBeatIndex, setOverAddButtonBeatIndex] = useState<
    number | undefined
  >(undefined);

  const height = 75;
  const pixelsPerSecond = 15;
  const width = props.song.duration * pixelsPerSecond;

  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

  const computedOrderedSectionInfoResult = songUtil.computeOrderedSectionInfo(
    props.song,
  );
  if (computedOrderedSectionInfoResult.isErr()) {
    console.error(computedOrderedSectionInfoResult.error);
    return null;
  }

  const firstSectionInfo = computedOrderedSectionInfoResult.value[0];
  if (firstSectionInfo === undefined) {
    console.error('firstSectionInfo is undefined');
    return null;
  }

  return (
    <svg
      css={{}}
      width={width}
      height={height}
      onMouseLeave={() => {
        setDraggingSectionId(undefined);
        setMaybeMutatedSectionStartTime(undefined);
      }}
      onMouseMove={(e) => {
        if (draggingSectionId) {
          console.log(
            'mouse move',
            e.movementX,
            e.movementY,
            draggingSectionId,
          );

          const svgRect = e.currentTarget.getBoundingClientRect();
          const relativeX = e.clientX - svgRect.left;
          const relativePosition = relativeX / width;
          setMaybeMutatedSectionStartTime(
            relativePosition * props.song.duration,
          );
        }
      }}
    >
      {/* The Section Display Layer */}
      {computedOrderedSectionInfoResult.value.map((section) => {
        return (
          <MusicSectionDisplay
            key={`${section.id}-section-display`}
            isFocused={draggingSectionId === section.id}
            startTime={section.startTime}
            endTime={section.endTime}
            pixelsPerSecond={pixelsPerSecond}
          />
        );
      })}

      {/* The Waveform and Beat Grid Layer */}
      <MusicWaveformDisplay
        waveformData={props.waveformData}
        width={width}
        height={75}
        offset={0}
      />
      <MusicBeatGrid
        onDoubleClick={(beatIndex) => {
          // This is a play request
          props.onPlayRequest(
            firstSectionInfo.startTime +
              beatIndexToSeconds(beatIndex, props.song.bpm),
          );
        }}
        highlightedBeatIndex={hoveringBeatIndex}
        width={width}
        beatWidth={(60 / props.song.bpm) * pixelsPerSecond}
        gridOffset={secondsToOffset(
          firstSectionInfo.startTime,
          pixelsPerSecond,
        )}
        onHover={(beatIndex) => {
          if (draggingSectionId) {
            return;
          }
          if (hoveringBeatIndex === beatIndex) {
            return;
          }
          setHoveringBeatIndex(beatIndex);
        }}
        onLeave={() => {
          if (overAddButtonBeatIndex !== undefined) {
            return;
          }
          setHoveringBeatIndex(undefined);
        }}
      />
      {/* The Drag Handle Control Layer */}
      {computedOrderedSectionInfoResult.value.map((section) => {
        let offset = secondsToOffset(section.startTime, pixelsPerSecond);
        if (
          section.id === draggingSectionId &&
          maybeMutatedSectionStartTime !== undefined
        ) {
          offset = secondsToOffset(
            maybeMutatedSectionStartTime,
            pixelsPerSecond,
          );
        }
        return (
          <DragHandle
            key={`${section.id}-drag-handle`}
            onToggleDrag={() => {
              if (
                draggingSectionId &&
                maybeMutatedSectionStartTime !== undefined &&
                draggingSectionId === section.id
              ) {
                const updatedSectionResult = songUtil.updateSectionStartTime(
                  props.song,
                  section.id,
                  maybeMutatedSectionStartTime,
                );
                if (updatedSectionResult.isErr()) {
                  console.error(updatedSectionResult.error);
                  return;
                }
                props.onUpdate(updatedSectionResult.value);
                setMaybeMutatedSectionStartTime(undefined);
                return setDraggingSectionId(undefined);
              }
              if (draggingSectionId === undefined) {
                console.log('starting to drag', section.id);
                return setDraggingSectionId(section.id);
              }
            }}
            offset={offset}
          />
        );
      })}
      {/* The Section Add Button Control Layer */}
      {draggingSectionId === undefined
        ? [hoveringBeatIndex]
            .filter(isPresent)
            .filter((hoveringBeatIndex) => {
              return !computedOrderedSectionInfoResult.value
                .map((section) => section.startBeat)
                .includes(hoveringBeatIndex);
            })
            .map((hoveringBeatIndex) => {
              return (
                <AddButton
                  key={`${hoveringBeatIndex}-add-button`}
                  onMouseOver={() => {
                    setOverAddButtonBeatIndex(hoveringBeatIndex);
                  }}
                  onMouseLeave={() => {
                    setOverAddButtonBeatIndex(undefined);
                  }}
                  x={
                    secondsToOffset(
                      beatIndexToSeconds(hoveringBeatIndex, props.song.bpm),
                      pixelsPerSecond,
                    ) +
                    secondsToOffset(firstSectionInfo.startTime, pixelsPerSecond)
                  }
                  y={height - 12}
                  onClick={() => {
                    const startTimeForBeatIndex =
                      beatIndexToSeconds(hoveringBeatIndex, props.song.bpm) +
                      firstSectionInfo.startTime;
                    // Add a new section at the beat index
                    const updatedSongResult = songUtil.insertNewSectionAtTime(
                      props.song,
                      startTimeForBeatIndex,
                    );
                    if (updatedSongResult.isErr()) {
                      console.error(updatedSongResult.error);
                      return;
                    }
                    props.onUpdate(updatedSongResult.value);
                  }}
                />
              );
            })
        : null}
      {hoveringBeatIndex && draggingSectionId === undefined
        ? computedOrderedSectionInfoResult.value
            .filter(
              (section) =>
                section.startBeat === hoveringBeatIndex &&
                section.positionType === 'middle',
            )
            .map((section) => {
              return (
                <RemoveButton
                  key={`${hoveringBeatIndex}-remove-button`}
                  onMouseOver={() => {
                    setOverAddButtonBeatIndex(section.startBeat);
                  }}
                  onMouseLeave={() => {
                    setOverAddButtonBeatIndex(undefined);
                  }}
                  x={secondsToOffset(section.startTime, pixelsPerSecond)}
                  y={height - 12}
                  onClick={() => {
                    const updatedSongResult = songUtil.removeSection(
                      props.song,
                      section.id,
                    );
                    if (updatedSongResult.isErr()) {
                      console.error(updatedSongResult.error);
                      return;
                    }
                    props.onUpdate(updatedSongResult.value);
                  }}
                />
              );
            })
        : null}
      {/* The Section Name Control Layer */}
      {computedOrderedSectionInfoResult.value.map((section) => {
        const duration = section.endTime - section.startTime;
        return (
          <foreignObject
            key={`${section.id}-section-name`}
            x={secondsToOffset(section.startTime, pixelsPerSecond)}
            width={duration * pixelsPerSecond}
            height={height}
          >
            <EditableText
              value={section.name}
              color="black"
              onValueChange={(newName: string) => {
                const updatedSectionResult = songUtil.updateSectionName(
                  props.song,
                  section.id,
                  newName,
                );
                if (updatedSectionResult.isErr()) {
                  console.error(updatedSectionResult.error);
                  return;
                }
                props.onUpdate(updatedSectionResult.value);
              }}
            />
          </foreignObject>
        );
      })}
    </svg>
  );
};

const componentOrange = '#FF3D00';

type MusicSectionDisplayProps = {
  isFocused: boolean;
  pixelsPerSecond: number;
  startTime: number;
  endTime: number;
};

const MusicSectionDisplay = (props: MusicSectionDisplayProps) => {
  const height = 75;
  const duration = props.endTime - props.startTime;
  const width = duration * props.pixelsPerSecond;
  return (
    <svg
      x={secondsToOffset(props.startTime, props.pixelsPerSecond)}
      width={width}
      height={height}
      css={{ pointerEvents: 'none' }}
    >
      <rect
        x="0"
        y="0"
        rx="6"
        width={width}
        height="75"
        fill={props.isFocused ? componentOrange : '#FFFFFF'}
        opacity={0.95}
      />
    </svg>
  );
};

type DragHandleProps = {
  onToggleDrag: () => void;
  offset: number;
};
const DragHandle = (props: DragHandleProps) => {
  return (
    <svg
      width="10"
      height="26"
      y="25"
      onClick={props.onToggleDrag}
      x={props.offset}
    >
      <rect width="10" height="26" rx="4" fill="#24FF00" />
      <path
        d="M4.06042 4C4.06042 4.55228 3.61271 5 3.06042 5C2.50814 5 2.06042 4.55228 2.06042 4C2.06042 3.44772 2.50814 3 3.06042 3C3.61271 3 4.06042 3.44772 4.06042 4Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
      <path
        d="M8 4C8 4.55228 7.55228 5 7 5C6.44772 5 6 4.55228 6 4C6 3.44772 6.44772 3 7 3C7.55228 3 8 3.44772 8 4Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
      <path
        d="M4.06042 10.0089C4.06042 10.5612 3.6127 11.0089 3.06042 11.0089C2.50813 11.0089 2.06042 10.5612 2.06042 10.0089C2.06042 9.45659 2.50813 9.00887 3.06042 9.00887C3.6127 9.00887 4.06042 9.45659 4.06042 10.0089Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
      <path
        d="M7.99999 10.0089C7.99999 10.5612 7.55228 11.0089 6.99999 11.0089C6.44771 11.0089 5.99999 10.5612 5.99999 10.0089C5.99999 9.45659 6.44771 9.00887 6.99999 9.00887C7.55228 9.00887 7.99999 9.45659 7.99999 10.0089Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
      <path
        d="M4.06042 16C4.06042 16.5523 3.6127 17 3.06042 17C2.50813 17 2.06042 16.5523 2.06042 16C2.06042 15.4477 2.50813 15 3.06042 15C3.6127 15 4.06042 15.4477 4.06042 16Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
      <path
        d="M7.99999 16C7.99999 16.5523 7.55228 17 6.99999 17C6.44771 17 5.99999 16.5523 5.99999 16C5.99999 15.4477 6.44771 15 6.99999 15C7.55228 15 7.99999 15.4477 7.99999 16Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
      <path
        d="M4.06042 22C4.06042 22.5523 3.6127 23 3.06042 23C2.50813 23 2.06042 22.5523 2.06042 22C2.06042 21.4477 2.50813 21 3.06042 21C3.6127 21 4.06042 21.4477 4.06042 22Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
      <path
        d="M7.99999 22C7.99999 22.5523 7.55228 23 6.99999 23C6.44771 23 5.99999 22.5523 5.99999 22C5.99999 21.4477 6.44771 21 6.99999 21C7.55228 21 7.99999 21.4477 7.99999 22Z"
        fill="#8000FF"
        fillOpacity="0.25"
      />
    </svg>
  );
};

type AddButtonProps = {
  x: number;
  y: number;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
};
function AddButton(props: AddButtonProps) {
  return (
    // biome-ignore lint/a11y/useKeyWithMouseEvents: <explanation>
    <svg
      onClick={props.onClick}
      x={props.x - 7}
      y={props.y}
      width="14"
      height="14"
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
    >
      <circle cx="7" cy="7" r="7" fill="#24FF00" />
      <line x1="0" y1="7" x2="14" y2="7" stroke="#63533A" />
      <line x1="7" y1="0" x2="7" y2="14" stroke="#63533A" />
    </svg>
  );
}

type RemoveButtonProps = {
  x: number;
  y: number;
  onClick: () => void;
  onMouseOver: () => void;
  onMouseLeave: () => void;
};
function RemoveButton(props: RemoveButtonProps) {
  return (
    // biome-ignore lint/a11y/useKeyWithMouseEvents: <explanation>
    <svg
      onClick={props.onClick}
      x={props.x - 7}
      y={props.y}
      width="14"
      height="14"
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
    >
      <circle cx="7" cy="7" r="7" fill="red" />
      <line x1="0" y1="7" x2="14" y2="7" stroke="#63533A" />
    </svg>
  );
}
