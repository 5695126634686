import { HookFunctionReturnType } from '@inkibra/api-base';
import { useState } from 'react';
import { InkibraRecordlessWaitlistApiFetcherRegistry } from './api';
import { InkibraRecordlessWaitlistType } from './type';

export function useWaitlists(
  runSetup = true,
): HookFunctionReturnType<InkibraRecordlessWaitlistType[]> {
  const [data, setData] = useState<InkibraRecordlessWaitlistType[]>([]);
  return {
    data,
    hookRunner: (data) => {
      setData(Object.values(data).filter(InkibraRecordlessWaitlistType.is));
    },
    setup: async (cache) => {
      if (runSetup) {
        const data = await InkibraRecordlessWaitlistApiFetcherRegistry.get(
          'getAllInkibraRecordlessWaitlists',
        ).fn({
          body: undefined,
          pathParams: {},
          pathQuery: {},
          files: undefined,
        });

        setData(data.value);
        cache.input.next(data.value);
      }
    },
  };
}

export function useWaitlist(
  waitlistId: string,
): HookFunctionReturnType<InkibraRecordlessWaitlistType | undefined> {
  const [data, setData] = useState<InkibraRecordlessWaitlistType | undefined>();
  return {
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data)
          .filter(InkibraRecordlessWaitlistType.is)
          .find(({ id }) => id === waitlistId),
      );
    },
    setup: async (cache) => {
      const data = await InkibraRecordlessWaitlistApiFetcherRegistry.get(
        'getInkibraRecordlessWaitlist',
      ).fn({
        body: undefined,
        pathParams: { waitlistId },
        pathQuery: {},
        files: undefined,
      });

      setData(data.value);
      cache.input.next(data.value);
    },
  };
}

export function useWaitlistsEntries(
  waitlistId: string,
): HookFunctionReturnType<InkibraRecordlessWaitlistType.Entry[]> {
  const [data, setData] = useState<InkibraRecordlessWaitlistType.Entry[]>([]);
  return {
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data)
          .filter(InkibraRecordlessWaitlistType.Entry.is)
          .filter((entry) => entry.waitlistId === waitlistId),
      );
    },
    setup: async (cache) => {
      const data = await InkibraRecordlessWaitlistApiFetcherRegistry.get(
        'getAllInkibraRecordlessWaitlistEntries',
      ).fn({
        body: undefined,
        pathParams: { waitlistId },
        pathQuery: {},
        files: undefined,
      });

      setData(data.value);
      cache.input.next(data.value);
    },
  };
}

export function useWaitlistsEntry(
  waitlistId: string,
  entryId: string,
): HookFunctionReturnType<InkibraRecordlessWaitlistType.Entry | undefined> {
  const [data, setData] = useState<
    InkibraRecordlessWaitlistType.Entry | undefined
  >(undefined);
  return {
    data,
    hookRunner: (data) => {
      setData(
        Object.values(data)
          .filter(InkibraRecordlessWaitlistType.Entry.is)
          .find((entry) => entry.id === entryId),
      );
    },
    setup: async (cache) => {
      const data = await InkibraRecordlessWaitlistApiFetcherRegistry.get(
        'getInkibraRecordlessWaitlistEntry',
      ).fn({
        body: undefined,
        pathParams: { waitlistId, entryId },
        pathQuery: {},
        files: undefined,
      });

      setData(data.value);
      cache.input.next(data.value);
    },
  };
}
