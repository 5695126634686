/** @jsxImportSource @emotion/react */

export function Fun() {
  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_350_3189"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <rect width="80" height="80" rx="20" fill="white" />
      </mask>
      <g mask="url(#mask0_350_3189)">
        <rect width="80" height="80" fill="#C317FF" fillOpacity="0.77" />
        <mask id="path-3-inside-1_350_3189" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.358 104.237L127.043 89.3437L68.8784 43.7092C73.0027 41.1335 76.5226 37.4675 78.9586 32.859C86.3178 18.9364 80.9972 1.68411 67.0746 -5.6751C53.152 -13.0343 35.8997 -7.71366 28.5405 6.20893C21.9376 18.7007 25.5422 33.8729 36.4089 42.175L36.3505 42.2495L115.358 104.237Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M115.358 104.237L127.043 89.3437L68.8784 43.7092C73.0027 41.1335 76.5226 37.4675 78.9586 32.859C86.3178 18.9364 80.9972 1.68411 67.0746 -5.6751C53.152 -13.0343 35.8997 -7.71366 28.5405 6.20893C21.9376 18.7007 25.5422 33.8729 36.4089 42.175L36.3505 42.2495L115.358 104.237Z"
          fill="#F31875"
        />
        <path
          d="M127.043 89.3437L128.616 90.5782L129.851 89.0047L128.277 87.7702L127.043 89.3437ZM115.358 104.237L114.123 105.81L115.697 107.045L116.931 105.471L115.358 104.237ZM68.8784 43.7092L67.819 42.0128L65.4009 43.5229L67.6438 45.2827L68.8784 43.7092ZM36.4089 42.175L37.9825 43.4095L39.2329 41.8156L37.6231 40.5857L36.4089 42.175ZM36.3505 42.2495L34.777 41.015L33.5425 42.5885L35.116 43.823L36.3505 42.2495ZM125.469 88.1091L113.784 103.002L116.931 105.471L128.616 90.5782L125.469 88.1091ZM67.6438 45.2827L125.808 90.9172L128.277 87.7702L70.1129 42.1357L67.6438 45.2827ZM77.1904 31.9244C74.9238 36.2126 71.6521 39.619 67.819 42.0128L69.9378 45.4055C74.3534 42.648 78.1215 38.7225 80.7268 33.7936L77.1904 31.9244ZM66.1399 -3.90692C79.086 2.93611 84.0335 18.9783 77.1904 31.9244L80.7268 33.7936C88.6022 18.8945 82.9083 0.432109 68.0092 -7.44329L66.1399 -3.90692ZM30.3086 7.14356C37.1517 -5.80249 53.1939 -10.75 66.1399 -3.90692L68.0092 -7.44329C53.1101 -15.3187 34.6477 -9.62482 26.7723 5.2743L30.3086 7.14356ZM37.6231 40.5857C27.5198 32.8669 24.1698 18.7574 30.3086 7.14356L26.7723 5.2743C19.7053 18.644 23.5646 34.8789 35.1948 43.7642L37.6231 40.5857ZM37.9241 43.4839L37.9825 43.4095L34.8354 40.9405L34.777 41.015L37.9241 43.4839ZM116.592 102.663L37.5851 40.6759L35.116 43.823L114.123 105.81L116.592 102.663Z"
          fill="black"
          mask="url(#path-3-inside-1_350_3189)"
        />
      </g>
    </svg>
  );
}
