/** @jsxImportSource react */

import { Route, Routes } from 'react-router-dom';
import LibraryHeader from './library/library-header';
import LibrarySongItem from './library/library-song-item';
import LibrarySongList from './library/library-song-list';

export default function LibraryIndex() {
  return (
    <>
      <LibraryHeader />
      <Routes>
        <Route path="/" element={<LibrarySongList />} />
        <Route path="/:songId" element={<LibrarySongItem />} />
      </Routes>
    </>
  );
}
