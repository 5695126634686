/** @jsxImportSource @emotion/react */

export function RunningMan() {
  return (
    <svg
      width="164"
      height="272"
      viewBox="0 0 164 272"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_1598)">
        <g filter="url(#filter0_i_1_1598)">
          <path
            d="M88.9894 46.8522C101.934 46.8522 112.399 36.3453 112.399 23.2653C112.399 10.3997 101.934 0 88.9894 0C75.8286 0 65.2568 10.3997 65.2568 23.2653C65.2568 36.3453 75.8286 46.8522 88.9894 46.8522ZM114.232 109.465C118.655 114.075 127.178 113.753 131.06 107.642L157.275 65.8289C165.365 52.9633 146.378 42.6709 138.935 54.4643L119.95 84.9129L105.063 70.0102C90.7154 55.8581 75.0734 52.9633 53.2826 55.4293L14.879 59.7177C9.91675 60.2538 6.03323 64.2207 5.49386 68.9381L0.100092 111.073C-1.62591 124.582 19.5176 128.12 21.3515 113.646L25.6665 79.9811L45.7314 77.7296C48.5362 77.5152 50.4779 79.3377 50.0464 82.447L44.9762 123.724C42.4951 143.237 53.822 154.495 73.2395 153.1L112.938 150.313L101.071 193.305C97.08 207.779 118.116 212.819 121.892 198.774L137.534 141.521C139.474 134.231 134.512 128.013 126.314 128.013H85.3215L90.4996 92.8466C91.039 89.4158 94.1674 89.3087 96.1092 91.4529L114.232 109.465ZM55.6559 161.249L53.0668 199.417L32.7864 249.807C29.2264 258.812 35.699 265.031 42.3873 265.031C46.5944 265.031 50.8014 262.779 52.959 257.419L73.6711 205.742C73.8868 204.992 74.2105 204.135 74.3183 203.384L80.3594 163.071L73.9946 163.5C67.1985 163.929 60.9417 163.071 55.6559 161.249Z"
            fill="#1D5C00"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_i_1_1598"
          x="0"
          y="0"
          width="179.243"
          height="272.031"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="20" dy="7" />
          <feGaussianBlur stdDeviation="11.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.32 0 0 0 0 1 0 0 0 0 0 0 0 0 1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_1_1598"
          />
        </filter>
        <clipPath id="clip0_1_1598">
          <rect width="163.2" height="272" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
