/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ResponsiveValue, responsiveStyle } from '../constants/media-query';

const styles = css({
  boxSizing: 'border-box',
  position: 'relative',
  zIndex: 0,
});

const contentStyles = css({
  display: 'grid',
  position: 'relative',
  boxSizing: 'border-box',
  maxWidth: '1400px',
  margin: 'auto',
  padding: '48px 16px',
});

export type SectionProps = {
  background?: string;
  gridTemplateRows?: ResponsiveValue<string>;
  gridTemplateColumns?: ResponsiveValue<string>;
};

export function Section({
  background,
  gridTemplateRows,
  gridTemplateColumns,
  children,
}: SectionProps & { children: React.ReactNode }) {
  let sectionStyles = css(styles);

  if (background) {
    sectionStyles = css(sectionStyles, { background: background });
  }

  let sectionContentStyles = css(contentStyles);

  if (gridTemplateRows) {
    sectionContentStyles = css(
      sectionContentStyles,
      responsiveStyle('gridTemplateRows', gridTemplateRows),
    );
  }

  if (gridTemplateColumns) {
    sectionContentStyles = css(
      sectionContentStyles,
      responsiveStyle('gridTemplateColumns', gridTemplateColumns),
    );
  }

  return (
    <section css={sectionStyles}>
      <div css={sectionContentStyles}>{children}</div>
    </section>
  );
}
