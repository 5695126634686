/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useState } from 'react';
import { Button } from './button';
import { Close } from './icons/close';
import { TextInput } from './text-input';

const styles = css({
  height: '100%',
  width: '100%',
  position: 'absolute',
  top: '0',
  left: '0',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  zIndex: 10001,
  display: 'none',
  gridTemplateColumns: '80%',
  justifyContent: 'center',
  alignItems: 'center',
});

const contentStyles = css({
  width: '100%',
  background: '#08031D',
  borderRadius: '25px',
  padding: '25px',
  display: 'grid',
  gridTemplateRows: 'min-content min-content 1fr min-content',
});

const contentCloseStyles = css({
  width: '100%',
  textAlign: 'right',
});

const contentTitleStyles = css({
  fontWeight: '700',
  fontSize: '32px',
  color: '#F2F2F7',
  textAlign: 'center',
  marginBottom: '16px',
});

const contentSubtitleStyles = css({
  fontWeight: '400',
  fontSize: '12px',
  color: '#FFFFFF',
  textAlign: 'center',
});

const formWrapperStyles = css({
  margin: '48px 0',
});

export type WorkoutEditorProps = {
  active: boolean;
  creationMode: boolean;
  onSubmit: (name: string) => Promise<boolean>;
  onClose: () => void;
};

export function WorkoutEditor({
  active,
  onSubmit,
  onClose,
  creationMode,
}: WorkoutEditorProps) {
  const initialValue = '';

  const [name, setName] = useState<string>(initialValue);

  const [error, setError] = useState<string>();

  const [submitDisabled, setSubmitDisabled] = useState<boolean>(false);

  const handleChange = (name: string) => {
    setName(name);
  };

  const handleValidation = () => {
    if (!name) {
      setError('required');

      return false;
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setSubmitDisabled(true);

    if (handleValidation()) {
      const success = await onSubmit(name);

      if (success) {
        setName(initialValue);
      }
    }

    setSubmitDisabled(false);
  };

  return (
    <div css={[styles, active && { display: 'grid' }]}>
      <div css={contentStyles}>
        <div css={contentCloseStyles} onClick={onClose}>
          <Close />
        </div>

        <div>
          <div css={contentTitleStyles}>
            {creationMode ? 'CREATE WORKOUT' : 'RENAME WORKOUT'}
          </div>

          <div css={contentSubtitleStyles}>
            Your place to customize and do what you want
          </div>
        </div>

        <div css={formWrapperStyles}>
          <form id="workoutForm" onSubmit={handleSubmit} noValidate>
            <TextInput
              name="name"
              label="WORKOUT NAME"
              placeholder="Name your workout"
              required={true}
              value={name}
              error={error}
              onChange={(e) => handleChange(e.target.value.slice(0, 20))}
            />
          </form>
        </div>

        <Button
          type="submit"
          form="workoutForm"
          color="primary"
          disabled={submitDisabled || !name}
          contents="SAVE"
        />
      </div>
    </div>
  );
}
