import * as uuid from 'uuid';

export type CacheableObject = {
  id: string;
  type: string;
};

/**
 * Represents a cacheable object.
 */
export namespace CacheableObject {
  /**
   * Represents a removed cacheable object.
   */
  export type Removed<T extends CacheableObject> = {
    id: string;
    removedType: T['type'];
    removedData: T;
    type: `REMOVED(${T['type']})`;
  };

  /**
   * Creates a removed cacheable object.
   * @param data The cacheable object to be removed.
   * @returns The removed cacheable object.
   */
  export function removed<T extends CacheableObject>(data: T): Removed<T> {
    return {
      id: data.id,
      removedType: data.type,
      removedData: data,
      type: `REMOVED(${data.type})`,
    };
  }

  /**
   * Represents a recovered cacheable object.
   */
  export namespace Removed {
    /**
     * Recovers a removed cacheable object.
     * @param removed The removed cacheable object.
     * @returns The recovered cacheable object.
     */
    export function recover<T extends CacheableObject>(removed: Removed<T>): T {
      return removed.removedData;
    }
  }

  /**
   * Represents a reference to a cacheable object.
   * @deprecated
   */
  export type Reference<T extends CacheableObject> = {
    referencedId: string;
    referencedType: T['type'];
  };

  /**
   * Creates a reference to a cacheable object.
   * @param item The cacheable object to create a reference for.
   * @deprecated
   * @returns The reference to the cacheable object.
   */
  export function makeRef<T extends CacheableObject>(
    item: Pick<T, 'id' | 'type'>,
  ): Reference<T> {
    return {
      referencedId: item.id,
      referencedType: item.type,
    };
  }

  /**
   * Generates a unique ID for a cacheable object.
   * @deprecated
   * @returns The generated ID.
   */
  export function makeId(): string {
    return uuid.v4();
  }
}
