/** @jsxImportSource @emotion/react */

import { LoggerContext } from '@inkibra/api-base';
import {
  GetCurrentInkibraSession,
  IdentifyInkibraVisitorSession,
  InkibraSession,
  InkibraSessionApiFetcherRegistry,
  ResumeInkibraSession,
  SetInkibraSessionPassword,
  VerifyInkibraSessionAuthenticationCode,
  VerifyInkibraSessionRecoveryCode,
} from '@inkibra/recordless.auth-api';
import { createContext, useContext, useEffect, useState } from 'react';

export const AuthenticatedSessionContext = createContext<
  () => {
    session: Omit<InkibraSession.UserSession, 'passwordHash'>;
    logout: () => Promise<void>;
  }
>(() => {
  throw new Error('Invalid AuthenticatedSessionContext.');
});

export enum AuthPhase {
  START = 0,
  LOGIN = 1,
  SIGN_UP = 2,
  GET_RECOVERY_CODE = 3,
  VERIFY_SESSION_AUTHENTICATION_CODE = 4,
  VERIFY_SESSION_RECOVERY_CODE = 5,
  SET_PASSWORD = 6,
  COMPLETE = 7,
}

export type AuthenticatedUserSessionFormProps = {
  onSignUpStart: (email: string, fullname?: string) => void;
  onVerifySessionAuthenticationCode: (code: string) => void;
  onRecoverAccount: (email: string) => void;
  onVerifySessionRecoveryCode: (code: string) => void;
  onSetPassword: (password: string) => void;
  onLogin: (email: string, password: string) => void;
  formError: string | undefined;
  authPhase: AuthPhase;
  inProgress: boolean;
  setAuthPhase: (authPhase: AuthPhase) => void;
};

export type AuthenticatedSessionGuardProps = {
  loadingElement: React.ReactNode;
  guardedElement: React.ReactNode;
  UserAuthForm: React.ComponentType<AuthenticatedUserSessionFormProps>;
  brand: InkibraSession.SessionBrand;
};

export function AuthenticatedSessionGuard(
  props: AuthenticatedSessionGuardProps,
) {
  const logger = useContext(LoggerContext)().child({
    component: 'AuthenticatedSessionGuard',
  });
  const [authResponse, setAuthResponse] = useState<
    | GetCurrentInkibraSession.Response
    | ResumeInkibraSession.Response
    | undefined
  >();

  const [authPhase, setAuthPhase] = useState<AuthPhase>(AuthPhase.START);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  const [inProgress, setInProgress] = useState<boolean>(false);

  async function getLoggedInUser() {
    const authResponse = await InkibraSessionApiFetcherRegistry.get(
      'GetCurrentInkibraSession',
    ).fn({
      body: undefined,
      pathParams: {},
      pathQuery: {},
      files: undefined,
    });
    setAuthResponse(authResponse);
  }
  useEffect(() => {
    getLoggedInUser();
  }, []);

  const handleSignUp = async (email: string, fullName?: string) => {
    const authBody = { email, fullName };

    if (IdentifyInkibraVisitorSession.Body.schema.is(authBody)) {
      const identifySessionResponse =
        await InkibraSessionApiFetcherRegistry.get(
          'IdentifyInkibraVisitorSession',
        ).fn({
          body: authBody,
          files: undefined,
          pathParams: {},
          pathQuery: {},
        });

      logger.debug('IdentifyInkibraSessionResponse', identifySessionResponse);

      if (identifySessionResponse.type === 'Ok') {
        const sendSessionCodeResponse =
          await InkibraSessionApiFetcherRegistry.get(
            'SendInkibraSessionAuthenticationOrRecoveryCode',
          ).fn({
            body: {
              brand: props.brand,
              kind: 'authentication',
            },
            files: undefined,
            pathParams: {},
            pathQuery: {},
          });

        logger.debug(
          'SendInkibraSessionAuthenticationOrRecoveryCode',
          sendSessionCodeResponse,
        );

        if (sendSessionCodeResponse.type === 'Ok') {
          setAuthPhase(AuthPhase.VERIFY_SESSION_AUTHENTICATION_CODE);
        } else {
          setFormError(sendSessionCodeResponse.error.message);
        }
      } else {
        setFormError(identifySessionResponse.error.message);
      }
    } else {
      const validationError = IdentifyInkibraVisitorSession.Body.schema
        .validate(authBody)
        .errors.map((error) => `${error.path}: ${error.expected}`)
        .join(', ');
      setFormError(validationError);
    }
  };

  const handleRecoverAccount = async (email: string) => {
    const authBody = { email };

    if (IdentifyInkibraVisitorSession.Body.schema.is(authBody)) {
      const identifySessionResponse =
        await InkibraSessionApiFetcherRegistry.get(
          'IdentifyInkibraVisitorSession',
        ).fn({
          body: authBody,
          files: undefined,
          pathParams: {},
          pathQuery: {},
        });

      logger.debug('IdentifyInkibraSessionResponse', identifySessionResponse);

      if (identifySessionResponse.type === 'Ok') {
        const sendSessionCodeResponse =
          await InkibraSessionApiFetcherRegistry.get(
            'SendInkibraSessionAuthenticationOrRecoveryCode',
          ).fn({
            body: {
              brand: props.brand,
              kind: 'recovery',
            },
            files: undefined,
            pathParams: {},
            pathQuery: {},
          });

        logger.debug(
          'SendInkibraSessionAuthenticationOrRecoveryCode',
          sendSessionCodeResponse,
        );

        if (sendSessionCodeResponse.type === 'Ok') {
          setAuthPhase(AuthPhase.VERIFY_SESSION_RECOVERY_CODE);
        } else {
          setFormError(sendSessionCodeResponse.error.message);
        }
      } else {
        setFormError(identifySessionResponse.error.message);
      }
    } else {
      const validationError = IdentifyInkibraVisitorSession.Body.schema
        .validate(authBody)
        .errors.map((error) => `${error.path}: ${error.expected}`)
        .join(', ');
      setFormError(validationError);
    }
  };

  const handleVerifySessionAuthenticationCode = async (code: string) => {
    if (
      VerifyInkibraSessionAuthenticationCode.Body.schema.is({
        code,
        brand: props.brand,
      })
    ) {
      const verifySessionCodeResponse =
        await InkibraSessionApiFetcherRegistry.get(
          'VerifyInkibraSessionAuthenticationCode',
        ).fn({
          body: { code, brand: props.brand },
          files: undefined,
          pathParams: {},
          pathQuery: {},
        });

      logger.debug(
        'VerifyInkibraSessionAuthenticationCode',
        verifySessionCodeResponse,
      );

      if (verifySessionCodeResponse.type === 'Ok') {
        setAuthPhase(AuthPhase.SET_PASSWORD);
      } else {
        setFormError(verifySessionCodeResponse.error.message);
      }
    } else {
      const validationError = VerifyInkibraSessionAuthenticationCode.Body.schema
        .validate({ code })
        .errors.map((error) => `${error.path}: ${error.expected}`)
        .join(', ');
      setFormError(validationError);
    }
  };

  const handleVerifySessionRecoveryCode = async (code: string) => {
    if (VerifyInkibraSessionRecoveryCode.Body.schema.is({ code })) {
      const verifySessionCodeResponse =
        await InkibraSessionApiFetcherRegistry.get(
          'VerifyInkibraSessionRecoveryCode',
        ).fn({
          body: { code },
          files: undefined,
          pathParams: {},
          pathQuery: {},
        });

      logger.debug(
        'VerifyInkibraSessionRecoveryCode',
        verifySessionCodeResponse,
      );

      if (verifySessionCodeResponse.type === 'Ok') {
        setAuthPhase(AuthPhase.SET_PASSWORD);
      } else {
        setFormError(verifySessionCodeResponse.error.message);
      }
    } else {
      const validationError = VerifyInkibraSessionRecoveryCode.Body.schema
        .validate({ code })
        .errors.map((error) => `${error.path}: ${error.expected}`)
        .join(', ');
      setFormError(validationError);
    }
  };

  const handleSetPassword = async (newPassword: string) => {
    const authBody = { newPassword };

    if (SetInkibraSessionPassword.Body.schema.is(authBody)) {
      const setSessionPasswordResponse =
        await InkibraSessionApiFetcherRegistry.get(
          'SetInkibraSessionPassword',
        ).fn({
          body: authBody,
          files: undefined,
          pathParams: {},
          pathQuery: {},
        });

      logger.debug('setSessionPasswordResponse', setSessionPasswordResponse);

      if (setSessionPasswordResponse.type === 'Ok') {
        await getLoggedInUser();
        setAuthPhase(AuthPhase.COMPLETE);
      } else {
        setFormError(setSessionPasswordResponse.error.message);
      }
    } else {
      const validationError = SetInkibraSessionPassword.Body.schema
        .validate(authBody)
        .errors.map((error) => `${error.path}: ${error.expected}`)
        .join(', ');
      setFormError(validationError);
    }
  };

  const handleLogin = async (email: string, password: string) => {
    const authBody = {
      email,
      password,
    };
    if (ResumeInkibraSession.Body.schema.is(authBody)) {
      const resumeInkibraSessionResponse =
        await InkibraSessionApiFetcherRegistry.get('ResumeInkibraSession').fn({
          body: authBody,
          pathParams: {},
          pathQuery: {},
          files: undefined,
        });

      logger.debug(
        'resumeInkibraSessionResponse',
        resumeInkibraSessionResponse,
      );

      if (resumeInkibraSessionResponse.type === 'Ok') {
        setAuthResponse(resumeInkibraSessionResponse);
        setAuthPhase(AuthPhase.COMPLETE);
      } else {
        setFormError(resumeInkibraSessionResponse.error.message);
      }
    } else {
      const validationErrors = ResumeInkibraSession.Body.schema
        .validate(authBody)
        .errors.map((error) => `${error.path}: ${error.expected}`)
        .join(', ');
      setFormError(validationErrors);
    }
  };

  if (authResponse === undefined) {
    return props.loadingElement;
  }

  if (
    authResponse.type === 'Err' ||
    (authResponse.type === 'Ok' &&
      authResponse.value.sessionKind === InkibraSession.SessionKind.VISITOR)
  ) {
    // We are not authenticated.
    // We should ask the user to authenticate.
    return (
      <props.UserAuthForm
        authPhase={authPhase}
        setAuthPhase={(authPhase) => {
          setFormError(undefined);
          setAuthPhase(authPhase);
        }}
        formError={formError}
        inProgress={inProgress}
        onLogin={async (email, password) => {
          setFormError(undefined);
          setInProgress(true);
          await handleLogin(email, password);
          setInProgress(false);
        }}
        onSignUpStart={async (email, fullname) => {
          setFormError(undefined);
          setInProgress(true);
          await handleSignUp(email, fullname);
          setInProgress(false);
        }}
        onRecoverAccount={async (email) => {
          setFormError(undefined);
          setInProgress(true);
          await handleRecoverAccount(email);
          setInProgress(false);
        }}
        onVerifySessionAuthenticationCode={async (code) => {
          setFormError(undefined);
          setInProgress(true);
          await handleVerifySessionAuthenticationCode(code);
          setInProgress(false);
        }}
        onVerifySessionRecoveryCode={async (code) => {
          setFormError(undefined);
          setInProgress(true);
          await handleVerifySessionRecoveryCode(code);
          setInProgress(false);
        }}
        onSetPassword={async (password) => {
          setFormError(undefined);
          setInProgress(true);
          await handleSetPassword(password);
          setInProgress(false);
        }}
      />
    );
  }
  if (
    authResponse.type === 'Ok' &&
    authResponse.value.sessionKind ===
      InkibraSession.SessionKind.AUTHENTICATED_USER
  ) {
    return (
      <AuthenticatedSessionContext.Provider
        value={() => {
          if (
            authResponse.type === 'Ok' &&
            authResponse.value.sessionKind ===
              InkibraSession.SessionKind.AUTHENTICATED_USER
          ) {
            return {
              session: authResponse.value,
              logout: async () => {
                const response = await InkibraSessionApiFetcherRegistry.get(
                  'DowngradeInkibraSession',
                ).fn({
                  body: undefined,
                  pathParams: {},
                  pathQuery: {},
                  files: undefined,
                });
                if (response.type === 'Ok') {
                  setAuthResponse(response);
                  setAuthPhase(AuthPhase.START);
                }
              },
            };
          }
          throw new Error('Invalid AuthenticatedSessionContext.');
        }}
      >
        {props.guardedElement}
      </AuthenticatedSessionContext.Provider>
    );
  }

  throw new Error('Unhandled authResponse type');
}
