import typia from 'typia';

import {
  ApiRoute,
  EmptyObject,
  GenericRegistry,
  HttpMethod,
  JobErrorType,
  JobId,
  JobProgressType,
  JobResultType,
  MimeType,
  NoData,
  RemoteBinaryLocator,
  SchemaHelpersFor,
  SerializableResult,
  SimpleQuery,
  SimpleQueryWithFilter,
  StatusCode,
  UNHANDLED_JOB_FAILURE,
} from '@inkibra/api-base';
import { ErrorDescriptor } from '@inkibra/error-base';
import {
  ArrangementOutputInfo,
  MixOutputInfo,
} from '@inkibra/recordless.music-engine';

import { UNHANDLED_VALIDATION_FAILURE } from '@inkibra/observable-cache';
import { InkibraRecordlessLibraryArrangementType } from './type-arrangement';
import { InkibraRecordlessLibraryAlbumArtworkBinaryLocator } from './type-artwork-file-remote-binary-locator';
import { InkibraRecordlessLibraryMixType } from './type-mix';
import { InkibraRecordlessLibraryMusicFileRemoteBinaryLocator } from './type-music-file-remote-binary-locator';
import { InkibraRecordlessLibrarySongType } from './type-song';

export namespace GetAllLibraryCatalogSongs {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = SimpleQueryWithFilter<
    InkibraRecordlessLibrarySongType,
    | 'catalogStatus'
    | 'title'
    | 'albumArtist'
    | 'slug'
    | 'bpm'
    | 'energy'
    | 'genres'
  >;

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibrarySongType[],
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs',
    {
      Name: 'getAllLibraryCatalogSongs';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getAllLibraryCatalogSongs',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/catalog/songs',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateCatalogSong {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessLibrarySongType.Creation;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }
  export type DUPLICATE_SONG_FAILURE = ErrorDescriptor<
    'DUPLICATE_SONG',
    'A song with the same title, artist, and album already exists',
    {
      title: string;
      albumArtist: string;
      albumTitle: string;
      originalSongId: InkibraRecordlessLibrarySongType['id'];
    }
  >;
  export type Response =
    | SerializableResult.OkWithStatusCode<
        InkibraRecordlessLibrarySongType,
        StatusCode.CREATED
      >
    | SerializableResult.ErrWithStatusCode<
        | UNHANDLED_VALIDATION_FAILURE
        | InkibraRecordlessLibrarySongType.SONG_VALIDATION_FAILURE
        | InkibraRecordlessLibrarySongType.INVALID_CATALOG_STATUS_TRANSITION_FAILURE,
        StatusCode.BAD_REQUEST
      >
    | SerializableResult.ErrWithStatusCode<
        DUPLICATE_SONG_FAILURE,
        StatusCode.CONFLICT
      >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs',
    {
      Name: 'createCatalogSong';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: {
        audio: {
          allowableMimeTypes: [MimeType.AUDIO_MP4];
        };
      };
      ResponseType: Response;
    }
  >({
    name: 'createCatalogSong',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/catalog/songs',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: {
      audio: {
        allowableMimeTypes: [MimeType.AUDIO_MP4],
      },
    },
    responseSchema: Response.schema,
  });
}

export namespace GetLibraryAlbumArtwork {
  export type PathParams = { albumSlug: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type MISSING_ALBUM_ARTWORK_ERROR_DESCRIPTOR = ErrorDescriptor<
    'MISSING_ALBUM_ARTWORK',
    'Missing album artwork',
    { albumSlug: string }
  >;

  export type Response =
    | SerializableResult.OkWithStatusCode<
        InkibraRecordlessLibraryAlbumArtworkBinaryLocator,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        MISSING_ALBUM_ARTWORK_ERROR_DESCRIPTOR,
        StatusCode.NOT_FOUND
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/albums/:albumSlug/artwork',
    {
      Name: 'getLibraryAlbumArtwork';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getLibraryAlbumArtwork',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/catalog/albums/:albumSlug/artwork',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace ModifyAlbumArtwork {
  export type PathParams = { albumSlug: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryAlbumArtworkBinaryLocator,
    StatusCode.OK
  >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/albums/:albumSlug/artwork',
    {
      Name: 'modifyAlbumArtwork';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: {
        image: {
          allowableMimeTypes: [MimeType.IMAGE_JPEG];
        };
      };
      ResponseType: Response;
    }
  >({
    name: 'modifyAlbumArtwork',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.library/catalog/albums/:albumSlug/artwork',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: {
      image: {
        allowableMimeTypes: [MimeType.IMAGE_JPEG],
      },
    },
    responseSchema: Response.schema,
  });
}

// TODO: create modification for song info vs song sections and voting / proposing transition
export namespace ModifyRecordlessSong {
  export type PathParams = { songId: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = Omit<
    InkibraRecordlessLibrarySongType.Modification,
    'catalogStatus'
  >;

  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibrarySongType,
    StatusCode.OK
  >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs/:songId',
    {
      Name: 'modifyRecordlessSong';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'modifyRecordlessSong',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.library/catalog/songs/:songId',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace SetLibrarySongCatalogStatus {
  export type PathParams = { songId: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = Pick<InkibraRecordlessLibrarySongType, 'catalogStatus'>;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibrarySongType,
    StatusCode.ACCEPTED
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs/:songId/catalogStatus',
    {
      Name: 'setLibrarySongCatalogStatus';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'setLibrarySongCatalogStatus',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.library/catalog/songs/:songId/catalogStatus',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetLibraryCatalogSong {
  export type PathParams = { songId: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibrarySongType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs/:songId',
    {
      Name: 'getLibraryCatalogSong';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getLibraryCatalogSong',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/catalog/songs/:songId',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetLibraryCatalogSongFile {
  export type PathParams = { songId: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryMusicFileRemoteBinaryLocator,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs/:songId/file.m4a',
    {
      Name: 'getLibraryCatalogSongFile';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getLibraryCatalogSongFile',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/catalog/songs/:songId/file.m4a',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetLibraryCatalogSongWaveform {
  export type PathParams = { songId: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {
    waveformResolutionOverride: '8' | '16' | '32';
  };

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    number[],
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs/:songId/waveform',
    {
      Name: 'getLibraryCatalogSongWaveform';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getLibraryCatalogSongWaveform',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/catalog/songs/:songId/waveform',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateMixSources {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = {
    jobId: JobId<'CreateMixSources'>;
    mixWindowOptions?: {
      startWithNodeAtTime: number;
      endWithNodeAtTime: number;
    };
  };

  export type Body = InkibraRecordlessLibraryMixType;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type CreateMixSourcesProgress = JobProgressType<
    'CreateMixSources',
    {
      mix: InkibraRecordlessLibraryMixType;
      mixWindowOptions: PathQuery['mixWindowOptions'];
    }
  >;

  export type CANNOT_RESOLVE_ARRANGEMENT_PATHS_FAILURE = ErrorDescriptor<
    'CANNOT_RESOLVE_ARRANGEMENT_PATHS',
    'Cannot resolve arrangement paths',
    InkibraRecordlessLibraryArrangementType.CANNOT_RESOLVE_ARRANGEMENT_PATH_FAILURE[]
  >;

  export type CreateMixSourcesResult = JobResultType<
    'CreateMixSources',
    {
      mixOutputInfo: MixOutputInfo;
      locator: InkibraRecordlessLibraryMusicFileRemoteBinaryLocator;
      waveform: number[];
    }
  >;

  export type CreateMixSourcesFailure =
    | JobErrorType<'CreateMixSources', CANNOT_RESOLVE_ARRANGEMENT_PATHS_FAILURE>
    | JobErrorType<'CreateMixSources', UNHANDLED_JOB_FAILURE>;

  export type Response =
    | SerializableResult.OkWithStatusCode<
        CreateMixSourcesProgress | CreateMixSourcesResult,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        CreateMixSourcesFailure,
        StatusCode.INTERNAL_SERVER_ERROR
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/mix-sources',
    {
      Name: 'createMixSources';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createMixSources',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/mix-sources',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateInkibraRecordlessLibraryArrangement {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessLibraryArrangementType.Creation;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryArrangementType,
    StatusCode.CREATED
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/arrangements',
    {
      Name: 'createInkibraRecordlessLibraryArrangement';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createInkibraRecordlessLibraryArrangement',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/catalog/arrangements',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

/**
 * Ensures that there are conforming recordless arrangements for a given song.
 * If there are not, they are drafted using the conforming arrangement list,
 * the energy curve for the song, and `InkibraRecordlessArrangementType.proposeArrangementFromEnergyCurveRequest`.
 */
export namespace EnsureRecordlessSongHasConformingRecordlessArrangements {
  export type PathParams = { songId: InkibraRecordlessLibrarySongType['id'] };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryArrangementType[],
    StatusCode.OK
  >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/songs/:songId/ensure-conforming-arrangements',
    {
      Name: 'ensureRecordlessSongHasConformingRecordlessArrangements';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'ensureRecordlessSongHasConformingRecordlessArrangements',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/catalog/songs/:songId/ensure-conforming-arrangements',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace ModifyInkibraRecordlessLibraryArrangement {
  export type PathParams = { arrangementId: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = Pick<
    InkibraRecordlessLibraryArrangementType.Modification,
    | 'name'
    | 'numberOfRatings'
    | 'score'
    | 'proposedTransitionMap'
    | 'approvedTransitionSet'
    | 'sectionArrangements'
  >;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryArrangementType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/arrangements/:arrangementId',
    {
      Name: 'modifyInkibraRecordlessLibraryArrangement';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'modifyInkibraRecordlessLibraryArrangement',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.library/catalog/arrangements/:arrangementId',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetInkibraRecordlessArrangementSources {
  export type PathParams = { id: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type ARRANGEMENT_SOURCE_NOT_FOUND_ERROR_DESCRIPTOR = ErrorDescriptor<
    'ARRANGEMENT_SOURCE_NOT_FOUND',
    'Arrangement source not found',
    { id: string; missing: 'audio' | 'waveform' }
  >;
  export type CANNOT_RETURN_ARRANGEMENT_SOURCE_FOR_PROPOSAL_ERROR_DESCRIPTOR =
    ErrorDescriptor<
      'CANNOT_RETURN_ARRANGEMENT_SOURCE_FOR_PROPOSAL',
      'Cannot return arrangement source for proposal',
      { id: string }
    >;
  export type Response =
    | SerializableResult.OkWithStatusCode<
        {
          locator: InkibraRecordlessLibraryMusicFileRemoteBinaryLocator;
          waveform: number[];
        },
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        ARRANGEMENT_SOURCE_NOT_FOUND_ERROR_DESCRIPTOR,
        StatusCode.NOT_FOUND
      >
    | SerializableResult.ErrWithStatusCode<
        CANNOT_RETURN_ARRANGEMENT_SOURCE_FOR_PROPOSAL_ERROR_DESCRIPTOR,
        StatusCode.NOT_ACCEPTABLE
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/arrangement-sources/:id',
    {
      Name: 'getInkibraRecordlessArrangementSources';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getInkibraRecordlessArrangementSources',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/arrangement-sources/:id',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateInkibraRecordlessArrangementSources {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessLibraryArrangementType;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type ARRANGEMENT_SONG_SOURCE_NOT_FOUND_ERROR_DESCRIPTOR =
    ErrorDescriptor<
      'ARRANGEMENT_SONG_SOURCE_NOT_FOUND',
      'Arrangement song source not found',
      { songId: string }
    >;

  export type ARRANGEMENT_SOURCE_ALREADY_EXISTS_ERROR_DESCRIPTOR =
    ErrorDescriptor<
      'ARRANGEMENT_SOURCE_ALREADY_EXISTS',
      'Arrangement source already exists',
      { arrangementHash: string }
    >;

  export type Response =
    | SerializableResult.OkWithStatusCode<
        {
          locator: InkibraRecordlessLibraryMusicFileRemoteBinaryLocator;
          waveform: number[];
          arrangementOutputInfo: ArrangementOutputInfo;
        },
        StatusCode.CREATED
      >
    | SerializableResult.ErrWithStatusCode<
        ARRANGEMENT_SOURCE_ALREADY_EXISTS_ERROR_DESCRIPTOR,
        StatusCode.CONFLICT
      >
    | SerializableResult.ErrWithStatusCode<
        ARRANGEMENT_SONG_SOURCE_NOT_FOUND_ERROR_DESCRIPTOR,
        StatusCode.NOT_FOUND
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/arrangement-sources',
    {
      Name: 'createInkibraRecordlessArrangementSources';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createInkibraRecordlessArrangementSources',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/arrangement-sources',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}
export namespace PreviewInkibraRecordlessArrangementSources {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }
  export type PathQuery = {
    encode: boolean;
    waveformResolutionOverride: '8' | '16' | '32';
  };

  export type Body = InkibraRecordlessLibraryArrangementType;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SerializableResult.OkWithStatusCode<
    {
      locator: InkibraRecordlessLibraryMusicFileRemoteBinaryLocator;
      waveform: number[];
      arrangementOutputInfo: ArrangementOutputInfo;
    },
    StatusCode.CREATED
  >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/preview-arrangement-sources',
    {
      Name: 'previewInkibraRecordlessArrangementSources';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'previewInkibraRecordlessArrangementSources',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/preview-arrangement-sources',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace LockRecordlessArrangementAndUploadSources {
  export type PathParams = { arrangementId: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = {
    finalizedArrangement: Required<
      Pick<
        InkibraRecordlessLibraryArrangementType.Modification,
        | 'sectionArrangements'
        | 'approvedTransitionSet'
        | 'proposedTransitionMap'
        | 'computedArrangementOutputInfo'
      >
    >;
    waveform: number[];
  };

  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryArrangementType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/arrangements/:arrangementId/lock-and-upload',
    {
      Name: 'lockRecordlessArrangementAndUploadSources';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: {
        audio: {
          allowableMimeTypes: [MimeType.AUDIO_MP4];
        };
      };
      ResponseType: Response;
    }
  >({
    name: 'lockRecordlessArrangementAndUploadSources',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.library/catalog/arrangements/:arrangementId/lock-and-upload',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: {
      audio: {
        allowableMimeTypes: [MimeType.AUDIO_MP4],
      },
    },
    responseSchema: Response.schema,
  });
}

export namespace FindInkibraRecordlessLibraryArrangements {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = SimpleQuery & {
    librarySongId: InkibraRecordlessLibrarySongType['id'];
    arrangementStatus?: InkibraRecordlessLibraryArrangementType['arrangementStatus'];
  };

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryArrangementType[],
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/arrangements',
    {
      Name: 'findInkibraRecordlessLibraryArrangements';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'findInkibraRecordlessLibraryArrangements',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/catalog/arrangements',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetInkibraRecordlessArrangement {
  export type PathParams = { id: string };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessLibraryArrangementType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/catalog/arrangements/:id',
    {
      Name: 'getInkibraRecordlessArrangement';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getInkibraRecordlessArrangement',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.library/catalog/arrangements/:id',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace FillSongInformation {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type FillableSongInformation = Pick<
    InkibraRecordlessLibrarySongType,
    | 'activityWords'
    | 'artists'
    | 'bpm'
    | 'description'
    | 'discNumber'
    | 'energy'
    | 'genres'
    | 'key'
    | 'keywords'
    | 'moodWords'
    | 'tagline'
    | 'trackNumber'
    | 'year'
  >;

  export type PathQuery = {
    fieldRequest: keyof FillableSongInformation;
  };

  export type Body = Pick<
    InkibraRecordlessLibrarySongType,
    'albumArtist' | 'albumTitle' | 'title'
  >;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }
  export type AI_RESPONSE_FAILURE = ErrorDescriptor<
    'AI_RESPONSE_FAILURE',
    'Failed to validate AI response',
    { validationErrors: string[] }
  >;
  export type Response =
    | SerializableResult.OkWithStatusCode<
        Partial<FillableSongInformation>,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        AI_RESPONSE_FAILURE,
        StatusCode.SERVICE_UNAVAILABLE
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/fillSongInformation',
    {
      Name: 'fillSongInformation';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'fillSongInformation',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/fillSongInformation',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GenerateAnnouncementSource {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = {
    voice: 'Ryan - Motivational';
  };

  export type Body = {
    announcement: string;
  };
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }
  export type ANNOUNCEMENT_CREATE_FAILURE = ErrorDescriptor<
    'ANNOUNCEMENT_CREATE_FAILURE',
    'Failed to create AI spoken announcement',
    { reason: string }
  >;

  export type AnnouncementFileLocator = RemoteBinaryLocator<
    MimeType.AUDIO_MPEG,
    'INKIBRA_RECORDLESS_LIBRARY_ANNOUNCEMENT_BINARY_LOCATOR'
  >;

  export type Response =
    | SerializableResult.OkWithStatusCode<
        AnnouncementFileLocator,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        ANNOUNCEMENT_CREATE_FAILURE,
        StatusCode.SERVICE_UNAVAILABLE
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.library/announcement-source',
    {
      Name: 'generateAnnouncementSource';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'generateAnnouncementSource',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.library/announcement-source',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export const InkibraRecordlessLibraryApiRouteRegistry = GenericRegistry.init({
  getAllLibraryCatalogSongs: GetAllLibraryCatalogSongs.Route,
  setLibrarySongCatalogStatus: SetLibrarySongCatalogStatus.Route,
  getLibraryCatalogSong: GetLibraryCatalogSong.Route,
  getLibraryCatalogSongFile: GetLibraryCatalogSongFile.Route,
  getLibraryCatalogSongWaveform: GetLibraryCatalogSongWaveform.Route,
  createMixSources: CreateMixSources.Route,
  createCatalogSong: CreateCatalogSong.Route,
  modifyRecordlessSong: ModifyRecordlessSong.Route,
  createInkibraRecordlessLibraryArrangement:
    CreateInkibraRecordlessLibraryArrangement.Route,
  modifyInkibraRecordlessLibraryArrangement:
    ModifyInkibraRecordlessLibraryArrangement.Route,
  findInkibraRecordlessLibraryArrangements:
    FindInkibraRecordlessLibraryArrangements.Route,
  getInkibraRecordlessArrangementSources:
    GetInkibraRecordlessArrangementSources.Route,
  createInkibraRecordlessArrangementSources:
    CreateInkibraRecordlessArrangementSources.Route,
  getLibraryAlbumArtwork: GetLibraryAlbumArtwork.Route,
  modifyAlbumArtwork: ModifyAlbumArtwork.Route,
  getInkibraRecordlessArrangement: GetInkibraRecordlessArrangement.Route,
  fillSongInformation: FillSongInformation.Route,
  ensureRecordlessSongHasConformingRecordlessArrangements:
    EnsureRecordlessSongHasConformingRecordlessArrangements.Route,
  previewInkibraRecordlessArrangementSources:
    PreviewInkibraRecordlessArrangementSources.Route,
  lockRecordlessArrangementAndUploadSources:
    LockRecordlessArrangementAndUploadSources.Route,
  generateAnnouncementSource: GenerateAnnouncementSource.Route,
});

export const InkibraRecordlessLibraryApiFetcherRegistry =
  InkibraRecordlessLibraryApiRouteRegistry.matchWithAll((routes) => {
    return {
      ...routes.getAllLibraryCatalogSongs.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.setLibrarySongCatalogStatus.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.getLibraryCatalogSong.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.getLibraryCatalogSongFile.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.getLibraryCatalogSongWaveform.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.createMixSources.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.createCatalogSong.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.modifyRecordlessSong.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.createInkibraRecordlessLibraryArrangement.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.modifyInkibraRecordlessLibraryArrangement.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.findInkibraRecordlessLibraryArrangements.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.getInkibraRecordlessArrangementSources.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.createInkibraRecordlessArrangementSources.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.getLibraryAlbumArtwork.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.modifyAlbumArtwork.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.getInkibraRecordlessArrangement.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.fillSongInformation.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
      ...routes.ensureRecordlessSongHasConformingRecordlessArrangements.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.previewInkibraRecordlessArrangementSources.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.lockRecordlessArrangementAndUploadSources.setupHandler<void>(
        () => {
          throw new Error('Not implemented');
        },
      ),
      ...routes.generateAnnouncementSource.setupHandler<void>(() => {
        throw new Error('Not implemented');
      }),
    };
  });
