/** @jsxImportSource react */
import { Route, Routes } from 'react-router-dom';
import WaitlistDisplay from './waitlist/waitlist-display';
import WaitlistHeader from './waitlist/waitlist-header';
import WaitlistList from './waitlist/waitlist-list';

export default function WaitlistIndex() {
  return (
    <>
      <WaitlistHeader />
      <Routes>
        <Route path="/" element={<WaitlistList />} />
        <Route path="/:waitlistId" element={<WaitlistDisplay />} />
      </Routes>
    </>
  );
}
