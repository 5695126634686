/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CSS, useSortable } from '@inkibra/recordless.ux';
import { InkibraRecordlessWorkoutIntervalType } from '../type';
import { DragHandle } from './icons/drag-handle';
import { VerticalMenu } from './icons/vertical-menu';

const styles = css({
  display: 'grid',
  gridTemplateColumns: 'min-content 1fr min-content',
  gap: '20px',
  borderRadius: '7px',
  background:
    'linear-gradient(90deg, #3F16E1 19.56%, #08031D 115.06%), linear-gradient(90deg, #3F16E1 0%, #08031D 100%)',
  padding: '20px',
  alignItems: 'center',
});

const contentStyles = css({
  display: 'grid',
  gridTemplateColumns: '1fr min-content',
  color: '#F2F2F7',
  alignItems: 'center',
});

const titleStyles = css({
  fontWeight: '700',
  fontSize: '16px',
  lineHeight: '100%',
  textTransform: 'uppercase',
  textAlign: 'left',
});

const timeStyles = css({
  fontWeight: '400',
  fontSize: '14px',
  lineHeight: '100%',
  textAlign: 'right',
  whiteSpace: 'nowrap',
});

export type EditableIntervalListItemProps = {
  index: number;
  sortKey: string;
  interval: InkibraRecordlessWorkoutIntervalType;
  onIntervalMenuClick: (
    index: number,
    { x, y }: { x: number; y: number },
  ) => void;
  editLocked: boolean;
};

export function EditableIntervalListItem({
  index,
  sortKey,
  interval,
  onIntervalMenuClick,
  editLocked,
}: EditableIntervalListItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: sortKey, disabled: editLocked });

  const handleIntervalMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onIntervalMenuClick(index, { x: e.clientX, y: e.clientY });
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      css={styles}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
    >
      <DragHandle opacity={editLocked ? 0 : 1} />

      <div css={contentStyles}>
        <div css={titleStyles}>{interval.name}</div>
        <div css={timeStyles}>{Math.ceil(interval.time / 60)}MIN</div>
      </div>

      <div onClick={handleIntervalMenuClick}>
        <VerticalMenu opacity={editLocked ? 0 : 1} />
      </div>
    </div>
  );
}
