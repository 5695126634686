import { FetchProvider, LoggerContext } from '@inkibra/api-base';
import * as Logger from '@inkibra/logger';
import {
  InkibraSessionApiFetcherRegistry,
  InkibraSessionApiRouteRegistry,
  initLogForwarding,
} from '@inkibra/recordless.auth-api';
import {
  InkibraRecordlessLibraryApiFetcherRegistry,
  InkibraRecordlessLibraryApiRouteRegistry,
} from '@inkibra/recordless.library-api';
import {
  InkibraRecordlessTempoApiFetcherRegistry,
  InkibraRecordlessTempoApiRouteRegistry,
} from '@inkibra/recordless.tempo-api';
import {
  InkibraRecordlessUserApiFetcherRegistry,
  InkibraRecordlessUserApiRouteRegistry,
} from '@inkibra/recordless.user-api';
import {
  InkibraRecordlessWaitlistApiFetcherRegistry,
  InkibraRecordlessWaitlistApiRouteRegistry,
} from '@inkibra/recordless.waitlist-api';
import { InkibraStorageProxy } from '@inkibra/storage-proxy';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Connector } from '../common/lib/connector';
import Index, { OUTLET_ID } from './index';

const logger = Logger.init('admin.client', 'debug');
const connector = new Connector();
const fetchProvider = new FetchProvider({
  Request,
  Response,
  Headers,
  storage: InkibraStorageProxy.makeStorageProxy<Connector.ConnectionStorage>(
    Connector.STORAGE_GROUP,
    {
      authorization: undefined,
      segment: 'none', // document.getElementById('site-outlet')?.getAttribute('data-segment')
    },
    sessionStorage,
  ),
  fetch: window.fetch.bind(window),
  domain: window.location.hostname,
  port: window.location.port
    ? parseInt(window.location.port)
    : window.location.protocol === 'https:'
      ? 443
      : 80,
  protocol: window.location.protocol as 'http:' | 'https:',
  logger,
});

InkibraSessionApiFetcherRegistry.implement(
  InkibraSessionApiRouteRegistry.match()
    .with('GetCurrentInkibraSession', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('IdentifyInkibraVisitorSession', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('SendInkibraSessionAuthenticationOrRecoveryCode', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('VerifyInkibraSessionAuthenticationCode', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('VerifyInkibraSessionRecoveryCode', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('ResumeInkibraSession', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('SetInkibraSessionPassword', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('DowngradeInkibraSession', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('SendInkibraRecordlessSessionEvent', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .exhaustive(),
);

initLogForwarding(logger, 'debug', fetchProvider.clientId);

InkibraRecordlessUserApiFetcherRegistry.implement(
  InkibraRecordlessUserApiRouteRegistry.match()
    .with('createInkibraRecordlessUser', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessUser', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessUserByOwner', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('modifyInkibraRecordlessUser', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .exhaustive(),
);

InkibraRecordlessWaitlistApiFetcherRegistry.implement(
  InkibraRecordlessWaitlistApiRouteRegistry.match()
    .with('createInkibraRecordlessWaitlist', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('attachWalletAddressToInkibraRecordlessWaitlistEntry', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('changeOpenStateInkibraRecordlessWaitlist', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('confirmInkibraRecordlessWaitlistEntry', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('createInkibraRecordlessWaitlistEntry', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getAllInkibraRecordlessWaitlistEntries', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getAllInkibraRecordlessWaitlists', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessWaitlist', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessWaitlistEntry', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .exhaustive(),
);

InkibraRecordlessLibraryApiFetcherRegistry.implement(
  InkibraRecordlessLibraryApiRouteRegistry.match()
    .with('getAllLibraryCatalogSongs', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getLibraryCatalogSong', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getLibraryCatalogSongFile', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('setLibrarySongCatalogStatus', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('createMixSources', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('createCatalogSong', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getLibraryCatalogSongWaveform', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('modifyRecordlessSong', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('createInkibraRecordlessLibraryArrangement', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('modifyInkibraRecordlessLibraryArrangement', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('findInkibraRecordlessLibraryArrangements', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('createInkibraRecordlessArrangementSources', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessArrangementSources', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getLibraryAlbumArtwork', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('modifyAlbumArtwork', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessArrangement', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('fillSongInformation', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with(
      'ensureRecordlessSongHasConformingRecordlessArrangements',
      (route) => {
        return route.handle<void>(async (args) => {
          return fetchProvider.createRouteHandler(route)(args);
        });
      },
    )
    .with('lockRecordlessArrangementAndUploadSources', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('previewInkibraRecordlessArrangementSources', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('generateAnnouncementSource', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .exhaustive(),
);

InkibraRecordlessTempoApiFetcherRegistry.implement(
  InkibraRecordlessTempoApiRouteRegistry.match()
    .with('getAllInkibraRecordlessWorkouts', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessWorkouts', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('getInkibraRecordlessWorkout', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('createInkibraRecordlessWorkout', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('modifyInkibraRecordlessWorkout', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('deleteInkibraRecordlessWorkout', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .with('createMixForWorkout', (route) => {
      return route.handle<void>(async (args) => {
        return fetchProvider.createRouteHandler(route)(args);
      });
    })
    .exhaustive(),
);

connector.maybeFetchProvider = fetchProvider;
connector.cache.connect();

function render() {
  const outlet = document.getElementById(OUTLET_ID);
  if (outlet) {
    ReactDOM.hydrateRoot(
      outlet,
      <React.StrictMode>
        <LoggerContext.Provider value={() => logger}>
          <Connector.Context.Provider value={connector}>
            <BrowserRouter>
              <Index />
            </BrowserRouter>
          </Connector.Context.Provider>
        </LoggerContext.Provider>
      </React.StrictMode>,
    );
  } else {
    throw new ReferenceError(OUTLET_ID);
  }
}

window.onload = () => {
  render();
};
