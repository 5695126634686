/** @jsxImportSource react */
import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { HookEffectContext } from '@inkibra/api-base';
import {
  InkibraRecordlessLibrarySongType,
  useLibrarySongs,
} from '@inkibra/recordless.library-api';
import { Connector } from '../../common/lib/connector';

// const Artwork = (params: any) => {
//   return <Avatar
//     aria-label="song artwork"
//     src={`${InkibraRecordlessCatalog.Api.downloadInkibraRecordlessCatalogArtwork.path.replace(':id', (params as { id: string }).id)}`}
//   />
// }

// biome-ignore lint/suspicious/noExplicitAny: TODO: fix this
const EditButton = (params: any) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      style={{ marginLeft: 16 }}
      component={Link}
      to={`/admin/library/${(params as { id: string }).id}`}
    >
      Open
    </Button>
  );
};

const columns: GridColDef[] = [
  // {
  //   field: 'artwork',
  //   headerName: 'Artwork',
  //   width: 60,
  //   renderCell: Artwork
  // },
  { field: 'id', headerName: 'ID', width: 325 },
  { field: 'title', headerName: 'Song Name', width: 200 },
  { field: 'artist', headerName: 'Artist', width: 150 },
  {
    field: 'catalogStatus',
    headerName: 'Catalog Status',
    width: 150,
    renderCell: (params) => {
      const status =
        params.value as InkibraRecordlessLibrarySongType.CatalogStatus;
      return (
        <div>
          {InkibraRecordlessLibrarySongType.CatalogStatus.toString(status)}{' '}
        </div>
      );
    },
  },
  { field: 'created', headerName: 'Date Created', sortable: false, width: 275 },
  {
    field: 'editButton',
    headerName: 'Edit',
    width: 100,
    renderCell: EditButton,
  },
];

export default function LibrarySongList() {
  const connector = Connector.useContext();
  const hookEffectContext = new HookEffectContext();
  const librarySongList = hookEffectContext.addEffect(useLibrarySongs());
  hookEffectContext.runEffects(connector.cache);

  console.log('LibrarySongList', librarySongList);
  return (
    <div style={{ display: 'flex', height: '80vh' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid rows={librarySongList} columns={columns} checkboxSelection />
      </div>
    </div>
  );
}
