"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchFromUrl = exports.parseBlob = exports.parseReadableStream = exports.parseNodeStream = exports.selectCover = exports.ratingToStars = exports.orderTags = exports.parseFromTokenizer = exports.parseBuffer = void 0;
const initDebug = require("debug");
const mm = require("music-metadata/lib/core");
const readable_web_to_node_stream_1 = require("readable-web-to-node-stream");
const debug = initDebug('music-metadata-browser:main');
var core_1 = require("music-metadata/lib/core");
Object.defineProperty(exports, "parseBuffer", { enumerable: true, get: function () { return core_1.parseBuffer; } });
Object.defineProperty(exports, "parseFromTokenizer", { enumerable: true, get: function () { return core_1.parseFromTokenizer; } });
Object.defineProperty(exports, "orderTags", { enumerable: true, get: function () { return core_1.orderTags; } });
Object.defineProperty(exports, "ratingToStars", { enumerable: true, get: function () { return core_1.ratingToStars; } });
Object.defineProperty(exports, "selectCover", { enumerable: true, get: function () { return core_1.selectCover; } });
/**
 * Parse audio Stream
 * @param stream - ReadableStream
 * @param contentType - MIME-Type
 * @param options - Parsing options
 * @returns Metadata
 */
exports.parseNodeStream = mm.parseStream;
/**
 * Parse Web API ReadableStream: https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream
 * @param stream - ReadableStream (web stream according WTWG Streams Standard)
 * @param fileInfo FileInfo object or MIME-Type
 * @param options - Parsing options
 * @returns Metadata
 */
async function parseReadableStream(stream, fileInfo, options) {
    const ns = new readable_web_to_node_stream_1.ReadableWebToNodeStream(stream);
    const res = await (0, exports.parseNodeStream)(ns, typeof fileInfo === 'string' ? { mimeType: fileInfo } : fileInfo, options);
    await ns.close();
    return res;
}
exports.parseReadableStream = parseReadableStream;
/**
 * Parse Web API File
 * @param blob - Blob to parse
 * @param options - Parsing options
 * @returns Metadata
 */
async function parseBlob(blob, options) {
    const fileInfo = { mimeType: blob.type, size: blob.size };
    if (blob instanceof File) {
        fileInfo.path = blob.name;
    }
    const stream = (blob.stream ? blob.stream() : convertBlobToReadableStream(blob));
    return parseReadableStream(stream, { mimeType: blob.type, size: blob.size }, options);
}
exports.parseBlob = parseBlob;
/**
 * Convert Blob to ReadableStream
 * Fallback for Safari versions < 14.1
 * @param blob
 */
function convertBlobToReadableStream(blob) {
    const fileReader = new FileReader();
    return new ReadableStream({
        start(controller) {
            // The following function handles each data chunk
            fileReader.onloadend = event => {
                let data = event.target.result;
                if (data instanceof ArrayBuffer) {
                    data = new Uint8Array(data);
                }
                controller.enqueue(data);
                controller.close();
            };
            fileReader.onerror = error => {
                controller.close();
            };
            fileReader.onabort = error => {
                controller.close();
            };
            fileReader.readAsArrayBuffer(blob);
        }
    });
}
/**
 * Parse fetched file, using the Web Fetch API
 * @param audioTrackUrl - URL to download the audio track from
 * @param options - Parsing options
 * @returns Metadata
 */
async function fetchFromUrl(audioTrackUrl, options) {
    const response = await fetch(audioTrackUrl);
    const fileInfo = {
        size: parseInt(response.headers.get('Content-Length'), 10),
        mimeType: response.headers.get('Content-Type')
    };
    if (response.ok) {
        if (response.body) {
            const res = await parseReadableStream(response.body, fileInfo, options);
            debug('Closing HTTP-readable-stream...');
            if (!response.body.locked) { // Prevent error in Firefox
                await response.body.cancel();
            }
            debug('HTTP-readable-stream closed.');
            return res;
        }
        else {
            // Fall back on Blob
            return parseBlob(await response.blob(), options);
        }
    }
    else {
        throw new Error(`HTTP error status=${response.status}: ${response.statusText}`);
    }
}
exports.fetchFromUrl = fetchFromUrl;
