import { LoggerContext } from '@inkibra/api-base';
import { Bunyan } from '@inkibra/logger';
import { Brand, CacheableObject } from '@inkibra/observable-cache';
import { Result } from 'neverthrow';
import React, {
  Ref,
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import {
  CreateMixSources,
  InkibraRecordlessLibraryApiFetcherRegistry,
} from '../api';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibraryMixType } from '../type-mix';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import {
  ClientPlaybackDriver,
  DriverPlaybackState,
} from './client-playback-driver';

export type Playable =
  | InkibraRecordlessLibraryArrangementType
  | InkibraRecordlessLibraryMixType
  | InkibraRecordlessLibrarySongType;

export enum LoadingState {
  UNLOADED = 'UNLOADED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export type LoadedAsyncPlayable<
  TPlayableAnnotation = undefined,
  TPlayable extends Playable = Playable,
> = {
  entryId: Brand<'nkrplayable'>;
  state: LoadingState.LOADED;
  playable: TPlayable;
  annotation: TPlayableAnnotation;
};

export type UnloadedAsyncPlayable<
  TPlayableAnnotation = undefined,
  TLoadingError = undefined,
  TPlayable extends Playable = Playable,
> = {
  entryId: Brand<'nkrplayable'>;
  state: LoadingState.UNLOADED;
  annotation: TPlayableAnnotation;
  loader: () => Promise<
    Result<LoadedAsyncPlayable<TPlayableAnnotation, TPlayable>, TLoadingError>
  >;
};

export type AsyncPlayable<
  TPlayableAnnotation = undefined,
  TLoadingError = undefined,
> =
  | {
      entryId: Brand<'nkrplayable'>;
      state: LoadingState.LOADING | LoadingState.ERROR;
      annotation: TPlayableAnnotation;
    }
  | LoadedAsyncPlayable<TPlayableAnnotation>
  | UnloadedAsyncPlayable<TPlayableAnnotation, TLoadingError>;

async function ensureMixCreation(
  jobId: string,
  mix: InkibraRecordlessLibraryMixType,
  mixWindowOptions?: {
    startWithNodeAtTime: number;
    endWithNodeAtTime: number;
  },
  callDepth = 0,
): Promise<CreateMixSources.CreateMixSourcesResult> {
  if (callDepth > 0) {
    await new Promise((resolve) => setTimeout(resolve, 5000));
  }
  // TODO: make this number configurable
  // If we have called this endpoint more than 50 times, we should stop
  if (callDepth > 50) {
    throw new Error('Failed to create mix');
  }

  const mixSourcesJobContinuation =
    await InkibraRecordlessLibraryApiFetcherRegistry.get('createMixSources').fn(
      {
        body: mix,
        files: undefined,
        pathParams: {},
        pathQuery: { jobId, mixWindowOptions },
      },
    );

  if (mixSourcesJobContinuation.type === 'Err') {
    throw mixSourcesJobContinuation.error;
  }

  if (mixSourcesJobContinuation.value.status !== 'complete') {
    return ensureMixCreation(jobId, mix, mixWindowOptions, callDepth + 1);
  }

  return mixSourcesJobContinuation.value;
}

function loadedComputedDataForMix(
  playable: InkibraRecordlessLibraryMixType,
  mixSources: CreateMixSources.CreateMixSourcesResult,
) {
  playable.computedMixOutputInfo = mixSources.result.mixOutputInfo;
  return {
    playable,
    locator: mixSources.result.locator,
    waveform: mixSources.result.waveform,
  };
}

async function getPlayableSources(logger: Bunyan, playable: Playable) {
  switch (playable?.type) {
    case 'nkrarrangement': {
      logger.debug('about to get sources');
      const sources = await InkibraRecordlessLibraryApiFetcherRegistry.get(
        'getInkibraRecordlessArrangementSources',
      ).fn({
        body: undefined,
        files: undefined,
        pathParams: {
          id: playable.id,
        },
        pathQuery: {},
      });
      logger.debug('got sources', sources);
      if (sources.type === 'Err') {
        throw sources.error;
      }
      logger.debug('returning sources', sources.value);
      return { ...sources.value, playable };
    }
    case 'nkrmix': {
      const mixSources = await ensureMixCreation(
        CacheableObject.makeId(),
        playable,
      );

      return loadedComputedDataForMix(playable, mixSources);
    }
    case 'nkrsong': {
      const songLocatorResponse =
        await InkibraRecordlessLibraryApiFetcherRegistry.get(
          'getLibraryCatalogSongFile',
        ).fn({
          body: undefined,
          files: undefined,
          pathParams: { songId: playable.id },
          pathQuery: {},
        });
      const waveformResponse =
        await InkibraRecordlessLibraryApiFetcherRegistry.get(
          'getLibraryCatalogSongWaveform',
        ).fn({
          body: undefined,
          files: undefined,
          pathParams: { songId: playable.id },
          pathQuery: { waveformResolutionOverride: '16' },
        });
      return {
        playable,
        locator: songLocatorResponse.value,
        waveform: waveformResponse.value,
      };
    }
  }
}

export type PlaybackState<TPlayableAnnotation = undefined> = {
  time: number;
  isPlaying: boolean;
  volume: number;
  completed: boolean;
  waveform: number[];
  nowPlaying?: LoadedAsyncPlayable<TPlayableAnnotation>;
  swapping: boolean;
};

export type QueueState<TPlayableAnnotation = undefined> = {
  history: LoadedAsyncPlayable<TPlayableAnnotation>[];
  nowPlaying?: LoadedAsyncPlayable<TPlayableAnnotation>;
  queue: AsyncPlayable<TPlayableAnnotation>[];
};

export type AudioDataState<TPlayableAnnotation = undefined> =
  | {
      waveform: number[];
      loadingState: LoadingState.UNLOADED | LoadingState.LOADING;
      playable: LoadedAsyncPlayable<TPlayableAnnotation>;
      audioDataUrl?: string;
      error?: Error;
    }
  | {
      waveform: number[];
      loadingState: LoadingState.LOADED;
      playable: LoadedAsyncPlayable<TPlayableAnnotation>;
      audioDataUrl: string;
      error?: Error;
    }
  | {
      waveform: number[];
      loadingState: LoadingState.ERROR;
      playable: LoadedAsyncPlayable<TPlayableAnnotation>;
      audioDataUrl?: string;
      error: Error;
    };

export type AudioDataStateMap<TPlayableAnnotation = undefined> = {
  [playableId: Playable['id']]: AudioDataState<TPlayableAnnotation>;
};

export type LibraryPlayerController<TPlayableAnnotation> = {
  setVolume: (volume: number) => void;
  pause: () => void;
  resume: () => Promise<void> | void;
  skip: () => Promise<void>;
  skipTo: (asyncPlayableQueueId: AsyncPlayable['entryId']) => Promise<void>;
  seek: (time: number) => void;
  /**
   * Stop the current playback and removes the now playing item
   */
  stop: () => void;
  /**
   * Clear the playback queue
   */
  clearQueue: () => void;
  updateNowPlayingAnnotation: (annotation: TPlayableAnnotation) => void;
  asyncSwapNowPlayingMix: (
    playable: UnloadedAsyncPlayable<
      TPlayableAnnotation,
      undefined,
      InkibraRecordlessLibraryMixType
    >,
    mixWindowOptions?: {
      startWithNodeAtTime: number;
      endWithNodeAtTime: number;
    },
  ) => Promise<void>;
  /**
   * Add a playable to the front of the queue
   */
  addPlayNext: (
    playable: Playable,
    annotation: TPlayableAnnotation,
    playWhenLoaded: boolean,
    entryId?: Brand<'nkrplayable'>,
  ) => void;
  addAsyncPlayNext: (
    asyncPlayable: UnloadedAsyncPlayable<TPlayableAnnotation>,
    playWhenLoaded: boolean,
  ) => void;
  /**
   * Add a playable to the end of the queue
   */
  addToQueue: (
    playable: Playable,
    annotation: TPlayableAnnotation,
    entryId?: Brand<'nkrplayable'>,
  ) => void;
  addAsyncToQueue: (
    asyncPlayable: UnloadedAsyncPlayable<TPlayableAnnotation>,
  ) => void;
  subscribeToPlaybackState: (
    callback: (state: PlaybackState<TPlayableAnnotation>) => void,
  ) => (state: PlaybackState<TPlayableAnnotation>) => void;
  unsubscribeFromPlaybackState: (
    callback?: (state: PlaybackState<TPlayableAnnotation>) => void,
  ) => void;
  subscribeToAudioDataState: (
    callback: (state: AudioDataStateMap<TPlayableAnnotation>) => void,
  ) => (state: AudioDataStateMap<TPlayableAnnotation>) => void;
  unsubscribeFromAudioDataState: (
    callback?: (state: AudioDataStateMap<TPlayableAnnotation>) => void,
  ) => void;
  subscribeToQueueState: (
    callback: (state: QueueState<TPlayableAnnotation>) => void,
  ) => (state: QueueState<TPlayableAnnotation>) => void;
  unsubscribeFromQueueState: (
    callback?: (state: QueueState<TPlayableAnnotation>) => void,
  ) => void;
  setRadioMode: (mode: 'off' | 'on') => void;
  getCurrentState: () => {
    playbackState: PlaybackState<TPlayableAnnotation>;
    queueState: QueueState<TPlayableAnnotation>;
    audioDataStateMap: AudioDataStateMap<TPlayableAnnotation>;
  };
};

function fixedForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => React.ReactNode,
): (props: P & React.RefAttributes<T>) => React.ReactNode {
  // biome-ignore lint/suspicious/noExplicitAny: any is necessary here
  return forwardRef(render) as any;
}

const DEFAULT_PRELOAD_AHEAD_COUNT = 3;
export const InkibraRecordlessLibraryClientPlayerElement = fixedForwardRef(
  // biome-ignore lint/complexity/noUselessTypeConstraint: This is necessary for the type to be inferred correctly
  <TPlayableAnnotation extends unknown>(
    {
      preloadAheadCount = DEFAULT_PRELOAD_AHEAD_COUNT,
    }: { preloadAheadCount?: number },
    ref: Ref<LibraryPlayerController<TPlayableAnnotation>>,
  ) => {
    const logger = useContext(LoggerContext)().child({
      component: 'client-player-element',
    });
    const clientPlaybackDriver = useRef<ClientPlaybackDriver>();
    const [audioDataStateMap, setAudioDataStateMap] = useState<
      AudioDataStateMap<TPlayableAnnotation>
    >({});
    const [nowPlaying, setNowPlaying] =
      useState<LoadedAsyncPlayable<TPlayableAnnotation>>();
    const [shouldPlayNextWhenLoaded, setShouldPlayNextWhenLoaded] =
      useState<boolean>(false);
    const [playbackQueue, setPlaybackQueue] = useState<
      AsyncPlayable<TPlayableAnnotation>[]
    >([]);
    const [playbackHistory, setPlaybackHistory] = useState<
      LoadedAsyncPlayable<TPlayableAnnotation>[]
    >([]);
    const [radioMode, setRadioMode] = useState<'off' | 'on'>('off');
    const [playbackStateSubscribers, setPlaybackStateSubscribers] = useState<
      Array<(state: PlaybackState<TPlayableAnnotation>) => void>
    >([]);
    const [audioDataStateSubscribers, setAudioDataStateSubscribers] = useState<
      Array<(state: AudioDataStateMap<TPlayableAnnotation>) => void>
    >([]);
    const [queueStateSubscribers, setQueueStateSubscribers] = useState<
      Array<(state: QueueState<TPlayableAnnotation>) => void>
    >([]);
    const [swapping, setSwapping] = useState(false);

    // Setup the playback driver
    useEffect(() => {
      clientPlaybackDriver.current = new ClientPlaybackDriver();
      return () => {
        clientPlaybackDriver.current?.destroy();
      };
    }, []);

    // Setup the playback driver time updates
    useEffect(
      function setupTimeUpdates() {
        const currentlyPlayingPlayable = nowPlaying?.playable;
        const currentlyPlayingPlayableWaveform = currentlyPlayingPlayable
          ? audioDataStateMap[currentlyPlayingPlayable.id]?.waveform
          : [];

        const unsubscribeFromTimeUpdates =
          clientPlaybackDriver.current?.subscribeToTimeUpdates((timeUpdate) => {
            if (timeUpdate.playbackState === DriverPlaybackState.Swapping) {
              return;
            }
            // Update the media session playback time
            if (navigator.mediaSession) {
              // TODO: can we handle artwork here?
              navigator.mediaSession.playbackState =
                timeUpdate.playbackState === DriverPlaybackState.Playing
                  ? 'playing'
                  : 'paused';
              try {
                navigator.mediaSession.setPositionState({
                  duration: timeUpdate.duration,
                  position: timeUpdate.time > 0 ? timeUpdate.time : 0,
                });
              } catch (error) {
                console.error('error setting media session position state', {
                  error,
                  timeUpdate,
                  nowPlaying,
                });
              }
            }
            const maybeNextPlayableInQueue = playbackQueue.at(0);
            const completed = timeUpdate.time >= timeUpdate.duration;
            if (maybeNextPlayableInQueue && completed) {
              // TODO: let's setup a crossfade here
              setShouldPlayNextWhenLoaded(true);
            } else if (completed && nowPlaying) {
              setPlaybackHistory((history) => [...history, nowPlaying]);
              setNowPlaying(undefined);
            }

            playbackStateSubscribers.forEach((subscriber) => {
              if (timeUpdate.playableId !== nowPlaying?.entryId) {
                console.warn(
                  'Swapping still in progress, skipping time updates',
                  'timeUpdate.playableId',
                  timeUpdate.playableId,
                  'nowPlaying?.entryId',
                  nowPlaying?.entryId,
                );
                return;
              }

              subscriber({
                time: timeUpdate.time,
                isPlaying:
                  timeUpdate.playbackState === DriverPlaybackState.Playing,
                volume: 0,
                completed,
                waveform: currentlyPlayingPlayableWaveform || [],
                nowPlaying,
                swapping,
              });
            });
          });

        return () => {
          unsubscribeFromTimeUpdates?.();
        };
      },
      [
        clientPlaybackDriver.current,
        playbackStateSubscribers,
        nowPlaying,
        audioDataStateMap,
        swapping,
        playbackQueue,
      ],
    );

    // Play the play next when loaded item if it is present and loaded
    useEffect(
      function ensurePlayNextWhenLoadedIsPlaying() {
        const playNextWhenLoaded = playbackQueue.at(0);
        const currentClientPlaybackDriver = clientPlaybackDriver.current;
        if (
          playNextWhenLoaded?.state !== LoadingState.LOADED ||
          !shouldPlayNextWhenLoaded ||
          currentClientPlaybackDriver === undefined
        ) {
          return;
        }

        const audioDataState =
          audioDataStateMap[playNextWhenLoaded.playable.id];
        if (
          !audioDataState ||
          audioDataState.loadingState !== LoadingState.LOADED
        ) {
          return;
        }

        // Load and play the source in the driver
        currentClientPlaybackDriver
          .loadSource(playNextWhenLoaded.entryId, audioDataState.audioDataUrl)
          .then(async (loadedSourceReference) => {
            console.log('loadedSourceReference', loadedSourceReference);
            currentClientPlaybackDriver.playOrSwapSource(loadedSourceReference);

            const driverPlaybackInfo =
              currentClientPlaybackDriver.getPlaybackInfo();
            console.log('driverPlaybackInfo', driverPlaybackInfo);
            currentClientPlaybackDriver.resume();
            if (nowPlaying) {
              setPlaybackHistory((history) => [...history, nowPlaying]);
            }
            setNowPlaying(playNextWhenLoaded);
            setPlaybackQueue((queue) =>
              queue.filter(
                (item) => item.entryId !== playNextWhenLoaded.entryId,
              ),
            );
            setShouldPlayNextWhenLoaded(false);
          });
      },
      [
        audioDataStateMap,
        shouldPlayNextWhenLoaded,
        clientPlaybackDriver.current,
      ],
    );

    /**
     * Ensure that the queue is loaded with audio data
     */
    useEffect(
      function ensureQueueAudioDataLoaded() {
        // See if we are currently loading any audio data
        const currentlyLoadingAudioData = Object.values(audioDataStateMap).find(
          (state) => state.loadingState === LoadingState.LOADING,
        );

        // If we are currently loading audio data, return
        if (currentlyLoadingAudioData) {
          return;
        }

        // Find the loaded async playables in the queue within the preload ahead count
        const loadedPlayables = playbackQueue
          .slice(0, preloadAheadCount)
          .filter((item) => item.state === LoadingState.LOADED);

        const nextLoadedPlayableWithoutAudioData = loadedPlayables.find(
          (item) => {
            const audioDataState = audioDataStateMap[item.playable.id];
            return (
              !audioDataState ||
              audioDataState.loadingState === LoadingState.UNLOADED
            );
          },
        );

        if (!nextLoadedPlayableWithoutAudioData) {
          return;
        }

        // Start loading the item
        // Update the audioDataStateMap with the new loading state
        const existingItemState: AudioDataState<TPlayableAnnotation> = {
          loadingState: LoadingState.LOADING,
          waveform: [],
          playable: nextLoadedPlayableWithoutAudioData,
        };

        setAudioDataStateMap((stateMap) => {
          const newStateMap: AudioDataStateMap<TPlayableAnnotation> = {
            ...stateMap,
            [nextLoadedPlayableWithoutAudioData.playable.id]: existingItemState,
          };
          return newStateMap;
        });

        const audioSourcesPromise = getPlayableSources(
          logger,
          nextLoadedPlayableWithoutAudioData.playable,
        );

        audioSourcesPromise.then((audioSources) => {
          const updatedEntry: AudioDataState<TPlayableAnnotation> = {
            loadingState: LoadingState.LOADED,
            playable: nextLoadedPlayableWithoutAudioData,
            waveform: audioSources.waveform,
            audioDataUrl: audioSources.locator.url,
          };

          // Update the audioDataStateMap with the new loaded state
          setAudioDataStateMap((stateMap) => {
            const newStateMap: AudioDataStateMap<TPlayableAnnotation> = {
              ...stateMap,
              [nextLoadedPlayableWithoutAudioData.playable.id]: updatedEntry,
            };
            return newStateMap;
          });
        });
      },
      [playbackQueue, audioDataStateMap],
    );

    /**
     * Notify subscribers of the audio data state
     */
    useEffect(
      function notifyAudioDataStateSubscribers() {
        audioDataStateSubscribers.forEach((subscriber) => {
          subscriber(audioDataStateMap);
        });
      },
      [audioDataStateMap, audioDataStateSubscribers],
    );

    useEffect(() => {
      if (!navigator.mediaSession) {
        return;
      }
      const currentClientPlaybackDriver = clientPlaybackDriver.current;
      if (!currentClientPlaybackDriver) {
        return;
      }
      if (radioMode === 'on') {
        navigator.mediaSession.setActionHandler('seekto', (event) => {
          console.log('seekto', event);
          if (event.seekTime !== undefined) {
            currentClientPlaybackDriver.seek(event.seekTime);
          }
        });
        navigator.mediaSession.setActionHandler('seekbackward', (event) => {
          console.log('seekbackward', event);
          if (event.seekTime !== undefined) {
            currentClientPlaybackDriver.seek(event.seekTime);
          }
        });
        navigator.mediaSession.setActionHandler('seekforward', (event) => {
          console.log('seekforward', event);
          if (event.seekTime !== undefined) {
            currentClientPlaybackDriver.seek(event.seekTime);
          }
        });
      } else if (radioMode === 'off') {
        // TODO: handle this with the playback driver
        navigator.mediaSession.setActionHandler('seekto', null);
        navigator.mediaSession.setActionHandler('seekbackward', null);
        navigator.mediaSession.setActionHandler('seekforward', null);
      }
    }, [radioMode]);

    /**
     * Load an unloaded async playable from the queue
     */
    useEffect(
      function loadAsyncPlayableFromQueue() {
        // scan the queue to see if we have an unloaded item
        const unloadedItem = playbackQueue.find(
          (item) => item.state === LoadingState.UNLOADED,
        );
        if (!unloadedItem || unloadedItem.state !== LoadingState.UNLOADED) {
          return;
        }
        // scan the queue to make sure we are not already loading an item
        const loadingItem = playbackQueue.find(
          (item) => item.state === LoadingState.LOADING,
        );
        if (loadingItem) {
          // we will only load one item at a time
          return;
        }
        // mark the item as loading
        const loadingItemUpdate: AsyncPlayable<TPlayableAnnotation> = {
          ...unloadedItem,
          state: LoadingState.LOADING,
        };
        console.log('----- loading item -----', loadingItemUpdate);
        setPlaybackQueue((queue) =>
          queue.map((item) =>
            item.entryId === unloadedItem.entryId ? loadingItemUpdate : item,
          ),
        );

        // load the item
        unloadedItem.loader().then((result) => {
          if (result.isOk()) {
            // replace the item in the queue with the loaded item
            console.log('loading item into playback queue', result.value);
            setPlaybackQueue((queue) =>
              queue.map((item) =>
                item.entryId === unloadedItem.entryId ? result.value : item,
              ),
            );
          } else {
            // if it is an error replace the item with the error
            setPlaybackQueue((queue) =>
              queue.map((item) =>
                item.entryId === unloadedItem.entryId
                  ? { ...item, state: LoadingState.ERROR }
                  : item,
              ),
            );
          }
        });
      },
      [playbackQueue],
    );

    useEffect(() => {
      // Notify subscribers of the queue state
      queueStateSubscribers.forEach((subscriber) => {
        subscriber({
          history: playbackHistory,
          nowPlaying,
          queue: playbackQueue,
        });
      });
    }, [playbackHistory, playbackQueue, nowPlaying]);

    // TODO: unload data that are no longer in queue or being played and that are old in history

    useImperativeHandle(
      ref,
      () => {
        return {
          setVolume: (_: number) => {
            // TODO: implement
          },
          resume: async () => {
            if (clientPlaybackDriver.current) {
              // If nothing is playing, move the first item in queue to play next when loaded
              if (!nowPlaying) {
                const firstItemInQueue = playbackQueue.at(0);
                if (firstItemInQueue) {
                  setShouldPlayNextWhenLoaded(true);
                }
              }
              console.log('resuming');
              console.log(
                'clientPlaybackDriver.current',
                clientPlaybackDriver.current,
              );
              console.log('playbackQueue', playbackQueue);
              console.log('nowPlaying', nowPlaying);
              console.log('audioDataStateMap', audioDataStateMap);
              console.log('playbackHistory', playbackHistory);
              console.log('shouldPlayNextWhenLoaded', shouldPlayNextWhenLoaded);
              await clientPlaybackDriver.current.resume();
            }
          },
          skip: async () => {
            // TODO: implement
          },
          skipTo: async (asyncPlayableQueueId) => {
            // Set this item as the play next when loaded item
            const asyncPlayableQueueItem = playbackQueue.find(
              (item) => item.entryId === asyncPlayableQueueId,
            );
            if (!asyncPlayableQueueItem) {
              return;
            }
            setShouldPlayNextWhenLoaded(true);

            // Clear items before the asyncPlayableQueueItem in the queue
            setPlaybackQueue((queue) =>
              queue.slice(queue.indexOf(asyncPlayableQueueItem)),
            );
          },
          stop: () => {
            if (clientPlaybackDriver.current) {
              clientPlaybackDriver.current.unloadPlayingSource();
              if (nowPlaying) {
                setShouldPlayNextWhenLoaded(false);
                setNowPlaying(undefined);
                setPlaybackHistory(() => [...playbackHistory, nowPlaying]);
              }
            }
          },
          clearQueue: () => {
            setPlaybackQueue([]);
            setShouldPlayNextWhenLoaded(false);
          },
          updateNowPlayingAnnotation: (annotation: TPlayableAnnotation) => {
            if (nowPlaying) {
              setNowPlaying({ ...nowPlaying, annotation });
            }
          },
          asyncSwapNowPlayingMix: async (
            playable: UnloadedAsyncPlayable<
              TPlayableAnnotation,
              undefined,
              InkibraRecordlessLibraryMixType
            >,
            mixWindowOptions?: {
              startWithNodeAtTime: number;
              endWithNodeAtTime: number;
            },
          ) => {
            if (
              swapping ||
              !clientPlaybackDriver.current ||
              clientPlaybackDriver.current.getPlaybackInfo().playbackState ===
                DriverPlaybackState.Swapping ||
              nowPlaying?.playable.type !== 'nkrmix' ||
              playable.entryId !== nowPlaying?.entryId
            ) {
              console.log('did not accept swap', {
                swapping,
                playbackState:
                  clientPlaybackDriver.current?.getPlaybackInfo().playbackState,
              });
              return;
            }
            setSwapping(true);
            const loadedPlayable = await playable.loader();
            if (loadedPlayable.isErr()) {
              setSwapping(false);
              return;
            }
            console.log(
              'loadedPlayable from client player element',
              loadedPlayable,
            );

            const mixCreationResult = await ensureMixCreation(
              CacheableObject.makeId(),
              loadedPlayable.value.playable,
              mixWindowOptions,
            );
            const audioSources = loadedComputedDataForMix(
              loadedPlayable.value.playable,
              mixCreationResult,
            );
            console.log('audioSources', audioSources);

            const updatedEntry: AudioDataState<TPlayableAnnotation> = {
              loadingState: LoadingState.LOADED,
              playable: loadedPlayable.value,
              waveform: audioSources.waveform,
              audioDataUrl: audioSources.locator.url,
            };

            setAudioDataStateMap((stateMap) => {
              const newStateMap: AudioDataStateMap<TPlayableAnnotation> = {
                ...stateMap,
                [loadedPlayable.value.playable.id]: updatedEntry,
              };
              return newStateMap;
            });
            const loadedSourceReference =
              await clientPlaybackDriver.current.loadSource(
                playable.entryId,
                audioSources.locator.url,
                true,
              );

            const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({});
            const getMixSwapInfoResult = mixUtil.getMixSwapInfo(
              nowPlaying.playable,
              loadedPlayable.value.playable,
            );
            console.log(
              'getMixSwapInfoResult',
              nowPlaying.playable,
              loadedPlayable.value.playable,
              getMixSwapInfoResult,
            );
            if (getMixSwapInfoResult.isErr()) {
              setSwapping(false);
              return;
            }
            const swapResult = clientPlaybackDriver.current.playOrSwapSource(
              loadedSourceReference,
              {
                ...getMixSwapInfoResult.value,
                transitionTime: 0.5,
              },
              () => {
                setNowPlaying(loadedPlayable.value);
                setSwapping(false);
              },
            );
            if (!swapResult) {
              setSwapping(false);
            }
          },
          addPlayNext: (
            playable: Playable,
            annotation: TPlayableAnnotation,
            playWhenLoaded: boolean,
            entryId = Brand.createId2<'nkrplayable'>('nkrplayable'),
          ) => {
            console.log('adding play next', playable, annotation);
            console.log('playWhenLoaded', playWhenLoaded);
            console.log('entryId', entryId);
            console.log('playable', playable);
            console.log('annotation', annotation);
            console.log('playbackQueue', playbackQueue);
            console.log('shouldPlayNextWhenLoaded', shouldPlayNextWhenLoaded);
            console.log('nowPlaying', nowPlaying);
            console.log('audioDataStateMap', audioDataStateMap);
            console.log('playbackHistory', playbackHistory);
            console.log(
              'clientPlaybackDriver.current',
              clientPlaybackDriver.current,
            );
            const newEntry: LoadedAsyncPlayable<TPlayableAnnotation> = {
              entryId,
              state: LoadingState.LOADED,
              playable,
              annotation,
            };
            setPlaybackQueue((queue) => [newEntry, ...queue]);
            setShouldPlayNextWhenLoaded(playWhenLoaded);
          },
          addAsyncPlayNext: (
            asyncPlayable: AsyncPlayable<TPlayableAnnotation>,
            playWhenLoaded: boolean,
          ) => {
            setPlaybackQueue((queue) => [asyncPlayable, ...queue]);
            setShouldPlayNextWhenLoaded(playWhenLoaded);
          },
          addToQueue: (
            playable: Playable,
            annotation: TPlayableAnnotation,
            entryId = Brand.createId2<'nkrplayable'>('nkrplayable'),
          ) => {
            const newEntry: LoadedAsyncPlayable<TPlayableAnnotation> = {
              entryId,
              state: LoadingState.LOADED,
              playable,
              annotation,
            };
            setPlaybackQueue((queue) => [...queue, newEntry]);
          },
          addAsyncToQueue: (
            asyncPlayable: AsyncPlayable<TPlayableAnnotation>,
          ) => {
            setPlaybackQueue((queue) => [...queue, asyncPlayable]);
          },
          pause: () => {
            if (clientPlaybackDriver.current) {
              clientPlaybackDriver.current.pause();
            }
          },
          seek: (time: number) => {
            if (clientPlaybackDriver.current) {
              clientPlaybackDriver.current.seek(time);
            }
          },
          subscribeToPlaybackState: (
            callback: (state: PlaybackState<TPlayableAnnotation>) => void,
          ) => {
            setPlaybackStateSubscribers((subscribers) => [
              ...subscribers,
              callback,
            ]);
            return callback;
          },
          unsubscribeFromPlaybackState: (
            callback?: (state: PlaybackState<TPlayableAnnotation>) => void,
          ) => {
            setPlaybackStateSubscribers((subscribers) =>
              subscribers.filter((sub) => sub !== callback),
            );
          },
          subscribeToAudioDataState: (
            callback: (state: AudioDataStateMap<TPlayableAnnotation>) => void,
          ) => {
            setAudioDataStateSubscribers((subscribers) => [
              ...subscribers,
              callback,
            ]);
            return callback;
          },
          unsubscribeFromAudioDataState: (
            callback?: (state: AudioDataStateMap<TPlayableAnnotation>) => void,
          ) => {
            setAudioDataStateSubscribers((subscribers) =>
              subscribers.filter((sub) => sub !== callback),
            );
          },
          subscribeToQueueState: (
            callback: (state: QueueState<TPlayableAnnotation>) => void,
          ) => {
            setQueueStateSubscribers((subscribers) => [
              ...subscribers,
              callback,
            ]);
            return callback;
          },
          unsubscribeFromQueueState: (
            callback?: (state: QueueState<TPlayableAnnotation>) => void,
          ) => {
            setQueueStateSubscribers((subscribers) =>
              subscribers.filter((sub) => sub !== callback),
            );
          },
          setRadioMode: (mode: 'off' | 'on') => {
            setRadioMode(mode);
          },
          getCurrentState: () => {
            const currentPlaybackDriverPlaybackInfo =
              clientPlaybackDriver.current?.getPlaybackInfo();

            if (!currentPlaybackDriverPlaybackInfo) {
              return {
                playbackState: {
                  isPlaying: false,
                  time: 0,
                  volume: 0,
                  completed: false,
                  waveform: [],
                  swapping: false,
                },
                queueState: {
                  history: playbackHistory,
                  nowPlaying,
                  queue: [],
                },
                audioDataStateMap,
              };
            }
            const completed =
              currentPlaybackDriverPlaybackInfo.time &&
              currentPlaybackDriverPlaybackInfo.duration
                ? currentPlaybackDriverPlaybackInfo.time >=
                  currentPlaybackDriverPlaybackInfo.duration
                : true;

            return {
              playbackState: {
                isPlaying:
                  currentPlaybackDriverPlaybackInfo.playbackState ===
                  DriverPlaybackState.Playing
                    ? true
                    : false,
                time: clientPlaybackDriver.current?.getPlaybackInfo().time ?? 0,
                volume: 0,
                completed,
                waveform: [],
                swapping,
              },
              queueState: {
                history: playbackHistory,
                nowPlaying,
                queue: playbackQueue,
              },
              audioDataStateMap,
            };
          },
        };
      },
      [
        clientPlaybackDriver.current,
        playbackQueue,
        audioDataStateMap,
        playbackHistory,
        nowPlaying,
        swapping,
      ],
    );

    return null;
  },
);
