import {
  ApiRoute,
  EmptyObject,
  GenericRegistry,
  HttpMethod,
  NoData,
  SchemaHelpersFor,
  SerializableResult,
  SessionEvent,
  SimpleSuccess,
  StatusCode,
} from '@inkibra/api-base';
import { ErrorDescriptor } from '@inkibra/error-base';
import typia from 'typia';
import { InkibraSession } from './type';

export type INKIBRA_SESSION_UNAUTHENTICATED_REQUEST_FAILURE = ErrorDescriptor<
  'INKIBRA_SESSION_UNAUTHENTICATED_REQUEST_FAILURE',
  'Unauthenticated request.',
  undefined
>;
export type INKIBRA_SESSION_ALREADY_LOGGED_IN_FAILURE = ErrorDescriptor<
  'INKIBRA_SESSION_ALREADY_LOGGED_IN_FAILURE',
  'Already logged in.',
  undefined
>;

export namespace GetCurrentInkibraSession {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    | Omit<InkibraSession.UserSession, 'passwordHash'>
    | InkibraSession.VisitorSession,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session',
    {
      Name: 'GetCurrentInkibraSession';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'GetCurrentInkibraSession',
    method: HttpMethod.GET,
    path: '/api/inkibra/session',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

/**
 * When identifying a visitor session, a user provides and email and optionally a full name.
 * This may only be done with a visitor session.
 */
export namespace IdentifyInkibraVisitorSession {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = InkibraSession.VisitorSessionIdentificationData;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response =
    | SerializableResult.OkWithStatusCode<
        InkibraSession.VisitorSession,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        INKIBRA_SESSION_ALREADY_LOGGED_IN_FAILURE,
        StatusCode.FORBIDDEN
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session',
    {
      Name: 'IdentifyInkibraVisitorSession';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'IdentifyInkibraVisitorSession',
    method: HttpMethod.POST,
    path: '/api/inkibra/session',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

/**
 * Send a code to authenticate or recovery code for a visitor session for account creation or recovery.
 */
export namespace SendInkibraSessionAuthenticationOrRecoveryCode {
  export type CANNOT_SEND_CODE_TO_UNIDENTIFIED_SESSION_FAILURE =
    ErrorDescriptor<
      'CANNOT_SEND_CODE_TO_UNIDENTIFIED_SESSION_FAILURE',
      'Cannot send code to unidentified session.',
      undefined
    >;
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = {
    brand: InkibraSession.SessionBrand;
    kind: 'authentication' | 'recovery';
  };
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response =
    | SimpleSuccess
    | SerializableResult.ErrWithStatusCode<
        CANNOT_SEND_CODE_TO_UNIDENTIFIED_SESSION_FAILURE,
        StatusCode.FORBIDDEN
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session/authentication-or-recovery-code',
    {
      Name: 'SendInkibraSessionAuthenticationOrRecoveryCode';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'SendInkibraSessionAuthenticationOrRecoveryCode',
    method: HttpMethod.POST,
    path: '/api/inkibra/session/authentication-or-recovery-code',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

/**
 * Verify an authentication code for a visitor session which if
 * valid will upgrade the session to an authenticated user session.
 */
export namespace VerifyInkibraSessionAuthenticationCode {
  export type INKIBRA_SESSION_CODE_INVALID_FAILURE = ErrorDescriptor<
    'INKIBRA_SESSION_CODE_INVALID_FAILURE',
    'Invalid code.',
    undefined
  >;
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};
  export type Body = {
    code: string;
    brand: InkibraSession.SessionBrand;
  };

  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response =
    | SerializableResult.OkWithStatusCode<
        InkibraSession.UserSession,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        INKIBRA_SESSION_CODE_INVALID_FAILURE,
        StatusCode.NOT_AUTHENTICATED
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session/authentication-code',
    {
      Name: 'VerifyInkibraSessionAuthenticationCode';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'VerifyInkibraSessionAuthenticationCode',
    method: HttpMethod.PUT,
    path: '/api/inkibra/session/authentication-code',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

/**
 * Verify a recovery code for a visitor session which if
 * will find the existing user session and log the user into it.
 */
export namespace VerifyInkibraSessionRecoveryCode {
  export type INKIBRA_SESSION_CODE_INVALID_FAILURE = ErrorDescriptor<
    'INKIBRA_SESSION_CODE_INVALID_FAILURE',
    'Invalid code.',
    undefined
  >;
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};
  export type Body = {
    code: string;
  };

  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response =
    | SerializableResult.OkWithStatusCode<
        InkibraSession.UserSession,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        INKIBRA_SESSION_CODE_INVALID_FAILURE,
        StatusCode.NOT_AUTHENTICATED
      >;

  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session/recovery-code',
    {
      Name: 'VerifyInkibraSessionRecoveryCode';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'VerifyInkibraSessionRecoveryCode',
    method: HttpMethod.PUT,
    path: '/api/inkibra/session/recovery-code',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

/**
 * Resuming a session logs in a user with an email and password.
 */
export namespace ResumeInkibraSession {
  export type INKIBRA_SESSION_INVALID_CREDENTIALS_FAILURE = ErrorDescriptor<
    'INKIBRA_SESSION_INVALID_CREDENTIALS_FAILURE',
    'Invalid credentials.',
    undefined
  >;
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = {
    email: InkibraSession.UserSession['email'];
    password: string;
  };
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response =
    | SerializableResult.OkWithStatusCode<
        Omit<InkibraSession.UserSession, 'passwordHash'>,
        StatusCode.OK
      >
    | SerializableResult.ErrWithStatusCode<
        INKIBRA_SESSION_INVALID_CREDENTIALS_FAILURE,
        StatusCode.NOT_AUTHENTICATED
      >
    | SerializableResult.ErrWithStatusCode<
        INKIBRA_SESSION_ALREADY_LOGGED_IN_FAILURE,
        StatusCode.FORBIDDEN
      >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session',
    {
      Name: 'ResumeInkibraSession';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'ResumeInkibraSession',
    method: HttpMethod.PUT,
    path: '/api/inkibra/session',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace DowngradeInkibraSession {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraSession.VisitorSession,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session/visitor',
    {
      Name: 'DowngradeInkibraSession';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'DowngradeInkibraSession',
    method: HttpMethod.POST,
    path: '/api/inkibra/session/visitor',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

/**
 * Changing a password requires the user to be recently logged in and verified via a code.
 */
export namespace SetInkibraSessionPassword {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = { newPassword: string };
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response =
    | SimpleSuccess
    | SerializableResult.ErrWithStatusCode<
        INKIBRA_SESSION_UNAUTHENTICATED_REQUEST_FAILURE,
        StatusCode.NOT_AUTHENTICATED
      >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session/password',
    {
      Name: 'SetInkibraSessionPassword';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'SetInkibraSessionPassword',
    method: HttpMethod.POST,
    path: '/api/inkibra/session/password',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace SendInkibraRecordlessSessionEvent {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body =
    | SessionEvent.PageViewEvent
    | SessionEvent<
        'CLIENT_LOG',
        {
          clientLogLevel: string | undefined;
          clientLogTime: string;
          clientLogMessage: string;
          clientLogData: unknown;
          clientId: string;
        }
      >
    | SessionEvent<
        'TONE_TEMPO_WAITLIST_JOIN',
        {
          email: string;
          name: string;
        }
      >
    | SessionEvent<
        'RECORDLESS_WAITLIST_JOIN',
        {
          email: string;
          name: string;
        }
      >
    | SessionEvent<
        'USER_CREATED',
        {
          email: string;
          name: string;
          brand: InkibraSession.SessionBrand;
        }
      >;
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SimpleSuccess;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = SimpleSuccess.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/session/event',
    {
      Name: 'SendInkibraRecordlessSessionEvent';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'SendInkibraRecordlessSessionEvent',
    method: HttpMethod.POST,
    path: '/api/inkibra/session/event',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export const InkibraSessionApiRouteRegistry = GenericRegistry.init({
  GetCurrentInkibraSession: GetCurrentInkibraSession.Route,
  IdentifyInkibraVisitorSession: IdentifyInkibraVisitorSession.Route,
  SendInkibraSessionAuthenticationOrRecoveryCode:
    SendInkibraSessionAuthenticationOrRecoveryCode.Route,
  VerifyInkibraSessionAuthenticationCode:
    VerifyInkibraSessionAuthenticationCode.Route,
  VerifyInkibraSessionRecoveryCode: VerifyInkibraSessionRecoveryCode.Route,
  ResumeInkibraSession: ResumeInkibraSession.Route,
  SetInkibraSessionPassword: SetInkibraSessionPassword.Route,
  DowngradeInkibraSession: DowngradeInkibraSession.Route,
  SendInkibraRecordlessSessionEvent: SendInkibraRecordlessSessionEvent.Route,
});

export const InkibraSessionApiFetcherRegistry =
  InkibraSessionApiRouteRegistry.matchWithAll((routes) => {
    return {
      ...routes.GetCurrentInkibraSession.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.IdentifyInkibraVisitorSession.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.SendInkibraSessionAuthenticationOrRecoveryCode.setupHandler<void>(
        () => {
          throw new Error('Not Implemented');
        },
      ),
      ...routes.VerifyInkibraSessionAuthenticationCode.setupHandler<void>(
        () => {
          throw new Error('Not Implemented');
        },
      ),
      ...routes.VerifyInkibraSessionRecoveryCode.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.ResumeInkibraSession.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.SetInkibraSessionPassword.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.DowngradeInkibraSession.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.SendInkibraRecordlessSessionEvent.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
    };
  });
