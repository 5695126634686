import typia from 'typia';
import { MimeType } from './mime-type';

export type RemoteBinaryLocator<
  TMimeType extends MimeType,
  Type extends string,
> = {
  readonly url: string & typia.tags.Format<'url'>;
  readonly contentHash: string;
  readonly contentType: TMimeType;
  readonly type: `RemoteBinaryLocator<${Type}>`;
  readonly date: string & typia.tags.Format<'date-time'>;
};
