/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AlbumInformation } from '../hooks';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { AlbumInformationCard } from './album-information-card';

type ContextMenuAnchorPoint = {
  x: number;
  y: number;
};

export type AlbumInformationCardGalleryProps = {
  albums: AlbumInformation[];
  onContextMenu: (anchor: ContextMenuAnchorPoint, data: string) => void;
  onPlay: (data: string) => void;
  onClick: (data: InkibraRecordlessLibrarySongType.AlbumSlug) => void;
};

export function AlbumInformationCardGallery(
  props: AlbumInformationCardGalleryProps,
) {
  return (
    <div
      css={css({
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, 250px)',
        gridGap: '20px',
      })}
    >
      {props.albums.map((album, index) => (
        <AlbumInformationCard
          key={index}
          artist={album.artist}
          albumName={album.albumName}
          albumSlug={album.albumSlug}
          onContextMenu={props.onContextMenu}
          onPlay={() => {
            props.onPlay(album.albumSlug);
          }}
          onClick={() => {
            props.onClick(album.albumSlug);
          }}
        />
      ))}
    </div>
  );
}
