import typia from 'typia';
import { SchemaHelpersFor } from './schema-helpers';

export type EmptyObject = Record<string, never>;

export namespace EmptyObject {
  export const schema: SchemaHelpersFor<EmptyObject> = {
    is: typia.createIs<EmptyObject>(),
    assert: typia.createAssert<EmptyObject>(),
    validate: typia.createValidate<EmptyObject>(),
    stringify: typia.json.createStringify<EmptyObject>(),
    clone: typia.misc.createClone<EmptyObject>(),
  };
}
