/** @jsxImportSource @emotion/react */

import {
  AuthenticatedSessionGuard,
  BasicUserSessionAuthenticationForm,
  InkibraSession,
} from '@inkibra/recordless.auth-api';
import { Route, Routes } from 'react-router-dom';
import Nav from './components/nav-drawer';
import LibraryIndex from './library';
import WaitlistIndex from './waitlist';
import WorkoutIndex from './workout';

type LocationQuery = {};
export const OUTLET_ID = 'recordless-admin-outlet';
export default function Index(_: LocationQuery) {
  return (
    <AuthenticatedSessionGuard
      loadingElement={<div>Loading...</div>}
      brand={InkibraSession.SessionBrand.INKIBRA_ADMIN}
      UserAuthForm={(props) => (
        <BasicUserSessionAuthenticationForm
          tagline={'𝔦𝔫𝕂𝔦𝔟𝔯𝔞 Net Admin Panel 🚀'}
          description={'Keeping it all together at 𝔦𝔫𝕂𝔦𝔟𝔯𝔞'}
          logoElement={undefined}
          {...props}
        />
      )}
      guardedElement={
        <Nav>
          <Routes>
            <Route path="/admin">
              <Route path="waitlists/*" element={<WaitlistIndex />} />
              <Route path="library/*" element={<LibraryIndex />} />
              <Route path="workouts/*" element={<WorkoutIndex />} />
            </Route>
          </Routes>
        </Nav>
      }
    />
  );
}
