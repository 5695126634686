import typia from 'typia';
import { SchemaHelpersFor } from './schema-helpers';

export type SessionEvent<TType extends string, TData> = {
  type: TType;
  data: TData;
};

export namespace SessionEvent {
  export type PageViewEvent = SessionEvent<
    'PAGE_VIEW',
    { url: string; initialLoad: boolean; pageLoadId: string }
  >;

  export type ExtractEventData<TEvent extends SessionEvent<string, unknown>> =
    TEvent['data'];
  export const schema: SchemaHelpersFor<SessionEvent<string, unknown>> = {
    is: typia.createIs<SessionEvent<string, unknown>>(),
    assert: typia.createAssert<SessionEvent<string, unknown>>(),
    validate: typia.createValidate<SessionEvent<string, unknown>>(),
    stringify: typia.json.createStringify<SessionEvent<string, unknown>>(),
    clone: typia.misc.createClone<SessionEvent<string, unknown>>(),
  };
}
