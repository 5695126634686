/** @jsxImportSource @emotion/react */

import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import {
  Button,
  ButtonBackgroundColor,
  EditableText,
  EditableTextSize,
} from '@inkibra/recordless.ux';
import { useContext, useRef } from 'react';
import { useMix } from '../hooks';
import { InkibraRecordlessLibraryMixType } from '../type-mix';
import { MixNodesDisplay } from './mix-nodes-display';
import { PlaybackSessionContext } from './playback-session-guard-context';

export type IntermixPlaylistDisplayProps = {
  intermixId: string;
};

export function IntermixPlaylistDisplay(props: IntermixPlaylistDisplayProps) {
  const cache = useContext(CacheContext)();
  const hookEffectContext = new HookEffectContext();
  const mixData = hookEffectContext.addEffect(useMix(props.intermixId));
  hookEffectContext.runEffects(cache);
  const playback = useContext(PlaybackSessionContext)();
  const ref = useRef<HTMLDivElement>(null);

  if (!mixData) {
    return null;
  }
  console.log('IntermixPlaylistDisplay mixData', mixData);
  const mixUtils = new InkibraRecordlessLibraryMixType.MixUtil({});

  return (
    <div
      css={{
        height: '100%',
        width: '100%',
        overflowY: 'scroll',
      }}
      ref={ref}
    >
      <EditableText
        value={mixData.name}
        size={EditableTextSize.H1}
        onValueChange={(newName) => {
          // TODO: Use a util function for this.
          const updatedMixResult = mixUtils.setName(mixData, newName);
          if (updatedMixResult.isOk()) {
            cache.input.next(updatedMixResult.value);
          }
        }}
      />
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridTemplateRows: '100%',
          columnGap: '50px',
          paddingRight: '100px',
          paddingLeft: '100px',
          width: '600px',
          gridTemplateAreas: `
            "play-button delete-button upload-button"
          `,
        }}
      >
        <Button
          buttonBackgroundColor={ButtonBackgroundColor.APP_FROSTED_LIGHT}
          gridArea={'play-button'}
          contents={'Play Mix'}
          onClick={() => {
            if (playback.current) {
              playback.current.addPlayNext(mixData, undefined, true);
            }
          }}
        />
        <Button
          buttonBackgroundColor={ButtonBackgroundColor.APP_FROSTED_DARK}
          gridArea={'delete-button'}
          contents={'Delete Mix'}
          onClick={() => {
            console.warn('Implement delete mix');
            // TODO: delete mix
          }}
        />
      </div>
      <br />
      <span>
        Target Head BPM:
        <input
          type="number"
          value={mixData.head.mixTarget.targetBpm}
          onChange={(event) => {
            const newBpm = parseInt(event.target.value);
            if (Number.isNaN(newBpm)) {
              return;
            }
            const updatedMixResult = mixUtils.setHeadMixTargetRequest(mixData, {
              targetBpm: newBpm,
              targetDetune: mixData.head.mixTarget.targetDetune,
              targetTailSeamBeats: mixData.head.mixTarget.targetTailSeamBeats,
            });
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>
      <span>
        Target Head Detune:
        <input
          type="number"
          value={mixData.head.mixTarget.targetDetune}
          onChange={(event) => {
            const newDetune = parseFloat(event.target.value);
            if (Number.isNaN(newDetune)) {
              return;
            }
            const updatedMixResult = mixUtils.setHeadMixTargetRequest(mixData, {
              targetBpm: mixData.head.mixTarget.targetBpm,
              targetDetune: newDetune,
              targetTailSeamBeats: mixData.head.mixTarget.targetTailSeamBeats,
            });
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>
      <span>
        Target Head Tail Seam Beats:
        <input
          type="number"
          value={mixData.head.mixTarget.targetTailSeamBeats}
          onChange={(event) => {
            const newTailSeamBeats = parseInt(event.target.value);
            if (Number.isNaN(newTailSeamBeats)) {
              return;
            }
            const updatedMixResult = mixUtils.setHeadMixTargetRequest(mixData, {
              targetBpm: mixData.head.mixTarget.targetBpm,
              targetDetune: mixData.head.mixTarget.targetDetune,
              targetTailSeamBeats: newTailSeamBeats,
            });
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>
      <br />
      <MixNodesDisplay
        containerRef={ref}
        mixData={mixData}
        onMixUpdate={(newMix) => {
          cache.input.next(newMix);
        }}
        onPlay={(mixNodeDisplayProps) => {
          if (playback.current) {
            playback.current.addPlayNext(
              mixNodeDisplayProps.mixNode.currentLibraryArrangement,
              undefined,
              true,
            );
          }
        }}
      />
      <br />
      <span>
        Target Tail BPM:
        <input
          type="number"
          value={mixData.tail.mixTarget.targetBpm}
          onChange={(event) => {
            const newBpm = parseInt(event.target.value);
            if (Number.isNaN(newBpm)) {
              return;
            }

            const updatedMixResult = mixUtils.setTailMixTargetRequest(mixData, {
              targetBpm: newBpm,
              targetDetune: mixData.tail.mixTarget.targetDetune,
              targetTailSeamBeats: mixData.tail.mixTarget.targetTailSeamBeats,
            });
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>
      <span>
        Target Tail Detune:
        <input
          type="number"
          value={mixData.tail.mixTarget.targetDetune}
          onChange={(event) => {
            const newDetune = parseFloat(event.target.value);
            if (Number.isNaN(newDetune)) {
              return;
            }
            const updatedMixResult = mixUtils.setTailMixTargetRequest(mixData, {
              targetBpm: mixData.tail.mixTarget.targetBpm,
              targetDetune: newDetune,
              targetTailSeamBeats: mixData.tail.mixTarget.targetTailSeamBeats,
            });
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>
      <span>
        Target Tail Seam Beats:
        <input
          type="number"
          value={mixData.tail.mixTarget.targetTailSeamBeats}
          onChange={(event) => {
            const newTailSeamBeats = parseInt(event.target.value);
            if (Number.isNaN(newTailSeamBeats)) {
              return;
            }
            const updatedMixResult = mixUtils.setTailMixTargetRequest(mixData, {
              targetBpm: mixData.tail.mixTarget.targetBpm,
              targetDetune: mixData.tail.mixTarget.targetDetune,
              targetTailSeamBeats: newTailSeamBeats,
            });
            if (updatedMixResult.isOk()) {
              cache.input.next(updatedMixResult.value);
            }
          }}
        />
      </span>
    </div>
  );
}
