/** @jsxImportSource @emotion/react */

import { FormGroupWrapper } from './form-group-wrapper';

const timeFormatter = (valueInSeconds: number) => {
  const minutes = Math.floor(valueInSeconds / 60);
  const seconds = Math.floor(valueInSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const imperialDistanceFormatter = (valueInInches: number) => {
  const feet = Math.floor(valueInInches / 12);
  const inches = valueInInches % 12;
  return `${feet}'${inches}"`;
};

const integerFormatter = (value: number) => value.toString();

const decimalFormatter = (value: number) => value.toFixed(2).toString();

export type StatisticsDisplayProps = {
  title: string;
  subtitle: string;
  name: string;
  value: number;
  formatter:
    | 'time-from-seconds'
    | 'decimal'
    | 'imperial-from-inches'
    | 'integer';
  label: string;
};

export function StatisticsDisplay({
  title,
  subtitle,
  value,
  formatter,
  label,
}: StatisticsDisplayProps) {
  const formattedValue =
    formatter === 'time-from-seconds'
      ? timeFormatter(value)
      : formatter === 'decimal'
        ? decimalFormatter(value)
        : formatter === 'imperial-from-inches'
          ? imperialDistanceFormatter(value)
          : formatter === 'integer'
            ? integerFormatter(value)
            : void 0;

  if (formattedValue === undefined) {
    throw new Error('Invalid value');
  }

  return (
    <FormGroupWrapper title={title} subtitle={subtitle}>
      <div
        css={{
          label: 'statistics-display-container',
          width: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr',
          gridTemplateRows: '1fr 14px',
          touchAction: 'none',
          userSelect: 'none',
        }}
      >
        <div
          css={{
            label: 'statistics-display',
            display: 'grid',
            gridTemplateColumns: '1fr',
            gap: '12px',
            alignItems: 'center',
            padding: '12px 14px',
            fontWeight: '500',
            fontSize: '32px',
            color: '#FFFFFF',
          }}
        >
          <span
            css={{
              label: 'statistics-display-label',
              textAlign: 'center',
              display: 'grid',
              gridTemplateRows: '1fr 1fr',
              gap: '4px',
            }}
          >
            <span>{formattedValue}</span>
            <span css={{ fontSize: '16px', fontWeight: '400' }}>{label}</span>
          </span>
        </div>
      </div>
    </FormGroupWrapper>
  );
}
