/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import {
  HeaderText,
  HeaderTextAlign,
  HeaderTextBackground,
} from './header-text';
import { ParagraphText, ParagraphTextAlign } from './paragraph-text';

import { responsiveStyle } from '../constants/media-query';

const footerBorderStyles = css({
  background:
    'linear-gradient(91.29deg, #4512EA 3.24%, #1282EA 22.73%, #12EA5B 34.54%, #EFE75E 50.68%, #E07614 63.87%, #F31875 81.71%, #4512EA 95.93%)',
  height: '1px',
  width: '100%',
  display: 'block',
});

const footerLogoStyles = css(
  {
    marginBottom: '-50px',
  },
  responsiveStyle('marginLeft', {
    xs: '-25px',
    md: '0px',
  }),
);

const footerStyles = css(
  {
    display: 'grid',
    paddingTop: '0px',
    justifyItems: 'left',
  },
  responsiveStyle('gridTemplateRows', {
    xs: '1fr 1fr',
    md: '1fr',
  }),
  responsiveStyle('gridTemplateColumns', {
    xs: '1fr',
    md: '50% 50%',
  }),
  responsiveStyle('padding', {
    xs: '25px',
    md: '50px',
  }),
);

const footerSectionStyles = css({
  display: 'inline-block',
});

export type FooterProps = {
  logoPath: string;
};

export function Footer(props: FooterProps) {
  return (
    <footer>
      <span css={footerBorderStyles} />
      <img css={footerLogoStyles} src={props.logoPath} alt="Logo" />
      <div css={footerStyles}>
        <div css={footerSectionStyles}>
          <HeaderText
            value={'inKibra Inc.'}
            fontSize={{ xs: '20px' }}
            fontWeight={400}
            background={HeaderTextBackground.SOLID_WHITE}
            textAlign={HeaderTextAlign.LEFT}
          />
          <ParagraphText
            value={'Copyright © 2023 InKibra, Inc. All rights reserved.'}
            textAlign={ParagraphTextAlign.LEFT}
          />
          <a
            href="https://cdn.recordless.app/common-assets/recordless_privacy_policy.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <ParagraphText
              value={'Privacy Policy'}
              textAlign={ParagraphTextAlign.LEFT}
            />
          </a>
          <a
            href="https://cdn.recordless.app/common-assets/recordless_terms_of_service.pdf"
            target="_blank"
            rel="noreferrer"
          >
            <ParagraphText
              value={'Terms of Service'}
              textAlign={ParagraphTextAlign.LEFT}
            />
          </a>
        </div>
        <div css={footerSectionStyles}>
          <HeaderText
            value={'Company'}
            fontSize={{ xs: '20px' }}
            fontWeight={400}
            background={HeaderTextBackground.SOLID_WHITE}
            textAlign={HeaderTextAlign.LEFT}
          />
          <a
            href="https://twitter.com/RecordlessApp"
            target="_blank"
            rel="noreferrer"
          >
            <ParagraphText
              value={'Twitter'}
              textAlign={ParagraphTextAlign.LEFT}
            />
          </a>
          <a
            href="mailto:contact@recordless.app"
            target="_blank"
            rel="noreferrer"
          >
            <ParagraphText
              value={'contact@recordless.app'}
              textAlign={ParagraphTextAlign.LEFT}
            />
          </a>
        </div>
      </div>
    </footer>
  );
}
