import { SchemaHelpersFor } from '@inkibra/api-base';
import { Brand } from '@inkibra/observable-cache';
import { InkibraSession } from '@inkibra/recordless.auth-api';
import typia from 'typia';
import * as uuid from 'uuid';

export type InkibraRecordlessUserType = {
  id: Brand<'INKIBRA_RECORDLESS_USER'> & typia.tags.Format<'uuid'>;
  ownerId: InkibraSession['id'];
  displayName: string;
  username: string;
  isArtist: boolean;
  type: typeof InkibraRecordlessUserType.typename;
  modified: string & typia.tags.Format<'date-time'>;
  created: string & typia.tags.Format<'date-time'>;
  deleted?: string & typia.tags.Format<'date-time'>;
};

export namespace InkibraRecordlessUserType {
  export const schema: SchemaHelpersFor<InkibraRecordlessUserType> = {
    is: typia.createIs<InkibraRecordlessUserType>(),
    assert: typia.createAssert<InkibraRecordlessUserType>(),
    validate: typia.createValidate<InkibraRecordlessUserType>(),
    stringify: typia.json.createStringify<InkibraRecordlessUserType>(),
    clone: typia.misc.createClone<InkibraRecordlessUserType>(),
  };

  export const typename = 'INKIBRA_RECORDLESS_USER';
  export function is(val: unknown): val is InkibraRecordlessUserType {
    return (val as InkibraRecordlessUserType).type === typename;
  }
  export function fromCreation(
    val: Creation,
    ownerId: InkibraSession['id'],
  ): InkibraRecordlessUserType {
    return {
      ...val,
      id: uuid.v4(),
      ownerId,
      type: InkibraRecordlessUserType.typename,
      modified: new Date().toISOString(),
      created: new Date().toISOString(),
    };
  }

  export type Creation = Pick<
    InkibraRecordlessUserType,
    'displayName' | 'username' | 'isArtist'
  >;

  export namespace Creation {
    export const schema: SchemaHelpersFor<Creation> = {
      is: typia.createIs<Creation>(),
      assert: typia.createAssert<Creation>(),
      validate: typia.createValidate<Creation>(),
      stringify: typia.json.createStringify<Creation>(),
      clone: typia.misc.createClone<Creation>(),
    };
  }

  export type Modification = Partial<
    Pick<InkibraRecordlessUserType, 'displayName' | 'username' | 'isArtist'>
  >;

  export namespace Modification {
    export const schema: SchemaHelpersFor<Modification> = {
      is: typia.createIs<Modification>(),
      assert: typia.createAssert<Modification>(),
      validate: typia.createValidate<Modification>(),
      stringify: typia.json.createStringify<Modification>(),
      clone: typia.misc.createClone<Modification>(),
    };
  }
}
