/** @jsxImportSource @emotion/react */

export function Start() {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 12.7461C5.68359 12.7461 4.91406 12.5898 4.19141 12.2773C3.47266 11.9648 2.83789 11.5332 2.28711 10.9824C1.73633 10.4316 1.30469 9.79688 0.992188 9.07812C0.679688 8.35547 0.523438 7.58594 0.523438 6.76953C0.523438 5.95312 0.679688 5.18555 0.992188 4.4668C1.30469 3.74414 1.73438 3.10742 2.28125 2.55664C2.83203 2.00586 3.4668 1.57422 4.18555 1.26172C4.9082 0.949219 5.67773 0.792969 6.49414 0.792969C7.31055 0.792969 8.08008 0.949219 8.80273 1.26172C9.52539 1.57422 10.1621 2.00586 10.7129 2.55664C11.2637 3.10742 11.6953 3.74414 12.0078 4.4668C12.3203 5.18555 12.4766 5.95312 12.4766 6.76953C12.4766 7.58594 12.3203 8.35547 12.0078 9.07812C11.6953 9.79688 11.2637 10.4316 10.7129 10.9824C10.1621 11.5332 9.52539 11.9648 8.80273 12.2773C8.08398 12.5898 7.31641 12.7461 6.5 12.7461ZM5.38672 9.13086L8.83203 7.10352C8.91406 7.05273 8.96875 6.98633 8.99609 6.9043C9.02344 6.81836 9.02344 6.73438 8.99609 6.65234C8.96875 6.56641 8.91406 6.5 8.83203 6.45312L5.38672 4.41406C5.30078 4.36328 5.21094 4.3418 5.11719 4.34961C5.02344 4.35742 4.94336 4.38867 4.87695 4.44336C4.81445 4.49805 4.7832 4.57422 4.7832 4.67188V8.87305C4.7832 8.9707 4.81445 9.04883 4.87695 9.10742C4.93945 9.16602 5.01562 9.19922 5.10547 9.20703C5.19922 9.21484 5.29297 9.18945 5.38672 9.13086Z"
        fill="#08031D"
      />
    </svg>
  );
}
