/** @jsxImportSource @emotion/react */

export function RunningManDark() {
  return (
    <svg viewBox="0 0 194 194" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="97" cy="97" r="97" fill="url(#paint0_linear_2388_1026)" />
      <g clipPath="url(#clip0_2388_1026)">
        <path
          d="M102.161 70.5421C107.017 70.5421 110.942 66.6007 110.942 61.6941C110.942 56.8679 107.017 52.9668 102.161 52.9668C97.2243 52.9668 93.2586 56.8679 93.2586 61.6941C93.2586 66.6007 97.2243 70.5421 102.161 70.5421ZM111.63 94.0294C113.289 95.7587 116.486 95.638 117.943 93.3457L127.776 77.6606C130.811 72.8345 123.689 68.9736 120.897 73.3976L113.775 84.8195L108.191 79.2292C102.809 73.9204 96.941 72.8345 88.7668 73.7595L74.3607 75.3682C72.4993 75.5693 71.0425 77.0574 70.8402 78.827L68.8168 94.6327C68.1694 99.7003 76.1008 101.027 76.7887 95.5981L78.4074 82.9695L85.9341 82.1249C86.9863 82.0444 87.7147 82.7281 87.5528 83.8945L85.6509 99.3783C84.7201 106.698 88.9691 110.921 96.253 110.398L111.145 109.352L106.693 125.48C105.196 130.909 113.087 132.8 114.504 127.531L120.371 106.054C121.099 103.32 119.238 100.987 116.162 100.987H100.785L102.728 87.7956C102.93 86.5086 104.104 86.4684 104.832 87.2728L111.63 94.0294ZM89.657 113.455L88.6858 127.772L81.0782 146.675C79.7428 150.053 82.1708 152.386 84.6797 152.386C86.2579 152.386 87.836 151.541 88.6454 149.53L96.4149 130.145C96.4959 129.864 96.6173 129.542 96.6577 129.26L98.9239 114.138L96.5363 114.299C93.9869 114.46 91.6399 114.138 89.657 113.455Z"
          fill="#08031D"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2388_1026"
          x1="0"
          y1="97"
          x2="194"
          y2="97"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#52FF00" />
          <stop offset="1" stopColor="#319900" />
        </linearGradient>
        <clipPath id="clip0_2388_1026">
          <rect
            width="61.2199"
            height="102.033"
            fill="white"
            transform="translate(68.7793 52.9668)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
