/** @jsxImportSource @emotion/react */

import { ArrangementOutputInfo } from '@inkibra/recordless.music-engine';
import { useEffect, useRef, useState } from 'react';

import { InkibraRecordlessLibraryApiFetcherRegistry } from '../api';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { RecordlessPlaybackDisplay } from './playback-display';

/**
 * Represents the props for the RecordlessTransitionReview component.
 */
export type RecordlessTransitionReviewProps = {
  song: InkibraRecordlessLibrarySongType;
  songWaveform: number[];
  reviewableTransitionId: InkibraRecordlessLibrarySongType.Transition['id'];
  approveTransition: () => void;
  pixelsPerSecond: number;
};

// TODO: What happens if we don't like a transition?

/**
 * A component that allows for the labeling of music transitions.
 */
export const RecordlessTransitionReview = (
  props: RecordlessTransitionReviewProps,
) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [transitionPreviewed, setTransitionPreviewed] = useState(false);
  const [waveform, setWaveform] = useState<number[]>([]);
  const [maybeArrangementOutputInfo, setMaybeArrangementOutputInfo] = useState<
    ArrangementOutputInfo | undefined
  >(undefined);

  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});

  const reviewableTransitionResult = songUtil.getTransitionById(
    props.song,
    props.reviewableTransitionId,
  );
  if (reviewableTransitionResult.isErr()) {
    console.error(
      'failed to get reviewable transition',
      reviewableTransitionResult.error,
    );
    throw new Error('failed to get reviewable transition');
  }

  const arrangementResult = arrangementUtil
    .create({
      name: 'transition-review',
      librarySong: props.song,
    })
    .andThen((arrangement) => {
      return arrangementUtil.addSectionArrangement(
        arrangement,
        reviewableTransitionResult.value.sourceSectionFieldId,
      );
    })
    .andThen((arrangement) => {
      return arrangementUtil.addSectionArrangement(
        arrangement,
        reviewableTransitionResult.value.targetSectionFieldId,
      );
    })
    .andThen((arrangement) => {
      const firstSectionArrangement = arrangement.sectionArrangements[0];
      if (!firstSectionArrangement) {
        throw new Error('unexpected no first section arrangement');
      }
      return arrangementUtil.updateSectionArrangementTransition(
        arrangement,
        firstSectionArrangement.id,
        props.reviewableTransitionId,
      );
    });

  if (arrangementResult.isErr()) {
    console.error('failed to create arrangement', arrangementResult.error);
    throw new Error('failed to create arrangement');
  }

  useEffect(() => {
    setTransitionPreviewed(false);
  }, [props.reviewableTransitionId, props.song]);

  return (
    <>
      <audio ref={audioRef} controls />
      <div css={{ width: '100vw' }} ref={containerRef}>
        <RecordlessPlaybackDisplay
          windowedWaveformData={waveform}
          duration={maybeArrangementOutputInfo?.duration || 0}
          relativeSections={
            maybeArrangementOutputInfo?.annotations.map((annotation) => {
              return {
                name: annotation.metadata,
                startTime: annotation.startsAt,
              };
            }) || []
          }
          audioPlayerRef={audioRef}
          relativeZero={0}
          containerRef={containerRef}
        />
        <button
          onClick={async () => {
            if (audioRef.current) {
              // TODO: we should display the transition overview above here to select from existing transition
              // TODO: The arrangement-creation-flow should have something that highlights the two section we are transitioning between
              const createSourceResult =
                await InkibraRecordlessLibraryApiFetcherRegistry.get(
                  'previewInkibraRecordlessArrangementSources',
                ).fn({
                  body: arrangementResult.value,
                  files: undefined,
                  pathParams: {},
                  pathQuery: {
                    encode: false,
                    waveformResolutionOverride: '16',
                  },
                });

              const sources = createSourceResult.value;

              if (!sources) {
                console.warn('no sources');
                return;
              }
              console.log('got sources', sources);
              audioRef.current.src = sources.locator.url;
              setWaveform(sources.waveform);
              // This only works because we will be labeling transition between non-consecutive sections
              // Otherwise we wouldn't be here.
              setMaybeArrangementOutputInfo(sources.arrangementOutputInfo);

              const sourceSectionDurationResult =
                songUtil.computeSectionDuration(
                  props.song,
                  reviewableTransitionResult.value.sourceSectionFieldId,
                );
              if (sourceSectionDurationResult.isErr()) {
                console.error(
                  'failed to get source section duration',
                  sourceSectionDurationResult.error,
                );
                throw new Error('failed to get source section duration');
              }
              audioRef.current.currentTime =
                sourceSectionDurationResult.value - 10 > 0
                  ? sourceSectionDurationResult.value - 10
                  : 0;
              setTransitionPreviewed(true);
              await audioRef.current.play();
            }
          }}
        >
          Preview Transition
        </button>
        <button
          disabled={!transitionPreviewed}
          onClick={() => {
            const sectionArrangement =
              arrangementResult.value.sectionArrangements[0];
            if (sectionArrangement?.selectedTransitionId) {
              props.approveTransition();
            }
          }}
        >
          Approve Transition
        </button>
      </div>
    </>
  );
  //
};
