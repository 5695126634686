/** @jsxImportSource @emotion/react */

export function Trophy() {
  return (
    <svg viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="46" cy="46" r="46" fill="#434343" />
      <path
        d="M28.8086 33.8438C28.8086 32.9297 29.0723 32.2148 29.5996 31.6992C30.1387 31.1836 30.877 30.9258 31.8145 30.9258H35.2246C35.3652 30.0234 35.7461 29.3203 36.3672 28.8164C37 28.3008 37.8438 28.043 38.8984 28.043H53.1016C54.168 28.043 55.0117 28.3008 55.6328 28.8164C56.2539 29.3203 56.6348 30.0234 56.7754 30.9258H60.1855C61.123 30.9258 61.8555 31.1836 62.3828 31.6992C62.9219 32.2148 63.1914 32.9297 63.1914 33.8438C63.1914 36.2578 62.8047 38.3789 62.0312 40.207C61.2578 42.0234 60.0977 43.5586 58.5508 44.8125C57.0039 46.0664 55.0703 47.0508 52.75 47.7656C52.2109 48.4336 51.6426 49.0371 51.0449 49.5762C50.4473 50.1035 49.8496 50.5547 49.252 50.9297V58.5762H51.9941C53.2363 58.5762 54.1738 58.9102 54.8066 59.5781C55.4512 60.2461 55.7734 61.166 55.7734 62.3379V65.2383C55.7734 65.6367 55.6387 65.959 55.3691 66.2051C55.0996 66.4512 54.7832 66.5742 54.4199 66.5742H37.5801C37.2168 66.5742 36.9004 66.4512 36.6309 66.2051C36.3613 65.959 36.2266 65.6367 36.2266 65.2383V62.3379C36.2266 61.166 36.543 60.2461 37.1758 59.5781C37.8203 58.9102 38.7578 58.5762 39.9883 58.5762H42.748V50.9297C42.1504 50.5547 41.5527 50.1035 40.9551 49.5762C40.3574 49.0371 39.7832 48.4336 39.2324 47.7656C36.9121 47.0508 34.9785 46.0664 33.4316 44.8125C31.8965 43.5586 30.7422 42.0234 29.9688 40.207C29.1953 38.3789 28.8086 36.2578 28.8086 33.8438ZM31.3223 34.0898C31.3223 36.5977 31.7969 38.707 32.7461 40.418C33.6953 42.1289 35.0898 43.4473 36.9297 44.373C36.3906 43.2949 35.957 42.1406 35.6289 40.9102C35.3008 39.668 35.1367 38.373 35.1367 37.0254V33.5801H31.832C31.6797 33.5801 31.5566 33.6328 31.4629 33.7383C31.3691 33.832 31.3223 33.9492 31.3223 34.0898ZM55.0527 44.373C56.9043 43.4473 58.3047 42.1289 59.2539 40.418C60.2031 38.707 60.6777 36.5977 60.6777 34.0898C60.6777 33.9492 60.6309 33.832 60.5371 33.7383C60.4434 33.6328 60.3203 33.5801 60.168 33.5801H56.8633V37.0254C56.8633 38.373 56.6992 39.668 56.3711 40.9102C56.043 42.1406 55.6035 43.2949 55.0527 44.373Z"
        fill="white"
      />
    </svg>
  );
}
