/** @jsxImportSource @emotion/react */

type DragHandleProps = {
  opacity?: number;
};
export function DragHandle(props: DragHandleProps) {
  return (
    <svg
      opacity={props.opacity}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5 11.6666H2.5"
        stroke="#08031D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5 8.33331H2.5"
        stroke="#08031D"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
