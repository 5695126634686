import { SessionEvent } from '../constants';

export class SessionEventBus<TEvents extends SessionEvent<string, unknown>> {
  #eventSenderFunction: (event: TEvents | SessionEvent.PageViewEvent) => void;
  constructor(
    eventSenderFunction: (event: TEvents | SessionEvent.PageViewEvent) => void,
  ) {
    this.#eventSenderFunction = eventSenderFunction;
  }
  public sendEvent(event: TEvents) {
    this.#eventSenderFunction(event);
  }
  public sendPageView(
    data: SessionEvent.ExtractEventData<SessionEvent.PageViewEvent>,
  ) {
    this.#eventSenderFunction({
      type: 'PAGE_VIEW',
      data,
    });
  }
}
