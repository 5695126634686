import { Result, err, ok } from 'neverthrow';
import { StatusCode } from '../constants/status-code';

/**
 * SerializableResult is a type that represents a result that can be serialized.
 * It can be of type 'Ok' or 'Err', each with a value and a status code.
 */

export type SerializableResult<OkType, ErrType> =
  | { type: 'Ok'; value: OkType; statusCode: StatusCode }
  | { type: 'Err'; error: ErrType; statusCode: StatusCode };

export namespace SerializableResult {
  /**
   * OkWithStatusCode is a type that represents a successful result with a status code.
   */
  export type OkWithStatusCode<OkType, S extends StatusCode> = {
    type: 'Ok';
    value: OkType;
    statusCode: S;
  };

  /**
   * ErrWithStatusCode is a type that represents an error result with a status code.
   */
  export type ErrWithStatusCode<ErrType, S extends StatusCode> = {
    type: 'Err';
    error: ErrType;
    statusCode: S;
  };

  /**
   * fromOk is a function that converts a successful result with a status code to a Result type.
   */
  export function fromOk<OkType, S extends StatusCode>(
    result: OkWithStatusCode<OkType, S>,
  ): Result<OkType, never> {
    return ok(result.value);
  }

  /**
   * fromErr is a function that converts an error result with a status code to a Result type.
   */
  export function fromErr<ErrType, S extends StatusCode>(
    result: ErrWithStatusCode<ErrType, S>,
  ): Result<never, ErrType> {
    return err(result.error);
  }

  /**
   * toOk is a function that creates a successful result with a status code.
   */
  export function toOk<OkType, S extends StatusCode>(
    value: OkType,
    statusCode: S,
  ): SerializableResult.OkWithStatusCode<OkType, S> {
    return { type: 'Ok', value, statusCode };
  }

  /**
   * toErr is a function that creates an error result with a status code.
   */
  export function toErr<ErrType, S extends StatusCode>(
    error: ErrType,
    statusCode: S,
  ): SerializableResult.ErrWithStatusCode<ErrType, S> {
    return { type: 'Err', error, statusCode };
  }
}
