/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { CacheContext, HookEffectContext } from '@inkibra/api-base';
import { Button, ButtonBackgroundColor } from '@inkibra/recordless.ux';
import { useContext } from 'react';
import { useLibraryAlbumArtworkLocator } from '../hooks';
import { InkibraRecordlessLibrarySongType } from '../type-song';

type ContextMenuAnchorPoint = {
  x: number;
  y: number;
};

const textStyles = css({
  fontFamily: "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '15px',
  lineHeight: '20px',
  letterSpacing: '-0.25px',
  color: 'white',
  userSelect: 'none',
  pointerEvents: 'none',
  margin: 0,
});

export type AlbumInformationCardProps = {
  artist: string;
  albumName: string;
  albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug;
  onContextMenu: (
    anchor: ContextMenuAnchorPoint,
    albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug,
  ) => void;
  onPlay: () => void;
  onClick: () => void;
};

export function AlbumInformationCard(props: AlbumInformationCardProps) {
  const cache = useContext(CacheContext)();
  const hookEffectContext = new HookEffectContext();
  const albumArtworkLocator = hookEffectContext.addEffect(
    useLibraryAlbumArtworkLocator(props.albumSlug),
  );
  hookEffectContext.runEffects(cache);
  return (
    <div
      onClick={props.onClick}
      onContextMenu={(e) => {
        e.preventDefault();
        props.onContextMenu({ x: e.clientX, y: e.clientY }, props.albumSlug);
      }}
      css={css({
        display: 'inline-grid',
        columnGap: '25px',
        background:
          'linear-gradient(190deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%)',
        backdropFilter: 'blur(15px)',
        boxShadow: '0px 4px 24px 1px rgba(35, 16, 94, 0.7)',
        boxSizing: 'border-box',
        borderRadius: '20px',
        padding: '25px',
        gridTemplateColumns: '160px 15px',
        gridTemplateRows: '210px 45px 25px 40px',
        gridTemplateAreas: `
      "artwork artwork"
      "album-title album-title"
      "album-artist album-artist"
      "play-button play-button"
    `,
      })}
    >
      <img
        css={{
          gridArea: 'artwork',
          width: '200px',
          height: '200px',
          borderRadius: '4px',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
        src={albumArtworkLocator?.url || ''}
      />
      <h2
        css={css(textStyles, {
          gridArea: 'album-title',
          letterSpacing: '1.25px',
          fontWeight: 300,
          fontSize: '15px',
          lineHeight: '18px',
        })}
      >
        {props.albumName}
      </h2>
      <h2
        css={css(textStyles, {
          gridArea: 'album-artist',
          fontWeight: 400,
          letterSpacing: '1.25px',
          fontSize: '15px',
          lineHeight: '15px',
          color: 'rgba(211, 211, 252, 0.5)',
        })}
      >
        {props.artist}
      </h2>
      <Button
        buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
        contents="Play"
        color="white"
        gridArea="play-button"
        onClick={(e) => {
          e.preventDefault();
          props.onPlay();
          e.stopPropagation();
        }}
      />
      {/* <span
      onClick={(e) => {
        e.preventDefault();
        props.onContextMenu({ x: e.clientX, y: e.clientY }, props.data);
        e.stopPropagation();
      }}
      css={{
        gridArea: 'ellipsis',
        cursor: 'pointer',
        position: 'relative',
      }}
    >
      <Ellipsis style={{
        position: 'absolute',
        bottom: '18px',
      }} />
    </span> */}
    </div>
  );
}
