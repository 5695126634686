/** @jsxImportSource @emotion/react */

type SideBarSectionEntry<T> = {
  title: string;
  data: T;
  // selected: boolean;
};

export type SidebarSectionProps<T> = {
  sectionName: string;
  sectionAction?: string;
  onSectionActionClick?: () => void;
  entries: SideBarSectionEntry<T>[];
  onEntryClick: (entry: SideBarSectionEntry<T>) => void;
};

// TODO: use in the library-api example
export function SidebarSection<T>({
  sectionName,
  entries,
  onEntryClick,
  sectionAction,
  onSectionActionClick,
}: SidebarSectionProps<T>) {
  return (
    <div>
      <h2
        css={{
          opacity: '0.3',
          fontWeight: '400',
          fontSize: '12px',
          fontFamily:
            "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          lineHeight: '14px',
          color: '#71E993', // TODO: App Green Color
          userSelect: 'none',
          cursor: 'default',
          width: '175px',
          ' > button': {
            background: 'none',
            border: 'none',
            fontWeight: '400',
            fontSize: '12px',
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            lineHeight: '14px',
            color: 'white',
            cursor: 'pointer',
            float: 'right',
          },
        }}
      >
        {sectionName}
        {sectionAction ? (
          <button
            onClick={() =>
              onSectionActionClick ? onSectionActionClick() : null
            }
          >
            {sectionAction}
          </button>
        ) : null}
      </h2>
      <ul
        css={{
          listStyleType: 'none',
          padding: 0,
          paddingLeft: '15px',
          margin: 0,
        }}
      >
        {entries.map((entry, i) => {
          return (
            <li
              key={i}
              onClick={() => onEntryClick(entry)}
              css={{
                color: 'white',
                fontSize: '12px',
                fontFamily:
                  "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
                lineHeight: '16px',
                fontWeight: '400',
                cursor: 'default',
                userSelect: 'none',
              }}
            >
              {entry.title}
            </li>
          );
        })}
      </ul>
    </div>
  );
}
