/** @jsxImportSource @emotion/react */

import {
  AuthenticatedSessionGuard,
  InkibraSession,
} from '@inkibra/recordless.auth-api';
import { Route, Routes } from 'react-router-dom';
import { ToneTempoUserAuthenticationForm } from '../components/user-authentication';
import { WorkoutSessionContextGuard } from '../components/workout-session-context';
import { Browse } from './browse';
import { DistanceActivityPlayback } from './distance-activity-playback';
import { DistanceActivityStart } from './distance-activity-start';
import { ErrorPage } from './error';
import {
  BrowseRoute,
  DistanceActivityPlaybackRoute,
  DistanceActivityStartRoute,
  ErrorRoute,
  WorkoutRoute,
} from './routes';
import { Workout } from './workout';

export type ToneTempoAppProps = {
  basePath?: string; // TODO: move to config
};

export * from './config';

export const ToneTempoApp = ({ basePath }: ToneTempoAppProps) => {
  return (
    <div
      css={{
        width: '100vw',
        fontFamily: 'Inter',
      }}
    >
      <AuthenticatedSessionGuard
        brand={InkibraSession.SessionBrand.TONETEMPO}
        loadingElement={<div>Loading...</div>}
        guardedElement={
          <WorkoutSessionContextGuard
            guardedElement={
              <Routes>
                <Route
                  path={BrowseRoute.getRouteString(basePath)}
                  element={<Browse />}
                />
                <Route
                  path={DistanceActivityStartRoute.getRouteString(basePath)}
                  element={<DistanceActivityStart />}
                />
                <Route
                  path={DistanceActivityPlaybackRoute.getRouteString(basePath)}
                  element={<DistanceActivityPlayback />}
                />
                <Route
                  path={WorkoutRoute.getRouteString(basePath)}
                  element={<Workout />}
                />
                <Route
                  path={ErrorRoute.getRouteString(basePath)}
                  element={<ErrorPage />}
                />
                {/* Add more routes as needed */}
              </Routes>
            }
          />
        }
        UserAuthForm={(props) => <ToneTempoUserAuthenticationForm {...props} />}
      />
    </div>
  );
};
