/** @jsxImportSource @emotion/react */

export function Creative() {
  return (
    <svg viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="mask0_350_3183"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="80"
        height="80"
      >
        <rect width="80" height="80" rx="20" fill="white" />
      </mask>
      <g mask="url(#mask0_350_3183)">
        <rect width="80" height="80" fill="#1AE3FE" fillOpacity="0.77" />
        <mask id="path-3-inside-1_350_3183" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.4666 -19.1061L80.8632 -22.6073L67.1892 50.0468C63.002 47.5747 58.126 46.1417 52.9134 46.1052C37.1659 45.9947 24.3104 58.6711 24.2 74.4186C24.0895 90.1661 36.7659 103.022 52.5134 103.132C66.6425 103.231 78.4434 93.0366 80.7996 79.5659L80.8926 79.5834L99.4666 -19.1061Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M99.4666 -19.1061L80.8632 -22.6073L67.1892 50.0468C63.002 47.5747 58.126 46.1417 52.9134 46.1052C37.1659 45.9947 24.3104 58.6711 24.2 74.4186C24.0895 90.1661 36.7659 103.022 52.5134 103.132C66.6425 103.231 78.4434 93.0366 80.7996 79.5659L80.8926 79.5834L99.4666 -19.1061Z"
          fill="#F31875"
        />
        <path
          d="M80.8632 -22.6073L81.2331 -24.5728L79.2676 -24.9428L78.8977 -22.9773L80.8632 -22.6073ZM99.4666 -19.1061L101.432 -18.7361L101.802 -20.7016L99.8365 -21.0716L99.4666 -19.1061ZM67.1892 50.0468L66.1725 51.769L68.6274 53.2184L69.1547 50.4167L67.1892 50.0468ZM80.7996 79.5659L81.1694 77.6004L79.1785 77.2258L78.8295 79.2213L80.7996 79.5659ZM80.8926 79.5834L80.5228 81.5489L82.4882 81.9187L82.8581 79.9533L80.8926 79.5834ZM80.4933 -20.6419L99.0967 -17.1406L99.8365 -21.0716L81.2331 -24.5728L80.4933 -20.6419ZM69.1547 50.4167L82.8287 -22.2374L78.8977 -22.9773L65.2238 49.6769L69.1547 50.4167ZM52.8994 48.1051C57.7496 48.1391 62.2809 49.4715 66.1725 51.769L68.206 48.3246C63.7231 45.6779 58.5023 44.1443 52.9274 44.1052L52.8994 48.1051ZM26.1999 74.4326C26.3026 59.7896 38.2564 48.0024 52.8994 48.1051L52.9274 44.1052C36.0754 43.987 22.3182 57.5525 22.2 74.4045L26.1999 74.4326ZM52.5274 101.132C37.8844 101.029 26.0972 89.0756 26.1999 74.4326L22.2 74.4045C22.0818 91.2566 35.6473 105.014 52.4994 105.132L52.5274 101.132ZM78.8295 79.2213C76.6389 91.7456 65.6635 101.224 52.5274 101.132L52.4994 105.132C67.6215 105.238 80.248 94.3275 82.7697 79.9105L78.8295 79.2213ZM81.2625 77.6179L81.1694 77.6004L80.4297 81.5314L80.5228 81.5489L81.2625 77.6179ZM97.5011 -19.476L78.9271 79.2135L82.8581 79.9533L101.432 -18.7361L97.5011 -19.476Z"
          fill="black"
          mask="url(#path-3-inside-1_350_3183)"
        />
      </g>
    </svg>
  );
}
