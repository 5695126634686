/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const dividerStyles = css({
  background:
    'linear-gradient(91.29deg, rgb(69, 18, 234) 3.24%, rgb(18, 130, 234) 22.73%, rgb(18, 234, 91) 34.54%, rgb(239, 231, 94) 50.68%, rgb(224, 118, 20) 63.87%, rgb(243, 24, 117) 81.71%, rgb(69, 18, 234) 95.93%)',
  height: '1px',
});

export type DividerProps = {
  margin?: string;
};

export function Divider({ margin }: DividerProps) {
  if (margin) {
    return <div css={css({ margin }, dividerStyles)} />;
  }

  return <div css={dividerStyles} />;
}
