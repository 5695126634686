import typia from 'typia';
import { SchemaHelpersFor } from './schema-helpers';

export type NoData = void;

export namespace NoData {
  export const schema: SchemaHelpersFor<NoData> = {
    is: typia.createIs<NoData>(),
    assert: typia.createAssert<NoData>(),
    validate: typia.createValidate<NoData>(),
    stringify: typia.json.createStringify<NoData>(),
    clone: typia.misc.createClone<NoData>(),
  };
}
