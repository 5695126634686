import { StatusCode } from '@inkibra/api-base';
import { ErrorType } from '@inkibra/error-base';

export namespace InkibraRecordlessLibraryErrorCodes {
  export type INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_UPLOADED = ErrorType<
    'INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_UPLOADED',
    'The song is already uploaded'
  >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_UPLOADED: INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_UPLOADED =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_UPLOADED',
      StatusCode.CONFLICT,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_SONG_MISSING_AUDIO_FILE = ErrorType<
    'INKIBRA_RECORDLESS_LIBRARY_SONG_MISSING_AUDIO_FILE',
    'The song is missing an audio file'
  >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_MISSING_AUDIO_FILE: INKIBRA_RECORDLESS_LIBRARY_SONG_MISSING_AUDIO_FILE =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_MISSING_AUDIO_FILE',
      StatusCode.BAD_REQUEST,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_SONG_NOT_UPLOADED = ErrorType<
    'INKIBRA_RECORDLESS_LIBRARY_SONG_NOT_UPLOADED',
    `The song with id: ${string} is not uploaded`
  >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_NOT_UPLOADED: INKIBRA_RECORDLESS_LIBRARY_SONG_NOT_UPLOADED =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_NOT_UPLOADED',
      StatusCode.NOT_FOUND,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_PENDING_CATALOG_STATUS =
    ErrorType<
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_PENDING_CATALOG_STATUS',
      'Cannot set a catalog status of pending'
    >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_PENDING_CATALOG_STATUS: INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_PENDING_CATALOG_STATUS =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_PENDING_CATALOG_STATUS',
      StatusCode.BAD_REQUEST,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_DRAFT_CATALOG_STATUS =
    ErrorType<
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_DRAFT_CATALOG_STATUS',
      'Cannot set a catalog status of draft'
    >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_DRAFT_CATALOG_STATUS: INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_DRAFT_CATALOG_STATUS =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_DRAFT_CATALOG_STATUS',
      StatusCode.BAD_REQUEST,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_IN_REVIEW_CATALOG_STATUS =
    ErrorType<
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_IN_REVIEW_CATALOG_STATUS',
      'Cannot set a catalog status of in review'
    >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_IN_REVIEW_CATALOG_STATUS: INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_IN_REVIEW_CATALOG_STATUS =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_IN_REVIEW_CATALOG_STATUS',
      StatusCode.BAD_REQUEST,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_WITHDRAWN_CATALOG_STATUS =
    ErrorType<
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_WITHDRAWN_CATALOG_STATUS',
      'Cannot set a catalog status of withdrawn'
    >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_WITHDRAWN_CATALOG_STATUS: INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_WITHDRAWN_CATALOG_STATUS =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_INVALID_SET_WITHDRAWN_CATALOG_STATUS',
      StatusCode.BAD_REQUEST,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_NOT_FOUND = ErrorType<
    'INKIBRA_RECORDLESS_LIBRARY_NOT_FOUND',
    `The library with id: ${string} was not found`
  >;
  export const INKIBRA_RECORDLESS_LIBRARY_NOT_FOUND: INKIBRA_RECORDLESS_LIBRARY_NOT_FOUND =
    new ErrorType('INKIBRA_RECORDLESS_LIBRARY_NOT_FOUND', StatusCode.NOT_FOUND);

  export type INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_ADDED_TO_LIBRARY =
    ErrorType<
      'INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_ADDED_TO_LIBRARY',
      `The song with id: ${string} is already added to the library with id: ${string}`
    >;
  export const INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_ADDED_TO_LIBRARY: INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_ADDED_TO_LIBRARY =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_SONG_ALREADY_ADDED_TO_LIBRARY',
      StatusCode.CONFLICT,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_NOT_FOUND = ErrorType<
    'INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_NOT_FOUND',
    `The playlist with id: ${string} was not found`
  >;
  export const INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_NOT_FOUND: INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_NOT_FOUND =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_NOT_FOUND',
      StatusCode.NOT_FOUND,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_PERMISSION_DENIED = ErrorType<
    'INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_PERMISSION_DENIED',
    'You do not have permissions to update this playlist'
  >;
  export const INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_PERMISSION_DENIED: INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_PERMISSION_DENIED =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_PLAYLIST_PERMISSION_DENIED',
      StatusCode.FORBIDDEN,
    );

  export type INKIBRA_RECORDLESS_LIBRARY_ARRANGEMENT_FILE_CREATION_ERROR =
    ErrorType<
      'INKIBRA_RECORDLESS_LIBRARY_ARRANGEMENT_FILE_CREATION_ERROR',
      'There was an error creating the arrangement file'
    >;
  export const INKIBRA_RECORDLESS_LIBRARY_ARRANGEMENT_FILE_CREATION_ERROR: INKIBRA_RECORDLESS_LIBRARY_ARRANGEMENT_FILE_CREATION_ERROR =
    new ErrorType(
      'INKIBRA_RECORDLESS_LIBRARY_ARRANGEMENT_FILE_CREATION_ERROR',
      StatusCode.INTERNAL_SERVER_ERROR,
    );
}
