/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const transparentTextStyles = css({
  textFillColor: 'transparent',
});

export type TransparentTextProps = {
  value: string;
};

export function TransparentText({ value }: TransparentTextProps) {
  return <span css={transparentTextStyles}>{value}</span>;
}
