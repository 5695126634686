/** @jsxImportSource @emotion/react */

import {
  AuthPhase,
  AuthenticatedUserSessionFormProps,
} from '@inkibra/recordless.auth-api';
import { useState } from 'react';
import { Button } from './button';
import { ToneTempoLogo } from './icons/tone-tempo-logo';
import { TextInput } from './text-input';

function ToneTempoAccountFormWrapper(props: { children: React.ReactNode[] }) {
  return (
    <div
      css={{
        width: '100%',
        height: '100dvh',
        display: 'grid',
        gridTemplateRows: 'minmax(auto, 1400px) min-content',
        background: '#08031D',
      }}
    >
      <div
        css={{
          label: 'top-section',
          width: '100%',
          display: 'grid',
          gridAutoRows: '1fr',
          backgroundImage: 'url(https://cdn.recordless.app/tempo/banner.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div
          css={{
            label: 'top-section-overlay',
            backgroundImage:
              'linear-gradient(180deg, rgba(36, 14, 131, 0.00) 0%, rgba(13, 5, 47, 0.83) 70%, #08031D 100%)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'grid',
            gridAutoRows: 'min-content',
            gridTemplateColumns: 'min-content',
            gap: '50px',
            justifyContent: 'center',
            alignContent: 'center',
          }}
        >
          <div
            css={{
              display: 'inline-grid',
              gap: '50px',
              gridTemplateColumns: 'min-content min-content',
            }}
          >
            <span
              css={{
                fontSize: '40px',
                color: '#F2F2F7',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '1em',
                fontFamily: 'STIX Two Math',
                alignSelf: 'center',
              }}
            >
              𝔦𝔫𝕂𝔦𝔟𝔯𝔞
            </span>
            <ToneTempoLogo />
          </div>
          <div
            css={{
              textAlign: 'center',
              fontSize: '40px',
              color: '#F2F2F7',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '1em',
            }}
          >
            ToneTempo
          </div>
        </div>
      </div>
      <div
        css={{
          label: 'bottom-section',
          width: '100%',
          padding: '0 16px 32px',
          overflow: 'auto',
          display: 'grid',
          gridAutoRows: 'min-content',
          gap: '24px',
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

export type ToneTempoUserAuthenticationFormProps =
  AuthenticatedUserSessionFormProps;

export function ToneTempoUserAuthenticationForm(
  props: ToneTempoUserAuthenticationFormProps,
) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');

  switch (props.authPhase) {
    case AuthPhase.START: {
      return (
        <ToneTempoAccountFormWrapper>
          <div
            css={{
              label: 'welcome-to-tonetempo',
              fontWeight: '400',
              fontSize: '24px',
              color: '#F2F2F7',
              textAlign: 'center',
              lineHeight: '1.5em',
              paddingBottom: '50px',
            }}
          >
            Workouts, music, motivation.
            <br />
            Perfectly synced.
          </div>

          <Button
            type="submit"
            color="primary"
            contents="CREATE ACCOUNT"
            onClick={() => props.setAuthPhase(AuthPhase.SIGN_UP)}
          />

          <Button
            type="button"
            fillMode="outline"
            contents="SIGN IN"
            onClick={() => props.setAuthPhase(AuthPhase.LOGIN)}
          />
        </ToneTempoAccountFormWrapper>
      );
    }
    case AuthPhase.SIGN_UP: {
      return (
        <ToneTempoAccountFormWrapper>
          <div
            css={{
              label: 'create-your-account',
              fontWeight: '400',
              fontSize: '24px',
              color: '#F2F2F7',
              textAlign: 'center',
            }}
          >
            Create your account
          </div>

          <p
            css={{
              label: 'form-error-text',
              height: '1em',
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>

          <div>
            <form
              id="createAccountForm"
              onSubmit={(e) => {
                e.preventDefault();
                props.onSignUpStart(email, name);
              }}
              css={{ display: 'grid', gap: '24px' }}
            >
              <div css={{ display: 'grid', gap: '24px' }}>
                <TextInput
                  name="name"
                  label="NAME"
                  placeholder="Enter full name"
                  required={true}
                  value={name}
                  autoComplete="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div>
                <TextInput
                  name="email"
                  label="EMAIL"
                  placeholder="Enter email"
                  required={true}
                  value={email}
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </form>
          </div>

          <div
            css={{
              label: 'terms-and-conditions',
              fontWeight: '400',
              fontSize: '16px',
              color: '#F2F2F7',
              textAlign: 'center',
            }}
          >
            By signing up, you agree to the{' '}
            <a href="https://www.inkibra.com/Terms-of-Service-Updated-June-17th-2024-67108e6914ed4142ab0493fec6401b7f">
              Terms of Service
            </a>{' '}
            and{' '}
            <a href="https://www.inkibra.com/Privacy-Policy-Updated-June-17th-2024-38f19f77db0549c5a05ede923e6a99be">
              Privacy Policy.
            </a>
          </div>

          <div
            css={{
              label: 'age-verification',
              fontWeight: '400',
              fontSize: '16px',
              color: '#F2F2F7',
              textAlign: 'center',
            }}
          >
            You are 18 years of age.
          </div>

          <Button
            type="submit"
            form="createAccountForm"
            color="primary"
            disabled={false}
            contents="CREATE ACCOUNT"
          />

          <Button
            type="button"
            fillMode="outline"
            contents="GO BACK"
            onClick={() => props.setAuthPhase(AuthPhase.START)}
          />
        </ToneTempoAccountFormWrapper>
      );
    }
    case AuthPhase.LOGIN: {
      return (
        <ToneTempoAccountFormWrapper>
          <div
            css={{
              label: 'sign-in-to-continue',
              fontWeight: '400',
              fontSize: '24px',
              color: '#F2F2F7',
              textAlign: 'center',
            }}
          >
            Sign in to continue
          </div>

          <p
            css={{
              label: 'form-error-text',
              height: '1em',
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>

          <div>
            <form
              id="signInForm"
              onSubmit={(e) => {
                e.preventDefault();
                props.onLogin(email, password);
              }}
              css={{ display: 'grid', gap: '24px' }}
            >
              <div>
                <TextInput
                  name="email"
                  label="EMAIL"
                  placeholder="Enter email"
                  required={true}
                  value={email}
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div>
                <TextInput
                  name="password"
                  label="PASSWORD"
                  placeholder="Enter full password"
                  required={true}
                  value={password}
                  autoComplete="current-password"
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </form>
          </div>

          <Button
            type="submit"
            form="signInForm"
            color="primary"
            disabled={false}
            contents="SIGN IN"
          />

          <Button
            type="button"
            fillMode="outline"
            contents="FORGOT PASSWORD?"
            onClick={() => props.setAuthPhase(AuthPhase.GET_RECOVERY_CODE)}
          />

          <Button
            type="button"
            fillMode="outline"
            contents="GO BACK"
            onClick={() => props.setAuthPhase(AuthPhase.START)}
          />
        </ToneTempoAccountFormWrapper>
      );
    }
    case AuthPhase.VERIFY_SESSION_AUTHENTICATION_CODE:
    case AuthPhase.VERIFY_SESSION_RECOVERY_CODE: {
      return (
        <ToneTempoAccountFormWrapper>
          <div
            css={{
              label: 'verify-session-code',
              fontWeight: '400',
              fontSize: '24px',
              color: '#F2F2F7',
              textAlign: 'center',
              lineHeight: '1.5em',
              paddingBottom: '50px',
            }}
          >
            Verify your session code
          </div>

          <p
            css={{
              label: 'form-error-text',
              height: '1em',
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>

          <div>
            <form
              id="verifySessionCodeForm"
              onSubmit={(e) => {
                e.preventDefault();
                if (
                  props.authPhase ===
                  AuthPhase.VERIFY_SESSION_AUTHENTICATION_CODE
                ) {
                  props.onVerifySessionAuthenticationCode(code);
                } else if (
                  props.authPhase === AuthPhase.VERIFY_SESSION_RECOVERY_CODE
                ) {
                  props.onVerifySessionRecoveryCode(code);
                }
              }}
              css={{ display: 'grid', gap: '24px' }}
            >
              <div>
                <TextInput
                  name="one-time-code"
                  label="ONE TIME CODE"
                  placeholder="Enter one time code"
                  required={true}
                  value={code}
                  autoComplete="one-time-code"
                  onChange={(e) => setCode(e.target.value)}
                />
              </div>
            </form>
          </div>

          <Button
            type="submit"
            form="verifySessionCodeForm"
            color="primary"
            contents="VERIFY CODE"
          />

          <Button
            type="button"
            fillMode="outline"
            contents="START OVER"
            onClick={() => props.setAuthPhase(AuthPhase.START)}
          />
        </ToneTempoAccountFormWrapper>
      );
    }
    case AuthPhase.SET_PASSWORD: {
      return (
        <ToneTempoAccountFormWrapper>
          <div
            css={{
              label: 'set-password',
              fontWeight: '400',
              fontSize: '24px',
              color: '#F2F2F7',
              textAlign: 'center',
              lineHeight: '1.5em',
              paddingBottom: '50px',
            }}
          >
            Set your password
          </div>

          <p
            css={{
              label: 'form-error-text',
              height: '1em',
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>

          <div>
            <form
              id="setPasswordForm"
              onSubmit={(e) => {
                e.preventDefault();
                props.onSetPassword(password);
              }}
              css={{ display: 'grid', gap: '24px' }}
            >
              <div>
                <TextInput
                  name="set-password"
                  label="PASSWORD"
                  placeholder="Enter new password"
                  required={true}
                  value={password}
                  type="password"
                  autoComplete="new-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
            </form>
          </div>

          <Button
            type="submit"
            form="setPasswordForm"
            color="primary"
            contents="SET PASSWORD"
          />

          <Button
            type="button"
            fillMode="outline"
            contents="START OVER"
            onClick={() => props.setAuthPhase(AuthPhase.START)}
          />
        </ToneTempoAccountFormWrapper>
      );
    }
    case AuthPhase.GET_RECOVERY_CODE: {
      return (
        <ToneTempoAccountFormWrapper>
          <div
            css={{
              label: 'get-recovery-code',
              fontWeight: '400',
              fontSize: '24px',
              color: '#F2F2F7',
              textAlign: 'center',
              lineHeight: '1.5em',
              paddingBottom: '50px',
            }}
          >
            Get recovery code
          </div>

          <p
            css={{
              label: 'form-error-text',
              height: '1em',
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>

          <div>
            <form
              id="getRecoveryCodeForm"
              onSubmit={(e) => {
                e.preventDefault();
                props.onRecoverAccount(email);
              }}
              css={{ display: 'grid', gap: '24px' }}
            >
              <div>
                <TextInput
                  name="email"
                  label="EMAIL"
                  placeholder="Enter your email"
                  required={true}
                  value={email}
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </form>
          </div>

          <Button
            type="submit"
            form="getRecoveryCodeForm"
            color="primary"
            contents="GET RECOVERY CODE"
          />

          <Button
            type="button"
            fillMode="outline"
            contents="START OVER"
            onClick={() => props.setAuthPhase(AuthPhase.START)}
          />
        </ToneTempoAccountFormWrapper>
      );
    }
  }
  return null;
}
