/** @jsxImportSource @emotion/react */

import { Fragment } from 'react';

/**
 * Represents the props for the MusicBeatGrid component.
 */
export type MusicBeatGridProps = {
  /**
   * The width of the beat grid in pixels.
   */
  width: number;
  /**
   * The width of a beat in pixels.
   */
  beatWidth: number;
  /**
   * The offset of the beat grid in pixels;
   */
  gridOffset: number;
  /**
   * The index of the beat to highlight.
   */
  highlightedBeatIndex?: number;
  /**
   * The event handler for when the mouse hovers over a beat.
   */
  onHover: (beat: number) => void;
  /**
   * The event handler for when the mouse leaves the beat grid.
   */
  onLeave: () => void;
  /**
   * The event handler for when a beat is double-clicked.
   */
  onDoubleClick?: (beat: number) => void;
};

function generateBeatGrid(width: number, beatWidth: number) {
  const beats = [];
  for (let i = 0; i < width; i += beatWidth) {
    beats.push(i);
  }
  return beats;
}

const BEAT_GRID_GREEN = '#24FF00';

/**
 * Represents a grid of beats for a music track.
 * It exposes events for when the mouse hovers over a beat.
 */
export const MusicBeatGrid = (props: MusicBeatGridProps) => {
  const height = 75;
  const beats = generateBeatGrid(props.width, props.beatWidth);
  return (
    <svg width={props.width} height={height}>
      {/* The red reference line */}
      <line
        x1="0"
        y1={height / 2}
        x2={props.width}
        y2={height / 2}
        stroke="red"
        strokeWidth={1}
      />
      {/* The beat lines in green */}
      {beats.map((beatPosition, beatIndex) => {
        const strokeWidth = beatIndex % 4 === 0 ? 4 : 1;
        if (beatIndex % 4 !== 0) {
          // Modification to only show every 4th beat
          return null;
        }
        const interactionWidth = 10;
        return (
          <Fragment key={`${beatIndex}-line-and-interaction`}>
            <line
              x1={props.gridOffset + beatPosition}
              y1={0}
              x2={props.gridOffset + beatPosition}
              y2={height}
              stroke={BEAT_GRID_GREEN}
              strokeWidth={
                props.highlightedBeatIndex === beatIndex ? 8 : strokeWidth
              }
            />
            {/* The wider interactive area rectangle */}
            {/* biome-ignore lint/a11y/useKeyWithMouseEvents: <explanation> */}
            <rect
              onDoubleClick={() =>
                props.onDoubleClick ? props.onDoubleClick(beatIndex) : undefined
              }
              onMouseOver={() => props.onHover(beatIndex)}
              onMouseLeave={props.onLeave}
              x={props.gridOffset + beatPosition - interactionWidth / 2}
              y={0}
              width={interactionWidth} // Adjust this according to your requirements
              height={height}
              fillOpacity="0"
            />
          </Fragment>
        );
      })}
    </svg>
  );
};
