/** @jsxImportSource react */

import { Grid, Typography } from '@mui/material';
import { Suspense, lazy } from 'react';

const WaitlistCreatorLazy = lazy(() => import('./waitlist-creator'));
export default function WaitlistHeader() {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h3" component="h3" gutterBottom>
          Waitlist Management
        </Typography>
      </Grid>
      <Grid item>
        <Suspense>
          <WaitlistCreatorLazy />
        </Suspense>
      </Grid>
    </Grid>
  );
}
