/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { ResponsiveValue, responsiveStyle } from '../constants/media-query';

const titleTextStyles = css({
  webkitBackgroundClip: 'text',
  backgroundClip: 'text',
  marginBottom: '8px',
});

export enum TitleTextBackground {
  ORANGE_TO_PINK = 'linear-gradient(90.15deg, #FC7C06 11.77%, #FF0BD8 43.67%)',
  BLUE_TO_GREEN = 'linear-gradient(90.95deg, #0B4FFF 54.74%, #1CD70C 102.7%)',
  RED_TO_YELLOW = 'linear-gradient(91.44deg, #F31875 48.78%, #EFE75E 96.22%)',
  RED_TO_PURPLE = 'linear-gradient(262.88deg, #F31875 24.9%, #5700FF 55.55%), #56B9F7;',
  WHITE = 'white',
}

export type TitleTextProps = {
  textAlign: ResponsiveValue<string>;
  background: TitleTextBackground;
  fontSize: ResponsiveValue<string>;
  fontWeight?: number;
};

export function TitleText({
  textAlign,
  background,
  fontSize,
  fontWeight = 500,
  children,
}: TitleTextProps & { children: React.ReactNode }) {
  return (
    <div
      css={css(
        { fontWeight, background },
        titleTextStyles,
        responsiveStyle('textAlign', textAlign),
        responsiveStyle('fontSize', fontSize),
      )}
    >
      {children}
    </div>
  );
}
