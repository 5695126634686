import {
  MimeType,
  RemoteBinaryLocator,
  SchemaHelpersFor,
} from '@inkibra/api-base';
import typia from 'typia';

export type InkibraRecordlessLibraryMusicFileRemoteBinaryLocator =
  RemoteBinaryLocator<
    MimeType.AUDIO_MPEG,
    typeof InkibraRecordlessLibraryMusicFileRemoteBinaryLocator.typename
  >;

export namespace InkibraRecordlessLibraryMusicFileRemoteBinaryLocator {
  export const schema: SchemaHelpersFor<InkibraRecordlessLibraryMusicFileRemoteBinaryLocator> =
    {
      is: typia.createIs<InkibraRecordlessLibraryMusicFileRemoteBinaryLocator>(),
      assert:
        typia.createAssert<InkibraRecordlessLibraryMusicFileRemoteBinaryLocator>(),
      validate:
        typia.createValidate<InkibraRecordlessLibraryMusicFileRemoteBinaryLocator>(),
      stringify:
        typia.json.createStringify<InkibraRecordlessLibraryMusicFileRemoteBinaryLocator>(),
      clone:
        typia.misc.createClone<InkibraRecordlessLibraryMusicFileRemoteBinaryLocator>(),
    };
  export const typename =
    'INKIBRA_RECORDLESS_LIBRARY_MUSIC_FILE_REMOTE_BINARY_LOCATOR';
}
