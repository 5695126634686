/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { responsiveStyle } from '../constants/media-query';

import { TestimonialCard, TestimonialCardProps } from './testimonial-card';

const carouselSyles = css(
  {
    width: '100%',
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
    rowGap: '32px',
    justifyContent: 'space-around',
    padding: '64px 0px',
  },
  responsiveStyle('gridTemplateColumns', {
    xs: '1fr',
    sm: '1fr',
    md: '1fr',
    lg: '1fr 1fr 1fr',
    xl: '1fr 1fr 1fr',
  }),
);

export type TestimonialCardCarouselProps = {
  testimonials: TestimonialCardProps[];
};

export function TestimonialCardCarousel({
  testimonials,
}: TestimonialCardCarouselProps) {
  return (
    <div css={carouselSyles}>
      {testimonials.map((testimonial) => (
        <TestimonialCard
          name={testimonial.name}
          location={testimonial.location}
          avatarPath={testimonial.avatarPath}
          quote={testimonial.quote}
        />
      ))}
    </div>
  );
}
