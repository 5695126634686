import { SchemaHelpersFor } from '@inkibra/api-base';
import { Brand, BrandRegex } from '@inkibra/observable-cache';
import { InkibraSession } from '@inkibra/recordless.auth-api';
import { InkibraRecordlessLibrarySongType } from '@inkibra/recordless.library-api';
import typia from 'typia';

export type InkibraRecordlessWorkoutType = {
  id: Brand<typeof InkibraRecordlessWorkoutType.typename> &
    BrandRegex<typeof InkibraRecordlessWorkoutType.typename>;
  ownerId: InkibraSession['id'];
  name: string & typia.tags.MinLength<1> & typia.tags.MaxLength<20>;
  workoutType: InkibraRecordlessWorkoutType.WorkoutType;
  description?: string & typia.tags.MinLength<70> & typia.tags.MaxLength<500>;
  backgroundImage?: string;
  type: typeof InkibraRecordlessWorkoutType.typename;
  intervals: InkibraRecordlessWorkoutIntervalType[];
  modified: string & typia.tags.Format<'date-time'>;
  created: string & typia.tags.Format<'date-time'>;
  deleted?: string & typia.tags.Format<'date-time'>;
};

export type InkibraRecordlessWorkoutIntervalType = {
  time: number & typia.tags.Minimum<30> & typia.tags.Maximum<600>;
  /** @deprecated */
  songInfo?: unknown;
  /** @deprecated */
  bpmRange?: unknown;
  bpmLock?: number & typia.tags.Minimum<80> & typia.tags.Maximum<180>;
  /** @deprecated */
  genres?: unknown;
  energy: number & typia.tags.Minimum<0> & typia.tags.Maximum<10>;
  name: string & typia.tags.MinLength<1> & typia.tags.MaxLength<18>;
  comments: string;
};

export namespace InkibraRecordlessWorkoutType {
  export enum WorkoutType {
    DISTANCE_WALKING = 'DISTANCE_WALKING',
    DISTANCE_RUNNING = 'DISTANCE_RUNNING',
    DISTANCE_JOGGING = 'DISTANCE_JOGGING',
    DISTANCE_HIKING = 'DISTANCE_HIKING',
    USER_WORKOUT = 'USER_WORKOUT',
    CATALOG_WORKOUT = 'CATALOG_WORKOUT',
    NEW_CATALOG_WORKOUT = 'NEW_CATALOG_WORKOUT',
  }

  export enum WorkoutGenre {
    POP = 'POP',
    RAP = 'RAP',
    EDM = 'EDM',
  }

  export namespace WorkoutGenre {
    export function convertToSongGenre(
      genre: WorkoutGenre,
    ): InkibraRecordlessLibrarySongType.GenreLiterals[] {
      switch (genre) {
        case WorkoutGenre.POP:
          return ['Hip-Hop/Rap', 'Pop', 'House (EDM)'];
        case WorkoutGenre.RAP:
          return [
            'Trap (Rap)',
            'Drill (Rap)',
            'Hip-Hop/Rap',
            'NY Drill (Rap)',
            'UK Drill (Rap)',
            'Chicago Drill (Rap)',
            'Amapiano',
          ];
        case WorkoutGenre.EDM:
          return [
            'EDM',
            'Bass (EDM)',
            'Trap (EDM)',
            'House (EDM)',
            'Techno (EDM)',
            'Dubstep (EDM)',
            'G-House (EDM)',
            'Hardstyle (EDM)',
            'Hip House (EDM)',
            'Deep House (EDM)',
            'Tech House (EDM)',
            'Tech House (EDM)',
            'Amapiano',
          ];
      }
    }
  }

  export const schema: SchemaHelpersFor<InkibraRecordlessWorkoutType> = {
    is: typia.createIs<InkibraRecordlessWorkoutType>(),
    assert: typia.createAssert<InkibraRecordlessWorkoutType>(),
    validate: typia.createValidate<InkibraRecordlessWorkoutType>(),
    stringify: typia.json.createStringify<InkibraRecordlessWorkoutType>(),
    clone: typia.misc.createClone<InkibraRecordlessWorkoutType>(),
  };

  /**
   * @description Stands For inKibra Tonetempo Workout
   */
  export const typename = 'nkttworkout';

  export function is(val: unknown): val is InkibraRecordlessWorkoutType {
    return (val as InkibraRecordlessWorkoutType | undefined)?.type === typename;
  }

  export function fromCreation(
    val: Creation,
    ownerId: InkibraSession['id'],
  ): InkibraRecordlessWorkoutType {
    return {
      ...val,
      id: Brand.createId2<typeof typename>(typename),
      ownerId,
      type: InkibraRecordlessWorkoutType.typename,
      modified: new Date().toISOString(),
      created: new Date().toISOString(),
    };
  }

  export type Creation = Pick<
    InkibraRecordlessWorkoutType,
    'name' | 'workoutType' | 'intervals'
  >;

  export namespace Creation {
    export const schema: SchemaHelpersFor<Creation> = {
      is: typia.createIs<Creation>(),
      assert: typia.createAssert<Creation>(),
      validate: typia.createValidate<Creation>(),
      stringify: typia.json.createStringify<Creation>(),
      clone: typia.misc.createClone<Creation>(),
    };
  }

  export function fromModification(
    val: Modification,
    workout: InkibraRecordlessWorkoutType,
  ): InkibraRecordlessWorkoutType {
    return {
      ...{
        ...workout,
        ...val,
      },
      modified: new Date().toISOString(),
    };
  }

  export type Modification = Partial<
    Pick<
      InkibraRecordlessWorkoutType,
      'name' | 'intervals' | 'description' | 'workoutType'
    >
  >;

  export namespace Modification {
    export const schema: SchemaHelpersFor<Modification> = {
      is: typia.createIs<Modification>(),
      assert: typia.createAssert<Modification>(),
      validate: typia.createValidate<Modification>(),
      stringify: typia.json.createStringify<Modification>(),
      clone: typia.misc.createClone<Modification>(),
    };
  }

  export function fromDeletion(
    workout: InkibraRecordlessWorkoutType,
  ): InkibraRecordlessWorkoutType {
    return {
      ...workout,
      deleted: new Date().toISOString(),
    };
  }

  export function getIntervalsWithStartAndEndTimes(
    workout: InkibraRecordlessWorkoutType,
  ) {
    let endTime = 0;
    return workout.intervals.map((interval, index) => {
      const intervalStartTime = endTime;
      endTime += interval.time;
      return { ...interval, startTime: intervalStartTime, endTime, index };
    });
  }

  export function getIntervalAtTime(
    workout: InkibraRecordlessWorkoutType,
    time: number,
  ) {
    const intervalsWithStartAndEndTimes =
      getIntervalsWithStartAndEndTimes(workout);
    return intervalsWithStartAndEndTimes.find((interval) => {
      return time >= interval.startTime && time < interval.endTime;
    });
  }

  export function intervalEnergyToSongEnergy(
    interval: InkibraRecordlessWorkoutIntervalType,
  ) {
    const minScale = 4;
    const maxScale = 8;
    return (interval.energy / 10) * (maxScale - minScale) + minScale;
  }
}
