/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

const styles = css({
  width: '100%',
  border: '1px solid #5B5B5B',
  borderRadius: '12px',
  position: 'relative',
  fontSize: '12px',
});

const labelStyles = css({
  color: '#FFFFFF',
  fontWeight: '700',
  padding: '0 6px',
  backgroundColor: '#08031D',
  position: 'relative',
  top: '-8px',
  left: '8px',
});

const inputStyles = css({
  color: '#FFFFFF',
  width: '100%',
  fontWeight: '300',
  padding: '16px',
  backgroundColor: '#08031D',
  border: 'none',
  boxSizing: 'border-box',
  position: 'relative',
  top: '-8px',
});

const errorStyles = css({
  fontSize: '12px',
  color: 'red',
});

export type TextInputProps = {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  value: string | number | undefined;
  error?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  autoComplete?:
    | 'one-time-code'
    | 'email'
    | 'username'
    | 'current-password'
    | 'new-password'
    | 'name';
  type?: 'text' | 'password';
};

export function TextInput({
  name,
  label,
  placeholder,
  required = false,
  value,
  error,
  onChange,
  type = 'text',
  autoComplete,
}: TextInputProps) {
  return (
    <div css={styles}>
      <label css={labelStyles} htmlFor={name}>
        {label}
      </label>

      <input
        css={inputStyles}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        required={required}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
      />

      {error && <div css={errorStyles}>{error}</div>}
    </div>
  );
}
