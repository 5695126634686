import { SchemaHelpersFor } from '@inkibra/api-base';
import {
  Brand,
  BrandRegex,
  CacheableObjectUtil,
} from '@inkibra/observable-cache';
import { InkibraSession } from '@inkibra/recordless.auth-api';
import typia from 'typia';

export type InkibraRecordlessBillingType = {
  id: Brand<typeof InkibraRecordlessBillingType.typename> &
    BrandRegex<typeof InkibraRecordlessBillingType.typename>;
  userId: InkibraSession['id'];
  stripeCustomerId: Brand<'stripe-customer-id'>;
  type: typeof InkibraRecordlessBillingType.typename;
  modified: string & typia.tags.Format<'date-time'>;
  created: string & typia.tags.Format<'date-time'>;
};

export namespace InkibraRecordlessBillingType {
  export const schema: SchemaHelpersFor<InkibraRecordlessBillingType> = {
    is: typia.createIs<InkibraRecordlessBillingType>(),
    assert: typia.createAssert<InkibraRecordlessBillingType>(),
    validate: typia.createValidate<InkibraRecordlessBillingType>(),
    stringify: typia.json.createStringify<InkibraRecordlessBillingType>(),
    clone: typia.misc.createClone<InkibraRecordlessBillingType>(),
  };

  /**
   * @description Stands for inKibra Billing
   */
  export const typename = 'nkbill';
  export function is(val: unknown): val is InkibraRecordlessBillingType {
    return (val as InkibraRecordlessBillingType).type === typename;
  }
  export function getKeyFromSessionId(userId: InkibraSession['id']) {
    const id = CacheableObjectUtil.stripId(userId, InkibraSession.typename);
    if (id.isErr()) {
      throw new TypeError('Invalid userId');
    }
    return `${typename}_${id.value}` as Brand<
      typeof InkibraRecordlessBillingType.typename
    >;
  }
  export function fromCreation(val: Creation): InkibraRecordlessBillingType {
    return {
      ...val,
      id: getKeyFromSessionId(val.userId),
      userId: val.userId,
      stripeCustomerId: val.stripeCustomerId,
      modified: new Date().toISOString(),
      created: new Date().toISOString(),
      type: InkibraRecordlessBillingType.typename,
    } as const;
  }

  export type Creation = Pick<
    InkibraRecordlessBillingType,
    'userId' | 'stripeCustomerId'
  >;

  export namespace Creation {
    export const schema: SchemaHelpersFor<Creation> = {
      is: typia.createIs<Creation>(),
      assert: typia.createAssert<Creation>(),
      validate: typia.createValidate<Creation>(),
      stringify: typia.json.createStringify<Creation>(),
      clone: typia.misc.createClone<Creation>(),
    };
  }
}
