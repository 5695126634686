/** @jsxImportSource @emotion/react */

import {
  CSS,
  DndContext,
  DragHandle,
  DragOverlay,
  KeyboardSensor,
  MouseSensor,
  SortableContext,
  TouchSensor,
  arrayMove,
  useDraggable,
  useDroppable,
  useSensor,
  useSensors,
  useSortable,
  verticalListSortingStrategy,
} from '@inkibra/recordless.ux';
import { ok } from 'neverthrow';
import { useState } from 'react';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibrarySongType } from '../type-song';

export type RecordlessArrangementGuideExternalProps = {
  id: string;
  arrangementName: string;
  orderedSectionsInfo: InkibraRecordlessLibraryArrangementType.SectionArrangement[];
  // TODO: add problematic section arrangements here
};

export type RecordlessArrangementComposerProps = {
  orderedSectionsInfo: InkibraRecordlessLibrarySongType.Section[];
  newArrangement: InkibraRecordlessLibraryArrangementType;
  maybeSelectedSectionArrangementId?: InkibraRecordlessLibraryArrangementType.SectionArrangement['id'];
  arrangementUpdate: (
    updatedArrangement: InkibraRecordlessLibraryArrangementType,
  ) => void;
};

export function RecordlessArrangementComposer(
  props: RecordlessArrangementComposerProps,
) {
  const [activeId, setActiveId] = useState<string | null>(null);
  const maybeDraggingSection = props.orderedSectionsInfo.find(
    (sectionInfo) => sectionInfo.id === activeId,
  );
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 10,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});

  return (
    <DndContext
      autoScroll={false}
      sensors={sensors}
      onDragStart={(event) => {
        setActiveId(event.active.id as string);
      }}
      onDragEnd={(event) => {
        if (event.over && maybeDraggingSection) {
          const updatedArrangement = arrangementUtil.addSectionArrangement(
            props.newArrangement,
            maybeDraggingSection.id,
          );
          if (updatedArrangement.isOk()) {
            props.arrangementUpdate(updatedArrangement.value);
          }
        }
        setActiveId(null);
      }}
    >
      {/* Default Song Arrangement Guide */}
      <div
        css={{
          width: 'calc(100% - 40px)',
          height: '185px',
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            backgroundColor: 'transparent',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'rgba(152, 115, 115, 0.34)',
            borderRadius: '10px',
          },
          background: 'rgba(93, 96, 105, 0.6)',
          borderRadius: '4px',
          display: 'grid',
          paddingLeft: '20px',
          paddingRight: '20px',
          marginBottom: '40px',
          gridTemplateRows: '100%',
          gridTemplateAreas: `
        "song-sections-display-row"
      `,
        }}
      >
        <div
          css={{
            gridArea: 'song-sections-display-row',
            whiteSpace: 'nowrap',
            marginTop: 'auto',
            marginBottom: 'auto',
            display: 'inline-block',
          }}
        >
          {props.orderedSectionsInfo.map((sectionInfo) => {
            return (
              <DraggableSongSection
                key={sectionInfo.id}
                section={sectionInfo}
              />
            );
          })}
        </div>
      </div>
      <DragOverlay>
        {maybeDraggingSection ? (
          <DraggableSongSection section={maybeDraggingSection} />
        ) : null}
      </DragOverlay>
      {/* Additional Arrangement Guides */}

      <ArrangementGuide
        arrangement={props.newArrangement}
        maybeSelectedSectionArrangementId={
          props.maybeSelectedSectionArrangementId
        }
        onArrangementOrderUpdate={(data) => {
          props.arrangementUpdate(data);
        }}
      />
    </DndContext>
  );
}

type DraggableSongSectionProps = {
  section: InkibraRecordlessLibrarySongType.Section;
};

function DraggableSongSection(props: DraggableSongSectionProps) {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id: props.section.id,
  });
  return (
    <span
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      css={{
        color: 'white',
        cursor: 'grab',
        minWidth: '88px',
        marginBottom: '8px',
        marginRight: '12px',
        height: '40px',
        lineHeight: '40px',
        background:
          'linear-gradient(180deg, rgba(3, 1, 17, 0.34) 5.49%, #030111 92.1%)',
        padding: '8px',
        borderRadius: '4px',
        display: 'block',
        fontFamily:
          "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        position: 'relative',
      }}
      key={props.section.id}
    >
      {props.section.name}
      <DragHandle
        style={{
          fill: '#919eab',
          marginLeft: '5px',
          position: 'absolute',
          right: '2px',
          top: '22px',
        }}
      />
    </span>
  );
}

type SortableDraggableSongSectionProps = {
  song: InkibraRecordlessLibrarySongType;
  sectionArrangement: InkibraRecordlessLibraryArrangementType.SectionArrangement;
  selected: boolean;
  onDeleted: () => void;
};

function SortableDraggableSongSection(
  props: SortableDraggableSongSectionProps,
) {
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});
  const sectionNameResult = songUtil
    .getSectionById(props.song, props.sectionArrangement.sectionFieldId)
    .andThen((section) => {
      return ok(section.name);
    });
  if (sectionNameResult.isErr()) {
    throw new Error('Section not found');
  }
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.sectionArrangement.id,
      // animateLayoutChanges: animateLayoutChanges
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  return (
    <span
      onDoubleClick={props.onDeleted}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      style={style}
      css={{
        color: 'white',
        cursor: 'grab',
        minWidth: '88px',
        marginRight: '12px',
        marginBottom: '8px',
        height: '40px',
        lineHeight: '40px',
        background:
          'linear-gradient(180deg, rgba(3, 1, 17, 0.34) 5.49%, #030111 92.1%)',
        padding: '8px',
        borderRadius: '4px',
        display: 'block',
        fontFamily:
          "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
        position: 'relative',
      }}
      key={props.sectionArrangement.id}
    >
      {props.selected ? 'Selected: ' : ''}
      {sectionNameResult.value}
      <DragHandle
        style={{
          fill: '#919eab',
          marginLeft: '5px',
          position: 'absolute',
          right: '2px',
          top: '22px',
        }}
      />
    </span>
  );
}

type ArrangementGuideInternalProps = {
  onArrangementOrderUpdate: (
    updatedArrangement: InkibraRecordlessLibraryArrangementType,
  ) => void;
  maybeSelectedSectionArrangementId?: InkibraRecordlessLibraryArrangementType.SectionArrangement['id'];
  arrangement: InkibraRecordlessLibraryArrangementType;
};

function ArrangementGuide(props: ArrangementGuideInternalProps) {
  // const [activeId, setActiveId] = useState<string | null>(null);
  const { isOver, setNodeRef } = useDroppable({
    id: `droppable-arrangement-guide-${props.arrangement.id}`,
  });
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor, {
    activationConstraint: {
      delay: 100,
      tolerance: 10,
    },
  });
  const keyboardSensor = useSensor(KeyboardSensor);
  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});
  return (
    <DndContext
      autoScroll={false}
      sensors={sensors}
      onDragEnd={(event) => {
        const { active, over } = event;
        if (over && active.id !== over.id) {
          const oldIndex = props.arrangement.sectionArrangements.findIndex(
            (item) => item.id === active.id,
          );
          const newIndex = props.arrangement.sectionArrangements.findIndex(
            (item) => item.id === over.id,
          );
          const updatedSectionArrangementOrder = arrayMove(
            props.arrangement.sectionArrangements,
            oldIndex,
            newIndex,
          );
          const updatedSectionArrangementOrderResult =
            arrangementUtil.updateSectionArrangementOrder(
              props.arrangement,
              updatedSectionArrangementOrder,
            );
          if (updatedSectionArrangementOrderResult.isOk()) {
            props.onArrangementOrderUpdate(
              updatedSectionArrangementOrderResult.value,
            );
          }
        }
      }}
    >
      <div
        ref={setNodeRef}
        css={{
          opacity: isOver ? 0.5 : 1,
          width: '100%',
          height: '185px',
          overflowY: 'scroll',
          '::-webkit-scrollbar': {
            backgroundColor: 'transparent',
          },
          '::-webkit-scrollbar-thumb': {
            background: 'rgba(152, 115, 115, 0.34)',
            borderRadius: '10px',
          },
          background: 'rgba(93, 96, 105, 0.6)',
          borderRadius: '4px 4px',
          display: 'grid',
          rowGap: '8px',
          gridTemplateAreas: `
          "song-arrangement-info-row"
          "song-arrangement-display-row"
        `,
        }}
      >
        <SortableContext
          items={props.arrangement.sectionArrangements}
          strategy={verticalListSortingStrategy}
        >
          <div
            css={{
              gridArea: 'song-arrangement-display-row',
              whiteSpace: 'nowrap',
              marginTop: 'auto',
              marginBottom: 'auto',
              display: 'inline-block',
              paddingLeft: '20px',
              paddingRight: '20px',
            }}
          >
            {props.arrangement.sectionArrangements.map((sectionInfo, index) => {
              return (
                <SortableDraggableSongSection
                  key={sectionInfo.id}
                  song={props.arrangement.librarySong}
                  selected={
                    props.maybeSelectedSectionArrangementId === sectionInfo.id
                  }
                  sectionArrangement={sectionInfo}
                  onDeleted={() => {
                    const updatedArrangementOrder =
                      props.arrangement.sectionArrangements.filter(
                        (_, removedIndex) => index !== removedIndex,
                      );
                    const updatedArrangementOrderResult =
                      arrangementUtil.updateSectionArrangementOrder(
                        props.arrangement,
                        updatedArrangementOrder,
                      );
                    if (updatedArrangementOrderResult.isOk()) {
                      props.onArrangementOrderUpdate(
                        updatedArrangementOrderResult.value,
                      );
                    }
                  }}
                />
              );
            })}
          </div>
          {/* <DragOverlay>
          {
            maybeDraggingSectionInfo ?
              <DraggableSongSection {...maybeDraggingSectionInfo} />
              :
              null
          }
        </DragOverlay> */}
        </SortableContext>
        {props.arrangement.sectionArrangements.length === 0 ? (
          <span
            css={{
              fontFamily:
                "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
              fontSize: '18px',
              justifySelf: 'center',
              alignSelf: 'end',
            }}
          >
            Drag Song Sections Here
          </span>
        ) : null}
      </div>
    </DndContext>
  );
}
