export enum MimeType {
  APPLICATION_JSON = 'application/json',
  APPLICATION_PDF = 'application/pdf',
  APPLICATION_DATA = 'application/data',
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_PNG = 'image/png',
  TEXT_JAVASCRIPT = 'text/javascript',
  TEXT_HTML = 'text/html',
  TEXT_XML = 'text/xml',
  TEXT_PLAIN = 'text/plain',
  TEXT_CSS = 'text/css',
  AUDIO_MPEG = 'audio/mpeg',
  AUDIO_WAV = 'audio/wav',
  AUDIO_MP4 = 'audio/x-m4a',
  MULTIPART_FORM_DATA = 'multipart/form-data',
}
