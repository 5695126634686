export enum InkibraSessionRoles {
  /** Special role that grants all other roles */
  GOD = 'GOD',
  WAITLIST_ADMIN = 'WAITLIST_ADMIN',
  WAITLIST_USER = 'WAITLIST_USER',
  LIBRARY_USER = 'LIBRARY_USER',
  LIBRARY_ADMIN = 'LIBRARY_ADMIN',
  WORKOUT_ADMIN = 'WORKOUT_ADMIN',
  WORKOUT_USER = 'WORKOUT_USER',
}

export namespace InkibraSessionRoles {
  export function getDefaultRoles() {
    return [
      InkibraSessionRoles.LIBRARY_USER,
      InkibraSessionRoles.WAITLIST_USER,
      InkibraSessionRoles.WORKOUT_USER,
    ];
  }
  export function hasRole(
    role: InkibraSessionRoles,
    roles: InkibraSessionRoles[] = [],
  ) {
    if (roles.includes(InkibraSessionRoles.GOD)) {
      return true;
    }
    roles = getDefaultRoles().concat(roles);
    if (roles.includes(role)) {
      return true;
    }
    return false;
  }
}
