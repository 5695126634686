import { Bunyan, Logger } from '@inkibra/logger';
import { InkibraSessionApiFetcherRegistry } from './api';

export function initLogForwarding(
  logger: Bunyan,
  level: 'info' | 'debug' | 'trace',
  clientId: string,
) {
  logger.addStream({
    level,
    type: 'raw',
    stream: {
      write: (rec: Object) => {
        if (
          (rec as { routeName: string }).routeName ===
          InkibraSessionApiFetcherRegistry.get(
            'SendInkibraRecordlessSessionEvent',
          ).name
        ) {
          return;
        }
        const clientLogMessage = (rec as { msg: string }).msg;
        const clientLogTime = (rec as { time: string }).time;
        const clientLogLevel =
          Logger.nameFromLevel[(rec as { level: number }).level];
        InkibraSessionApiFetcherRegistry.get(
          'SendInkibraRecordlessSessionEvent',
        ).fn({
          body: {
            type: 'CLIENT_LOG',
            data: {
              clientLogLevel,
              clientLogMessage,
              clientLogTime,
              clientLogData: rec,
              clientId,
            },
          },
          files: undefined,
          pathParams: {},
          pathQuery: {},
        });
      },
    },
  });
}
