import {
  ApiRoute,
  EmptyObject,
  GenericRegistry,
  HttpMethod,
  NoData,
  SchemaHelpersFor,
  SerializableResult,
  StatusCode,
} from '@inkibra/api-base';
import typia from 'typia';

export namespace CreateCheckoutSession {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = {
    priceId: string;
    successUrl: string;
    cancelUrl: string;
  };
  export namespace Body {
    export const schema: SchemaHelpersFor<Body> = {
      is: typia.createIs<Body>(),
      assert: typia.createAssert<Body>(),
      validate: typia.createValidate<Body>(),
      stringify: typia.json.createStringify<Body>(),
      clone: typia.misc.createClone<Body>(),
    };
  }

  export type Response = SerializableResult.OkWithStatusCode<
    { url: string },
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/billing/create-checkout-session',
    {
      Name: 'createCheckoutSession';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createCheckoutSession',
    method: HttpMethod.POST,
    path: '/api/inkibra/billing/create-checkout-session',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CheckSubscriptionStatus {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = EmptyObject.schema;
  }

  export type PathQuery = { productId: string };
  export namespace PathQuery {
    export const schema: SchemaHelpersFor<PathQuery> = {
      is: typia.createIs<PathQuery>(),
      assert: typia.createAssert<PathQuery>(),
      validate: typia.createValidate<PathQuery>(),
      stringify: typia.json.createStringify<PathQuery>(),
      clone: typia.misc.createClone<PathQuery>(),
    };
  }

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    { status: 'active' | 'inactive' },
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/billing/subscription-status',
    {
      Name: 'checkSubscriptionStatus';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'checkSubscriptionStatus',
    method: HttpMethod.GET,
    path: '/api/inkibra/billing/subscription-status',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export const InkibraRecordlessBillingApiRouteRegistry = GenericRegistry.init({
  createCheckoutSession: CreateCheckoutSession.Route,
  checkSubscriptionStatus: CheckSubscriptionStatus.Route,
});

export const InkibraRecordlessBillingApiFetcherRegistry =
  InkibraRecordlessBillingApiRouteRegistry.matchWithAll((routes) => {
    return {
      ...routes.createCheckoutSession.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.checkSubscriptionStatus.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
    };
  });
