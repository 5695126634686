/** @jsxImportSource react */

import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { HookEffectContext } from '@inkibra/api-base';
import { useAllWorkouts } from '@inkibra/recordless.tempo-api';
import { Connector } from '../../common/lib/connector';

import {
  InkibraRecordlessTempoApiFetcherRegistry,
  InkibraRecordlessWorkoutIntervalType,
} from '@inkibra/recordless.tempo-api';

export default function WorkoutList() {
  const connector = Connector.useContext();
  const hookEffectContext = new HookEffectContext();
  const workouts = hookEffectContext.addEffect(useAllWorkouts());
  hookEffectContext.runEffects(connector.cache);

  const handleDeleteClick = async (id: string) => {
    const pathParams = {
      id: id,
    };

    const response = await InkibraRecordlessTempoApiFetcherRegistry.get(
      'deleteInkibraRecordlessWorkout',
    ).fn({
      pathParams: pathParams,
      pathQuery: {},
      body: undefined,
      files: undefined,
    });

    connector.cache.input.next(response.value);

    return true;
  };

  const columns: GridColDef[] = [
    {
      field: 'open',
      headerName: '',
      width: 100,
      renderCell: (params) => {
        const id = params.row.id as string;

        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            component={Link}
            to={`/admin/workouts/${id}`}
          >
            Open
          </Button>
        );
      },
    },
    {
      field: 'delete',
      headerName: '',
      width: 125,
      renderCell: (params) => {
        const id = params.row.id as string;

        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleDeleteClick(id)}
          >
            Delete
          </Button>
        );
      },
    },
    {
      field: 'id',
      headerName: 'ID',
      width: 325,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 200,
    },
    {
      field: 'intervals',
      headerName: 'Intervals',
      sortable: false,
      width: 70,
      renderCell: (params) => {
        const intervals = params.row.intervals as unknown[];

        return <span>{intervals.length}</span>;
      },
    },
    {
      field: 'duration',
      headerName: 'Duration',
      sortable: false,
      width: 70,
      renderCell: (params) => {
        const intervals = params.row
          .intervals as InkibraRecordlessWorkoutIntervalType[];

        return (
          <span>
            {intervals.reduce(
              (totalTime, interval) => totalTime + interval.time,
              0,
            )}
          </span>
        );
      },
    },
    {
      field: 'created',
      headerName: 'Created At',
      sortable: false,
      width: 275,
    },
    {
      field: 'deleted',
      headerName: 'Deleted At',
      sortable: false,
      width: 275,
    },
  ];

  return (
    <div style={{ display: 'flex', height: '80vh' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid
          rows={workouts}
          columns={columns}
          initialState={{
            columns: {
              columnVisibilityModel: {
                id: false,
              },
            },
          }}
        />
      </div>
    </div>
  );
}
