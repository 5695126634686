/** @jsxImportSource @emotion/react */

import {
  CacheContext,
  HookEffectContext,
  TypedFormData,
} from '@inkibra/api-base';
import { useContext, useState } from 'react';
import {
  InkibraRecordlessLibraryApiFetcherRegistry,
  ModifyAlbumArtwork,
} from '../api';
import { useLibraryAlbumArtworkLocator } from '../hooks';
import { InkibraRecordlessLibrarySongType } from '../type-song';

export type AlbumArtEditingProps = {
  albumSlug: InkibraRecordlessLibrarySongType.AlbumSlug;
};

export const AlbumArtEditing = (props: AlbumArtEditingProps) => {
  const cache = useContext(CacheContext)();
  const hookContext = new HookEffectContext();
  const [albumArtLocator, setupAlbumArtLocator] =
    hookContext.addEffectWithSetup(
      useLibraryAlbumArtworkLocator(props.albumSlug),
    );
  hookContext.runEffects(cache);

  const [modifiedAlbumArtFile, setModifiedAlbumArtFile] = useState<File>();

  return (
    <div>
      <img
        src={albumArtLocator?.url}
        css={{ maxWidth: '100px', maxHeight: '100px' }}
      />
      <input
        accept="image/jpeg"
        type="file"
        onChange={(event) => {
          if (!event.target.files) {
            setModifiedAlbumArtFile(undefined);
          } else {
            const file = event.target.files[0];
            setModifiedAlbumArtFile(file);
          }
        }}
      />
      <button
        disabled={!modifiedAlbumArtFile}
        onClick={async () => {
          if (modifiedAlbumArtFile) {
            const typedFormData = new TypedFormData(
              ModifyAlbumArtwork.Route.fileInputDescription,
            );
            typedFormData.set('image', modifiedAlbumArtFile);
            await InkibraRecordlessLibraryApiFetcherRegistry.get(
              'modifyAlbumArtwork',
            ).fn({
              body: undefined,
              files: typedFormData,
              pathParams: {
                albumSlug: props.albumSlug,
              },
              pathQuery: {},
            });
            if (setupAlbumArtLocator) {
              setupAlbumArtLocator(cache);
            }
          }
        }}
      >
        Save
      </button>
    </div>
  );
};
