import { StatusCode } from '@inkibra/api-base';
import { ErrorType } from '@inkibra/error-base';

export namespace InkibraRecordlessAuthErrorCodes {
  export type INKIBRA_RECORDLESS_AUTH_BAD_REQUEST = ErrorType<
    'INKIBRA_RECORDLESS_AUTH_BAD_REQUEST',
    'Invalid Request'
  >;
  export const INKIBRA_RECORDLESS_AUTH_BAD_REQUEST: INKIBRA_RECORDLESS_AUTH_BAD_REQUEST =
    new ErrorType(
      'INKIBRA_RECORDLESS_AUTH_BAD_REQUEST',
      StatusCode.BAD_REQUEST,
    );

  export type INKIBRA_RECORDLESS_AUTH_UNAUTHENTICATED_REQUEST = ErrorType<
    'INKIBRA_RECORDLESS_AUTH_UNAUTHENTICATED_REQUEST',
    'You must authenticate your request using the auth api first'
  >;
  export const INKIBRA_RECORDLESS_AUTH_UNAUTHENTICATED_REQUEST: INKIBRA_RECORDLESS_AUTH_UNAUTHENTICATED_REQUEST =
    new ErrorType(
      'INKIBRA_RECORDLESS_AUTH_UNAUTHENTICATED_REQUEST',
      StatusCode.NOT_AUTHENTICATED,
    );

  export type INKIBRA_RECORDLESS_AUTH_FORBIDDEN_REQUEST = ErrorType<
    'INKIBRA_RECORDLESS_AUTH_FORBIDDEN_REQUEST',
    'You do not have permissions for this resource or request'
  >;
  export const INKIBRA_RECORDLESS_AUTH_FORBIDDEN_REQUEST: INKIBRA_RECORDLESS_AUTH_FORBIDDEN_REQUEST =
    new ErrorType(
      'INKIBRA_RECORDLESS_AUTH_FORBIDDEN_REQUEST',
      StatusCode.FORBIDDEN,
    );

  export type INKIBRA_RECORDLESS_AUTH_ALREADY_LOGGED_IN = ErrorType<
    'INKIBRA_RECORDLESS_AUTH_ALREADY_LOGGED_IN',
    'This request is forbidden: this auth session is already logged in'
  >;
  export const INKIBRA_RECORDLESS_AUTH_ALREADY_LOGGED_IN: INKIBRA_RECORDLESS_AUTH_ALREADY_LOGGED_IN =
    new ErrorType(
      'INKIBRA_RECORDLESS_AUTH_ALREADY_LOGGED_IN',
      StatusCode.FORBIDDEN,
    );

  export type INKIBRA_RECORDLESS_AUTH_LOGIN_CREDENTIAL_ERROR = ErrorType<
    'INKIBRA_RECORDLESS_AUTH_LOGIN_CREDENTIAL_ERROR',
    'This request is forbidden: this auth session is not logged in'
  >;
  export const INKIBRA_RECORDLESS_AUTH_LOGIN_CREDENTIAL_ERROR: INKIBRA_RECORDLESS_AUTH_LOGIN_CREDENTIAL_ERROR =
    new ErrorType(
      'INKIBRA_RECORDLESS_AUTH_LOGIN_CREDENTIAL_ERROR',
      StatusCode.FORBIDDEN,
    );

  export type INKIBRA_RECORDLESS_AUTH_FUNCTION_NOT_IMPLEMENTED = ErrorType<
    'INKIBRA_RECORDLESS_AUTH_FUNCTION_NOT_IMPLEMENTED',
    'That function is not implemented'
  >;
  export const INKIBRA_RECORDLESS_AUTH_FUNCTION_NOT_IMPLEMENTED = new ErrorType(
    'INKIBRA_RECORDLESS_AUTH_FUNCTION_NOT_IMPLEMENTED',
    StatusCode.NOT_IMPLEMENTED,
  );
}
