import * as Immutable from 'immutable';
import InternalStorage from 'internal-storage';
import { BehaviorSubject, Observable, forkJoin, merge } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import * as uuid from 'uuid';
// import {TypedPathWrapper} from 'typed-path';
// import get from 'lodash.get';
export { Subscription } from 'rxjs';

export * from './lib/cacheable-object';
export * from './lib/cacheable-object-util';
export * from './lib/observable-cache';
export * from './lib/type-data-manager';
export * from './lib/typed-collection';
export * from './lib/filter';

/**
 * @deprecated
 */
export interface IObjectCache<T extends ICachedObject> {
  [key: string]: T;
}

/**
 * @deprecated
 */
export type ICachedObject = {
  id: string;
  type: string;
};

/**
 * @deprecated
 */
export type Reference<T extends ICachedObject> = {
  id: string;
  referencedType: T['type'];
  type: `REF(${T['type']})`;
};

// TODO: rename ObjectDataUtil
/**
 * @deprecated
 */
export class ObjectLifeCycleManager {
  /**
   * @deprecated
   */
  public static makeId() {
    return uuid.v4();
  }
}

/**
 * @deprecated
 */
export interface IRemovedCachedObject<T extends ICachedObject>
  extends ICachedObject {
  id: string;
  type: 'REMOVED';
  removed: T;
}
interface IStorage {
  input: BehaviorSubject<ICachedObject | ICachedObject[]>;
  observable: Observable<Immutable.Map<string, ICachedObject>>;
}

const internal = InternalStorage<ObservableCache, IStorage>();

function accumulator(
  current: Immutable.Map<string, ICachedObject>,
  next: ICachedObject | ICachedObject[],
) {
  if (Array.isArray(next)) {
    let merged = current;
    const collectedObj = next.reduce(
      (prev, curr) => {
        prev[curr.id] = curr;
        merged = merged.remove(curr.id);
        return prev;
      },
      {} as IObjectCache<ICachedObject>,
    );
    const toMerge = Immutable.fromJS(collectedObj);
    // biome-ignore lint/suspicious/noExplicitAny: this implementation is deprecated
    merged = merged.mergeDeep(toMerge as any);
    return merged;
  }
  const obj = {
    [next.id]: next,
  };
  const toMerge = Immutable.fromJS(obj);
  // biome-ignore lint/suspicious/noExplicitAny: this implementation is deprecated
  const merged = current.remove(next.id).mergeDeep(toMerge as any);
  return merged;
}

/**
 * @deprecated
 */
export class ObservableCache<T extends ICachedObject = ICachedObject> {
  public static remove<T extends ICachedObject>(
    valToRemove: T,
  ): IRemovedCachedObject<T> {
    return {
      id: valToRemove.id,
      type: 'REMOVED',
      removed: valToRemove,
    };
  }
  #observablesToZip: Observable<ICachedObject | ICachedObject[]>[] = [];
  #cacheSubject: BehaviorSubject<Immutable.Map<string, ICachedObject>> =
    new BehaviorSubject(Immutable.Map<string, ICachedObject>());
  constructor(debounceTimeInMS = 0) {
    this.clear(debounceTimeInMS);
  }
  // TODO: allow clear to work (https://blog.angular-university.io/rxjs-error-handling/ The Catch and Replace Strategy)
  private clear(_: number) {
    internal(this).input = new BehaviorSubject<ICachedObject | ICachedObject[]>(
      { id: 'ObservableCache', type: 'ObservableCache' },
    );

    internal(this).observable = this.#cacheSubject.asObservable();

    let state = Immutable.Map<string, ICachedObject>();
    internal(this).input.subscribe((newData) => {
      state = accumulator(state, newData);
      this.#cacheSubject.next(state);
    });
    // NOTE: This primes the cache so future take(1)s work
  }
  /** Adds observables to the cache */
  public add(observables: Observable<ICachedObject | ICachedObject[]>[]) {
    this.#observablesToZip = this.#observablesToZip.concat(observables);
  }
  public fire() {
    forkJoin(this.#observablesToZip).subscribe(
      (data) => {
        data.forEach((entry) => this.input.next(entry));
      },
      undefined,
      () => {
        this.input.complete();
      },
    );
  }
  public connect() {
    merge(this.#observablesToZip).subscribe((data) => {
      void data.forEach((entry) => this.input.next(entry));
    });
  }
  public get input(): BehaviorSubject<ICachedObject | ICachedObject[]> {
    return internal(this).input;
  }
  public get rawObservable() {
    return internal(this).observable.pipe(shareReplay(1));
  }
  get objectObservable(): Observable<IObjectCache<T>> {
    return this.rawObservable.pipe(map((obj) => obj.toJS() as IObjectCache<T>));
  }
  get latest() {
    return this.#cacheSubject.value.toJS() as IObjectCache<T>;
  }
  get latestAsObject(): Promise<IObjectCache<T>> {
    const objectCache = this.objectObservable.pipe(take(1)).toPromise();
    return objectCache;
  }
  get arrayObservable(): Observable<T[]> {
    return this.objectObservable.pipe(
      map((itemObj) => {
        return Object.values(itemObj);
      }),
    );
  }
  get latestAsArray(): Promise<T[]> {
    const objectCache = this.arrayObservable.pipe(take(1)).toPromise();
    return objectCache;
  }
}

const cache = new ObservableCache();
export default cache;
