import { StatusCode } from '@inkibra/api-base';
import { ErrorType } from '@inkibra/error-base';

export namespace InkibraRecordlessUserErrorCodes {
  export type INKIBRA_RECORDLESS_USER_CREATE_WITH_ILLEGAL_OWNER = ErrorType<
    'INKIBRA_RECORDLESS_USER_CREATE_WITH_ILLEGAL_OWNER',
    'Cannot create a user for another owner'
  >;
  export const INKIBRA_RECORDLESS_USER_CREATE_WITH_ILLEGAL_OWNER: INKIBRA_RECORDLESS_USER_CREATE_WITH_ILLEGAL_OWNER =
    new ErrorType(
      'INKIBRA_RECORDLESS_USER_CREATE_WITH_ILLEGAL_OWNER',
      StatusCode.FORBIDDEN,
    );

  export type INKIBRA_RECORDLESS_USER_ALREADY_EXISTS = ErrorType<
    'INKIBRA_RECORDLESS_USER_ALREADY_EXISTS',
    'A user already exists for that user'
  >;
  export const INKIBRA_RECORDLESS_USER_ALREADY_EXISTS: INKIBRA_RECORDLESS_USER_ALREADY_EXISTS =
    new ErrorType(
      'INKIBRA_RECORDLESS_USER_ALREADY_EXISTS',
      StatusCode.CONFLICT,
    );

  export type INKIBRA_RECORDLESS_USER_NOT_FOUND = ErrorType<
    'INKIBRA_RECORDLESS_USER_NOT_FOUND',
    'A user was not found'
  >;
  export const INKIBRA_RECORDLESS_USER_NOT_FOUND: INKIBRA_RECORDLESS_USER_NOT_FOUND =
    new ErrorType('INKIBRA_RECORDLESS_USER_NOT_FOUND', StatusCode.NOT_FOUND);
}
