import { HookFunctionReturnType } from '@inkibra/api-base';
import { useState } from 'react';
import {
  GetInkibraRecordlessUser,
  InkibraRecordlessUserApiFetcherRegistry,
} from './api';
import { InkibraRecordlessUserType } from './type';

export function useUser(
  id: GetInkibraRecordlessUser.PathParams['id'],
): HookFunctionReturnType<InkibraRecordlessUserType | undefined> {
  const [data, setData] = useState<InkibraRecordlessUserType | undefined>();
  return {
    data,
    hookRunner: (data) => {
      const maybeUser = data[id];
      if (InkibraRecordlessUserType.is(maybeUser)) {
        setData(maybeUser);
      }
    },
    setup: async (cache) => {
      const data = await InkibraRecordlessUserApiFetcherRegistry.get(
        'getInkibraRecordlessUser',
      ).fn({
        body: undefined,
        pathParams: { id },
        pathQuery: {},
        files: undefined,
      });

      setData(data.value);
      cache.input.next(data.value);
    },
  };
}
