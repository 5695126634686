/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import {
  InkibraRecordlessLibraryMixType,
  InkibraRecordlessLibrarySongType,
  LoadedAsyncPlayable,
  PlaybackState,
} from '@inkibra/recordless.library-api';
import { useContext, useEffect, useState } from 'react';
import { PlayableIntervalListItem } from './playable-interval-list-item';
import { WorkoutSessionContext } from './workout-session-context';

const borderStyles = '1px solid #4017E1';

const styles = css({
  width: '100%',
  overflow: 'auto',
  borderBottom: borderStyles,
});

export type PlayableIntervalSingularProps = {
  name: string;
  playableEntryId: LoadedAsyncPlayable['entryId'];
  librarySong?: InkibraRecordlessLibrarySongType;
};

export function PlayableIntervalSingular(props: PlayableIntervalSingularProps) {
  const workoutSession = useContext(WorkoutSessionContext)();
  const [playbackTime, setPlaybackTime] = useState(0);

  useEffect(() => {
    function handlePlaybackStateChange(playbackState: PlaybackState<unknown>) {
      if (props.playableEntryId === playbackState.nowPlaying?.entryId) {
        setPlaybackTime(playbackState.time);
      }
    }

    workoutSession.playback.current?.subscribeToPlaybackState(
      handlePlaybackStateChange,
    );

    return () => {
      workoutSession.playback.current?.unsubscribeFromPlaybackState(
        handlePlaybackStateChange,
      );
    };
  }, [workoutSession.playback.current]);

  if (props.librarySong === undefined) {
    return (
      <div css={styles}>
        <PlayableIntervalListItem
          intervalName={props.name}
          elapsedPlaybackTime={playbackTime}
          relativePlaybackPosition={
            InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
              .FUTURE
          }
        />
      </div>
    );
  }
  return (
    <div css={styles}>
      <PlayableIntervalListItem
        intervalName={props.name}
        duration={props.librarySong.duration}
        librarySong={props.librarySong}
        elapsedPlaybackTime={playbackTime}
        relativePlaybackPosition={
          playbackTime === 0
            ? InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
                .FUTURE
            : InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
                .CURRENT
        }
        isContinuation={false}
      />
    </div>
  );
}
