/** @jsxImportSource @emotion/react */

import { ArrangementOutputInfo } from '@inkibra/recordless.music-engine';
import { ok } from 'neverthrow';
import { useEffect, useRef, useState } from 'react';

import { InkibraRecordlessLibraryApiFetcherRegistry } from '../api';
import { InkibraRecordlessLibraryArrangementType } from '../type-arrangement';
import { InkibraRecordlessLibrarySongType } from '../type-song';
import { RecordlessPlaybackDisplay } from './playback-display';
import { RecordlessTransitionEditor } from './transition-editor';

/*
  TODO: have this take an arrangement that way it can work directly
  with the logic on the arrangement for the transition selections.
  TODO: keep propose transition here.
  TODO: add transition overview here so that we can select the transitions
  from existing ones as well
*/
/**
 * Represents the props for the RecordlessTransitionReview component.
 */
export type RecordlessTransitionDraftingProps = {
  song: InkibraRecordlessLibrarySongType;
  songWaveform: number[];
  sourceSectionId: InkibraRecordlessLibrarySongType.Section['id'];
  targetSectionId: InkibraRecordlessLibrarySongType.Section['id'];
  proposeTransition: (
    proposedTransition: InkibraRecordlessLibrarySongType.Transition,
  ) => void;
  cancel: () => void;
  pixelsPerSecond: number;
};

// TODO: What happens if we don't like a transition?

/**
 * A component that allows for the labeling of music transitions.
 */
export const RecordlessTransitionDrafting = (
  props: RecordlessTransitionDraftingProps,
) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [modifiedTransitionPreviewed, setModifiedTransitionPreviewed] =
    useState(false);
  const [waveform, setWaveform] = useState<number[]>([]);
  const [maybeArrangementOutputInfo, setMaybeArrangementOutputInfo] = useState<
    ArrangementOutputInfo | undefined
  >(undefined);
  const [draftTransitionProposal, setDraftTransitionProposal] =
    useState<InkibraRecordlessLibrarySongType.Transition>();

  const arrangementUtil =
    new InkibraRecordlessLibraryArrangementType.RecordlessArrangementUtil({});

  const arrangementResult = arrangementUtil
    .create({
      name: 'transition-review',
      librarySong: props.song,
    })
    .andThen((arrangement) => {
      return arrangementUtil.addSectionArrangement(
        arrangement,
        props.sourceSectionId,
      );
    })
    .andThen((arrangement) => {
      return arrangementUtil.addSectionArrangement(
        arrangement,
        props.targetSectionId,
      );
    })
    .andThen((arrangement) => {
      if (draftTransitionProposal) {
        return arrangementUtil.proposeTransition(
          arrangement,
          draftTransitionProposal,
        );
      }
      return ok(arrangement);
    });

  if (arrangementResult.isErr()) {
    console.error('failed to create arrangement', arrangementResult.error);
    throw new Error('failed to create arrangement');
  }
  const songUtil = new InkibraRecordlessLibrarySongType.RecordlessSongUtil({});

  useEffect(() => {
    const draftTransitionResult = songUtil.draftTransition(
      props.song,
      props.sourceSectionId,
      props.targetSectionId,
    );
    if (draftTransitionResult.isErr()) {
      console.error('failed to draft transition', draftTransitionResult.error);
      throw new Error('failed to draft transition');
    }
    setDraftTransitionProposal(draftTransitionResult.value);
    setModifiedTransitionPreviewed(false);
  }, [props.song, props.sourceSectionId, props.targetSectionId]);

  if (!draftTransitionProposal) {
    return null;
  }

  return (
    <>
      <audio ref={audioRef} controls />
      <div css={{ width: '100vw' }} ref={containerRef}>
        <RecordlessPlaybackDisplay
          windowedWaveformData={waveform}
          duration={maybeArrangementOutputInfo?.duration || 0}
          relativeSections={
            maybeArrangementOutputInfo?.annotations.map((annotation) => {
              return {
                name: annotation.metadata,
                startTime: annotation.startsAt,
              };
            }) || []
          }
          audioPlayerRef={audioRef}
          relativeZero={0}
          containerRef={containerRef}
        />
        <button
          onClick={async () => {
            if (audioRef.current) {
              // TODO: we should display the transition overview above here to select from existing transition
              // TODO: The arrangement-creation-flow should have something that highlights the two section we are transitioning between
              const createSourceResult =
                await InkibraRecordlessLibraryApiFetcherRegistry.get(
                  'previewInkibraRecordlessArrangementSources',
                ).fn({
                  body: arrangementResult.value,
                  files: undefined,
                  pathParams: {},
                  pathQuery: {
                    encode: false,
                    waveformResolutionOverride: '16',
                  },
                });

              const sources = createSourceResult.value;

              if (!sources) {
                console.warn('no sources');
                return;
              }
              console.log('got sources', sources);
              audioRef.current.src = sources.locator.url;
              setWaveform(sources.waveform);
              // This only works because we will be labeling transition between non-consecutive sections
              // Otherwise we wouldn't be here.
              setMaybeArrangementOutputInfo(sources.arrangementOutputInfo);

              const sourceSectionDurationResult =
                songUtil.computeSectionDuration(
                  props.song,
                  props.sourceSectionId,
                );
              if (sourceSectionDurationResult.isErr()) {
                console.error(
                  'failed to get source section duration',
                  sourceSectionDurationResult.error,
                );
                throw new Error('failed to get source section duration');
              }
              audioRef.current.currentTime =
                sourceSectionDurationResult.value - 10 > 0
                  ? sourceSectionDurationResult.value - 10
                  : 0;
              setModifiedTransitionPreviewed(true);
              await audioRef.current.play();
            }
          }}
        >
          Preview Transition
        </button>
        <RecordlessTransitionEditor
          onTransitionUpdate={(updatedTransition) => {
            setDraftTransitionProposal(updatedTransition);
            setModifiedTransitionPreviewed(false);
          }}
          transition={draftTransitionProposal}
          pixelsPerSecond={props.pixelsPerSecond}
          song={props.song}
          songWaveform={props.songWaveform}
        />
        <button
          disabled={!modifiedTransitionPreviewed}
          onClick={() => {
            props.proposeTransition(draftTransitionProposal);
          }}
        >
          Save Modifications
        </button>
        <button
          onClick={() => {
            props.cancel();
          }}
        >
          Cancel Modifications
        </button>
      </div>
    </>
  );
  //
};
