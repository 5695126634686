import { SchemaHelpersFor } from '@inkibra/api-base';
import typia from 'typia';
import * as uuid from 'uuid';
import { InkibraRecordlessWaitlistEntryType } from './entry-type';

export type InkibraRecordlessWaitlistType = {
  id: string & typia.tags.Format<'uuid'>;
  name: string;
  open: boolean;
  collectName: boolean;
  collectWallet: boolean;
  confirmViaEmail: boolean;
  hasBackgroundVideo: boolean;
  hasBackgroundImage: boolean;
  confirmationRedirect: string;
  // TODO: add theme (setup a few different themes on frontend)
  waitlistEvent: 'TONE_TEMPO_WAITLIST_JOIN' | 'RECORDLESS_WAITLIST_JOIN';
  type: typeof InkibraRecordlessWaitlistType.typename;
  modified: string & typia.tags.Format<'date-time'>;
  created: string & typia.tags.Format<'date-time'>;
  deleted?: string & typia.tags.Format<'date-time'>;
};

export namespace InkibraRecordlessWaitlistType {
  export import Entry = InkibraRecordlessWaitlistEntryType;

  export const schema: SchemaHelpersFor<InkibraRecordlessWaitlistType> = {
    is: typia.createIs<InkibraRecordlessWaitlistType>(),
    assert: typia.createAssert<InkibraRecordlessWaitlistType>(),
    validate: typia.createValidate<InkibraRecordlessWaitlistType>(),
    stringify: typia.json.createStringify<InkibraRecordlessWaitlistType>(),
    clone: typia.misc.createClone<InkibraRecordlessWaitlistType>(),
  };

  export const typename = 'INKIBRA_RECORDLESS_WAITLIST';
  export function is(val: unknown): val is InkibraRecordlessWaitlistType {
    return (val as InkibraRecordlessWaitlistType).type === typename;
  }
  export function fromCreation(val: Creation): InkibraRecordlessWaitlistType {
    return {
      ...val,
      open: true,
      id: uuid.v4(),
      type: InkibraRecordlessWaitlistType.typename,
      modified: new Date().toISOString(),
      created: new Date().toISOString(),
    };
  }

  export type Creation = Pick<
    InkibraRecordlessWaitlistType,
    | 'name'
    | 'collectName'
    | 'collectWallet'
    | 'confirmViaEmail'
    | 'hasBackgroundImage'
    | 'hasBackgroundVideo'
    | 'confirmationRedirect'
    | 'waitlistEvent'
  >;

  export namespace Creation {
    export const schema: SchemaHelpersFor<Creation> = {
      is: typia.createIs<Creation>(),
      assert: typia.createAssert<Creation>(),
      validate: typia.createValidate<Creation>(),
      stringify: typia.json.createStringify<Creation>(),
      clone: typia.misc.createClone<Creation>(),
    };
  }

  export type Modification = Pick<InkibraRecordlessWaitlistType, 'open'>;

  export namespace Modification {
    export const schema: SchemaHelpersFor<Modification> = {
      is: typia.createIs<Modification>(),
      assert: typia.createAssert<Modification>(),
      validate: typia.createValidate<Modification>(),
      stringify: typia.json.createStringify<Modification>(),
      clone: typia.misc.createClone<Modification>(),
    };
  }
}
