/** @jsxImportSource @emotion/react */

export function Pause() {
  return (
    <svg
      width="17"
      height="19"
      viewBox="0 0 17 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.515137"
        y="0.0454712"
        width="6.57576"
        height="18.7879"
        fill="white"
      />
      <rect
        x="9.43945"
        y="0.0454712"
        width="6.57576"
        height="18.7879"
        fill="white"
      />
    </svg>
  );
}
