/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useState } from 'react';

import { Button, ButtonBackgroundColor } from '@inkibra/recordless.ux';
import {
  AuthPhase,
  AuthenticatedUserSessionFormProps,
} from './authenticated-session-guard';

const inputStyles = css({
  display: 'block',
  background: 'none',
  color: 'white',
  border: '1px solid rgba(217, 217, 217, 0.3)',
  borderRadius: '8px',
  boxSizing: 'border-box',
  width: '100%',
  height: '48px',
  fontFamily: "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '16px',
  textAlign: 'center',
  lineHeight: '130%',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: '10px',
  marginBottom: '20px',
});

export type BasicUserSessionAuthenticationFormProps = {
  description: string;
  tagline: string;
  logoElement: React.ReactNode;
};

export const BasicUserSessionAuthenticationForm = (
  props: BasicUserSessionAuthenticationFormProps &
    AuthenticatedUserSessionFormProps,
) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [fullname, setFullname] = useState<string>('');
  const [code, setCode] = useState<string>('');

  switch (props.authPhase) {
    case AuthPhase.START: {
      return (
        <div
          css={{
            height: '100dvh',
            display: 'grid',
            margin: 'auto',
            alignContent: 'center',
            width: '350px',
            color: 'white',
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
          }}
        >
          {props.logoElement}
          <h2
            css={{
              textAlign: 'center',
              fontSize: '24px',
              marginBottom: '40px',
            }}
          >
            {props.tagline}
          </h2>
          <p
            css={{
              fontSize: '13px',
              textAlign: 'center',
              marginBottom: '40px',
              opacity: 0.7,
            }}
          >
            {props.description}
          </p>
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
            contents="Sign Up"
            onClick={() => {
              props.setAuthPhase(AuthPhase.SIGN_UP);
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.APP_FROSTED_LIGHT}
            contents="Log In"
            onClick={() => {
              props.setAuthPhase(AuthPhase.LOGIN);
            }}
          />
        </div>
      );
    }
    case AuthPhase.SIGN_UP: {
      return (
        <div
          css={{
            height: '100dvh',
            display: 'grid',
            margin: 'auto',
            alignContent: 'center',
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            textAlign: 'center',
            width: '350px',
          }}
        >
          <h2
            css={{
              color: 'white',
              textAlign: 'center',
            }}
          >
            Sign Up
          </h2>
          <p
            css={{
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>
          <input
            css={inputStyles}
            autoComplete="email"
            placeholder="enter your email"
            value={email}
            required
            type="text"
            disabled={props.inProgress}
            onChange={(event) => {
              setEmail(event.target.value.toLowerCase());
            }}
          />
          <input
            css={inputStyles}
            autoComplete="name"
            placeholder="enter your full name"
            value={fullname}
            type="text"
            required
            disabled={props.inProgress}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                void props.onSignUpStart(email, fullname);
              }
            }}
            onChange={(event) => {
              setFullname(event.target.value);
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
            contents={'Sign Up'}
            disabled={props.inProgress}
            onClick={() => {
              void props.onSignUpStart(email, fullname);
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.APP_FROSTED_LIGHT}
            contents="Back"
            disabled={props.inProgress}
            onClick={() => {
              props.setAuthPhase(AuthPhase.START);
            }}
          />
        </div>
      );
    }
    case AuthPhase.LOGIN: {
      return (
        <div
          css={{
            height: '100dvh',
            display: 'grid',
            margin: 'auto',
            alignContent: 'center',
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            textAlign: 'center',
            width: '350px',
          }}
        >
          <h2
            css={{
              color: 'white',
              textAlign: 'center',
            }}
          >
            Log In
          </h2>
          <p
            css={{
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>
          <input
            css={inputStyles}
            autoComplete="email"
            placeholder="enter your email"
            value={email}
            required
            type="text"
            disabled={props.inProgress}
            onChange={(event) => {
              setEmail(event.target.value.toLowerCase());
            }}
          />
          <input
            css={inputStyles}
            autoComplete="password"
            placeholder="enter your password"
            value={password}
            type="password"
            required
            disabled={props.inProgress}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                void props.onLogin(email, password);
              }
            }}
            onChange={(event) => {
              setPassword(event.target.value.toLowerCase());
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
            contents={'Log In'}
            disabled={props.inProgress}
            onClick={() => {
              void props.onLogin(email, password);
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.APP_FROSTED_LIGHT}
            contents={'Forgot Password?'}
            disabled={props.inProgress}
            onClick={() => {
              void props.setAuthPhase(AuthPhase.GET_RECOVERY_CODE);
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.APP_FROSTED_LIGHT}
            contents="Back"
            disabled={props.inProgress}
            onClick={() => {
              props.setAuthPhase(AuthPhase.START);
            }}
          />
        </div>
      );
    }
    case AuthPhase.VERIFY_SESSION_RECOVERY_CODE:
    case AuthPhase.VERIFY_SESSION_AUTHENTICATION_CODE: {
      return (
        <div
          css={{
            height: '100dvh',
            display: 'grid',
            margin: 'auto',
            alignContent: 'center',
          }}
        >
          <h1>Verify Code</h1>
          <p
            css={{
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>
          <input
            type="text"
            css={inputStyles}
            value={code}
            disabled={props.inProgress}
            required
            autoComplete="one-time-code"
            placeholder="one time code"
            onChange={(event) => {
              setCode(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                if (
                  props.authPhase === AuthPhase.VERIFY_SESSION_RECOVERY_CODE
                ) {
                  void props.onVerifySessionRecoveryCode(code);
                } else if (
                  props.authPhase ===
                  AuthPhase.VERIFY_SESSION_AUTHENTICATION_CODE
                ) {
                  void props.onVerifySessionAuthenticationCode(code);
                }
              }
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
            contents="Verify"
            disabled={props.inProgress}
            onClick={() => {
              if (props.authPhase === AuthPhase.VERIFY_SESSION_RECOVERY_CODE) {
                void props.onVerifySessionRecoveryCode(code);
              } else if (
                props.authPhase === AuthPhase.VERIFY_SESSION_AUTHENTICATION_CODE
              ) {
                void props.onVerifySessionAuthenticationCode(code);
              }
            }}
          />
        </div>
      );
    }
    case AuthPhase.SET_PASSWORD: {
      return (
        <div
          css={{
            height: '100dvh',
            display: 'grid',
            margin: 'auto',
            alignContent: 'center',
          }}
        >
          <h1>Set Password</h1>
          <p
            css={{
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>
          <input
            type="password"
            value={password}
            css={inputStyles}
            disabled={props.inProgress}
            required
            autoComplete="new-password"
            placeholder="new password"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                void props.onSetPassword(password);
              }
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
            contents="Set Password"
            disabled={props.inProgress}
            onClick={() => {
              void props.onSetPassword(password);
            }}
          />
        </div>
      );
    }
    case AuthPhase.GET_RECOVERY_CODE: {
      return (
        <div
          css={{
            height: '100dvh',
            display: 'grid',
            margin: 'auto',
            alignContent: 'center',
            fontFamily:
              "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
            textAlign: 'center',
            width: '350px',
          }}
        >
          <h2
            css={{
              color: 'white',
              textAlign: 'center',
            }}
          >
            Request a code
          </h2>
          <p
            css={{
              color: 'red',
              textAlign: 'center',
              opacity: 0.7,
              whiteSpace: 'pre-line',
            }}
          >
            {props.formError}
          </p>
          <input
            css={inputStyles}
            autoComplete="email"
            placeholder="enter your email"
            value={email}
            required
            type="text"
            disabled={props.inProgress}
            onChange={(event) => {
              setEmail(event.target.value.toLowerCase());
            }}
            onKeyDown={(event) => {
              if (event.key === 'Enter') {
                void props.onRecoverAccount(email);
              }
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
            contents={'Get Reset Code'}
            disabled={props.inProgress}
            onClick={() => {
              void props.onRecoverAccount(email);
            }}
          />
          <Button
            buttonBackgroundColor={ButtonBackgroundColor.APP_FROSTED_LIGHT}
            contents="Back"
            disabled={props.inProgress}
            onClick={() => {
              props.setAuthPhase(AuthPhase.START);
            }}
          />
        </div>
      );
    }
    default:
      return null;
  }
};
