/** @jsxImportSource @emotion/react */

import {
  AudioDataState,
  InkibraRecordlessLibraryMixType,
  LoadedAsyncPlayable,
  LoadingState,
  PlaybackState,
} from '@inkibra/recordless.library-api';
import { useContext, useEffect, useState } from 'react';
import { InkibraRecordlessWorkoutType } from '../type';
import { Button } from './button';
import { PlayableIntervalListItem } from './playable-interval-list-item';
import { WorkoutSessionContext } from './workout-session-context';

export type PlayableIntervalListProps = {
  workout: InkibraRecordlessWorkoutType;
  playableEntryId: LoadedAsyncPlayable['entryId'];
  mix?: InkibraRecordlessLibraryMixType;
};

export function PlayableIntervalList(props: PlayableIntervalListProps) {
  const workoutSession = useContext(WorkoutSessionContext)();
  const [playbackTime, setPlaybackTime] = useState(0);
  const [audioDataState, setAudioDataState] =
    useState<AudioDataState<unknown>>();

  useEffect(() => {
    function handlePlaybackStateChange(playbackState: PlaybackState<unknown>) {
      if (props.playableEntryId === playbackState.nowPlaying?.entryId) {
        setPlaybackTime(playbackState.time);
      }
    }

    workoutSession.playback.current?.subscribeToPlaybackState(
      handlePlaybackStateChange,
    );

    return () => {
      workoutSession.playback.current?.unsubscribeFromPlaybackState(
        handlePlaybackStateChange,
      );
    };
  }, [workoutSession.playback.current]);

  useEffect(() => {
    const audioDataStateSubscriber =
      workoutSession.playback.current?.subscribeToAudioDataState(
        (audioData) => {
          const audioDataForPlayable = Object.values(audioData).find(
            (audioDataState) => {
              return audioDataState.playable.entryId === props.playableEntryId;
            },
          );
          if (audioDataForPlayable) {
            setAudioDataState(audioDataForPlayable);
          }
        },
      );
    return () => {
      workoutSession.playback.current?.unsubscribeFromAudioDataState(
        audioDataStateSubscriber,
      );
    };
  }, [workoutSession.playback.current]);

  const mixUtil = new InkibraRecordlessLibraryMixType.MixUtil({});

  return (
    <div
      css={{
        width: '100%',
        borderBottom: '1px solid #4017E1',
        position: 'relative',
      }}
    >
      <div
        css={{
          label: 'start-workout-button',
          display: 'grid',
          gridTemplateColumns: '0.8fr',
          justifyContent: 'center',
          position: 'absolute',
          top: '-18px',
          width: '100%',
        }}
      >
        {audioDataState?.loadingState !== LoadingState.LOADED ||
        playbackTime !== 0 ? (
          <Button
            type="button"
            color="alternative"
            buttonSize="small"
            fillMode="solid"
            contents={props.workout.name}
          />
        ) : (
          <Button
            type="button"
            icon="start"
            color="primary"
            buttonSize="small"
            contents={`START ${props.workout.name}`}
            onClick={() => {
              workoutSession.playback.current?.skipTo(props.playableEntryId);
            }}
          />
        )}
      </div>
      {InkibraRecordlessWorkoutType.getIntervalsWithStartAndEndTimes(
        props.workout,
      ).map((interval, index) => {
        const mix = props.mix;

        if (mix === undefined) {
          const playbackPosition =
            playbackTime <= interval.startTime
              ? InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
                  .FUTURE
              : playbackTime > interval.endTime
                ? InkibraRecordlessLibraryMixType.MixElement
                    .RelativePlaybackTime.PAST
                : InkibraRecordlessLibraryMixType.MixElement
                    .RelativePlaybackTime.CURRENT;
          const elapsedPlaybackTime =
            playbackPosition ===
            InkibraRecordlessLibraryMixType.MixElement.RelativePlaybackTime
              .CURRENT
              ? Math.max(playbackTime - interval.startTime, 0)
              : 0;
          return (
            <PlayableIntervalListItem
              key={index}
              intervalName={interval.name}
              duration={interval.time}
              relativePlaybackPosition={playbackPosition}
              elapsedPlaybackTime={elapsedPlaybackTime}
            />
          );
        }

        const mixNodes = mixUtil.getOrderedMixNodesWithAnnotation(
          mix,
          `interval-${index}`,
        );

        return mixNodes.map((mixNode, mixNodeIndex) => {
          const relativePlaybackPositionResult =
            mixUtil.getNodeRelativePlaybackPosition(
              mix,
              mixNode.id,
              playbackTime,
            );
          if (relativePlaybackPositionResult.isErr()) {
            return null;
          }
          const relativePlaybackPosition = relativePlaybackPositionResult.value;
          const timeElapsedResult =
            mixUtil.convertPlaybackTimeToNodeBodyRelativeTimeElapsed(
              mix,
              mixNode.id,
              playbackTime,
            );
          if (timeElapsedResult.isErr()) {
            return null;
          }
          const timeElapsed = timeElapsedResult.value;
          const librarySong = mixNode.currentLibraryArrangement.librarySong;

          const bodyDurationResult = mixUtil.calculateBodyDuration(
            mix,
            mixNode.id,
          );
          if (bodyDurationResult.isErr()) {
            return null;
          }
          const bodyDuration = bodyDurationResult.value;

          return (
            <PlayableIntervalListItem
              key={index}
              intervalName={interval.name}
              duration={bodyDuration}
              isContinuation={mixNodeIndex !== 0}
              relativePlaybackPosition={relativePlaybackPosition}
              librarySong={librarySong}
              elapsedPlaybackTime={timeElapsed}
            />
          );
        });
      })}
    </div>
  );
}
