/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import { Button, ButtonBackgroundColor } from './button';

import { responsiveStyle } from '../constants/media-query';

const headerStyles = css({
  height: '65px',
  position: 'sticky',
  top: '0px',
  backgroundColor: 'rgba(19, 20, 21, 0.7)',
  zIndex: 1000,
  backdropFilter: 'blur(5px)',
  boxSizing: 'border-box',
  marginBottom: '16px',
});

const headerContentStyles = css(
  {
    display: 'flex',
    width: '100%',
    maxWidth: '1400px',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '65px',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxSizing: 'border-box',
  },
  responsiveStyle('padding', {
    xs: '0 12px',
    sm: '0 12px',
    md: '0 16px',
    lg: '0 16px',
    xl: '0 16px',
  }),
);

const headerSectionStyles = css({});

const headerImageStyles = css({
  position: 'relative',
  top: '25px',
  left: '-45px',
});

const headerMenuStyles = css(
  {
    display: 'inline-flex',
    listStyleType: 'none',
    padding: '0px',
    color: 'white',
    fontFamily: "'Nunito Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  responsiveStyle('fontSize', {
    xs: '12px',
    sm: '12px',
    lg: '16px',
    xl: '16px',
  }),
);

const headerMenuItemStyles = css(
  {
    outline: 'none',
    cursor: 'pointer',
    textAlign: 'center',
  },
  responsiveStyle('margin', {
    xs: '0 12px',
    sm: '0 12px',
    lg: '0 16px',
    xl: '0 16px',
  }),
);

export type HeaderProps = {
  onPrimaryAction: () => void;
  primaryAction: 'available' | 'waitlist';
  logoPath: string;
  menuItems: string[];
  homeLink: string;
};

export function Header(props: HeaderProps) {
  return (
    <header css={headerStyles}>
      <div css={headerContentStyles}>
        <div css={headerSectionStyles}>
          <a href={props.homeLink}>
            <img css={headerImageStyles} src={props.logoPath} alt="logo" />
          </a>
        </div>

        <div css={headerSectionStyles}>
          <ul css={headerMenuStyles}>
            {props.menuItems.map((item) => (
              <li css={headerMenuItemStyles}>{item}</li>
            ))}
          </ul>
        </div>

        <div css={headerSectionStyles}>
          <Button
            contents={
              props.primaryAction === 'available'
                ? 'Download Studio'
                : 'Join Waitlist'
            }
            buttonBackgroundColor={ButtonBackgroundColor.PURPLE}
            onClick={props.onPrimaryAction}
          />
        </div>
      </div>
    </header>
  );
}
