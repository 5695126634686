import {
  ApiRoute,
  EmptyObject,
  GenericRegistry,
  HttpMethod,
  NoData,
  SchemaHelpersFor,
  SerializableResult,
  SimpleQuery,
  StatusCode,
} from '@inkibra/api-base';
import { Filter } from '@inkibra/observable-cache';
import typia from 'typia';
import { InkibraRecordlessWaitlistEntryType } from './entry-type';
import { InkibraRecordlessWaitlistType } from './type';

export type InkibraRecordlessWaitlistTypeResponse =
  SerializableResult.OkWithStatusCode<
    InkibraRecordlessWaitlistType,
    StatusCode.OK
  >;
export namespace InkibraRecordlessWaitlistTypeResponse {
  export const schema: SchemaHelpersFor<InkibraRecordlessWaitlistTypeResponse> =
    {
      is: typia.createIs<InkibraRecordlessWaitlistTypeResponse>(),
      assert: typia.createAssert<InkibraRecordlessWaitlistTypeResponse>(),
      validate: typia.createValidate<InkibraRecordlessWaitlistTypeResponse>(),
      stringify:
        typia.json.createStringify<InkibraRecordlessWaitlistTypeResponse>(),
      clone: typia.misc.createClone<InkibraRecordlessWaitlistTypeResponse>(),
    };
}

export type InkibraRecordlessWaitlistsTypeResponse =
  SerializableResult.OkWithStatusCode<
    InkibraRecordlessWaitlistType[],
    StatusCode.OK
  >;
export namespace InkibraRecordlessWaitlistsTypeResponse {
  export const schema: SchemaHelpersFor<InkibraRecordlessWaitlistsTypeResponse> =
    {
      is: typia.createIs<InkibraRecordlessWaitlistsTypeResponse>(),
      assert: typia.createAssert<InkibraRecordlessWaitlistsTypeResponse>(),
      validate: typia.createValidate<InkibraRecordlessWaitlistsTypeResponse>(),
      stringify:
        typia.json.createStringify<InkibraRecordlessWaitlistsTypeResponse>(),
      clone: typia.misc.createClone<InkibraRecordlessWaitlistsTypeResponse>(),
    };
}
export namespace GetInkibraRecordlessWaitlist {
  export type PathParams = {
    waitlistId: InkibraRecordlessWaitlistType['id'];
  };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = SimpleQuery;

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessWaitlistType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/:waitlistId',
    {
      Name: 'getInkibraRecordlessWaitlist';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getInkibraRecordlessWaitlist',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.waitlists/:waitlistId',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetAllInkibraRecordlessWaitlists {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = SimpleQuery;

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = InkibraRecordlessWaitlistsTypeResponse;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistsTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists',
    {
      Name: 'getAllInkibraRecordlessWaitlists';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getAllInkibraRecordlessWaitlists',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.waitlists',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateInkibraRecordlessWaitlist {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessWaitlistType.Creation;
  export namespace Body {
    export const schema = InkibraRecordlessWaitlistType.Creation.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessWaitlistType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/',
    {
      Name: 'createInkibraRecordlessWaitlist';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createInkibraRecordlessWaitlist',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.waitlists/',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace ChangeOpenStateInkibraRecordlessWaitlist {
  export type PathParams = {
    waitlistId: InkibraRecordlessWaitlistType['id'];
  };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery2 = {};

  type PathQuery = {
    nameOperator?:
      | Filter.Operators.EQUAL
      | Filter.Operators.NOT_EQUAL
      | Filter.Operators.IN
      | Filter.Operators.NOT_IN;
    nameValue?: string | undefined;
    nameValues?: string[] | undefined;
  };

  export type Body = InkibraRecordlessWaitlistType.Modification;
  export namespace Body {
    export const schema = InkibraRecordlessWaitlistType.Modification.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessWaitlistType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/:waitlistId',
    {
      Name: 'changeOpenStateInkibraRecordlessWaitlist';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'changeOpenStateInkibraRecordlessWaitlist',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.waitlists/:waitlistId',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export type InkibraRecordlessWaitlistEntryTypeResponse =
  SerializableResult.OkWithStatusCode<
    InkibraRecordlessWaitlistEntryType,
    StatusCode.OK
  >;
export namespace InkibraRecordlessWaitlistEntryTypeResponse {
  export const schema: SchemaHelpersFor<InkibraRecordlessWaitlistEntryTypeResponse> =
    {
      is: typia.createIs<InkibraRecordlessWaitlistEntryTypeResponse>(),
      assert: typia.createAssert<InkibraRecordlessWaitlistEntryTypeResponse>(),
      validate:
        typia.createValidate<InkibraRecordlessWaitlistEntryTypeResponse>(),
      stringify:
        typia.json.createStringify<InkibraRecordlessWaitlistEntryTypeResponse>(),
      clone:
        typia.misc.createClone<InkibraRecordlessWaitlistEntryTypeResponse>(),
    };
}

export type InkibraRecordlessWaitlistEntriesTypeResponse =
  SerializableResult.OkWithStatusCode<
    InkibraRecordlessWaitlistEntryType[],
    StatusCode.OK
  >;
export namespace InkibraRecordlessWaitlistEntriesTypeResponse {
  export const schema: SchemaHelpersFor<InkibraRecordlessWaitlistEntriesTypeResponse> =
    {
      is: typia.createIs<InkibraRecordlessWaitlistEntriesTypeResponse>(),
      assert:
        typia.createAssert<InkibraRecordlessWaitlistEntriesTypeResponse>(),
      validate:
        typia.createValidate<InkibraRecordlessWaitlistEntriesTypeResponse>(),
      stringify:
        typia.json.createStringify<InkibraRecordlessWaitlistEntriesTypeResponse>(),
      clone:
        typia.misc.createClone<InkibraRecordlessWaitlistEntriesTypeResponse>(),
    };
}

export namespace GetAllInkibraRecordlessWaitlistEntries {
  export type PathParams = {
    waitlistId: InkibraRecordlessWaitlistType['id'];
  };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = InkibraRecordlessWaitlistEntriesTypeResponse;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistEntriesTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/:waitlistId/entries',
    {
      Name: 'getAllInkibraRecordlessWaitlistEntries';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getAllInkibraRecordlessWaitlistEntries',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.waitlists/:waitlistId/entries',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetInkibraRecordlessWaitlistEntry {
  export type PathParams = {
    waitlistId: InkibraRecordlessWaitlistType['id'];
    entryId: InkibraRecordlessWaitlistEntryType['id'];
  };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = InkibraRecordlessWaitlistEntryTypeResponse;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistEntryTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/:waitlistId/entries/:entryId',
    {
      Name: 'getInkibraRecordlessWaitlistEntry';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'getInkibraRecordlessWaitlistEntry',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.waitlists/:waitlistId/entries/:entryId',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateInkibraRecordlessWaitlistEntry {
  export type PathParams = {
    waitlistId: InkibraRecordlessWaitlistEntryType['id'];
  };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessWaitlistEntryType.Creation;
  export namespace Body {
    export const schema = InkibraRecordlessWaitlistEntryType.Creation.schema;
  }

  export type Response = InkibraRecordlessWaitlistEntryTypeResponse;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistEntryTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/:waitlistId/entry',
    {
      Name: 'createInkibraRecordlessWaitlistEntry';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'createInkibraRecordlessWaitlistEntry',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.waitlists/:waitlistId/entry',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace ConfirmInkibraRecordlessWaitlistEntry {
  export type PathParams = {
    waitlistId: InkibraRecordlessWaitlistType['id'];
    entryId: InkibraRecordlessWaitlistEntryType['id'];
  };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    {
      confirmationRedirect: string;
    },
    StatusCode.OK
  >;
  export namespace Response {
    export const schema: SchemaHelpersFor<Response> = {
      is: typia.createIs<Response>(),
      assert: typia.createAssert<Response>(),
      validate: typia.createValidate<Response>(),
      stringify: typia.json.createStringify<Response>(),
      clone: typia.misc.createClone<Response>(),
    };
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/:waitlistId/entries/:entryId/confirmed',
    {
      Name: 'confirmInkibraRecordlessWaitlistEntry';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'confirmInkibraRecordlessWaitlistEntry',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.waitlists/:waitlistId/entries/:entryId/confirmed',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace AttachWalletAddressToInkibraRecordlessWaitlistEntry {
  export type PathParams = {
    waitlistId: InkibraRecordlessWaitlistType['id'];
    entryId: InkibraRecordlessWaitlistEntryType['id'];
  };
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body =
    InkibraRecordlessWaitlistEntryType.WalletAddressModification;
  export namespace Body {
    export const schema =
      InkibraRecordlessWaitlistEntryType.WalletAddressModification.schema;
  }

  export type Response = InkibraRecordlessWaitlistEntryTypeResponse;
  export namespace Response {
    export const schema = InkibraRecordlessWaitlistEntryTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.waitlists/:waitlistId/entries/:entryId/wallet',
    {
      Name: 'attachWalletAddressToInkibraRecordlessWaitlistEntry';
      Method: HttpMethod.PUT;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    }
  >({
    name: 'attachWalletAddressToInkibraRecordlessWaitlistEntry',
    method: HttpMethod.PUT,
    path: '/api/inkibra/recordless.waitlists/:waitlistId/entries/:entryId/wallet',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export const InkibraRecordlessWaitlistApiRouteRegistry = GenericRegistry.init({
  getInkibraRecordlessWaitlist: GetInkibraRecordlessWaitlist.Route,
  getAllInkibraRecordlessWaitlists: GetAllInkibraRecordlessWaitlists.Route,
  createInkibraRecordlessWaitlist: CreateInkibraRecordlessWaitlist.Route,
  changeOpenStateInkibraRecordlessWaitlist:
    ChangeOpenStateInkibraRecordlessWaitlist.Route,
  getAllInkibraRecordlessWaitlistEntries:
    GetAllInkibraRecordlessWaitlistEntries.Route,
  getInkibraRecordlessWaitlistEntry: GetInkibraRecordlessWaitlistEntry.Route,
  createInkibraRecordlessWaitlistEntry:
    CreateInkibraRecordlessWaitlistEntry.Route,
  confirmInkibraRecordlessWaitlistEntry:
    ConfirmInkibraRecordlessWaitlistEntry.Route,
  attachWalletAddressToInkibraRecordlessWaitlistEntry:
    AttachWalletAddressToInkibraRecordlessWaitlistEntry.Route,
});

export const InkibraRecordlessWaitlistApiFetcherRegistry =
  InkibraRecordlessWaitlistApiRouteRegistry.matchWithAll((routes) => {
    return {
      ...routes.getInkibraRecordlessWaitlist.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.getAllInkibraRecordlessWaitlists.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.createInkibraRecordlessWaitlist.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.changeOpenStateInkibraRecordlessWaitlist.setupHandler<void>(
        () => {
          throw new Error('Not Implemented');
        },
      ),
      ...routes.getAllInkibraRecordlessWaitlistEntries.setupHandler<void>(
        () => {
          throw new Error('Not Implemented');
        },
      ),
      ...routes.getInkibraRecordlessWaitlistEntry.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.createInkibraRecordlessWaitlistEntry.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.confirmInkibraRecordlessWaitlistEntry.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.attachWalletAddressToInkibraRecordlessWaitlistEntry.setupHandler<void>(
        () => {
          throw new Error('Not Implemented');
        },
      ),
    };
  });
