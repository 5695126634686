/** @jsxImportSource react */

import { Route, Routes } from 'react-router-dom';

import WorkoutDisplay from './workouts/workout-display';
import WorkoutHeader from './workouts/workout-header';
import WorkoutList from './workouts/workout-list';

export default function WorkoutIndex() {
  return (
    <>
      <WorkoutHeader />
      <Routes>
        <Route path="/" element={<WorkoutList />} />
        <Route path="/:id" element={<WorkoutDisplay />} />
      </Routes>
    </>
  );
}
