import {
  ApiRoute,
  EmptyObject,
  GenericRegistry,
  HttpMethod,
  NoData,
  SchemaHelpersFor,
  SerializableResult,
  StatusCode,
} from '@inkibra/api-base';
import { ErrorDescriptor } from '@inkibra/error-base';
import typia from 'typia';
import { InkibraRecordlessUserType } from './type';

export type NOT_AUTHENTICATED_FOR_USER_API_FAILURE = ErrorDescriptor<
  'NOT_AUTHENTICATED_FOR_USER_API_FAILURE',
  'NOT_Authenticated for user api failure',
  undefined
>;
export type InkibraRecordlessUserNotAuthenticatedResponse =
  SerializableResult.ErrWithStatusCode<
    NOT_AUTHENTICATED_FOR_USER_API_FAILURE,
    StatusCode.NOT_AUTHENTICATED
  >;

export type InkibraRecordlessUserTypeResponse =
  SerializableResult.OkWithStatusCode<InkibraRecordlessUserType, StatusCode.OK>;
export namespace InkibraRecordlessUserTypeResponse {
  export const schema: SchemaHelpersFor<
    | InkibraRecordlessUserTypeResponse
    | InkibraRecordlessUserNotAuthenticatedResponse
  > = {
    is: typia.createIs<
      | InkibraRecordlessUserTypeResponse
      | InkibraRecordlessUserNotAuthenticatedResponse
    >(),
    assert: typia.createAssert<
      | InkibraRecordlessUserTypeResponse
      | InkibraRecordlessUserNotAuthenticatedResponse
    >(),
    validate: typia.createValidate<
      | InkibraRecordlessUserTypeResponse
      | InkibraRecordlessUserNotAuthenticatedResponse
    >(),
    stringify: typia.json.createStringify<
      | InkibraRecordlessUserTypeResponse
      | InkibraRecordlessUserNotAuthenticatedResponse
    >(),
    clone: typia.misc.createClone<
      | InkibraRecordlessUserTypeResponse
      | InkibraRecordlessUserNotAuthenticatedResponse
    >(),
  };
}

export namespace GetInkibraRecordlessUser {
  export type PathParams = Pick<InkibraRecordlessUserType, 'id'>;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessUserType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema = InkibraRecordlessUserTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.user/:id',
    {
      Name: 'getInkibraRecordlessUser';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    },
    InkibraRecordlessUserNotAuthenticatedResponse
  >({
    name: 'getInkibraRecordlessUser',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.user/:id',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace GetInkibraRecordlessUserByOwner {
  export type PathParams = Pick<InkibraRecordlessUserType, 'ownerId'>;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = NoData;
  export namespace Body {
    export const schema = NoData.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessUserType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema = InkibraRecordlessUserTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.user/:ownerId',
    {
      Name: 'getInkibraRecordlessUserByOwner';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    },
    InkibraRecordlessUserNotAuthenticatedResponse
  >({
    name: 'getInkibraRecordlessUserByOwner',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.user/:ownerId',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace CreateInkibraRecordlessUser {
  export type PathParams = EmptyObject;
  export namespace PathParams {
    export const schema = EmptyObject.schema;
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessUserType.Creation;
  export namespace Body {
    export const schema = InkibraRecordlessUserType.Creation.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessUserType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema = InkibraRecordlessUserTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.user/',
    {
      Name: 'createInkibraRecordlessUser';
      Method: HttpMethod.POST;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    },
    InkibraRecordlessUserNotAuthenticatedResponse
  >({
    name: 'createInkibraRecordlessUser',
    method: HttpMethod.POST,
    path: '/api/inkibra/recordless.user/',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export namespace ModifyInkibraRecordlessUser {
  export type PathParams = Pick<InkibraRecordlessUserType, 'id'>;
  export namespace PathParams {
    export const schema: SchemaHelpersFor<PathParams> = {
      is: typia.createIs<PathParams>(),
      assert: typia.createAssert<PathParams>(),
      validate: typia.createValidate<PathParams>(),
      stringify: typia.json.createStringify<PathParams>(),
      clone: typia.misc.createClone<PathParams>(),
    };
  }

  export type PathQuery = {};

  export type Body = InkibraRecordlessUserType.Creation;
  export namespace Body {
    export const schema = InkibraRecordlessUserType.Creation.schema;
  }

  export type Response = SerializableResult.OkWithStatusCode<
    InkibraRecordlessUserType,
    StatusCode.OK
  >;
  export namespace Response {
    export const schema = InkibraRecordlessUserTypeResponse.schema;
  }

  export const Route = new ApiRoute<
    '/api/inkibra/recordless.user/:id',
    {
      Name: 'modifyInkibraRecordlessUser';
      Method: HttpMethod.GET;
      PathParamsType: PathParams;
      PathQueryType: PathQuery;
      BodyType: Body;
      FileInputDescriptionType: undefined;
      ResponseType: Response;
    },
    InkibraRecordlessUserNotAuthenticatedResponse
  >({
    name: 'modifyInkibraRecordlessUser',
    method: HttpMethod.GET,
    path: '/api/inkibra/recordless.user/:id',
    pathParamsSchema: PathParams.schema,
    pathQueryValidator: typia.createValidate<PathQuery>(),
    bodySchema: Body.schema,
    fileInputDescription: undefined,
    responseSchema: Response.schema,
  });
}

export const InkibraRecordlessUserApiRouteRegistry = GenericRegistry.init({
  getInkibraRecordlessUser: GetInkibraRecordlessUser.Route,
  getInkibraRecordlessUserByOwner: GetInkibraRecordlessUserByOwner.Route,
  createInkibraRecordlessUser: CreateInkibraRecordlessUser.Route,
  modifyInkibraRecordlessUser: ModifyInkibraRecordlessUser.Route,
});

export const InkibraRecordlessUserApiFetcherRegistry =
  InkibraRecordlessUserApiRouteRegistry.matchWithAll((routes) => {
    return {
      ...routes.getInkibraRecordlessUser.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.getInkibraRecordlessUserByOwner.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.createInkibraRecordlessUser.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
      ...routes.modifyInkibraRecordlessUser.setupHandler<void>(() => {
        throw new Error('Not Implemented');
      }),
    };
  });
