import { SUBSCRIPTION_CHECK_FAILURE } from '../api';
import { InkibraRecordlessWorkoutType } from '../type';

type PathParam = string | number;

type PathParams<T extends string> =
  T extends `${infer _Start}:${infer Param}/${infer Rest}`
    ? { [K in Param | keyof PathParams<Rest>]: PathParam }
    : T extends `${infer _Start}:${infer Param}`
      ? { [K in Param]: PathParam }
      : Record<string, never>;

type RouteConfig<Path extends string, Params> = {
  getRouteString: (basePath?: string) => Path;
  makeRouteLink: (params: Params) => string;
};

function createRoute<Path extends string, Params extends PathParams<Path>>(
  path: Path,
): RouteConfig<Path, Params> {
  let basePath = '';
  return {
    getRouteString: (newBasePath = basePath) => {
      basePath = newBasePath;
      return `${basePath}${path}` as Path;
    },
    makeRouteLink: (params: Params) => {
      let fullPath = `${basePath}${path}`;
      for (const [key, value] of Object.entries(params)) {
        fullPath = fullPath.replace(`:${key}`, value as string);
      }
      return fullPath;
    },
  };
}

export type ExtractRoutePathParams<T> = T extends RouteConfig<
  string,
  infer Params
>
  ? Params
  : never;

export const BrowseRoute = createRoute('/');

export const DistanceActivityStartRoute = createRoute<
  '/distance-activity-start',
  {}
>('/distance-activity-start');

export const DistanceActivityPlaybackRoute = createRoute<
  '/distance-activity-playback',
  {}
>('/distance-activity-playback');

export const WorkoutRoute = createRoute<
  '/:id',
  { id: InkibraRecordlessWorkoutType['id'] }
>('/:id');
export const LoadingRoute = createRoute<
  '/:id/loading',
  { id: InkibraRecordlessWorkoutType['id'] }
>('/:id/loading');
export const PlayingRoute = createRoute<
  '/:id/playing',
  { id: InkibraRecordlessWorkoutType['id'] }
>('/:id/playing');
export const ErrorRoute = createRoute<
  '/:id/error/:reason',
  {
    id: InkibraRecordlessWorkoutType['id'];
    reason:
      | SUBSCRIPTION_CHECK_FAILURE['code']
      | 'TEMPO_MIX_CREATION_DID_NOT_COMPLETE'
      | 'ERROR_LOADING_PREWORKOUT_SONG';
  }
>('/:id/error/:reason');
export const CompletedRoute = createRoute<
  '/:id/completed',
  { id: InkibraRecordlessWorkoutType['id'] }
>('/:id/completed');
