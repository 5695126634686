/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

export enum ParagraphTextAlign {
  LEFT = 'left',
  CENTER = 'center',
}

export type ParagraphTextProps = {
  value: string;
  textAlign: ParagraphTextAlign;
  fontSize?: string;
};

const styles = css({
  display: 'block',
  color: 'white',
  fontFamily:
    'DM Sans, Nunito Sans, Helvetica Neue, Helvetica, Arial, sans-serif',
  fontSize: '15px',
  lineHeight: '150%',
  fontWeight: 200,
  opacity: 0.7,
});

export function ParagraphText({
  value,
  textAlign,
  fontSize = '15px',
}: ParagraphTextProps) {
  return <span css={css(styles, { textAlign, fontSize })}>{value}</span>;
}
