/** @jsxImportSource @emotion/react */

import { useContext } from 'react';
import { InkibraRecordlessWorkoutType } from '../type';
import { Button } from './button';
import { WorkoutSessionContext } from './workout-session-context';
export type WorkoutStartButtonProps = {
  workout: InkibraRecordlessWorkoutType;
};

export function WorkoutStartButton({ workout }: WorkoutStartButtonProps) {
  const workoutSession = useContext(WorkoutSessionContext)();

  return (
    <Button
      type="button"
      icon="start"
      color="primary"
      disabled={workout.intervals.length === 0}
      buttonSize="large"
      contents={
        workoutSession.isWorkoutSessionActive
          ? 'QUEUE WORKOUT'
          : 'START WORKOUT'
      }
      onClick={() => {
        workoutSession.queueWorkout(workout);
      }}
    />
  );
}
