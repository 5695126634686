/** @jsxImportSource react */
import Button from '@mui/material/Button';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';

import { HookEffectContext } from '@inkibra/api-base';
import { useWaitlists } from '@inkibra/recordless.waitlist-api';
import { Connector } from '../../common/lib/connector';

// biome-ignore lint/suspicious/noExplicitAny: TODO: fix this
const OpenButton = (params: any) => {
  console.log('got params', params);
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      style={{ marginLeft: 16 }}
      component={Link}
      to={`/admin/waitlists/${(params as { id: string }).id}`}
    >
      Open
    </Button>
  );
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 300 },
  { field: 'name', headerName: 'Waitlist Name', width: 250 },
  { field: 'waitlistEvent', headerName: 'Waitlist Event', width: 200 },
  {
    field: 'confirmationRedirect',
    headerName: 'Confirmation Redirect',
    width: 200,
  },
  { field: 'open', headerName: 'Open', width: 70, type: 'boolean' },
  // {
  //   field: "entries",
  //   headerName: "Entries",
  //   type: "number",
  //   width: 90
  // },
  {
    field: 'created',
    headerName: 'Date Created',
    sortable: false,
    width: 275,
  },
  {
    field: 'openWaitlistButton',
    headerName: 'Open Waitlist',
    width: 100,
    renderCell: OpenButton,
  },
];

export default function WaitlistList() {
  const connector = Connector.useContext();
  const hookEffectContext = new HookEffectContext();
  const waitlists = hookEffectContext.addEffect(useWaitlists());
  hookEffectContext.runEffects(connector.cache);

  console.log('WaitlistList', waitlists);
  return (
    <div style={{ display: 'flex', height: '80vh' }}>
      <div style={{ flexGrow: 1 }}>
        <DataGrid rows={waitlists} columns={columns} checkboxSelection />
      </div>
    </div>
  );
}
